import React from "react";
import { useGlobal } from "reactn";
import { cloneDeep } from "lodash";

import { LightTheme } from "theme/LightTheme";
import { DarkTheme } from "theme/DarkTheme";

import { MuiThemeProvider, createTheme } from "@material-ui/core/styles";

const AltThemedComponent = ({ children }) => {
  const [userPrefs = {}] = useGlobal("userprefs");

  const theme = React.useMemo(() => {
    const theme = cloneDeep(userPrefs.theme && userPrefs.theme === "dark" ? DarkTheme : LightTheme);
    theme.palette.primary.main = theme.palette.amber.main;
    theme.palette.secondary.main = theme.palette.purple.main;

    return createTheme(theme);
  }, [userPrefs.theme]);

  return <MuiThemeProvider theme={theme}>{children}</MuiThemeProvider>;
};

export default AltThemedComponent;
