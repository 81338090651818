import React from "react";
import queryString from "query-string";

import QBTypography from "components/QBTypography";
import { useMessages, useBranding } from "providers/BrandingProvider";

import CircularProgress from "@material-ui/core/CircularProgress";
import Button from "@material-ui/core/Button";
import RequestInfo from "components/modals/RequestInfo";

const Loading = ({ location }) => {
  const Messages = useMessages();
  const branding = useBranding();

  const { error, notavailable } = queryString.parse(location.search);
  const [loadingMessage, setLoadingMessage] = React.useState(Messages.MESSAGE.DOWNLOAD_MESSAGE);
  const timeout = React.useRef();

  const ok = !error && !notavailable;

  // Message which uses the error body but varied wording
  const errorLikeMessage = error
    ? Messages.MESSAGE.DOWNLOAD_HELP_DESK_ERROR
    : notavailable
    ? Messages.MESSAGE.DOWNLOAD_NOT_AVAILABLE
    : "";

  React.useEffect(() => {
    if (timeout.current) {
      timeout.current.clearTimeout();
    }

    timeout.current = window.setTimeout(function () {
      if (!error) {
        setLoadingMessage(Messages.MESSAGE.LENGTHY_DOWNLOAD_MESSAGE);
      }
    }, 5000);

    return function () {
      if (timeout.current) {
        timeout.current.clearTimeout();
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error, timeout]);

  return (
    <div
      style={{
        height: "100vh",
        display: "flex",
        flexGrow: 1,
        flexFlow: "column wrap",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <div style={{ marginBottom: "3.0rem" }}>
        <img src={`/logos/${branding.logo?.splash}`} alt={Messages.BRANDING.INSTITUTION_NAME} />
      </div>
      {ok && (
        <>
          <div style={{ marginBottom: "1.0rem" }}>
            <CircularProgress color="secondary" />
          </div>
          <QBTypography>{loadingMessage}</QBTypography>
        </>
      )}
      {!ok && (
        <>
          <QBTypography style={{ marginBottom: "1.0rem" }} color="error">
            {errorLikeMessage}
          </QBTypography>
          <QBTypography style={{ marginBottom: "1.0rem" }}>
            {Messages.MESSAGE.FOR_ASSISTANCE}{" "}
            <RequestInfo variant="hyperlink" linkLabel={`${Messages.MESSAGE.SEND_MESSAGE}.`} />
          </QBTypography>
          <Button color="secondary" onClick={window.close}>
            {Messages.LABEL.CLOSE}
          </Button>
        </>
      )}
    </div>
  );
};

export default Loading;
