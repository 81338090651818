import React from "react";
import clsx from "clsx";
import PropTypes from "prop-types";

import * as Constants from "commons/constants";
import { useMessages } from "providers/BrandingProvider";
import CheckboxFilter from "components/filters/CheckboxFilter";
import ToggleButtonFilterGroup from "components/filters/containers/ToggleButtonFilterGroup";
import TaxStatusToggleButtonFilterGroup from "components/filters/TaxStatusToggleButtonFilterGroup";
import { PopperPaper } from "components/containers/Modals";
import { StyledSecondaryTooltip } from "components/Controls";

import Button from "@material-ui/core/Button";
import Popper from "@material-ui/core/Popper";
import Fade from "@material-ui/core/Fade";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import IconButton from "@material-ui/core/IconButton";
import CancelPresentationIcon from "@material-ui/icons/CancelPresentation";
import { makeStyles } from "@material-ui/core/styles";
import { alpha } from "@material-ui/core/styles/colorManipulator";
import DateRangeFilter from "../DateRangeFilter";

const useStyles = makeStyles((theme) => ({
  wrapper: {
    flexGrow: 1,
    marginTop: 2,
  },
  transitionPopper: {
    zIndex: 10,
  },
  toolbar: {
    display: "flex",
    justifyContent: "flex-end",
    flexGrow: 1,
    width: "100%",
    marginBottom: 5,
  },
  iconButton: {
    padding: 0,
  },
  linkButton: {
    fontSize: "0.6rem",
    minWidth: 40,
    marginRight: 5,
  },
  closeIcon: {
    fontSize: "0.9rem",
  },
  section: {
    marginBottom: 20,
    "&:last-child": {
      marginBottom: 10,
    },
  },
  button: {
    margin: 0,
    fontSize: "0.6rem",
    fontWeight: "normal",
    minWidth: 40,
    lineHeight: 1,
    padding: "4px 8px",
  },
  searchIcon: {
    fontSize: "1.1rem",
  },
  filtered: {
    fontWeight: 400,
    color: alpha(theme.palette.secondary.main, 0.6),
    "&:hover": {
      borderColor: alpha(theme.palette.secondary.main, 0.15),
      backgroundColor: alpha(theme.palette.secondary.main, 0.08),
    },
  },
}));

const AdvancedSearchPopper = ({ filters, filterConfigs, width, onFilterChange, instrumentCategory }) => {
  const classes = useStyles();
  const Messages = useMessages();

  const [anchorEl, setAnchorEl] = React.useState(null);
  const paperWidth = width ? width : 500;
  const open = Boolean(anchorEl);
  const hasFilter = false; //TODO:  we can calc this now

  const togglePopper = (event) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const onClosePopper = () => {
    if (anchorEl) setAnchorEl(null);
  };

  let children = (
    <React.Fragment>
      <div className={classes.toolbar}>
        <>
          <IconButton className={classes.iconButton} color="primary" onClick={onClosePopper}>
            <CancelPresentationIcon className={classes.closeIcon} />
          </IconButton>
        </>
      </div>
      <div>
        {filterConfigs.map((cmp, i) => {
          let filterKey = cmp.filterKey != null ? cmp.filterKey : cmp.accessor;
          return (
            <div key={i} style={{ width: paperWidth }} className={classes.section}>
              {cmp.filter && cmp.filter.type === Constants.FILTER_TYPE.CHECKBOX && (
                <CheckboxFilter
                  hideOmit={cmp.filter.hideOmit}
                  optionsKey={cmp.filter.optionsKey}
                  filterKey={filterKey}
                  filter={filters[filterKey]}
                  header={cmp.label}
                  maxHeight={cmp.filter.height}
                  width={paperWidth}
                  instrumentCategory={instrumentCategory}
                  onFilterChange={onFilterChange}
                />
              )}
              {cmp.filter && cmp.filter.type === Constants.FILTER_TYPE.DATERANGE && (
                <DateRangeFilter
                  filterKey={filterKey}
                  filter={filters[filterKey]}
                  header={cmp.label}
                  variant="popper"
                  onFilterChange={onFilterChange}
                />
              )}
              {cmp.type === Constants.FILTER_TYPE.TOGGLEGROUP && (
                <ToggleButtonFilterGroup
                  header={cmp.header}
                  filters={filters}
                  filterConfigs={cmp.filters}
                  onFilterChange={onFilterChange}
                />
              )}
              {cmp.type === Constants.FILTER_TYPE.TAXSTATUS_TOGGLEGROUP && (
                <TaxStatusToggleButtonFilterGroup
                  header={cmp.header}
                  filters={filters}
                  filterConfigs={cmp.filters}
                  onFilterChange={onFilterChange}
                />
              )}
            </div>
          );
        })}
      </div>
    </React.Fragment>
  );

  return (
    <div className={classes.wrapper}>
      <StyledSecondaryTooltip title={Messages.TOOLTIP.MORE_SEARCH}>
        <Button
          color="secondary"
          variant="outlined"
          size="small"
          className={clsx(classes.button, {
            [classes.filtered]: hasFilter,
          })}
          type="button"
          onClick={togglePopper}
        >
          {Messages.LABEL.ADVANCED_SEARCH}
        </Button>
      </StyledSecondaryTooltip>
      <Popper
        open={open}
        placement="bottom-start"
        anchorEl={anchorEl}
        className={classes.transitionPopper}
        transition
      >
        {({ TransitionProps }) => (
          <Fade {...TransitionProps}>
            <React.Fragment>
              <ClickAwayListener onClickAway={onClosePopper}>
                <PopperPaper>{children}</PopperPaper>
              </ClickAwayListener>
            </React.Fragment>
          </Fade>
        )}
      </Popper>
    </div>
  );
};

AdvancedSearchPopper.propTypes = {
  filters: PropTypes.object.isRequired,
  filterConfigs: PropTypes.arrayOf(PropTypes.object).isRequired,
  width: PropTypes.number,
  instrumentCategory: PropTypes.string,
  onFilterChange: PropTypes.func.isRequired,
};

export default AdvancedSearchPopper;
