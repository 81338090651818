import React from "react";
import PropTypes from "prop-types";

import * as Constants from "commons/constants";
import { useMessages } from "providers/BrandingProvider";

import DataTable from "components/datatable/DataTable";
import ModalMessages from "components/modals/common/ModalMessages";
import {
  TicketBody,
  TicketRow,
  TicketRowSection,
  TicketButton,
} from "components/modals//tickets/TicketComponents";

import { makeStyles } from "@material-ui/core/styles";
import { ClosableModal } from "components/containers/Modals";

const useStyles = makeStyles((theme) => ({
  cusip: {
    color: theme.palette.primary.main,
  },
}));

const ReviewQuote = ({ isOpen, orders = [], onCloseModal }) => {
  const classes = useStyles();
  const Messages = useMessages();

  return (
    <ClosableModal
      open={isOpen}
      onClose={onCloseModal}
      header={
        <div>
          <span>Review Price Quotes for </span>
          <span className={classes.cusip}>{orders ? orders[0].cusip : ""}</span>
        </div>
      }
    >
      <TicketBody>
        <div style={{ marginTop: "1.0rem", marginBottom: "1.0rem" }}>
          <ModalMessages
            messages={["NOTE: This Price Quote is viewable for 2 days"]}
            level={Constants.ALERT_LEVEL.WARNING}
          />
        </div>
        <div style={{ marginBottom: "2.0rem" }}>
          <DataTable
            queryType={Constants.QUERY_TYPE.REVIEW_PRICE_QUOTE}
            data={orders}
            editable={false}
            selectable={false}
            TableProps={{
              className: "datatable-plain",
              style: { minWidth: 800 },
            }}
          />
        </div>

        <TicketRow>
          <TicketRowSection></TicketRowSection>
          <TicketRowSection>
            <TicketButton onClick={onCloseModal} label={Messages.LABEL.CLOSE} />
          </TicketRowSection>
        </TicketRow>

        {/* <div style={{ margin: "1.0rem", display: "flex", justifyContent: "center" }}>
                <ActionButton
                  onClick={onAddBondsToCart}
                  variant="outlined"
                  color="primary"
                  size="small"
                  style={{ margin: "0 1.0rem" }}
                >
                  Buy this bond
                </ActionButton>
                {isOfferEnabled && (
                  <ActionButton
                    variant="outlined"
                    color="secondary"
                    size="small"
                    style={{ margin: "0 1.0rem" }}
                  >
                    Sell this bond
                  </ActionButton>
                )}
              </div> */}
      </TicketBody>
    </ClosableModal>
  );
};

ReviewQuote.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onCloseModal: PropTypes.func.isRequired,
  orders: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default ReviewQuote;
