const BRANDING = {
  APPLICATION_NAME: "QwickBonds",
  APPLICATION_HEADER: "QwickBonds",
  INSTITUTION_NAME: "QwickBonds",
  PROVIDER_NAME: "MCAP LLC",
};

const FORM_LABEL = {
  FIRSTNAME: "First name",
  LASTNAME: "Last name",
  USERNAME: "Username",
  PASSWORD: "Password",
  PHONE: "Phone number",
  EMAIL: "Email",
  INSTITUTION: "What type of Institutional Investor are you?",
};

export const LABEL = {
  BUY: "Buy",
  BUYER: "Buyer",
  SELL: "Sell",
  SELLER: "Seller",
  BUY_ALL: "Buy All",
  USER_MESSAGE: "Enter special instructions here",
  INSTRUCTIONS: "INSTRUCTIONS",
  SEARCH: "Search",
  SAVED_SEARCH: "Searches",
  SAVED_SEARCHES: "Saved Searches",
  SAVE_EXISTING_SEARCH: "Save Current Search",
  SEARCH_NAME: "Search Name",
  SAVED_SEARCH_OPTIONS: "Saved",
  SAVE: "Save",
  SAVE_AS_NEW_SEARCH: "Save as New Search",
  UPDATE_CURRENT_SEARCH: "Update Existing Search",
  UPDATE_INSTITUTIONS: "Update Institutions",
  ADD_ALL_INSTITUTIONS: "Add All Institutions",
  REMOVE_ALL_INSTITUTIONS: "Remove All Institutions",
  UPDATE: "Update",
  CANCEL: "Cancel",
  CANCEL_TICKET: "Cancel Ticket",
  SUBMIT: "Submit",
  CLOSE: "Close",
  CONTINUE_NEXT_TICKET: "Continue to Next Ticket",
  DELETE: "Delete",
  CONFIRM: "Confirm",
  FAVORITE: "Bookmark",
  PREVIEW_ORDER: "Preview Order",
  EDIT_ORDER: "Edit Order",
  PLACE_ORDER: "Place Order",
  PREVIEW_REQUEST: "Preview Request",
  RETRY: "Retry",
  EDIT_REQUEST: "Edit Request",
  PLACE_REQUEST: "Place Request",
  SUBMIT_UPDATE: "Submit Update",
  ENTER: "Enter",
  FILTER: "Filter",
  CLEAR: "Clear",
  APPLY: "Apply",
  OMIT: "Omit",
  INCLUDE_UNRATED: "Include Unrated",
  IGNORE_FILTER: "Ignore this column filter",
  MAX: "MAX",
  MIN: "MIN",
  MINIMUM: "Minimum",
  RANGE: "Range",
  NONE: "None",
  ALL: "All",
  SHOW_ALL: "Show: All",
  YES: "Yes",
  NO: "No",
  OK: "OK",
  FROM: "From",
  TO: "To",
  BOND: "Bond",
  ACTIVES: "Actives",
  CD: "CD",
  CD_PRIMARY: "DTC Primary",
  CD_SECONDARY: "DTC Secondary",
  AGENCY: "Agency",
  MUNICIPAL: "Municipal",
  TREASURY: "Treasury",
  MORTGAGE: "Mortgage Backed Securities",
  HOME: "Home",
  INVENTORY: "Offerings",
  FAVORITES: "Favorites",
  ISSUER_TRACKER: "Issuer Tracker",
  HISTORY: "Order History",
  RATES: "Current Rates",
  YIELD_CURVE: "Yield Curve",
  NEW_ACCOUNT: "New Account",
  CONTACT: "Contact Us",
  HELP: "Help",
  NEWS: "News & Messages",
  TRADE_STATUS: "Trade Status",
  PRICE_QUOTES: "Price Quotes",
  BIDS_RFQ: "Bids (RFQ)",
  BONDS_FOR_SALE: "Offer Bonds for Sale",
  PRICE_QUOTES_SHORT: "Quotes",
  BIDS_RFQ_SHORT: "Bids",
  BONDS_FOR_SALE_SHORT: "Offer",
  COMPARABLE: "Comparable Bonds",
  COMPARABLE_CD: "Comparable CDs",
  STATUS: "Order Status",
  EMAIL: "Email",
  PHONE: "Phone",
  HIGHEST: "Highest",
  MEDIAN: "Median",
  CURRENT_YIELD_HIGHEST: "Current Highest Yield",
  CURRENT_YIELD_MEDIAN: "Current Median Yield",
  SEARCHING: "Loading...",
  SENDING: "Sending...",
  ISSUER_NOT_FOUND: "Issuer not found",
  CUSIP_NOT_FOUND: "CUSIP not found",
  CUSIP_INVALID: "Invalid CUSIP",
  TRADE_TICKET: "Your Order",
  OTHER_OFFERINGS: "Other Offerings",
  ALL_OFFERINGS_FOR: "All offerings for",
  ALL_OFFERINGS_FROM: "All offerings from ",
  COMPARABLE_TO: "Comparable Bonds for ",
  COMPARABLE_TO_CDS: "Comparable CDs for ",
  APPLY_SEARCH: "Apply Search",
  FIND_COMPRABLE_BONDS: "Find Comparable Bonds",
  FIND_COMPRABLE_CDS: "Find Comparable CDs",
  ADD_FAVORITES: "Add CUSIPs to Favorites",
  ADD_ISSUERS_TO_TRACKER: "Add Issuers to Tracker",
  ENTER_MORE_CUSIPS: "Enter more CUSIPs",
  ENTER_MORE_ISSUERS: "Enter more Issuers",
  ADD_CUSIPS: "Add CUSIPs",
  ADD_ISSUERS: "Add Issuers",
  ADD_CUSIPS_TICKET: "Add CUSIPs to Ticket",
  RETURN_TICKET: "Return to Ticket",
  ANNOUNCEMENT: `Message from ${BRANDING.APPLICATION_NAME}`,
  DISABLE_NOTIFICATIONS: "Turn off notifications",
  PROVIDED_BY: "powered by",
  TRADE_DESK: "Help Desk",
  TRADE_DESK_PHONE: "844-636-2663",
  LOGIN: "Login",
  LOG_INTO_ACCOUNT: "Log into Account",
  NEED_ACCOUNT: "Need an Account?",
  REQUEST_PASSWORD: "Request Password",
  FORGOT_PASSWORD: "Forgot Password?",
  NAME: "Name",
  ACCOUNT: "Account",
  INSTITUTION: "Institution",
  ADVANCED_SEARCH: "Filter",
  RECENT_SEARCHES: "Recent Searches",
  DATE: "Date",
  TYPE: "Type",
  SEE_RATES: "See Current Rates",
  SEARCH_CUSIP_PLACEHOLDER: "Search CUSIP",
  EMAIL_ALERTS: "Email alerts",
  SEE_FULL_DISCLAIMER: "See Full Disclaimer Information",
  DISCLAIMERS: "Disclaimers",
  REQUEST: {
    PRICE_QUOTES: "Request Price Quotes",
    BIDS_RFQ: "Request Bids",
    BONDS_FOR_SALE: "Offer Bonds for Sale",
  },
  REQUEST_INFO: "Message Help Desk",
  REQUEST_INFO_CUSIP: "Request More Information",
  REQUEST_INFO_PLACEHOLDER: "What can we help you with?",
  PREFERRED_METHOD: "Preferred method of contact",
  YOUR_HOLDINGS: "Your Holdings of FDIC#",
  ALL_BY: "All CUSIPS from ",
  ADD_CUSIP_TO_TRACKER: "Add {0} to issuer tracker",
  REMOVE_CUSIP_TRACKER: "Remove {0} from issuer tracker",
  EMAIL_FREQUENCY: "Email Alert Frequency",
  EXTERNAL_LINK_TEXT: "QwickRate",
  CONTACT_HELP_DESK: "Contact Help Desk",
  CART: "Trade Ticket",
  PROCEED_TO_CART: "Go to Ticket",
  CONTINUE_SHOPPING: "Continue Exploring Offerings",
  IN_CART: "Ticketed",
  RATES_CURRENT: "Rates current as of",
  PRICES_CURRENT: "Prices and rates current as of",
  SELECT_ACCOUNT: "Select an account to apply this trade",
  SELECT_ACCOUNTS_FOR_SAVED_SEARCH: "Select the institutions to grant access to this search",
  UNKNOWN: "UNKNOWN",
  REFRESH_BROWSER: "Refresh Browser",
  SEARCH_INSTITUTIONS: "Search Institutions",
  SEARCH_TRADERS: "Search Traders",
  AVAILABLE_INSTITUTIONS: "Available Institutions",
  SELECTED_INSTITUTIONS: "Selected Institutions",
  NEW_NEWS_ITEM: "New News Item",
  EDIT_NEWS_ITEM: "Edit News Item",
  ADD_LINK: "Add link",
  NEW_FILE: "New file",
  UPLOAD: "Upload",
  UPLOAD_FILE: "Upload file",
  URL: "URL",
  FILE: "File",
  SELECT_FILE: "Select or upload a file",
  HEADLINE: "Headline",
  ITEM_TEXT: "News Text",
  LINKS: "Links",
  LINK_TEXT: "Link Text",
  CURRENT_NEWS_ITEMS: "Current News Items",
  NEWS_ADMIN: "News Administration",
  FILE_ADMINISTRATION: "File Management",
  SELECT_FILE_OR_UPLOAD: "Click a file to select or upload a new file",
  FILE_NAME: "File Name",
  FILE_DESCRIPTION: "File Description",
  OPTIONAL: "optional",
};

export const MESSAGE = {
  ACCOUNT_CURRENTLY_TRADING: "You are current trading under account",
  ACCOUNT_CURRENTLY_TRADING_ADDING_BONDS: `If you would like to place this trade with another account,
    please remove the trade from the cart and complete
    the exisiting purchase.  Then re-add the trade to the cart.`,
  NOT_A_TRADER_ACCOUNT:
    "You are not an authorized trader. Please use the information above to contact the Help Desk and we'll get you trading right away.",
  SEND_MESSAGE: "send us a message",
  EXCEED_TOTAL_THRESHOLD: "***NOTE*** This order is for $1,000,000 or more",
  EXCEED_FDIC_THRESHOLD: "***WARNING!*** Your CD order is over $250,000.00 which exceeds FDIC coverage!",
  EXCEED_FDIC_THRESHOLD_FIX:
    "You may click 'Adjust' next to any CD to modify your order to remain within FDIC coverage",
  HOLDINGS_WARNING:
    "Our records indicate that you already hold CDs for an associated FDIC insured institution. Mouse over the FDIC# to view these holdings.",
  QR_USER_HOLDINGS_WARNING: "Please review your CD holdings before proceeding to prevent over-placement.",
  MAKE_WHOLE_CALL_WARNING:
    "Please note that {0} has a make whole call. Please contact the trade desk if you require more information.",
  RESTRICTED_STATES_WARNING:
    "CD {0} has restricted states. Please make sure that your institution is not located in {1}.",
  PREREFUNDED_WARNING: "Please note that municipal bond {0} has been pre-refunded",
  TRADE_DISCLAIMER: `Please note that prices, yields, and availability of DTC eligible CDs and Bonds are
    subject to change based on market conditions. If the price, yield, or availability of this DTC eligible CD
    or Bond changes after you have placed the order, then your order will not be filled. ${BRANDING.PROVIDER_NAME} representatives
    may contact you if there are changes in the market that may affect your order.`,
  TRADE_PRICE_DROP: "Good news! The price of a bond in your order has dropped!",
  TRADE_SUCCESS: "Your order has been received!",
  TRADE_SUCCESS_NEXT: "TBA: Trade next steps here",
  TRADE_FAIL_RETRY:
    "There has been an error and the trades above were not processed.  You may try to submit your order again or contact the Help Desk for assistance.",
  TRADE_FAIL:
    "There has been an error and the trades above were not processed.  Please contact the Help Desk for assistance.",
  MARKET_EOD: `The market is closed.  Your trade will be filled on a best effort basis, if the market allows,
    prior to {0}, otherwise your trade will be moved to the next open trading day
    and filled when the market opens.`,
  MARKET_CLOSED_SHORT: "Market is currently closed",
  MARKET_CLOSED:
    "The market is currently closed.  Trades submitted after hours will be filled the following business day.",
  EARLY_CLOSED: "Early close: Market closes at 2pm EST today.",
  BOND_UNAVAILABLE:
    "We're sorry.  The items below are no longer available in our offerings and will not be included in the final ticket.",
  PRICE_INCREASE: `The price of the offerings below have increased.  Please confirm the new price or remove the offering from the ticket.`,
  QTY_CHANGE: `The available quantity of the offerings below have changed.  Please confirm the new quantity or
        remove the offering from the ticket.`,
  PRICE_INCREASE_AND_QTY_CHANGE: `The price or available quantity of the offerings below have changed.  Please confirm the new price/quantity or
      remove the offering from the ticket.`,
  SELECT_CART: `Mortgage Backed Securities must be purchased separate from other offerings. Please select which
    offerings you would like to purchase.`,
  CART_DEMO_MESSAGE: `The offering you have selected has been added to your ticket. You may continue to explore
    and add more products or you may continue to your purchase.`,
  DISCLAIMERS: {
    GENERAL1: `The ${BRANDING.APPLICATION_NAME} platform is powered by MCAP Technologies LLC. All securities
    are offered through MCAP LLC, which is a registered broker-dealer, member of FINRA, MSRB and SIPC and are
    not offered by QwickBonds, MCAP Technologies LLC, MCAP Inc. or QwickRate. MCAP Technologies LLC,
    QwickBonds LLC and MCAP LLC are wholly owned subsidiaries of MCAP Inc. QwickRate is a separate and
    unaffiliated company.  QwickRate LLC has a partnership with QwickBonds LLC.`,
    GENERAL2: `MCAP LLC may have a position in the brokered CDs or bonds presented herein and may buy or sell
    such CDs or bonds in the course of its regular business. MCAP LLC receives a fee from the depository
    institution on primary issue brokered CDs. MCAP LLC acts as a principal on secondary market brokered CDs
    and bonds and may receive a markup on the sale of such CDs or bonds, which is included in the price
    presented. MCAP LLC does not provide recommendations and does not act as a fiduciary.`,
    GENERAL3: `Bonds are not FDIC-Insured and may lose value if sold prior to maturity or in the event of
      default. Fixed income securities are subject to increased risk of loss of principal during
      periods of rising interest rates. Investment value will fluctuate, and bonds, when sold, may be
      worth more or less than original cost. Fixed income securities are subject to various other
      risks, including changes in interest rates, credit quality, market valuation, liquidity,
      prepayments, early redemption, corporate events, tax ramifications and other factors.`,
    GENERAL4: `Please review this information carefully with your financial advisor, if any, to assure it meets
      your investment objectives. If you sell your brokered CD or bond prior to maturity, you may
      receive less than your initial investment. The information herein has been obtained from sources
      considered to be reliable, but we do not guarantee that it is accurate or complete`,
    PRICE_QUOTES: {
      SHORT:
        "Disclosure: Price Quotes are subjective and an approximation of the current market value. Not an offer or solicitation to buy or sell.",
      EXTENDED: `Price Quotes are subjective and an approximation of the current market value.
        Not an offer or solicitation to buy or sell. Price Quote pricing is based on past historical trade levels,
        comparable bond pricing, current spreads of like bonds, and is subjective.  As with all your investments,
        you must make your own determination whether an investment is appropriate for you.
        ${BRANDING.APPLICATION_NAME} and ${BRANDING.PROVIDER_NAME} is not recommending or endorsing any security by making it available to customers.
        You should conduct research and perform a thorough investigation as to the characteristics of any securities you
        intend to purchase.`,
    },
    ORDERS1: `Due to the illiquid nature of the fixed income market, it is possible that bonds displayed on ${BRANDING.APPLICATION_NAME}
      may no longer be available. Similarly, due to the manual nature of providing quotations, errors can occur,
      and bonds may be displayed at a price that is clearly away from the current prevailing market price.
      ${BRANDING.APPLICATION_NAME} reserves the right to cancel transactions in cases where the displayed bid/offering is no longer
      available or the price displayed is clearly away from the current prevailing market price. Purchase of bonds is
      subject to availability.`,
    ORDERS2: `During normal market hours, the prices and quantities available are subject to change and are
      updated by the offering institution throughout the day. Due to the possibility of system outages,
      untimely information provided by vendors, or various other reasons, ${BRANDING.APPLICATION_NAME} cannot guarantee
      the timeliness or accuracy of prices displayed. System availability and response time are subject
      to market conditions.`,
    MOODYS: ` A bond rating system provided by Moody's to specify its assessment
      of the quality of bonds (e.g., Aaa for best quality bonds, Baa for a lower quality than Aaa,
      etc.). A rating of Baa3 is the lowest Moody's credit rating that is still classified as investment
      grade. Non-rated positions will display as "--". Moody's ratings ("Ratings") are proprietary to Moody's
      or its affiliates and are protected by copyright and other intellectual property laws.
      Ratings are licensed to Licensee by Moody's. Moody's® is a registered trademark of Moody's
      Investors Service, Inc. RATINGS MAY NOT BE COPIED OR OTHERWISE REPRODUCED, REPACKAGED, FURTHER TRANSMITTED, TRANSFERRED,
      DISSEMINATED, REDISTRIBUTED OR RESOLD, OR STORED FOR SUBSEQUENT USE FOR ANY SUCH PURPOSE, IN WHOLE OR IN PART,
      IN ANY FORM OR MANNER OR BY ANY MEANS WHATSOEVER, BY ANY PERSON WITHOUT MOODY'S PRIOR WRITTEN
      CONSENT.`,
    SANDP: [
      `S&P Global Ratings Disclaimers and Regulatory Disclosures Copyright © 2020 by Standard & Poor's
      Financial Services LLC. All rights reserved.`,

      `No content (including ratings, credit-related analyses and data, valuations, model, software or other application
      or output therefrom) or any part thereof (Content) may be modified, reverse engineered, reproduced or
      distributed in any form by any means, or stored in a database or retrieval system, without the prior
      written permission of Standard & Poor's Financial Services LLC or its affiliates (collectively, S&P).
      The Content shall not be used for any unlawful or unauthorized purposes. S&P and any third-party providers,
      as well as their directors, officers, shareholders, employees or agents (collectively S&P Parties)
      do not guarantee the accuracy, completeness, timeliness or availability of the Content.
      S&P Parties are not responsible for any errors or omissions (negligent or otherwise), regardless of the cause,
      for the results obtained from the use of the Content, or for the security or maintenance of any data input by the user.
      The Content is provided on an "as is" basis. S&P PARTIES DISCLAIM ANY AND ALL EXPRESS OR IMPLIED
      WARRANTIES, INCLUDING, BUT NOT LIMITED TO, ANY WARRANTIES OF MERCHANTABILITY OR FITNESS FOR A PARTICULAR
      PURPOSE OR USE, FREEDOM FROM BUGS, SOFTWARE ERRORS OR DEFECTS, THAT THE CONTENT'S FUNCTIONING WILL BE
      UNINTERRUPTED OR THAT THE CONTENT WILL OPERATE WITH ANY SOFTWARE OR HARDWARE CONFIGURATION.
      In no event shall S&P Parties be liable to any party for any direct, indirect, incidental, exemplary,
      compensatory, punitive, special or consequential damages, costs, expenses, legal fees, or
      losses (including, without limitation, lost income or lost profits and opportunity costs or losses caused by negligence)
      in connection with any use of the Content even if advised of the possibility of such damages.`,

      `Credit-related and other analyses, including ratings, and statements in the Content are statements of
       opinion as of the date they are expressed and not statements of fact. S&P's opinions, analyses and rating
       acknowledgment decisions (described below) are not recommendations to purchase, hold, or sell any securities
       or to make any investment decisions, and do not address the suitability of any security. S&P assumes
       no obligation to update the Content following publication in any form or format. The Content should not
       be relied on and is not a substitute for the skill, judgment and experience of the user, its management,
       employees, advisors and/or clients when making investment and other business decisions. S&P does not act
       as a fiduciary or an investment advisor except where registered as such.
       While S&P has obtained information from sources it believes to be reliable, S&P does not perform an
       audit and undertakes no duty of due diligence or independent verification of any information it receives.
       Rating-related publications may be published for a variety of reasons that are not necessarily
       dependent on action by rating committees, including, but not limited to, the publication of a periodic
       update on a credit rating and related analyses.`,

      `To the extent that regulatory authorities allow a rating agency to acknowledge in one jurisdiction a
       rating issued in another jurisdiction for certain regulatory purposes, S&P reserves the right to assign,
       withdraw or suspend such acknowledgment at any time and in its sole discretion. S&P Parties disclaim
       any duty whatsoever arising out of the assignment, withdrawal or suspension of an acknowledgment as well
       as any liability for any damage alleged to have been suffered on account thereof.`,

      `S&P keeps certain activities of its business units separate from each other in order to preserve the
       independence and objectivity of their respective activities. As a result, certain business units of S&P
       may have information that is not available to other S&P business units. S&P has established policies
       and procedures to maintain the confidentiality of certain non-public information received in connection
       with each analytical process.`,

      `S&P may receive compensation for its ratings and certain analyses, normally from issuers or underwriters
       of securities or from obligors. S&P reserves the right to disseminate its opinions and analyses.
       S&P's public ratings and analyses are made available on its Web sites, www.standardandpoors.com (free of charge),
       and www.ratingsdirect.com and www.globalcreditportal.com (subscription), and may be distributed through
       other means, including via S&P publications and third-party redistributors. Additional information
       about our ratings fees is available at www.standardandpoors.com/usratingsfees.`,
    ],
    DATAPROVIDERS: `Moody's Investors Service, S&P Global, and Intercontinental Exchange - ICE Data Services`,
  },
  REQUEST_SUCCESS: "We have received your submission",
  REQUEST_SUCCESS_NEXT: {
    PRICE_QUOTES: "Next step: We will contact you when we have price quotes for your review.",
    BIDS_RFQ:
      "Next step: We will contact you when your bids have been received. You decide whether or not to sell.",
    BONDS_FOR_SALE: "Your offer(s) are now listed for sale on the platform.",
    TENATIVE_BONDS_FOR_SALE:
      "Offers that require Help Desk attention will be listed for sale on the platform shortly.",
  },

  REQUEST_DUPLICATE_CUSIP: {
    PRICE_QUOTES:
      "Duplicate CUSIPs in your ticket have been identified above.  You may submit only one request per CUSIP.  Please remove the undesired entry to proceed.",
    BIDS_RFQ:
      "Duplicate CUSIPs in your ticket have been identified above.  This is allowed, however, you may wish to enter an optional customer ID to assist you with tracking your offer.",
    BONDS_FOR_SALE:
      "Duplicate CUSIPs in your ticket have been identified above.  This is allowed, however, you may wish to enter an optional customer ID to assist you with tracking your offer.",
  },

  REQUEST_INFO_SUCCESS: `Thank you for contacting the ${BRANDING.APPLICATION_NAME} Help Desk. One of our traders will be in contact with you shortly.`,
  REQUEST_NEGATIVE_YIELD:
    "We are unable to accept negative or zero yields. Please adjust price or yields before proceeding.",
  REQUEST_INVALID_YIELD: "Yields are too high or invalid. Please adjust yields.",
  REQUEST_FAIL:
    "There has been an error and the requests below were not processed.  Please contact the Help Desk for assistance.",
  REQUEST_SUCCEED_SOME: "The remainder of your requests were received.",
  LOOKUP_ERROR:
    "There has been an error retrieving market data.  Please try again or contact the Help Desk for assistance.",
  CALCULATOR_ERROR:
    "We were unable to calculate certain values.  Please proceed with your request and our Help Desk will provide the necessary data.",
  REQUEST_EDIT_WARNING: `Warning: There is a pending sale of this offering. The Help Desk will need to approve before
    you can edit the offering and it may not be possible to honor any edits. Do you wish to continue?`,
  REQUEST_CANCEL_WARNING: `Warning: There is a pending sale of this offering. The Help Desk will need to approve before
    the offering is cancelled and it may not be possible to honor the cancellation. Do you wish to continue?`,
  ACTUAL_CANCEL_WARNING: "Are you sure you want to cancel this offering?",
  MULTI_CANCEL_WARNING: "Are you sure you want to cancel all selected offerings?",
  SELL_BIDS_SELL_WARNING: "Are you sure you wish to accept the current high bid and sell?",
  SELL_BIDS_DECLINE_WARNING: "Are you sure you wish to decline all bids on this offer?",
  RETURN_TO_REFERRER_ERROR:
    "We're sorry but we are currently unable to fill this order.  Please switch tabs to return to the {0} site.",
  RETURN_TO_REFERRER_CANCEL: "Your order has been cancelled.  Please switch tabs to return to the {0} site.",
  RETURN_TO_REFERRER_PURCHASE_COMPLETE: "Switch tabs to return to the {0} site and view your updated cart",
  DUPLICATE_FAVORITES: "CUSIPs indicated below have already been added to Favorites and will not be added.",
  DUPLICATE_ISSUERS:
    "Issuers indicated below have already been added to Issuer Tracker and will not be added.",
  INVALID_CUSIP: "Some CUSIPs were not found in our inventory or were invalid.  They will not be added.",
  INVALID_ISSUER: "Some CUSIPs were invalid.  They will not be added.",
  ISSUER_NOT_FOUND: `Some issuers were not found in our inventory and will not be added to the Tracker.  Please contact ${LABEL.TRADE_DESK_PHONE} with any questions or concerns.`,
  NO_FAVORITES: "You currently do have any Favorites",
  NO_COMPARABLE: "Enter a CUSIP above to see comparable bonds in our inventory",
  SEARCH_BOX: "Enter a CUSIP to search our offerings",
  NODATA: {
    BONDS_NOT_FOUND: "No bonds found",
    CDS_NOT_FOUND: "No CDs found",
    BONDS_NOT_FOUND_COMPARABLE: "No Comparable Bonds found",
    CDS_NOT_FOUND_COMPARABLE: "No Comparable CDs found",
    NO_FAVORITES_TYPE: "You have no Favorites of this type",
    NO_FAVORITE_ISSUERS_TYPE: "You have no saved issuers of this type",
    NO_BONDS_IMPORT: "Nothing to add",
    NO_BONDS_SEARCH: "No bonds found within your current search parameters",
    NO_CDS_SEARCH: "No CDs found within your current search parameters",
    NO_BONDS_TICKET: "This ticket contains no bonds",
    NO_BONDS_SELL: "No recent activity",
    NO_ORDER_HISTORY: "No current Order History",
    NO_ORDER: "No orders found",
    NO_ORDER_SEARCH: "No orders found within your current search parameters",
    NO_ORDER_STATUS: "No current or recent trades",
    RATES_UNAVALIABLE: "Current rates are unavailable at this time",
    SERVER_ERROR: "There has been an error and data could not be retrieved",
    CUSIP_SEARCH_NOT_FOUND: "No cusips were found matching your search",
    HOLDINGS_NOT_FOUND: "No holdings found",
    OPTIONS: "No options available",
    DOCUMENTS: "No files available",
  },
  BOND_NOT_IN_INVENTORY: `This CUSIP is currently not in our inventory. You may still explore its Comparable
    Bonds, offer the CUSIP for sale and add the CUSIP to your Favorites.`,
  BOND_UNKNOWN: `We are unable to retrieve information for this CUSIP from our database. Please contact our Help Desk for
    further information.`,
  VALIDATION: {
    REQUIRED: "Required",
    INVALID_FORMAT: "Format is invalid",
    PASSWORD_MINIMUM: "Password must be at least 8 characters",
    INVALID_DATE: "This is not a valid date",
    OUTOFORDER_DATE_RANGE: "End Date is before Start Date",
    DUPLICATE_NAME: "This name is already in use",
    SEARCH_NAME_DIFFERENT: "Search name must be different than existing name",
    INSTITUTIONS_REQUIRED: "At least one institution is required",
  },
  AUTHENTICATION_FAIL: "Login failed.  Please ensure username and password are correct.",
  QB_APPLICATION_LOADING: "Please wait while the application loads...",
  GENERAL_RETRY:
    "There has been an error.  Please refresh the page and try again. If you still have issues please contact the Help Desk.",
  DOWNLOAD_MESSAGE: "One moment while we fetch your document",
  LENGTHY_DOWNLOAD_MESSAGE:
    "We're sorry your download is taking so long.  This download may take up to 30 seconds.  Thank you for your patience.",
  DOWNLOAD_HELP_DESK_ERROR: `There has been an error and the document did not download.   Please try again.`,
  FOR_ASSISTANCE: `For assistance, please call ${LABEL.TRADE_DESK_PHONE} or`,
  DOWNLOAD_ERROR:
    "There has been an error and the document did not download.  Please try again or contact the Help Desk.",
  DOWNLOAD_NOT_AVAILABLE: "The document you have requested is not available at this time.",
  MESSAGE_SEND_ERROR: `There has been an error and your message was not received.  Please try again or contact ${LABEL.TRADE_DESK_PHONE} for assistance.`,
  FETCH_PROFILE_WARNING: "There has been an error and your user profile was not retrieved.",
  ADD_FAVORITES_ERROR: "We're sorry but we are unable to add to your favorites at this time.",
  FETCH_FAVORITES_ERROR: "We're sorry but we are unable to retrieve your favorites at this time.",
  FETCH_DATA_ERROR:
    "There has been an error retrieving data. Please refresh the page and try again. If you still have issues please contact the Help Desk.",
  SESSION_TIMEOUT: "Your session has timed out.  Please log into the application again.",
  UNAUTHORIZED:
    "Your credentials are not valid.  Please log into the application again or contact the Help Desk.",
  GENERAL_WARNING:
    "There has been an error and some data was not retrieved.  You may continue to use the application but some areas may be inaccessible.",
  MUST_BE_A_TRADER_FEATURE:
    "This feature is only accessible to authorized personnel for your account. Please contact the Help Desk if you require access.",
  GENERIC_NO_ACCESS_TO_FEATURE:
    "You do not have access to this feature. Please contact the help desk to enable access.",
  FDIC_EQUIVALENCIES: "The two branches for this institution do not receive separate FDIC insurance coverage",
  TRADE_ALERT: "WARNING:  Please review below before proceeding",
  OUTDATED_VERSION_ALERT_MESSAGE: `The ${BRANDING.APPLICATION_NAME} application has recently updated. Please refresh your browser to use the latest version of ${BRANDING.APPLICATION_NAME}.`,
  NO_MESSAGES: "Check back here for important News & Messages",
  DOCUMENT_WARNING: `Users are responsible for the information contained in uploaded documents and are cautioned against uploading
    documents with sensitive data`,
};

export const TOOLTIP = {
  CLEAR_SEARCH: "Clear all search filters",
  HIDE_SEARCH: "Hide search filters",
  SHOW_SEARCH: "Show search filters",
  MORE_SEARCH: "More search options",
  ADDITIONAL_OFFERING: "additional offering",
  ADDITIONAL_OFFERINGS: "additional offerings",
  OFFERING_FROM: "offering from",
  OFFERINGS_FROM: "offerings from",
  SEE_ALL_OFFERINGS_FOR: "See all offerings for",
  SEE_ALL_OFFERINGS_FROM: "See all offerings from ",
  PRINT: "Print",
  FAVORITE: "Favorite this bond",
  UNFAVORITE: "Unfavorite this bond",
  BUY: "Buy this bond",
  EDIT: "Edit this transaction",
  IMPORT_FAVORITES: "Add Bonds to Favorites",
  TRADE_TICKET: "Trade Ticket",
  PURCHASE_PACKAGE: "Purchase Package",
  CRITERIA: "Edit search criteria",
  BOND_UNAVAILABLE: "This bond is no longer available",
  TAXABLE: "Taxable bond",
  OPEN_MENU: "Open menu",
  CLOSE_MENU: "Close menu",
  EDIT_REQUEST_PENDING: "Request for edit pending",
  CANCEL_REQUEST_PENDING: "Request for cancel pending",
  REQUEST_INFO: "Contact our Help Desk",
  REQUEST_INFO_CUSIP: "Contact our Help Desk for more information on this CUSIP",
  RESTRICTED_STATES:
    "Institutions located in the listed Restricted States are unable to purchase this investment",
  EMAIL_FREQUENCY: "See email alert frequency",
};

const DEFAULT = { BRANDING, FORM_LABEL, LABEL, MESSAGE, TOOLTIP };

export default DEFAULT;
