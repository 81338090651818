import React from "react";
import { useGlobal } from "reactn";
import PropTypes from "prop-types";

import * as utils from "commons/utils";
import { useMessages } from "providers/BrandingProvider";
import { StyledSecondaryTooltip } from "components/Controls";
import { addFavorite, deleteFavorite } from "services/FavoritesService";
import * as Constants from "commons/constants";

import IconButton from "@material-ui/core/IconButton";
import BookmarkIcon from "@material-ui/icons/Bookmark";
import BookmarkBorderIcon from "@material-ui/icons/BookmarkBorder";
import { getUser } from "../commons/helpers/userStorage";

const Favorite = ({ bond }) => {
  const Messages = useMessages();
  const [favorites = []] = useGlobal("favorites");
  const cusip = bond ? bond.cusip : null;

  const user = getUser();

  const allCusips = utils.getValuesByProperty(favorites, "cusip");
  const [isFavorite, setFavorite] = React.useState(allCusips.includes(cusip));

  React.useEffect(() => {
    if (utils.hasNonEmptyValue(cusip)) {
      setFavorite(allCusips.includes(cusip));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cusip, allCusips]);

  const handleFavoriteClick = () => {
    if (user[Constants.USER_PERMISSIONS.CAN_USE_FAVORITES_AND_TRACKERS]) {
      if (isFavorite) {
        deleteFavorite(bond.cusip);
      } else {
        addFavorite(bond);
      }
      setFavorite(!isFavorite);
    } else {
      utils.issueWarningAlert(Messages.MESSAGE.MUST_BE_A_TRADER_FEATURE);
    }
  };

  return (
    <IconButton size="small" onClick={handleFavoriteClick}>
      {isFavorite && (
        <StyledSecondaryTooltip title={Messages.TOOLTIP.UNFAVORITE}>
          <BookmarkIcon color="secondary" />
        </StyledSecondaryTooltip>
      )}
      {!isFavorite && (
        <StyledSecondaryTooltip title={Messages.TOOLTIP.FAVORITE}>
          <BookmarkBorderIcon color="secondary" />
        </StyledSecondaryTooltip>
      )}
    </IconButton>
  );
};

Favorite.propTypes = {
  bond: PropTypes.object,
};

export default Favorite;
