import React from "react";
import * as Sentry from "@sentry/react";
import { useMessages } from "providers/BrandingProvider";

const MODAL_ID = "outdated-version-modal";

const previousHtml = React.createRef();

export const checkBuildVersion = async () => {
  if (process.env.NODE_ENV !== "development") {
    const url = `https://${window.location.host}/index.html`;

    fetch(url, {
      headers: {
        "Cache-Control": "no-cache, max-age=0",
        Pragma: "no-cache",
      },
    })
      .then((response) => {
        if (response.status !== 200) {
          throw new Error("Attempt to verify build version while application is offline");
        }
        return response.text();
      })
      .then((html) => {
        if (!previousHtml.current) {
          previousHtml.current = html;
          return;
        }

        if (previousHtml.current !== html) {
          showOutdatedVersionModal();
        }
      })
      .catch((error) => {
        Sentry.captureException(error);
      });
  }
};

const isOutdatedVersionModalOpen = () => {
  const modal = document.getElementById(MODAL_ID);
  return window.getComputedStyle(modal).display === "block";
};

const hideOutdatedVersionModal = () => {
  document.getElementById(MODAL_ID).style.display = "none";
};

const showOutdatedVersionModal = () => {
  if (isOutdatedVersionModalOpen()) {
    hideOutdatedVersionModal();
  }

  document.getElementById(MODAL_ID).style.display = "block";
};

const OutdatedVersionModal = () => {
  const Messages = useMessages();

  return (
    <div id={MODAL_ID} style={{ display: "none" }}>
      <div
        role="presentation"
        style={{
          position: "fixed",
          zIndex: 1300,
          inset: 0,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <div
          aria-hidden="true"
          style={{
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            display: "flex",
            zIndex: -1,
            position: "fixed",
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            opacity: 1,
            transition: "opacity 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
          }}
        ></div>
        <div
          style={{
            maxWidth: 400,
            backgroundColor: "#fafafa",
            boxShadow:
              "0px 5px 5px -3px rgb(0 0 0 / 20%), 0px 8px 10px 1px rgb(0 0 0 / 14%), 0px 3px 14px 2px rgb(0 0 0 / 12%)",
            borderRadius: 5,
            opacity: 1,
            transition: "opacity 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
          }}
        >
          <div style={{ padding: 16 }}>
            <div style={{ fontSize: "0.75rem", color: "rgb(10,10,10.95)", margin: "1.0rem" }}>
              {Messages.MESSAGE.OUTDATED_VERSION_ALERT_MESSAGE}
            </div>
            <div style={{ textAlign: "center", margin: "1.0rem" }}>
              <a
                style={{ color: "#13b71b", textDecoration: "none", textTransform: "uppercase" }}
                href={window.location.href}
              >
                {Messages.LABEL.REFRESH_BROWSER}
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OutdatedVersionModal;
