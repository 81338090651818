import React from "react";
import { get, cloneDeep } from "lodash";

import * as utils from "commons/utils";
import { useViewState, useViewStateDispatcher } from "providers/ViewStateProvider";
import * as Constants from "commons/constants";
import { Div } from "components/containers/Containers";
import DataTable from "components/datatable/DataTable";
import { useDataService } from "services/DataService";
import useAutoRefresh from "hooks/useAutoRefresh";

const OrderStatus = () => {
  const viewState = useViewState();

  const dispatchViewStateChange = useViewStateDispatcher();
  const stateRef = React.useRef();

  const [state, queryDataService] = useDataService();
  const [queryState, setQueryState] = React.useState([]);

  const currentViewState = get(viewState, Constants.VIEW.STATUS, {});
  stateRef.current = cloneDeep(currentViewState);

  const searchId = currentViewState.query?.searchId;

  React.useEffect(() => {
    const queryParams = {
      type: Constants.QUERY_TYPE.STATUS,
      page: 0,
      sort: [{ id: "enteredDate", desc: true }],
      highlightedOrders: [],
    };
    queryParams.clearDataOnFetch = !searchId;
    queryDataService(queryParams);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchId]);

  React.useEffect(() => {
    setQueryState(state);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state]);

  const refreshData = () => {
    const currentViewState = stateRef.current;
    if (utils.hasNonEmptyValue(currentViewState)) {
      const updatedQuery = cloneDeep(currentViewState.query);
      updatedQuery.suppressLoading = true;
      updatedQuery.clearDataOnFetch = false;
      updatedQuery.highlighted = [];
      queryDataService(updatedQuery, { autorefresh: true });
    }
  };

  useAutoRefresh(refreshData);

  const handleCurrentViewStateChange = (state) => {
    const updatedState = {
      state: state,
      view: Constants.VIEW.STATUS,
    };

    dispatchViewStateChange(updatedState);
  };

  return (
    <Div className="orderStatusTable">
      <DataTable
        queryType={Constants.QUERY_TYPE.STATUS}
        data={queryState.data}
        loading={queryState.isLoading && !queryState.query.suppressLoading}
        error={queryState.isError}
        selectable={false}
        onViewStateChange={handleCurrentViewStateChange}
      />
    </Div>
  );
};

export default OrderStatus;
