import React from "react";
import moment from "moment-timezone";
import { get, sum, map, isEqual, cloneDeep } from "lodash";

import * as utils from "commons/utils";
import * as filterUtils from "commons/filterUtils";
import * as Constants from "commons/constants";
import { BuyButton } from "components/modals/TradeTicket";
import {
  TextInput,
  TooltipBadge,
  StyledTooltip,
  StyledSecondaryTooltip,
  StyledCheck,
  StyledButtonGroup,
  StyledInfoIcon,
  LinkButton,
  StyledIconButton,
  StyledEditIcon,
  StyledCancelIcon,
} from "components/Controls";
import CountdownClock from "components/CountdownClock";
import SellSideSimpleActionButton from "components/SellSideSimpleActionButton";

import Favorite from "components/Favorite";
import FavoriteIssuer from "components/FavoriteIssuer";
import Holdings from "components/Holdings";
import RangeFilter from "components/filters/RangeFilter";
import SelectRatingFilter from "components/filters/SelectRatingFilter";
import DualRangeFilter from "components/filters/DualRangeFilter";
import DateRangeFilter from "components/filters/DateRangeFilter";
import CheckboxFilterPopper from "components/filters/containers/CheckboxFilterPopper";
import AdvancedSearchPopper from "components/filters/containers/AdvancedSearchPopper";
import { AdvancedCriteriaModalButton } from "components/modals/advancedcriteria/AdvancedCriteriaModal";
import ReviewQuote from "components/modals/ReviewQuote";
import {
  RequestEditConfirmation,
  RequestCancelConfirmation,
  SimpleConfirmation,
} from "components/modals/InformationDialog";

import KeywordFilter from "components/filters/KeywordFilter";
import DataTableConfig from "config/datatableconfig";
import * as DataConfig from "config/dataconfig";

import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import Badge from "@material-ui/core/Badge";

import DocumentIcon from "@material-ui/icons/InsertDriveFileOutlined";
import DeleteIcon from "@material-ui/icons/RemoveCircleOutline";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";
import ArrowDropUpIcon from "@material-ui/icons/ArrowDropUp";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import EditIcon from "@material-ui/icons/Edit";
import TitleIcon from "@material-ui/icons/Title";
import CircularProgress from "@material-ui/core/CircularProgress";

import { withStyles } from "@material-ui/core/styles";
import { StyledSecondaryInfoIcon } from "components/Controls";
import DocumentDownload from "components/DocumentDownload";

const isAdvancedCompBondsEnabled = process.env.REACT_APP_FEATURES_ADV_COMPBONDS === "true";

/**
 * Styled sub-components
 */

const HeaderButton = withStyles((theme) => ({
  root: {
    fontSize: "0.6rem",
    lineHeight: 1,
    fontWeight: "normal",
    marginTop: 2,
    marginBottom: 2,
    borderRadius: 4,
    minWidth: 40,
    padding: "4px 8px",
    "&.MuiButton-outlinedSecondary.Mui-disabled": {
      border: "1px solid",
      borderColor: theme.palette.border.disabled,
      color: theme.palette.text.disabled,
    },
  },
}))(Button);

const HeaderIconButton = withStyles((theme) => ({
  root: {
    padding: 0,
    fontSize: "0.6rem",
    borderRadius: 4,
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
}))(IconButton);

const HideFilterSvgIcon = withStyles((theme) => ({
  root: {
    padding: 0,
    fontSize: "1.0rem",
  },
}))(KeyboardArrowDownIcon);

const ShowFilterSvgIcon = withStyles((theme) => ({
  root: {
    padding: 0,
    fontSize: "1.0rem",
  },
}))(KeyboardArrowRightIcon);

const SortAscIcon = withStyles((theme) => ({
  root: {
    marginTop: -6,
    marginBottom: -8,
    fontSize: "1.2rem",
  },
}))(ArrowDropUpIcon);

const SortDescIcon = withStyles((theme) => ({
  root: {
    marginTop: -6,
    marginBottom: -8,
    fontSize: "1.2rem",
  },
}))(ArrowDropDownIcon);

const RequestEditIcon = withStyles((theme) => ({
  root: {
    fontSize: "1.0rem",
    color: theme.palette.text.warning,
  },
}))(EditIcon);

const StyledDeleteIconButton = withStyles((theme) => ({
  root: {
    borderRadius: 4,
    padding: 4,
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
}))(IconButton);

const StyledDeleteIcon = withStyles((theme) => ({
  root: {
    fontSize: "1.0rem",
  },
}))(DeleteIcon);

const StyledTaxableIcon = withStyles((theme) => ({
  root: {
    fontSize: "0.8rem",
    marginBottom: -2,
    marginRight: 4,
    border: "1px solid",
    borderColor: theme.palette.border.primary,
    color: theme.palette.primary.main,
    borderRadius: "50%",
    padding: 2,
  },
}))(TitleIcon);

export const StyledDocumentIcon = withStyles((theme) => ({
  root: {
    fontSize: "1.5rem",
  },
}))(DocumentIcon);

const DocumentBadge = withStyles((theme) => ({
  anchorOriginTopRightRectangle: {
    transform: "scale(1) translate(-12%, 20%)",
    color: theme.palette.primary.main,
    fontSize: "0.6rem",
    letterSpacing: "-0.1em",
    cursor: "pointer",
  },
}))(Badge);

const ConfigHelper = (Messages) => {
  /**
   * Filter configurations
   */
  const getFilterProps = (tableProps, columnProps, filterProps, onChange) => {
    const filterKey = filterProps.filterKey || columnProps.accessor;
    return {
      filterKey: filterProps.filterKey || columnProps.accessor,
      filter: get(tableProps.searchFilter, filterKey),
      instrumentCategory: tableProps.instrumentCategory,
      onFilterChange: onChange,
      baseFilter: tableProps.baseFilter,
      label: filterProps.label,
      data: tableProps.data,
      ...filterProps,
    };
  };

  const appendRangeFilterConfig = (column, tableProps, columnProps, filterProps) => {
    const filterConfig = {
      filterMethod: filterUtils.filterRange,
      Filter: ({ filter, onChange }) => (
        <RangeFilter {...getFilterProps(tableProps, columnProps, filterProps, onChange)} />
      ),
    };

    Object.assign(column, filterConfig);
  };

  const appendDualRangeFilterConfig = (column, tableProps, columnProps, filterProps) => {
    const filterConfig = {
      filterMethod: filterUtils.filterRange,
      Filter: ({ filter, onChange }) => (
        <DualRangeFilter {...getFilterProps(tableProps, columnProps, filterProps, onChange)} />
      ),
    };

    Object.assign(column, filterConfig);
  };

  const appendSelectRatingFilterConfig = (column, tableProps, columnProps, filterProps) => {
    const filterConfig = {
      filterMethod: filterUtils.filterRange,
      Filter: ({ filter, onChange }) => (
        <SelectRatingFilter {...getFilterProps(tableProps, columnProps, filterProps, onChange)} />
      ),
    };

    Object.assign(column, filterConfig);
  };

  const appendDateRangeFilterConfig = (column, tableProps, columnProps, filterProps) => {
    const filterConfig = {
      filterMethod: filterUtils.filterDateRange,
      Filter: ({ filter, onChange }) => (
        <DateRangeFilter {...getFilterProps(tableProps, columnProps, filterProps, onChange)} />
      ),
    };

    Object.assign(column, filterConfig);
  };

  const appendKeywordFilterConfig = (column, tableProps, columnProps, filterProps) => {
    const filterConfig = {
      filterMethod: filterUtils.filterKeyword,
      Filter: ({ filter, onChange }) => (
        <KeywordFilter
          filterType={filterProps.type}
          showFilterText={true}
          pad={columnProps.pad || 0}
          lookahead={columnProps.lookahead !== false}
          {...getFilterProps(tableProps, columnProps, filterProps, onChange)}
        />
      ),
    };

    Object.assign(column, filterConfig);
  };

  const appendCheckboxFilterConfig = (column, tableProps, columnProps, filterProps) => {
    const filterConfig = {
      filterMethod: filterUtils.filterOptions,
      Filter: ({ filter, onChange }) => (
        <CheckboxFilterPopper
          buttonLabel={Messages.LABEL.FILTER}
          {...getFilterProps(tableProps, columnProps, filterProps, onChange)}
        />
      ),
    };

    Object.assign(column, filterConfig);
  };

  const appendAdvancedFilterConfig = (column, tableProps, columnProps, filterProps) => {
    if (!tableProps.advancedSearch?.filters) {
      return null;
    }
    const filterConfig = {
      filterMethod: filterUtils.filter,
      Filter: ({ filter, onChange }) => (
        <div>
          <StyledSecondaryTooltip title={Messages.TOOLTIP.CLEAR_SEARCH}>
            <span>
              <HeaderButton
                disabled={isEqual(tableProps.searchFilter, getDefaultFilter(tableProps))}
                onClick={(e) => onChange([])}
                size="small"
                color="secondary"
                variant="outlined"
              >
                {Messages.LABEL.CLEAR}
              </HeaderButton>
            </span>
          </StyledSecondaryTooltip>
          <AdvancedSearchPopper
            filters={tableProps.searchFilter}
            filterConfigs={tableProps.advancedSearch.filters}
            width={tableProps.advancedSearch.width}
            instrumentCategory={tableProps.instrumentCategory}
            onFilterChange={onChange}
          />
        </div>
      ),
    };

    Object.assign(column, filterConfig);
  };

  const appendClearFilterConfig = (column, tableProps, columnProps, filterProps) => {
    const filterConfig = {
      Filter: ({ filter, onChange }) => (
        <HeaderButton
          style={{ marginTop: 21 }}
          disabled={isEqual(tableProps.searchFilter, getDefaultFilter(tableProps))}
          onClick={(e) => onChange([])}
          size="small"
          color="secondary"
          variant="outlined"
        >
          {Messages.LABEL.CLEAR}
        </HeaderButton>
      ),
    };

    column.filterable = true;
    Object.assign(column, filterConfig);
  };

  const appendToggleFilterHeaderConfig = (column, tableProps, columnProps) => {
    const headerConfig = {
      Header: tableProps.filterable
        ? () => (
            <HeaderIconButton size="small" onClick={tableProps.onToggleFilterbar}>
              {tableProps.showFilterbar && (
                <StyledTooltip title={Messages.TOOLTIP.HIDE_SEARCH}>
                  <HideFilterSvgIcon />
                </StyledTooltip>
              )}
              {!tableProps.showFilterbar && (
                <StyledTooltip title={Messages.TOOLTIP.SHOW_SEARCH}>
                  <ShowFilterSvgIcon />
                </StyledTooltip>
              )}
            </HeaderIconButton>
          )
        : "",
      headerClassName: "controlCell",
    };

    Object.assign(column, headerConfig);
  };

  const appendAdvancedCriteriaHeaderConfig = (column, tableProps, columnProps) => {
    const headerConfig = {
      Header: (props) => {
        const lowestTier = props.data.reverse().find((b) => utils.hasNonEmptyValue(b.brokeredsecurityid))
          ?._original.tierNumber;

        const modal = React.useMemo(() => {
          if (!isAdvancedCompBondsEnabled) {
            return null;
          }

          return (
            <AdvancedCriteriaModalButton
              tier={lowestTier || 3}
              filterConfigs={tableProps.advancedCompBondCriteria.filters}
              onToggleAdvancedCriteriaModal={tableProps.onToggleAdvancedCriteriaModal}
            />
          );
        }, [lowestTier]);

        return modal;
      },
    };

    Object.assign(column, headerConfig);
  };

  /**
   * Column configurations
   */

  const appendMultiselectColumn = (columnConfig, tableProps) => {
    const accessor = columnConfig.accessor;
    const selectedRowIds = utils.getValuesByProperty(tableProps.selectedBonds, accessor);

    if (!columnConfig.disableSelectAll) {
      const multiSelectAll = () => {
        let viewableData = [];
        if (tableProps.tableRef.current) {
          const table = tableProps.tableRef.current;
          const state = table.getResolvedState();
          const sortedData = cloneDeep(state.sortedData);
          if (sortedData.length > 0) {
            const offset = state.page * state.pageSize;
            viewableData = sortedData.splice(offset, offset + state.pageSize);
          }
        }
        let selectableData = [];

        if (accessor === "orderNo") {
          selectableData = viewableData.filter((d) => utils.isEditableOrder(d));
        } else {
          selectableData = viewableData.filter((d) => utils.hasNonEmptyValue(get(d, accessor)));
        }
        const selectableRowIds = utils.getValuesByProperty(selectableData, accessor);

        return utils.hasNonEmptyValue(selectableData) ? (
          <StyledCheck
            checked={!selectableRowIds.some((id) => !selectedRowIds.includes(id))}
            onChange={(event) => {
              tableProps.onSelectedBondsChange(
                event.target.checked,
                selectableData.map((d) => d._original),
                -1,
                accessor,
              );
            }}
          />
        ) : null;
      };

      if (tableProps.showFilterbar) {
        columnConfig.Filter = multiSelectAll;
      } else {
        columnConfig.Header = multiSelectAll;
      }
    }

    if (accessor === "orderNo") {
      columnConfig.Cell = (props) => {
        return utils.isEditableOrder(props.original) ? (
          <StyledCheck
            checked={selectedRowIds.includes(props.value)}
            onChange={(event) => {
              tableProps.onSelectedBondsChange(
                event.target.checked,
                [props.original],
                props.viewIndex,
                accessor,
              );
            }}
            value={props.value}
          />
        ) : null;
      };
    } else {
      columnConfig.Cell = (props) => {
        if (!props.original || !utils.hasNonEmptyValue(props.original[accessor])) {
          return null;
        }
        const isIssuerBond = props.original.hasOwnProperty("issuerInventory");

        if (!isIssuerBond) {
          return (
            <StyledCheck
              checked={selectedRowIds.includes(props.value)}
              onChange={(event) => {
                tableProps.onSelectedBondsChange(
                  event.target.checked,
                  [props.original],
                  props.viewIndex,
                  accessor,
                );
              }}
              value={props.value}
            />
          );
        } else {
          return (
            <StyledCheck
              disabled={props.original.inventorySize === 0}
              checked={
                props.original.inventorySize > 0 &&
                props.original.issuerInventory.every((b) =>
                  tableProps.selectedBonds.some((selected) => selected.cusip === b.cusip),
                )
              }
              onChange={(event) => {
                tableProps.onSelectedBondsChange(
                  event.target.checked,
                  [props.original],
                  props.viewIndex,
                  accessor,
                );
              }}
              value={props.value}
            />
          );
        }
      };
    }
  };

  /**
   * Consumes headerbadge object settable in datatableconfig.js column section
   *
   * MYCOLUMN: {
   *   column: {
   *   headerbadge : {
   *      tooltip: "Message to user",
   *      icon: ICON_CONSTANT,
   *    }
   *  }
   */

  const getHeader = (tableProps, accessor, header, sortable, tooltip, columnBadge) => {
    const isSorted = utils.hasNonEmptyValue(tableProps.sorted) && tableProps.sorted[0].id === accessor;
    let headerComp = <div>{header}</div>;

    if (tooltip) {
      headerComp = <StyledSecondaryTooltip title={tooltip}>{headerComp}</StyledSecondaryTooltip>;
    }

    return (cellProps) => (
      <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
        {headerComp}
        <TooltipBadge {...columnBadge} />
        {sortable && utils.hasNonEmptyValue(accessor) && utils.hasNonEmptyValue(header) && (
          <div style={{ display: "flex", flexDirection: "column" }}>
            <SortAscIcon
              className="sortAsc"
              color={isSorted && !tableProps.sorted[0].desc ? "primary" : "disabled"}
            />
            <SortDescIcon
              className="sortDesc"
              color={isSorted && tableProps.sorted[0].desc ? "primary" : "disabled"}
            />
          </div>
        )}
      </div>
    );
  };

  const getColumnConfig = (column, tableProps, columnIndex) => {
    const columnProps = column.column;

    const accessor = columnProps.accessor;
    const propertyConfig = get(DataConfig.PROPERTY, accessor, {});

    const header = columnProps.hasOwnProperty("header")
      ? columnProps.header
      : propertyConfig
      ? propertyConfig.header || propertyConfig.label
      : "";

    let sortable = false;

    if (tableProps.sortable) {
      sortable = get(columnProps, "sortable", true);
    }

    let exportable = get(columnProps, "exportable", true);

    const filterProps = { ...(propertyConfig.filter || {}), ...(column.filter || {}) };
    let filterable =
      tableProps.filterable &&
      utils.hasNonEmptyValue(filterProps) &&
      filterProps.type !== Constants.FILTER_TYPE.NONE;

    let columnConfig = {
      headerText: header,
      headerClassName: columnProps.className,
      headerStyle: columnProps.headerStyle,
      accessor: columnProps.accessor,
      minWidth:
        filterable || tableProps.useFilterWidth
          ? columnProps.width
          : columnProps.nofilterWidth || columnProps.width,
      maxWidth:
        filterable || tableProps.useFilterWidth
          ? columnProps.width
          : columnProps.nofilterWidth || columnProps.width,
      exportWidth: get(columnProps, "exportWidth"),
      sortable: sortable,
      filterable: filterable,
      exportable: exportable,
      static: get(columnProps, "static", false),
      expander: get(columnProps, "expander", false),
      disableSelectAll: get(columnProps, "disableSelectAll", false),
    };

    if (columnProps.maxWidth) {
      columnConfig.maxWidth = columnProps.maxWidth;
    }

    if (sortable && propertyConfig.format === Constants.FORMAT_TYPE.DATE) {
      columnConfig.sortMethod = utils.sortDates;
    }

    if (columnProps.type === Constants.CELL_TYPE.MULTISELECT) {
      appendMultiselectColumn(columnConfig, tableProps);
    } else if (columnProps.type === Constants.CELL_TYPE.FILTER_TOGGLE) {
      appendToggleFilterHeaderConfig(columnConfig, tableProps, columnProps);
    } else if (columnProps.type === Constants.CELL_TYPE.ADVANCED_CRITERIA) {
      appendAdvancedCriteriaHeaderConfig(columnConfig, tableProps, columnProps);
    } else {
      columnConfig.Header = getHeader(
        tableProps,
        columnProps.accessor,
        header,
        sortable,
        propertyConfig.tooltip,
        columnProps.headerbadge,
      );
    }

    if (columnProps.expander) {
      columnConfig.Expander = (props) => {
        if (columnProps.expanderType === "BIDS") {
          const numBids = get(props.original, "bids", []).length;

          const expandable =
            numBids > 1 || (numBids === 1 && !utils.hasNonEmptyValue(props.original.highBid));
          return (
            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              <div style={{ marginRight: 2 }}>
                {expandable && props.isExpanded && <span>&#x229f;</span>}
                {expandable && !props.isExpanded && (
                  <StyledTooltip title={"Click to see all bids"}>
                    <span>&#x229e;</span>
                  </StyledTooltip>
                )}
              </div>
              <div>{numBids}</div>
            </div>
          );
        } else {
          if (props.original.brokeredsecurityid && get(props.original, "numSimilarCusips", -1) > 0) {
            return (
              <div>
                {props.isExpanded && <span>&#x229f;</span>}
                {!props.isExpanded && (
                  <StyledTooltip
                    title={`${props.original.numSimilarCusips} ${
                      props.original.numSimilarCusips === 1
                        ? Messages.TOOLTIP.ADDITIONAL_OFFERING
                        : Messages.TOOLTIP.ADDITIONAL_OFFERINGS
                    }`}
                  >
                    <span>&#x229e;</span>
                  </StyledTooltip>
                )}
              </div>
            );
          } else if (get(props.original, "inventorySize", -1) > 0) {
            return (
              <div>
                {props.isExpanded && <span>&#x229f;</span>}
                {!props.isExpanded && (
                  <StyledTooltip
                    title={`${props.original.inventorySize} ${
                      props.original.inventorySize === 1
                        ? Messages.TOOLTIP.OFFERING_FROM
                        : Messages.TOOLTIP.OFFERINGS_FROM
                    } ${props.original.issuer}`}
                  >
                    <span>&#x229e;</span>
                  </StyledTooltip>
                )}
              </div>
            );
          }
        }

        return null;
      };
    }

    if (columnConfig.filterable) {
      switch (filterProps.type) {
        case Constants.FILTER_TYPE.RANGE:
          appendRangeFilterConfig(columnConfig, tableProps, columnProps, filterProps);
          break;
        case Constants.FILTER_TYPE.DUALRANGE:
          appendDualRangeFilterConfig(columnConfig, tableProps, columnProps, filterProps);
          break;
        case Constants.FILTER_TYPE.SELECTRATING:
          appendSelectRatingFilterConfig(columnConfig, tableProps, columnProps, filterProps);
          break;
        case Constants.FILTER_TYPE.DATERANGE:
          appendDateRangeFilterConfig(columnConfig, tableProps, columnProps, filterProps);
          break;
        case Constants.FILTER_TYPE.KEYWORD:
        case Constants.FILTER_TYPE.KEYWORD_WILDCARD:
          appendKeywordFilterConfig(columnConfig, tableProps, columnProps, filterProps);
          break;
        case Constants.FILTER_TYPE.CHECKBOX:
          appendCheckboxFilterConfig(columnConfig, tableProps, columnProps, filterProps);
          break;
        case Constants.FILTER_TYPE.ADVANCED:
          appendAdvancedFilterConfig(columnConfig, tableProps, columnProps, filterProps);
          break;
        case Constants.FILTER_TYPE.CLEAR:
          appendClearFilterConfig(columnConfig, tableProps, columnProps);
          break;
        case Constants.FILTER_TYPE.CUSTOM:
          break;
        default:
          columnConfig.filterable = false;
          break;
      }
    }

    const dataCellProps = column.datacell;

    if (utils.hasNonEmptyValue(dataCellProps)) {
      columnConfig.className = dataCellProps.className;

      switch (dataCellProps.type) {
        case Constants.CELL_TYPE.RATES_ROW_HEADER:
          columnConfig.className = "rowheader";
          columnConfig.headerClassName = "controlCell";
          columnConfig.Cell = (props) => {
            if (!props.value) {
              return null;
            }
            const accessor = props.column.id.split(".")[0];
            const queryString = props.original[accessor].queryString;
            const url = props.original[accessor].link;
            const formattedValue = utils.formatValue(props.value, props.column.id, props.original);
            if (utils.hasNonEmptyValue(url)) {
              return <div className="clickable">{utils.formatHyperlink(url, formattedValue)}</div>;
            } else if (utils.hasNonEmptyValue(queryString)) {
              return (
                <div className="clickable" onClick={(e) => tableProps.onCellNavigation(queryString)}>
                  {formattedValue}
                </div>
              );
            } else {
              return props.value;
            }
          };
          break;
        // case Constants.CELL_TYPE.HEAT_MAP:
        //    columnConfig.className = 'heatMapCell';
        //    columnConfig.Cell = (props) => {
        //       const maxValue = props.original.maxHeatValue;
        //       const shadeFactor = 100 / maxValue;
        //       let opacity = isNaN(props.value) ? 0 : (Math.abs(props.value) * shadeFactor) / 100;
        //       let hue = Number(props.value) < 0 ? '183, 23, 23' : '42, 121, 165';
        //       const style = {
        //          backgroundColor: `rgba(${hue}, ${opacity})`
        //       };
        //       return (<div onClick={e => tableProps.onCellNavigation(props.column.id, props.original)} style={style}>{utils.formatNumber(props.value, 2, true)}</div>);
        //    }
        //    break;
        case Constants.CELL_TYPE.CLICKABLE:
          columnConfig.className = "centeredCell";
          columnConfig.Cell = (props) => {
            if (!props.value) {
              return null;
            }
            const accessor = props.column.id.split(".")[0];
            const queryString = props.original[accessor].queryString;
            const valueChanged = props.original[accessor].valueChanged;
            const url = props.original[accessor].link;
            const formattedValue = utils.formatValue(props.value, props.column.id, props.original);
            if (utils.hasNonEmptyValue(url)) {
              return (
                <div className={`clickable ${valueChanged ? "changedValue" : ""}`}>
                  {utils.formatHyperlink(url, formattedValue)}
                </div>
              );
            } else if (utils.hasNonEmptyValue(queryString)) {
              return (
                <div
                  className={`clickable ${valueChanged ? "changedValue" : ""}`}
                  onClick={(e) => tableProps.onCellNavigation(queryString)}
                >
                  {formattedValue}
                </div>
              );
            } else {
              return props.value;
            }
          };
          break;
        case Constants.CELL_TYPE.BUY:
          columnConfig.Cell = (props) => {
            if (!props.original.id) {
              return null;
            }
            return (
              <BuyButton
                disabled={!props.original.inInventory}
                onClick={tableProps.onAddBondsToCart}
                onCartClick={tableProps.onOpenTradeTicket}
                bond={props.original}
              />
            );
          };
          break;
        case Constants.CELL_TYPE.FAVORITE:
          columnConfig.Cell = (props) => {
            if (!props.original[props.column.id] || props.original.cusip === Messages.LABEL.UNKNOWN) {
              return null;
            }
            return <Favorite bond={props.original} />;
          };
          break;
        case Constants.CELL_TYPE.FAVORITE_ISSUER:
          columnConfig.Cell = (props) => {
            if (!props.original[props.column.id]) {
              return null;
            }
            return <FavoriteIssuer bond={props.original} />;
          };
          break;
        case Constants.CELL_TYPE.STATUS:
          columnConfig.Cell = (props) => {
            if (!props.value) {
              return null;
            }
            const value = utils.formatValue(props.value, columnProps.accessor, props.original);
            const title = get(props.original, "userRequestEdit")
              ? Messages.TOOLTIP.EDIT_REQUEST_PENDING
              : get(props.original, "userRequestCancel")
              ? Messages.TOOLTIP.CANCEL_REQUEST_PENDING
              : null;

            return utils.hasNonEmptyValue(title) && utils.hasNonEmptyValue(value) ? (
              <div>
                {value}
                <StyledTooltip title={title}>
                  <StyledInfoIcon />
                </StyledTooltip>
              </div>
            ) : (
              value
            );
          };
          break;
        case Constants.CELL_TYPE.INSURANCE_NUMBER:
          columnConfig.Cell = (props) => {
            if (!props.value) {
              return null;
            }
            const value = utils.formatValue(props.value, columnProps.accessor, props.original);
            const hasHoldings = props.original.hasHoldings;
            const color =
              props.original.hasOwnProperty("warnings") && props.original.warnings.includes(props.column.id)
                ? "warning"
                : undefined;
            return hasHoldings ? <Holdings label={value} bond={props.original} color={color} /> : value;
          };
          break;
        case Constants.CELL_TYPE.ISSUER:
          columnConfig.Cell = (props) => {
            if (!props.value) {
              return null;
            }
            const value = utils.formatValue(props.value, props.column.id, props.original);
            let toolTipTitle = null;
            if (dataCellProps.hasOwnProperty("tooltip")) {
              let toolTipProps = dataCellProps.tooltip;
              if (toolTipProps.hasOwnProperty("accessor")) {
                toolTipTitle = get(props.original, toolTipProps.accessor);
              } else if (toolTipProps.hasOwnProperty("title")) {
                toolTipTitle = toolTipProps.title;
              }
            }
            if (
              value &&
              props.original.taxable &&
              props.original.instrumentCategory === Constants.INVENTORY_TYPE.MUNICIPAL
            ) {
              return (
                <div className="indicated">
                  <StyledTooltip title={Messages.TOOLTIP.TAXABLE}>
                    <span>
                      <StyledTaxableIcon />
                    </span>
                  </StyledTooltip>
                  {value}
                </div>
              );
            } else if (toolTipTitle !== null) {
              return (
                <StyledTooltip title={toolTipTitle}>
                  <span>{value}</span>
                </StyledTooltip>
              );
            } else {
              return value || "";
            }
          };
          break;
        case Constants.CELL_TYPE.ISSUER_RESPONSIVE:
          columnConfig.Cell = (props) => {
            if (props.value === Constants.RESPONSE.LOADING) {
              return <span className="info">{Messages.LABEL.SEARCHING}</span>;
            } else if (props.value === Constants.RESPONSE.ISSUER_NOT_FOUND) {
              return <span className="error">{Messages.LABEL.ISSUER_NOT_FOUND}</span>;
            } else if (props.value === Constants.RESPONSE.NOT_FOUND) {
              return <span className="error">{Messages.LABEL.CUSIP_NOT_FOUND}</span>;
            } else if (props.value === Constants.RESPONSE.INVALID) {
              return <span className="error">{Messages.LABEL.CUSIP_INVALID}</span>;
            } else {
              const value = utils.formatValue(props.value, props.column.id, props.original);
              if (
                props.value &&
                props.original.taxable &&
                props.original.instrumentCategory === Constants.INVENTORY_TYPE.MUNICIPAL
              ) {
                return (
                  <div className="indicated">
                    <StyledTooltip title={Messages.TOOLTIP.TAXABLE}>
                      <span>
                        <StyledTaxableIcon />
                      </span>
                    </StyledTooltip>
                    {value}
                  </div>
                );
              } else {
                return value || "";
              }
            }
          };
          break;
        case Constants.CELL_TYPE.DELETE:
          if (tableProps.editable) {
            columnConfig.Cell = (props) => {
              if (props.original.deleteDisabled) return null;

              return (
                <StyledDeleteIconButton
                  disableRipple
                  disableFocusRipple
                  onClick={() => tableProps.onValueChange(props)}
                >
                  <StyledDeleteIcon color="error" />
                </StyledDeleteIconButton>
              );
            };
          } else {
            columnConfig.width = 0;
          }
          break;
        case Constants.CELL_TYPE.CHECKBOX:
          columnConfig.className = "checkCell";
          if (tableProps.editable) {
            columnConfig.Cell = (props) => {
              return (
                <StyledCheck
                  checked={!!props.value}
                  onChange={(e) => {
                    tableProps.onValueChange(props, e.target.checked);
                  }}
                />
              );
            };
          }
          break;
        case Constants.CELL_TYPE.LINK_BUTTON:
          if (tableProps.editable) {
            columnConfig.className = "checkCell";
            columnConfig.Cell = (props) => {
              if (!props.original[props.column.id]) {
                return null;
              }
              return (
                <LinkButton
                  label={dataCellProps.label}
                  style={{ padding: 0, minWidth: 40 }}
                  onClick={(e) => tableProps.onValueChange(props)}
                />
              );
            };
          }
          break;
        case Constants.CELL_TYPE.EDITABLE:
          if (tableProps.editable) {
            columnConfig.className = dataCellProps.editClassName;
            columnConfig.Cell = (props) => {
              if (props.value === Constants.RESPONSE.LOADING) {
                return (
                  <div style={{ display: "flex", textAlign: "center", justifyContent: "center" }}>
                    <CircularProgress size={14} color="secondary" />
                  </div>
                );
              }
              if (props.column.id === "yieldToWorst" && !props.original.callable) {
                return utils.formatValue(props.value, props.column.id, props.original) ?? null;
              }

              const key = `${props.column.id}_${props.index}`;
              const value =
                props.value === Constants.RESPONSE.LOADING || props.value === Constants.RESPONSE.DISABLED
                  ? " -- "
                  : utils.formatValue(props.value, props.column.id, props.original);
              return (
                <React.Fragment>
                  <TextInput
                    tabIndex={props.index * tableProps.columns.length + columnIndex + 1}
                    id={key}
                    key={key}
                    maxLength={dataCellProps.maxLength}
                    autoFocus={tableProps.focusedElementId === key}
                    warning={
                      props.original.hasOwnProperty("warnings") &&
                      props.original.warnings.includes(props.column.id)
                    }
                    error={
                      props.original.hasOwnProperty("errors") &&
                      props.original.errors.includes(props.column.id)
                    }
                    disabled={
                      props.value === Constants.RESPONSE.LOADING ||
                      props.value === Constants.RESPONSE.DISABLED
                    }
                    defaultValue={value}
                    startAdornment={dataCellProps.startAdornment}
                    placeholder={dataCellProps.placeholder}
                    onBlur={(event) => {
                      tableProps.onValueChange(props, event.target.value, event.relatedTarget);
                    }}
                    onKeyPress={(event) => {
                      if (event.charCode === 13) {
                        tableProps.onValueChange(props, event.target.value);
                      }
                    }}
                  />
                </React.Fragment>
              );
            };
          }
          break;
        case Constants.CELL_TYPE.TOGGLE_BUTTONS:
          if (tableProps.editable) {
            columnConfig.className = dataCellProps.editClassName;
            columnConfig.Cell = (props) => {
              return (
                <StyledButtonGroup variant="outlined" color="primary" size="small">
                  <Button
                    color="primary"
                    key={Constants.ACTOR_TYPE.BUYER}
                    disabled={props.value === Constants.ACTOR_TYPE.BUYER}
                    onClick={(event) => tableProps.onValueChange(props, Constants.ACTOR_TYPE.BUYER)}
                  >
                    {Messages.LABEL.BUYER}
                  </Button>
                  <Button
                    color="primary"
                    key={Constants.ACTOR_TYPE.SELLER}
                    disabled={props.value === Constants.ACTOR_TYPE.SELLER}
                    onClick={(event) => tableProps.onValueChange(props, Constants.ACTOR_TYPE.SELLER)}
                  >
                    {Messages.LABEL.SELLER}
                  </Button>
                </StyledButtonGroup>
              );
            };
          } else {
            columnConfig.Cell = (props) =>
              props.value === Constants.ACTOR_TYPE.BUYER ? Messages.LABEL.BUYER : Messages.LABEL.SELLER;
          }
          break;
        case Constants.CELL_TYPE.SHOW_ALERT:
          columnConfig.Cell = (props) => {
            return props.value ? "!" : "";
          };
          break;
        case Constants.CELL_TYPE.DOCUMENTS:
          columnConfig.Cell = (props) => {
            const documents = props.original.links;
            if (!documents.length)
              return (
                <LinkButton
                  label={Messages.LABEL.UPLOAD}
                  style={{ padding: 0 }}
                  onClick={() => tableProps.onOpenDocumentsModal({ order: props.original })}
                />
              );
            return (
              <StyledTooltip title={`Click to view document${documents.length > 1 ? "s" : ""}`}>
                <DocumentBadge
                  badgeContent={documents.length}
                  onClick={() => tableProps.onOpenDocumentsModal({ order: props.original })}
                >
                  <StyledIconButton>
                    <StyledDocumentIcon color="primary" />
                  </StyledIconButton>
                </DocumentBadge>
              </StyledTooltip>
            );
          };
          break;
        case Constants.CELL_TYPE.FILE:
          columnConfig.Cell = (props) => {
            return <DocumentDownload {...props.value} title="Click to Download" />;
          };
          break;
        case Constants.CELL_TYPE.SELL_SIDE_ACTION_BUTTON:
          columnConfig.Cell = (props) => {
            const links = [];
            if (props.value) {
              props.value.forEach((obj) => {
                if (obj.rel === "edit") {
                  links.unshift(
                    <div key={`${obj.title}_${props.original.id}`} style={{ margin: "auto" }}>
                      <StyledTooltip title="Edit Offer">
                        <StyledIconButton
                          disableRipple
                          disableFocusRipple
                          onClick={() => tableProps.onOpenRequestTicket([props.original])}
                        >
                          <StyledEditIcon />
                        </StyledIconButton>
                      </StyledTooltip>
                    </div>,
                  );
                }

                if (obj.rel === "action") {
                  let confirmationModal = null;
                  let icon = null;

                  if (obj.title.toLowerCase() === "request edit") {
                    icon = RequestEditIcon;
                    confirmationModal = { Component: RequestEditConfirmation };
                  } else if (obj.title.toLowerCase() === "request cancel") {
                    icon = StyledCancelIcon;
                    confirmationModal = { Component: RequestCancelConfirmation };
                  } else if (obj.title.toLowerCase() === "review") {
                    confirmationModal = { Component: ReviewQuote };
                  } else if (obj.title.toLowerCase() === "cancel") {
                    confirmationModal = {
                      Component: SimpleConfirmation,
                      message: Messages.MESSAGE.ACTUAL_CANCEL_WARNING,
                    };
                    icon = StyledCancelIcon;
                  } else if (obj.title.toLowerCase() === "decline") {
                    confirmationModal = {
                      Component: SimpleConfirmation,
                      message: Messages.MESSAGE.SELL_BIDS_DECLINE_WARNING,
                    };
                  } else if (obj.title.toLowerCase() === "sell") {
                    confirmationModal = {
                      Component: SimpleConfirmation,
                      message: Messages.MESSAGE.SELL_BIDS_SELL_WARNING,
                    };
                  }

                  links.push(
                    <div key={`${obj.title}_${props.original.id}`} style={{ marginRight: 2, marginLeft: 2 }}>
                      <SellSideSimpleActionButton
                        url={obj.href}
                        label={obj.title}
                        action={obj.title.toLowerCase()}
                        orders={[props.original]}
                        onActionStatusChange={tableProps.onValueChange}
                        variant={obj.title.toLowerCase() === "unreview" ? "outlined" : "filled"}
                        confirmationModal={confirmationModal}
                        Icon={icon}
                      />
                    </div>,
                  );
                }
              });
            }
            return <div style={{ display: "flex", justifyContent: "space-evenly" }}>{links}</div>;
          };
          break;
        case Constants.CELL_TYPE.ACTIVE_BIDS:
          columnConfig.Cell = (props) => {
            if (!props.value) {
              return null;
            }

            const endTime = moment.tz(props.value, "America/New_York").toDate();
            return <CountdownClock bond={props.original} endTime={endTime} />;
          };
          break;
        default:
          break;
      }
    }

    if (!columnConfig.hasOwnProperty("Cell")) {
      columnConfig.Cell = (props) => {
        let value;

        if (dataCellProps && dataCellProps.accessor) {
          value = utils.formatValue(
            get(props.original, dataCellProps.accessor),
            dataCellProps.accessor,
            props.original,
          );
        } else {
          value = utils.formatValue(props.value, columnProps.accessor, props.original);
        }
        value =
          props.value === Constants.RESPONSE.LOADING || props.value === Constants.RESPONSE.DISABLED
            ? "--"
            : value;
        if (props.original.hasOwnProperty("errors") && props.original.errors.includes(props.column.id)) {
          value = <span className="error">{value}</span>;
        } else if (
          props.original.hasOwnProperty("warnings") &&
          props.original.warnings.includes(props.column.id)
        ) {
          value = <span className="warning">{value}</span>;
        } else if (props.original.hasOwnProperty("noted") && props.original.noted.includes(props.column.id)) {
          value = <span className="info">{value}</span>;
        } else {
          value = value || "";
        }

        if (dataCellProps && dataCellProps.tooltip && value !== "") {
          let title = dataCellProps.tooltip.accessor
            ? get(props.original, dataCellProps.tooltip.accessor)
            : dataCellProps.tooltip.title;

          if (dataCellProps.tooltip.valueFilter && !dataCellProps.tooltip.valueFilter.includes(props.value)) {
            title = null;
          }
          const placement = dataCellProps.tooltip.placement || "bottom";
          const Tooltip =
            dataCellProps.tooltip.color === "secondary" ? StyledSecondaryTooltip : StyledTooltip;
          const Icon = dataCellProps.tooltip.color === "secondary" ? StyledSecondaryInfoIcon : StyledInfoIcon;

          return utils.hasNonEmptyValue(title) ? (
            <div>
              {value}
              <Tooltip placement={placement} title={title}>
                <Icon />
              </Tooltip>
            </div>
          ) : (
            value || ""
          );
        } else {
          return value || "";
        }
      };
    }

    const footerProps = column.footer;

    if (tableProps.showFooter && footerProps) {
      columnConfig.footerClassName = footerProps.className;
      columnConfig.Footer = footerProps.footer;

      if (footerProps.type === Constants.CELL_TYPE.TOTAL) {
        columnConfig.Footer = (props) => {
          const hasNAValue = props.data.some((d) => get(d, columnConfig.accessor) === "N/A");
          let value = hasNAValue
            ? "N/A"
            : sum(
                map(props.data, (d) => {
                  let val = get(d, columnConfig.accessor);
                  if (isNaN(val)) {
                    return 0;
                  }
                  return Number(val);
                }),
              );

          if (value === "N/A") {
            return (
              <span className={columnConfig.footerClassName}>
                <span className="warning">N/A</span>
              </span>
            );
          }
          value = utils.formatValue(value, columnConfig.accessor, props.original);

          return <span className={columnConfig.footerClassName}>{value}</span>;
        };
      }
    }

    return columnConfig;
  };

  const getBaseTableConfig = ({ tableType, tableSubType, instrumentCategory }) => {
    const [DEFAULT_TABLE, SUBTYPED_TABLE, INVENTORY_TABLE] = new DataTableConfig(Messages);
    let tableConfig;

    if (!instrumentCategory) {
      tableConfig = DEFAULT_TABLE[tableType];
      if (
        tableSubType &&
        SUBTYPED_TABLE.hasOwnProperty(tableType) &&
        SUBTYPED_TABLE[tableType].hasOwnProperty(tableSubType)
      ) {
        tableConfig = {
          ...DEFAULT_TABLE[tableType],
          ...SUBTYPED_TABLE[tableType][tableSubType],
        };
      }
    } else {
      const inventoryKey = utils.getInventoryKeyFromType(instrumentCategory);
      tableConfig = {
        ...INVENTORY_TABLE[inventoryKey]["DEFAULT"],
        ...INVENTORY_TABLE[inventoryKey][tableType],
      };
      tableConfig = { ...DEFAULT_TABLE[tableType], ...tableConfig };
    }

    if (tableConfig.appendedColumns) {
      tableConfig.columns = tableConfig.columns.concat(tableConfig.appendedColumns);
    }

    if (tableConfig.prependedColumns) {
      tableConfig.columns = tableConfig.prependedColumns.concat(tableConfig.columns);
    }

    if (utils.hasNonEmptyValue(tableSubType) && utils.hasNonEmptyValue(tableConfig.subTypeFilterKey)) {
      let filter = {};
      filter[tableConfig.subTypeFilterKey] = tableSubType;
      tableConfig.baseFilter = filter;
    }

    return tableConfig;
  };

  const getTableConfig = (props) => {
    const tableConfig = getBaseTableConfig(props);

    // table props, a merge of table config and provided props; only of interest to config helper to generate columns
    const tableProps = { ...tableConfig, ...props };

    let columns = [];

    if (utils.hasNonEmptyValue(tableConfig.columns)) {
      const columnsToConfig =
        props.alternateView && utils.hasNonEmptyValue(tableConfig.alternateViewColumns)
          ? tableConfig.alternateViewColumns
          : tableConfig.columns;

      // turns config json into column objects
      columns = columnsToConfig.map((column, i) => {
        return getColumnConfig(column, tableProps, i);
      });

      tableConfig.multiselect = columnsToConfig.find(
        (c) => c.column.type === Constants.CELL_TYPE.MULTISELECT,
      );
    }

    tableConfig.tableColumns = columns;

    if (tableConfig.FDICFilter) {
      tableConfig.FDICFilter = get(DataConfig.PROPERTY, tableConfig.FDICFilter.accessor, {});
    }

    tableConfig.expandable = tableConfig.hasOwnProperty("expandable")
      ? tableConfig.expandable
      : columns.some((col) => col.expander);

    if (!tableConfig.expandable) {
      tableConfig.tableColumns = tableConfig.tableColumns.filter((col) => !col.expander);
    }

    return tableConfig;
  };

  const getDefaultFilter = (tableConfig) => {
    return { ...(tableConfig.baseFilter || {}), ...(tableConfig.defaultFilter || {}) };
  };

  return [getDefaultFilter, getTableConfig];
};

export default ConfigHelper;
