import React from "react";
import { useGlobal } from "reactn";
import { replace } from "lodash";
import { CSSTransition } from "react-transition-group";
import PropTypes from "prop-types";
import { getUser } from "commons/helpers/userStorage";

import * as utils from "commons/utils";
import * as Constants from "commons/constants";
import { useMessages } from "providers/BrandingProvider";
import { TextAreaInput } from "components/Controls";
import { Div } from "components/containers/Containers";
import * as ticketUtils from "./tickets/ticketUtils";
import { ModalImportCusipButton } from "./common/FormComponents";
import ModalMessages from "./common/ModalMessages";
import { TicketBody, TicketRow, TicketRowSection, TicketButton, Spacer } from "./tickets/TicketComponents";
import DataTable from "components/datatable/DataTable";
import QBTypography from "components/QBTypography";
import { ClosableModal } from "components/containers/Modals";
import { useDataService } from "services/DataService";
import { addFavorites } from "services/FavoritesService";

import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: 200,
    minWidth: 662,
    marginTop: 10,
  },
  instructionsWrapper: {
    display: "flex",
    flexDirection: "column",
    paddingLeft: 20,
  },
  instructions: {
    flexGrow: 1,
    textAlign: "left",
    marginBottom: 10,
  },
  instruction: {
    color: theme.palette.text.primary,
  },
  buttonRow: {
    flexGrow: 0,
    textAlign: "right",
  },
  tableWrapper: {
    margin: "auto 0",
    minWidth: 600,
  },
  importButton: {
    marginBottom: "0.5rem",
    width: "100%",
    textAlign: "right",
  },
}));

const ref = React.createRef();

const lockModalHeight = () => {
  if (ref.current) {
    ref.current.style["min-height"] = ref.current.clientHeight + "px";
    ref.current.style["max-height"] = ref.current.clientHeight + "px";
    ref.current.style["overflow-y"] = "hidden";
  }
};

const unlockModalHeight = () => {
  if (ref.current) {
    ref.current.style["min-height"] = "inherit";
    ref.current.style["max-height"] = "inherit";
    ref.current.style["overflow-y"] = "auto";
  }
};

const ImportFavorites = ({ open, onCloseModal }) => {
  const classes = useStyles();
  const Messages = useMessages();
  const [favorites = []] = useGlobal("favorites");
  const [userData, setUserData] = React.useState("");
  const [modalStatus, setModalStatus] = React.useState(ticketUtils.TICKET_STATUS.IMPORT);
  const [stagedStatus, setStagedStatus] = React.useState(ticketUtils.TICKET_STATUS.IMPORT);

  const [infoMessages, setInfoMessages] = React.useState([]);
  const [warningMessages, setWarningMessages] = React.useState([]);
  const [errorMessages, setErrorMessages] = React.useState([]);

  const [state, queryDataService] = useDataService();

  const [data, setData] = React.useState([]);
  const user = getUser();

  React.useEffect(() => {
    resetErrorState();
    setModalStatus(ticketUtils.TICKET_STATUS.IMPORT);
    setStagedStatus(ticketUtils.TICKET_STATUS.IMPORT);
    setData([]);
    setUserData("");

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  React.useEffect(() => {
    handleCusipLookup(state);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.data]);

  const getValidCusips = () => {
    return data.filter((d) => d.id > -1 && utils.isValidCusip(d.cusip) && !d.duplicate).map((d) => d.cusip);
  };

  const handleCloseModal = (e, category) => {
    category = category === "backdropClick" ? null : category;
    onCloseModal(category);
    unlockModalHeight();
  };

  const resetErrorState = () => {
    infoMessages.splice(0, infoMessages.length);
    setInfoMessages(infoMessages);
    warningMessages.splice(0, warningMessages.length);
    setWarningMessages(warningMessages);
    errorMessages.splice(0, errorMessages.length);
    setErrorMessages(errorMessages);
  };

  const handleSubmit = (e) => {
    if (user[Constants.USER_PERMISSIONS.CAN_USE_FAVORITES_AND_TRACKERS]) {
      const filteredData = data.filter(
        (datum) => !datum.duplicate && utils.hasNonEmptyValue(datum.cusip.trim()),
      );

      resetErrorState();
      let category;

      if (utils.hasNonEmptyValue(filteredData)) {
        const favoritesToAdd = filteredData.map((d) => d.cusip);
        addFavorites(favoritesToAdd);
        const key = utils.getInventoryKeyFromInstrumentType(filteredData[0].instrumentType.description);
        if (key) {
          category = Constants.INVENTORY_TYPE[key];
        }
      }
      handleCloseModal(e, category);
    } else {
      utils.issueWarningAlert(Messages.MESSAGE.MUST_BE_A_TRADER_FEATURE);
    }
  };

  const handleValueChange = (props, value) => {
    const accessor = props.column.id;

    if (accessor === "toggleImport") {
      toggleImport();
    } else if (accessor === "delete") {
      data.splice(props.index, 1);
      validateAndSetData(data);
    }
  };

  const setStatus = () => {
    setModalStatus(stagedStatus);
    unlockModalHeight();
  };

  const toggleImport = () => {
    resetErrorState();
    if (modalStatus !== ticketUtils.TICKET_STATUS.IMPORT) {
      lockModalHeight();
      setStagedStatus(ticketUtils.TICKET_STATUS.IMPORT);
    } else {
      setStagedStatus(ticketUtils.TICKET_STATUS.PREVIEW);
      validateAndSetData(data);
    }
  };

  const lookupCusips = (queryData) => {
    queryData.some((datum, i) => {
      if (utils.isValidCusip(datum.cusip) && datum.id < 0 && !utils.hasNonEmptyValue(datum.issuer)) {
        const updatedRec = {
          id: -1,
          issuer: Constants.RESPONSE.LOADING,
          cusip: datum.cusip,
        };
        queryData[i] = updatedRec;
        const queryParams = {
          type: Constants.QUERY_TYPE.CUSIP_SEARCH,
          identifier: {
            filterKey: "cusip",
            filterValue: datum.cusip.trim(),
          },
          clearDataOnFetch: false,
        };

        window.setTimeout(() => queryDataService(queryParams), 10);

        return true;
      }

      return false;
    });

    validateAndSetData(queryData);
  };

  const handleCusipLookup = (state) => {
    if (utils.hasNonEmptyValue(state.query)) {
      if (state.isError) {
        // gonna need to parse for specific 'cusip not found' error
        const affectedRecs = utils.findInArray(
          data,
          state.query.identifier.filterKey,
          state.query.identifier.filterValue,
        );
        affectedRecs.forEach((rec) => {
          rec.issuer = Constants.RESPONSE.NOT_FOUND;
        });
      } else {
        var cusips = favorites.map((f) => f.cusip.toLowerCase());
        var responseCusip = state.query.identifier.filterValue.toLowerCase();

        data.forEach((datum, i) => {
          if (datum.id < 0 && responseCusip === datum.cusip.toLowerCase()) {
            if (utils.hasNonEmptyValue(state.data)) {
              datum.duplicate = cusips.includes(responseCusip);
              cusips.push(responseCusip);
              data[i] = { ...datum, ...state.data[0] };
            } else if (!utils.isValidCusip(datum.cusip)) {
              datum.issuer = Constants.RESPONSE.INVALID;
            } else {
              datum.issuer = Constants.RESPONSE.NOT_FOUND;
            }
          }
        });
      }
    }

    lookupCusips(data);
  };

  const validateAndSetData = (data) => {
    const msgs = [];

    data.forEach((d) => (d.errors = utils.isValidCusip(d.cusip) ? [] : ["cusip"]));

    const hasInvalid = data.some(
      (d) =>
        d.issuer === Constants.RESPONSE.INVALID ||
        d.issuer === Constants.RESPONSE.NOT_FOUND ||
        utils.hasNonEmptyValue(d.errors),
    );
    if (hasInvalid) {
      msgs.push(Messages.MESSAGE.INVALID_CUSIP);
    }

    const hasDuplicate = data.some((d) => d.duplicate);
    if (hasDuplicate) {
      msgs.push(Messages.MESSAGE.DUPLICATE_FAVORITES);
    }

    setErrorMessages(msgs);
    setData(utils.cloneArrayOfObjects(data));
  };

  const processUserData = (event) => {
    if (utils.hasNonEmptyValue(userData)) {
      const filteredData = data.filter(
        (datum) => utils.hasNonEmptyValue(datum.cusip.trim()) && !utils.hasNonEmptyValue(datum.errors),
      );

      const rows = userData.split("\n");
      let values;

      rows.map((row) => {
        row = replace(row.trim(), /,|'|'|$/g, "");
        if (utils.hasNonEmptyValue(row)) {
          values = row.split(/\b\s+(?!$)/);
          const cusip = values[0].trim();
          var rec = {
            id: -1,
            cusip: cusip,
            issuer: utils.isValidCusip(cusip) ? "" : Constants.RESPONSE.INVALID,
          };
          filteredData.push(rec);
        }

        return row;
      });

      lookupCusips(filteredData);
      setStagedStatus(ticketUtils.TICKET_STATUS.PREVIEW);
      setUserData("");
    }
  };

  const hasValidCusip = utils.hasNonEmptyValue(getValidCusips());

  const placeholderText = "CUSIP";

  // Repeat N times to demonstrate to user to enter multiple rows
  const placeholder = Array(3).fill(placeholderText).join("\n") + "\n ...";

  return (
    <ClosableModal open={open} header={Messages.LABEL.ADD_FAVORITES} onClose={handleCloseModal}>
      <Div className={classes.root}>
        <TicketBody ref={ref}>
          {modalStatus === ticketUtils.TICKET_STATUS.PREVIEW && (
            <div className={classes.importButton}>
              <ModalImportCusipButton onClick={toggleImport} label={Messages.LABEL.ENTER_MORE_CUSIPS} />
            </div>
          )}
          <ModalMessages messages={errorMessages} level={Constants.ALERT_LEVEL.ERROR} />
          <ModalMessages messages={warningMessages} level={Constants.ALERT_LEVEL.WARNING} />
          <ModalMessages messages={infoMessages} level={Constants.ALERT_LEVEL.INFO} />
          <CSSTransition
            mountOnEnter
            unmountOnExit
            timeout={{ enter: 500, exit: 100 }}
            classNames="transition-fade"
            onExited={setStatus}
            in={
              modalStatus === ticketUtils.TICKET_STATUS.IMPORT &&
              stagedStatus === ticketUtils.TICKET_STATUS.IMPORT
            }
          >
            <TicketRow>
              <TicketRowSection>
                <TextAreaInput
                  rows={11}
                  value={userData}
                  onChange={(event) => {
                    setUserData(event.currentTarget.value);
                  }}
                  placeholder={placeholder}
                />
              </TicketRowSection>
              <TicketRowSection>
                <Div className={classes.instructionsWrapper}>
                  <div className={classes.instructions}>
                    <QBTypography variant="body1">
                      Enter CUSIPs, one per line, to add to your Favorites.
                    </QBTypography>
                  </div>
                  <div className={classes.buttonRow}>
                    <TicketButton onClick={toggleImport} label={Messages.LABEL.CANCEL} />
                    <TicketButton
                      disabled={!utils.hasNonEmptyValue(userData)}
                      onClick={processUserData}
                      label={Messages.LABEL.ENTER}
                    />
                  </div>
                </Div>
              </TicketRowSection>
            </TicketRow>
          </CSSTransition>
          <CSSTransition
            mountOnEnter
            unmountOnExit
            timeout={{ enter: 500, exit: 100 }}
            classNames="transition-fade"
            onExited={setStatus}
            in={
              modalStatus === ticketUtils.TICKET_STATUS.PREVIEW &&
              stagedStatus === ticketUtils.TICKET_STATUS.PREVIEW
            }
          >
            <div className={classes.tableWrapper}>
              <DataTable
                queryType={Constants.QUERY_TYPE.FAVORITE_IMPORT}
                data={data}
                editable={true}
                selectable={false}
                onValueChange={handleValueChange}
              />
              <Spacer />
              <TicketRow>
                <TicketRowSection></TicketRowSection>
                <TicketRowSection>
                  <TicketButton onClick={handleCloseModal} label={Messages.LABEL.CANCEL} />
                  <TicketButton
                    disabled={!hasValidCusip}
                    onClick={handleSubmit}
                    label={Messages.LABEL.ADD_CUSIPS}
                    color="primary"
                  />
                </TicketRowSection>
              </TicketRow>
            </div>
          </CSSTransition>
        </TicketBody>
      </Div>
    </ClosableModal>
  );
};

ImportFavorites.propTypes = {
  open: PropTypes.bool.isRequired,
  onCloseModal: PropTypes.func.isRequired,
};

export default ImportFavorites;
