import React from "react";

import { useMessages } from "providers/BrandingProvider";
import QBTypography from "components/QBTypography";
import CusipSearch from "components/CusipSearch";
import { SearchContainer, Div } from "components/containers/Containers";
import { SidebarCard, SidebarCardContent } from "components/containers/Containers";

import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  messageWrapper: {
    margin: "auto",
    display: "flex",
    flexGrow: 1,
  },
  searchWrapper: {
    display: "flex",
    justifyContent: "center",
    flexWrap: "nowrap",
    width: "100%",
    marginTop: 4,
  },
}));

const SearchCard = () => {
  const classes = useStyles();
  const Messages = useMessages();
  return (
    <SidebarCard>
      <SidebarCardContent>
        <Div className={classes.messageWrapper}>
          <SearchContainer>
            <QBTypography variant="caption">{Messages.MESSAGE.SEARCH_BOX}</QBTypography>
            <div className={classes.searchWrapper}>
              <CusipSearch />
            </div>
          </SearchContainer>
        </Div>
      </SidebarCardContent>
    </SidebarCard>
  );
};

export default SearchCard;
