import React from "react";
import PropTypes from "prop-types";
import download from "downloadjs";
import clsx from "clsx";

import * as utils from "commons/utils";
import { useMessages } from "providers/BrandingProvider";
import HttpService from "services/commons/HttpService";
import { LinkButton, StyledTooltip, StyledIconButton, StyledSecondaryTooltip } from "components/Controls";

import CircularProgress from "@material-ui/core/CircularProgress";
import DocumentIcon from "@material-ui/icons/DescriptionOutlined";
import ImageIcon from "@material-ui/icons/CameraAlt";
import LinkIcon from "@material-ui/icons/Link";

import { withStyles, makeStyles } from "@material-ui/core/styles";

const StyledDocumentIcon = withStyles((theme) => ({
  root: {
    fontSize: "1.2rem",
  },
}))(DocumentIcon);

const StyledImageIcon = withStyles((theme) => ({
  root: {
    fontSize: "1.2rem",
  },
}))(ImageIcon);

const StyledLinkIcon = withStyles((theme) => ({
  root: {
    fontSize: "1.2rem",
  },
}))(LinkIcon);

const downloadDocument = (link, Messages, setActive = () => {}) => {
  const { title, url, fileName, authenticated, hyperlink } = link;

  if (hyperlink) {
    let newTab = window.open("/loading", title);
    if (authenticated) {
      setActive(true);

      HttpService.get(url, { timeout: 30000 })
        .then((response) => {
          const url = response.data.content;
          if (newTab) {
            newTab.location.href = url;
          }
        })
        .catch((error) => {
          newTab.location.href = "/loading?error=true";
          utils.issueErrorAlert(Messages.MESSAGE.DOWNLOAD_ERROR);
        })
        .finally(() => setActive(false));
    } else {
      newTab.location.href = url;
    }
  } else {
    setActive(true);

    HttpService.get(url, { responseType: "blob" })
      .then((response) => {
        const content = response.headers["content-type"];
        download(response.data, fileName || Messages.BRANDING.APPLICATION_NAME, content);
      })
      .catch((error) => {
        utils.issueErrorAlert(Messages.MESSAGE.DOWNLOAD_ERROR);
      })
      .finally(() => setActive(false));
  }
};

const useStyles = makeStyles((theme) => ({
  tertiary: {
    color: theme.palette.text.warning,
  },
}));

const DocumentDownload = (props) => {
  const {
    url,
    title,
    fileName,
    fileType = "application/pdf",
    authenticated = false,
    hyperlink = false,
    variant = "icon",
    color = "secondary",
    ...otherProps
  } = props;

  const Messages = useMessages();
  const classes = useStyles();
  const [active, setActive] = React.useState(false);

  const Icon = fileType.startsWith("image")
    ? StyledImageIcon
    : hyperlink
    ? StyledLinkIcon
    : StyledDocumentIcon;
  const ToolTip = color === "secondary" ? StyledSecondaryTooltip : StyledTooltip;
  const iconColor = color !== "tertiary" ? color : "primary";

  const handleDocumentDownload = () => {
    downloadDocument({ title, url, fileName, authenticated, hyperlink }, Messages, setActive);
  };

  return (
    <div className="no-print">
      {variant === "icon" && (
        <StyledIconButton onClick={handleDocumentDownload} {...otherProps}>
          <ToolTip title={title}>
            <div>
              {active && <CircularProgress color="secondary" size={12} />}
              {!active && (
                <Icon color={iconColor} className={clsx({ [classes.tertiary]: color === "tertiary" })} />
              )}
            </div>
          </ToolTip>
        </StyledIconButton>
      )}
      {variant === "link" && (
        <div style={{ display: "flex", alignItems: "center" }}>
          <LinkButton onClick={handleDocumentDownload} label={title} {...otherProps} />
          {active && <CircularProgress color="secondary" size={10} style={{ marginLeft: 4 }} />}
        </div>
      )}
    </div>
  );
};

DocumentDownload.propTypes = {
  url: PropTypes.string.isRequired,
  tooltip: PropTypes.string,
  fileName: PropTypes.string,
  variant: PropTypes.oneOf(["icon", "link"]),
  label: PropTypes.string,
};

export default DocumentDownload;
