import React from "react";
import { get, remove, deburr, orderBy } from "lodash";
import moment from "moment-timezone";
import PropTypes from "prop-types";

import { useViewState, useViewStateDispatcher } from "providers/ViewStateProvider";
import * as utils from "commons/utils";
import * as queryUtils from "commons/queryUtils";
import * as Constants from "commons/constants";
import { useMessages } from "providers/BrandingProvider";
import * as DataOptions from "commons/dataOptions";
import { TextInput, SearchAdornment } from "components/Controls";
import { PopperPaper } from "components/containers/Modals";
import { useDataService } from "services/DataService";

import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import ListItem from "@material-ui/core/ListItem";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import IconButton from "@material-ui/core/IconButton";
import EditIcon from "@material-ui/icons/Edit";
import EmailIcon from "@material-ui/icons/Email";
import GroupIcon from "@material-ui/icons/Group";
import RemoveCircleOutlineIcon from "@material-ui/icons/RemoveCircleOutline";
import Popper from "@material-ui/core/Popper";
import Fade from "@material-ui/core/Fade";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";

import { makeStyles, withStyles } from "@material-ui/core/styles";
import { getAffiliateInstitutions, getUser } from "../commons/helpers/userStorage";
import { LabelledIconButton } from "./Controls";
import SaveIcon from "@material-ui/icons/Save";
import AltThemedComponent from "./AltThemedComponent";
import SaveSearchModal from "./modals/SaveSearchModal";
import { Collapse } from "@material-ui/core";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  transitionPopper: {
    zIndex: 10,
  },
  paper: {
    width: "100%",
    minWidth: 200,
    maxWidth: 300,
    paddingRight: 4,
  },
  header: {
    display: "flex",
    alignItems: "center",
    width: "100%",
    cursor: "pointer",
    marginTop: 20,
    borderBottom: "1px solid",
    borderBottomColor: theme.palette.border.main,
    "&:first-child": {
      marginTop: 0,
    },
  },
  headerText: {
    fontSize: "0.65rem",
    color: theme.palette.text.popper.header,
    textTransform: "uppercase",
    letterSpacing: "0.07em",
    fontWeight: 800,
  },
  label: {
    lineHeight: "30px",
    fontSize: "0.7rem",
    marginRight: 4,
  },
  icon: {
    color: theme.palette.primary.main,
    height: 20,
    width: 20,
    right: -5,
  },
  menuItem: {
    fontSize: "0.65rem",
    color: theme.palette.text.textInput,
    padding: 4,
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    overflow: "hidden",
  },
  selectable: {
    "&:hover:not(.edit)": {
      backgroundColor: theme.palette.background.hover,
    },
    "&.selected": {
      backgroundColor: theme.palette.background.selected,
    },
  },
  iconButton: {
    padding: 2,
  },
  itemIcon: {
    fontSize: "0.8rem",
  },
  nameWrapper: {
    marginRight: 4,
    width: "100%",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  inputRoot: {
    margin: 0,
    width: "100%",
  },
  name: {
    cursor: "pointer",
  },
  toolbarSection: {
    width: "100%",
    textAlign: "center",
    paddingRight: 4,
  },
  searchesWrapper: {
    overflowY: "auto",
    maxHeight: 600,
    paddingRight: 4,
  },
  searchInputWrapper: {
    maxWidth: 150,
    textAlign: "center",
  },
  buttonWrapper: {
    textAlign: "right",
    width: "100%",
    "& button": {
      margin: "0 2px",
    },
  },
}));

export const SavedSearchButton = withStyles((theme) => ({
  root: {
    minWidth: 50,
    lineHeight: 1,
    padding: 4,
    fontSize: "0.6rem",
    margin: "0 6px",
  },
}))(Button);

const MenuButton = withStyles((theme) => ({
  root: {
    padding: 4,
    fontSize: "0.6rem",
    minWidth: 40,
    fontWeight: "normal",
    borderRadius: 4,
    lineHeight: 1,
    "&.MuiButton-outlinedSecondary.Mui-disabled": {
      border: "1px solid",
      borderColor: theme.palette.border.disabled,
      color: theme.palette.text.disabled,
    },
  },
}))(Button);

const CollapseHideIcon = withStyles(() => ({
  root: {
    padding: 0,
    fontSize: "1.2rem",
  },
}))(KeyboardArrowDownIcon);

const CollapseShowIcon = withStyles(() => ({
  root: {
    padding: 0,
    fontSize: "1.2rem",
  },
}))(KeyboardArrowRightIcon);

const Search = ({ search, onDelete, onSelect, onUpdate, onEditStart, onCompanyEditStart }) => {
  const classes = useStyles();
  const Messages = useMessages();

  const user = getUser();
  const affiliateInstitutions = getAffiliateInstitutions();
  const canSaveForOthers = user.affiliateAdmin && affiliateInstitutions?.length > 0;

  const [confirm, setConfirm] = React.useState(false);
  const [email, setEmail] = React.useState(search.emailEnabled);
  const allowEdit =
    utils.hasNonEmptyValue(onUpdate) &&
    utils.hasNonEmptyValue(onDelete) &&
    utils.hasNonEmptyValue(onEditStart);

  const toggleEmail = () => {
    search.emailEnabled = !email;
    setEmail(!email);
    onUpdate(search);
  };

  const handleBlur = (event) => {
    setConfirm(false);
  };

  return (
    <ClickAwayListener onClickAway={handleBlur}>
      <ListItem
        key={search.description}
        classes={{
          root: classes.menuItem,
        }}
      >
        <>
          {!confirm && (
            <React.Fragment>
              <div
                title={search.description}
                className={classes.nameWrapper}
                onClick={(e) => onSelect(e, search)}
              >
                <Typography className={classes.name} variant="inherit" noWrap>
                  {search.description}
                </Typography>
              </div>
              {allowEdit && (
                <React.Fragment>
                  {canSaveForOthers && search.template && (
                    <IconButton
                      disableRipple
                      disableFocusRipple
                      className={classes.iconButton}
                      onClick={() => onCompanyEditStart(search)}
                    >
                      <GroupIcon color="secondary" className={classes.itemIcon} fontSize="small" />
                    </IconButton>
                  )}
                  <IconButton
                    disableRipple
                    disableFocusRipple
                    className={classes.iconButton}
                    onClick={() => onEditStart(search)}
                  >
                    <EditIcon color="secondary" className={classes.itemIcon} fontSize="small" />
                  </IconButton>
                  <IconButton
                    disableRipple
                    disableFocusRipple
                    className={classes.iconButton}
                    onClick={toggleEmail}
                  >
                    <EmailIcon
                      color={!email ? "disabled" : "secondary"}
                      className={classes.itemIcon}
                      fontSize="small"
                    />
                  </IconButton>
                  <IconButton
                    disableRipple
                    disableFocusRipple
                    className={classes.iconButton}
                    onClick={(e) => setConfirm(true)}
                  >
                    <RemoveCircleOutlineIcon color="error" className={classes.itemIcon} fontSize="small" />
                  </IconButton>
                </React.Fragment>
              )}
            </React.Fragment>
          )}
          {confirm && (
            <React.Fragment>
              <div title={search.description} className={classes.nameWrapper}>
                <Typography className={classes.name} variant="inherit" noWrap>
                  {search.description}
                </Typography>
              </div>
              <div className={classes.buttonWrapper}>
                <MenuButton
                  size="small"
                  variant="outlined"
                  disableFocusRipple={true}
                  onClick={(e) => setConfirm(false)}
                >
                  {Messages.LABEL.CANCEL}
                </MenuButton>
                <MenuButton
                  size="small"
                  color="secondary"
                  variant="outlined"
                  disableFocusRipple={true}
                  onClick={(e) => onDelete(e, search)}
                >
                  {Messages.LABEL.DELETE}
                </MenuButton>
              </div>
            </React.Fragment>
          )}
        </>
      </ListItem>
    </ClickAwayListener>
  );
};

Search.propTypes = {
  search: PropTypes.shape({
    id: PropTypes.number.isRequired,
    description: PropTypes.string.isRequired,
    emailEnabled: PropTypes.bool.isRequired,
    searchLastExecutedDate: PropTypes.string,
  }).isRequired,
  validationNames: PropTypes.arrayOf(PropTypes.string),
  onSelect: PropTypes.func.isRequired,
  onUpdate: PropTypes.func,
  onDelete: PropTypes.func,
  onEditStart: PropTypes.func,
  onCompanyEditStart: PropTypes.func,
};

const SearchGroup = ({ header, searches, defaultOpen }) => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(defaultOpen);

  return (
    <>
      <div className={classes.header}>
        {!open && <CollapseShowIcon onClick={() => setOpen(!open)} />}
        {open && <CollapseHideIcon onClick={() => setOpen(!open)} />}
        <div className={classes.headerText}>{header}</div>
      </div>
      <Collapse in={open}>{searches}</Collapse>
    </>
  );
};

const savedSearchesRef = React.createRef();

const SavedSearch = () => {
  const classes = useStyles();
  const Messages = useMessages();

  const [data, setData] = React.useState([]);
  const [activeSavedSearchIds, setActiveSavedSearchIds] = React.useState({});
  const [updateState, updateWithDataService] = useDataService();
  const [fetchState, fetchFromDataService] = useDataService();
  const [savedSearchesAnchorEl, setSavedSearchesAnchorEl] = React.useState(null);
  const [modalData, setModalData] = React.useState();
  const [searchValue, setSearchValue] = React.useState("");
  const [filteredOptions, setFilteredOptions] = React.useState([]);

  const user = getUser();
  const affiliateInstitutions = getAffiliateInstitutions();
  const canSaveForOthers = user.affiliateAdmin && affiliateInstitutions?.length > 0;

  const viewState = useViewState();
  const dispatchViewStateChange = useViewStateDispatcher();
  const doShowSearch = data.length >= 10;

  React.useEffect(() => {
    querySavedSearches();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    if (!updateState.isLoading && !updateState.isError) {
      querySavedSearches();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateState]);

  React.useEffect(() => {
    if (!fetchState.isLoading && !fetchState.isError) {
      const fetchedData = fetchState.data
        .map((datum) => {
          const query = queryUtils.getInventoryQueryFromQueryString(datum.searchQueryString);
          if (utils.hasNonEmptyValue(query) && utils.hasNonEmptyValue(query.filter)) {
            const instrumentCategory = get(query, "filter.instrumentCategory");
            datum.instrumentCategory = instrumentCategory;
            return datum;
          } else {
            return null;
          }
        })
        .filter((d) => utils.hasNonEmptyValue(d))
        .filter((d) => user.id === d.userId); // Temp - screen to only this user's searches until we can fully add support on the UI for company-level

      setData(fetchedData);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchState.data]);

  const handleEditStart = (search) => {
    setModalData({ open: true, search: search });
  };

  const handleCompanyEditStart = (search) => {
    setModalData({ open: true, search: search, editCompanyOnly: true });
  };

  const currentInventoryState = get(viewState, `${Constants.VIEW.INVENTORY}.${Constants.SUBVIEW.BUY}`, {});
  const activeInstrumentCategory = get(
    currentInventoryState,
    "activeQuerySubType",
    Constants.INVENTORY_TYPE.MUNICIPAL,
  );

  const getCurrentSearchFilter = () => {
    //const currentViewState = getCurrentInventoryState();
    const currentFilter = get(currentInventoryState, `${activeInstrumentCategory}.query`, {});
    delete currentFilter.page;
    delete currentFilter.size;
    delete currentFilter.clearDataOnFetch;
    return currentFilter;
  };

  const getActiveSavedSearch = () => {
    let activeSaved;
    data.some((search) => {
      if (search.id === get(activeSavedSearchIds, activeInstrumentCategory)) {
        activeSaved = search;
        return true;
      } else {
        return false;
      }
    });
    return activeSaved;
  };

  const clearSearch = () => {
    setFilteredOptions([]);
    setSearchValue("");
  };

  const closeSavedSearchesPopper = () => {
    setSavedSearchesAnchorEl(null);
    clearSearch();
  };

  const closeSavePopper = () => {
    setModalData(null);
  };

  const openSavedSearches = (event) => {
    event.stopPropagation();
    setSavedSearchesAnchorEl(savedSearchesAnchorEl ? null : event.currentTarget.parentElement);
  };

  const querySavedSearches = () => {
    const queryParams = {
      type: Constants.QUERY_TYPE.SAVED_SEARCH_ACTION,
      action: "get",
      clearDataOnFetch: false,
      size: 500,
      category: Constants.SAVED_SEARCH_CATEGORY.SAVED_SEARCH,
    };
    fetchFromDataService(queryParams);
  };

  const doSavedSearchAction = (action, id, search, isSavingForOthers) => {
    const queryParams = {
      type: Constants.QUERY_TYPE.SAVED_SEARCH_ACTION,
      action: action,
      identifier: id,
      clearDataOnFetch: false,
      isAdmin: isSavingForOthers,
    };

    if (utils.hasNonEmptyValue(search)) {
      delete search.id;
      queryParams.params = search;
    }

    updateWithDataService(queryParams);
  };

  const openSaveSearch = (event) => {
    event.stopPropagation();
    setModalData({ open: true, search: getActiveSavedSearch() });
  };

  const handleSelectSearch = (event, search) => {
    event.stopPropagation();
    const query = queryUtils.getInventoryQueryFromQueryString(search.searchQueryString);
    const instrumentCategory = get(query, "filter.instrumentCategory");
    let activeSearches = activeSavedSearchIds;
    activeSearches[instrumentCategory] = search.id;
    setActiveSavedSearchIds(activeSearches);

    dispatchViewStateChange({
      state: { query: query, activeView: Constants.VIEW.INVENTORY },
      view: Constants.VIEW.INVENTORY,
      subView: Constants.SUBVIEW.BUY,
      querySubType: instrumentCategory,
    });

    search.searchLastExecutedDate = moment.utc().toISOString();
    handleUpdateSearch(search);
    closeSavedSearchesPopper();
  };

  const handleDeleteSearch = (event, search) => {
    event.stopPropagation();
    remove(data, (d) => d.id === search.id);
    doSavedSearchAction("delete", search.id, search);
  };

  const handleUpdateSearch = (savedSearch, editCompanyOnly) => {
    const params = {
      description: savedSearch.description,
      searchQueryString: savedSearch.searchQueryString,
      emailEnabled: savedSearch.emailEnabled,
      searchLastExecutedDate: savedSearch.searchLastExecutedDate,
      category: Constants.SAVED_SEARCH_CATEGORY.SAVED_SEARCH,
      instrumentTypeId: utils.getInstrumentCategoryId(savedSearch.instrumentCategory),
    };

    if (editCompanyOnly) {
      params.template = true;
      params.companyId = savedSearch.companyId;
    }

    doSavedSearchAction("put", savedSearch.id, params, editCompanyOnly);
    closeSavePopper();
  };

  const handleAddSearch = (savedSearch) => {
    const searchFilter = getCurrentSearchFilter();
    const isSavingForOthers = canSaveForOthers && savedSearch.companyId?.length > 0;

    if (utils.hasNonEmptyValue(searchFilter)) {
      const params = {
        description: savedSearch.description,
        searchQueryString: queryUtils.getFilterAndSortQueryString(searchFilter),
        emailEnabled: savedSearch.emailEnabled,
        category: Constants.SAVED_SEARCH_CATEGORY.SAVED_SEARCH,
        instrumentTypeId: utils.getInstrumentCategoryId(activeInstrumentCategory),
      };

      if (isSavingForOthers) {
        params.template = savedSearch.companyId?.length > 0;
        params.companyId = savedSearch.companyId?.length > 0 ? savedSearch.companyId : null;
      } else {
        params.searchLastExecutedDate = new Date().toISOString();
      }

      const queryParams = {
        type: Constants.QUERY_TYPE.SAVED_SEARCH_ACTION,
        action: "post",
        clearDataOnFetch: false,
        params: params,
        isAdmin: isSavingForOthers,
      };

      updateWithDataService(queryParams);
    }
    closeSavePopper();
  };

  const handleInputChange = (event) => {
    const filtered = [];

    let value = event.target.value;
    setSearchValue(value);
    value = `${deburr(value.trim()).toLowerCase()}`;

    if (value !== "") {
      data.forEach((item, i) => {
        if (item.description.toLowerCase().startsWith(value)) {
          filtered.push(item);
        }
      });
    }

    setFilteredOptions(filtered);
  };

  const recentSearches = React.useMemo(() => {
    const searches = [];
    const searchData = utils.hasNonEmptyValue(searchValue) ? filteredOptions : data;
    const executedSearches = searchData.filter((d) => utils.hasNonEmptyValue(d.searchLastExecutedDate));

    if (executedSearches.length > 0) {
      const sortedData = orderBy(
        executedSearches,
        [({ searchLastExecutedDate }) => searchLastExecutedDate || "", "description"],
        ["desc", "asc"],
      );

      sortedData.forEach((search, i) => {
        if (i < 3) {
          const searchElem = (
            <Search
              key={`recent_${JSON.stringify(search)}`}
              search={search}
              onSelect={handleSelectSearch}
              onEditStart={handleEditStart}
              onCompanyEditStart={handleCompanyEditStart}
              onDelete={handleDeleteSearch}
              onUpdate={handleUpdateSearch}
            />
          );
          searches.push(searchElem);
        }
      });
    }

    if (searches.length === 0) return null;

    return <SearchGroup searches={searches} defaultOpen={true} header={Messages.LABEL.RECENT_SEARCHES} />;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchValue, data, filteredOptions]);

  const categorizedSearches = React.useMemo(() => {
    const categories = [];
    const searchData = utils.hasNonEmptyValue(searchValue) ? filteredOptions : data;
    const searchesByType = utils.groupByProperty(searchData, "instrumentCategory");

    Object.values(DataOptions.STATIC_OPTIONS.BUY).forEach((category) => {
      if (searchesByType.hasOwnProperty(category.value)) {
        const searches = [];
        //header = <PopperHeader key={category.value}>{category.label}</PopperHeader>;
        //searches.push(header);
        const sortedData = orderBy(searchesByType[category.value], "description", "asc");
        sortedData.forEach((search) => {
          const searchElem = (
            <Search
              key={JSON.stringify(search)}
              search={search}
              onSelect={handleSelectSearch}
              onEditStart={handleEditStart}
              onDelete={handleDeleteSearch}
              onUpdate={handleUpdateSearch}
              onCompanyEditStart={handleCompanyEditStart}
            />
          );
          searches.push(searchElem);
        });
        categories.push(
          <SearchGroup
            searches={searches}
            defaultOpen={activeInstrumentCategory === category.value}
            header={category.label}
          />,
        );
      }
    });

    return categories;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchValue, data, filteredOptions, activeInstrumentCategory]);

  return (
    <div className={classes.root}>
      <AltThemedComponent>
        <SavedSearchButton
          disabled={!utils.hasNonEmptyValue(data)}
          size="small"
          color="primary"
          endIcon={<ArrowDropDownIcon />}
          onClick={openSavedSearches}
        >
          {Messages.LABEL.SAVED_SEARCHES}
        </SavedSearchButton>
        <LabelledIconButton
          disabled={!utils.hasNonEmptyValue(getCurrentSearchFilter())}
          size="small"
          variant="outlined"
          color="primary"
          onClick={openSaveSearch}
          startIcon={<SaveIcon />}
        >
          {Messages.LABEL.SAVE_EXISTING_SEARCH}
        </LabelledIconButton>
      </AltThemedComponent>

      <Popper
        open={Boolean(savedSearchesAnchorEl) && utils.hasNonEmptyValue(data)}
        placement="bottom"
        ref={savedSearchesRef}
        anchorEl={savedSearchesAnchorEl}
        className={classes.transitionPopper}
        transition
      >
        {({ TransitionProps }) => (
          <Fade {...TransitionProps}>
            <div>
              <ClickAwayListener onClickAway={closeSavedSearchesPopper}>
                <PopperPaper onClick={(e) => e.stopPropagation()} classes={{ root: classes.paper }}>
                  {doShowSearch && (
                    <div className={classes.toolbarSection}>
                      <TextInput
                        value={searchValue}
                        maxLength={100}
                        classes={{ root: classes.searchInputWrapper }}
                        onChange={handleInputChange}
                        endAdornment={<SearchAdornment />}
                        autoFocus={true}
                      />
                    </div>
                  )}
                  <div className={classes.searchesWrapper}>
                    {recentSearches}
                    {categorizedSearches}
                  </div>
                </PopperPaper>
              </ClickAwayListener>
            </div>
          </Fade>
        )}
      </Popper>
      <SaveSearchModal
        open={modalData?.open === true}
        validationNames={data.map((s) => s.description)}
        search={modalData?.search}
        editCompanyOnly={modalData?.editCompanyOnly}
        onUpdate={handleUpdateSearch}
        onAdd={handleAddSearch}
        onClose={() => setModalData(null)}
      />
    </div>
  );
};

export default SavedSearch;
