import React from "react";
import Downshift from "downshift";
import PropTypes from "prop-types";
import clsx from "clsx";
import deburr from "lodash/deburr";

import * as utils from "commons/utils";
import { PopperPaper } from "components/containers/Modals";
import { SearchAdornment, StyledMenuItem } from "components/Controls";

import TextField from "@material-ui/core/TextField";
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
import Popper from "@material-ui/core/Popper";
import Fade from "@material-ui/core/Fade";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";

import { makeStyles } from "@material-ui/core/styles";
import { alpha } from "@material-ui/core/styles/colorManipulator";

const useStyles = makeStyles((theme) => ({
  transitionPopper: {
    zIndex: 15000,
    maxHeight: 250,
    overflow: "auto",
  },
  paper: {
    width: "100%",
    padding: "0",
    backgroundColor: theme.palette.background.popperPaperLight,
    borderRadius: 0,
  },
  inputContainer: {
    flexGrow: 1,
    position: "relative",
    margin: 0,
    padding: 0,
  },
  inputRoot: {
    display: "inline-flex",
    paddingRight: 0,
    background: theme.palette.background.shade,
    borderRadius: 4,
    "&:hover .MuiOutlinedInput-notchedOutline": {
      borderColor: theme.palette.border.highlight,
      borderWidth: 1,
    },
    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: theme.palette.border.highlight,
      borderWidth: 1,
    },
  },
  inputRootPrimary: {
    "&:hover .MuiOutlinedInput-notchedOutline": {
      borderColor: theme.palette.border.primary,
    },
    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: theme.palette.border.primary,
    },
  },
  input: {
    background: theme.palette.background.shade,
    borderColor: theme.palette.border.textInput,
    outlineWidth: 0,
    padding: "2px 6px",
    fontSize: "0.7rem",
    borderRadius: 3,
    fontWeight: "normal",
    color: theme.palette.text.textInput,
    lineHeight: 1,
    width: "calc(100% - 14px)",
  },
  inputLarge: {
    fontSize: "0.8rem",
  },
  notchedOutline: {
    borderColor: theme.palette.border.textInput,
    borderWidth: 1,
  },
  notchedOutlinePrimary: {
    borderColor: theme.palette.border.primary,
  },

  iconButton: {
    padding: 2,
    color: alpha(theme.palette.common.white, 0.5),
    "&:hover": {
      backgroundColor: "transparent",
      color: alpha(theme.palette.common.white, 0.8),
    },
  },
  adornmentPositionEnd: {
    marginLeft: 0,
  },
  suggestionRoot: {
    fontSize: ".7rem",
    padding: "5px 10px",
    minHeight: 0,
    textTransform: "uppercase",
    fontFamily: theme.typography.fontFamily,
    "&:hover": {
      backgroundColor: theme.palette.background.hover,
    },
    "&.Mui-selected": {
      backgroundColor: theme.palette.background.hover,
    },
    "&:hover&.Mui-selected": {
      backgroundColor: theme.palette.background.hover,
    },
  },
  suggestionSelected: {
    backgroundColor: theme.palette.background.selected,
  },
}));

const Typeahead = ({
  value,
  suggestions,
  onSubmitSearch,
  onInputValueChange,
  onInputClick,
  onClosePopper,
  onClickAway,
  placeholder = "",
  maxLength = 100,
  color,
  size,
  alwaysRenderSuggestions = false,
}) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const classes = useStyles();

  const handleKeyPress = (event) => {
    if (event.charCode === 13) {
      onSubmitSearch(value);
    }
  };

  const handleInputClick = (event) => {
    if (onInputClick) {
      onInputClick(event);
    }
  };

  return (
    <Downshift inputValue={value} onInputValueChange={onInputValueChange} onSelect={onSubmitSearch}>
      {({ getInputProps, getItemProps, getLabelProps, getMenuProps, highlightedIndex, isOpen }) => {
        const { onBlur, onFocus, ...inputProps } = getInputProps({
          onKeyPress: handleKeyPress,
          onClick: handleInputClick,
        });
        return (
          <div className={classes.inputContainer}>
            {renderInput(
              {
                fullWidth: true,
                variant: "outlined",
                placeholder,
                classes,
                InputLabelProps: getLabelProps({ shrink: true }),
                InputProps: { onBlur, onFocus },
                inputProps,
                ref: (node) => {
                  setAnchorEl(node);
                },
              },
              onSubmitSearch,
              onClickAway,
              maxLength,
              color,
              size,
            )}

            <Popper
              open={isOpen || (alwaysRenderSuggestions && utils.hasNonEmptyValue(suggestions))}
              anchorEl={anchorEl}
              className={classes.transitionPopper}
              transition
              placement="bottom-start"
            >
              {({ TransitionProps }) => (
                <Fade {...TransitionProps} timeout={100}>
                  <React.Fragment>
                    <ClickAwayListener onClickAway={onClosePopper}>
                      <div
                        {...(utils.hasNonEmptyValue(suggestions)
                          ? getMenuProps({}, { suppressRefError: true })
                          : {})}
                      >
                        <PopperPaper
                          classes={{ root: classes.paper }}
                          style={{ minWidth: anchorEl ? anchorEl.clientWidth + 20 : undefined }}
                        >
                          {suggestions.map((suggestion, i) =>
                            renderSuggestion({
                              suggestion,
                              itemProps: getItemProps({ item: suggestion }),
                              classes,
                              isHighlighted: highlightedIndex === i,
                            }),
                          )}
                        </PopperPaper>
                      </div>
                    </ClickAwayListener>
                  </React.Fragment>
                </Fade>
              )}
            </Popper>
          </div>
        );
      }}
    </Downshift>
  );
};
Typeahead.propTypes = {
  value: PropTypes.string.isRequired,
  suggestions: PropTypes.arrayOf(PropTypes.string),
  onSubmitSearch: PropTypes.func.isRequired,
  onInputValueChange: PropTypes.func.isRequired,
  onInputClick: PropTypes.func,
  onClosePopper: PropTypes.func.isRequired,
  onClickAway: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  maxLength: PropTypes.number,
  color: PropTypes.string,
  size: PropTypes.string,
  alwaysRenderSuggestions: PropTypes.bool,
};

function renderInput(props, onInputValueComitted, onClickAway, maxLength, color, size) {
  const { InputProps, inputProps, classes, ref, ...other } = props;

  return (
    <React.Fragment>
      <ClickAwayListener onClickAway={onClickAway}>
        <TextField
          inputProps={{ ...inputProps, ...{ maxLength: maxLength > 0 ? maxLength : 100 } }}
          InputProps={{
            inputRef: ref,
            classes: {
              root: clsx(classes.inputRoot, {
                [classes.inputRootPrimary]: color === "primary",
              }),
              input: clsx(classes.input, { [classes.inputLarge]: size === "large" }),
              notchedOutline: clsx(classes.notchedOutline, {
                [classes.notchedOutlinePrimary]: color === "primary",
              }),
            },
            endAdornment: (
              <InputAdornment position="end" classes={{ positionEnd: classes.adornmentPositionEnd }}>
                <IconButton className={classes.iconButton} onClick={onInputValueComitted}>
                  <SearchAdornment color={color} />
                </IconButton>
              </InputAdornment>
            ),
            ...InputProps,
          }}
          {...other}
        />
      </ClickAwayListener>
    </React.Fragment>
  );
}

renderInput.propTypes = {
  classes: PropTypes.object.isRequired,
  ref: PropTypes.object.isRequired,
  pad: PropTypes.number.isRequired,
  InputProps: PropTypes.object,
};

function renderSuggestion(suggestionProps) {
  const { suggestion, itemProps, isHighlighted, classes } = suggestionProps;

  return typeof suggestion === "string" ? (
    <StyledMenuItem
      {...itemProps}
      classes={{
        root: clsx(classes.suggestionRoot, { [classes.suggestionSelected]: isHighlighted }),
      }}
      key={suggestion}
      component="div"
    >
      {suggestion}
    </StyledMenuItem>
  ) : (
    [suggestion]
  );
}

renderSuggestion.propTypes = {
  classes: PropTypes.object.isRequired,
  itemProps: PropTypes.object.isRequired,
  suggestion: PropTypes.string.isRequired,
};

export const isTypeaheadEqual = (value1, value2) => {
  if (!value1 || !value2) return false;
  let val1 = deburr(value1.trim()).toLowerCase();
  let val2 = deburr(value2.trim()).toLowerCase();
  return val1.indexOf(val2) === 0;
};

export default Typeahead;
