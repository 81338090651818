import React from "react";
import clsx from "clsx";
import PropTypes from "prop-types";

import { useMessages } from "providers/BrandingProvider";
import * as utils from "commons/utils";
import * as DataConfig from "config/dataconfig";
import * as Constants from "commons/constants";
import HttpService from "services/commons/HttpService";
import QBTypography from "components/QBTypography";
import { ClosableModal } from "components/containers/Modals";
import DataTable from "components/datatable/DataTable";
import { ActionButton, FormButton, FormOutlinedButton } from "components/Controls";

import { useDataService } from "services/DataService";
import { QUERY_TYPE } from "commons/constants";
import { TextAreaInput } from "components/Controls";
import { TextInput } from "components/Controls";
import ModalMessages from "./common/ModalMessages";
import { TicketButton, TicketRow } from "./tickets/TicketComponents";
import { SimpleConfirmation } from "./InformationDialog";

import { makeStyles } from "@material-ui/core/styles";
import RemoveCircleOutlineIcon from "@material-ui/icons/RemoveCircleOutline";
import { Backdrop, CircularProgress } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: 300,
    width: 1000,
    maxWidth: 1000,
    marginTop: 20,
  },
  label: {
    fontSize: "0.65rem",
    color: theme.palette.text.popper.header,
    lineHeight: 1,
    marginBottom: 4,
    textTransform: "uppercase",
    letterSpacing: "0.07em",
    fontWeight: 800,
  },
  sublabel: {
    color: theme.palette.text.secondary,
    textTransform: "none",
  },
  backdrop: {
    backgroundColor: theme.palette.background.disabled,
  },
  cusip: {
    color: theme.palette.primary.main,
  },
}));

const TradeDocuments = ({ open, onClose, order }) => {
  const classes = useStyles();
  const Messages = useMessages();

  const [documents, setDocuments] = React.useState([]);

  const [errorMessages, setErrorMessages] = React.useState([]);
  const [fileForUpload, setFileForUpload] = React.useState(null);
  const [confirmationModal, setConfirmationModal] = React.useState();
  const [uploading, setUploading] = React.useState();

  const [fetchState, fetchFromDataService] = useDataService();
  const [updateState, updateWithDataService] = useDataService();

  const updatedRef = React.useRef(false);

  React.useEffect(() => {
    if (open) {
      fetchFiles();
    }
    updatedRef.current = false;
    setDocuments([]);
    setErrorMessages([]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  React.useEffect(() => {
    if (!fetchState.isInit && !fetchState.isLoading && !fetchState.isError) {
      if (fetchState.data) {
        const documents = fetchState.data.map((d) => {
          return {
            id: d.id,
            fileName: d.name,
            fileDescription: d.description,
            fileUploaded: d.enteredDate,
            enteredUserName: d.enteredUserName,
            deleteDisabled: !utils.hasNonEmptyValue(d.links.find((l) => l.rel === "deleteDocument")),
            file: {
              url: d.links.find((l) => l.rel !== "deleteDocument").href,
              fileName: d.name,
              fileType: d.mimeType,
              id: d.id,
            },
          };
        });

        setDocuments(documents);
      }
    } else if (updateState.isError) {
      setErrorMessages(["There was a problem retrieving file information."]);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchState]);

  React.useEffect(() => {
    if (!updateState.isInit && !updateState.isLoading && !updateState.isError) {
      fetchFiles();
      updatedRef.current = true;
    } else if (updateState.isError) {
      setErrorMessages(["There was a problem updating the file.  Please try again"]);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateState]);

  const fetchFiles = () => {
    setErrorMessages([]);

    const queryParams = {
      type: QUERY_TYPE.TRADE_DOCUMENTS,
      action: "get",
      clearDataOnFetch: false,
      buySellType: order.buySellType,
      identifier: order.id,
    };
    fetchFromDataService(queryParams);
  };

  const updateFile = (action, file) => {
    setErrorMessages([]);

    const queryParams = {
      type: QUERY_TYPE.TRADE_DOCUMENTS,
      action,
      clearDataOnFetch: false,
    };

    if (action === "delete") {
      queryParams.identifier = file.id;
    }

    updateWithDataService(queryParams);
  };

  const uploadFile = () => {
    setErrorMessages([]);
    setUploading(true);

    const data = new FormData();
    data.append("file", fileForUpload.file);
    data.append("name", fileForUpload.name || fileForUpload.file.name);
    data.append("description", fileForUpload.description);
    data.append("mimeType", fileForUpload.file.type);
    data.append("sendEmail", false); // TODO

    const params = {
      url: `${DataConfig.TRADE_DOCUMENTS_ENDPOINT}${order.buySellType}/${order.id}/upload`,
      method: "post",
      data,
    };

    HttpService(params)
      .then((response) => {
        setFileForUpload(null);
        fetchFiles();
        updatedRef.current = true;
      })
      .catch((error) => {
        setFileForUpload(null);
        console.error(error);
        setErrorMessages(["There was a problem uploading your file.  Please try again"]);
      })
      .finally(() => {
        setUploading(false);
      });
  };

  const handleClose = () => {
    onClose(updatedRef.current);
    updatedRef.current = false;
  };

  const handleFileSelectedForUpload = (e) => {
    setErrorMessages([]);

    if (!e.target.files) {
      return;
    }
    const file = e.target.files[0];
    setFileForUpload({ file: file, name: "", description: "" });
  };

  const handleFileNameChanged = (value) => {
    setFileForUpload((file) => {
      file.name = value;
      return { ...file };
    });
  };

  const handleFileDescriptionChanged = (value) => {
    setFileForUpload((file) => {
      file.description = value;
      return { ...file };
    });
  };

  const handleDeleteFile = (props) => {
    setConfirmationModal({
      open: true,
      onCloseModal: () => {
        updateFile("delete", { id: props.original.id });
      },
      message: "Are you sure you want to delete this file?",
    });
  };

  return (
    <>
      <ClosableModal
        scrollable={false}
        open={open}
        onClose={handleClose}
        header={
          <div>
            <span>Documents for Order </span> <span className={classes.cusip}>#{order?.orderNo}</span>
          </div>
        }
      >
        <div className={classes.root}>
          <div style={{ minHeight: 20 }}>
            <ModalMessages messages={errorMessages} level={Constants.ALERT_LEVEL.ERROR} />
            <ModalMessages
              messages={fileForUpload ? [Messages.MESSAGE.DOCUMENT_WARNING] : []}
              level={Constants.ALERT_LEVEL.WARNING}
            />
          </div>
          <div>
            {fileForUpload?.file && (
              <div style={{ marginTop: 10 }}>
                <Backdrop
                  className={classes.backdrop}
                  style={{ position: "absolute", zIndex: 200 }}
                  open={uploading}
                >
                  <CircularProgress color="secondary" />
                </Backdrop>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    flexWrap: "nowrap",
                  }}
                >
                  <div style={{ display: "flex", alignItems: "center", minWidth: 500, maxWidth: 500 }}>
                    <QBTypography
                      component="div"
                      style={{
                        whiteSpace: "nowrap",
                        textOverflow: "ellipsis",
                        overflow: "hidden",
                        margin: "10px 0 5px",
                      }}
                    >
                      <span>File</span>
                      <span>{fileForUpload.file.name}</span>
                    </QBTypography>

                    <RemoveCircleOutlineIcon
                      onClick={() => setFileForUpload(null)}
                      color="error"
                      fontSize="small"
                      style={{ fontSize: "0.8rem", cursor: "pointer", margin: "0 8px" }}
                    />
                  </div>
                  <div>
                    <div style={{ margin: "20px 0 5px" }} className={clsx(classes.label, classes.sublabel)}>
                      {Messages.LABEL.FILE_NAME} ({Messages.LABEL.OPTIONAL})
                    </div>
                    <TextInput
                      maxLength={100}
                      value={fileForUpload.name ?? ""}
                      onChange={(event) => {
                        handleFileNameChanged(event.currentTarget.value ?? "");
                      }}
                      placeholder={Messages.LABEL.FILE_NAME}
                      variant="form"
                      style={{ width: 500 }}
                    />
                  </div>
                  <div>
                    <div style={{ margin: "10px 0 5px" }} className={clsx(classes.label, classes.sublabel)}>
                      {Messages.LABEL.FILE_DESCRIPTION} ({Messages.LABEL.OPTIONAL})
                    </div>
                    <TextAreaInput
                      rows={3}
                      inputProps={{ maxLength: 255 }}
                      value={fileForUpload.description ?? ""}
                      onChange={(event) => {
                        handleFileDescriptionChanged(event.currentTarget.value ?? "");
                      }}
                      placeholder={Messages.LABEL.FILE_DESCRIPTION}
                      variant="form"
                      style={{ width: 500 }}
                    />
                  </div>
                </div>
                <div style={{ display: "flex", justifyContent: "center", gap: 5, marginTop: 20 }}>
                  <FormOutlinedButton onClick={() => setFileForUpload(null)}>
                    {Messages.LABEL.CANCEL}
                  </FormOutlinedButton>
                  <FormButton onClick={uploadFile}>{Messages.LABEL.UPLOAD_FILE}</FormButton>
                </div>
              </div>
            )}
            {!fileForUpload && (
              <>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    marginBottom: 20,
                  }}
                >
                  <QBTypography variant="body1" color="primary"></QBTypography>
                  <ActionButton component="label">
                    {Messages.LABEL.NEW_FILE}
                    <input type="file" hidden onChange={handleFileSelectedForUpload} />
                  </ActionButton>
                </div>

                <DataTable
                  queryType={Constants.QUERY_TYPE.TRADE_DOCUMENTS}
                  data={documents}
                  loading={fetchState.isLoading || updateState.isLoading}
                  error={fetchState.isError}
                  editable={true}
                  onValueChange={handleDeleteFile}
                />
              </>
            )}
          </div>
        </div>
        <TicketRow style={{ marginTop: 40, justifyContent: "center" }}>
          <TicketButton onClick={handleClose} label={Messages.LABEL.CLOSE} />
        </TicketRow>
        {confirmationModal && (
          <SimpleConfirmation
            message={confirmationModal?.message}
            isOpen={confirmationModal?.open}
            setIsOpen={() => setConfirmationModal(null)}
            onCloseModal={confirmationModal?.onCloseModal}
          />
        )}
      </ClosableModal>
    </>
  );
};

TradeDocuments.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default TradeDocuments;
