import React from "react";
import { useGlobal } from "reactn";
import PropTypes from "prop-types";
import queryString from "query-string";

import HttpService from "services/commons/HttpService";
import { useViewStateDispatcher } from "providers/ViewStateProvider";

import * as Constants from "commons/constants";
import * as utils from "commons/utils";
import * as DataConfig from "config/dataconfig";

function CartRedirectHandler({ location, onAddBondsToCart, onError }) {
  const dispatchViewStateChange = useViewStateDispatcher();
  const [isAuthenticated] = useGlobal("authenticated");

  React.useEffect(() => {
    if (location.search && isAuthenticated) {
      const params = queryString.parse(location.search);

      if (utils.hasNonEmptyValue(params.id)) {
        const url = `${DataConfig.INVENTORY_ENDPOINT}/withupdatebetterrate?id=${params.id}&cusip=${params.cusip}&quantity=${params.quantity}&rate=${params.rate}`;

        HttpService({ method: "post", url: url, data: {} })
          .then((response) => {
            if (response.data?.content || response.data?.errors) {
              const ids = params.id ? params.id.split(",") : [];
              const quantities = params.quantity ? params.quantity.split(",") : [];
              const portfolioIds = params.portfolioid ? params.portfolioid.split(",") : [];
              const cusips = params.cusip ? params.cusip.split(",") : [];
              const prices = params.price ? params.price.split(",") : [];
              const ytws = params.ytw ? params.ytw.split(",") : [];
              const ytms = params.ytm ? params.ytm.split(",") : [];

              const bonds = [];
              Object.keys(response.data.content).forEach((id) => {
                const bond = response.data.content[id];
                const index = ids.indexOf(id);

                bond.fromBetterRate = true;
                bond.portfolioid = portfolioIds.length > index ? portfolioIds[index] : null;
                bond.referrerid = params.referrerid;
                bond.returnURL = params.returnURL;
                bond.returnSiteName = params.returnSiteName;

                // retain prices and quanitities from Better Rate so that we may report on changes, if any
                bond.quantity = quantities.length > index ? quantities[index] : bond.quantity;
                bond.price = prices.length > index ? prices[index] : bond.price;
                bond.yieldToWorst = ytws.length > index ? ytws[index] : bond.ytw;
                bond.yieldToMaturity = ytms.length > index ? ytms[index] : bond.ytm;

                bonds.push(bond);
              });

              Object.keys(response.data.errors).forEach((id) => {
                let bond = response.data.errors[id];
                const index = ids.indexOf(id);

                // original offering no longer available, this is the "best" offering for this cusip available
                if (bond.data && bond.data.quantity > 0) {
                  bond = bond.data;

                  // retain prices from Better Rate so that we may report on changes, if any
                  bond.price = prices.length > index ? prices[index] : bond.price;
                  bond.yieldToWorst = ytws.length > index ? ytws[index] : bond.ytw;
                  bond.yieldToMaturity = ytms.length > index ? ytms[index] : bond.ytm;
                  // no longer in db with no other offering, this will report as unavailable
                } else {
                  bond.brokeredsecurityid = id;
                  bond.id = id;
                  bond.cusip = cusips.length > index ? cusips[index] : null;
                }

                bond.fromBetterRate = true;
                bond.portfolioid = portfolioIds.length > index ? portfolioIds[index] : null;
                bond.referrerid = params.referrerid;
                bond.returnURL = params.returnURL;
                bond.returnSiteName = params.returnSiteName;

                // retain qty from Better Rate so that we may report on changes, if any
                bond.quantity = quantities.length > index ? quantities[index] : bond.quantity;

                bonds.push(bond);
              });

              if (bonds.length > 0) {
                onError(null);
                onAddBondsToCart(bonds);

                dispatchViewStateChange({
                  state: {
                    activeView: Constants.VIEW.INVENTORY,
                    activeSubView: Constants.SUBVIEW.BUY,
                    activeQuerySubType: bonds[0].instrumentCategory,
                  },
                  view: Constants.VIEW.INVENTORY,
                  subView: Constants.SUBVIEW.BUY,
                  querySubType: bonds[0].instrumentCategory,
                });
              } else {
                onError({
                  returnURL: params.returnURL,
                  returnSiteName: params.returnSiteName,
                });
              }
            } else {
              onError({
                returnURL: params.returnURL,
                returnSiteName: params.returnSiteName,
              });
            }
          })
          .catch((e) => {
            onError({
              returnURL: params.returnURL,
              returnSiteName: params.returnSiteName,
            });
          });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.search, isAuthenticated]);

  return null;
}

CartRedirectHandler.propTypes = {
  location: PropTypes.object.isRequired,
  onAddBondsToCart: PropTypes.func.isRequired,
  onError: PropTypes.func.isRequired,
};

export default CartRedirectHandler;
