import React from "react";
import moment from "moment";
import * as DataConfig from "config/dataconfig";

const isBackgroundRefreshEnabled = process.env.REACT_APP_FEATURES_BACKGROUND_REFRESH === "true";

const useAutoRefresh = (func, disabled = false, refreshRate) => {
  const interval = React.useRef({ timeout: null, interval: null });

  React.useEffect(() => {
    if (interval.current) {
      window.clearTimeout(interval.current.timeout);
      window.clearInterval(interval.current.interval);
      interval.current.timeout = null;
      interval.current.interval = null;
    }

    if (isBackgroundRefreshEnabled && !disabled) {
      const start = moment();
      const delay = 10 - (start.second() % 10);

      interval.current.timeout = window.setTimeout(function () {
        interval.current.interval = window.setInterval(function () {
          func();
        }, refreshRate || DataConfig.DEFAULT_POLLING_INTERVAL);
      }, delay * 1000);
    }

    return () => {
      if (interval.current?.timeout) {
        window.clearTimeout(interval.current.timeout);
        window.clearInterval(interval.current.interval);
        interval.current.timeout = null;
        // eslint-disable-next-line react-hooks/exhaustive-deps
        interval.current.interval = null;
      }
    };
  }, [func, disabled, refreshRate]);
};

export default useAutoRefresh;
