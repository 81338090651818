import React from "react";
import { get, uniq } from "lodash";
import PropTypes from "prop-types";

import * as utils from "commons/utils";
import * as Constants from "commons/constants";
import CheckboxFilter from "components/filters/CheckboxFilter";
import RemoveFilterButton from "components/filters/common/RemoveFilterButton";
import { PopperPaper } from "components/containers/Modals";
import { useOptionsService } from "services/OptionsService";

import Button from "@material-ui/core/Button";
import Popper from "@material-ui/core/Popper";
import Fade from "@material-ui/core/Fade";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import { makeStyles } from "@material-ui/core/styles";
import { alpha } from "@material-ui/core/styles/colorManipulator";

const useStyles = makeStyles((theme) => ({
  wrapper: {
    flexGrow: 1,
  },
  transitionPopper: {
    zIndex: 15000,
  },
  filterText: {
    padding: 0,
    margin: 0,
    marginTop: 3,
    marginBottom: 1,
    height: 18,
    fontSize: ".65rem",
    color: theme.palette.text.secondary,
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    overflow: "hidden",
    letterSpacing: "0.05em",
  },
  removeFilter: {
    fontSize: ".6rem",
  },
  paper: {
    width: "100%",
    minHeight: 40,
    maxHeight: 450,
  },
  button: {
    margin: 0,
    marginTop: 0,
    lineHeight: 1.1,
    width: "100%",
    minWidth: 50,
    fontSize: "0.65rem",
    backgroundColor: alpha(theme.palette.secondary.main, 0.05),
    borderColor: alpha(theme.palette.secondary.main, 0.2),
    color: alpha(theme.palette.secondary.main, 0.8),
    borderWidth: 1,
    textTransform: "uppercase",
    letterSpacing: "0.12em",
    "&:hover": {
      borderColor: alpha(theme.palette.secondary.main, 0.4),
      backgroundColor: alpha(theme.palette.secondary.main, 0.08),
    },
  },
  label: {
    display: "inline-block",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    overflow: "hidden",
  },
}));

const CheckboxFilterPopper = (props) => {
  const classes = useStyles();
  const {
    filter,
    filterKey,
    onFilterChange,
    optionsKey,
    buttonLabel,
    instrumentCategory,
    variant = "filter",
    data,
  } = props;

  let currentFilter = get(filter, "filterValue", {});

  if (typeof currentFilter !== "object") {
    currentFilter = {
      selected: Array.isArray(currentFilter) ? currentFilter : [currentFilter],
      omit: false,
    };
  }
  const selected = get(currentFilter, "selected", []);
  const currentOptions = React.useRef();

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [filterText, setFilterText] = React.useState("");
  const hasFilter = utils.hasNonEmptyValue(currentFilter) && utils.hasNonEmptyValue(selected);
  const isOmit = get(currentFilter, "omit", false);

  const open = Boolean(anchorEl);
  const [options, setOptionKey] = useOptionsService(instrumentCategory);

  React.useEffect(() => {
    if (optionsKey) {
      setOptionKey(optionsKey);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [optionsKey]);

  React.useEffect(() => {
    if (optionsKey) {
      currentOptions.current = options;
    }

    renderDisplayText();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [options]);

  React.useEffect(() => {
    if (data) {
      const opts = [];

      data.forEach((d) => {
        if (get(d, filterKey)) {
          opts.push(get(d, filterKey));
        }
      });

      currentOptions.current = uniq(opts.sort()).map((o) => {
        return { label: o, value: o };
      });

      renderDisplayText();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  React.useEffect(() => {
    renderDisplayText();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [options, JSON.stringify(selected)]);

  const renderDisplayText = () => {
    const displayValues = [];

    if (currentOptions.current) {
      currentOptions.current.forEach((o) => {
        if (selected.includes(o.value)) {
          displayValues.push(o.shortLabel || o.label);
        }
      });
    }

    const text = utils.getFilterDisplayText(displayValues);
    setFilterText(text);
  };

  const togglePopper = (event) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const onClosePopper = () => {
    if (anchorEl) setAnchorEl(null);
  };

  const removeFilter = () => {
    const values = {
      filterKey: filterKey,
      filterValue: {
        selected: [],
        omit: false,
      },
      filterType: Constants.FILTER_TYPE.CHECKBOX,
    };
    onFilterChange(values);
  };

  return (
    <div className={classes.wrapper}>
      {variant === "filter" && (
        <div className={classes.filterText}>
          {hasFilter && <RemoveFilterButton isOmit={isOmit} onClick={removeFilter} />}
          {filterText}
        </div>
      )}
      <Button
        variant="outlined"
        size="small"
        className={classes.button}
        classes={{ label: classes.label }}
        type="button"
        onClick={togglePopper}
      >
        {buttonLabel}
      </Button>
      <Popper
        open={open}
        placement={variant === "filter" ? "bottom" : "right"}
        anchorEl={anchorEl}
        className={classes.transitionPopper}
        transition
      >
        {({ TransitionProps }) => (
          <Fade {...TransitionProps}>
            <React.Fragment>
              <ClickAwayListener onClickAway={onClosePopper}>
                <PopperPaper classes={{ root: classes.paper }}>
                  <CheckboxFilter {...props} onClosePopper={onClosePopper} />
                </PopperPaper>
              </ClickAwayListener>
            </React.Fragment>
          </Fade>
        )}
      </Popper>
    </div>
  );
};

CheckboxFilterPopper.propTypes = {
  filter: PropTypes.shape(CheckboxFilter.FilterDefinition),
  filterKey: PropTypes.string.isRequired,
  buttonLabel: PropTypes.string.isRequired,
  onFilterChange: PropTypes.func.isRequired,
  variant: PropTypes.oneOf(["filter", "standalone"]),
  instrumentCategory: PropTypes.string,
};

export default CheckboxFilterPopper;
