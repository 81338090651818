import queryString from "query-string";

const AUTHSERVER_URL = process.env.REACT_APP_AUTHSERVER_URL;
const OAUTH2_CLIENT_ID_PREFIX = process.env.REACT_APP_OAUTH2_CLIENT_ID_PREFIX;

/**
 * Determine the full OAUTH2 clientid - especially helpful for our affiliates who
 * need their own client ID which we detect by using the domain name we are using.
 * @returns {string|*}
 */
export const fullOAuth2ClientID = () => {
  if (window.location.hostname === "localhost") {
    return OAUTH2_CLIENT_ID_PREFIX + "-local";
  }

  const domainParts = window.location.hostname.split(".").reverse();

  // Qwickbonds.com entries
  if (domainParts[0] === "com" && domainParts[1] === "qwickbonds") {
    if (domainParts[3] === "devapp") {
      return OAUTH2_CLIENT_ID_PREFIX + "-dev";
    }
  }

  // QB Affiliates use their subdomain
  if (domainParts[1] === "qbbonds") {
    return OAUTH2_CLIENT_ID_PREFIX + "-" + domainParts[2];
  }

  //Default - prod-like
  return OAUTH2_CLIENT_ID_PREFIX;
};

export const redirectToLoginPage = (redirectUri) => {
  // Track the referral on the first access of qwickbonds.com and pass it through the login server to track
  // login events.

  // Append loginVia parameter safely by unpacking and repacking.
  const referrrer = document.referrer || "direct";
  const extractedParms = queryString.parse(redirectUri);
  extractedParms.referrer = referrrer;
  //const newRedirectUri = queryString.stringify(extractedParms); //encodes

  // double encode required as it gets decoded once on its return.
  // First encode by the stringify above and second encode here.
  // OAuthCallbackHandler will handle the final decoding.
  //const newState = encodeURIComponent(newRedirectUri);

  // Temporary. new state above did not work with encoding issues and need to troubleshoot
  const oldState = encodeURIComponent(encodeURIComponent(redirectUri));

  const loginURL = `${AUTHSERVER_URL}/oauth/authorize?client_id=${fullOAuth2ClientID()}&response_type=token&state=${oldState}`;
  window.location.assign(loginURL);
};

export const redirectToSignUpPage = (redirectUri) => {
  const state = encodeURIComponent(redirectUri);
  const loginURL = `${AUTHSERVER_URL}/oauth/authorize?client_id=${fullOAuth2ClientID()}&response_type=token&state=${state}&signup=true`;
  window.location.assign(loginURL);
};
