import { replace } from "lodash";

import * as utils from "commons/utils";
import * as Constants from "commons/constants";

export const TICKET_STATUS = {
  EDIT: "EDIT",
  PREVIEW: "PREVIEW",
  CONFLICTED: "CONFLICTED",
  IMPORT: "IMPORT",
  COMPLETE: "COMPLETE",
};

export const ERROR = {
  NONE: "NONE",
  LOOKUP_FAIL: "LOOKUP_FAIL",
  SUBMIT_FAIL: "SUBMIT_FAIL",
  NOT_A_TRADER_ACCOUNT: "NOT_A_TRADER_ACCOUNT",
};

export const generateRequestRecord = () => {
  const blankRec = {
    id: -1,
    cusip: "",
    buyerSeller: Constants.ACTOR_TYPE.BUYER,
    errors: [],
  };
  return blankRec;
};

export const readyBond = (bond) => {
  if (!bond.quantity_edit || bond.errors.includes("quantity_edit")) {
    bond.quantity_edit = 0;
  }
  bond.availableQuantity = bond.quantity_inventory || bond.quantity;
  bond.total = bond.total || 0;
  bond.calculatorErrors = bond.calculatorErrors || [];
  bond.errors = bond.errors || [];
  bond.warnings = bond.warnings || [];
  bond.noted = bond.noted || [];

  utils.removeFromArray(bond.errors, "quantity_edit");
  return bond;
};

export const readyTicket = (data = []) => {
  return data.map((row) => readyBond(row));
};

export const validateBuyQuantity = (datum, messages) => {
  const buyQty = datum.quantity_edit;

  const ALL_REQUIRED_MESSAGE = `${datum.cusip} requires you to buy the entire quantity of ${datum.availableQuantity}`;

  let isValid = !isNaN(Number(buyQty));

  if (isValid && Number(buyQty) > datum.availableQuantity) {
    messages.push(`${datum.cusip} has a maximum order size of ${datum.availableQuantity}`);
    isValid = false;
  }

  if (isValid && Number(buyQty) <= 0) {
    messages.push(`${datum.cusip} quantity must be larger than 0`);
    isValid = false;
  }

  if (
    isValid &&
    utils.hasNonEmptyValue(datum.restrictions?.minimumOrderQuantity) &&
    Number(buyQty) < datum.restrictions?.minimumOrderQuantity
  ) {
    if (datum.restrictions.minimumOrderQuantity === datum.availableQuantity) {
      messages.push(ALL_REQUIRED_MESSAGE);
    } else {
      messages.push(`${datum.cusip} has a minimum order size of ${datum.restrictions.minimumOrderQuantity}`);
    }
    isValid = false;
  }

  const minIncrement = datum.restrictions?.minimumIncrement || 1;

  let minIncrementRemaining = Number((Number(buyQty) % minIncrement).toFixed(6));
  let tailPiece = Number((datum.availableQuantity % 1).toFixed(6));

  datum.requiredPartialQty = 0;

  // Invalidate if buyQty is not increment of minimumIncrement
  if (
    isValid &&
    Number(buyQty) !== datum.availableQuantity &&
    utils.hasNonEmptyValue(datum.restrictions?.minimumIncrement) &&
    datum.restrictions?.minimumIncrement !== datum.availableQuantity &&
    minIncrementRemaining !== 0
  ) {
    messages.push(
      `Please enter your order for ${datum.cusip} in increments of ${datum.restrictions.minimumIncrement}`,
    );
    isValid = false;
  }

  // Current face is less than 2m, require to take tail piece
  if (isValid) {
    const remainingAfterPurchase = datum.availableQuantity - buyQty;

    // current face mre than 1m, purchase leaves buyer with less than 250000, buyer must take all
    if (datum.currentFace >= 1000000 && remainingAfterPurchase > 0 && remainingAfterPurchase < 250) {
      datum.requiredPartialQty = remainingAfterPurchase;
      // force the tail piece if not taken
    } else if (datum.currentFace < 2000000 && tailPiece !== 0 && minIncrementRemaining !== tailPiece) {
      datum.requiredPartialQty = tailPiece;
    }

    if (datum.requiredPartialQty > 0) {
      isValid = false;
      const partialQtyMessage = `${datum.cusip} has a tail amount of $${Number(
        (datum.requiredPartialQty * 1000).toFixed(6),
      )} that must be included in your order. Click 'Adjust' to include the tail amount in your order size.`;
      messages.push(partialQtyMessage);
    }

    if (
      isValid &&
      utils.hasNonEmptyValue(datum.restrictions?.minimumBalanceRemaining) &&
      remainingAfterPurchase < datum.restrictions?.minimumBalanceRemaining &&
      remainingAfterPurchase !== 0
    ) {
      isValid = false;
      // const idealRemaining = datum.availableQuantity - datum.restrictions.minimumBalanceRemaining;
      const minBalRemainingMessage = `For ${datum.cusip}, please enter either the full amount offered, or for an amount leaving a minimum quantity of ${datum.restrictions.minimumBalanceRemaining}.`;
      messages.push(minBalRemainingMessage);
    }
  }

  if (utils.hasNonEmptyValue(buyQty) && !isNaN(Number(buyQty))) {
    datum.quantity_edit = Number(buyQty);
  }

  if (!isValid) {
    utils.addToArray(datum.errors, "quantity_edit");
  } else {
    utils.removeFromArray(datum.errors, "quantity_edit");
  }

  return isValid;
};

export const hasTotalThreshold = (data = []) => {
  return data.some((d) => {
    const total = Number(d.total);
    return total >= 1000000;
  });
};

export const validateCusip = (datum) => {
  datum.cusip = datum.cusip.toUpperCase();

  const value = datum.cusip;
  let isValid = utils.isValidCusip(value);

  if (datum.issuer === Constants.RESPONSE.NOT_FOUND) {
    isValid = false;
  }

  if (!isValid) {
    utils.addToArray(datum.errors, "cusip");
  } else {
    utils.removeFromArray(datum.errors, "cusip");
  }
};

export const validateSellQuantity = (datum, accessor) => {
  let value = datum[accessor];

  // strip commas and currency signs
  if (utils.hasNonEmptyValue(value)) {
    value = replace(datum[accessor].toString().trim(), /,|$/g, "");
  }

  let isValid = true;

  if (!utils.hasNonEmptyValue(value)) {
    isValid = datum.errors.includes("cusip"); // empty values ok if cusip hasn't been found
  } else if (isNaN(Number(value))) {
    isValid = false;
  } else if (Number(value) <= 0) {
    isValid = false;
  }

  if (!isValid) {
    utils.addToArray(datum.errors, "quantity_edit");
    utils.addToArray(datum.errors, "originalSize");
  } else {
    utils.removeFromArray(datum.errors, "quantity_edit");
    utils.removeFromArray(datum.errors, "originalSize");
  }

  if (utils.hasNonEmptyValue(value) && !isNaN(Number(value))) {
    datum[accessor] = Number(value);
  }

  return isValid;
};

const hasPriceOrYield = (d) => {
  return (
    (utils.hasNonEmptyValue(d.price) && !isNaN(Number(d.price))) ||
    (utils.hasNonEmptyValue(d.yieldToWorst) && !isNaN(Number(d.yieldToWorst))) ||
    (utils.hasNonEmptyValue(d.yieldToMaturity) && !isNaN(Number(d.yieldToMaturity)))
  );
};

export const validatePrice = (datum) => {
  const value = datum.price;

  let isValid = true;
  if (!utils.hasNonEmptyValue(value)) {
    // empty values ok if cusip hasn't been found, or calculator error
    isValid = datum.errors.includes("cusip") || (datum.calculatorErrors?.length && hasPriceOrYield(datum));
  } else if (isNaN(Number(value))) {
    isValid = false;
  } else if (Number(value) < 0) {
    isValid = false;
  }

  if (utils.hasNonEmptyValue(value) && !isNaN(Number(value))) {
    datum.price = Number(value);
  }

  if (!isValid) {
    utils.addToArray(datum.errors, "price");
  } else {
    utils.removeFromArray(datum.errors, "price");
  }

  return isValid;
};

export const validateYield = (datum, accessor, ticketErrorMessages, Messages) => {
  const value = datum[accessor];

  let isValid = true;
  if (!utils.hasNonEmptyValue(value)) {
    // empty values ok if cusip hasn't been found, or calculator error
    isValid = datum.errors.includes("cusip") || (datum.calculatorErrors?.length && hasPriceOrYield(datum));
  } else if (isNaN(Number(value))) {
    isValid = false;
  }

  if (utils.hasNonEmptyValue(value) && !isNaN(Number(value))) {
    datum[accessor] = Number(value);

    // Dont allow negative yields from the user or calculator
    if (datum[accessor] <= 0) {
      isValid = false;
      utils.addToArray(ticketErrorMessages, Messages.MESSAGE.REQUEST_NEGATIVE_YIELD);
    }

    // Dont allow outrageous yields and turn it from a backend error into a UI/user error
    if (datum[accessor] > 999) {
      isValid = false;
      utils.addToArray(ticketErrorMessages, Messages.MESSAGE.REQUEST_INVALID_YIELD);
    }
  }

  if (!isValid) {
    utils.addToArray(datum.errors, accessor);
  } else {
    utils.removeFromArray(datum.errors, accessor);
  }

  return isValid;
};
