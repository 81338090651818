import * as Constants from "commons/constants";
import { LABEL } from "commons/messages";
import * as DataConfig from "config/dataconfig";

export const STATIC_OPTIONS = {
  ALL_ONLY_OMIT: [
    { value: "", label: "All" },
    { value: "true", label: "Only" },
    { value: "false", label: "Omit" },
  ],

  EXCLUDE_FDIC: [
    { value: "false", label: "Include Owned FDIC#S" },
    { value: "true", label: "Exclude" },
  ],

  BUY: [
    { value: Constants.INVENTORY_TYPE.TREASURY, label: LABEL.TREASURY },
    { value: Constants.INVENTORY_TYPE.CD, label: LABEL.CD },
    { value: Constants.INVENTORY_TYPE.MUNICIPAL, label: LABEL.MUNICIPAL },
    { value: Constants.INVENTORY_TYPE.MORTGAGE, label: LABEL.MORTGAGE },
    { value: Constants.INVENTORY_TYPE.AGENCY, label: LABEL.AGENCY },
  ],

  CALL_FEATURES: [
    { value: "", label: "All" },
    { value: "true", label: "Callable" },
    { value: "false", label: "Non-callable" },
  ],

  CATEGORY: [
    { value: "", label: "All" },
    { value: "6,7", label: "General Obligation" },
    { value: "9", label: "Revenue" },
  ],

  CD_TYPE: [
    { label: "All", value: "" },
    { label: LABEL.CD_PRIMARY, value: 128 },
    { label: LABEL.CD_SECONDARY, value: 129 },
  ],

  TREASURY_TYPE: [
    { label: "All", value: "" },
    { label: LABEL.ACTIVES, value: "true" },
  ],

  COUPON_FEATURES: [{ value: "Zero coupon", label: "Zero Coupon" }],

  INVENTORY: [
    { value: Constants.SUBVIEW.BUY, label: LABEL.BUY },
    { value: Constants.SUBVIEW.SELL, label: LABEL.SELL },
  ],

  LINK_TYPE: [
    { value: Constants.LINK_TYPE.FILE, label: LABEL.FILE },
    { value: Constants.LINK_TYPE.URL, label: LABEL.URL },
  ],

  MONTHS: [
    { label: "January", value: "1" },
    { label: "February", value: "2" },
    { label: "March", value: "3" },
    { label: "April", value: "4" },
    { label: "May", value: "5" },
    { label: "June", value: "6" },
    { label: "July", value: "7" },
    { label: "August", value: "8" },
    { label: "September", value: "9" },
    { label: "October", value: "10" },
    { label: "November", value: "11" },
    { label: "December", value: "12" },
  ],

  PRODUCT_TYPE: [
    { label: LABEL.AGENCY, value: Constants.INVENTORY_TYPE.AGENCY },
    { label: LABEL.CD_PRIMARY, value: Constants.CD_TYPE.PRIMARY },
    { label: LABEL.CD_SECONDARY, value: Constants.CD_TYPE.SECONDARY },
    { label: LABEL.MORTG, value: Constants.INVENTORY_TYPE.MORTGAGE },
    { label: LABEL.MUNICIPAL, value: Constants.INVENTORY_TYPE.MUNICIPAL },
    { label: LABEL.TREASURY, value: Constants.INVENTORY_TYPE.TREASURY },
  ],

  RATE_TYPE: [
    { label: "Highest Yield", value: Constants.RATES_TYPE.HIGHEST },
    { label: "Median Yield", value: Constants.RATES_TYPE.MEDIAN },
  ],

  SAME_OR_ONE_LESS: [
    { label: "-1", value: "-1" },
    { label: "Same", value: "same" },
  ],

  STATES: [
    { label: "Alabama", value: "AL", alt: "AL", shortLabel: "AL" },
    { label: "Alaska", value: "AK", alt: "AK", shortLabel: "AK" },
    { label: "Arizona", value: "AZ", alt: "AZ", shortLabel: "AZ" },
    { label: "Arkansas", value: "AR", alt: "AR", shortLabel: "AR" },
    { label: "California", value: "CA", alt: "CA", shortLabel: "CA" },
    { label: "Colorado", value: "CO", alt: "CO", shortLabel: "CO" },
    { label: "Connecticut", value: "CT", alt: "CT", shortLabel: "CT" },
    { label: "Delaware", value: "DE", alt: "DE", shortLabel: "DE" },
    { label: "District Of Columbia", value: "DC", alt: "DC", shortLabel: "DC" },
    { label: "Florida", value: "FL", alt: "FL", shortLabel: "FL" },
    { label: "Georgia", value: "GA", alt: "GA", shortLabel: "GA" },
    { label: "Guam", value: "GU", alt: "GU", shortLabel: "GU" },
    { label: "Hawaii", value: "HI", alt: "HI", shortLabel: "HI" },
    { label: "Idaho", value: "ID", alt: "ID", shortLabel: "ID" },
    { label: "Illinois", value: "IL", alt: "IL", shortLabel: "IL" },
    { label: "Indiana", value: "IN", alt: "IN", shortLabel: "IN" },
    { label: "Iowa", value: "IA", alt: "IA", shortLabel: "IA" },
    { label: "Kansas", value: "KS", alt: "KS", shortLabel: "KS" },
    { label: "Kentucky", value: "KY", alt: "KY", shortLabel: "KY" },
    { label: "Louisiana", value: "LA", alt: "LA", shortLabel: "LA" },
    { label: "Maine", value: "ME", alt: "ME", shortLabel: "ME" },
    { label: "Maryland", value: "MD", alt: "MD", shortLabel: "MD" },
    { label: "Massachusetts", value: "MA", alt: "MA", shortLabel: "MA" },
    { label: "Michigan", value: "MI", alt: "MI", shortLabel: "MI" },
    { label: "Minnesota", value: "MN", alt: "MN", shortLabel: "MN" },
    { label: "Mississippi", value: "MS", alt: "MS", shortLabel: "MS" },
    { label: "Missouri", value: "MO", alt: "MO", shortLabel: "MO" },
    { label: "Montana", value: "MT", alt: "MT", shortLabel: "MT" },
    { label: "Nebraska", value: "NE", alt: "NE", shortLabel: "NE" },
    { label: "Nevada", value: "NV", alt: "NV", shortLabel: "NV" },
    { label: "New Hampshire", value: "NH", alt: "NH", shortLabel: "NH" },
    { label: "New Jersey", value: "NJ", alt: "NJ", shortLabel: "NJ" },
    { label: "New Mexico", value: "NM", alt: "NM", shortLabel: "NM" },
    { label: "New York", value: "NY", alt: "NY", shortLabel: "NY" },
    { label: "North Carolina", value: "NC", alt: "NC", shortLabel: "NC" },
    { label: "North Dakota", value: "ND", alt: "ND", shortLabel: "ND" },
    { label: "Ohio", value: "OH", alt: "OH", shortLabel: "OH" },
    { label: "Oklahoma", value: "OK", alt: "OK", shortLabel: "OK" },
    { label: "Oregon", value: "OR", alt: "OR", shortLabel: "OR" },
    { label: "Pennsylvania", value: "PA", alt: "PA", shortLabel: "PA" },
    { label: "Puerto Rico", value: "PR", alt: "PR", shortLabel: "PR" },
    { label: "Rhode Island", value: "RI", alt: "RI", shortLabel: "RI" },
    { label: "South Carolina", value: "SC", alt: "SC", shortLabel: "SC" },
    { label: "South Dakota", value: "SD", alt: "SD", shortLabel: "SD" },
    { label: "Tennessee", value: "TN", alt: "TN", shortLabel: "TN" },
    { label: "Texas", value: "TX", alt: "TX", shortLabel: "TX" },
    { label: "Utah", value: "UT", alt: "UT", shortLabel: "UT" },
    { label: "Vermont", value: "VT", alt: "VT", shortLabel: "VT" },
    { label: "Virginia", value: "VA", alt: "VA", shortLabel: "VA" },
    { label: "Virgin Islands", value: "VI", alt: "VI", shortLabel: "VI" },
    { label: "Washington", value: "WA", alt: "WA", shortLabel: "WA" },
    { label: "West Virginia", value: "WV", alt: "WV", shortLabel: "WV" },
    { label: "Wisconsin", value: "WI", alt: "WI", shortLabel: "WI" },
    { label: "Wyoming", value: "WY", alt: "WY", shortLabel: "WY" },
  ],

  SELL: [
    { value: Constants.TRADE_TYPE.PRICE_QUOTES, label: LABEL.PRICE_QUOTES },
    { value: Constants.TRADE_TYPE.BIDS_RFQ, label: LABEL.BIDS_RFQ },
    { value: Constants.TRADE_TYPE.BONDS_FOR_SALE, label: LABEL.BONDS_FOR_SALE },
  ],

  STATUS: [
    { value: "7", label: "Cancel Fill" },
    { value: "5", label: "Filled" },
    { value: "6", label: "Pending Fill" },
    { value: "8", label: "Cancel" },
    { value: "9", label: "Reject" },
    { value: "10", label: "Cancelled by User" },
    { value: "11", label: "Updating Bids" },
  ],

  TOOLS: [
    { value: Constants.TOOL_TYPE.COMPARABLE, label: LABEL.COMPARABLE },
    { value: Constants.TOOL_TYPE.STATUS, label: LABEL.STATUS },
    { value: Constants.TOOL_TYPE.RATES, label: LABEL.RATES },
    { value: Constants.TOOL_TYPE.YIELD_CURVE, label: LABEL.YIELD_CURVE },
  ],

  TOOLS_CD: [
    { value: Constants.TOOL_TYPE.COMPARABLE, label: LABEL.COMPARABLE_CD },
    { value: Constants.TOOL_TYPE.STATUS, label: LABEL.STATUS },
    { value: Constants.TOOL_TYPE.RATES, label: LABEL.RATES },
    { value: Constants.TOOL_TYPE.YIELD_CURVE, label: LABEL.YIELD_CURVE },
  ],
};

export const OPTIONS = { ...STATIC_OPTIONS, ...DataConfig.REFERENCE_ENDPOINT };
