import React from "react";
import { get } from "lodash";
import PropTypes from "prop-types";

import * as utils from "commons/utils";
import * as Constants from "commons/constants";
import { SliderInput, TextInput, InlineErrorTooltip } from "components/Controls";

import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  sliderWrapper: {
    paddingLeft: 5,
    paddingRight: 5,
    paddingTop: 3,
    paddingBottom: 6,
  },
  inputWrapper: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
  },
  inputRoot: {
    margin: 0,
    maxWidth: 48,
    flexDirection: "row",
  },
  label: {
    fontSize: ".65rem",
    color: theme.palette.text.secondary,
    lineHeight: 1,
    textAlign: "right",
    margin: "auto 5px",
  },
}));

const RangeFilter = (props) => {
  const {
    filter,
    filterKey,
    label,
    defaultValue,
    step,
    min,
    max,
    marks,
    onFilterChange,
    variant = "filter",
    SliderProps,
    TextInputProps,
  } = props;
  const hideSlider = variant === "standalone";
  const currentFilter = get(filter, "filterValue", []);
  const defaultVal =
    utils.hasNonEmptyValue(currentFilter) && utils.hasNonEmptyValue(currentFilter[0])
      ? currentFilter[0]
      : defaultValue || min;

  const [value, setValue] = React.useState(defaultVal);
  const [inputValue, setInputValue] = React.useState(defaultVal);
  const [valueValid, setValueValid] = React.useState(true);
  const [tooltipMessage, setTooltipMessage] = React.useState("");

  const classes = useStyles();

  React.useEffect(() => {
    setValue(defaultVal);
    setInputValue(defaultVal);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultVal]);

  const isValid = (val) => {
    return utils.hasNonEmptyValue(val) && !isNaN(val) && val >= 0;
  };

  const handleSliderChange = (event, newValue) => {
    setTooltipMessage("");

    setValue(newValue);
    setInputValue(newValue);
    setValueValid(true);
  };

  const handleInputChange = (event) => {
    setTooltipMessage("");

    let val = event.target.value;
    const valid = isValid(val);
    setValueValid(valid);
    setInputValue(val);
    if (valid) {
      setValue(val);
    }
  };

  const handleKeyPress = (event) => {
    if (event.charCode === 13) {
      adjustInputValuesAndApply();
    }
  };

  const handleBlur = () => {
    adjustInputValuesAndApply();
  };

  const adjustInputValuesAndApply = () => {
    let val = inputValue;

    if (!utils.hasNonEmptyValue(val)) {
      val = defaultVal;
      setValue(val);
      setInputValue(val);
      setValueValid(true);
    }

    validateAndApply(val);
  };

  const validateAndApply = (val) => {
    setTooltipMessage("");

    const valid = isValid(val);
    setValueValid(valid);

    if (!utils.hasNonEmptyValue(val)) {
      setTooltipMessage("Required value");
    } else if (isNaN(val)) {
      setTooltipMessage("This must be a number");
    } else if (val < 0) {
      setTooltipMessage("This must be a positive number");
    } else if (valid) {
      setValue(val);
      applyRangeFilter(val);
    }
  };

  const applyRangeFilter = (val) => {
    if (Number(val) !== defaultVal) {
      // ensure range bounds
      val = Number(val);
      val = Math.max(min, val);

      if (val === min) {
        val = null;
      }

      onFilterChange({
        filterKey: filterKey,
        filterValue: [val, null],
        filterType: Constants.FILTER_TYPE.RANGE,
      });
    }
  };

  return (
    <React.Fragment>
      {!hideSlider && (
        <div className={classes.sliderWrapper}>
          <SliderInput
            value={Number(value)}
            color="secondary"
            onChange={handleSliderChange}
            onChangeCommitted={(e) => validateAndApply(value)}
            step={marks ? null : step}
            min={min}
            max={max}
            marks={marks}
            {...SliderProps}
          />
        </div>
      )}
      <div className={classes.inputWrapper}>
        {hideSlider && utils.hasNonEmptyValue(label) && <span className={classes.label}>{label}</span>}
        <InlineErrorTooltip
          arrow
          onClick={(e) => setTooltipMessage("")}
          open={utils.hasNonEmptyValue(tooltipMessage)}
          title={tooltipMessage}
        >
          <div>
            <TextInput
              variant={variant}
              value={inputValue}
              error={!valueValid}
              className={classes.inputRoot}
              onChange={handleInputChange}
              onBlur={handleBlur}
              onKeyPress={handleKeyPress}
              {...TextInputProps}
            />
          </div>
        </InlineErrorTooltip>
      </div>
    </React.Fragment>
  );
};

RangeFilter.FilterDefinition = {
  filterKey: PropTypes.string,
  filterValue: PropTypes.arrayOf(PropTypes.number),
  filterType: PropTypes.oneOf(Object.values(Constants.FILTER_TYPE)),
};

RangeFilter.propTypes = {
  filter: PropTypes.shape(RangeFilter.FilterDefinition),
  filterKey: PropTypes.string.isRequired,
  label: PropTypes.string,
  hideSlider: PropTypes.bool,
  defaultValue: PropTypes.number,
  step: PropTypes.number,
  min: PropTypes.number.isRequired,
  max: PropTypes.number,
  marks: PropTypes.arrayOf(PropTypes.object),
  onFilterChange: PropTypes.func.isRequired,
  variant: PropTypes.oneOf(["filter", "standalone"]),
  SliderProps: PropTypes.object,
  TextInputProps: PropTypes.object,
};

export default RangeFilter;
