import React from "react";
import { get, isEqual } from "lodash";
import PropTypes from "prop-types";

import * as DataOptions from "commons/dataOptions";
import * as utils from "commons/utils";
import * as Constants from "commons/constants";

import { StyledMenuItem, StyledFilterSelect, StyledSelectFormControl } from "components/Controls";
import { PopperHeader } from "components/containers/Modals";

import { useMessages } from "providers/BrandingProvider";
import { useOptionsService } from "services/OptionsService";

import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";

import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  inputWrapper: {
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    maxWidth: 200,
  },
  optionsWrapper: {
    marginTop: 4,
    paddingTop: 6,
  },
  labelText: {
    padding: 0,
    margin: 0,
    marginTop: 3,
    marginBottom: 2,
    height: 18,
    fontSize: ".65rem",
    color: theme.palette.text.secondary,
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    overflow: "hidden",
    letterSpacing: "0.03em",
  },
  iconRoot: {
    fontSize: "0.8rem",
  },
  includeLabelRoot: {
    margin: 0,
    minWidth: 100,
  },
  includeLabel: {
    fontSize: "0.7rem",
    marginLeft: 0,
    letterSpacing: "0.03em",
  },
  includeCheckboxRoot: {
    padding: 5,
    color: theme.palette.secondary.main,
  },
  includeMenuItem: {
    padding: "2px 10px 2px 0",
    "&.Mui-disabled": {
      opacity: 1,
      pointerEvents: "auto",
    },
    "&:hover": {
      background: "none",
    },
  },
}));

const excludeValue = 999;

const SelectRatingFilter = ({
  filter,
  filterKey,
  optionsKey,
  onFilterChange,
  variant = "filter",
  SelectProps,
  MenuItemProps,
}) => {
  const classes = useStyles();

  const Messages = useMessages();
  const currentFilter = get(filter, "filterValue", {});
  const noneValue = -1;
  let filterValue = utils.hasNonEmptyValue(currentFilter.value) ? currentFilter.value : noneValue;
  if (Array.isArray(filterValue) && filterValue.length === 1) {
    filterValue = [`a_${filterValue}`];
  }
  const label = Messages.LABEL.MINIMUM;
  const [filterText, setFilterText] = React.useState(label);
  const [value, setValue] = React.useState(noneValue);
  const [include, setInclude] = React.useState(!utils.hasNonEmptyValue(currentFilter.exclude));
  const [rangeOptions, setRangeOptions] = React.useState([]);
  const [minimumOptions, setMinimumOptions] = React.useState([]);

  const [options, setOptionKey] = useOptionsService();

  React.useEffect(() => {
    if (optionsKey) {
      setOptionKey(optionsKey);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [optionsKey]);

  React.useEffect(() => {
    setLabel(value);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  React.useEffect(() => {
    const ranges = [];
    const minimums = [];

    options.forEach((o) => {
      if (Array.isArray(o.value) && o.value.length === 1) {
        o.value = [`a_${o.value}`];
      }
      if (isEqual(filterValue, o.value)) {
        // use isEqual as value may be an array
        setValue(o.value);
        setLabel(o.value);
      }

      if (Array.isArray(o.value)) {
        ranges.push(o);
      } else {
        minimums.push(o);
      }
    });

    setRangeOptions(ranges);
    setMinimumOptions(minimums);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [options]);

  const setLabel = (val) => {
    if (Array.isArray(val) || val.toString().indexOf("a_") === 0) {
      setFilterText(Messages.LABEL.RANGE);
    } else {
      setFilterText(Messages.LABEL.MINIMUM);
    }
  };

  const handleIncludeChecked = (event) => {
    setInclude(!include);
    handleFilterChange(value, !include);
    // don't close the menu
    event.preventDefault();
    event.stopPropagation();
  };

  const handleInputChange = (event, index) => {
    if (!event.target.value) {
      event.preventDefault();
      return;
    }

    let val = event.target.value === noneValue ? null : event.target.value;

    if (!isEqual(val, value)) {
      // use isEqual as value may be an array
      setValue(val);
      handleFilterChange(val, include);
    }
  };

  const handleFilterChange = (val, include) => {
    if (val.toString().indexOf("a_") === 0) {
      val = [Number(val[0].replace("a_", ""))];
    }
    onFilterChange({
      filterKey: filterKey,
      filterValue: { value: val, exclude: include ? null : excludeValue },
      filterType: Constants.FILTER_TYPE.SELECTRATING,
    });
  };

  return (
    <div className={classes.inputWrapper}>
      {variant === "filter" && utils.hasNonEmptyValue(filterText) && (
        <div className={classes.labelText}>{filterText}</div>
      )}
      <StyledSelectFormControl>
        <StyledFilterSelect
          size={variant === "standalone" ? "lg" : "sm"}
          value={options.length === 0 ? noneValue : value}
          onChange={(e) => handleInputChange(e, 0)}
          {...SelectProps}
        >
          {isEqual(value, noneValue) && (
            <StyledMenuItem key="novalue" value={noneValue} {...MenuItemProps}>
              {Messages.LABEL.NONE}
            </StyledMenuItem>
          )}
          <StyledMenuItem disabled={true} className={classes.includeMenuItem}>
            <FormControlLabel
              classes={{ root: classes.includeLabelRoot, label: classes.includeLabel }}
              control={
                <Checkbox
                  size="small"
                  checked={include}
                  classes={{ root: classes.includeCheckboxRoot }}
                  icon={<CheckBoxOutlineBlankIcon classes={{ root: classes.iconRoot }} />}
                  checkedIcon={<CheckBoxIcon color="secondary" classes={{ root: classes.iconRoot }} />}
                />
              }
              label={Messages.LABEL.INCLUDE_UNRATED}
              color="primary"
              onClick={handleIncludeChecked}
            />
          </StyledMenuItem>
          {utils.hasNonEmptyValue(rangeOptions) && <PopperHeader>{Messages.LABEL.RANGE}</PopperHeader>}
          {rangeOptions.map((option, i) => {
            if (!option.value) {
              return null;
            }
            const item = (
              <StyledMenuItem key={i} value={option.value} {...MenuItemProps}>
                {option.label}
              </StyledMenuItem>
            );
            return item;
          })}
          {utils.hasNonEmptyValue(minimumOptions) && <PopperHeader>{Messages.LABEL.MINIMUM}</PopperHeader>}
          {minimumOptions.map((option, i) => {
            if (!option.value) {
              return null;
            }
            const item = (
              <StyledMenuItem key={i} value={option.value} {...MenuItemProps}>
                {option.label}
              </StyledMenuItem>
            );
            return item;
          })}
        </StyledFilterSelect>
      </StyledSelectFormControl>
    </div>
  );
};

SelectRatingFilter.FilterValueDefinition = PropTypes.shape({
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.arrayOf(PropTypes.string),
    PropTypes.arrayOf(PropTypes.number),
  ]),
  exclude: PropTypes.number,
});

SelectRatingFilter.FilterDefinition = {
  filterKey: PropTypes.string,
  filterValue: SelectRatingFilter.FilterValueDefinition,
  filterType: PropTypes.oneOf(Object.values(Constants.FILTER_TYPE)),
};

SelectRatingFilter.propTypes = {
  filter: PropTypes.shape(SelectRatingFilter.FilterDefinition),
  filterKey: PropTypes.string.isRequired,
  optionsKey: PropTypes.oneOf(Object.keys(DataOptions.OPTIONS)).isRequired,
  onFilterChange: PropTypes.func.isRequired,
  variant: PropTypes.oneOf(["filter", "standalone"]),
  SelectProps: PropTypes.object,
  MenuItemProps: PropTypes.object,
};

export default SelectRatingFilter;
