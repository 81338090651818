import { setGlobal } from "reactn";
import { cloneDeep, keyBy, isEqual } from "lodash";

import HttpService from "services/commons/HttpService";
import * as DataConfig from "config/dataconfig";
import { QUERY_TYPE } from "commons/constants";

let _favoritesCache = null;
let _favoriteIssuersCache = null;

export const getFavorites = () => {
  return _favoritesCache;
};

export const getFavoriteIssuers = () => {
  return _favoriteIssuersCache;
};

export const fetchAllFavorites = () => {
  fetchFavorites();
  fetchFavoriteIssuers();
};

export const fetchFavorites = async (reloadId) => {
  const params = {
    method: "GET",
    query: QUERY_TYPE.FAVORITES,
    url: `${DataConfig.FAVORITES_ENDPOINT}?type=cusip&size=500${reloadId ? `&reload=${reloadId}` : ""}`,
  };
  HttpService(params)
    .then((response) => {
      const favorites = response.data.content;
      setGlobal({ favoritesRefreshedTime: response.data.refreshedTime });

      if (!isEqual(JSON.stringify(_favoritesCache), JSON.stringify(favorites))) {
        _favoritesCache = favorites;
        setGlobal({ favorites: favorites });
      }
    })
    .catch((e) => {
      console.error(`Error fetching favs: ${e}`);
      setGlobal({ favorites: [] });
    });
};

const fetchFavoriteIssuers = async () => {
  const params = {
    method: "GET",
    query: QUERY_TYPE.ISSUER_TRACKER,
    url: `${DataConfig.FAVORITES_ENDPOINT}?type=issuer&size=500`,
  };

  HttpService(params)
    .then((response) => {
      if (response.data.content) {
        const favoriteIssuers = keyBy(response.data.content, "cusip");

        if (!isEqual(JSON.stringify(_favoriteIssuersCache), JSON.stringify(favoriteIssuers))) {
          _favoriteIssuersCache = favoriteIssuers;
          setGlobal({ favoriteIssuers: favoriteIssuers });
        }
      }
    })
    .catch((e) => {
      console.error(`Error fetching favIssuers: ${e}`);
      setGlobal({ favoriteIssuers: [] });
    });
};

export const addFavorite = (bond) => {
  const params = {
    url: `${DataConfig.FAVORITES_ENDPOINT}/`,
    method: "post",
    data: { cusip: bond.cusip, type: "cusip" },
  };

  // Create a "fake" entry so we're not waiting on the service response
  const newCacheEntry = {};
  newCacheEntry.inventoryBondInformation = cloneDeep(bond);

  _favoritesCache.unshift(newCacheEntry);
  setGlobal({ favorites: _favoritesCache });

  HttpService(params)
    .then((response) => {
      // When we get the response, swap out the cache entry for the "real" backend entry
      // so we have additional data like enteredUser
      _favoritesCache = _favoritesCache.filter(
        (f) => f?.inventoryBondInformation?.cusip !== response.data.cusip,
      );
      _favoritesCache.unshift(response.data);
      setGlobal({ favorites: _favoritesCache });
    })
    .catch((e) => {});
};

export const addFavoriteIssuer = (issuer) => {
  if (!issuer) return;

  _favoriteIssuersCache[issuer.cusip] = issuer;
  setGlobal({ favoriteIssuers: _favoriteIssuersCache });

  const params = {
    url: `${DataConfig.FAVORITES_ENDPOINT}/`,
    method: "post",
    data: { cusip: issuer.cusip, type: "issuer" },
  };

  HttpService(params)
    .then((response) => {
      if (response.data) {
        _favoriteIssuersCache[response.data.cusip] = response.data;
        setGlobal({ favoriteIssuers: _favoriteIssuersCache });
      }
    })
    .catch((e) => {});
};

export const addFavorites = (cusips) => {
  cusips.forEach((cusip, i) => {
    const params = {
      url: `${DataConfig.FAVORITES_ENDPOINT}/`,
      method: "post",
      data: { cusip: cusip, type: "cusip" },
    };

    HttpService(params)
      .then((response) => {
        if (i === cusips.length - 1) {
          fetchFavorites();
        }
      })
      .catch((e) => {
        // some have succeeded, so fetch
        if (cusips.length > 1 && i === cusips.length - 1) {
          fetchFavorites();
        }
      });
  });
};

export const addFavoriteIssuers = (issuers) => {
  issuers.forEach((i) => (_favoriteIssuersCache[i.cusip] = i));
  setGlobal({ favoriteIssuers: _favoriteIssuersCache });

  issuers.forEach((issuer, i) => {
    const params = {
      url: `${DataConfig.FAVORITES_ENDPOINT}/`,
      method: "post",
      data: { cusip: issuer.cusip, type: "issuer" },
    };

    HttpService(params)
      .then((response) => {
        if (response.data) {
          _favoriteIssuersCache[response.data.cusip] = response.data;
          setGlobal({ favoriteIssuers: _favoriteIssuersCache });
        }
      })
      .catch((e) => {});
  });
};

export const deleteFavorite = (cusip) => {
  _favoritesCache = _favoritesCache.filter((f) => f.cusip !== cusip);
  setGlobal({ favorites: _favoritesCache });

  const params = {
    url: `${DataConfig.FAVORITES_ENDPOINT}/${cusip}?type=cusip`,
    method: "delete",
  };

  HttpService(params)
    .then((response) => {})
    .catch((e) => {});
};

export const deleteFavoriteIssuer = (cusip) => {
  delete _favoriteIssuersCache[cusip];
  setGlobal({ favoriteIssuers: _favoriteIssuersCache });

  const params = {
    url: `${DataConfig.FAVORITES_ENDPOINT}/${cusip}?type=issuer`,
    method: "delete",
  };

  HttpService(params)
    .then((response) => {})
    .catch((e) => {});
};
