import React from "react";
import PropTypes from "prop-types";
import { get } from "lodash";

import { useViewStateDispatcher, useViewState } from "providers/ViewStateProvider";
import * as utils from "commons/utils";
import * as Constants from "commons/constants";
import { useMessages } from "providers/BrandingProvider";
import { TextAreaInput } from "components/Controls";
import DataTable from "components/datatable/DataTable";
import * as ticketUtils from "./tickets/ticketUtils";
import TicketHeader from "./tickets/TicketHeader";
import ModalMessages from "./common/ModalMessages";
import {
  TicketBody,
  TicketFooter,
  TicketRow,
  TicketRowSection,
  TicketButton,
  Spacer,
  UserMessage,
  NarrowTableWrapper,
} from "./tickets/TicketComponents";
import { StyledTooltip, LinkButton, FormOutlinedButton } from "components/Controls";
import { UnclosableModal } from "components/containers/Modals";
import { useDataService } from "services/DataService";
import QBTypography from "components/QBTypography";

import Button from "@material-ui/core/Button";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { alpha } from "@material-ui/core/styles/colorManipulator";
import { getUser } from "commons/helpers/userStorage";
import { fetchMarketHours } from "services/UtilityService";
import { InformationDialog } from "./InformationDialog";
import { userHasAnyPermission } from "commons/utils";
import { USER_PERMISSIONS } from "commons/constants";
import { INVENTORY_TYPE } from "commons/constants";
import AccountSelectionModal from "./AccountSelectionModal";
import { getAffiliateInstitutions } from "commons/helpers/userStorage";

const useStyles = makeStyles((theme) => ({
  account: {
    color: theme.palette.secondary.dark,
  },
  affiliate: {
    fontSize: "0.8rem",
  },
}));

const TICKET_WIDTH = {
  CD: 1250,
  Agency: 1025,
  Muni: 1015,
  Treasury: 975,
  MBS: 1250,
};

const FDIC_LIMIT = 250000;

export const TradeTicket = ({ open, bonds, ticketInfo = {}, onCloseTradeTicket, onCartUpdated }) => {
  const classes = useStyles();
  const Messages = useMessages();
  const user = getUser();

  const [status, setStatus] = React.useState(ticketUtils.TICKET_STATUS.EDIT);
  const [error, setError] = React.useState(ticketUtils.ERROR.NONE);

  const [buyer, setBuyer] = React.useState();

  const [accountAlertModalOpen, setAccountAlertModalOpen] = React.useState(false);

  const [userMessage, setUserMessage] = React.useState("");
  const [data, setData] = React.useState([]);
  const [conflicted, setConflicted] = React.useState([]);
  const [failures, setFailures] = React.useState([]);

  const [alternateView, setAlternateView] = React.useState(true);
  const [marketStatus, setMarketStatus] = React.useState(Constants.MARKET_STATUS.OPEN);
  const [earlyCloseStatus, setEarlyCloseStatus] = React.useState(false);
  const [qwickBondsClosedTime, setQwickBondsClosedTime] = React.useState("4:30 PM EST");
  const [confirmationOpen, setConfirmationOpen] = React.useState(false);
  const [referrer, setReferrer] = React.useState(null);

  let [infoMessages, setInfoMessages] = React.useState([]);
  let [warningMessages, setWarningMessages] = React.useState([]);
  let [errorMessages, setErrorMessages] = React.useState([]);

  const [refreshTime, setRefreshTime] = React.useState();

  const [focusedElementId, setFocusedElementId] = React.useState();

  const viewState = useViewState();
  const activeView = get(viewState, "activeView");
  const activeSubView = get(viewState, [activeView, "activeSubView"]);
  const activeQuerySubType = get(viewState, [activeView, activeSubView, "activeQuerySubType"]);
  const currentViewState = utils.findCurrentViewState(viewState);

  const dispatchViewStateChange = useViewStateDispatcher();
  const bondsInCart = ticketUtils.readyTicket(bonds);

  const tableInstrumentCategory =
    bondsInCart.length && bondsInCart[0].instrumentCategory === Constants.INVENTORY_TYPE.MORTGAGE
      ? Constants.INVENTORY_TYPE.MORTGAGE
      : Constants.INVENTORY_TYPE.CD;
  const [queryCalculatorState, queryCalculator] = useDataService();
  const [queryLookupState, queryLookup] = useDataService();
  const [postTradeState, postTrade] = useDataService();
  const [queryQuanityAdjustState, queryQuanityAdjust] = useDataService();

  const statusRef = React.useRef();
  const refetchRef = React.useRef();
  const userCanTrade = userHasAnyPermission(user, [USER_PERMISSIONS.CAN_TRADE]);
  const is_mcap_env = process.env.REACT_APP_FEATURES_IS_MCAP === "true";
  const userIsHistoricalQR = user.hasOwnProperty('historicalQRCustomer') && user['historicalQRCustomer'] === true;
  const affiliateInstitutions = getAffiliateInstitutions();
  const canTradeForOthers = userCanTrade && user.affiliateAdmin && affiliateInstitutions?.length > 0;

  React.useEffect(() => {
    window.addEventListener("blur", handleWindowBlur, false);

    return function () {
      window.removeEventListener("blur", handleWindowBlur, false);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    if (open) {
      // if only one clearing account, set it
      if (user?.brokeredClearingAccounts?.length === 1 && !canTradeForOthers) {
        setBuyer({ brokeredClearingAccount: user.brokeredClearingAccounts[0] });
      }

      // alert user of account they are trading under
      if (
        buyer?.brokeredClearingAccount != null &&
        (user?.brokeredClearingAccounts.length > 1 || canTradeForOthers)
      ) {
        setAccountAlertModalOpen(true);
      }
      refetchRef.current = false;
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  React.useEffect(() => {
    statusRef.current = status;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status]);

  React.useEffect(() => {
    handleCalculatorResponse(queryCalculatorState);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryCalculatorState.data]);

  React.useEffect(() => {
    handleLookupResponse(queryLookupState);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryLookupState.data]);

  React.useEffect(() => {
    handleSubmitTradeResponse(postTradeState);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [postTradeState.data]);

  React.useEffect(() => {
    handleAdjustQuantityResponse(queryQuanityAdjustState);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryQuanityAdjustState.data]);

  React.useEffect(() => {
    resetErrorState();
    setStatus(ticketUtils.TICKET_STATUS.EDIT);
    setFailures([]);
    setConflicted([]);
    setUserMessage("");

    if (open) {
      if (bondsInCart.length && bondsInCart[0].returnURL && bondsInCart[0].returnSiteName) {
        setReferrer({
          returnURL: bondsInCart[0].returnURL,
          returnSiteName: bondsInCart[0].returnSiteName,
          referrerId: bondsInCart[0].referrerid,
        });
      } else {
        setReferrer(null);
      }

      validateBondAvailability(bondsInCart);
    } else {
      setData([]);
      setReferrer(null);
      setRefreshTime(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open, bonds]);

  const getValididatedData = () => {
    return data.filter(
      (d) =>
        !utils.hasNonEmptyValue(d.errors) &&
        utils.hasNonEmptyValue(get(d, "principal") && utils.hasNonEmptyValue(get(d, "accruedInterest"))),
    );
  };

  const isValidTicket =
    userCanTrade && utils.hasNonEmptyValue(data) && data.length === getValididatedData().length;

  const handleWindowBlur = () => {
    setReferrer(null);
    if (statusRef.current === ticketUtils.TICKET_STATUS.COMPLETE) {
      closeTradeTicket();
    }
  };

  const handleCloseTradeTicketAfterSuccessOrCancel = () => {
    if (!ticketInfo.hasNextTicket && referrer?.referrerId === "1") {
      setConfirmationOpen(true);
    } else {
      closeTradeTicket(true);
    }
  };

  const handleContinueExploring = () => {
    onCloseTradeTicket(false, tableInstrumentCategory);
  };

  const handleCancelTradeTicket = () => {
    onCartUpdated({ deleted: bonds });
    handleCloseTradeTicketAfterSuccessOrCancel();
  };

  const closeTradeTicket = (populateNextTicket) => {
    if (status === ticketUtils.TICKET_STATUS.COMPLETE && utils.hasNonEmptyValue(data)) {
      const highlighted = data.map((d) => d.id);
      dispatchViewStateChange({
        state: {
          activeTool: Constants.TOOL_TYPE.STATUS,
          query: {
            searchId: Date.now(),
            highlighted: highlighted,
          },
        },
        view: Constants.VIEW.STATUS,
      });
    }

    if (refetchRef.current && utils.isFreshnessSensitiveView(activeView, activeSubView)) {
      const updatedState = {
        state: {
          activeView: activeView,
          activeSubView: activeSubView,
          activeQuerySubType: activeQuerySubType,
          query: {
            ...currentViewState.query,
            searchId: Date.now(),
            reloadId: Date.now(),
          },
        },
        view: activeView,
        subView: activeSubView,
        querySubType: activeQuerySubType,
      };

      dispatchViewStateChange(updatedState);
    }

    setBuyer(null);
    onCloseTradeTicket(populateNextTicket, tableInstrumentCategory);
  };

  const validateBond = (datum) => {
    errorMessages = errorMessages.filter((m) => m.indexOf(datum.cusip) !== 0);
    ticketUtils.validateBuyQuantity(datum, errorMessages);
  };

  const resetErrorState = () => {
    infoMessages = [];
    setInfoMessages(infoMessages);
    warningMessages = [];
    setWarningMessages(warningMessages);
    errorMessages = [];
    setErrorMessages(errorMessages);
    setError(ticketUtils.ERROR.NONE);
  };

  const enablePreview = () => {
    resetErrorState();
    validateBondAvailability(data);
    setStatus(ticketUtils.TICKET_STATUS.PREVIEW);
    //validateAndSetData(getValididatedData());
    setFailures([]);
  };

  const enableEditing = () => {
    resetErrorState();
    setStatus(ticketUtils.TICKET_STATUS.EDIT);

    validateAndSetData(data);
    setFailures([]);
  };

  const handlePlaceTrade = () => {
    if (!user[Constants.USER_PERMISSIONS.CAN_TRADE]) {
      setError(ticketUtils.ERROR.NOT_A_TRADER_ACCOUNT);
      setErrorMessages([Messages.MESSAGE.NOT_A_TRADER_ACCOUNT]);
      return;
    }

    resetErrorState();
    validateBondAvailability(getValididatedData(), true);
  };

  const handleRetryLookup = () => {
    resetErrorState();
    // TODO: remove failures??
    validateBondAvailability(data);
  };

  const handleResubmitTrade = () => {
    resetErrorState();
    validateBondAvailability(data, true);
  };

  const handleCloseFailedBondsModal = () => {
    onCartUpdated({ deleted: failures });
    setFailures([]);
  };

  const handleConfirmUpdates = () => {
    resetErrorState();

    const bonds = data.concat(conflicted);
    /* Reset errors, otherwise updatePrincipalAndInterest will reject them */
    bonds.forEach((b) => (b.errors = []));

    if (status === ticketUtils.TICKET_STATUS.EDIT) {
      updatePrincipalAndInterest(bonds);
    } else {
      validateAndSetData(bonds);
    }

    setConflicted([]);
  };

  const handleConflictTableValueChange = (props) => {
    const accessor = props.column.id;

    if (accessor === "delete") {
      const deletedBond = conflicted.splice(props.index, 1);
      setConflicted(utils.cloneArrayOfObjects(conflicted));
      onCartUpdated({ deleted: deletedBond });
      if (!utils.hasNonEmptyValue(conflicted) && status === ticketUtils.TICKET_STATUS.EDIT) {
        resetErrorState();
        updatePrincipalAndInterest(data);
      }
    }
  };

  const handleValueChange = (props, value, relatedTarget) => {
    const accessor = props.column.id;

    if (relatedTarget) {
      setFocusedElementId(relatedTarget.id);
    } else {
      setFocusedElementId(null);
    }

    if (accessor === "delete") {
      const deletedBond = data.splice(props.index, 1);
      onCartUpdated({ deleted: deletedBond });
      resetErrorState();
      validateAndSetData(data);
    } else if (accessor === "isOverFDICLimit") {
      adjustQuantityWithinFDICLimit(props.original);
    } else if (accessor === "requiredPartialQty") {
      adjustQuantityWithPartialQty(props.original);
    } else {
      const rawValue = value.trim();
      const currentValue = utils.hasNonEmptyValue(props.row[accessor])
        ? props.row[accessor].toString()
        : props.row[accessor];
      if (currentValue !== rawValue) {
        data[props.index][accessor] = rawValue;

        if (accessor === "quantity_edit") {
          data[props.index].quantity = rawValue;
        }
        validateBond(data[props.index]);
        updatePrincipalAndInterest(data);
        validateAndSetData(data);
      }
    }
  };

  const adjustQuantityWithinFDICLimit = (bond) => {
    //resetErrorState();

    if (utils.hasNonEmptyValue(bond)) {
      var params = {
        brokeredSecurityId: bond.brokeredsecurityid,
        amountDollars: FDIC_LIMIT,
      };

      const queryParams = {
        type: Constants.QUERY_TYPE.ADJUST_QUANTITY,
        clearDataOnFetch: false,
        params: [params],
      };

      queryQuanityAdjust(queryParams);
    }
  };

  const adjustQuantityWithPartialQty = (bond) => {
    bond.quantity_edit = Math.floor(bond.quantity_edit) + bond.requiredPartialQty;
    utils.removeFromArray(bond.errors, "quantity_edit");
    updatePrincipalAndInterest(data);
  };

  const handleAdjustQuantityResponse = (queryQuanityAdjustState) => {
    if (queryQuanityAdjustState.isError || utils.hasNonEmptyValue(queryQuanityAdjustState.errors)) {
      setErrorMessages([Messages.MESSAGE.LOOKUP_ERROR]);
    } else if (!queryQuanityAdjustState.isLoading) {
      data.forEach((datum) => {
        const brokeredsecurityid = datum.brokeredsecurityid;

        if (utils.hasNonEmptyValue(queryQuanityAdjustState.data[brokeredsecurityid])) {
          //datum.quantity = queryQuanityAdjustState.data[brokeredsecurityid];
          datum.quantity_edit = queryQuanityAdjustState.data[brokeredsecurityid];
          datum.isOverFDICLimit = false;
        }

        validateBond(datum);
      });

      updatePrincipalAndInterest(data);
    }
  };

  const updateMarketAvailability = async () => {
    const marketHours = await fetchMarketHours();
    if (marketHours) {
      if (!marketHours.inQBHours) {
        setMarketStatus(
          marketHours.marketClosing ? Constants.MARKET_STATUS.EOD : Constants.MARKET_STATUS.CLOSED,
        );
      }
      if (marketHours.earlyCloseDay) {
        setEarlyCloseStatus(true);
        setQwickBondsClosedTime(marketHours.qwickBondsClosedTime);
      }
    }
  };

  const validateBondAvailability = (bonds, isSubmit) => {
    var ids = [];
    var cusips = [];

    updateMarketAvailability();
    resetErrorState();
    validateAndSetData(bonds);

    bonds
      .filter((d) => !utils.hasNonEmptyValue(d.errors))
      .forEach((datum, i) => {
        ids.push(datum.brokeredsecurityid);
        cusips.push(datum.cusip);
      });

    if (utils.hasNonEmptyValue(ids)) {
      const queryParams = {
        type: Constants.QUERY_TYPE.AVAILABILITY_LOOKUP,
        ids: ids,
        cusips: cusips,
        clearDataOnFetch: false,
        isSubmit: isSubmit,
      };

      queryLookup(queryParams);
    }
  };

  const handleLookupResponse = (queryLookupState) => {
    resetErrorState();

    if (queryLookupState.isError) {
      setErrorMessages([Messages.MESSAGE.LOOKUP_ERROR]);
      setConflicted([]);
      setFailures([]);
      setError(ticketUtils.ERROR.LOOKUP_FAIL);
    } else if (!queryLookupState.isLoading && !queryLookupState.isInit) {
      setRefreshTime(queryLookupState.refreshedTime);

      const availabilityData = queryLookupState.data;
      const successes = [];
      const failed = [];
      const conflicts = [];

      let updatedBond;
      data.forEach((bond, i) => {
        const id = bond.brokeredsecurityid;
        bond.errors = [];
        bond.warnings = [];
        bond.noted = [];

        if (availabilityData.hasOwnProperty(id)) {
          updatedBond = availabilityData[id];

          if (!utils.hasNonEmptyValue(updatedBond)) {
            failed.push(bond);
          } else {
            delete bond.price_previous;
            delete bond.yieldToMaturity_previous;
            delete bond.yieldToWorst_previous;
            delete bond.quantity_previous;

            bond.quantity_inventory = updatedBond.quantity;

            if (updatedBond.price > bond.price) {
              bond.errors = ["price", "yieldToMaturity", "yieldToWorst"];
            }

            if (updatedBond.quantity < bond.quantity_edit) {
              bond.quantity_previous = bond.quantity;
              bond.quantity_edit = updatedBond.quantity;
              bond.quantity = updatedBond.quantity;
              utils.addToArray(bond.errors, "quantity");
            }

            bond.price_previous = bond.price;
            bond.price = updatedBond.price;
            bond.yieldToMaturity_previous = bond.yieldToMaturity;
            bond.yieldToMaturity = updatedBond.yieldToMaturity;
            bond.yieldToWorst_previous = bond.yieldToWorst;
            bond.yieldToWorst = updatedBond.yieldToWorst;

            if (utils.hasNonEmptyValue(bond.errors)) {
              conflicts.push(bond);
            } else {
              validateBond(bond);
              successes.push(bond);
            }
          }
        } else {
          if (bond.data) {
            conflicts.push(bond.data);
          } else {
            bond.cusip = bond.cusip || Messages.LABEL.UNKNOWN;
            bond.issuer = bond.issuer || "";
            failed.push(bond);
          }
        }
      });

      if (conflicts.length > 0) {
        refetchRef.current = true;
      }

      setConflicted(utils.cloneArrayOfObjects(conflicts));
      setFailures(utils.cloneArrayOfObjects(failed));

      if (!utils.hasNonEmptyValue(conflicts)) {
        if (utils.hasNonEmptyValue(successes)) {
          validateAndSetData(successes);
          if (queryLookupState.query.isSubmit && !utils.hasNonEmptyValue(failed)) {
            submitTrade(successes);
          } else if (status === ticketUtils.TICKET_STATUS.EDIT) {
            updatePrincipalAndInterest(successes);
          }
        } else {
          validateAndSetData([]);
        }
      } else {
        validateAndSetData(successes);
      }
    }
  };

  const submitTrade = (bonds) => {
    resetErrorState();
    setFailures([]);

    var params = [];

    bonds.forEach((datum, i) => {
      var rec = {
        id: i,
        quantity: datum.quantity_edit,
        brokeredSecurityId: datum.brokeredsecurityid,
        brokeredClearAccountId: buyer.brokeredClearingAccount.id,
      };

      if (buyer.affiliateTrader) {
        rec.submittedForId = buyer.affiliateTrader.id;
      }

      if (datum.portfolioid) {
        rec.portfolioId = datum.portfolioid;
      }

      if (datum.referrerid) {
        rec.referrerId = datum.referrerid;
      }

      if (utils.hasNonEmptyValue(userMessage)) {
        rec.notes = userMessage.trim().substring(0, 256);
      }

      params.unshift(rec);
    });

    if (utils.hasNonEmptyValue(params)) {
      const queryParams = {
        type: Constants.QUERY_TYPE.TRADE_TICKET,
        params: params,
        clearDataOnFetch: false,
      };

      postTrade(queryParams);
    }
  };

  const handleSubmitTradeResponse = (postTradeState) => {
    resetErrorState();
    setRefreshTime(null);

    if (postTradeState.isError) {
      setErrorMessages([Messages.MESSAGE.TRADE_FAIL_RETRY]);
      setError(ticketUtils.ERROR.SUBMIT_FAIL);
    } else {
      const succeeded = ticketUtils.readyTicket(Object.values(postTradeState.data));
      const failed = postTradeState.errors;
      const failedData = [];

      if (utils.hasNonEmptyValue(failed)) {
        for (let e in failed) {
          if (failed.hasOwnProperty(e)) {
            const failedRec = utils.findInArray(data, "id", parseInt(e));
            failedData.push(failedRec[0]);
          }
        }
      }

      if (utils.hasNonEmptyValue(succeeded)) {
        const msgs = [Messages.MESSAGE.TRADE_SUCCESS];
        if (utils.hasNonEmptyValue(referrer)) {
          msgs.push(
            Messages.MESSAGE.RETURN_TO_REFERRER_PURCHASE_COMPLETE.replace("{0}", referrer.returnSiteName),
          );
        }
        setInfoMessages(msgs);

        if (marketStatus !== Constants.MARKET_STATUS.OPEN) {
          const msg =
            marketStatus === Constants.MARKET_STATUS.CLOSED
              ? Messages.MESSAGE.MARKET_CLOSED
              : Messages.MESSAGE.MARKET_EOD.replace("{0}", qwickBondsClosedTime);
          warningMessages.push(msg);
          setWarningMessages(warningMessages);
        }
      }
      setStatus(ticketUtils.TICKET_STATUS.COMPLETE);
      setFailures(utils.cloneArrayOfObjects(failedData));
      setData(utils.cloneArrayOfObjects(succeeded));
      onCartUpdated({ deleted: succeeded });
    }
  };

  const updatePrincipalAndInterest = (bonds) => {
    var params = [];

    bonds.forEach((bond, i) => {
      if (!bond.errors.includes("quantity_edit")) {
        const param = {
          brokeredSecurityId: bond.brokeredsecurityid,
        };

        if (utils.hasNonEmptyValue(bond.quantity_edit)) {
          if (bond.instrumentCategory === Constants.INVENTORY_TYPE.MORTGAGE) {
            param.amountDollars = bond.quantity_edit * 1000;
          } else {
            param.quantityThousands = bond.quantity_edit;
          }
        }

        params.push(param);

        bond.accruedInterest = Constants.RESPONSE.LOADING;
        bond.principal = Constants.RESPONSE.LOADING;
      }
    });

    if (utils.hasNonEmptyValue(params)) {
      const queryParams = {
        type: Constants.QUERY_TYPE.PRINCIPAL_INTEREST_LOOKUP,
        params: params,
        clearDataOnFetch: false,
      };

      queryCalculator(queryParams);
    }

    validateAndSetData(bonds);
  };

  const handleCalculatorResponse = (queryCalculatorState) => {
    resetErrorState();

    if (queryCalculatorState.isError) {
      setErrorMessages([Messages.MESSAGE.LOOKUP_ERROR]);
      setError(ticketUtils.ERROR.LOOKUP_FAIL);
    } else if (!queryCalculatorState.isLoading) {
      data.forEach((datum) => {
        const brokeredsecurityid = datum.brokeredsecurityid;

        if (utils.hasNonEmptyValue(queryCalculatorState.errors[brokeredsecurityid])) {
          setError(ticketUtils.ERROR.LOOKUP_FAIL);
          errorMessages.push(Messages.MESSAGE.LOOKUP_ERROR);
          datum.originalFace = null;
          datum.currentFace = null;
          datum.accruedInterest = null;
          datum.principal = null;
          datum.total = 0;
        } else if (utils.hasNonEmptyValue(queryCalculatorState.data[brokeredsecurityid])) {
          const calcResponse = queryCalculatorState.data[brokeredsecurityid];
          datum.originalFace = calcResponse.originalFaceMBS;
          datum.currentFace = calcResponse.currentFaceMBS;
          datum.accruedInterest = calcResponse.accruedInterest;
          datum.principal = calcResponse.principal;
          datum.total = calcResponse.total;
        }
      });

      validateAndSetData(data);
    }
  };

  const handleAccountSelected = ({ brokeredClearingAccount, affiliateTrader, affiliateInstitution }) => {
    setBuyer({ brokeredClearingAccount, affiliateTrader, affiliateInstitution });
  };

  /**
   * Validates data, sets user messaging and triggers render.  Note that errorState may not
   * have been reset and may carry desired messaging.  Be sure to retain any existing messaging.
   *
   * @param {<Array.<Object>} data
   */
  const validateAndSetData = (data) => {


    const anyOfTheseAreCDs = function(data){
      let haveCDs = false;
      data.forEach((datum) => {
        if (datum.instrumentType.description === Constants.INSTRUMENT_TYPE.CD_PRIMARY ||
          datum.instrumentType.description === Constants.INSTRUMENT_TYPE.CD_SECONDARY  ) {
          haveCDs = true;
        }
      });
      return haveCDs;
    }

    if (!userCanTrade) {
      errorMessages.push(Messages.MESSAGE.NOT_A_TRADER_ACCOUNT);
    } else {
      data.forEach((datum) => validateBond(datum));

      /**
       * INFO MESSAGES
       */

      // price drop
      let hasPriceDrop = false;

      if (status !== ticketUtils.TICKET_STATUS.COMPLETE) {
        data.forEach((datum) => {
          if (datum.price < datum.price_previous) {
            utils.addToArray(datum.noted, "price");
            hasPriceDrop = true;
          }
        });
      }

      if (hasPriceDrop) {
        infoMessages.push(Messages.MESSAGE.TRADE_PRICE_DROP);
      } else {
        utils.removeFromArray(infoMessages, Messages.MESSAGE.TRADE_PRICE_DROP);
      }

      /**
       * WARNING MESSAGES
       */

      //early close message
      if (earlyCloseStatus) {
        const msg = Messages.MESSAGE.EARLY_CLOSED;
        warningMessages.push(msg);
      }

      // market closed
      if (marketStatus !== Constants.MARKET_STATUS.OPEN && data?.length) {
        const msg =
          marketStatus === Constants.MARKET_STATUS.CLOSED
            ? Messages.MESSAGE.MARKET_CLOSED
            : Messages.MESSAGE.MARKET_EOD.replace("{0}", qwickBondsClosedTime);
        warningMessages.push(msg);
      } else {
        utils.removeFromArray(warningMessages, Messages.MESSAGE.MARKET_CLOSED);
      }

      // exceeds total threhold
      if (ticketUtils.hasTotalThreshold(data)) {
        warningMessages.push(Messages.MESSAGE.EXCEED_TOTAL_THRESHOLD);
      } else {
        utils.removeFromArray(warningMessages, Messages.MESSAGE.EXCEED_TOTAL_THRESHOLD);
      }

      // has holdings in portfolio
      let hasHoldings = false;

      if (status !== ticketUtils.TICKET_STATUS.COMPLETE) {
        data.forEach((datum) => {
          if (datum.hasHoldings) {
            utils.addToArray(datum.warnings, "insuranceNumber");
            hasHoldings = true;
          }
        });
      }

      if (!is_mcap_env && hasHoldings) {
        warningMessages.push(Messages.MESSAGE.HOLDINGS_WARNING);
      } else {
        utils.removeFromArray(warningMessages, Messages.MESSAGE.HOLDINGS_WARNING);
      }

      if( anyOfTheseAreCDs(data) && is_mcap_env && userIsHistoricalQR ){
        warningMessages.push(Messages.MESSAGE.QR_USER_HOLDINGS_WARNING);
      } else {
        utils.removeFromArray(warningMessages, Messages.MESSAGE.QR_USER_HOLDINGS_WARNING);
      }


      data.forEach((datum) => {
        if (datum.makeWholeCall) {
          warningMessages.push(Messages.MESSAGE.MAKE_WHOLE_CALL_WARNING.replace("{0}", datum.cusip));
        }
        if (datum.preRefunded) {
          warningMessages.push(Messages.MESSAGE.PREREFUNDED_WARNING.replace("{0}", datum.cusip));
        }
        if (datum.stateRestrictions?.length > 0) {
          const states = datum.stateRestrictions.map((k) => k.state.stateAbbreviation).join(", ");
          warningMessages.push(
            Messages.MESSAGE.RESTRICTED_STATES_WARNING.replace("{0}", datum.cusip).replace("{1}", states),
          );
        }
      });

      /**
       * ERROR MESSAGES
       */

      // exceeds FDIC limit
      const fdicMap = {};
      data.forEach((datum) => {
        datum.duplicate = true;
        if (
          datum.instrumentCategory === Constants.INVENTORY_TYPE.CD &&
          utils.hasNonEmptyValue(datum.insuranceNumber)
        ) {
          fdicMap[datum.insuranceNumber] = fdicMap[datum.insuranceNumber] || { total: 0, numItems: 0 };
          fdicMap[datum.insuranceNumber].total = fdicMap[datum.insuranceNumber].total + Number(datum.total);
          fdicMap[datum.insuranceNumber].numItems++;
        }
      });

      data.forEach((datum) => {
        if (fdicMap.hasOwnProperty(datum.insuranceNumber)) {
          const totals = fdicMap[datum.insuranceNumber];
          datum.isOverFDICLimit = totals.total > FDIC_LIMIT && totals.numItems === 1;
        }
      });

      const hasFdicThreshold = Object.values(fdicMap).some((t) => t.total > FDIC_LIMIT);
      const hasAdjustableFdicThreshold = data.some((d) => d.isOverFDICLimit);

      if (hasFdicThreshold) {
        errorMessages.push(Messages.MESSAGE.EXCEED_FDIC_THRESHOLD);
        if (hasAdjustableFdicThreshold) {
          errorMessages.push(Messages.MESSAGE.EXCEED_FDIC_THRESHOLD_FIX);
        }
      } else {
        utils.removeFromArray(errorMessages, Messages.MESSAGE.EXCEED_FDIC_THRESHOLD);
        utils.removeFromArray(errorMessages, Messages.MESSAGE.EXCEED_FDIC_THRESHOLD_FIX);
      }

      /**
       * ALTERNATE VIEW (renders 'Adjust' link column)
       */
      const hasRequiredPartialQty = data.some((d) => d.requiredPartialQty > 0);
      setAlternateView(hasAdjustableFdicThreshold || hasRequiredPartialQty);
    }

    setInfoMessages(infoMessages);
    setWarningMessages(warningMessages);
    setErrorMessages(errorMessages);

    setData(utils.cloneArrayOfObjects(data));
    onCartUpdated({ updated: data });
  };

  const ticketWidth = TICKET_WIDTH[tableInstrumentCategory];

  const accountSelectionModalOpen =
    open &&
    bondsInCart?.length > 0 &&
    buyer?.brokeredClearingAccount == null &&
    (user.brokeredClearingAccounts?.length > 1 || canTradeForOthers);

  return (
    <>
      <UnclosableModal
        header={Messages.LABEL.TRADE_TICKET}
        open={open} //&& !utils.hasNonEmptyValue(failures) && !utils.hasNonEmptyValue(conflicted)
        obfuscatedBackdrop={utils.hasNonEmptyValue(referrer)}
      >
        <div className={classes.root} style={{ maxWidth: ticketWidth }}>
          <TicketHeader
            infoMessages={infoMessages}
            warningMessages={warningMessages}
            errorMessages={errorMessages}
            cart={data}
            ticketInfo={ticketInfo}
            refreshTime={refreshTime}
            instrumentCategory={
              tableInstrumentCategory === INVENTORY_TYPE.MORTGAGE ? INVENTORY_TYPE.MORTGAGE : null
            }
            buyer={buyer}
            onOpenAccountSelectModal={() => {
              setBuyer(null);
            }}
            disableAccountEdit={status !== ticketUtils.TICKET_STATUS.EDIT}
            allowTradeForOthers={canTradeForOthers}
          />
          <TicketBody style={{ maxHeight: "inherit" }}>
            <>
              <DataTable
                style={{ width: ticketWidth, marginTop: 10 }}
                queryType={Constants.QUERY_TYPE.TRADE_TICKET}
                instrumentCategory={tableInstrumentCategory}
                data={data}
                editable={status === ticketUtils.TICKET_STATUS.EDIT}
                alternateView={alternateView || status !== ticketUtils.TICKET_STATUS.EDIT}
                loading={postTradeState.isLoading || queryLookupState.isLoading}
                selectable={false}
                focusedElementId={focusedElementId}
                onValueChange={handleValueChange}
                TableProps={{ className: "totals-table" }}
              />

              {!utils.hasNonEmptyValue(data) && (
                <>
                  <Spacer />
                  <TicketRow>
                    <TicketRowSection></TicketRowSection>
                    <TicketRowSection>
                      <TicketButton
                        onClick={handleCloseTradeTicketAfterSuccessOrCancel}
                        label={Messages.LABEL.CLOSE}
                      />
                    </TicketRowSection>
                  </TicketRow>
                </>
              )}

              {utils.hasNonEmptyValue(data) && (
                <>
                  <TicketRow>
                    <TicketRowSection>
                      <div style={{ marginRight: 10, marginTop: 10 }}>
                        <ModalMessages
                          messages={errorMessages}
                          level={Constants.ALERT_LEVEL.ERROR}
                          isFooterMessage={true}
                        />

                        <ModalMessages
                          messages={warningMessages}
                          level={Constants.ALERT_LEVEL.WARNING}
                          isFooterMessage={true}
                        />
                      </div>
                    </TicketRowSection>
                    <TicketRowSection>
                      <div style={{ display: "flex", justifyContent: "flex-end", marginTop: 10 }}>
                        {status !== ticketUtils.TICKET_STATUS.COMPLETE && (
                          <>
                            <FormOutlinedButton
                              style={{ margin: "0 0.5rem" }}
                              onClick={handleContinueExploring}
                            >
                              {Messages.LABEL.CONTINUE_SHOPPING}
                            </FormOutlinedButton>
                            <TicketButton
                              onClick={handleCancelTradeTicket}
                              label={Messages.LABEL.CANCEL_TICKET}
                            />
                          </>
                        )}
                        {error === ticketUtils.ERROR.LOOKUP_FAIL && (
                          <TicketButton onClick={handleRetryLookup} label={Messages.LABEL.RETRY} />
                        )}
                        {error === ticketUtils.ERROR.SUBMIT_FAIL && (
                          <TicketButton onClick={handleResubmitTrade} label={Messages.LABEL.RETRY} />
                        )}
                        {status === ticketUtils.TICKET_STATUS.EDIT && error === ticketUtils.ERROR.NONE && (
                          <TicketButton
                            disabled={
                              !isValidTicket ||
                              queryCalculatorState.isLoading ||
                              queryCalculatorState.isLoading
                            }
                            onClick={enablePreview}
                            label={Messages.LABEL.PREVIEW_ORDER}
                            color="primary"
                          />
                        )}
                        {status === ticketUtils.TICKET_STATUS.PREVIEW && error === ticketUtils.ERROR.NONE && (
                          <>
                            <TicketButton
                              onClick={enableEditing}
                              label={Messages.LABEL.EDIT_ORDER}
                              color="primary"
                            />
                            <TicketButton
                              disabled={
                                !isValidTicket ||
                                queryCalculatorState.isLoading ||
                                queryCalculatorState.isLoading
                              }
                              onClick={handlePlaceTrade}
                              label={Messages.LABEL.PLACE_ORDER}
                              color="primary"
                            />
                          </>
                        )}
                        {status === ticketUtils.TICKET_STATUS.COMPLETE && (
                          <TicketButton
                            onClick={handleCloseTradeTicketAfterSuccessOrCancel}
                            label={
                              ticketInfo?.hasNextTicket
                                ? Messages.LABEL.CONTINUE_NEXT_TICKET
                                : Messages.LABEL.CLOSE
                            }
                          />
                        )}
                      </div>
                      <div style={{ marginTop: 10, marginLeft: 10 }}>
                        {status === ticketUtils.TICKET_STATUS.EDIT && (
                          <TextAreaInput
                            style={{ alignItems: "flex-end", minWidth: 400 }}
                            rows={2}
                            value={userMessage}
                            onChange={(event) => {
                              setUserMessage(event.currentTarget.value);
                            }}
                            placeholder={Messages.LABEL.USER_MESSAGE}
                          />
                        )}
                        {status !== ticketUtils.TICKET_STATUS.EDIT && <UserMessage text={userMessage} />}
                      </div>
                    </TicketRowSection>
                  </TicketRow>
                </>
              )}
            </>
          </TicketBody>
          <TicketFooter
            text={Messages.MESSAGE.TRADE_DISCLAIMER}
            helpLinkText={Messages.LABEL.SEE_FULL_DISCLAIMER}
            helpSection="orders"
            onClose={closeTradeTicket}
          />
        </div>
      </UnclosableModal>
      {utils.hasNonEmptyValue(referrer) && (
        <InformationDialog
          message={
            status !== ticketUtils.TICKET_STATUS.COMPLETE
              ? Messages.MESSAGE.RETURN_TO_REFERRER_CANCEL.replace("{0}", referrer.returnSiteName)
              : Messages.MESSAGE.RETURN_TO_REFERRER_PURCHASE_COMPLETE.replace("{0}", referrer.returnSiteName)
          }
          isOpen={confirmationOpen}
          setIsOpen={setConfirmationOpen}
          cancelLabel={Messages.LABEL.CLOSE}
          onCancel={closeTradeTicket}
        />
      )}
      <UnclosableModal
        open={
          utils.hasNonEmptyValue(failures) &&
          !utils.hasNonEmptyValue(conflicted) &&
          !accountSelectionModalOpen
        }
        obfuscatedBackdrop={utils.hasNonEmptyValue(referrer)}
      >
        <FailedBonds
          instrumentCategory={tableInstrumentCategory}
          failures={failures}
          handleCloseFailedBondsModal={handleCloseFailedBondsModal}
        />
      </UnclosableModal>
      <UnclosableModal
        open={utils.hasNonEmptyValue(conflicted) && !accountSelectionModalOpen}
        obfuscatedBackdrop={utils.hasNonEmptyValue(referrer)}
      >
        <ConflictedBonds
          instrumentCategory={tableInstrumentCategory}
          conflicted={conflicted}
          handleCancelTradeTicket={handleCancelTradeTicket}
          handleConfirmUpdates={handleConfirmUpdates}
          handleConflictTableValueChange={handleConflictTableValueChange}
        />
      </UnclosableModal>
      <UnclosableModal open={accountAlertModalOpen}>
        <TicketBody style={{ marginBottom: 20 }}>
          <div style={{ whiteSpace: "nowrap" }}>
            <span>{Messages.MESSAGE.ACCOUNT_CURRENTLY_TRADING} </span>
            <span className={classes.account}>{buyer?.brokeredClearingAccount?.accountFormatted}</span>
          </div>
          {buyer?.affiliateInstitution && buyer?.affiliateTrader && (
            <div style={{ display: "flex", justifyContent: "center", gap: 5 }}>
              <span className={classes.affiliate}>{buyer?.affiliateInstitution?.companyName}</span>
              <span className={classes.affiliate}>{`\u2022`}</span>
              <span className={classes.affiliate}>{buyer?.affiliateTrader?.fullName}</span>
            </div>
          )}

          {ticketInfo.addingBonds && (
            <div style={{ marginTop: 20, maxWidth: 450 }}>
              <QBTypography>{Messages.MESSAGE.ACCOUNT_CURRENTLY_TRADING_ADDING_BONDS}</QBTypography>
            </div>
          )}
        </TicketBody>
        <TicketRow style={{ justifyContent: "center" }}>
          <TicketButton onClick={() => setAccountAlertModalOpen(false)} label={Messages.LABEL.OK} />
        </TicketRow>
      </UnclosableModal>
      <AccountSelectionModal
        allowTradeForOthers={canTradeForOthers}
        onSelect={handleAccountSelected}
        open={accountSelectionModalOpen}
      />
    </>
  );
};

const FailedBonds = ({ instrumentCategory, failures, handleCloseFailedBondsModal }) => {
  const Messages = useMessages();

  return (
    <>
      <ModalMessages messages={[Messages.MESSAGE.BOND_UNAVAILABLE]} level={Constants.ALERT_LEVEL.ERROR} />

      <NarrowTableWrapper>
        <DataTable
          queryType={Constants.QUERY_TYPE.TRADE_TICKET_FAILURES}
          instrumentCategory={instrumentCategory}
          data={failures}
          editable={false}
          selectable={false}
          TableProps={{ className: "datatable-plain" }}
        />
      </NarrowTableWrapper>
      <Spacer />
      <TicketRow>
        <TicketRowSection></TicketRowSection>
        <TicketRowSection>
          <TicketButton onClick={handleCloseFailedBondsModal} label={Messages.LABEL.CLOSE} />
        </TicketRowSection>
      </TicketRow>
    </>
  );
};

const ConflictedBonds = ({
  conflicted,
  instrumentCategory,
  handleCancelTradeTicket,
  handleConfirmUpdates,
  handleConflictTableValueChange,
}) => {
  const Messages = useMessages();

  let conflictsPriceDrop = false;

  conflicted.forEach((datum) => {
    if (datum.price < datum.price_previous) {
      utils.addToArray(datum.noted, "price");
      conflictsPriceDrop = true;
    }
  });

  const hasPriceIncrease = conflicted.some((bond) => bond.errors && bond.errors.includes("price"));
  const hasQuantityChange = conflicted.some((bond) => bond.errors && bond.errors.includes("quantity"));

  const message =
    hasPriceIncrease && hasQuantityChange
      ? Messages.MESSAGE.PRICE_INCREASE_AND_QTY_CHANGE
      : hasPriceIncrease
      ? Messages.MESSAGE.PRICE_INCREASE
      : Messages.MESSAGE.QTY_CHANGE;

  return (
    <>
      {conflictsPriceDrop && (
        <ModalMessages messages={[Messages.MESSAGE.TRADE_PRICE_DROP]} level={Constants.ALERT_LEVEL.INFO} />
      )}
      <ModalMessages messages={[message]} level={Constants.ALERT_LEVEL.WARNING} />
      <NarrowTableWrapper>
        <DataTable
          queryType={Constants.QUERY_TYPE.TRADE_TICKET_CONFLICT}
          instrumentCategory={instrumentCategory}
          data={conflicted}
          editable={true}
          selectable={false}
          onValueChange={handleConflictTableValueChange}
        />
      </NarrowTableWrapper>
      <Spacer />
      <TicketRow>
        <TicketRowSection></TicketRowSection>
        <TicketRowSection>
          <TicketButton onClick={handleCancelTradeTicket} label={Messages.LABEL.CANCEL_TICKET} />
          <TicketButton
            disabled={!utils.hasNonEmptyValue(conflicted)}
            onClick={handleConfirmUpdates}
            label={Messages.LABEL.CONFIRM}
            color="primary"
          />
        </TicketRowSection>
      </TicketRow>
    </>
  );
};

TradeTicket.propTypes = {
  open: PropTypes.bool.isRequired,
  bonds: PropTypes.arrayOf(PropTypes.object),
  ticketInfo: PropTypes.object.isRequired,
  onCloseTradeTicket: PropTypes.func.isRequired,
  onCartUpdated: PropTypes.func.isRequired,
};

const StyledBuyButton = withStyles((theme) => ({
  root: {
    minWidth: 40,
    padding: "4px",
    fontSize: "0.6rem",
    lineHeight: 1,
    color: theme.palette.text.selected.primary,
    backgroundColor: theme.palette.background.button.buy,
    "&:hover": {
      backgroundColor: alpha(theme.palette.background.button.buy, 0.8),
      color: theme.palette.text.selected.primary,
    },
    "&.MuiButton-outlined.Mui-disabled": {
      border: "1px solid",
      borderColor: theme.palette.border.disabled,
      color: theme.palette.text.unselected.disabled,
      backgroundColor: alpha(theme.palette.background.button.primary, 0.3),
    },
  },
}))(Button);

export const BuyButton = ({ onClick, onCartClick, disabled, bond, ...otherProps }) => {
  const Messages = useMessages();

  const viewState = useViewState();
  const bondsInCart = viewState.cart || [];
  const inCart = bondsInCart.find((bondInCart) => bondInCart.brokeredsecurityid === bond.brokeredsecurityid);
  const tooltip = disabled ? Messages.TOOLTIP.BOND_UNAVAILABLE : Messages.TOOLTIP.BUY;

  if (inCart) {
    return (
      <span>
        <LinkButton
          onClick={() => onCartClick(bond.instrumentCategory)}
          style={{ padding: "0 0.25rem", minWidth: 40, fontSize: "0.6rem" }}
          label={Messages.LABEL.IN_CART}
        />
      </span>
    );
  }
  return (
    <StyledTooltip title={tooltip}>
      <span>
        <StyledBuyButton
          onClick={() => onClick([bond], bondsInCart)}
          disabled={disabled}
          variant="outlined"
          color="primary"
          size="small"
          {...otherProps}
        >
          {Messages.LABEL.BUY}
        </StyledBuyButton>
      </span>
    </StyledTooltip>
  );
};

BuyButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  onCartClick: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};
