import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";

import * as utils from "commons/utils";
import * as Constants from "commons/constants";
import { useMessages } from "providers/BrandingProvider";
import { PopperPaper } from "components/containers/Modals";
import {
  StyledCheckmarkIcon,
  StyledWarningCheckmarkIcon,
  StyledInfoIcon,
  StyledWarningInfoIcon,
} from "components/Controls";

import QBTypography from "components/QBTypography";
import DataTable from "components/datatable/DataTable";
import ModalMessages from "./modals/common/ModalMessages";

import Tooltip from "@material-ui/core/Tooltip";
import Fade from "@material-ui/core/Fade";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  paper: {
    width: "100%",
    minWidth: 700,
    maxHeight: "97vh",
  },
  tooltip: {
    fontWeight: "normal",
    maxWidth: 700,
    padding: 0,
    backgroundColor: theme.palette.background.popperPaper,
  },
  tooltipPlacement: {
    margin: "8px 0",
  },
  wrapper: {
    marginTop: 10,
    marginBottom: 10,
  },
  header: {
    display: "flex",
    justifyContent: "center",
    marginBottom: 10,
  },
  insuranceNumber: {
    color: theme.palette.primary.main,
  },
  textWarning: {
    color: theme.palette.text.warning,
  },
  textInfo: {
    color: theme.palette.secondary.main,
  },
}));

const Holdings = ({ bond, color, label }) => {
  const classes = useStyles();
  const Messages = useMessages();
  // TODO - why is h.holdingCompany actually null here and require safe navigation?
  const isEquivalency = !bond.holdings.some(
    (h) => h?.holdingCompany.insuranceNumber === bond.insuranceNumber,
  );
  const hasEquivalency = bond.holdings.some((h) => h.equivalentHolding);

  let Icon;

  if (isEquivalency) {
    Icon = color === "warning" ? StyledWarningInfoIcon : StyledInfoIcon;
  } else {
    Icon = color === "warning" ? StyledWarningCheckmarkIcon : StyledCheckmarkIcon;
  }

  const HoldingsTable = () => {
    return (
      <PopperPaper classes={{ root: classes.paper }}>
        <div className={classes.wrapper}>
          <div className={classes.header}>
            <QBTypography variant="h3">
              <span>
                {Messages.LABEL.YOUR_HOLDINGS}{" "}
                <span className={classes.insuranceNumber}>
                  {utils.formatValue(bond.insuranceNumber, "insuranceNumber")}
                </span>
              </span>
            </QBTypography>
          </div>
          {hasEquivalency && (
            <ModalMessages
              messages={[Messages.MESSAGE.FDIC_EQUIVALENCIES]}
              level={Constants.ALERT_LEVEL.WARNING}
            />
          )}
        </div>
        <DataTable
          TableProps={{ className: "datatable-popper" }}
          queryType={Constants.QUERY_TYPE.HOLDINGS}
          selectable={false}
          data={bond.holdings}
        />
      </PopperPaper>
    );
  };
  return (
    <Tooltip
      title={<HoldingsTable />}
      TransitionComponent={Fade}
      interactive
      classes={{
        tooltip: classes.tooltip,
        tooltipPlacementTop: classes.tooltipPlacement,
        tooltipPlacementBottom: classes.tooltipPlacement,
      }}
    >
      <span style={{ cursor: "pointer" }}>
        {utils.hasNonEmptyValue(label) && (
          <span
            className={clsx({
              [classes.textInfo]: color === "info",
              [classes.textWarning]: color === "warning",
            })}
          >
            {label}
          </span>
        )}
        <Icon />
      </span>
    </Tooltip>
  );
};

Holdings.propTypes = {
  bond: PropTypes.object.isRequired,
  iconColor: PropTypes.oneOf(["info", "warning"]),
  textColor: PropTypes.oneOf(["info", "warning"]),
};

export default Holdings;
