import moment from "moment-timezone";
import { get } from "lodash";

import * as Constants from "commons/constants";
import * as DataConfig from "config/dataconfig";
import * as utils from "commons/utils";

export const filter = (filter, row) => {
  if (filter.value?.filterType) {
    switch (filter.value.filterType) {
      case Constants.FILTER_TYPE.RANGE:
      case Constants.FILTER_TYPE.DUALRANGE:
      case Constants.FILTER_TYPE.SELECTRATING:
        return filterRange(filter, row);
      case Constants.FILTER_TYPE.DATERANGE:
        return filterDateRange(filter, row);
      case Constants.FILTER_TYPE.KEYWORD:
      case Constants.FILTER_TYPE.KEYWORD_WILDCARD:
        return filterKeyword(filter, row);
      case Constants.FILTER_TYPE.CHECKBOX:
        return filterOptions(filter, row);
      case Constants.FILTER_TYPE.TOGGLE:
        return filterValue(filter, row);
      default:
        return true;
    }
  }
  return true;
};

export const filterKeyword = (filter, row) => {
  const value = filter.value.filterValue;
  const filterKey = filter.value.filterKey || filter.id;
  const rowValue = get(row._original, filterKey, "");

  return (
    !utils.hasNonEmptyValue(value) || rowValue?.toString().toLowerCase().indexOf(value.toLowerCase()) > -1
  );
};

export const filterRange = (filter, row) => {
  const ranges = filter.value.filterValue;
  const filterKey = filter.value.filterKey || filter.id;

  // don't filter if there is no filter
  if (!utils.hasNonEmptyValue(ranges)) {
    return true;
  }

  const min = utils.hasNonEmptyValue(ranges[0]) && ranges[0] >= 0 ? ranges[0] : 0;
  const max = utils.hasNonEmptyValue(ranges[1]) ? ranges[1] : Number.MAX_SAFE_INTEGER;

  const rowValue = get(row._original, filterKey);

  return Number(rowValue) >= min && Number(rowValue) <= max;
};

export const filterDateRange = (filter, row, column) => {
  const ranges = filter.value.filterValue;
  const filterKey = filter.value.filterKey || filter.id;

  // don't filter if there is no filter
  if (!utils.hasNonEmptyValue(ranges)) {
    return true;
  }

  const value = moment.parseZone(get(row._original, filterKey));
  // non dates do not make the cut
  if (!value.isValid()) {
    return false;
  }

  const startDate = moment(ranges[0], DataConfig.DATE_FORMAT.FILTER, true);
  const endDate = moment(ranges[1], DataConfig.DATE_FORMAT.FILTER, true);

  // don't filter if we don't have any valid dates
  if (!startDate.isValid() && !endDate.isValid()) {
    return true;
  }

  const isBefore = value.isBefore(endDate) || value.isSame(endDate);
  const isAfter = value.isAfter(startDate) || value.isSame(startDate);

  if (!startDate.isValid()) {
    return isBefore;
  }

  if (!endDate.isValid()) {
    return isAfter;
  }

  return isBefore && isAfter;
};

export const filterOptions = (filter, row) => {
  const filterValue = filter.value.filterValue;
  const filterKey = filter.value.filterKey || filter.id;
  const rowValue = get(row._original, filterKey, "").toString();

  const isFiltered =
    !utils.hasNonEmptyValue(filterValue.selected) ||
    (!filterValue.omit && filterValue.selected.includes(rowValue)) ||
    (filterValue.omit && !filterValue.selected.includes(rowValue));

  return isFiltered;
};

export const filterValue = (filter, row) => {
  const filterValue = filter.value.filterValue.toString();
  const filterKey = filter.value.filterKey || filter.id;
  const rowValue = get(row._original, filterKey, "").toString();

  return rowValue === filterValue;
};

export const hasEmptyFilterValue = (filterValue) => {
  return (
    !utils.hasNonEmptyValue(filterValue) ||
    (filterValue.hasOwnProperty("selected") && !utils.hasNonEmptyValue(filterValue.selected)) ||
    (Array.isArray(filterValue) &&
      filterValue.length === 2 &&
      !utils.hasNonEmptyValue(filterValue[0]) &&
      !utils.hasNonEmptyValue(filterValue[1]))
  );
};
