import React from "react";
import { get } from "lodash";
import PropTypes from "prop-types";

import * as utils from "commons/utils";
import * as Constants from "commons/constants";
import { useMessages } from "providers/BrandingProvider";
import { SliderInput, TextInput, InlineErrorTooltip } from "components/Controls";

import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  sliderWrapper: {
    paddingLeft: 5,
    paddingRight: 5,
    marginTop: 3,
    marginBottom: 6,
  },
  inputWrapper: {
    display: "inline-flex",
    justifyContent: "center",
    width: "100%",
  },
  inputRoot: {
    margin: "0 2px",
    width: "100%",
  },
}));

const DualRangeFilter = ({
  filter,
  filterKey,
  onFilterChange,
  step,
  min,
  max,
  SliderProps,
  TextInputProps,
}) => {
  const Messages = useMessages();
  const currentFilter = get(filter, "filterValue", []);

  const minDisplay = min !== 0 ? Messages.LABEL.MIN : "0";
  const maxDisplay = Messages.LABEL.MAX;
  const defaultValue1 =
    currentFilter.length > 0 && utils.hasNonEmptyValue(currentFilter[0]) ? currentFilter[0] : min;
  const defaultValue2 =
    currentFilter.length > 1 && utils.hasNonEmptyValue(currentFilter[1]) ? currentFilter[1] : max;

  const [value1, setValue1] = React.useState(defaultValue1);
  const [value2, setValue2] = React.useState(defaultValue2);
  const [inputValue1, setInputValue1] = React.useState(defaultValue1 === min ? minDisplay : defaultValue1);
  const [inputValue2, setInputValue2] = React.useState(defaultValue2 === max ? maxDisplay : defaultValue2);
  const [value1Valid, setValue1Valid] = React.useState(true);
  const [value2Valid, setValue2Valid] = React.useState(true);
  const [tooltipMessage, setTooltipMessage] = React.useState("");
  const [tooltipMessage2, setTooltipMessage2] = React.useState("");
  const classes = useStyles();

  const isValid = (val) => {
    return utils.hasNonEmptyValue(val) && !isNaN(val) && val >= 0;
  };

  const syncInputValue1 = (val) => {
    val = val?.toString();
    if (val === min.toString() || val.toLowerCase() === minDisplay.toLowerCase()) {
      val = minDisplay;
    }
    setInputValue1(val);
  };

  const syncInputValue2 = (val) => {
    val = val?.toString();
    if (val === max.toString() || val.toString().toLowerCase() === maxDisplay.toLowerCase()) {
      val = maxDisplay;
    }
    setInputValue2(val);
  };

  const handleSliderChange = (event, newValue) => {
    setTooltipMessage("");
    setTooltipMessage2("");

    setValue1(isValid(newValue[0]) ? newValue[0] : min);
    syncInputValue1(newValue[0]);

    setValue2(isValid(newValue[1]) ? newValue[1] : max);
    syncInputValue2(newValue[1]);

    setValue1Valid(true);
    setValue2Valid(true);
  };

  const handleInputChange = (event, index) => {
    setTooltipMessage("");
    setTooltipMessage2("");

    let val = event.target.value;

    if (index === 0 && val.toString().toLowerCase() === minDisplay.toLowerCase()) {
      val = min;
    }

    if (index === 1 && val.toString().toLowerCase() === maxDisplay.toLowerCase()) {
      val = max;
    }

    const valid = isValid(val);

    if (index === 0) {
      if (valid) {
        setValue1(Number(val));
      }

      setValue1Valid(valid);
      syncInputValue1(val);
    } else {
      if (valid) {
        setValue2(Number(val));
      }
      setValue2Valid(valid);
      syncInputValue2(val);
    }
  };

  const adjustInputValuesAndApply = () => {
    setTooltipMessage("");
    setTooltipMessage2("");

    let val1 = inputValue1;
    let val2 = inputValue2;

    if (!utils.hasNonEmptyValue(val1)) {
      val1 = defaultValue1;
      setValue1(val1);
      syncInputValue1(val1);
      setValue1Valid(true);
    } else {
      syncInputValue1(val1);
    }

    if (!utils.hasNonEmptyValue(val2)) {
      val2 = defaultValue2;
      setValue2(val2);
      syncInputValue2(val2);
      setValue2Valid(true);
    } else {
      syncInputValue2(val2);
    }

    validateAndApply(val1, val2);
  };

  const handleBlur = () => {
    adjustInputValuesAndApply();
  };

  const handleKeyPress = (event) => {
    if (event.charCode === 13) {
      adjustInputValuesAndApply();
    }
  };

  const handleInput1Focus = (event) => {
    if (inputValue1 === minDisplay) {
      setInputValue1("");
    }
  };

  const handleInput2Focus = (event) => {
    if (inputValue2 === maxDisplay) {
      setInputValue2("");
    }
  };

  const validateAndApply = (val1, val2) => {
    setTooltipMessage("");
    setTooltipMessage2("");

    if (val1.toString().toLowerCase() === minDisplay.toLowerCase()) {
      val1 = min;
    }

    if (val2.toString().toLowerCase() === maxDisplay.toLowerCase()) {
      val2 = max;
    }

    const val1Valid = isValid(val1);
    const val2Valid = isValid(val2);

    setValue1Valid(val1Valid);
    setValue2Valid(val2Valid);

    if (!utils.hasNonEmptyValue(val1)) {
      setTooltipMessage("Required value");
    } else if (!utils.hasNonEmptyValue(val2)) {
      setTooltipMessage2("Required value");
    } else if (isNaN(val1)) {
      setTooltipMessage("This must be a number");
    } else if (isNaN(val2)) {
      setTooltipMessage2("This must be a number");
    } else if (Number(val1) < 0) {
      setTooltipMessage("This must be a positive number");
    } else if (Number(val2) < 0) {
      setTooltipMessage2("This must be a positive number");
    } else if (val1Valid && val2Valid) {
      val1 = Number(val1);
      val2 = Number(val2);

      if (val1 !== min && val2 !== max && val2 < val1) {
        setTooltipMessage2(`This must be a number greater than ${val1}`);
      } else {
        setValue1(val1);
        syncInputValue1(val1);

        setValue2(val2);
        syncInputValue2(val2);

        applyRangeFilter(val1, val2);
      }
    }
  };

  const applyRangeFilter = (val1, val2) => {
    if (val1 !== defaultValue1 || val2 !== defaultValue2) {
      if (val1 === min) {
        val1 = null;
      }
      if (val2 === max) {
        val2 = null;
      }

      onFilterChange({
        filterKey: filterKey,
        filterValue: [val1, val2],
        filterType: Constants.FILTER_TYPE.RANGE,
      });
    }
  };

  return (
    <div>
      <div className={classes.sliderWrapper}>
        <SliderInput
          value={[value1, value2]}
          color="secondary"
          onChange={handleSliderChange}
          onChangeCommitted={(e) => validateAndApply(value1, value2)}
          step={step}
          min={min}
          max={max}
          {...SliderProps}
        />
      </div>

      <div className={classes.inputWrapper}>
        <InlineErrorTooltip
          arrow
          onClick={(e) => setTooltipMessage("")}
          open={utils.hasNonEmptyValue(tooltipMessage) && !utils.hasNonEmptyValue(tooltipMessage2)}
          title={tooltipMessage}
        >
          <div style={{ display: "flex" }}>
            <TextInput
              variant="filter"
              value={inputValue1}
              error={!value1Valid}
              className={classes.inputRoot}
              onChange={(event) => handleInputChange(event, 0)}
              onFocus={handleInput1Focus}
              onBlur={handleBlur}
              onKeyPress={handleKeyPress}
              {...TextInputProps}
            />
          </div>
        </InlineErrorTooltip>
        <InlineErrorTooltip
          arrow
          onClick={(e) => setTooltipMessage2("")}
          open={utils.hasNonEmptyValue(tooltipMessage2) && !utils.hasNonEmptyValue(tooltipMessage)}
          title={tooltipMessage2}
        >
          <div style={{ display: "flex" }}>
            <TextInput
              variant="filter"
              value={inputValue2}
              error={!value2Valid}
              className={classes.inputRoot}
              onChange={(event) => handleInputChange(event, 1)}
              onFocus={handleInput2Focus}
              onBlur={handleBlur}
              onKeyPress={handleKeyPress}
              {...TextInputProps}
            />
          </div>
        </InlineErrorTooltip>
      </div>
    </div>
  );
};

DualRangeFilter.FilterDefinition = {
  filterKey: PropTypes.string,
  filterValue: PropTypes.arrayOf(PropTypes.number),
  filterType: PropTypes.oneOf(Object.values(Constants.FILTER_TYPE)),
};

DualRangeFilter.propTypes = {
  filter: PropTypes.shape(DualRangeFilter.FilterDefinition),
  filterKey: PropTypes.string.isRequired,
  step: PropTypes.number.isRequired,
  min: PropTypes.number.isRequired,
  max: PropTypes.number.isRequired,
  onFilterChange: PropTypes.func.isRequired,
  SliderProps: PropTypes.object,
  TextInputProps: PropTypes.object,
};

export default DualRangeFilter;
