import React from "react";
import clsx from "clsx";
import { v4 as uuid } from "uuid";

import { useMessages } from "providers/BrandingProvider";

import * as Constants from "commons/constants";
import QBTypography from "components/QBTypography";
import { PillButton } from "components/Controls";
import ModalMessages from "components/modals/common/ModalMessages";
import { TextAreaInput } from "components/Controls";
import { TextInput } from "components/Controls";
import { FormButton } from "components/Controls";
import NavigationToolbar from "components/toolbars/NavigationToolbar";
import FileUploadAdmin from "components/modals/FileUploadAdmin";

import { makeStyles } from "@material-ui/core/styles";
import RemoveCircleOutlineIcon from "@material-ui/icons/RemoveCircleOutline";
import { IconButton } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  label: {
    fontSize: "0.65rem",
    color: theme.palette.text.popper.header,
    lineHeight: 1,
    marginBottom: 4,
    textTransform: "uppercase",
    letterSpacing: "0.07em",
    fontWeight: 800,
  },
  sublabel: {
    color: theme.palette.text.secondary,
    textTransform: "none",
  },
  linkWrapper: {
    display: "flex",
    alignItems: "flex-start",
    padding: "15px 10px",
    gap: 20,
    backgroundColor: theme.palette.background.paperForm,
    borderBottom: `1px solid ${theme.palette.border.subtle}`,
    "&:last-child": {
      borderBottom: "none",
    },
  },
}));

const NewsItemEditor = ({ item, newsItems, onSubmit, onNewsItemChange }) => {
  const Messages = useMessages();
  const classes = useStyles();
  const [invalidMessages, setInvalidMessages] = React.useState([]);

  React.useEffect(() => {
    setInvalidMessages([]);
  }, [item]);

  const handleSubmitItem = () => {
    const invalidMessages = getInvalidMessages();
    if (invalidMessages.length === 0) {
      item.links = item.links.reduce((links, link) => {
        if (!link.url && !link.file) {
          return links;
        } else {
          return links.concat(link);
        }
      }, []);
      delete item.key;
      onSubmit(item);
    }

    setInvalidMessages(invalidMessages);
  };

  const getInvalidMessages = () => {
    const invalidMessages = [];

    if (!item.header && !item.itemText) {
      invalidMessages.push("You must have either a headline or news text");
    }

    return invalidMessages;
  };

  return (
    <>
      <div style={{ paddingRight: 10, marginTop: 10 }}>
        <ModalMessages
          messages={invalidMessages}
          level={Constants.ALERT_LEVEL.WARNING}
          wrapperStyle={{ marginTop: 0 }}
        />
        <div style={{ margin: "10px 0 5px" }} className={classes.label}>
          {Messages.LABEL.HEADLINE}
        </div>
        <TextInput
          autoFocus={true}
          maxLength={100}
          value={item.header}
          onChange={(event) => {
            onNewsItemChange({ ...item, header: event.currentTarget.value ?? "" });
          }}
          placeholder={Messages.LABEL.HEADLINE}
          variant="form"
        />
        <div style={{ margin: "20px 0 5px" }} className={classes.label}>
          {Messages.LABEL.ITEM_TEXT}
        </div>
        <TextAreaInput
          rows={11}
          value={item.itemText}
          onChange={(event) => {
            onNewsItemChange({ ...item, itemText: event.currentTarget.value ?? "" });
          }}
          placeholder={Messages.LABEL.ITEM_TEXT}
          variant="form"
        />
      </div>

      <Links
        links={item.links}
        setLinks={(links) => {
          onNewsItemChange({ ...item, links });
        }}
        newsItems={newsItems}
      />

      <div style={{ display: "flex", justifyContent: "center", paddingRight: 10, marginTop: 10 }}>
        <FormButton style={{ margin: "0 0.5rem" }} onClick={handleSubmitItem}>
          {Messages.LABEL.SAVE}
        </FormButton>
      </div>
    </>
  );
};

const Links = ({ links, setLinks, newsItems }) => {
  const Messages = useMessages();
  const classes = useStyles();

  const handleLinkUpdate = (link) => {
    const idx = links.findIndex((l) => l.id === link.id);
    links.splice(idx, 1, link);

    setLinks([...links]);
  };

  const deleteLink = (link) => {
    const idx = links.findIndex((l) => l.id === link.id);
    links.splice(idx, 1);
    setLinks([...links]);
  };

  const addLink = () => {
    links.push({ id: uuid() });
    setLinks([...links]);
  };

  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          paddingRight: 10,
          margin: "20px 0 5px",
        }}
      >
        <div className={classes.label}>{Messages.LABEL.LINKS}</div>
        <div>
          <PillButton color="primary" onClick={addLink}>
            {Messages.LABEL.ADD_LINK}
          </PillButton>
        </div>
      </div>
      <div
        style={{
          display: "flex",
          flexFlow: "column nowrap",
          overflowY: "auto",
          overflowX: "hidden",
          paddingRight: 10,
        }}
      >
        {links.map((link) => {
          return (
            <div key={link.id} className={classes.linkWrapper}>
              <LinkEditor
                link={link}
                newsItems={newsItems}
                setLink={handleLinkUpdate}
                onDelete={deleteLink}
              />
            </div>
          );
        })}
      </div>
    </>
  );
};

const LinkEditor = ({ newsItems, link, setLink, onDelete }) => {
  const Messages = useMessages();
  const classes = useStyles();

  const [fileModalOpen, setFileModalOpen] = React.useState(false);

  const [selectedType, setSelectedType] = React.useState(
    link.url ? Constants.LINK_TYPE.URL : Constants.LINK_TYPE.FILE,
  );

  const handleTypeChange = (value) => {
    link.url = null;
    link.file = null;
    link.text = null;
    setLink(link);
    setSelectedType(value);
  };

  const handleLinkTextChange = (value) => {
    link.text = value;
    setLink(link);
  };

  const handleUrlChange = (value) => {
    link.url = value;
    link.file = null;
    setLink(link);
  };

  const handleRemoveFile = () => {
    link.file = null;
    setLink(link);
  };

  const handleFileSelected = (file) => {
    link.file = file;
    link.text = `Download ${file.fileName}`;
  };

  return (
    <div style={{ minWidth: 0, flexGrow: 1 }}>
      <div style={{ display: "flex", gap: 10, alignItems: "center", justifyContent: "space-between" }}>
        <NavigationToolbar
          optionsKey="LINK_TYPE"
          selectedOption={selectedType}
          onOptionSelected={handleTypeChange}
          style={{ margin: "0 0" }}
        />
        <div>
          <IconButton size="small" onClick={() => onDelete(link)} disableRipple disableFocusRipple>
            <RemoveCircleOutlineIcon color="error" fontSize="small" />
          </IconButton>
        </div>
      </div>
      {selectedType === Constants.LINK_TYPE.URL && (
        <>
          <div style={{ margin: "10px 0 5px" }} className={clsx(classes.label, classes.sublabel)}>
            {Messages.LABEL.URL}
          </div>
          <TextInput
            maxLength={255}
            value={link.url ?? ""}
            onChange={(event) => {
              handleUrlChange(event.currentTarget.value ?? "");
            }}
            placeholder={Messages.LABEL.URL}
            variant="form"
            style={{ width: "100%" }}
          />
        </>
      )}
      {selectedType === Constants.LINK_TYPE.FILE && (
        <>
          {link.file && (
            <>
              <div
                style={{ display: "flex", gap: 10, alignItems: "center", justifyContent: "space-between" }}
              >
                <div style={{ display: "flex", alignItems: "center", minWidth: 0 }}>
                  <QBTypography
                    component="div"
                    style={{
                      whiteSpace: "nowrap",
                      textOverflow: "ellipsis",
                      overflow: "hidden",
                      margin: "10px 0 5px",
                    }}
                  >
                    <span>File</span>
                    <span>{link.file.fileName}</span>
                  </QBTypography>

                  <RemoveCircleOutlineIcon
                    onClick={handleRemoveFile}
                    color="error"
                    fontSize="small"
                    style={{ fontSize: "0.8rem", cursor: "pointer", margin: "0 8px" }}
                  />
                </div>
              </div>
            </>
          )}
          {!link.file && (
            <>
              <div style={{ margin: "10px auto 5px", textAlign: "center" }}>
                <PillButton onClick={() => setFileModalOpen(true)}>{Messages.LABEL.SELECT_FILE}</PillButton>
              </div>
              <FileUploadAdmin
                open={fileModalOpen}
                onClose={() => setFileModalOpen(false)}
                onFileSelected={handleFileSelected}
                newsItems={newsItems}
              />
            </>
          )}
        </>
      )}

      <div>
        <div style={{ margin: "10px 0 5px" }} className={clsx(classes.label, classes.sublabel)}>
          {Messages.LABEL.LINK_TEXT}
        </div>
        <TextInput
          maxLength={100}
          value={link.text ?? ""}
          onChange={(event) => {
            handleLinkTextChange(event.currentTarget.value ?? "");
          }}
          placeholder={Messages.LABEL.LINK_TEXT}
          variant="form"
          style={{ width: "100%" }}
        />
      </div>
    </div>
  );
};

export default NewsItemEditor;
