import React from "react";
import { useGlobal } from "reactn";
import { CSSTransition } from "react-transition-group";
import PropTypes from "prop-types";

import * as utils from "commons/utils";
import * as Constants from "commons/constants";
import { useMessages } from "providers/BrandingProvider";
import { TextAreaInput } from "components/Controls";
import { Div } from "components/containers/Containers";
import * as ticketUtils from "./tickets/ticketUtils";
import { ModalImportCusipButton } from "./common/FormComponents";
import ModalMessages from "./common/ModalMessages";
import { TicketBody, TicketRow, TicketRowSection, TicketButton, Spacer } from "./tickets/TicketComponents";
import DataTable from "components/datatable/DataTable";
import QBTypography from "components/QBTypography";
import { ClosableModal } from "components/containers/Modals";
import { useDataService } from "services/DataService";
import { addFavoriteIssuers } from "services/FavoritesService";

import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: 200,
    minWidth: 662,
    marginTop: 10,
  },
  instructionsWrapper: {
    display: "flex",
    flexDirection: "column",
    paddingLeft: 20,
  },
  instructions: {
    flexGrow: 1,
    textAlign: "left",
    marginBottom: 10,
  },
  instruction: {
    color: theme.palette.text.primary,
  },
  buttonRow: {
    flexGrow: 0,
    textAlign: "right",
  },
  tableWrapper: {
    margin: "auto 0",
    minWidth: 600,
  },
  importButton: {
    marginBottom: "0.5rem",
    width: "100%",
    textAlign: "right",
  },
}));

const ref = React.createRef();

const lockModalHeight = () => {
  if (ref.current) {
    ref.current.style["min-height"] = ref.current.clientHeight + "px";
    ref.current.style["max-height"] = ref.current.clientHeight + "px";
    ref.current.style["overflow-y"] = "hidden";
  }
};

const unlockModalHeight = () => {
  if (ref.current) {
    ref.current.style["min-height"] = "inherit";
    ref.current.style["max-height"] = "inherit";
    ref.current.style["overflow-y"] = "auto";
  }
};

const ImportIssuers = ({ open, onCloseModal }) => {
  const classes = useStyles();
  const Messages = useMessages();
  const [favoriteIssuers = []] = useGlobal("favoriteIssuers");
  const favoriteIssuerCusips = Object.keys(favoriteIssuers);

  const [userData, setUserData] = React.useState("");
  const [modalStatus, setModalStatus] = React.useState(ticketUtils.TICKET_STATUS.IMPORT);
  const [stagedStatus, setStagedStatus] = React.useState(ticketUtils.TICKET_STATUS.IMPORT);

  const [infoMessages, setInfoMessages] = React.useState([]);
  const [warningMessages, setWarningMessages] = React.useState([]);
  const [errorMessages, setErrorMessages] = React.useState([]);

  const [state, queryDataService] = useDataService();

  const [data, setData] = React.useState([]);
  const [loading, setLoading] = React.useState(false);

  React.useEffect(() => {
    resetErrorState();
    setModalStatus(ticketUtils.TICKET_STATUS.IMPORT);
    setStagedStatus(ticketUtils.TICKET_STATUS.IMPORT);
    setData([]);
    setUserData("");

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  React.useEffect(() => {
    handleIssuerLookup(state);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.data]);

  const isValidCusip = (d) => {
    return (
      !d.duplicate &&
      d.issuer !== Constants.RESPONSE.ISSUER_NOT_FOUND &&
      d.issuer !== Constants.RESPONSE.INVALID
    );
  };

  const handleCloseModal = (e, category) => {
    category = category === "backdropClick" ? null : category;
    onCloseModal(category);
    unlockModalHeight();
  };

  const resetErrorState = () => {
    infoMessages.splice(0, infoMessages.length);
    setInfoMessages(infoMessages);
    warningMessages.splice(0, warningMessages.length);
    setWarningMessages(warningMessages);
    errorMessages.splice(0, errorMessages.length);
    setErrorMessages(errorMessages);
  };

  const handleSubmit = (e) => {
    const filteredData = data.filter((datum) => isValidCusip(datum));

    resetErrorState();

    if (utils.hasNonEmptyValue(filteredData)) {
      addFavoriteIssuers(filteredData);
    }
    handleCloseModal(e, filteredData);
  };

  const handleValueChange = (props, value) => {
    const accessor = props.column.id;

    if (accessor === "toggleImport") {
      toggleImport();
    } else if (accessor === "delete") {
      data.splice(props.index, 1);
      validateAndSetData(data);
    }
  };

  const setStatus = () => {
    setModalStatus(stagedStatus);
    unlockModalHeight();
  };

  const toggleImport = () => {
    resetErrorState();
    if (modalStatus !== ticketUtils.TICKET_STATUS.IMPORT) {
      lockModalHeight();
      setStagedStatus(ticketUtils.TICKET_STATUS.IMPORT);
    } else {
      setStagedStatus(ticketUtils.TICKET_STATUS.PREVIEW);
      validateAndSetData(data);
    }
  };

  const lookupIssuers = (cusips) => {
    const queryParams = {
      type: Constants.QUERY_TYPE.ISSUER_TRACKER,
      size: 100,
      sort: [{ id: "cusip", desc: false }],
      issuers: cusips.filter((c) => c.cusip.length === 6).map((c) => c.cusip),
      filter: {
        quantity: {
          filterKey: "quantity",
          filterValue: [0],
          filterType: Constants.FILTER_TYPE.RANGE,
        },
      },
    };
    setLoading(true);
    queryDataService(queryParams);
    validateAndSetData(cusips);
  };

  const handleIssuerLookup = (state) => {
    if (utils.hasNonEmptyValue(state.query)) {
      if (state.isError) {
        data.forEach((rec, i) => {
          if (state.query.issuers.includes(rec.cusip)) {
            rec.issuer = Constants.RESPONSE.ISSUER_NOT_FOUND;
            data[i] = rec;
          }
        });
      } else {
        var existingIssuers = [...favoriteIssuerCusips];

        data.forEach((rec, i) => {
          const respRec = state.data.find((c) => c.cusip === rec.cusip);

          rec.duplicate = existingIssuers.includes(rec.cusip);
          existingIssuers.push(rec.cusip);

          if (!respRec) {
            if (rec.cusip.length !== 6) {
              rec.issuer = Constants.RESPONSE.INVALID;
            } else {
              rec.issuer = Constants.RESPONSE.ISSUER_NOT_FOUND;
            }

            data[i] = rec;
          } else {
            const mergedRec = { ...rec, ...respRec };
            mergedRec.issuer =
              mergedRec.issuer && mergedRec.issuer !== "Not available"
                ? mergedRec.issuer
                : Constants.RESPONSE.ISSUER_NOT_FOUND;

            data[i] = mergedRec;
          }
        });
      }
      validateAndSetData(data);
      setLoading(false);
    }
  };

  const validateAndSetData = (data) => {
    const errorMessages = [];

    const hasUnfound = data.some((d) => d.issuer === Constants.RESPONSE.ISSUER_NOT_FOUND);
    if (hasUnfound) {
      errorMessages.push(Messages.MESSAGE.ISSUER_NOT_FOUND);
    }

    const hasInvalid = data.some((d) => d.issuer === Constants.RESPONSE.INVALID);
    if (hasInvalid) {
      errorMessages.push(Messages.MESSAGE.INVALID_ISSUER);
    }

    const hasDuplicate = data.some((d) => d.duplicate);
    if (hasDuplicate) {
      errorMessages.push(Messages.MESSAGE.DUPLICATE_ISSUERS);
    }

    setErrorMessages(errorMessages);
    setData(utils.cloneArrayOfObjects(data));
  };

  const processUserData = () => {
    if (utils.hasNonEmptyValue(userData)) {
      const filteredData = data.filter(
        (datum) => utils.hasNonEmptyValue(datum.cusip.trim()) && !utils.hasNonEmptyValue(datum.errors),
      );

      const cusips = userData
        .split("\n")
        .filter((c) => c && c.trim().length > 0)
        .map((c) => c.trim());

      cusips.forEach((cusip) => {
        cusip = cusip.substr(0, 6);
        if (utils.hasNonEmptyValue(cusip)) {
          var rec = {
            id: -1,
            cusip: cusip,
            issuer: cusip.length === 6 ? "" : Constants.RESPONSE.INVALID,
          };
          filteredData.push(rec);
        }

        return cusip;
      });

      var existingIssuers = [...favoriteIssuerCusips];

      data.forEach((rec, i) => {
        rec.duplicate = existingIssuers.includes(rec.cusip);
        existingIssuers.push(rec.cusip);
      });

      lookupIssuers(filteredData);
      setStagedStatus(ticketUtils.TICKET_STATUS.PREVIEW);
      setUserData("");
    }
  };

  const hasValidCusip = data.some((d) => isValidCusip(d));

  const placeholderText = "6-digit CUSIP";

  // Repeat N times to demonstrate to user to enter multiple rows
  const placeholder = Array(3).fill(placeholderText).join("\n") + "\n ...";

  return (
    <ClosableModal open={open} header={Messages.LABEL.ADD_ISSUERS_TO_TRACKER} onClose={handleCloseModal}>
      <Div className={classes.root}>
        <TicketBody ref={ref}>
          {modalStatus === ticketUtils.TICKET_STATUS.PREVIEW && (
            <div className={classes.importButton}>
              <ModalImportCusipButton onClick={toggleImport} label={Messages.LABEL.ENTER_MORE_ISSUERS} />
            </div>
          )}
          <ModalMessages messages={errorMessages} level={Constants.ALERT_LEVEL.ERROR} />
          <ModalMessages messages={warningMessages} level={Constants.ALERT_LEVEL.WARNING} />
          <ModalMessages messages={infoMessages} level={Constants.ALERT_LEVEL.INFO} />
          <CSSTransition
            mountOnEnter
            unmountOnExit
            timeout={{ enter: 500, exit: 100 }}
            classNames="transition-fade"
            onExited={setStatus}
            in={
              modalStatus === ticketUtils.TICKET_STATUS.IMPORT &&
              stagedStatus === ticketUtils.TICKET_STATUS.IMPORT
            }
          >
            <TicketRow>
              <TicketRowSection>
                <TextAreaInput
                  rows={11}
                  value={userData}
                  onChange={(event) => {
                    setUserData(event.currentTarget.value);
                  }}
                  placeholder={placeholder}
                />
              </TicketRowSection>
              <TicketRowSection>
                <Div className={classes.instructionsWrapper}>
                  <div className={classes.instructions}>
                    <QBTypography variant="body1">
                      Enter 6-character CUSIPs, one per line, to add to Issuer Tracker
                    </QBTypography>
                  </div>
                  <div className={classes.buttonRow}>
                    <TicketButton onClick={toggleImport} label={Messages.LABEL.CANCEL} />
                    <TicketButton
                      disabled={!utils.hasNonEmptyValue(userData)}
                      onClick={processUserData}
                      label={Messages.LABEL.ENTER}
                    />
                  </div>
                </Div>
              </TicketRowSection>
            </TicketRow>
          </CSSTransition>
          <CSSTransition
            mountOnEnter
            unmountOnExit
            timeout={{ enter: 500, exit: 100 }}
            classNames="transition-fade"
            onExited={setStatus}
            in={
              modalStatus === ticketUtils.TICKET_STATUS.PREVIEW &&
              stagedStatus === ticketUtils.TICKET_STATUS.PREVIEW
            }
          >
            <div className={classes.tableWrapper}>
              <DataTable
                queryType={Constants.QUERY_TYPE.FAVORITE_IMPORT}
                data={data}
                editable={true}
                selectable={false}
                onValueChange={handleValueChange}
                loading={loading}
              />
              <Spacer />
              <TicketRow>
                <TicketRowSection></TicketRowSection>
                <TicketRowSection>
                  <TicketButton onClick={handleCloseModal} label={Messages.LABEL.CANCEL} />
                  <TicketButton
                    disabled={!hasValidCusip}
                    onClick={handleSubmit}
                    label={Messages.LABEL.ADD_ISSUERS}
                    color="primary"
                  />
                </TicketRowSection>
              </TicketRow>
            </div>
          </CSSTransition>
        </TicketBody>
      </Div>
    </ClosableModal>
  );
};

ImportIssuers.propTypes = {
  open: PropTypes.bool.isRequired,
  onCloseModal: PropTypes.func.isRequired,
};

export default ImportIssuers;
