import React from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import { get, deburr, cloneDeep, uniq } from "lodash";

import * as Constants from "commons/constants";
import { useMessages } from "providers/BrandingProvider";
import * as DataOptions from "commons/dataOptions";
import * as utils from "commons/utils";
import { TextInput, SearchAdornment } from "components/Controls";
import { useOptionsService } from "services/OptionsService";

import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import IconButton from "@material-ui/core/IconButton";
import Button from "@material-ui/core/Button";
import CancelPresentationIcon from "@material-ui/icons/CancelPresentation";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import IndeterminateCheckBoxIcon from "@material-ui/icons/IndeterminateCheckBox";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  wrapper: {
    paddingLeft: 10,
    paddingRight: 10,
    alignItems: "center",
  },
  header: {
    margin: "auto 2px",
    textTransform: "uppercase",
    color: theme.palette.text.popper.header,
  },
  labelWrapper: {
    padding: "2px 4px",
    fontSize: "0.8em",
  },
  inputWrapper: {
    marginTop: 4,
    "& input": {
      textAlign: "left",
    },
  },
  toolbar: {
    width: "100%",
    display: "flex",
    paddingBottom: 2,
    marginBottom: 10,
    borderBottom: "1px solid",
    borderBottomColor: theme.palette.border.main,
  },
  toolbarSection: {
    display: "flex",
    justifyContent: "flex-start",
    flexGrow: 0,
    minWidth: "34%",
    "&:first-child": {
      justifyContent: "flex-start !important",
      margin: "0 !important",
    },
    "&:last-child": {
      justifyContent: "flex-end",
      marginLeft: 10,
      flexGrow: 1,
      minWidth: "33%",
    },
  },
  toolbarHeader: {
    display: "flex",
    justifyContent: "flex-start",
    minWidth: "33%",
    alignItems: "center",
  },
  omitLabelRoot: {
    marginRight: 0,
    marginLeft: 0,
  },
  omitCheckboxRoot: {
    padding: 5,
    color: theme.palette.primary.main,
  },
  checkboxRoot: {
    padding: 5,
    color: theme.palette.secondary.main,
  },
  iconRoot: {
    fontSize: "0.8rem",
  },
  linkButton: {
    fontSize: "0.6rem",
    minWidth: 40,
    marginRight: 5,
  },
  iconButton: {
    padding: 5,
  },
  closeIcon: {
    fontSize: "0.9rem",
  },
  formControlWrapper: {},
  collapsibleFormWrapper: {
    borderWidth: 0,
    margin: 0,
  },
  optionGroup: {
    display: "flex",
    flexWrap: "wrap",
    flexDirection: "column",
    paddingRight: 10,
  },
  suboption: {
    marginLeft: "14px !important",
  },
  labelRoot: {
    margin: 0,
    minWidth: 100,
  },
  label: {
    fontSize: "0.7rem",
    marginLeft: 0,
    letterSpacing: "0.03em",
    color: theme.palette.text.secondary,
  },
  filteredLabel: {},
  unfilteredLabel: {
    color: theme.palette.text.disabled,
  },
}));

const CheckboxFilter = ({
  filter,
  filterKey,
  optionsKey,
  instrumentCategory,
  header,
  hideOmit,
  searchThreshold,
  maxHeight,
  width,
  collapsible = false,
  onFilterChange,
  onClosePopper,
  data,
}) => {
  const Messages = useMessages();
  let currentFilter = get(filter, "filterValue", {});

  if (typeof currentFilter !== "object") {
    currentFilter = {
      selected: Array.isArray(currentFilter) ? currentFilter : [currentFilter],
      omit: false,
    };
  }

  const [options, setOptionKey] = useOptionsService(instrumentCategory);

  const [processedOptions, setProcessedOptions] = React.useState([]);
  const [searchValue, setSearchValue] = React.useState("");
  const [selected, setSelected] = React.useState(currentFilter.selected || []);

  const [omit, setOmit] = React.useState(currentFilter.omit || false);
  const [filteredOptions, setFilteredOptions] = React.useState([]);
  const classes = useStyles();

  React.useEffect(() => {
    if (optionsKey) {
      setOptionKey(optionsKey);
    } else if (data) {
      let opts = [];

      data.forEach((d) => {
        if (get(d, filterKey)) {
          opts.push(get(d, filterKey));
        }
      });

      opts = uniq(opts.sort()).map((o) => {
        return { label: o, value: o };
      });

      setProcessedOptions(opts);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [optionsKey, data]);

  React.useEffect(() => {
    if (optionsKey) {
      if (utils.hasNonEmptyValue(options) && Array.isArray(options[0].value)) {
        const values = options.map((o) => o.value);
        options.forEach((o, i) => {
          values.forEach((v, i2) => {
            const result = o.value.every((val) => v.includes(val)) && i !== i2;
            if (result) {
              options[i2].subOptions = options[i2].subOptions || [];
              options[i2].subOptions.push(cloneDeep(o));
              o.delete = true;
            }
          });
        });
      }

      setProcessedOptions(options.filter((o) => !o.delete));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [options]);

  const handleInputChange = (event) => {
    const filtered = [];

    let value = event.target.value;
    setSearchValue(value);
    value = `${deburr(value.trim()).toLowerCase()}`;

    if (value !== "") {
      processedOptions.forEach((item, i) => {
        if (item.label.toLowerCase().startsWith(value)) {
          filtered.push(item.value);
        } else if (item.alt && item.alt.toLowerCase().startsWith(value)) {
          filtered.push(item.value);
        }
      });
    }

    setFilteredOptions(filtered);
  };

  const handleOmitChecked = (event) => {
    let checked = event.target.checked;
    setOmit(checked);
    if (utils.hasNonEmptyValue(selected)) {
      applyCheckboxFilter(selected, checked);
    }
  };

  const handleOptionChecked = (event, parentValues) => {
    const item = event.target.value;
    const items = item.split(","); //Array.isArray(item) ? item : [item];
    const checked = event.target.checked;
    let selectedValues = selected.filter((c) => !parentValues.includes(c));

    if (checked) {
      items.forEach((i) => {
        if (!selected.includes(i)) {
          selectedValues.push(i);
        }
      });
    } else {
      selectedValues = selectedValues.filter((c) => !items.includes(c));
    }

    applyCheckboxFilter(selectedValues, omit);
  };

  const clearFilter = () => {
    setFilteredOptions([]);
    setOmit(false);
    setSearchValue("");
    applyCheckboxFilter([], false);
  };

  const executeQuery = (selected, omit) => {
    const values = {
      filterKey: filterKey,
      filterValue: {
        selected: selected,
        omit: omit,
      },
      filterType: Constants.FILTER_TYPE.CHECKBOX,
    };

    return onFilterChange(values);
  };

  const applyCheckboxFilter = (selected, omit) => {
    setSelected(selected);
    executeQuery(selected, omit);
  };

  const renderOption = (option, isSubOption, parentValues = []) => {
    if (!option.value) {
      return null;
    }
    let itemValue = option.value;
    const itemsValues = Array.isArray(itemValue) ? itemValue : [itemValue];
    let isChecked = !itemsValues.some((v) => !selected.includes(v));
    let isFiltered = !itemsValues.some((v) => !filteredOptions.includes(v));
    let isUnfiltered = utils.hasNonEmptyValue(filteredOptions) && !isFiltered;

    const item = (
      <FormControlLabel
        classes={{
          root: clsx(classes.labelRoot, {
            [classes.suboption]: isSubOption,
          }),
          label: clsx(classes.label, {
            [classes.filteredLabel]: isFiltered,
            [classes.unfilteredLabel]: isUnfiltered,
          }),
        }}
        className={filteredOptions.indexOf(itemValue) > -1 ? "filtered" : ""}
        key={itemValue}
        control={
          <Checkbox
            size="small"
            value={itemValue}
            classes={{ root: classes.checkboxRoot }}
            checked={isChecked}
            indeterminate={omit && isChecked}
            icon={<CheckBoxOutlineBlankIcon classes={{ root: classes.iconRoot }} />}
            checkedIcon={<CheckBoxIcon color="secondary" classes={{ root: classes.iconRoot }} />}
            indeterminateIcon={
              <IndeterminateCheckBoxIcon color="error" classes={{ root: classes.iconRoot }} />
            }
            onChange={(e) => handleOptionChecked(e, parentValues)}
          />
        }
        label={option.label}
      />
    );

    return item;
  };

  const renderOptionGroup = (option) => {
    var options = [];
    options.push(renderOption(option));
    if (option.subOptions) {
      const allSubOptionValues = [];
      option.subOptions.forEach((o) => o.value.forEach((v) => allSubOptionValues.push(v)));
      const parentValues = option.value.filter((o) => !allSubOptionValues.includes(o));
      option.subOptions.forEach((suboption) => {
        options.push(renderOption(suboption, true, parentValues));
      });
    }

    return (
      <div key={`optionGroup_${option.value.toString()}`} className={classes.optionGroup}>
        {options}
      </div>
    );
  };

  const numColumns =
    options.length > 30
      ? 4
      : options.length > 8 || options.length === 3
      ? 3
      : Math.max(Math.min(options.length, 2), 1);

  const formHeight = utils.hasNonEmptyValue(maxHeight)
    ? maxHeight
    : utils.hasNonEmptyValue(options)
    ? Math.ceil(options.length / numColumns) * 23
    : "auto";
  const formWidth = utils.hasNonEmptyValue(width) ? width : numColumns * 150;
  const showSearchThreshold = searchThreshold || 20;
  const doShowSearch = options.length >= showSearchThreshold;

  return (
    <div>
      <div className={classes.toolbar}>
        {utils.hasNonEmptyValue(header) && (
          <div className={classes.toolbarHeader}>
            <span className={classes.header}>{header}</span>
          </div>
        )}
        <div className={classes.toolbarSection}>
          {doShowSearch && (
            <TextInput
              maxLength={100}
              value={searchValue}
              classes={{ root: classes.inputWrapper }}
              onChange={handleInputChange}
              endAdornment={<SearchAdornment />}
              autoFocus={true}
            />
          )}
          {!hideOmit && (
            <FormControlLabel
              classes={{ root: classes.omitLabelRoot, label: classes.label }}
              control={
                <Checkbox
                  size="small"
                  checked={omit}
                  classes={{ root: classes.omitCheckboxRoot }}
                  icon={<CheckBoxOutlineBlankIcon classes={{ root: classes.iconRoot }} />}
                  checkedIcon={<CheckBoxIcon color="error" classes={{ root: classes.iconRoot }} />}
                  onChange={handleOmitChecked}
                />
              }
              label={Messages.LABEL.OMIT}
              color="primary"
            />
          )}
        </div>
        <div className={classes.toolbarSection}>
          <Button
            disabled={!utils.hasNonEmptyValue(selected)}
            color="primary"
            onClick={clearFilter}
            className={classes.linkButton}
          >
            {Messages.LABEL.CLEAR}
          </Button>
          {onClosePopper && (
            <>
              <IconButton className={classes.iconButton} color="primary" onClick={onClosePopper}>
                <CancelPresentationIcon className={classes.closeIcon} />
              </IconButton>
            </>
          )}
        </div>
      </div>

      <div
        className={clsx(classes.formControlWrapper, { [classes.collapsibleFormWrapper]: collapsible })}
        style={{ width: formWidth }}
      >
        <FormGroup style={{ height: formHeight }}>
          {processedOptions.length > 0 && (
            <>
              {processedOptions.map((option) => {
                return renderOptionGroup(option);
              })}
            </>
          )}
          {processedOptions.length === 0 && (
            <div>
              <i>{Messages.MESSAGE.NODATA.OPTIONS}</i>
            </div>
          )}
        </FormGroup>
      </div>
    </div>
  );
};

CheckboxFilter.FilterValueDefinition = PropTypes.shape({
  selected: PropTypes.arrayOf(PropTypes.string).isRequired,
  omit: PropTypes.bool,
});

CheckboxFilter.FilterDefinition = {
  filterKey: PropTypes.string,
  filterValue: PropTypes.oneOfType([PropTypes.string, PropTypes.array, CheckboxFilter.FilterValueDefinition]),
  filterType: PropTypes.oneOf(Object.values(Constants.FILTER_TYPE)),
};

CheckboxFilter.propTypes = {
  filter: PropTypes.shape(CheckboxFilter.FilterDefinition),
  filterKey: PropTypes.string.isRequired,
  optionsKey: PropTypes.oneOf(Object.keys(DataOptions.OPTIONS)),
  instrumentCategory: PropTypes.string,
  header: PropTypes.string,
  hideOmit: PropTypes.bool,
  searchThreshold: PropTypes.number,
  maxHeight: PropTypes.number,
  width: PropTypes.number,
  collapsible: PropTypes.bool,
  onFilterChange: PropTypes.func.isRequired,
  onClosePopper: PropTypes.func,
};

export default CheckboxFilter;
