import React from "react";
import PropTypes from "prop-types";

import IconButton from "@material-ui/core/IconButton";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import RemoveCircleOutlineIcon from "@material-ui/icons/RemoveCircleOutline";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  iconButton: {
    padding: "3px 2px",
  },
  removeIcon: {
    fontSize: "0.8rem",
  },
}));

const RemoveFilterButton = (props) => {
  const classes = useStyles();
  const { isOmit, ...otherProps } = props;

  return (
    <React.Fragment>
      {!isOmit && (
        <IconButton color="secondary" className={classes.iconButton} {...otherProps}>
          <HighlightOffIcon className={classes.removeIcon} fontSize="small" />
        </IconButton>
      )}
      {isOmit && (
        <IconButton className={classes.iconButton} {...otherProps}>
          <RemoveCircleOutlineIcon color="error" className={classes.removeIcon} fontSize="small" />
        </IconButton>
      )}
    </React.Fragment>
  );
};

RemoveFilterButton.propTypes = {
  isOmit: PropTypes.bool,
};

export default RemoveFilterButton;
