import React from "react";
import PropTypes from "prop-types";
import { HTTP_CODES } from "../commons/constants";
import * as utils from "commons/utils";
import { useMessages } from "providers/BrandingProvider";
import HttpService from "services/commons/HttpService";
import Button from "@material-ui/core/Button";
import { StyledSecondaryTooltip } from "components/Controls";

import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: 0,
    minWidth: 0,
  },
  label: {
    fontWeight: "normal",
    textDecoration: "underline",
    fontSize: "0.7rem",
    letterSpacing: "0.07em",
    lineHeight: 1.43,
  },
}));

const HyperlinkButton = ({ url, tooltip, label, newWindow = true, authenticated = false, ...otherProps }) => {
  const classes = useStyles();
  const Messages = useMessages();

  const fetchUrl = () => {
    if (authenticated) {
      let newTab = window.open("/loading", label);

      HttpService.get(url, { timeout: 30000 })
        .then((response) => {
          if (response.status === HTTP_CODES.NO_CONTENT) {
            newTab.location.href = "/loading?notavailable=true";
          } else {
            const url = response.data.content;
            if (newTab) {
              newTab.location.href = url;
            }
          }
        })
        .catch((error) => {
          newTab.location.href = "/loading?error=true";
          utils.issueErrorAlert(Messages.MESSAGE.DOWNLOAD_ERROR);
        });
    } else if (newWindow) {
      window.open(url, label);
    } else {
      window.location.href = url;
    }
  };

  return (
    <div className="no-print">
      {utils.hasNonEmptyValue(tooltip) && (
        <StyledSecondaryTooltip title={tooltip}>
          <Button
            disableRipple
            disableFocusRipple
            onClick={fetchUrl}
            classes={{ root: classes.root, label: classes.label }}
            color="secondary"
            {...otherProps}
          >
            {label}
          </Button>
        </StyledSecondaryTooltip>
      )}
      {!utils.hasNonEmptyValue(tooltip) && (
        <Button
          disableRipple
          disableFocusRipple
          onClick={fetchUrl}
          classes={{ root: classes.root, label: classes.label }}
          color="secondary"
          {...otherProps}
        >
          {label}
        </Button>
      )}
    </div>
  );
};

HyperlinkButton.propTypes = {
  url: PropTypes.string.isRequired,
  tooltip: PropTypes.string,
  label: PropTypes.string,
  authenticated: PropTypes.bool,
  newWindow: PropTypes.bool,
};

export default HyperlinkButton;
