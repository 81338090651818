import React from "react";
import PropTypes from "prop-types";

import { useMessages } from "providers/BrandingProvider";

import { TicketBody, TicketRow } from "components/modals//tickets/TicketComponents";

import { ClosableModal } from "components/containers/Modals";
import QBTypography from "components/QBTypography";
import { makeStyles } from "@material-ui/core/styles";
import { FormButton } from "components/Controls";
import { FormOutlinedButton } from "components/Controls";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: "30px 20px",
    marginBottom: 20,
    minWidth: 400,
    maxWidth: 500,
    border: "1px solid",
    borderColor: theme.palette.border.subtle,
    borderRadius: theme.shape.borderRadius,
    background: theme.palette.background.subtle,
  },
}));

export const InformationDialog = ({
  isOpen,
  setIsOpen,
  onCloseModal,
  onCancel,
  confirmLabel,
  cancelLabel,
  message,
}) => {
  const classes = useStyles();
  const Messages = useMessages();
  const closeNoAction = () => {
    setIsOpen(false);
    if (onCancel) {
      onCancel();
    }
  };

  const close = () => {
    setIsOpen(false);
    onCloseModal();
  };

  return (
    <ClosableModal open={isOpen} onClose={closeNoAction}>
      <TicketBody>
        <div className={classes.root}>
          <QBTypography>{message}</QBTypography>
        </div>
        <TicketRow style={{ justifyContent: "center" }}>
          {onCloseModal && (
            <FormButton style={{ margin: "0 0.5rem" }} onClick={close}>
              {confirmLabel || Messages.LABEL.CONFIRM}
            </FormButton>
          )}

          <FormOutlinedButton style={{ margin: "0 0.5rem" }} onClick={closeNoAction}>
            {cancelLabel || Messages.LABEL.CANCEL}
          </FormOutlinedButton>
        </TicketRow>
      </TicketBody>
    </ClosableModal>
  );
};

InformationDialog.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  setIsOpen: PropTypes.func.isRequired,
  onCloseModal: PropTypes.func,
  onCancel: PropTypes.func,
  cancelLabel: PropTypes.string,
  confirmLabel: PropTypes.string,
  message: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
};

export const RequestEditConfirmation = ({ ...dialogProps }) => {
  const Messages = useMessages();
  return (
    <InformationDialog
      {...dialogProps}
      message={Messages.MESSAGE.REQUEST_EDIT_WARNING}
      cancelLabel={Messages.LABEL.NO}
      confirmLabel={Messages.LABEL.YES}
    />
  );
};

RequestEditConfirmation.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  setIsOpen: PropTypes.func.isRequired,
  onCloseModal: PropTypes.func.isRequired,
};

export const RequestCancelConfirmation = ({ message, ...dialogProps }) => {
  const Messages = useMessages();
  return (
    <InformationDialog
      message={Messages.MESSAGE.REQUEST_CANCEL_WARNING}
      cancelLabel={Messages.LABEL.NO}
      confirmLabel={Messages.LABEL.YES}
      {...dialogProps}
    />
  );
};

RequestCancelConfirmation.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  setIsOpen: PropTypes.func.isRequired,
  onCloseModal: PropTypes.func.isRequired,
};

export const SimpleConfirmation = ({ message, ...dialogProps }) => {
  const Messages = useMessages();
  return (
    <InformationDialog
      message={message}
      cancelLabel={Messages.LABEL.NO}
      confirmLabel={Messages.LABEL.YES}
      {...dialogProps}
    />
  );
};

SimpleConfirmation.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  setIsOpen: PropTypes.func.isRequired,
  onCloseModal: PropTypes.func.isRequired,
  message: PropTypes.string.isRequired,
};
