import React, { useState } from "react";
import ReactTable from "react-table";
import PropTypes from "prop-types";
import { get, debounce, isEqual, cloneDeep, uniqBy, sortBy } from "lodash";
import axios from "axios";

import { useViewState } from "providers/ViewStateProvider";
import { useMessages, useBranding } from "providers/BrandingProvider";
import * as Constants from "commons/constants";
import * as utils from "commons/utils";
import * as filterUtils from "commons/filterUtils";
import * as DataConfig from "config/dataconfig";
import ToggleButtonFilter from "components/filters/ToggleButtonFilter";
import { useDataService } from "services/DataService";
import ConfigHelper from "./configHelper";
import { InnerTable, Loading, PreviousButton, NextButton } from "./TableComponents";
import { SimpleConfirmation } from "components/modals/InformationDialog";
import { generateXls } from "commons/export";
import SellSideSimpleActionButton from "components/SellSideSimpleActionButton";
import { StyledIconButton } from "components/Controls";

import Button from "@material-ui/core/Button";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { alpha } from "@material-ui/core/styles/colorManipulator";
import DownLoadIcon from "@material-ui/icons/GetApp";

import "react-table/react-table.css";
import "./datatable.scss";
import "./datatable_dark.scss";
import "./datatable_light.scss";
import useAutoRefresh from "hooks/useAutoRefresh";
import TradeDocuments from "components/modals/TradeDocuments";

const useStyles = makeStyles((theme) => ({
  subheader: {
    display: "flex",
    marginBottom: 10,
    width: "100%",
    justifyContent: "space-between",
  },
  subheaderSection: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    flexBasis: "33%",
    "&:last-child": {
      justifyContent: "flex-end",
    },
  },
  datatableWrapper: {
    display: "flex",
    flexFlow: "column",
    alignItems: "flex-start",
    alignContent: "flex-start",
    minHeight: 0,
    width: "100%",
    maxHeight: "100%",
    // eslint-disable-next-line no-useless-computed-key
    ["@media all and (-ms-high-contrast: none), (-ms-high-contrast: active)"]: {
      flexFlow: "row wrap",
    },
  },
}));

/* Order History 'Clear', Favorites 'Buy' */
const SubheaderFilledButton = withStyles((theme) => ({
  root: {
    minWidth: 50,
    fontSize: "0.6rem",
    padding: 4,
    lineHeight: 1,
    color: theme.palette.text.selected.secondary,
    backgroundColor: theme.palette.background.button.secondary,
    "&:hover": {
      backgroundColor: alpha(theme.palette.background.button.secondary, 0.5),
      color: theme.palette.text.selected.secondary,
    },
    "&.MuiButton-outlinedSecondary.Mui-disabled": {
      border: "1px solid",
      borderColor: theme.palette.border.disabled,
      color: theme.palette.text.unselected.disabled,
      backgroundColor: alpha(theme.palette.background.button.disabledsecondary, 0.3),
    },
  },
}))(Button);

const CancelToken = axios.CancelToken;

const adjustTableColumns = (tableConfig, data) => {
  let tableColumns = tableConfig.tableColumns;

  if (utils.hasNonEmptyValue(data)) {
    if (tableConfig.pruneEmptyColumns) {
      tableConfig.tableColumns = tableConfig.tableColumns.filter((column) => {
        return (
          column.static ||
          !utils.hasNonEmptyValue(column.accessor) ||
          data.some((d) => d.hasOwnProperty(column.accessor))
        );
      });
    }
  }

  const linksColumn = tableColumns.find((c) => c.accessor === "links");
  if (linksColumn) {
    let columnWidth = linksColumn.minWidth;
    data.forEach((d) => {
      const links = get(d, "links", []);
      let width = 0;

      if (links.length) {
        links.forEach((l) => {
          if (l.rel === "edit") {
            width += 20;
          } else if (l.rel === "action") {
            if (
              l.title.toLowerCase() === "cancel" ||
              l.title.toLowerCase() === "request cancel" ||
              l.title.toLowerCase() === "request edit"
            ) {
              width += 20;
            } else {
              width += 50;
            }
          }
        });
      }

      columnWidth = Math.max(columnWidth, width);
    });

    linksColumn.minWidth = columnWidth;
    linksColumn.maxWidth = Math.max(linksColumn.maxWidth, columnWidth);
  }

  return tableColumns;
};

function DataTable({
  queryType,
  querySubType,
  view,
  subView,
  instrumentCategory,
  style = {},
  editable = false,
  selectable = false,
  fixedWidth = false,
  autoSelectFirstBond = false,
  defaultHideFilterbar,
  selectedBond,
  data,
  unfilteredData,
  exportData,
  totalNumberRows,
  manual = false,
  loading = false,
  error = false,
  autorefresh = false,
  focusedElementId,
  onValueChange,
  onAddBondsToCart,
  onOpenTradeTicket,
  onOpenRequestTicket,
  onViewStateChange,
  onCellNavigation,
  onToggleAdvancedCriteriaModal,
  onDocumentsUpdated,
  onRowSelect,
  determineAlternateView,
  determineDisabledRow,
  alternateView = false,
  setInventoryRefreshedTime,
  TableProps,
}) {
  const classes = useStyles();
  const Messages = useMessages();
  const branding = useBranding();

  const [documentsOpen, setDocumentsOpen] = React.useState(null);

  const onOpenDocumentsModal = (bond) => {
    setDocumentsOpen(bond);
  };

  const isFDICFilterEnabled =
    process.env.REACT_APP_FEATURES_OWNED_FDIC_FILTER === "true" &&
    branding.features.OWNED_FDIC_FILTER === true;

  const [getDefaultFilter, getTableConfig] = new ConfigHelper(Messages);

  const [useAlternateView, setUseAlternateView] = React.useState(
    determineAlternateView ? determineAlternateView() : alternateView,
  );

  const [state, queryDataService, dispatchData] = useDataService(data, totalNumberRows);
  const [refreshState, queryRefreshDataService] = useDataService();
  const viewState = useViewState();

  const doQueryForData = data === undefined; // doQueryForData = server-side filtering and sort
  manual = manual || doQueryForData;

  let currentViewState = get(viewState, view || queryType, {});
  if (utils.hasNonEmptyValue(subView)) {
    currentViewState = get(currentViewState, subView, {});
  }

  // get filterbar prop from view/subView
  const doShowFilterbar = get(currentViewState, "showFilterbar", !defaultHideFilterbar);

  if (utils.hasNonEmptyValue(querySubType)) {
    currentViewState = get(currentViewState, querySubType, {});
  }

  const query = get(currentViewState, "query", {});
  const searchId = query.searchId;

  const searchFilter = get(state, "query.filter", {});
  const showLoading = loading || (state.isLoading && !state.query.suppressLoading);
  const highlightedRows = !state.options?.autorefresh ? state.query?.highlighted : [];
  const requestCancelToken = React.useRef();

  const filtered = !manual
    ? Object.keys(searchFilter).map((f) => {
        return { id: searchFilter[f].column || f, value: searchFilter[f] };
      })
    : [];

  const [expandedRows, setExpandedRows] = React.useState({});
  const [selectedBonds, setSelectedBonds] = React.useState([]);

  const handleSelectedBondsChange = (checked, bonds, rowIndex, identifier) => {
    if (utils.hasNonEmptyValue(bonds)) {
      let updatedSelected = [];
      const isIssuerBond = bonds[0].hasOwnProperty("issuerInventory");

      if (!isIssuerBond) {
        if (checked) {
          updatedSelected = uniqBy([...selectedBonds, ...bonds], identifier);
          setSelectedBonds(updatedSelected);
        } else {
          updatedSelected = selectedBonds.filter(
            (selectedBond) => !bonds.some((bond) => isEqual(bond, selectedBond)),
          );
          setSelectedBonds(updatedSelected);
        }
      } else {
        let updatedBonds = [];
        bonds.forEach((b) => (updatedBonds = updatedBonds.concat(b.issuerInventory)));

        if (checked) {
          updatedSelected = uniqBy([...selectedBonds, ...updatedBonds], identifier);
          setSelectedBonds(updatedSelected);
          expandedRows[rowIndex] = bonds[0].cusip;
          setExpandedRows(expandedRows);
        } else {
          updatedSelected = selectedBonds.filter(
            (selectedBond) => !updatedBonds.some((bond) => isEqual(bond, selectedBond)),
          );
          setSelectedBonds(updatedSelected);
        }
      }
    }
  };

  const handleAddBondsToCart = (bonds, currentBonds) => {
    if (onAddBondsToCart) {
      setSelectedBonds([]);
      onAddBondsToCart(bonds, currentBonds);
    }
  };

  const handleOpenRequestTicket = (orders) => {
    if (onOpenRequestTicket) {
      const filteredOrders = orders.filter((order) => {
        const inDataOrder = state.data.find((d) => d.orderNo === order.orderNo);
        return inDataOrder && utils.isEditableOrder(inDataOrder);
      });
      setSelectedBonds([]);
      onOpenRequestTicket(filteredOrders);
    }
  };

  const handleToggleFilterbar = () => {
    handleCurrentViewStateChange({ showFilterbar: !doShowFilterbar });
  };

  const handleCurrentViewStateChange = (state, querySubType) => {
    if (onViewStateChange) {
      onViewStateChange(state, querySubType);
    }
  };

  const handleSubNavOptionSelected = (option) => {
    handleFilterChange([{ value: option }]);
  };

  const handleFilterChange = (filters) => {
    if (requestCancelToken.current) {
      requestCancelToken.current();
    }

    const currentState = state;
    currentState.query.filter = get(state, "query.filter", {});

    if (!utils.hasNonEmptyValue(filters) || !utils.hasNonEmptyValue(filters[filters.length - 1].value)) {
      currentState.query.filter = getDefaultFilter(tableConfig);
    } else {
      const filterKeys = Object.keys(currentState.query.filter);

      filterKeys.forEach((k) => {
        const propertyConfig = get(DataConfig.PROPERTY, k);
        if (
          k !== "tradeType" &&
          k !== "instrumentTypeId" &&
          k !== "instrumentCategory" &&
          (!utils.hasNonEmptyValue(propertyConfig) || !utils.hasNonEmptyValue(propertyConfig.filter))
        ) {
          delete currentState.query.filter[k];
        }
      });

      const filter = filters[filters.length - 1];
      const filtersToProcess = Array.isArray(filter.value) ? filter.value : [filter.value];

      filtersToProcess.forEach((f, i) => {
        f.column = filter.id || f.filterKey;
        const filterKey = f.filterKey;
        const filterValue = f.filterValue;

        if (filterUtils.hasEmptyFilterValue(filterValue)) {
          delete currentState.query.filter[filterKey];

          if (
            filterKey === "cusip" &&
            Object.keys(currentState.query.filter).length === 1 &&
            currentState.query.filter.hasOwnProperty("instrumentCategory")
          ) {
            currentState.query.filter = getDefaultFilter(tableConfig);
          }

          if (!manual && i === 0) {
            filters.pop();
          }
        } else {
          currentState.query.filter[filterKey] = f;
        }
      });
    }

    if (doQueryForData) {
      filters.pop();
      const cancelToken = new CancelToken(function executor(c) {
        requestCancelToken.current = c;
      });

      currentState.query.cancelToken = cancelToken;
    }

    currentState.query.page = 0;
    currentState.query.clearDataOnFetch = false;
    currentState.query.highlighted = [];
    currentState.query.suppressLoading = false;

    executeQuery(currentState.query);
  };

  const _executeQuery = (query) => {
    queryDataService({ ...query });
    handleCurrentViewStateChange({ query: { ...query } }, querySubType);
  };

  const executeQuery = debounce(_executeQuery, 10);

  const handleSortedChange = (newSorted) => {
    if (utils.hasNonEmptyValue(newSorted) && utils.hasNonEmptyValue(newSorted[0].id)) {
      const currentState = state;
      currentState.query.page = 0;
      currentState.query.sort = newSorted;
      currentState.query.clearDataOnFetch = false;
      currentState.query.highlighted = [];
      currentState.query.suppressLoading = false;
      executeQuery(currentState.query);
    }
  };

  const handlePageChange = (page) => {
    const currentState = state;
    currentState.query.page = page;
    currentState.query.clearDataOnFetch = false;
    currentState.query.highlighted = [];
    currentState.query.suppressLoading = false;
    executeQuery(currentState.query);
  };

  const handleRowExpanded = (newExpanded, index, e, props) => {
    const dataIndex = index[0];
    const numBids = get(props.original, "bids", []).length;

    if (numBids > 1 || (numBids === 1 && !utils.hasNonEmptyValue(props.original.highBid))) {
      if (newExpanded[dataIndex] !== false) {
        var bidId = props.original.id;
        newExpanded[dataIndex] = bidId;
      }
    } else if (props.original.numSimilarCusips > 0 || props.original.inventorySize > 0) {
      if (newExpanded[dataIndex] !== false) {
        var cusip = props.original.cusip;
        newExpanded[dataIndex] = cusip;
      }
    } else {
      delete newExpanded[dataIndex];
    }

    setExpandedRows(newExpanded);
  };

  const handleRowSelected = (row) => {
    if (onRowSelect) {
      onRowSelect(row);
    } else if (utils.hasNonEmptyValue(row?.cusip)) {
      const state = { selectedBond: row, activeTool: Constants.TOOL_TYPE.COMPARABLE };
      handleCurrentViewStateChange(state, querySubType);
    }
  };

  const handleDocumentsClose = (refetch) => {
    setDocumentsOpen(null);
    if (refetch && onDocumentsUpdated) {
      onDocumentsUpdated();
    }
  };

  const querySort = state.query?.sort;
  let tableRef = React.useRef(null);
  const optionsData = unfilteredData || data;
  const tableConfig = React.useMemo(
    () =>
      getTableConfig({
        tableType: queryType,
        tableSubType: querySubType,
        instrumentCategory: instrumentCategory,
        searchFilter: searchFilter,
        showFilterbar: doShowFilterbar,
        alternateView: useAlternateView,
        onToggleFilterbar: handleToggleFilterbar,
        onSelectedBondsChange: handleSelectedBondsChange,
        onAddBondsToCart: handleAddBondsToCart,
        onOpenTradeTicket: onOpenTradeTicket,
        onOpenRequestTicket: onOpenRequestTicket,
        onValueChange: onValueChange,
        onCellNavigation: onCellNavigation,
        onToggleAdvancedCriteriaModal: onToggleAdvancedCriteriaModal,
        onOpenDocumentsModal: onOpenDocumentsModal,
        selectedBond: selectedBond,
        selectedBonds: selectedBonds,
        sorted: state.query?.sort,
        editable: editable,
        focusedElementId: focusedElementId,
        data: optionsData,
        tableRef: tableRef,
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      queryType,
      querySubType,
      instrumentCategory,
      doShowFilterbar,
      selectedBonds,
      editable,
      searchFilter,
      querySort,
      useAlternateView,
      optionsData,
    ],
  );

  let tableColumns = adjustTableColumns(tableConfig, state.data);

  const secondaryNavOptions = tableConfig.secondaryNavOptions || [];
  const doShowSecondaryNav = utils.hasNonEmptyValue(secondaryNavOptions) && tableConfig.filterable;
  const doShowClearFilter = tableConfig.showClearFilter && tableConfig.filterable;
  const doShowMultiSelect = utils.hasNonEmptyValue(tableConfig.multiselect);
  const doShowSubheader = doShowSecondaryNav || doShowClearFilter || doShowMultiSelect;
  const defaultFilter = getDefaultFilter(tableConfig);

  const hasFilterApplied = !isEqual(searchFilter, defaultFilter);

  const [noDataText, setNoDataText] = useState(
    showLoading
      ? ""
      : hasFilterApplied && tableConfig.noFilteredDataText
      ? tableConfig.noFilteredDataText
      : tableConfig.noDataText,
  );

  // initial query after component mount, or triggered by searchId
  React.useEffect(() => {
    delete query.cancelToken;

    if (!query.doNotReset) {
      const defaultParams = {
        type: queryType,
        size: tableConfig.defaultPageSize,
        page: 0,
        filter: getDefaultFilter(tableConfig),
        sort: tableConfig.defaultSort || [],
        clearDataOnFetch: true,
      };

      queryDataService({ ...defaultParams, ...query });

      let updatedQuery = { ...defaultParams, ...query };
      delete updatedQuery.suppressLoading;
      updatedQuery.highlighted = [];

      handleCurrentViewStateChange({ query: updatedQuery }, querySubType);
    } else {
      queryDataService(query, { autorefresh: true });
      delete query.doNotReset;
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryType, querySubType, searchId]);

  const refreshData = () => {
    if (utils.hasNonEmptyValue(state.query)) {
      state.query.highlighted = [];
      state.query.suppressLoading = true;
      state.query.doNotReset = true;
      state.query.clearDataOnFetch = false;
      queryRefreshDataService(cloneDeep(state.query), { autorefresh: true });
    }
  };

  useAutoRefresh(refreshData, !autorefresh);

  // select first row after initial query
  React.useEffect(() => {
    if (!state.isLoading && !state.isError && !state.isUnauthorized) {
      if (autoSelectFirstBond && selectable && utils.hasNonEmptyValue(state.data)) {
        const noBondSelected =
          !utils.hasNonEmptyValue(selectedBond) ||
          !utils.hasNonEmptyValue(state.data.find((b) => b.cusip === selectedBond.cusip));
        if (noBondSelected) {
          const firstSelectable = state.data.find((b) => utils.hasNonEmptyValue(b.brokeredsecurityid));
          if (firstSelectable) {
            const state = { selectedBond: firstSelectable };
            handleCurrentViewStateChange(state, querySubType);
          }
        } else if (utils.hasNonEmptyValue(selectedBond) && !state.options?.autorefresh) {
          handleCurrentViewStateChange({ selectedBond: selectedBond }, querySubType);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state]);

  React.useEffect(() => {
    if (setInventoryRefreshedTime && state.refreshedTime) {
      setInventoryRefreshedTime(state.refreshedTime);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.refreshedTime]);

  React.useEffect(() => {
    if (setInventoryRefreshedTime && refreshState.refreshedTime) {
      setInventoryRefreshedTime(refreshState.refreshedTime);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshState.refreshedTime]);

  React.useEffect(() => {
    if (refreshState.isLoading || refreshState.isError || refreshState.isInit) {
      return;
    }

    if (isEqual(state.query, refreshState.query) && !isEqual(state.data, refreshState.data)) {
      dispatchData(refreshState);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshState]);

  React.useEffect(() => {
    if (utils.hasNonEmptyValue(state.data)) {
      if (utils.hasNonEmptyValue(expandedRows)) {
        let updatedExpanded = {};
        const expandedRowValues = Object.values(expandedRows);
        state.data.forEach((bond, i) => {
          if (get(bond, "bids", []).length > 0 && expandedRowValues.includes(bond.id)) {
            updatedExpanded[i] = bond.id;
          } else if (
            expandedRowValues.includes(bond.cusip) &&
            (bond.numSimilarCusips > 0 || bond.inventorySize > 0)
          ) {
            updatedExpanded[i] = bond.cusip;
          }
        });
        setExpandedRows(updatedExpanded);
      }

      if (determineAlternateView) {
        setUseAlternateView(determineAlternateView(state.data));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.data]);

  React.useEffect(() => {
    setSelectedBonds([]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [instrumentCategory]);

  React.useEffect(() => {
    setUseAlternateView(alternateView);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [alternateView]);

  React.useEffect(() => {
    if (manual && utils.hasNonEmptyValue(data) && utils.hasNonEmptyValue(selectedBonds)) {
      const isIssuerBond = data[0].hasOwnProperty("issuerInventory");
      let comparisonData = [];

      if (!isIssuerBond) {
        comparisonData = data;
      } else {
        data.forEach((b) => (comparisonData = comparisonData.concat(b.issuerInventory)));
      }
      let updatedSelected = selectedBonds.filter((selectedBond) =>
        comparisonData.some((bond) => bond.cusip === selectedBond.cusip),
      );

      if (updatedSelected) {
        setSelectedBonds(updatedSelected);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const minRows = () => {
    if (!manual && tableConfig.filterable) {
      return 3;
    } else {
      if (utils.hasNonEmptyValue(state.data)) {
        return Math.min(state.data.length, tableConfig.defaultPageSize);
      } else {
        return 3;
      }
    }
  };

  React.useEffect(() => {
    let noDataText = "";

    if (state.isError || error) {
      noDataText = Messages.MESSAGE.NODATA.SERVER_ERROR;
    } else if (state.isUnauthorized) {
      noDataText = Messages.MESSAGE.GENERIC_NO_ACCESS_TO_FEATURE;
    } else if (showLoading) {
      noDataText = "";
    } else if (hasFilterApplied && tableConfig.noFilteredDataText) {
      noDataText = tableConfig.noFilteredDataText;
    } else {
      noDataText = tableConfig.noDataText;
    }

    setNoDataText(noDataText);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.isUnauthorized, state.isError, error, hasFilterApplied, showLoading]);

  return (
    <div className={classes.datatableWrapper}>
      {doShowSubheader && (
        <div className={classes.subheader}>
          <div className={classes.subheaderSection}>
            {doShowClearFilter && (
              <SubheaderFilledButton
                style={{ marginRight: "0.5rem" }}
                onClick={() => handleFilterChange([])}
                variant="outlined"
                size="small"
                color="secondary"
                disabled={!hasFilterApplied}
              >
                {Messages.LABEL.CLEAR}
              </SubheaderFilledButton>
            )}
            {tableConfig.exportable && (
              <StyledIconButton
                disabled={showLoading}
                style={{ marginRight: "0.5rem" }}
                color="secondary"
                onClick={(e) =>
                  generateXls(
                    exportData || state.data,
                    tableColumns,
                    "Order History",
                    `${Messages.BRANDING.APPLICATION_NAME} Order History`,
                  )
                }
              >
                <DownLoadIcon />
              </StyledIconButton>
            )}
            {doShowMultiSelect && subView === Constants.SUBVIEW.SELL && (
              <>
                <div>
                  <SubheaderFilledButton
                    variant="outlined"
                    size="small"
                    color="secondary"
                    disabled={
                      !utils.hasNonEmptyValue(selectedBonds) ||
                      !selectedBonds.some((order) => {
                        const inDataOrder = state.data.find((d) => d.orderNo === order.orderNo);
                        return inDataOrder && utils.isEditableOrder(inDataOrder);
                      })
                    }
                    onClick={() => handleOpenRequestTicket(selectedBonds)}
                  >
                    Edit Selected
                  </SubheaderFilledButton>
                </div>
                <div style={{ marginLeft: 4, width: 100, textAlign: "center" }}>
                  <SellSideSimpleActionButton
                    label="Cancel Selected"
                    action="cancel"
                    variant="outlined"
                    orders={selectedBonds}
                    onActionStatusChange={onValueChange}
                    confirmationModal={{
                      Component: SimpleConfirmation,
                      message: Messages.MESSAGE.MULTI_CANCEL_WARNING,
                    }}
                    disabled={
                      !utils.hasNonEmptyValue(selectedBonds) ||
                      !selectedBonds.some((order) => {
                        const inDataOrder = state.data.find((d) => d.orderNo === order.orderNo);
                        return inDataOrder && utils.isEditableOrder(inDataOrder);
                      })
                    }
                  />
                </div>
              </>
            )}
            {doShowSecondaryNav && secondaryNavOptions.length > 1 && (
              <ToggleButtonFilter
                variant="navigation"
                optionsKey={secondaryNavOptions[0].optionsKey}
                filterKey={secondaryNavOptions[0].filterKey}
                filter={searchFilter[secondaryNavOptions[0].filterKey]}
                onFilterChange={handleSubNavOptionSelected}
              />
            )}
          </div>
          <div className={classes.subheaderSection}>
            {isFDICFilterEnabled && tableConfig.filterable && tableConfig.FDICFilter && (
              <ToggleButtonFilter
                label={tableConfig.FDICFilter.filterlabel || tableConfig.FDICFilter.label}
                optionsKey={tableConfig.FDICFilter.filter.optionsKey}
                filterKey={tableConfig.FDICFilter.accessor}
                filter={searchFilter[tableConfig.FDICFilter.accessor]}
                filterType={Constants.FILTER_TYPE.BOOLEAN_TOGGLE}
                variant="navigation"
                onFilterChange={handleSubNavOptionSelected}
              />
            )}
            {doShowSecondaryNav && (
              <ToggleButtonFilter
                variant="navigation"
                optionsKey={secondaryNavOptions[secondaryNavOptions.length - 1].optionsKey}
                filterKey={secondaryNavOptions[secondaryNavOptions.length - 1].filterKey}
                filter={searchFilter[secondaryNavOptions[secondaryNavOptions.length - 1].filterKey]}
                onFilterChange={handleSubNavOptionSelected}
              />
            )}
          </div>
        </div>
      )}
      <ReactTable
        ref={(r) => (tableRef.current = r)}
        manual={manual}
        columns={tableColumns}
        data={state.data}
        page={state.page.number}
        pageSize={state.page.size}
        pages={state.page.totalPages}
        minRows={minRows()}
        defaultPageSize={tableConfig.defaultPageSize}
        loading={showLoading}
        loadingText=""
        LoadingComponent={Loading}
        noDataText={noDataText}
        resizable={false}
        expanderDefaults={{
          sortable: false,
          resizable: false,
          filterable: false,
        }}
        defaultSortMethod={(a, b, desc) => {
          // force null and undefined to the bottom
          a = a === null || a === undefined ? -Infinity : a;
          b = b === null || b === undefined ? -Infinity : b;
          // force any string values to lowercase
          a = typeof a === "string" ? a.toLowerCase() : a;
          b = typeof b === "string" ? b.toLowerCase() : b;
          // Return either 1 or -1 to indicate a sort priority
          if (a > b) {
            return 1;
          }
          if (a < b) {
            return -1;
          }
          // returning 0 or undefined will use any subsequent column sorting methods or the row index as a tiebreaker
          return 0;
        }}
        onExpandedChange={handleRowExpanded}
        expanded={expandedRows}
        filterable={tableConfig.filterable}
        filtered={filtered}
        onFilteredChange={handleFilterChange}
        sortable={tableConfig.sortable}
        sorted={state.query?.sort}
        onSortedChange={handleSortedChange}
        showPagination={tableConfig.showPagination}
        showPageJump={false}
        showPageSizeOptions={false}
        onPageChange={handlePageChange}
        PreviousComponent={PreviousButton}
        NextComponent={NextButton}
        renderCurrentPage={(page) => {
          // paging info
          let numStart = "--";
          let numEnd = "--";
          let numTotalRecs = "--";

          let numRecs = 0;
          if (utils.hasNonEmptyValue(state.page)) {
            numRecs = state.page.numRecs;
            numStart = numRecs > 0 ? state.page.number * state.page.size + 1 : 0;
            numEnd = numRecs > 0 ? numStart + numRecs - 1 : 0;
            numTotalRecs = numRecs > 0 ? state.page.totalElements : 0;
          }

          return (
            <div>
              <span className="-startNum">{numStart}</span>
              {numRecs > 0 && (
                <React.Fragment>
                  <span>-</span>
                  <span className="-endNum">{numEnd}</span>
                </React.Fragment>
              )}
              <span> of </span>
              <span className="-totalRecs">{numTotalRecs}</span>
              {queryType === Constants.QUERY_TYPE.INVENTORY && (
                <span className="-pagelabel">Offerings Available</span>
              )}
            </div>
          );
        }}
        renderTotalPagesCount={(page) => {
          // we're writing out own paging info
          return null;
        }}
        style={{
          ...style,
          ...{
            maxHeight: tableConfig.maxHeight,
            minHeight: 0,
            width: "100%",
          },
        }}
        getTableProps={(tableState) => {
          if (!fixedWidth) {
            return {};
          }
          return {
            style: { maxWidth: tableState.rowMinWidth, minHeight: 0 },
          };
        }}
        getTheadProps={(tableState) => {
          if (tableConfig.hideHeaders) {
            return { style: { display: "none" } };
          }
          return {
            className: !tableState.filterable || !doShowFilterbar ? "-nofilters" : "",
          };
        }}
        getTheadThProps={(tableState, rowInfo, column, instance) => {
          return {
            onClick: (e) => {
              // Do whatever else you need to
              if (column.sortable) {
                column.defaultSortDesc = e.target.parentNode.classList?.contains("sortDesc");
                instance.sortColumn(column);
              }
            },
          };
        }}
        getTheadFilterProps={(tableState) => {
          return {
            className: !tableState.filterable || !doShowFilterbar ? "-hidden" : "",
          };
        }}
        getTrProps={(tableState, rowInfo) => {
          if (
            rowInfo &&
            rowInfo.original &&
            (rowInfo.original.hasOwnProperty("id") || rowInfo.original.hasOwnProperty("issuerInventory"))
          ) {
            const isExpanded =
              tableState.expanded.hasOwnProperty(rowInfo.viewIndex) &&
              tableState.expanded[rowInfo.viewIndex] !== false;
            const isHighlighted = highlightedRows?.includes(rowInfo.original.id);
            const isSelected =
              utils.hasNonEmptyValue(selectedBond) && rowInfo.original.cusip === selectedBond.cusip;
            const isDisabled = determineDisabledRow ? determineDisabledRow(rowInfo.original) : false;

            let classNames = isSelected && selectable ? "selected" : "";

            if (selectable && (utils.hasNonEmptyValue(rowInfo.original.cusip) || onRowSelect)) {
              classNames += " selectable";
            }

            if (isExpanded) {
              classNames += " expanded";
            }

            if (isHighlighted) {
              classNames += " highlighted";
            }

            if (isDisabled) {
              classNames += " disabled";
            }

            return {
              className: classNames,
            };
          } else if (rowInfo && rowInfo.original) {
            return {
              className: "subheader",
            };
          } else {
            return {};
          }
        }}
        getTdProps={(state, rowInfo, column) => {
          if (
            selectable &&
            column.className !== "checkCell" &&
            column.className !== "controlCell" &&
            column.className !== "documentIcons" &&
            !column.expander &&
            (utils.hasNonEmptyValue(rowInfo?.original.cusip) || onRowSelect)
          ) {
            return {
              onClick: (e) => handleRowSelected(rowInfo.original),
            };
          } else if (!column.expander) {
            return {
              onClick: (e) => e.stopPropagation(),
            };
          } else {
            return {};
          }
        }}
        getPaginationProps={(tableState) => {
          // we're writing our own paging info; set these props to empty to suppress rendering

          if (!tableState.manual) {
            state.page.numRecs = Math.min(tableState.pageRows.length, tableState.pageSize);
            state.page.totalElements = tableState.sortedData.length;
            state.page.totalPages = Math.ceil(tableState.sortedData.length / tableState.pageSize);
          }

          return {
            canNext: state.page.number + 1 < state.page.totalPages,
            pageText: "",
            ofText: "",
          };
        }}
        getNoDataProps={(tableState) => {
          if (tableState.loading) {
            return { style: { display: "none" } };
          }

          let top = tableState.filterable && doShowFilterbar ? 123 : tableConfig.hideHeaders ? 42 : 72;
          return { style: { top: top } };
        }}
        getLoadingProps={(tableState) => {
          let top = 0;

          if (tableConfig.loadingTop) {
            top = tableConfig.loadingTop;
          } else {
            top = tableState.filterable && doShowFilterbar ? 81 : tableConfig.hideHeaders ? 0 : 30;
          }
          return { style: { top: top } };
        }}
        SubComponent={
          !tableConfig.expandable
            ? null
            : (row) => {
                if (!get(expandedRows, row.index)) {
                  return null;
                }
                if (get(row.original, "bids", []).length > 1) {
                  const data = row.original.bids
                    .map((b) => {
                      return {
                        highBid: b,
                        id: b.id,
                        enteredUser: {
                          fullName: row.original.enteredUser.fullName,
                        },
                        enteredDate: row.original.enteredDate,
                        status: {
                          description: row.original.status.description,
                        },
                        quantity: row.original.quantity,
                        currentFace: row.original.currentFace,
                        cusip: row.original.cusip,
                        issuer: row.original.issuer,
                        instrumentType: {
                          description: row.original.instrumentType.description,
                        },
                        brokeredClearingAccount: {
                          accountFormatted: row.original.brokeredClearingAccount.accountFormatted,
                        },
                      };
                    })
                    .filter((b) => b.id !== row.original?.highBid.id);
                  data.sort((a, b) => b.highBid.markupPrice - a.highBid.markupPrice);
                  return (
                    <InnerTable
                      key={row.original.id}
                      bidId={row.original.id}
                      data={data}
                      tableColumns={tableConfig.tableColumns}
                    />
                  );
                }

                if (row.original.brokeredsecurityid && get(row.original, "numSimilarCusips", -1) > 0) {
                  return (
                    <InnerTable
                      key={row.original.brokeredsecurityid}
                      bondId={row.original.brokeredsecurityid}
                      numSimilarCusips={get(row.original, "numSimilarCusips")}
                      tableColumns={tableConfig.tableColumns}
                      queryFilter={state.query?.filter}
                      selectedBond={selectedBond}
                      onRowSelected={handleRowSelected}
                      queryType={queryType}
                    />
                  );
                }

                if (get(row.original, "inventorySize", -1) > 0) {
                  const innerData = sortBy(get(row.original, "issuerInventory", []), "cusip");
                  return (
                    <InnerTable
                      key={row.original.brokeredsecurityid}
                      tableColumns={tableConfig.tableColumns}
                      data={innerData}
                      selectedBond={selectedBond}
                      onRowSelected={handleRowSelected}
                      queryType={queryType}
                    />
                  );
                }

                return null;
              }
        }
        {...TableProps}
      ></ReactTable>
      <TradeDocuments
        open={documentsOpen !== null}
        onClose={handleDocumentsClose}
        order={documentsOpen?.order}
      />
    </div>
  );
}

export default DataTable;

DataTable.propTypes = {
  queryType: PropTypes.oneOf(Object.values(Constants.QUERY_TYPE)).isRequired,
  querySubType: PropTypes.oneOf(
    Object.values({ ...Constants.INVENTORY_TYPE, ...Constants.TRADE_TYPE, ...Constants.RATES_TYPE }),
  ),
  subView: PropTypes.oneOf(Object.values(Constants.SUBVIEW)),
  instrumentCategory: PropTypes.oneOf(Object.values(Constants.INVENTORY_TYPE)),
  data: PropTypes.arrayOf(PropTypes.object),
  selectedBond: PropTypes.object,
  style: PropTypes.object,
  manual: PropTypes.bool,
  editable: PropTypes.bool,
  selectable: PropTypes.bool,
  fixedWidth: PropTypes.bool,
  defaultHideFilterbar: PropTypes.bool,
  loading: PropTypes.bool,
  error: PropTypes.bool,
  alternateView: PropTypes.bool,
  determineAlternateView: PropTypes.func,
  determineDisabledRow: PropTypes.func,
  autoSelectFirstBond: PropTypes.bool,
  focusedElementId: PropTypes.string,
  onValueChange: PropTypes.func,
  onAddBondsToCart: PropTypes.func,
  onOpenRequestTicket: PropTypes.func,
  onViewStateChange: PropTypes.func,
  onCellNavigation: PropTypes.func,
  onToggleAdvancedCriteriaModal: PropTypes.func,
  onRowSelect: PropTypes.func,
  onDocumentsUpdated: PropTypes.func,
  setInventoryRefreshedTime: PropTypes.func,
  TableProps: PropTypes.object,
};
