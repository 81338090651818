import React from "react";
import { useGlobal } from "reactn";
import PropTypes from "prop-types";
import { get } from "lodash";

import { useViewStateDispatcher } from "providers/ViewStateProvider";
import * as Constants from "commons/constants";
import * as queryUtils from "commons/queryUtils";

function BuyRedirectHandler(props) {
  const { location } = props;
  const dispatchViewStateChange = useViewStateDispatcher();
  const [isAuthenticated] = useGlobal("authenticated");

  React.useEffect(() => {
    if (location.search && isAuthenticated) {
      const query = queryUtils.getInventoryQueryFromQueryString(location.search);

      dispatchViewStateChange({
        state: { query: query, activeView: Constants.VIEW.INVENTORY },
        view: Constants.VIEW.INVENTORY,
        subView: Constants.SUBVIEW.BUY,
        querySubType: get(query, "filter.instrumentCategory"),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.search, isAuthenticated]);

  return null;
}

BuyRedirectHandler.propTypes = {
  location: PropTypes.object.isRequired,
};

export default BuyRedirectHandler;
