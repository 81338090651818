import React from "react";
import PropTypes from "prop-types";

import * as Constants from "commons/constants";

import FormGroup from "@material-ui/core/FormGroup";
import ToggleButtonFilter from "components/filters/ToggleButtonFilter";

import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  headerWrapper: {
    height: 28,
    width: "100%",
    display: "flex",
    alignItems: "center",
    borderBottom: "1px solid",
    borderBottomColor: theme.palette.border.main,
  },
  header: {
    margin: "auto 2px",
    textTransform: "uppercase",
    color: theme.palette.text.popper.header,
  },
  formControl: {
    marginTop: 2,
    paddingTop: 6,
  },
  sectionWrapper: {
    margin: 5,
    marginBottom: 10,
  },
}));

const TaxStatusToggleButtonFilterGroup = (props) => {
  const { filterConfigs, filters, header, onFilterChange } = props;
  const classes = useStyles();

  const handleFilterChange = (filter) => {
    // if (filter.filterKey === 'generalMarket') {
    //    if (filter.filterValue === 'true') {
    //       onFilterChange({filterKey: 'taxable', filterValue: 'false', filterType: Constants.FILTER_TYPE.TOGGLE});
    //       onFilterChange({filterKey: 'bankQualified', filterValue: 'false', filterType: Constants.FILTER_TYPE.TOGGLE});
    //    }
    //    else if (filter.filterValue === 'false') {
    //       onFilterChange({filterKey: 'taxable', filterValue: 'true', filterType: Constants.FILTER_TYPE.TOGGLE});
    //       onFilterChange({filterKey: 'bankQualified', filterValue: 'true', filterType: Constants.FILTER_TYPE.TOGGLE});
    //    } else {
    //       onFilterChange({filterKey: 'taxable', filterValue: '', filterType: Constants.FILTER_TYPE.TOGGLE});
    //       onFilterChange({filterKey: 'bankQualified', filterValue: '', filterType: Constants.FILTER_TYPE.TOGGLE});
    //    }
    // }

    const filters = [filter];

    if (filter.filterKey === "generalMarket") {
      if (filter.filterValue === "true") {
        filters.push({
          filterKey: "taxable",
          filterValue: "false",
          filterType: Constants.FILTER_TYPE.TOGGLE,
        });
        filters.push({
          filterKey: "bankQualified",
          filterValue: "false",
          filterType: Constants.FILTER_TYPE.TOGGLE,
        });
      }
    } else if (filter.filterKey === "taxable") {
      if (filter.filterValue === "true") {
        filters.push({
          filterKey: "generalMarket",
          filterValue: "false",
          filterType: Constants.FILTER_TYPE.TOGGLE,
        });
        filters.push({
          filterKey: "bankQualified",
          filterValue: "false",
          filterType: Constants.FILTER_TYPE.TOGGLE,
        });
      }
    } else if (filter.filterKey === "bankQualified") {
      if (filter.filterValue === "true") {
        filters.push({
          filterKey: "generalMarket",
          filterValue: "false",
          filterType: Constants.FILTER_TYPE.TOGGLE,
        });
        filters.push({
          filterKey: "taxable",
          filterValue: "false",
          filterType: Constants.FILTER_TYPE.TOGGLE,
        });
      }
    }

    onFilterChange(filters);
  };

  return (
    <React.Fragment>
      {header && (
        <div className={classes.headerWrapper}>
          <span className={classes.header}>{header}</span>
        </div>
      )}
      <FormGroup classes={{ root: classes.formControl }}>
        {filterConfigs.map((config, i) => {
          return (
            <div key={config.accessor} className={classes.sectionWrapper}>
              <ToggleButtonFilter
                label={config.filterlabel || config.label}
                optionsKey={config.filter.optionsKey}
                filterKey={config.accessor}
                filter={filters[config.accessor]}
                onFilterChange={handleFilterChange}
              />
            </div>
          );
        })}
      </FormGroup>
    </React.Fragment>
  );
};

TaxStatusToggleButtonFilterGroup.propTypes = {
  filters: PropTypes.object.isRequired,
  filterConfigs: PropTypes.arrayOf(PropTypes.object).isRequired,
  header: PropTypes.string,
  onFilterChange: PropTypes.func.isRequired,
};

export default TaxStatusToggleButtonFilterGroup;
