import React from "react";
import PropTypes from "prop-types";

import * as Constants from "commons/constants";
import { useMessages } from "providers/BrandingProvider";
import * as utils from "commons/utils";
import DataTable from "components/datatable/DataTable";
import QBTypography from "components/QBTypography";
import { ClosableModal } from "components/containers/Modals";
import { useDataService } from "services/DataService";
import { StyledTooltip } from "components/Controls";

import IconButton from "@material-ui/core/IconButton";
import BookIcon from "@material-ui/icons/ImportContacts";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(3),
    outline: 0,
    backgroundColor: theme.palette.background.paper,
    maxWidth: "97vw",
  },
  header: {
    width: "100%",
    display: "flex",
    marginBottom: 20,
  },
  headerItem: {
    flexGrow: 1,
    textAlign: "center",
    "&:first-child": {
      flexGrow: 0,
      textAlign: "left",
    },
    "&:last-child": {
      flexGrow: 0,
      textAlign: "right",
    },
  },
  cusip: {
    color: theme.palette.primary.main,
  },
}));

const DepthOfBook = ({ onAddBondsToCart, onOpenTradeTicket, bond }) => {
  const classes = useStyles();
  const Messages = useMessages();

  const [open, setOpen] = React.useState(false);
  const [data, setData] = React.useState([]);

  const instrumentCategory = utils.hasNonEmptyValue(bond) ? bond.instrumentCategory : null;

  const [state, queryDataService] = useDataService();

  const handleAddBondsToCart = (bonds, currentBonds) => {
    setOpen(false);
    onAddBondsToCart(bonds, currentBonds);
  };

  const handleOpenTradeTicket = (bonds) => {
    setOpen(false);
    onOpenTradeTicket(bonds);
  };

  // initial query after component mount
  React.useEffect(() => {
    if (open && utils.hasNonEmptyValue(bond)) {
      const queryParams = {
        type: Constants.QUERY_TYPE.SIMILAR,
        sort: [{ id: "price", desc: false }],
        identifier: {
          filterKey: "brokeredsecurityid",
          filterValue: bond.brokeredsecurityid,
        },
        size: 500,
      };

      queryParams.clearDataOnFetch = true;
      queryDataService(queryParams);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  React.useEffect(() => {
    if (state.data) {
      setData(state.data.concat([bond]));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.data]);

  return (
    <>
      <StyledTooltip title={`${Messages.TOOLTIP.SEE_ALL_OFFERINGS_FOR} ${bond ? bond.cusip : ""}`}>
        <IconButton size="small" onClick={() => setOpen(true)}>
          <BookIcon color="primary" />
        </IconButton>
      </StyledTooltip>
      <ClosableModal
        open={open}
        onClose={(e) => setOpen(false)}
        header={
          <div>
            <span>{Messages.LABEL.ALL_OFFERINGS_FOR}</span>{" "}
            <span className={classes.cusip}>{bond ? bond.cusip : ""}</span>
          </div>
        }
      >
        <React.Fragment>
          <div className={classes.header}>
            <div className={classes.headerItem}>
              <QBTypography>{bond ? bond.issuer : ""}</QBTypography>
            </div>
            <div className={classes.headerItem}></div>
            <div className={classes.headerItem}>
              <QBTypography variant="overline" color="secondary">
                {bond ? Messages.LABEL[utils.getInventoryKeyFromType(bond.instrumentCategory)] : ""}
              </QBTypography>
            </div>
          </div>
          <div className="modalTable" style={{ maxHeight: "calc(97vh - 200px)" }}>
            <DataTable
              queryType={Constants.QUERY_TYPE.DEPTH_OF_BOOK}
              instrumentCategory={instrumentCategory}
              data={data}
              loading={state.isLoading}
              error={state.isError}
              filterable={false}
              selectedBond={bond}
              onAddBondsToCart={handleAddBondsToCart}
              onOpenTradeTicket={handleOpenTradeTicket}
            />
          </div>
        </React.Fragment>
      </ClosableModal>
    </>
  );
};

DepthOfBook.propTypes = {
  onAddBondsToCart: PropTypes.func.isRequired,
  onOpenTradeTicket: PropTypes.func.isRequired,
  bond: PropTypes.object,
};

export default DepthOfBook;
