import React from "react";
import { setGlobal } from "reactn";
import moment from "moment-timezone";
import { get, invert, replace, cloneDeep, isEqual, orderBy } from "lodash";

import * as filterUtils from "commons/filterUtils";
import * as DataConfig from "config/dataconfig";
import * as Constants from "commons/constants";
import { LABEL } from "commons/messages";
import HyperlinkButton from "components/HyperlinkButton";
import RequestInfo from "components/modals/RequestInfo";

export const issueErrorAlert = (msg) => {
  setGlobal({
    notificationMessage: { type: Constants.ALERT_LEVEL.ERROR, message: msg, timestamp: Date.now() },
  });
};

export const issueWarningAlert = (msg) => {
  setGlobal({
    notificationMessage: { type: Constants.ALERT_LEVEL.WARNING, message: msg, timestamp: Date.now() },
  });
};

export const issueAlert = (msg, action) => {
  setGlobal({
    notificationMessage: {
      type: Constants.ALERT_LEVEL.INFO,
      message: msg,
      action: action,
      timestamp: Date.now(),
    },
  });
};

export const issueAnnouncements = (msgs) => {
  setGlobal({
    notificationMessage: { type: Constants.ALERT_LEVEL.ANNOUNCEMENT, messages: msgs },
  });
};

export const removeEnclosingQuotes = (value) => {
  return value.replace(/^['"](.*)['"]$/, "$1");
};

export const removeEnclosingParentheses = (value) => {
  return value.replace(/^\((.*)\)$/, "$1");
};

export const convertUnicode = (value) => {
  return value.replace(/\\u(\w\w\w\w)/g, function (a, b) {
    var charcode = parseInt(b, 16);
    return String.fromCharCode(charcode);
  });
};

export const getFilterDisplayText = (value) => {
  if (!value || value.length === 0) {
    return LABEL.SHOW_ALL;
  }

  if (Array.isArray(value)) {
    let display = value[0];
    if (value.length > 1) {
      display = `${display} +${value.length - 1}`;
    }
    return display;
  }

  return value;
};

export const hasNonEmptyValue = (value) => {
  let empty;
  if (value === null || value === undefined) {
    empty = true;
  } else if (typeof value === "object") {
    empty = Object.keys(value).length === 0;
  } else if (Array.isArray(value)) {
    empty = value.length === 0;
  } else {
    empty = value === "";
  }
  return !empty;
};

export const groupByProperty = (recs, key) => {
  return recs.reduce((map, rec) => {
    let group = get(rec, key);
    if (hasNonEmptyValue(group)) {
      if (!map.hasOwnProperty(group)) {
        map[group] = [];
      }
      map[group].push(rec);
    }
    return map;
  }, {});
};

export const getValuesByProperty = (recs, key) => {
  return recs
    .map((rec) => {
      return get(rec, key);
    })
    .filter((value) => {
      return hasNonEmptyValue(value);
    });
};

export const getInventoryKeyFromType = (type) => {
  return invert(Constants.INVENTORY_TYPE)[type];
};

export const getInventoryKeyFromInstrumentType = (type) => {
  if (type === Constants.INSTRUMENT_TYPE.CD_PRIMARY || type === Constants.INSTRUMENT_TYPE.CD_SECONDARY) {
    return Constants.INVENTORY_TYPE.CD;
  } else {
    return invert(Constants.INSTRUMENT_TYPE)[type];
  }
};

export const isValidCusip = (cusip) => {
  return hasNonEmptyValue(cusip) && cusip.match(/^[a-zA-Z0-9]{9}$/);
};

export const addToArray = (array, value) => {
  if (array != null && !array.includes(value)) {
    array.push(value);
  }
};

export const removeFromArray = (array, value) => {
  array.forEach((item, i) => {
    if (item === value) {
      array.splice(i, 1);
    }
  });
};

export const cloneArrayOfObjects = (arr) => {
  return arr.map((item) => {
    return { ...item };
  });
};

export const findInArray = (array, key, value) => {
  return array.filter((obj) => obj[key] === value);
};

export const convertCurrencyToInteger = (value) => {
  if (hasNonEmptyValue(value)) {
    return;
  }
  return parseInt(replace(value.trim(), /,|$/g, ""));
};

export const hasContactTradeDesk = (value, key, record, enableContactDeskLinks) => {
  const config = get(DataConfig.PROPERTY, key);

  if (!config || !enableContactDeskLinks) {
    return { contactTradeDeskValueUsed: false, contactTradeDeskValue: value };
  }

  if (!hasNonEmptyValue(value) && config.hasOwnProperty("contactTradeDeskText")) {
    return { contactTradeDeskValueUsed: true, contactTradeDeskValue: config.contactTradeDeskText };
  }

  // If we have specified a particular value is considered "no data" like 0, then instead of displaying
  // that value, display the configured text
  if (config.hasOwnProperty("contactTradeDeskText") && config.hasOwnProperty("noDataValueEquivalent")) {
    if (value === config.noDataValueEquivalent) {
      return { contactTradeDeskValueUsed: true, contactTradeDeskValue: config.contactTradeDeskText };
    }
  }

  return { contactTradeDeskValueUsed: false, contactTradeDeskValue: value };
};

export const sortData = (data, sorts = []) => {
  return orderBy(
    data,
    sorts.map((sort) => {
      return (row) => {
        if (get(row, sort.id, "") === "") {
          return -Infinity;
        }
        return typeof get(row, sort.id) === "string" ? get(row, sort.id).toLowerCase() : get(row, sort.id);
      };
    }),
    sorts.map((d) => (d.desc ? "desc" : "asc")),
  );
};

export const pageData = (data, page, size) => {
  const startIndex = page * size;
  return data.slice(startIndex, startIndex + size);
};

export const formatValue = (value, key, record, enableContactDeskLinks) => {
  const config = get(DataConfig.PROPERTY, key);

  if (!config) {
    return value;
  }

  var contactTradeDeskReturn = hasContactTradeDesk(value, key, record, enableContactDeskLinks);

  if (contactTradeDeskReturn.contactTradeDeskValueUsed) {
    return contactTradeDeskReturn.contactTradeDeskValue;
  }

  if (!hasNonEmptyValue(value) && config.hasOwnProperty("noDataText")) {
    if (enableContactDeskLinks && config.noDataText === Constants.MESSAGE_KEYS.CONTACT_TRADE_DESK) {
      return <RequestInfo bond={record} variant="hyperlink" linkLabel={LABEL.CONTACT_HELP_DESK} />;
    }
    return config.noDataText;
  }

  // If we have specified a particular value is considered "no data" like 0, then instead of displaying
  // that value, display the configured text
  if (config.hasOwnProperty("noDataText") && config.hasOwnProperty("noDataValueEquivalent")) {
    if (value === config.noDataValueEquivalent) {
      if (enableContactDeskLinks && config.noDataText === Constants.MESSAGE_KEYS.CONTACT_TRADE_DESK) {
        return <RequestInfo bond={record} variant="hyperlink" linkLabel={LABEL.CONTACT_HELP_DESK} />;
      }
      return config.noDataText;
    }
  }

  let minDecimal = config.minDecimal;

  const scaleAccessor = config.formatAccessor || `${key}Scale`;
  if (record && hasNonEmptyValue(get(record, scaleAccessor))) {
    minDecimal = Number(get(record, scaleAccessor));
  }

  const format = config.format;

  switch (format) {
    case Constants.FORMAT_TYPE.HYPERLINK_BUTTON:
      value = formatHyperlinkButton(value, config.label, config.tooltip, config.authenticated);
      break;
    case Constants.FORMAT_TYPE.HYPERLINK:
      value = formatHyperlink(value, config.label);
      break;
    case Constants.FORMAT_TYPE.BOOLEAN:
      value = formatBoolean(value);
      break;
    case Constants.FORMAT_TYPE.CURRENCY:
      value = formatCurrency(value, minDecimal);
      break;
    case Constants.FORMAT_TYPE.DATE:
      value = formatDate(value);
      break;
    case Constants.FORMAT_TYPE.DATETIME:
      value = formatTimestamp(value);
      break;
    case Constants.FORMAT_TYPE.NUMBER:
      value = formatNumber(value, minDecimal, config.omitComma);
      break;
    default:
      break;
  }

  return value;
};

export const formatHyperlinkButton = (url, label, tooltip, authenticated) => {
  return <HyperlinkButton url={url} label={label} tooltip={tooltip} authenticated={authenticated} />;
};

export const formatHyperlink = (url, label, title, inApp) => {
  return inApp ? (
    <a href={url} className="externalLink" title={title || label}>
      {label}
    </a>
  ) : (
    <a href={url} className="externalLink" title={title || label} target="_blank" rel="noopener noreferrer">
      {label}
    </a>
  );
};

export const formatEmail = (address, name) => {
  return (
    <a href={`mailto:${address}`} className="externalLink">
      {name || address}
    </a>
  );
};

export const formatBoolean = (value) => {
  if (!hasNonEmptyValue(value)) {
    return LABEL.NO;
  } else {
    return value.toString() === "true" ? LABEL.YES : LABEL.NO;
  }
};

export const formatCurrency = (value, minimumFractionDigits) => {
  if (!hasNonEmptyValue(value) || isNaN(Number(value))) {
    return value;
  }

  const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    minimumFractionDigits: minimumFractionDigits || 0,
  });

  return formatter.format(value);
};

export const formatNumber = (value, minimumFractionDigits, omitComma) => {
  if (!hasNonEmptyValue(value) || !hasNonEmptyValue(value.toString().trim()) || isNaN(Number(value))) {
    return value;
  }

  // blunt rounding
  let val = Number(value).toFixed(minimumFractionDigits || 0);

  if (omitComma) {
    return val;
  }

  // comma-tizer the value
  const formatter = new Intl.NumberFormat("en-US", {
    style: "decimal",
    currency: "USD",
    minimumFractionDigits: minimumFractionDigits || 0,
  });

  return formatter.format(val);
};

export const formatRomanNumeral = (value) => {
  value = Number(value);

  if (isNaN(value)) {
    return value;
  }

  switch (value) {
    case 1:
      return "I";
    case 2:
      return "II";
    case 3:
      return "III";
    case 4:
      return "IV";
    case 5:
      return "V";
    case 6:
      return "VI";
    case 7:
      return "VII";
    case 8:
      return "VIII";
    case 9:
      return "IX";
    case 10:
      return "X";
    default:
      return value.toString();
  }
};

export const formatDate = (value) => {
  if (!hasNonEmptyValue(value)) {
    return "";
  }

  let date = moment(value, DataConfig.DATE_FORMAT.API);
  if (!date.isValid()) {
    return value;
  }
  return moment(date).format(DataConfig.DATE_FORMAT.DISPLAY);
};

export const formatTimestamp = (value) => {
  return moment(value).format(DataConfig.DATE_FORMAT.DATETIME);
};

export const getCurrentDateTime = (isEST) => {
  if (isEST) {
    const now = moment.utc();
    return now.tz("America/New_York").format(DataConfig.DATE_FORMAT.DATETIME);
  } else {
    return moment().format(DataConfig.DATE_FORMAT.DATETIME);
  }
};

export const parseDate = (value) => {
  return moment.utc(value, DataConfig.DATE_FORMAT.API, true);
};

export const parseTimestamp = (value) => {
  return moment.utc(value, DataConfig.DATE_FORMAT.API_TIMESTAMP, true);
};

export const parseMonthYear = (value) => {
  return moment.utc(value, DataConfig.DATE_FORMAT.MONTH_DAY, true);
};

export const sortDates = (a, b, desc) => {
  a = moment(a);
  b = moment(b);

  if (!a.isValid()) {
    return -1;
  }

  if (!b.isValid()) {
    return -1;
  }

  if (a.isAfter(b)) {
    return 1;
  }
  if (a.isBefore(b)) {
    return -1;
  }

  return 0;
};

export const findCurrentViewState = (state) => {
  const activeView = state.activeView;

  if (state.hasOwnProperty(activeView)) {
    if (state[activeView].hasOwnProperty("activeSubView")) {
      const activeSubView = state[activeView].activeSubView;
      if (state[activeView].hasOwnProperty(activeSubView)) {
        if (state[activeView][activeSubView].hasOwnProperty("activeQuerySubType")) {
          const activeQuerySubType = state[activeView][activeSubView].activeQuerySubType;
          if (!state[activeView][activeSubView].hasOwnProperty(activeQuerySubType)) {
            state[activeView][activeSubView][activeQuerySubType] = {};
          }
          return state[activeView][activeSubView][activeQuerySubType];
        } else {
          return state[activeView][activeSubView];
        }
      } else {
        state[activeView][activeSubView] = {};
        return state[activeView][activeSubView];
      }
    } else {
      return state[activeView];
    }
  } else if (hasNonEmptyValue(activeView)) {
    state[activeView] = {};
    return state[activeView];
  } else {
    return {};
  }
};

/**
 *
 * Comparable Bonds related utils
 *
 */
export const getCompBondQuantityFilterKey = (bond) => {
  return bond?.instrumentCategory === Constants.INVENTORY_TYPE.MORTGAGE ? "currentFace" : "quantity";
};

export const compBondQtyFilterDefaultValue = (instrumentCategory) => {
  return instrumentCategory === Constants.INVENTORY_TYPE.MORTGAGE ? 100000 : 100;
};

export const findCompBondQuantityFilter = (viewState, bond) => {
  const filterKey = getCompBondQuantityFilterKey(bond);
  const queryFilterPath = `query.filter.${filterKey}`;

  let activeView = {};
  const inventoryView = get(viewState, Constants.VIEW.INVENTORY, {});
  const buyView = get(inventoryView, Constants.SUBVIEW.BUY, {});
  if (hasNonEmptyValue(bond)) {
    activeView = get(buyView, bond.instrumentCategory, {});
  }

  const defaultFilterValue = compBondQtyFilterDefaultValue(bond?.instrumentCategory);

  const defaultFilter = {
    filterKey: filterKey,
    filterValue: [defaultFilterValue],
    filterType: Constants.FILTER_TYPE.RANGE,
  };

  // get from activeView
  const activeViewFilter = get(activeView, queryFilterPath, defaultFilter);

  // filter can be overridden at the cusip level
  const compBondView = get(viewState, Constants.VIEW.COMPARABLE, {});
  const currentViewState = hasNonEmptyValue(bond)
    ? get(compBondView, `${bond.instrumentCategory}.${bond.cusip}`, {})
    : {};
  const filter = get(currentViewState, queryFilterPath, activeViewFilter);

  return filter;
};

export const findCompBondExcludeOwnedCdsFilter = (viewState, bond) => {
  let activeView = {};
  const inventoryView = get(viewState, Constants.VIEW.INVENTORY, {});
  const buyView = get(inventoryView, Constants.SUBVIEW.BUY, {});
  if (hasNonEmptyValue(bond) && bond.instrumentCategory === Constants.INVENTORY_TYPE.CD) {
    activeView = get(buyView, Constants.INVENTORY_TYPE.CD, {});
  }

  const defaultFilter = {
    filterKey: "excludeOwnedCds",
    filterValue: false,
    filterType: Constants.FILTER_TYPE.BOOLEAN_TOGGLE,
  };

  // get from activeView
  return get(activeView, "query.filter.excludeOwnedCds", defaultFilter);
};

export const findActiveCompBondQuery = (viewState, bond) => {
  const compBondView = get(viewState, Constants.VIEW.COMPARABLE, {});

  let criteria;

  if (hasNonEmptyValue(bond)) {
    criteria = get(compBondView, `${bond.instrumentCategory}.${bond.cusip}.query`);
    if (!hasNonEmptyValue(criteria)) {
      criteria = get(compBondView, `${bond.instrumentCategory}.query`);
    }
  }

  return criteria;
};

export const createCompBondQueryForSave = (query) => {
  const updatedQuery = cloneDeep(query);

  delete updatedQuery.searchId;
  delete updatedQuery.filter.quantity;
  delete updatedQuery.filter.currentFace;

  let updatedFilter = {};
  for (var filterKey in updatedQuery.filter) {
    if (
      filterKey === "instrumentCategory" ||
      (!updatedQuery.ignore.includes(filterKey) &&
        !filterUtils.hasEmptyFilterValue(updatedQuery.filter[filterKey].filterValue))
    ) {
      updatedFilter[filterKey] = updatedQuery.filter[filterKey];
    }
  }
  updatedQuery.filter = updatedFilter;

  return updatedQuery;
};

export const isDirtyCompBondSavedQuery = (query, savedSearches) => {
  let isDirty = false;

  if (query?.id) {
    const savedSearch = savedSearches.find((s) => s.id === query.id);
    if (savedSearch && savedSearch.filterConfig) {
      const savedQuery = JSON.parse(savedSearch.filterConfig);
      const updatedQueryForCompare = createCompBondQueryForSave(query);
      isDirty = !isEqual(savedQuery.filter, updatedQueryForCompare.filter);
    }
  }

  return isDirty;
};

export const hasCompanyColumn = (data) => {
  if (!data || data.length === 0) {
    return false;
  }
  return new Set(data.map((item) => item.companyName)).size > 1;
};

export const hasEditableOrder = (data) => {
  if (!data) {
    return false;
  }
  return data.some((datum) => {
    return isEditableOrder(datum);
  });
};

export const isEditableOrder = (datum) => {
  if (!hasNonEmptyValue(datum.links)) {
    return false;
  } else {
    return datum.links.some((l) => l.rel === "edit" || l.title?.toLowerCase() === "cancel");
  }
};

// Returns true if we require a specific user permission and the user has the permission
// false otherwise
export const userHasAnyPermission = (user, permissionsRequired) => {
  // Special case
  if (permissionsRequired === null || permissionsRequired === undefined) {
    return true;
  }
  let hasPermission = false;

  permissionsRequired.forEach((perm) => {
    if (user.hasOwnProperty(perm) && user[perm]) {
      hasPermission = true;
    }
  });
  return hasPermission;
};

export const featureFlagEnabled = (flagName) => {
  return process.env[flagName] === "true" || process.env[flagName] === "TRUE";
};

export const obfuscateCusips = (bonds) => {
  return bonds.map((b) => {
    b.cusip = `XXXXX${b.cusip.substring(5)}`;
    return b;
  });
};

export const isFreshnessSensitiveView = (view, subView) =>
  (view === Constants.VIEW.INVENTORY && subView === Constants.SUBVIEW.BUY) ||
  view === Constants.VIEW.FAVORITES ||
  view === Constants.VIEW.ISSUER_TRACKER;

export const getInstrumentCategoryId = (instrumentCategory) => {
  let id;
  switch (instrumentCategory) {
    case Constants.INVENTORY_TYPE.CD:
      id = 128;
      break;
    case Constants.INVENTORY_TYPE.AGENCY:
      id = 130;
      break;
    case Constants.INVENTORY_TYPE.TREASURY:
      id = 131;
      break;
    case Constants.INVENTORY_TYPE.MUNICIPAL:
      id = 132;
      break;
    case Constants.INVENTORY_TYPE.MORTGAGE:
      id = 133;
      break;
    default:
      id = 0;
  }

  return id;
};
