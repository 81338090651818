import { get } from "lodash";

import * as utils from "commons/utils";
import * as DataConfig from "config/dataconfig";
import HttpService from "services/commons/HttpService";

export const fetchMarketHours = async () => {
  const marketHours = await HttpService.get(`reference/${DataConfig.REFERENCE_ENDPOINT.markethours.url}`)
    .then((response) => {
      const marketHours = response.data;
      return get(marketHours, ["instrumentType", 128]);
    })
    .catch((e) => {
      utils.issueErrorAlert("Unable to determine current market hours");
      return;
    });

  return marketHours;
};
