/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/require-default-props */
import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";

import * as Constants from "commons/constants";
import * as utils from "commons/utils";
import QBTypography from "components/QBTypography";

import Button from "@material-ui/core/Button";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import ErrorIcon from "@material-ui/icons/Error";
import InfoIcon from "@material-ui/icons/Info";
import CloseIcon from "@material-ui/icons/Close";
import IconButton from "@material-ui/core/IconButton";
import SnackbarContent from "@material-ui/core/SnackbarContent";
import WarningIcon from "@material-ui/icons/Warning";
import { makeStyles, withStyles } from "@material-ui/core/styles";

const StyledInfoIcon = withStyles((theme) => ({
  root: {
    color: theme.palette.secondary.main,
  },
}))(InfoIcon);

const variantIcon = {
  success: CheckCircleIcon,
  warning: WarningIcon,
  error: ErrorIcon,
  info: StyledInfoIcon,
};

const useStyles = makeStyles((theme) => ({
  root: {
    padding: "3px 16px 0 16px",
    backgroundColor: "#eaeaea",
    width: "100%",
  },
  action: {
    padding: 0,
  },
  actionButton: {
    padding: 0,
    marginRight: 8,
    fontSize: "0.8rem",
    "&:hover": {
      backgroundColor: "transparent",
      textDecoration: "underline",
    },
  },
  messageLink: {},
  footerLink: {
    color: "#888",
  },
  error: {
    backgroundColor: theme.palette.background.snackbar,
  },
  info: {
    backgroundColor: theme.palette.background.snackbar,
  },
  warning: {
    backgroundColor: theme.palette.background.snackbar,
  },
  iconWrapper: {
    flexGrow: 0,
    color: theme.palette.text.warning,
    "&:first-child": {
      marginRight: 6,
    },
    "&:last-child": {
      marginLeft: 6,
    },
  },
  messageWrapper: {
    flexGrow: 1,
  },
  iconButton: {
    padding: 0,
    marginTop: -4,
  },
  icon: {
    fontSize: "1.2rem",
  },
  content: {
    display: "flex",
    flexDirection: "column",
    padding: 0,
    fontSize: "0.875rem",
    width: 250,
    minHeight: 50,
    justifyContent: "center",
  },
  body: {
    display: "flex",
    flexDirection: "row",
    padding: 0,
    fontSize: "0.875rem",
    color: theme.palette.text.snackbar,
  },
  message: {
    whiteSpace: "normal",
  },
}));

// COMPONENT
const NotificationContentWrapper = React.forwardRef((props, ref) => {
  const classes = useStyles();
  const { message, action, onClose, level, ...other } = props;
  const Icon = variantIcon[level];
  const color =
    level === Constants.ALERT_LEVEL.INFO
      ? "secondary"
      : level === Constants.ALERT_LEVEL.WARNING
      ? "inherit"
      : "error";

  const handleActionLink = (e, state) => {
    onClose();
  };

  return (
    <SnackbarContent
      ref={ref}
      classes={{ root: clsx(classes[level], classes.root), action: classes.action }}
      message={
        <div className={classes.content}>
          <div className={classes.body}>
            {Icon && (
              <div className={classes.iconWrapper}>
                <Icon color={color} className={classes.icon} />
              </div>
            )}
            <div className={classes.messageWrapper}>
              <QBTypography color="inherit" className={classes.message}>
                {message}
              </QBTypography>
            </div>
            <div className={classes.iconWrapper}>
              <IconButton className={classes.iconButton} color="primary" onClick={onClose}>
                <CloseIcon className={classes.icon} />
              </IconButton>
            </div>
          </div>
          <div>
            {utils.hasNonEmptyValue(action) && (
              <Button
                disableRipple
                disableFocusRipple
                className={clsx(classes.actionButton, classes.messageLink)}
                color="secondary"
                onClick={(e) => handleActionLink(e, action)}
              >
                {action.label}
              </Button>
            )}
            {
              // level !== Constants.ALERT_LEVEL.ERROR && level !== Constants.ALERT_LEVEL.WARNING &&
              // <Button
              //    disableRipple
              //    disableFocusRipple
              //    className={clsx(classes.actionButton, classes.footerLink)}
              //    color='inherit' onClick={e => handleActionLink(e, {disableNotifications: true})}>
              //       {Messages.LABEL.DISABLE_NOTIFICATIONS}
              // </Button>
            }
          </div>
        </div>
      }
      {...other}
    />
  );
});

NotificationContentWrapper.propTypes = {
  message: PropTypes.string.isRequired,
  action: PropTypes.shape({
    label: PropTypes.string,
    state: PropTypes.object,
  }),
  onClose: PropTypes.func,
  level: PropTypes.oneOf([
    Constants.ALERT_LEVEL.ERROR,
    Constants.ALERT_LEVEL.WARNING,
    Constants.ALERT_LEVEL.INFO,
  ]).isRequired,
};

export default NotificationContentWrapper;
