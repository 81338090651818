import React from "react";
import { get, startCase, toLower, cloneDeep, isEqual } from "lodash";
import PropTypes from "prop-types";

import { useViewState, useViewStateDispatcher } from "providers/ViewStateProvider";
import * as Constants from "commons/constants";
import { useMessages } from "providers/BrandingProvider";
import * as DataConfig from "config/dataconfig";
import * as utils from "commons/utils";
import OrderStatus from "components/OrderStatus";
import ComparableBonds from "components/ComparableBonds";
import RateBoard from "components/RateBoard";
import RateFreshnessTime from "components/RateFreshnessTime";
import QBTypography from "components/QBTypography";
import NavigationToolbar from "components/toolbars/NavigationToolbar";
import RangeFilter from "components/filters/RangeFilter";
import { TabbedCard, TabbedCardContent } from "components/containers/Containers";
import { Div } from "components/containers/Containers";
import { StyledButtonGroup } from "components/Controls";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  cusip: {
    color: theme.palette.primary.main,
  },
}));

const Tools = ({ onAddBondsToCart, onOpenTradeTicket }) => {
  const classes = useStyles();
  const Messages = useMessages();

  const viewState = useViewState();
  const dispatchViewStateChange = useViewStateDispatcher();
  const selectedTool = get(viewState, "activeTool", Constants.TOOL_TYPE.RATES);

  const comparableView = get(viewState, Constants.VIEW.COMPARABLE, {});
  const compareBond = comparableView.compareBond || {};

  const filter = utils.findCompBondQuantityFilter(viewState, compareBond);
  const filterKey = filter.filterKey;
  const filterLabel = startCase(
    toLower(`${Messages.LABEL.MIN} ${get(DataConfig.PROPERTY, filterKey).label}`),
  );

  const [bond, setBond] = React.useState();
  const [ratesRefreshedTime, setRatesRefreshedTime] = React.useState();

  React.useEffect(() => {
    if (
      utils.hasNonEmptyValue(compareBond) &&
      utils.hasNonEmptyValue(compareBond.cusip) &&
      !isEqual(bond, compareBond)
    ) {
      setBond(compareBond);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [compareBond]);

  const handleToolbarChange = (tool) => {
    dispatchViewStateChange({ state: { activeTool: tool } });
  };

  const handleFilterChange = (filter) => {
    if (utils.hasNonEmptyValue(filter)) {
      if (utils.hasNonEmptyValue(bond)) {
        let queryState = utils.findActiveCompBondQuery(viewState, bond) || { filter: {} };
        queryState = cloneDeep(queryState);
        queryState.filter[filterKey] = filter;

        dispatchViewStateChange({
          state: { query: queryState },
          view: Constants.VIEW.COMPARABLE,
          subView: bond.instrumentCategory,
          querySubType: bond.cusip,
        });
      }
    }
  };

  const handleBoardTypeChange = (option) => {
    dispatchViewStateChange({ state: { boardType: option }, view: Constants.VIEW.RATES });
  };

  const headerItems = [];

  if (selectedTool === Constants.TOOL_TYPE.COMPARABLE) {
    if (utils.hasNonEmptyValue(bond)) {
      headerItems.push(
        <>
          <QBTypography key="cb_header" variant="h3">
            {bond.instrumentCategory === Constants.INVENTORY_TYPE.CD
              ? Messages.LABEL.COMPARABLE_TO_CDS
              : Messages.LABEL.COMPARABLE_TO}
            <span className={classes.cusip}> {bond.cusip}</span>
          </QBTypography>
          <div style={{ margin: "0 20px" }} key="cb_filter">
            <RangeFilter
              label={filterLabel}
              filterKey={filterKey}
              filter={filter}
              onFilterChange={handleFilterChange}
              variant="standalone"
              step={10}
              min={0}
            />
          </div>
        </>,
      );
    } else {
      headerItems.push(
        <QBTypography key="cb_header" variant="h3">
          {Messages.LABEL.COMPARABLE} <span></span>
        </QBTypography>,
      );
    }
  } else if (selectedTool === Constants.TOOL_TYPE.STATUS) {
    headerItems.push(
      <QBTypography key="status_header" variant="h3">
        {Messages.LABEL.STATUS}
      </QBTypography>,
    );
  } else if (selectedTool === Constants.TOOL_TYPE.RATES || selectedTool === Constants.TOOL_TYPE.YIELD_CURVE) {
    const currentViewState = get(viewState, Constants.VIEW.RATES, {});
    const boardType = currentViewState.boardType || Constants.RATES_TYPE.HIGHEST;

    const headerComp = (
      <React.Fragment>
        <QBTypography key="status_header" variant="h3">
          {boardType === Constants.RATES_TYPE.HIGHEST
            ? Messages.LABEL.CURRENT_YIELD_HIGHEST
            : Messages.LABEL.CURRENT_YIELD_MEDIAN}
        </QBTypography>
        <StyledButtonGroup style={{ marginLeft: 20 }}>
          <Button
            disabled={boardType === Constants.RATES_TYPE.HIGHEST}
            color="primary"
            variant="outlined"
            onClick={(e) => handleBoardTypeChange(Constants.RATES_TYPE.HIGHEST)}
          >
            {Messages.LABEL.HIGHEST}
          </Button>
          <Button
            disabled={boardType === Constants.RATES_TYPE.MEDIAN}
            color="primary"
            variant="outlined"
            onClick={(e) => handleBoardTypeChange(Constants.RATES_TYPE.MEDIAN)}
          >
            {Messages.LABEL.MEDIAN}
          </Button>
        </StyledButtonGroup>
      </React.Fragment>
    );

    headerItems.push(headerComp);
    headerItems.push(
      <RateFreshnessTime
        label={Messages.LABEL.RATES_CURRENT}
        refreshedTime={ratesRefreshedTime}
        key="tools_clock"
      />,
    );
  }

  headerItems.push(
    <NavigationToolbar
      key="tools_nav"
      optionsKey={
        bond && bond.instrumentCategory === Constants.INVENTORY_TYPE.CD
          ? Constants.VIEW.TOOLS_CD
          : Constants.VIEW.TOOLS
      }
      selectedOption={selectedTool}
      onOptionSelected={handleToolbarChange}
    />,
  );

  return (
    <Div>
      <TabbedCard headerItems={headerItems}>
        <TabbedCardContent>
          {selectedTool === Constants.TOOL_TYPE.COMPARABLE && (
            <ComparableBonds
              filter={filter}
              onAddBondsToCart={onAddBondsToCart}
              onOpenTradeTicket={onOpenTradeTicket}
            />
          )}
          {selectedTool === Constants.TOOL_TYPE.STATUS && <OrderStatus />}
          {(selectedTool === Constants.TOOL_TYPE.RATES ||
            selectedTool === Constants.TOOL_TYPE.YIELD_CURVE) && (
            <RateBoard setRatesRefreshedTime={setRatesRefreshedTime} tool={selectedTool} />
          )}
        </TabbedCardContent>
      </TabbedCard>
    </Div>
  );
};

Tools.propTypes = {
  onAddBondsToCart: PropTypes.func.isRequired,
  onOpenTradeTicket: PropTypes.func.isRequired,
};

export default Tools;
