import React from "react";
import clsx from "clsx";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { pick } from "lodash";

import * as DataConfig from "config/dataconfig";
import HttpService from "services/commons/HttpService";

import QBTypography from "components/QBTypography";
import { makeStyles } from "@material-ui/core/styles";
import RemoveCircleOutlineIcon from "@material-ui/icons/RemoveCircleOutline";
import DragIndicatorIcon from "@material-ui/icons/DragIndicator";
import { IconButton } from "@material-ui/core";
import { Backdrop, CircularProgress } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  newsItemList: {
    backgroundColor: theme.palette.background.popperPaperLight,
  },
  newsItemWrapper: {
    display: "flex",
    alignItems: "center",
    padding: 10,
    backgroundColor: theme.palette.background.paperForm,
    borderBottom: `1px solid ${theme.palette.border.subtle}`,
    "&:first-child": {
      borderTop: `1px solid ${theme.palette.border.subtle}`,
    },
  },
  newsItemWrapperDragging: {
    borderTop: `1px solid ${theme.palette.border.subtle}`,
  },
  selected: {
    borderLeft: `3px solid ${theme.palette.primary.main}`,
  },
  dragIndicator: {
    color: theme.palette.background.selected,
  },
  backdrop: {
    backgroundColor: theme.palette.background.disabled,
  },
}));

const updateNewsItem = async (item) => {
  const message = pick(item, ["header", "itemText", "links", "order"]);

  const params = {
    url: `${DataConfig.NEWS_ENDPOINT}/${item.id}`,
    method: "put",
    data: {
      message: JSON.stringify(message),
    },
  };

  return await HttpService(params);
};

const NewsItemList = ({ newsItems, setMessages, onDelete, onEdit, itemToEdit }) => {
  const classes = useStyles();
  const [working, setWorking] = React.useState(false);

  const handleDragEnd = (result) => {
    const { source, destination } = result;

    if (!destination) {
      return;
    }

    const items = [...newsItems];
    const startIndex = source.index;
    const endIndex = destination.index;
    const [removed] = items.splice(startIndex, 1);

    const promises = [];

    items.splice(endIndex, 0, removed);
    items.forEach((item, idx) => {
      if (item.order !== idx) {
        item.order = idx;
        promises.push(updateNewsItem(item));
      }
    });

    setWorking(true);

    Promise.all(promises).then(() => {
      setWorking(false);
    });

    setMessages(items);
  };

  return (
    <>
      <div
        className={classes.newsItemList}
        style={{
          display: "flex",
          flexFlow: "column nowrap",
          overflowY: "auto",
          overflowX: "hidden",
          maxHeight: "calc(100vh - 180px)",
        }}
      >
        <DragDropContext onDragEnd={handleDragEnd}>
          <Droppable droppableId="droppable">
            {(provided) => (
              <div ref={provided.innerRef}>
                {newsItems?.map((msg, i) => {
                  return (
                    <Draggable key={msg.id} draggableId={`draggable-${msg.id}`} index={i}>
                      {(provided) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          className={clsx(classes.newsItemWrapper, {
                            [classes.selected]: itemToEdit?.id === msg.id,
                            [classes.newsItemWrapperDragging]: provided.isDragging,
                          })}
                        >
                          <div
                            style={{
                              cursor: "pointer",
                              maxWidth: "calc(100% - 50px)",
                              minWidth: "calc(100% - 50px)",
                              flexGrow: 1,
                              marginRight: 10,
                            }}
                            onClick={() => onEdit(msg)}
                          >
                            <div style={{ fontSize: "0.85rem", marginBottom: "0.25rem" }}>
                              {msg.header || `[NO HEADLINE]`}
                            </div>
                            <QBTypography
                              component="div"
                              variant="caption"
                              style={{
                                whiteSpace: "nowrap",
                                textOverflow: "ellipsis",
                                overflow: "hidden",
                              }}
                            >
                              {msg.itemText || `[NO TEXT]`}
                            </QBTypography>
                          </div>
                          <IconButton
                            size="small"
                            onClick={() => onDelete(msg)}
                            disableRipple
                            disableFocusRipple
                          >
                            <RemoveCircleOutlineIcon color="error" fontSize="small" />
                          </IconButton>
                          <DragIndicatorIcon className={classes.dragIndicator} />
                        </div>
                      )}
                    </Draggable>
                  );
                })}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </div>
      <Backdrop className={classes.backdrop} style={{ position: "absolute", zIndex: 200 }} open={working}>
        <CircularProgress color="secondary" />
      </Backdrop>
    </>
  );
};

export default NewsItemList;
