import React from "react";
import { CSSTransition } from "react-transition-group";
import PropTypes from "prop-types";

import * as utils from "commons/utils";
import * as Constants from "commons/constants";
import { useMessages } from "providers/BrandingProvider";
import * as DataConfig from "config/dataconfig";
import HttpService from "services/commons/HttpService";
import { TextAreaInput } from "components/Controls";
import { Div } from "components/containers/Containers";
import ModalMessages from "./common/ModalMessages";
import { TicketBody, TicketRow, TicketButton, Spacer } from "./tickets/TicketComponents";
import { StyledTooltip, StyledSecondaryButtonGroup } from "components/Controls";
import QBTypography from "components/QBTypography";
import { ClosableModal } from "components/containers/Modals";

import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import ContactSupportIcon from "@material-ui/icons/ContactSupport";

import { makeStyles } from "@material-ui/core/styles";
import { LinkButton } from "components/Controls";

const useStyles = makeStyles((theme) => ({
  root: {
    maxHeight: 350,
    width: 300,
    maxWidth: 300,
    marginTop: 5,
  },
  cusip: {
    color: theme.palette.primary.main,
  },
}));

const STATUS = {
  FORM: "form",
  COMPLETE: "complete",
};

export const METHOD = {
  EMAIL: "EMAIL",
  PHONE: "PHONE",
};

const RequestInfo = ({ bond, variant = "icon", linkLabel }) => {
  const classes = useStyles();
  const Messages = useMessages();

  const [open, setOpen] = React.useState(false);
  const [userMessage, setUserMessage] = React.useState("");
  const [preferredMethod, setPreferredMethod] = React.useState(METHOD.EMAIL);
  const [modalStatus, setModalStatus] = React.useState(STATUS.FORM);
  const [errorMessages, setErrorMessages] = React.useState([]);
  const [infoMessages, setInfoMessages] = React.useState([]);

  React.useEffect(() => {
    if (open) {
      setErrorMessages([]);
      setInfoMessages([]);
      setModalStatus(STATUS.FORM);
      setUserMessage("");
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  const handleCloseModal = (e) => {
    setOpen(false);
  };

  const handleSubmit = (e) => {
    setErrorMessages([]);
    setInfoMessages([Messages.LABEL.SENDING]);

    const params = {
      url: DataConfig.MESSAGE_DESK_ENDPOINT,
      method: "post",
      data: {
        userMessage: userMessage,
        brokeredSecurityID: bond && bond.brokeredSecurityID > 0 ? bond.brokeredSecurityID : undefined,
        cusip: bond?.cusip,
        preferredMethodOfContact: preferredMethod,
      },
    };

    HttpService(params)
      .then((response) => {
        setInfoMessages([]);
        setModalStatus(STATUS.COMPLETE);
      })
      .catch((e) => {
        setErrorMessages([Messages.MESSAGE.MESSAGE_SEND_ERROR]);
        setInfoMessages([]);
      });
  };

  const header = utils.hasNonEmptyValue(bond) ? (
    <span>
      {Messages.LABEL.REQUEST_INFO_CUSIP} <span className={classes.cusip}>{bond.cusip}</span>
    </span>
  ) : (
    Messages.LABEL.REQUEST_INFO
  );

  const tooltip = utils.hasNonEmptyValue(bond)
    ? Messages.TOOLTIP.REQUEST_INFO_CUSIP
    : Messages.TOOLTIP.REQUEST_INFO;

  return (
    <>
      {variant === "icon" && (
        <IconButton size="small" onClick={(e) => setOpen(true)}>
          <StyledTooltip title={tooltip}>
            <ContactSupportIcon fontSize="small" color="primary" />
          </StyledTooltip>
        </IconButton>
      )}
      {variant === "hyperlink" && (
        <LinkButton
          style={{ padding: 0 }}
          onClick={(e) => setOpen(true)}
          label={linkLabel ?? Messages.LABEL.CONTACT_HELP_DESK}
        />
      )}

      <ClosableModal open={open} header={header} onClose={handleCloseModal}>
        <div className={classes.root}>
          <TicketBody>
            <ModalMessages messages={errorMessages} level={Constants.ALERT_LEVEL.ERROR} />
            <ModalMessages messages={infoMessages} level={Constants.ALERT_LEVEL.INFO} />
            <CSSTransition
              mountOnEnter
              unmountOnExit
              timeout={{ enter: 500 }}
              classNames="transition-fade"
              in={modalStatus === STATUS.FORM}
            >
              <Div>
                <TicketRow>
                  <TextAreaInput
                    rows={12}
                    value={userMessage}
                    onChange={(event) => {
                      setUserMessage(event.currentTarget.value);
                    }}
                    placeholder={Messages.LABEL.REQUEST_INFO_PLACEHOLDER}
                  />
                </TicketRow>
                <Spacer />
                <TicketRow>
                  <div style={{ flexGrow: 1, marginRight: 10 }}>
                    <QBTypography color="textSecondary" style={{ lineHeight: 1.8 }}>
                      {Messages.LABEL.PREFERRED_METHOD}:
                    </QBTypography>
                  </div>
                  <StyledSecondaryButtonGroup>
                    <Button
                      disabled={preferredMethod === METHOD.EMAIL}
                      color="secondary"
                      variant="outlined"
                      onClick={(e) => setPreferredMethod(METHOD.EMAIL)}
                    >
                      {Messages.LABEL.EMAIL}
                    </Button>
                    <Button
                      disabled={preferredMethod === METHOD.PHONE}
                      color="secondary"
                      variant="outlined"
                      onClick={(e) => setPreferredMethod(METHOD.PHONE)}
                    >
                      {Messages.LABEL.PHONE}
                    </Button>
                  </StyledSecondaryButtonGroup>
                </TicketRow>
                <TicketRow style={{ marginTop: 40, justifyContent: "center" }}>
                  <TicketButton onClick={handleCloseModal} label={Messages.LABEL.CANCEL} />
                  <TicketButton
                    disabled={!utils.hasNonEmptyValue(userMessage)}
                    onClick={handleSubmit}
                    label={Messages.LABEL.SUBMIT}
                  />
                </TicketRow>
              </Div>
            </CSSTransition>
            <CSSTransition
              mountOnEnter
              unmountOnExit
              timeout={{ enter: 500, exit: 200 }}
              classNames="transition-fade"
              in={modalStatus === STATUS.COMPLETE}
            >
              <Div>
                <TicketRow style={{ marginTop: 20, justifyContent: "center" }}>
                  <QBTypography color="textSecondary">{Messages.MESSAGE.REQUEST_INFO_SUCCESS}</QBTypography>
                </TicketRow>
                <Spacer />
                <TicketRow style={{ marginTop: 40, justifyContent: "center" }}>
                  <TicketButton onClick={handleCloseModal} label={Messages.LABEL.CLOSE} />
                </TicketRow>
              </Div>
            </CSSTransition>
          </TicketBody>
        </div>
      </ClosableModal>
    </>
  );
};

RequestInfo.propTypes = {
  bond: PropTypes.object,
  variant: PropTypes.oneOf(["icon", "hyperlink"]),
  linkLabel: PropTypes.string,
};

export default RequestInfo;
