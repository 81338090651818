import React from "react";
import { get } from "lodash";

import { useViewState, useViewStateDispatcher } from "providers/ViewStateProvider";
import * as utils from "commons/utils";
import * as Constants from "commons/constants";
import { useMessages } from "providers/BrandingProvider";
import KeywordFilter from "components/filters/KeywordFilter";
import { useDataService } from "services/DataService";

const CusipSearch = () => {
  const viewState = useViewState();
  const Messages = useMessages();
  const dispatchViewStateChange = useViewStateDispatcher();
  const [state, queryDataService] = useDataService();

  const handleFilterChange = (filter) => {
    let cusip = filter.filterValue.replace(/\s/g, "").toUpperCase();
    if (utils.isValidCusip(cusip)) {
      const queryParams = {
        type: Constants.QUERY_TYPE.CUSIP_SEARCH,
        identifier: {
          filterKey: "cusip",
          filterValue: [cusip],
        },
        clearDataOnFetch: false,
      };

      queryDataService(queryParams);
    } else {
      executeInventorySearch(cusip);
    }
  };

  const executeInventorySearch = (cusip) => {
    const activeInstrumentCategory = get(
      viewState,
      `${Constants.VIEW.INVENTORY}.${Constants.SUBVIEW.BUY}.activeQuerySubType`,
    );
    dispatchViewStateChange({
      state: {
        activeView: Constants.VIEW.INVENTORY,
        activeSubView: Constants.SUBVIEW.BUY,
        activeQuerySubType: activeInstrumentCategory,
        selectedBond: { cusip: cusip },
        query: {
          filter: {
            cusip: {
              filterKey: "cusip",
              filterValue: cusip,
              filterType: Constants.FILTER_TYPE.KEYWORD_WILDCARD,
            },
            instrumentCategory: activeInstrumentCategory,
          },
          searchId: new Date().getTime(),
        },
      },
      view: Constants.VIEW.INVENTORY,
      subView: Constants.SUBVIEW.BUY,
      querySubType: activeInstrumentCategory,
    });
  };

  React.useEffect(() => {
    if (state.query.identifier && utils.hasNonEmptyValue(state.query.identifier.filterValue)) {
      const cusip = state.query.identifier.filterValue[0];
      const activeView = get(viewState, "activeView");

      if (!state.isError) {
        if (!utils.hasNonEmptyValue(state.data)) {
          if (activeView === Constants.VIEW.INVENTORY) {
            executeInventorySearch(cusip);
          } else {
            utils.issueErrorAlert(Messages.MESSAGE.NODATA.CUSIP_SEARCH_NOT_FOUND);
          }
        } else {
          const bond = state.data[0];
          const key = utils.getInventoryKeyFromInstrumentType(bond.instrumentType.description);

          if (key) {
            bond.instrumentCategory = Constants.INVENTORY_TYPE[key];
          }

          dispatchViewStateChange({
            state: {
              activeView: Constants.VIEW.INVENTORY,
              activeSubView: Constants.SUBVIEW.BUY,
              activeQuerySubType: bond.instrumentCategory,
              activeTool: Constants.TOOL_TYPE.COMPARABLE,
              selectedBond: bond,
              query: {
                filter: {
                  cusip: {
                    filterKey: "cusip",
                    filterValue: bond.cusip,
                    filterType: Constants.FILTER_TYPE.KEYWORD_WILDCARD,
                  },
                  instrumentCategory: bond.instrumentCategory,
                },
                searchId: bond.cusip,
              },
            },
            view: Constants.VIEW.INVENTORY,
            subView: Constants.SUBVIEW.BUY,
            querySubType: bond.instrumentCategory,
          });
        }
      } else {
        utils.issueErrorAlert(Messages.MESSAGE.NODATA.SERVER_ERROR);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.data]);

  return (
    <React.Fragment>
      <KeywordFilter
        style={{ maxWidth: 300 }}
        placeholder={Messages.LABEL.SEARCH_CUSIP_PLACEHOLDER}
        filterKey="cusip"
        onFilterChange={handleFilterChange}
      />
    </React.Fragment>
  );
};

export default CusipSearch;
