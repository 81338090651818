import React from "react";
import { get, orderBy } from "lodash";

import * as DataOptions from "commons/dataOptions";
import * as utils from "commons/utils";
import * as DataConfig from "config/dataconfig";
import HttpService from "services/commons/HttpService";
import { HTTP_CODES } from "commons/constants";

const DATA_OPTIONS_CACHE = "qboc";

const _getOptionsCache = () => {
  let cache = window.sessionStorage.getItem(DATA_OPTIONS_CACHE);
  if (utils.hasNonEmptyValue(cache)) {
    cache = JSON.parse(cache);
  }
  return cache;
};

const _setOptionsCache = (optionsCache) => {
  window.sessionStorage.setItem(DATA_OPTIONS_CACHE, JSON.stringify(optionsCache));
};

const _preprocessData = (data, config, instrumentCategory) => {
  let processedData = data;

  if (config.applyIntrumentCategoryFilter && utils.hasNonEmptyValue(instrumentCategory)) {
    const instrumentCategoryId = utils.getInstrumentCategoryId(instrumentCategory);
    processedData.filter((d) => d.applicableInstrumentTypes.includes(instrumentCategoryId));
  }

  // If this data has a display property, then use it to control if its visible or not.
  // If we dont have this property, then don't use it to filter (i.e. unset = TRUE).
  processedData = processedData.filter((d) => (utils.hasNonEmptyValue(d.display) ? d.display : true));

  if (utils.hasNonEmptyValue(config.sortKey)) {
    processedData = orderBy(processedData, [config.sortKey], config.desc ? ["desc"] : ["asc"]);
  }

  processedData = processedData.map((option) => {
    let values = {};

    if (config.key === "ratingsorder") {
      const id = option.id || option.ids;
      values = { label: option.description, value: id, id: id.toString() };
    } else {
      let value;
      if (Array.isArray(option.id)) {
        option.id = option.id[0].replace(/"/g, "").split(",");
        value = option.id;
      } else {
        value = option.id.toString();
      }
      values = { label: option.description, value: value, id: option.id.toString() };
    }
    return { ...option, ...values };
  });

  return processedData;
};

const _filterDataByInstrumentCategory = (data, config, instrumentCategory) => {
  let processedData = data;

  if (
    utils.hasNonEmptyValue(instrumentCategory) &&
    utils.hasNonEmptyValue(config) &&
    config.applyIntrumentCategoryFilter
  ) {
    const instrumentCategoryId = utils.getInstrumentCategoryId(instrumentCategory);
    processedData = processedData.filter((d) => d.applicableInstrumentTypes.includes(instrumentCategoryId));
  }

  return processedData;
};

export const useOptionsService = (instrumentCategory) => {
  const [options, setOptions] = React.useState([]);
  const [optionsKey, setOptionKey] = React.useState();

  React.useEffect(() => {
    const fetchData = async () => {
      if (optionsKey) {
        const config = get(DataConfig.REFERENCE_ENDPOINT, optionsKey);
        const optionsCache = _getOptionsCache() || {};
        let filteredData = [];
        if (utils.hasNonEmptyValue(get(optionsCache, optionsKey))) {
          filteredData = _filterDataByInstrumentCategory(
            optionsCache[optionsKey],
            config,
            instrumentCategory,
          );
          setOptions(filteredData);
        } else if (utils.hasNonEmptyValue(get(DataOptions.STATIC_OPTIONS, optionsKey))) {
          filteredData = _filterDataByInstrumentCategory(
            DataOptions.STATIC_OPTIONS[optionsKey],
            config,
            instrumentCategory,
          );
          setOptions(filteredData);
        } else {
          if (utils.hasNonEmptyValue(config)) {
            const path = `/reference/${config.url}`;
            HttpService.get(path)
              .then((response) => {
                if (response.status === HTTP_CODES.SUCCESS) {
                  const optionsData = _preprocessData(response.data, config);
                  optionsCache[optionsKey] = optionsData;
                  _setOptionsCache(optionsCache);
                  filteredData = _filterDataByInstrumentCategory(optionsData, config, instrumentCategory);
                  setOptions(filteredData);
                } else {
                  console.error(
                    "Options Provider received no data in response for optionsKey: " + optionsKey,
                  );
                }
              })
              .catch((error) => {
                setOptions([]);
              });
          } else {
            setOptions([]);
          }
        }
      } else {
        setOptions([]);
      }
    };
    fetchData();
  }, [optionsKey, instrumentCategory]);

  return [options, setOptionKey];
};
