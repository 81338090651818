import qs from "qs";
import axios from "axios";
import * as Sentry from "@sentry/react";
import {fullOAuth2ClientID} from "./OAuth2ClientService";

import { storeAccessToken } from "commons/helpers/userStorage";
import * as DataConfig from "config/dataconfig";

const OAUTH2_CLIENT_ID = fullOAuth2ClientID();
const OAUTH2_CLIENT_SECRET = process.env.REACT_APP_OAUTH2_CLIENT_SECRET;

const HttpService = axios.create({
  withCredentials: true,
  timeout: 20000,
});

export const login = (userLogin) => {
  const formData = {
    username: userLogin.username,
    password: userLogin.password,
    grant_type: "password",
    scope: "read write",
  };

  const config = {
    headers: {
      "content-type": "application/x-www-form-urlencoded",
    },
    auth: {
      username: OAUTH2_CLIENT_ID,
      password: OAUTH2_CLIENT_SECRET,
    },
  };
  return HttpService.post(DataConfig.AUTH_LOGIN_ENDPOINT, qs.stringify(formData), config)
    .then((response) => {
      if (response.data && response.data.access_token) {
        storeAccessToken(response.data.access_token, response.data.expires_in);
        return response.data.principal;
      } else {
        Sentry.captureException(new Error("Auth service did not return access token"), (scope) => {
          scope.setContext("response", response);
        });
      }
      return;
    })
    .catch((error) => {
      Sentry.captureException(error);
    });
};

export const resetPassword = (userInfo) => {
  const data = {
    username: userInfo.username,
    email: userInfo.email,
  };

  const config = {
    auth: {
      username: OAUTH2_CLIENT_ID,
      password: OAUTH2_CLIENT_SECRET,
    },
  };
  return HttpService.post(DataConfig.AUTH_RESET_PASSWORD_ENDPOINT, data, config).then((response) => {
    return;
  });
};
