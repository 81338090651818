import axios from "axios";
import * as Sentry from "@sentry/react";
import { escape, get } from "lodash";
import download from "downloadjs";

import * as DataConfig from "config/dataconfig";
import * as Constants from "commons/constants";
import * as utils from "commons/utils";

export const dataToExportXml = (data, columns, sheetName) => {
  columns = columns.filter((c) => c.exportable && c.accessor);
  let xml = `<table name="${sheetName}"><tr>`;

  columns.forEach((c) => {
    xml += `<th style="text-align: center; font-weight:bold">${escape(c.headerText)}</th>`;
  });
  xml += "</tr>";

  data.forEach((d) => {
    xml += `<tr>`;
    columns.forEach((c) => {
      const config = get(DataConfig.PROPERTY, c.accessor);
      let value = "";
      if (config.format === Constants.FORMAT_TYPE.CURRENCY) {
        value = utils.formatNumber(get(d, c.accessor), 2, true);
      } else {
        value = utils.formatValue(get(d, c.accessor), c.accessor, d);
      }

      let style = "";
      if (config.format === Constants.FORMAT_TYPE.NUMBER) {
        style = `width: 10; -xls-content-type: string; text-align: right;`;
      } else if (config.format === Constants.FORMAT_TYPE.CURRENCY) {
        style = `width: 20; -xls-format: currency_dollar`;
      } else if (config.format === Constants.FORMAT_TYPE.DATE) {
        style = `width: 12;`;
      } else {
        style = `width: ${c.exportWidth ? c.exportWidth : "auto"}`;
      }
      xml += `<td style="${style}">${escape(value)}</td>`;
    });
    xml += "</tr>";
  });

  xml += "</table>";

  return xml;
};

export const generateXls = async (data, columns, sheetName, fileName) => {
  const xml = dataToExportXml(data, columns, sheetName);
  const document = `${fileName || sheetName}.xls`;
  await axios
    .post(
      "https://docraptor.com/docs",
      {
        document_content: xml,
        type: "xls",
        test: process.env.REACT_APP_STAGE !== "prod",
        user_credentials: "fv-JBVZ6Y_-peSh4Tu1M",
      },
      { responseType: "arraybuffer" },
    )
    .then((resp, error) => {
      if (resp.status === 200) {
        download(resp.data, document, resp.headers["content-type"]);
      } else if (error) {
        Sentry.captureException(error);
        utils.issueErrorAlert(error);
      } else {
        Sentry.captureException(new Error(`DocRaptor document ${document} was unable to download`), (scope) =>
          scope.setContext("response", resp),
        );
        utils.issueErrorAlert("Document was unable to download.  Please try again later.");
      }

      return resp.status;
    });
};
