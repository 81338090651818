import React from "react";
import PropTypes from "prop-types";
import { get, uniqBy } from "lodash";

import * as Constants from "commons/constants";
import { useMessages } from "providers/BrandingProvider";
import * as DataConfig from "config/dataconfig";
import * as utils from "commons/utils";
import QBTypography from "components/QBTypography";

import { useOptionsService } from "services/OptionsService";

import IncrementFilter, {
  getDateIncrementTooltipValue,
  getDateIncrementLabel,
} from "components/filters/IncrementFilter";
import SelectRatingFilter from "components/filters/SelectRatingFilter";
import DualRangeFilter from "components/filters/DualRangeFilter";
import CheckboxFilter from "components/filters/CheckboxFilter";
import TaxStatusToggleButtonFilterGroup from "components/filters/TaxStatusToggleButtonFilterGroup";

import Collapse from "@material-ui/core/Collapse";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Radio from "@material-ui/core/Radio";

import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";

import { makeStyles, withStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  header: {
    display: "flex",
    width: "100%",
    cursor: "pointer",
    marginTop: 40,
    "&:first-child": {
      marginTop: 20,
    },
    "& div:last-child": {
      textAlign: "right",
      flexGrow: 1,
    },
    "& div:first-child": {
      textAlign: "left",
    },
  },
  footer: {
    display: "flex",
    marginTop: 10,
    marginBottom: 20,
    width: "100%",
    "& div:last-child": {
      textAlign: "right",
      flexGrow: 1,
      flexWrap: "nowrap",
      whiteSpace: "nowrap",
    },
    "& div:first-child": {
      textAlign: "left",
    },
  },
  section: {
    borderTop: "1px solid",
    borderTopColor: theme.palette.border.main,
    minWidth: 400,
    paddingTop: 10,
    "&:last-child": {
      marginBottom: 20,
    },
  },
  filterWrapper: {
    padding: "0 10px",
  },
  headerText: {
    textTransform: "uppercase",
    color: theme.palette.text.popper.header,
  },
  bondValue: {
    color: theme.palette.primary.main,
  },
  criteria: {
    color: theme.palette.secondary.main,
  },
  iconRoot: {
    fontSize: "0.8rem",
    color: theme.palette.text.secondary,
  },
  includeLabelRoot: {
    margin: 0,
    minWidth: 100,
  },
  includeLabel: {
    fontSize: "0.7rem",
    marginLeft: 0,
    letterSpacing: "0.03em",
    color: theme.palette.text.secondary,
  },
  includeCheckboxRoot: {
    padding: 5,
  },
  radioLabel: {
    fontSize: "0.8rem",
    marginLeft: 0,
    color: theme.palette.text.secondary,
  },
  filterRadio: {
    "& svg": {
      fontSize: "1.0rem",
    },
  },
  incrementWrapper: {
    maxWidth: 250,
  },
}));

const HideFilterSvgIcon = withStyles((theme) => ({
  root: {
    padding: 0,
    fontSize: "1.2rem",
  },
}))(KeyboardArrowDownIcon);

const ShowFilterSvgIcon = withStyles((theme) => ({
  root: {
    padding: 0,
    fontSize: "1.2rem",
  },
}))(KeyboardArrowRightIcon);

const getBondDisplayValue = (config, bond, valueDisplayMap) => {
  if (valueDisplayMap) {
    return valueDisplayMap[get(bond, config.accessor)];
  } else {
    return utils.formatValue(get(bond, config.accessor), config.accessor, bond);
  }
};

export const FilterHeader = (props) => {
  const classes = useStyles();
  const { config, collapsed, onClick, ignore = false, handleIgnoreChecked } = props;

  return (
    <div onClick={onClick} className={classes.header}>
      {!ignore && collapsed && <ShowFilterSvgIcon onClick={onClick} />}
      {!ignore && !collapsed && <HideFilterSvgIcon onClick={onClick} />}
      <div className={classes.headerText}>{config.label || config.header}</div>
      <div>
        {utils.hasNonEmptyValue(handleIgnoreChecked) && (
          <IgnoreCheckBox ignore={ignore} handleIgnoreChecked={handleIgnoreChecked} />
        )}
      </div>
    </div>
  );
};

FilterHeader.propTypes = {
  config: PropTypes.object.isRequired,
  collapsed: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
  ignore: PropTypes.bool,
  handleIgnoreChecked: PropTypes.func,
};

const IgnoreCheckBox = ({ ignore, handleIgnoreChecked }) => {
  const classes = useStyles();
  const Messages = useMessages();

  return (
    <FormControlLabel
      classes={{ root: classes.includeLabelRoot, label: classes.includeLabel }}
      control={
        <Checkbox
          size="small"
          checked={ignore}
          classes={{ root: classes.includeCheckboxRoot }}
          icon={<CheckBoxOutlineBlankIcon classes={{ root: classes.iconRoot }} />}
          checkedIcon={<CheckBoxIcon color="secondary" classes={{ root: classes.iconRoot }} />}
        />
      }
      label={Messages.LABEL.IGNORE_FILTER}
      onClick={handleIgnoreChecked}
    />
  );
};

IgnoreCheckBox.propTypes = {
  ignore: PropTypes.bool.isRequired,
  handleIgnoreChecked: PropTypes.func.isRequired,
};

const AdvancedCriteriaFilterPropTypes = {
  config: PropTypes.object.isRequired,
  bond: PropTypes.object.isRequired,
  query: PropTypes.object.isRequired,
  onFilterChange: PropTypes.func.isRequired,
  searches: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
    }).isRequired,
  ),
};

export const AdvancedCriteriaDateRangeIncrementFilter = ({
  config,
  bond,
  query,
  onFilterChange,
  searches,
}) => {
  const classes = useStyles();
  const Messages = useMessages();
  const ignored = query.ignore.includes(config.accessor);
  const [open, setOpen] = React.useState(!ignored);

  const instrumentText =
    bond.instrumentCategory === Constants.INVENTORY_TYPE.CD
      ? Messages.LABEL.CD
      : Messages.LABEL.BOND.toLowerCase();

  const handleIgnoreChecked = (e) => {
    e.preventDefault();
    e.stopPropagation();

    const updatedIgnored = !ignored;
    setOpen(!updatedIgnored);
    onFilterChange({
      filterKey: "ignore",
      filterValue: { ignore: updatedIgnored, accessor: config.accessor },
    });
  };

  return (
    <React.Fragment>
      <FilterHeader
        config={config}
        collapsed={!open}
        onClick={(e) => setOpen(!open && !ignored)}
        ignore={ignored}
        handleIgnoreChecked={handleIgnoreChecked}
      />
      <div className={classes.section}>
        <div className={classes.filterWrapper}>
          <Collapse in={open && !ignored} timeout={400}>
            <div className={classes.incrementWrapper}>
              <IncrementFilter
                key={`${query.id}${utils.isDirtyCompBondSavedQuery(query, searches)}`}
                filterConfig={config.filter}
                filter={get(query.filter, config.accessor, {})}
                onFilterChange={onFilterChange}
                SliderProps={{ valueLabelFormat: getDateIncrementTooltipValue }}
              />
            </div>
          </Collapse>
          <Collapse in={!ignored} timeout={400}>
            <div className={classes.footer}>
              <QBTypography variant="caption" component="div">
                <span className={classes.criteria}>
                  +/- {getDateIncrementLabel(get(query.filter, `${config.accessor}.filterValue`))}
                </span>
                <span>
                  {" "}
                  from selected {instrumentText}'s {config.label}
                </span>
              </QBTypography>
              {utils.hasNonEmptyValue(get(bond, config.accessor)) && (
                <QBTypography variant="caption" style={{ paddingLeft: 10 }} component="div">
                  <span>This {instrumentText}: </span>
                  <span className={classes.bondValue}>{getBondDisplayValue(config, bond)}</span>
                </QBTypography>
              )}
            </div>
          </Collapse>
        </div>
      </div>
    </React.Fragment>
  );
};

AdvancedCriteriaDateRangeIncrementFilter.propTypes = AdvancedCriteriaFilterPropTypes;

export const AdvancedCriteriaNumericRangeIncrementFilter = ({
  config,
  bond,
  query,
  onFilterChange,
  searches,
}) => {
  const classes = useStyles();
  const Messages = useMessages();
  const ignored = query.ignore.includes(config.accessor);
  const [open, setOpen] = React.useState(!ignored);

  const instrumentText =
    bond.instrumentCategory === Constants.INVENTORY_TYPE.CD
      ? Messages.LABEL.CD
      : Messages.LABEL.BOND.toLowerCase();

  const handleIgnoreChecked = (e) => {
    e.preventDefault();
    e.stopPropagation();

    const updatedIgnored = !ignored;
    setOpen(!updatedIgnored);
    onFilterChange({
      filterKey: "ignore",
      filterValue: { ignore: updatedIgnored, accessor: config.accessor },
    });
  };

  return (
    <React.Fragment>
      <FilterHeader
        config={config}
        collapsed={!open}
        onClick={(e) => setOpen(!open && !ignored)}
        ignore={ignored}
        handleIgnoreChecked={handleIgnoreChecked}
      />
      <div className={classes.section}>
        <div className={classes.filterWrapper}>
          <Collapse in={open && !ignored} timeout={400}>
            <div className={classes.incrementWrapper}>
              <IncrementFilter
                key={`${query.id}${utils.isDirtyCompBondSavedQuery(query, searches)}`}
                filterConfig={config.filter}
                filter={get(query.filter, config.accessor, {})}
                onFilterChange={onFilterChange}
              />
            </div>
          </Collapse>
          <Collapse in={!ignored} timeout={400}>
            <div className={classes.footer}>
              <QBTypography variant="caption" component="div">
                <span className={classes.criteria}>
                  +/- {get(query.filter, `${config.accessor}.filterValue`)}
                </span>
                <span>
                  {" "}
                  from selected {instrumentText}'s {config.label}
                </span>
              </QBTypography>
              {utils.hasNonEmptyValue(get(bond, config.accessor)) && (
                <QBTypography variant="caption" style={{ paddingLeft: 10 }} component="div">
                  <span>This {instrumentText}: </span>
                  <span className={classes.bondValue}>{getBondDisplayValue(config, bond)}</span>
                </QBTypography>
              )}
            </div>
          </Collapse>
        </div>
      </div>
    </React.Fragment>
  );
};

AdvancedCriteriaNumericRangeIncrementFilter.propTypes = AdvancedCriteriaFilterPropTypes;

export const AdvancedCriteriaDualRangeFilter = ({ config, bond, query, onFilterChange, searches }) => {
  const classes = useStyles();
  const Messages = useMessages();
  const ignored = query.ignore.includes(config.accessor);
  const [open, setOpen] = React.useState(!ignored);

  const filter = get(query.filter, config.accessor, {});

  const instrumentText =
    bond.instrumentCategory === Constants.INVENTORY_TYPE.CD
      ? Messages.LABEL.CD
      : Messages.LABEL.BOND.toLowerCase();

  const handleIgnoreChecked = (e) => {
    e.preventDefault();
    e.stopPropagation();

    const updatedIgnored = !ignored;
    setOpen(!updatedIgnored);
    onFilterChange({
      filterKey: "ignore",
      filterValue: { ignore: updatedIgnored, accessor: config.accessor },
    });
  };

  return (
    <React.Fragment>
      <FilterHeader
        config={config}
        collapsed={!open}
        onClick={(e) => setOpen(!open && !ignored)}
        ignore={ignored}
        handleIgnoreChecked={handleIgnoreChecked}
      />
      <div className={classes.section}>
        <div className={classes.filterWrapper}>
          <Collapse in={open && !ignored} timeout={400} style={{ marginBottom: 20, maxWidth: 200 }}>
            <DualRangeFilter
              key={`${query.id}${utils.isDirtyCompBondSavedQuery(query, searches)}`}
              filterKey={config.accessor}
              filter={filter}
              onFilterChange={onFilterChange}
              SliderProps={{ valueLabelDisplay: "auto" }}
              {...config.filter}
            />
          </Collapse>
          <Collapse in={!ignored} timeout={400}>
            <div className={classes.footer}>
              <QBTypography variant="caption" component="div">
                <span>{config.label} between </span>
                <span className={classes.criteria}>{filter.filterValue[0] || Messages.LABEL.MIN}</span>
                <span> and </span>
                <span className={classes.criteria}>{filter.filterValue[1] || Messages.LABEL.MAX} </span>
              </QBTypography>
              {utils.hasNonEmptyValue(get(bond, config.accessor)) && (
                <QBTypography variant="caption" style={{ paddingLeft: 10 }} component="div">
                  <span>This {instrumentText}: </span>
                  <span className={classes.bondValue}>{getBondDisplayValue(config, bond)}</span>
                </QBTypography>
              )}
            </div>
          </Collapse>
        </div>
      </div>
    </React.Fragment>
  );
};

AdvancedCriteriaDualRangeFilter.propTypes = AdvancedCriteriaFilterPropTypes;

const getTaxStatusBondValues = (config, bond) => {
  const values = [];

  config.filters.forEach((f) => {
    if (get(bond, f.accessor)) {
      values.push(get(DataConfig.PROPERTY, f.accessor)?.label);
    }
  });

  return values.join(", ");
};

const getTaxStatusFilterValues = (config, filters, value) => {
  const values = [];

  config.filters.forEach((config) => {
    const filter = filters[config.accessor];
    if (filter.filterValue === value) {
      values.push(get(DataConfig.PROPERTY, filter.filterKey)?.label);
    }
  });

  return values.join(", ");
};

export const AdvancedCriteriaTaxStatusFilter = ({ config, bond, query, onFilterChange, searches }) => {
  const classes = useStyles();
  const Messages = useMessages();
  const ignored = query.ignore.includes(config.filters[0].accessor);
  const [open, setOpen] = React.useState(!ignored);

  const thisBondValues = getTaxStatusBondValues(config, bond);
  const thisFilterValues = getTaxStatusFilterValues(config, query.filter, "true");
  const thisFilterOmitValues = getTaxStatusFilterValues(config, query.filter, "false");

  const instrumentText =
    bond.instrumentCategory === Constants.INVENTORY_TYPE.CD
      ? Messages.LABEL.CD
      : Messages.LABEL.BOND.toLowerCase();

  const handleIgnoreChecked = (e) => {
    e.preventDefault();
    e.stopPropagation();

    const updatedIgnored = !ignored;
    setOpen(!updatedIgnored);

    const filters = config.filters.map((filter) => {
      return {
        filterKey: "ignore",
        filterValue: {
          ignore: updatedIgnored,
          accessor: filter.accessor,
        },
      };
    });

    onFilterChange(filters);
  };

  return (
    <React.Fragment>
      <FilterHeader
        config={config}
        collapsed={!open}
        onClick={(e) => setOpen(!open && !ignored)}
        ignore={ignored}
        handleIgnoreChecked={handleIgnoreChecked}
      />
      <div className={classes.section}>
        <div className={classes.filterWrapper}>
          <Collapse in={open && !ignored} timeout={400} style={{ marginBottom: 20, maxWidth: 200 }}>
            <TaxStatusToggleButtonFilterGroup
              key={`${query.id}${utils.isDirtyCompBondSavedQuery(query, searches)}`}
              filters={query.filter}
              filterConfigs={config.filters}
              onFilterChange={onFilterChange}
            />
          </Collapse>
          <Collapse in={!ignored} timeout={400}>
            <div className={classes.footer}>
              <QBTypography variant="caption" component="div" style={{ lineHeight: 1.4 }}>
                {utils.hasNonEmptyValue(thisFilterValues) && (
                  <div>
                    <span>Selected: </span>
                    <span className={classes.criteria}>{thisFilterValues}</span>
                  </div>
                )}
                {utils.hasNonEmptyValue(thisFilterOmitValues) && (
                  <div>
                    <span>Omitting: </span>
                    <span className={classes.criteria}>{thisFilterOmitValues}</span>
                  </div>
                )}
                {!utils.hasNonEmptyValue(thisFilterValues) && !utils.hasNonEmptyValue(thisFilterOmitValues) && (
                  <div>
                    <span>No filters applied</span>
                  </div>
                )}
              </QBTypography>
              {utils.hasNonEmptyValue(thisBondValues) && (
                <QBTypography variant="caption" component="div" style={{ paddingLeft: 10, lineHeight: 1.4 }}>
                  <span>This {instrumentText}: </span>
                  <span className={classes.bondValue}>{thisBondValues}</span>
                </QBTypography>
              )}
            </div>
          </Collapse>
        </div>
      </div>
    </React.Fragment>
  );
};

AdvancedCriteriaTaxStatusFilter.propTypes = AdvancedCriteriaFilterPropTypes;

const getRatingIncrementLabel = (value) => {
  if (value === 0) {
    return "the same as";
  } else if (value < 0) {
    return `${Math.abs(value)} rating${value < -1 ? "s" : ""} lower than`;
  } else {
    return `${value} rating${value > 1 ? "s" : ""} higher than`;
  }
};

export const AdvancedCriteriaSelectRatingFilter = ({ config, bond, query, onFilterChange, searches }) => {
  const classes = useStyles();
  const Messages = useMessages();
  const [options, setOptionsKey] = useOptionsService();
  const [optionsMap, setOptionsMap] = React.useState();

  const ignored = query.ignore.includes(config.accessor);
  const [open, setOpen] = React.useState(!ignored);

  const filter = get(query.filter, config.accessor, {});
  const [isSelect, setSelect] = React.useState(false);

  const sliderFilter = {
    filterKey: filter.filterKey,
    filterValue: filter.filterValue.increment,
    filterType: Constants.FILTER_TYPE.RATING_INCREMENT,
  };

  const ratingFilter = {
    filterKey: filter.filterKey,
    filterValue: filter.filterValue,
    filterType: Constants.FILTER_TYPE.SELECTRATING,
  };

  React.useEffect(() => {
    if (utils.hasNonEmptyValue(options)) {
      const optionsMap = {
        999: Messages.LABEL.NONE,
      };
      options.forEach((o) => {
        //if (!Array.isArray(o.value)) {
        optionsMap[o.value] = o.label;
        //}
      });

      setOptionsMap(optionsMap);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [options]);

  React.useEffect(() => {
    setOptionsKey(config.filter?.optionsKey);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const instrumentText =
    bond.instrumentCategory === Constants.INVENTORY_TYPE.CD
      ? Messages.LABEL.CD
      : Messages.LABEL.BOND.toLowerCase();

  const handleIgnoreChecked = (e) => {
    e.preventDefault();
    e.stopPropagation();

    const updatedIgnored = !ignored;
    setOpen(!updatedIgnored);
    onFilterChange({
      filterKey: "ignore",
      filterValue: { ignore: updatedIgnored, accessor: config.accessor },
    });
  };

  const handleFilterChange = (updatedFilter) => {
    if (updatedFilter.filterType === Constants.FILTER_TYPE.RATING_INCREMENT) {
      updatedFilter.filterValue = { ...filter.filterValue, ...{ increment: updatedFilter.filterValue } };
    } else {
      updatedFilter.filterValue = { ...filter.filterValue, ...updatedFilter.filterValue };
    }

    onFilterChange(updatedFilter);
  };

  return (
    <React.Fragment>
      {optionsMap && (
        <React.Fragment>
          <FilterHeader
            config={config}
            collapsed={!open}
            onClick={(e) => setOpen(!open && !ignored)}
            ignore={ignored}
            handleIgnoreChecked={handleIgnoreChecked}
          />
          <div className={classes.section}>
            <div className={classes.filterWrapper}>
              <Collapse in={open && !ignored} timeout={400}>
                <div style={{ display: "flex", flexWrap: "nowrap" }}>
                  <div style={{ flexGrow: 99 }}>
                    <div style={{ marginBottom: 15 }}>
                      <FormControlLabel
                        classes={{ label: classes.radioLabel }}
                        value="end"
                        label="By dynamic value"
                        control={
                          <Radio
                            classes={{ root: classes.filterRadio }}
                            checked={!isSelect}
                            onChange={(e) => setSelect(false)}
                            value="increment"
                            name="ratingsfilteroptions"
                          />
                        }
                      />
                    </div>
                    <div className={classes.incrementWrapper}>
                      <IncrementFilter
                        key={`increment_${query.id}${utils.isDirtyCompBondSavedQuery(query, searches)}`}
                        filterConfig={{
                          type: Constants.FILTER_TYPE.RATING_INCREMENT,
                          min: -3,
                          max: 0,
                          marks: [
                            { value: -3, label: "3 lower" },
                            { value: -2, label: "2 lower" },
                            { value: -1, label: "1 lower" },
                            { value: 0, label: "same" },
                          ],
                        }}
                        filter={sliderFilter}
                        onFilterChange={handleFilterChange}
                        SliderProps={{ disabled: isSelect }}
                      />
                    </div>
                  </div>
                  <div style={{ flexGrow: 1 }}>
                    <div style={{ marginBottom: 15 }}>
                      <FormControlLabel
                        classes={{ label: classes.radioLabel }}
                        value="end"
                        label="By specific rating or range"
                        control={
                          <Radio
                            classes={{ root: classes.filterRadio }}
                            checked={isSelect}
                            onChange={(e) => setSelect(true)}
                            value="select"
                            name="ratingsfilteroptions"
                          />
                        }
                      />
                    </div>
                    <SelectRatingFilter
                      SelectProps={{ disabled: !isSelect }}
                      key={`${query.id}${utils.isDirtyCompBondSavedQuery(query, searches)}`}
                      variant="standalone"
                      filterKey={config.accessor}
                      filter={ratingFilter}
                      onFilterChange={handleFilterChange}
                      {...config.filter}
                    />
                  </div>
                </div>
              </Collapse>
              <Collapse in={!ignored} timeout={400}>
                <div className={classes.footer}>
                  <QBTypography variant="caption" component="div">
                    {isSelect && !Array.isArray(filter.filterValue.value) && (
                      <React.Fragment>
                        <span>Minimum rating of </span>
                        <span className={classes.criteria}>{optionsMap[filter.filterValue.value]}</span>
                      </React.Fragment>
                    )}
                    {isSelect && Array.isArray(filter.filterValue.value) && (
                      <React.Fragment>
                        <span>Rating is </span>
                        <span className={classes.criteria}>{optionsMap[filter.filterValue.value]}</span>
                      </React.Fragment>
                    )}
                    {isSelect && !utils.hasNonEmptyValue(filter.filterValue.exclude) && (
                      <span className={classes.criteria}> including Unrated</span>
                    )}
                    {!isSelect && (
                      <React.Fragment>
                        <span>Minimum rating </span>
                        <span className={classes.criteria}>
                          {getRatingIncrementLabel(filter.filterValue.increment)}
                        </span>
                        <span> selected {instrumentText}'s Rating</span>
                      </React.Fragment>
                    )}
                  </QBTypography>
                  {utils.hasNonEmptyValue(get(bond, config.accessor)) && (
                    <QBTypography variant="caption" style={{ paddingLeft: 10 }} component="div">
                      <span>This {instrumentText}: </span>
                      <span className={classes.bondValue}>
                        {getBondDisplayValue(config, bond, optionsMap)}
                      </span>
                    </QBTypography>
                  )}
                </div>
              </Collapse>
            </div>
          </div>
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

AdvancedCriteriaSelectRatingFilter.propTypes = AdvancedCriteriaFilterPropTypes;

export const AdvancedCriteriaCheckboxFilter = ({ config, bond, query, onFilterChange, searches }) => {
  const classes = useStyles();
  const Messages = useMessages();
  const [options, setOptionsKey] = useOptionsService();
  const [optionsMap, setOptionsMap] = React.useState();

  const ignored = query.ignore.includes(config.accessor);
  const [open, setOpen] = React.useState(false);

  const filter = get(query.filter, config.accessor, {});

  const instrumentText =
    bond.instrumentCategory === Constants.INVENTORY_TYPE.CD
      ? Messages.LABEL.CD
      : Messages.LABEL.BOND.toLowerCase();

  React.useEffect(() => {
    if (utils.hasNonEmptyValue(options)) {
      const optionsMap = {};

      options.forEach((o) => {
        if (Array.isArray(o.value)) {
          o.value.forEach((v) => {
            optionsMap[v] = o.label;
          });
        }
      });

      options.forEach((o) => {
        if (!Array.isArray(o.value)) {
          optionsMap[o.value] = o.label;
        }
      });

      options.forEach((o) => {
        if (Array.isArray(o.value)) {
          const isIncomplete =
            Object.values(optionsMap).indexOf(o.label) !== Object.values(optionsMap).lastIndexOf(o.label);
          if (isIncomplete) {
            optionsMap[o.value] = o.label;
          }
        }
      });

      setOptionsMap(optionsMap);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [options]);

  React.useEffect(() => {
    setOptionsKey(config.filter.optionsKey);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleIgnoreChecked = (e) => {
    e.preventDefault();
    e.stopPropagation();

    const updatedIgnored = !ignored;
    setOpen(!updatedIgnored);
    onFilterChange({
      filterKey: "ignore",
      filterValue: { ignore: updatedIgnored, accessor: config.accessor },
    });
  };

  const updateFilter = (filter, optionsMap) => {
    for (var e in optionsMap) {
      const valueArray = e.split(",");
      if (
        valueArray.length > 1 &&
        filter.filterValue.selected.length === 1 &&
        valueArray.includes(filter.filterValue.selected[0])
      ) {
        filter.filterValue.selected = valueArray;
      }
    }
    return filter;
  };

  return (
    <React.Fragment>
      {optionsMap && (
        <React.Fragment>
          <FilterHeader
            collapsed={!open}
            config={config}
            onClick={(e) => setOpen(!open && !ignored)}
            ignore={ignored}
            handleIgnoreChecked={handleIgnoreChecked}
          />
          <div className={classes.section}>
            <Collapse in={open && !ignored} timeout={800}>
              <div style={{ marginBottom: 20 }}>
                <CheckboxFilter
                  key={`${query.id}${utils.isDirtyCompBondSavedQuery(query, searches)}`}
                  collapsible={true}
                  filterKey={config.accessor}
                  filter={updateFilter(filter, optionsMap)}
                  width={550}
                  instrumentCategory={bond.instrumentCategory}
                  onFilterChange={onFilterChange}
                  {...config.filter}
                />
              </div>
            </Collapse>
            <Collapse in={!ignored} timeout={400}>
              <div className={classes.filterWrapper}>
                <div className={classes.footer}>
                  <QBTypography style={{ lineHeight: 1.4 }} component="div" variant="caption">
                    <span>Selected: </span>
                    {utils.hasNonEmptyValue(filter.filterValue.selected) && (
                      <span className={classes.criteria}>
                        {filter.filterValue.omit ? "OMIT " : ""}
                        {uniqBy(filter.filterValue.selected.map((v) => optionsMap[v]).sort()).join(", ")}
                      </span>
                    )}
                    {!utils.hasNonEmptyValue(filter.filterValue.selected) && (
                      <span>{Messages.LABEL.NONE}</span>
                    )}
                  </QBTypography>
                  {utils.hasNonEmptyValue(get(bond, config.accessor)) && (
                    <QBTypography
                      style={{ paddingLeft: 10, lineHeight: 1.4 }}
                      component="div"
                      variant="caption"
                    >
                      <span>This {instrumentText}: </span>
                      <span className={classes.bondValue}>
                        {getBondDisplayValue(config, bond, optionsMap)}
                      </span>
                    </QBTypography>
                  )}
                </div>
              </div>
            </Collapse>
          </div>
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

AdvancedCriteriaCheckboxFilter.propTypes = AdvancedCriteriaFilterPropTypes;
