import React from "react";
import Countdown from "react-countdown";

import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  time: {
    color: theme.palette.secondary.main,
  },
}));

const CountdownClock = (props) => {
  const { endTime, bond } = props;
  const classes = useStyles();

  const renderer = ({ hours, minutes, seconds, completed }) => {
    if (completed) {
      return <span className={classes.time}>{!bond.bids?.length ? "EXPIRED" : "DELIVERED"}</span>;
    } else {
      return (
        <span className={classes.time}>
          {hours}:{String(minutes).padStart(2, 0)}:{String(seconds).padStart(2, 0)}
        </span>
      );
    }
  };

  return (
    <React.Fragment>
      <Countdown date={endTime} renderer={renderer} />
    </React.Fragment>
  );
};

export default CountdownClock;
