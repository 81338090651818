import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core/styles";

const QBTypography = withStyles((theme) => ({
  h1: {
    fontSize: "1.4rem",
    color: theme.palette.logo.main,
  },
  h2: {
    // reserved from expansion panel headers
    fontSize: "1.2rem",
    margin: "auto 0",
  },
  h3: {
    fontSize: "1.0rem",
    fontWeight: 300,
    lineHeight: 1,
    letterSpacing: "-0.01562em",
  },
  h6: {
    color: theme.palette.text.tertiary,
    fontSize: ".7rem",
    letterSpacing: "0.08333em",
    textTransform: "uppercase",
  },
  overline: {
    lineHeight: 2,
  },
  subtitle1: {
    "& > span": {
      fontSize: ".8rem",
      color: theme.palette.text.secondary,
    },
    "& > span:last-child": {
      marginLeft: 5,
      color: theme.palette.secondary.main,
    },
  },
  body1: {
    fontSize: ".75rem",
    lineHeight: 1.4,
    "& > span": {
      color: theme.palette.secondary.dark,
      marginRight: 4,
    },
    "& > span:first-child": {
      color: theme.palette.text.secondary,
    },
    "& > span:last-child": {
      marginRight: 0,
    },
  },
  body2: {
    fontSize: ".75rem",
    lineHeight: 1.4,
  },
  caption: {
    color: theme.palette.text.secondary,
    lineHeight: 1,
    letterSpacing: "normal",
  },
}))(Typography);

export default QBTypography;
