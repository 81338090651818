import React from "react";
import { get, omitBy } from "lodash";
import { CSSTransition } from "react-transition-group";
import PropTypes from "prop-types";
import clsx from "clsx";
import { useGlobal } from "reactn";

import { setPreference } from "services/PreferencesService";
import { useViewStateDispatcher } from "providers/ViewStateProvider";
import * as utils from "commons/utils";
import * as Constants from "commons/constants";
import { useMessages } from "providers/BrandingProvider";
import { TextAreaInput, ActionButton, StyledSecondaryTooltip } from "components/Controls";

import DataTable from "components/datatable/DataTable";
import * as ticketUtils from "./tickets/ticketUtils";
import TicketHeader from "./tickets/TicketHeader";
import ModalMessages from "./common/ModalMessages";
import { getUser } from "commons/helpers/userStorage";
import { ModalImportCusipButton, ModalReturnButton } from "./common/FormComponents";
import {
  TicketBody,
  TicketFooter,
  TicketRow,
  TicketRowSection,
  TicketButton,
  Spacer,
  UserMessage,
  SuccessMessage,
} from "./tickets/TicketComponents";
import ImportCusips from "./tickets/ImportCusips";
import { UnclosableModal } from "components/containers/Modals";
import { useDataService } from "services/DataService";

import Switch from "@material-ui/core/Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Button from "@material-ui/core/Button";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { alpha } from "@material-ui/core/styles/colorManipulator";
import { userHasAnyPermission } from "commons/utils";
import { USER_PERMISSIONS } from "commons/constants";
import { isValidCusip } from "commons/utils";
import AccountSelectionModal from "./AccountSelectionModal";
import { getAffiliateInstitutions } from "commons/helpers/userStorage";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    width: 980,
  },
  offersModal: {
    width: 1290,
  },
  switchLabel: {
    whiteSpace: "nowrap",
    color: theme.palette.text.secondary,
    fontSize: "0.65rem",
    textTransform: "uppercase",
  },
  switchRoot: {
    whiteSpace: "nowrap",
  },
  switchWrapper: {
    marginRight: 2,
    marginLeft: 2,
    color: theme.palette.secondary.main,
  },
}));

const UNCALCULATED_VALUE = "N/A";

const ref = React.createRef();

const lockModalHeight = () => {
  if (ref.current) {
    ref.current.style["min-height"] = ref.current.clientHeight + "px";
    ref.current.style["max-height"] = ref.current.clientHeight + "px";
    ref.current.style["overflow-y"] = "hidden";
  }
};

const unlockModalHeight = () => {
  if (ref.current) {
    ref.current.style["min-height"] = "inherit";
    ref.current.style["max-height"] = "inherit";
    ref.current.style["overflow-y"] = "auto";
  }
};

const adjustSubmittedRequest = (bond) => {
  bond.warnings = bond.warnings || [];
  bond.calculatorErrors = bond.calculatorErrors || [];

  if (!bond.principal) {
    bond.warnings.push("principal");
    bond.principal = UNCALCULATED_VALUE;
  }
  if (!bond.total) {
    bond.warnings.push("total");
    bond.total = UNCALCULATED_VALUE;
  }
  if (!bond.accruedInterest) {
    bond.warnings.push("accruedInterest");
    bond.accruedInterest = UNCALCULATED_VALUE;
  }

  if (!bond.price) {
    bond.warnings.push("price");
    bond.calculatorErrors.push("yieldToMaturity");

    if (bond.callable) {
      bond.calculatorErrors.push("yieldToWorst");
    }
  }
  if (!bond.yieldToMaturity) {
    bond.warnings.push("yieldToMaturity");
    bond.calculatorErrors.push("price");
  }

  if (bond.callable && !bond.yieldToWorst) {
    bond.warnings.push("yieldToWorst");
    bond.calculatorErrors.push("price");
  }
};

export const RequestTicket = ({ open, tradeType, bondsInRequest, onCloseRequestTicket }) => {
  const classes = useStyles();
  const [userprefs = {}] = useGlobal("userprefs");
  const user = getUser();
  const Messages = useMessages();

  const [ticketStatus, setTicketStatus] = React.useState();
  const [stagedStatus, setStagedStatus] = React.useState();
  const [buyer, setBuyer] = React.useState();

  // eslint-disable-next-line no-unused-vars
  const [error, setError] = React.useState(ticketUtils.ERROR.NONE);

  const [data, setData] = React.useState([]);
  const [failures, setFailures] = React.useState([]);

  let [infoMessages, setInfoMessages] = React.useState([]);
  let [warningMessages, setWarningMessages] = React.useState([]);
  let [errorMessages, setErrorMessages] = React.useState([]);

  const [userMessage, setUserMessage] = React.useState("");

  const [focusedElementId, setFocusedElementId] = React.useState();
  const [additionalValueKey, setAdditionalValueKey] = React.useState("price");
  const [quantityField, setQuantityField] = React.useState(userprefs.sellquantity || "originalSize");

  const [queryCalculatorState, queryCalculator] = useDataService();
  const [queryLookupState, queryLookup] = useDataService();
  const [postTradeState, postTrade] = useDataService();

  const dispatchViewStateChange = useViewStateDispatcher();

  const isOrderEdit =
    utils.hasNonEmptyValue(bondsInRequest) && utils.hasNonEmptyValue(bondsInRequest[0].orderNo);

  const isOffersForm = tradeType === Constants.TRADE_TYPE.BONDS_FOR_SALE;
  const userCanTrade = userHasAnyPermission(user, [USER_PERMISSIONS.CAN_TRADE]);
  const affiliateInstitutions = getAffiliateInstitutions();
  const canTradeForOthers = userCanTrade && user.affiliateAdmin && affiliateInstitutions?.length > 0;

  React.useEffect(() => {
    if (open) {
      if (isOrderEdit) {
        setBuyer({ brokeredClearingAccount: bondsInRequest[0].brokeredClearingAccount });
      } else {
        if (user?.brokeredClearingAccounts?.length === 1 && !canTradeForOthers) {
          setBuyer({ brokeredClearingAccount: user.brokeredClearingAccounts[0] });
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOrderEdit, open]);

  React.useEffect(() => {
    handleCalculatorResponse(queryCalculatorState);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryCalculatorState.data]);

  React.useEffect(() => {
    handleLookupResponse(queryLookupState);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryLookupState.data]);

  React.useEffect(() => {
    handleSubmitRequestResponse(postTradeState);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [postTradeState.data]);

  React.useEffect(() => {
    if (ticketStatus === ticketUtils.TICKET_STATUS.PREVIEW) {
      resetErrorState();
      const validData = getValidatedData();
      validateAndSetData(validData, true);
    } else if (ticketStatus === ticketUtils.TICKET_STATUS.EDIT) {
      resetErrorState();
      validateAndSetData(data);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ticketStatus]);

  React.useEffect(() => {
    if (open) {
      if (utils.hasNonEmptyValue(bondsInRequest)) {
        const bonds = bondsInRequest.map((bond) => {
          bond.errors = [];
          bond.warnings = [];
          bond.calculatorErrors = [];
          bond.buyerSeller = Constants.ACTOR_TYPE.BUYER;

          if (bond.orderNo) {
            bond.originalSize = bond.quantity * 1000;
            bond.quantity_edit = bond.quantity;
            bond.additionalValueKey = bond.additionalValueKey || "price";

            if (isOffersForm) {
              adjustSubmittedRequest(bond);
            }
          } else {
            delete bond.yieldToWorst;
            delete bond.price;
            delete bond.yieldToMaturity;
            delete bond.principal;
            delete bond.total;
            delete bond.accruedInterest;
          }

          return bond;
        });
        setData(bonds);
      } else {
        setData([]);
      }

      setTicketStatus(ticketUtils.TICKET_STATUS.EDIT);
      setStagedStatus(ticketUtils.TICKET_STATUS.EDIT);
    } else {
      setTicketStatus(null);
      setData([]);
    }

    setFailures([]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  const toggleQuantityField = () => {
    const field = quantityField === "originalSize" ? "quantity_edit" : "originalSize";
    setQuantityField(field);
    setPreference("sellquantity", field);
    updatePriceAndYield();
  };

  const allowableNull = (datum, accessor) =>
    utils.hasNonEmptyValue(datum[accessor] && datum.warnings?.includes[accessor]);

  const validateBond = (datum, tradeType, ticketErrorMessages) => {
    if (!utils.hasNonEmptyValue(datum.cusip) && !utils.hasNonEmptyValue(datum[quantityField])) {
      datum.errors = [];
      return;
    }

    ticketUtils.validateCusip(datum);
    ticketUtils.validateSellQuantity(datum, quantityField);

    if (tradeType === Constants.TRADE_TYPE.BONDS_FOR_SALE) {
      if (
        datum.price !== Constants.RESPONSE.LOADING &&
        datum.price !== Constants.RESPONSE.DISABLED &&
        datum.price !== UNCALCULATED_VALUE &&
        !allowableNull(datum, "price")
      ) {
        ticketUtils.validatePrice(datum);
      } else {
        utils.removeFromArray(datum.errors, "price");
      }
      if (
        datum.yieldToWorst !== Constants.RESPONSE.LOADING &&
        datum.yieldToWorst !== Constants.RESPONSE.DISABLED &&
        datum.yieldToWorst !== UNCALCULATED_VALUE &&
        !allowableNull(datum, "yieldToWorst")
      ) {
        ticketUtils.validateYield(datum, "yieldToWorst", ticketErrorMessages, Messages);
      } else {
        utils.removeFromArray(datum.errors, "yieldToWorst");
      }
      if (
        datum.yieldToMaturity !== Constants.RESPONSE.LOADING &&
        datum.yieldToMaturity !== Constants.RESPONSE.DISABLED &&
        datum.yieldToMaturity !== UNCALCULATED_VALUE &&
        !allowableNull(datum, "yieldToMaturity")
      ) {
        ticketUtils.validateYield(datum, "yieldToMaturity", ticketErrorMessages, Messages);
      } else {
        utils.removeFromArray(datum.errors, "yieldToMaturity");
      }
    }
  };

  const resetErrorState = () => {
    infoMessages = [];
    setInfoMessages(infoMessages);
    warningMessages = [];
    setWarningMessages(warningMessages);
    errorMessages = [];
    setErrorMessages(errorMessages);
    setError(ticketUtils.ERROR.NONE);
  };

  const getValidCusipData = () =>
    data.filter((d) => d.id > -1 && utils.isValidCusip(d.cusip) && !d.errors.includes("cusip"));

  const getValidCusips = () => {
    return getValidCusipData().map((d) => d.cusip);
  };

  const getValidatedData = () => {
    const validCusips = getValidCusipData();
    const validRequests = validCusips.filter((d) => !utils.hasNonEmptyValue(d.errors));
    if (tradeType !== Constants.TRADE_TYPE.PRICE_QUOTES) {
      return validRequests;
    } else {
      return validRequests.filter((d) => !d.duplicate);
    }
  };

  const readyToPreview = () => {
    const validData = getValidatedData();
    const dataWithCusips = data.filter((d) => utils.hasNonEmptyValue(d.cusip));

    const isLoading = validData.some((d) => {
      return Object.values(d).some((v) => v === Constants.RESPONSE.LOADING);
    });

    return userCanTrade && validData.length > 0 && validData.length === dataWithCusips.length && !isLoading;
  };

  const handleCloseRequestTicket = () => {
    unlockModalHeight();
    setAdditionalValueKey("price");
    setBuyer(null);
    onCloseRequestTicket();

    if (ticketStatus === ticketUtils.TICKET_STATUS.COMPLETE && utils.hasNonEmptyValue(data)) {
      const highlighted = data.map((d) => d.id);
      dispatchViewStateChange({
        state: {
          activeView: Constants.VIEW.INVENTORY,
          activeSubView: Constants.SUBVIEW.SELL,
          activeQuerySubType: tradeType,
          query: {
            searchId: Date.now(),
            highlighted: highlighted,
          },
        },
        view: Constants.VIEW.INVENTORY,
        subView: Constants.SUBVIEW.SELL,
        querySubType: tradeType,
      });

      dispatchViewStateChange({
        state: {
          activeTool: Constants.TOOL_TYPE.STATUS,
          query: {
            searchId: Date.now(),
            highlighted: highlighted,
          },
        },
        view: Constants.VIEW.STATUS,
      });
    }
  };

  const enablePreview = () => {
    setTicketStatus(ticketUtils.TICKET_STATUS.PREVIEW);
  };

  const enableEditing = () => {
    setTicketStatus(ticketUtils.TICKET_STATUS.EDIT);
  };

  const handleRetryLookup = () => {
    resetErrorState();
    lookupCusips(data);
  };

  const setStatus = () => {
    setTicketStatus(stagedStatus);
    unlockModalHeight();
  };

  const toggleImport = () => {
    resetErrorState();
    if (ticketStatus !== ticketUtils.TICKET_STATUS.IMPORT) {
      lockModalHeight();
      setStagedStatus(ticketUtils.TICKET_STATUS.IMPORT);
    } else {
      setStagedStatus(ticketUtils.TICKET_STATUS.EDIT);
      validateAndSetData(data);
      validateAndSetData(data);
    }
  };

  const handleSubmitRequest = () => {
    if (!user[Constants.USER_PERMISSIONS.CAN_TRADE]) {
      setError(ticketUtils.ERROR.NOT_A_TRADER_ACCOUNT);
      setErrorMessages([Messages.MESSAGE.NOT_A_TRADER_ACCOUNT]);
      return;
    }

    resetErrorState();
    setFailures([]);

    var params = [];
    var validData = getValidCusipData();
    validData.forEach((datum, i) => {
      var rec = {
        id: isOrderEdit ? datum.id : i,
        originalSize: quantityField === "originalSize" ? datum.originalSize : datum.quantity_edit * 1000,
        userLabel: datum.userLabel,
        brokeredClearAccountId: buyer?.brokeredClearingAccount.id,
      };

      if (buyer.affiliateTrader) {
        rec.submittedForId = buyer.affiliateTrader.id;
      }

      if (!isOrderEdit) {
        rec.cusip = datum.cusip;
      }

      if (utils.hasNonEmptyValue(userMessage)) {
        rec.specialInstructions = userMessage.trim().substring(0, 256);
      }

      if (tradeType === Constants.TRADE_TYPE.PRICE_QUOTES) {
        rec.buyerSeller = datum.buyerSeller;
        rec["@class"] = "com.qwickrate.qwickbondsapi.sell.forms.RequestQuoteForm";
      } else if (tradeType === Constants.TRADE_TYPE.BONDS_FOR_SALE) {
        rec.price = datum.price === UNCALCULATED_VALUE ? null : datum.price;
        rec.yieldToMaturity = datum.yieldToMaturity === UNCALCULATED_VALUE ? null : datum.yieldToMaturity;
        rec.yieldToWorst = !datum.callable
          ? null
          : datum.yieldToWorst === UNCALCULATED_VALUE
          ? null
          : datum.yieldToWorst;
        rec.principal = datum.principal === UNCALCULATED_VALUE ? null : datum.principal;
        rec.total = datum.total === UNCALCULATED_VALUE ? null : datum.total;
        rec.accruedInterest = datum.accruedInterest === UNCALCULATED_VALUE ? null : datum.accruedInterest;
        rec.carryToNextDay = datum.carryToNextDay;
        rec["@class"] = isOrderEdit
          ? "com.qwickrate.qwickbondsapi.sell.forms.UserEditOfferForm"
          : "com.qwickrate.qwickbondsapi.sell.forms.RequestOfferForm";
      } else if (tradeType === Constants.TRADE_TYPE.BIDS_RFQ) {
        rec["@class"] = "com.qwickrate.qwickbondsapi.sell.forms.bids.RequestBidsForm";
      }

      params.push(rec);
    });

    if (utils.hasNonEmptyValue(params)) {
      const queryParams = {
        tradeType: tradeType,
        params: params,
        clearDataOnFetch: false,
        type: isOrderEdit ? Constants.QUERY_TYPE.REQUEST_TICKET_EDIT : Constants.QUERY_TYPE.REQUEST_TICKET,
      };

      postTrade(queryParams);
    }
  };

  const handleSubmitRequestResponse = (state) => {
    resetErrorState();

    if (state.isError) {
      setError(ticketUtils.ERROR.SUBMIT_FAIL);
      setErrorMessages([Messages.MESSAGE.REQUEST_FAIL]);
    } else {
      const failed = state.errors;
      let failedData = [];

      let succeeded = Object.values(state.data).map((d) => {
        if (isOffersForm) {
          adjustSubmittedRequest(d);
        }
        return d;
      });

      updatePriceYieldNullValues(succeeded);
      ticketUtils.readyTicket(succeeded);

      if (utils.hasNonEmptyValue(failed)) {
        var validData = getValidCusipData();
        for (let e in failed) {
          if (failed.hasOwnProperty(e)) {
            const failedRec = validData[Number(e)];
            failedData.push(failedRec);
          }
        }
      }

      if (utils.hasNonEmptyValue(succeeded)) {
        setInfoMessages([Messages.MESSAGE.REQUEST_SUCCESS]);
      }

      setTicketStatus(ticketUtils.TICKET_STATUS.COMPLETE);
      setFailures(utils.cloneArrayOfObjects(failedData));
      setData(utils.cloneArrayOfObjects(succeeded));
    }
  };

  const lookupCusips = (queryData) => {
    resetErrorState();

    var cusips = [];
    queryData.forEach((datum, i) => {
      if (
        utils.isValidCusip(datum.cusip) &&
        datum.id < 0 &&
        (!datum.errors.includes("cusip") || datum.issuer === Constants.RESPONSE.NOT_FOUND)
      ) {
        const updatedRec = {
          id: -1,
          issuer: Constants.RESPONSE.LOADING,
          cusip: datum.cusip,
          buyerSeller: datum.buyerSeller,
          originalSize: quantityField === "originalSize" ? datum.originalSize : datum.quantity_edit * 1000,
          quantity_edit: quantityField === "originalSize" ? datum.originalSize / 1000 : datum.quantity_edit,
          errors: [],
        };
        if (
          tradeType === Constants.TRADE_TYPE.BONDS_FOR_SALE &&
          utils.hasNonEmptyValue(datum.additionalValueKey)
        ) {
          updatedRec.additionalValueKey = datum.additionalValueKey;
          updatedRec[datum.additionalValueKey] = datum[datum.additionalValueKey];
        }

        queryData[i] = updatedRec;

        if (!cusips.includes(datum.cusip)) {
          cusips.push(datum.cusip);
        }
      }
    });

    if (utils.hasNonEmptyValue(cusips)) {
      const queryParams = {
        type: Constants.QUERY_TYPE.CUSIP_LOOKUP,
        identifier: {
          filterKey: "cusip",
          filterValue: cusips.join(","),
        },
        clearDataOnFetch: false,
      };

      queryLookup(queryParams);
    }

    validateAndSetData(queryData);
  };

  const handleLookupResponse = (state) => {
    resetErrorState();

    if (state.isError) {
      const cusipsQueried = state.query.identifier.filterValue.split(",");
      data.forEach((datum, i) => {
        if (cusipsQueried.includes(datum.cusip)) {
          data[i].issuer = Constants.RESPONSE.NOT_FOUND;
          utils.addToArray(data[i].errors, "cusip");
          delete data[i].additionalValueKey;
          delete data[i].price;
          delete data[i].yieldToMaturity;
          delete data[i].yieldToWorst;
          delete data[i].principal;
          delete data[i].accruedInterest;
          delete data[i].total;
        }
      });

      setErrorMessages([Messages.MESSAGE.LOOKUP_ERROR]);
      setError(ticketUtils.ERROR.LOOKUP_FAIL);
      setFailures([]);

      validateAndSetData(data);
    } else if (!state.isLoading) {
      var validCusipRetrieved = [];

      data.forEach((datum, i) => {
        const cusip = datum.cusip;
        if (state.data.hasOwnProperty(cusip)) {
          if (utils.hasNonEmptyValue(state.data[cusip])) {
            data[i].id = i + 1;
            data[i] = { ...datum, ...state.data[cusip] };

            if (utils.hasNonEmptyValue(datum.additionalValueKey)) {
              data[i].additionalValueKey = datum.additionalValueKey;
              data[i][additionalValueKey] = get(datum, additionalValueKey);
            }

            if (!data[i].callable) {
              if (data[i].additionalValueKey === "yieldToWorst") {
                delete data[i].additionalValueKey;
              }
            }

            validateBond(data[i], tradeType);
            validCusipRetrieved.push(cusip);
          } else {
            data[i].issuer = Constants.RESPONSE.NOT_FOUND;
            utils.addToArray(data[i].errors, "cusip");
            delete data[i].additionalValueKey;
            delete data[i].price;
            delete data[i].yieldToMaturity;
            delete data[i].yieldToWorst;
            delete data[i].principal;
            delete data[i].accruedInterest;
            delete data[i].total;
          }
        }
      });

      if (validCusipRetrieved.length) {
        updatePriceAndYield(validCusipRetrieved);
      }

      validateAndSetData(data);
    }
  };

  const updatePriceAndYield = (cusipsToQuery) => {
    var params = [];
    var useOriginalSize = quantityField === "originalSize";

    if (tradeType === Constants.TRADE_TYPE.BONDS_FOR_SALE) {
      var validCusips = getValidCusips();
      data.forEach((datum, i) => {
        if (cusipsToQuery && !cusipsToQuery.includes(datum.cusip)) {
          return;
        }
        if (validCusips.includes(datum.cusip)) {
          const param = {
            id: datum.id,
            cusip: datum.cusip,
          };

          let valid = false;

          if (useOriginalSize && !datum.errors.includes("originalSize")) {
            param.quantity = datum.originalSize / 1000;
            valid = true;
          }

          if (!useOriginalSize && !datum.errors.includes("quantity_edit")) {
            param.quantity = datum.quantity_edit;
            valid = true;
          }

          if (
            utils.hasNonEmptyValue(datum.additionalValueKey) &&
            utils.hasNonEmptyValue(datum[datum.additionalValueKey])
          ) {
            valid = !datum.errors.includes(datum.additionalValueKey);

            if (valid) {
              param[datum.additionalValueKey] = parseFloat(datum[datum.additionalValueKey]);
            }
          }

          if (valid) {
            params.push(param);

            if (datum.additionalValueKey !== "price") {
              datum.price = Constants.RESPONSE.LOADING;
            }
            if (datum.additionalValueKey !== "yieldToMaturity") {
              datum.yieldToMaturity = Constants.RESPONSE.LOADING;
            }
            if (datum.additionalValueKey !== "yieldToWorst") {
              datum.yieldToWorst = Constants.RESPONSE.LOADING;
            }

            datum.calculatorErrors = [];
          }
        }
      });

      if (utils.hasNonEmptyValue(params)) {
        const queryParams = {
          type: Constants.QUERY_TYPE.PRICE_YIELD_LOOKUP,
          params: params,
          clearDataOnFetch: false,
        };

        queryCalculator(queryParams);
      }

      validateAndSetData(data);
    }
  };

  const handleCalculatorResponse = (state) => {
    resetErrorState();
    if (state.isError) {
      setErrorMessages([Messages.MESSAGE.LOOKUP_ERROR]);
      setError(ticketUtils.ERROR.LOOKUP_FAIL);
    } else if (!state.isLoading) {
      data.forEach((datum, i) => {
        if (state.data.hasOwnProperty(datum.id)) {
          datum = { ...datum, ...state.data[datum.id] };
          datum.calculatorErrors = [];
          datum.warnings = [];
          data[i] = { ...datum };
        } else if (state.errors.hasOwnProperty(datum.id)) {
          const resp = state.errors[datum.id];

          if (resp.data) {
            datum = {
              ...datum,
              ...omitBy(resp.data, (value, field) => {
                return (
                  (field === "priceScale" ||
                    field === "yieldToMaturityScale" ||
                    field === "yieldToWorstScale") &&
                  value == null
                );
              }),
            };
            //datum = { ...datum, ...resp.data };
          }

          datum.warnings = datum.warnings || [];

          const additionalValueKey = datum.additionalValueKey;

          if (additionalValueKey) {
            datum.calculatorErrors = [additionalValueKey];
            datum[additionalValueKey] = state.query.params.find((p) => (p.id = datum.id))[additionalValueKey];

            if (additionalValueKey !== "price") {
              datum.price = null;
            }

            if (additionalValueKey !== "yieldToMaturity") {
              datum.yieldToMaturity = null;
            }

            if (additionalValueKey !== "yieldToWorst") {
              datum.yieldToWorst = null;
            }

            if (!datum.price) {
              utils.addToArray(datum.warnings, "price");
            } else {
              utils.removeFromArray(datum.warnings, "price");
            }
            if (!datum.yieldToMaturity) {
              utils.addToArray(datum.warnings, "yieldToMaturity");
            } else {
              utils.removeFromArray(datum.warnings, "yieldToMaturity");
            }
            if (datum.callable && !datum.yieldToWorst) {
              utils.addToArray(datum.warnings, "yieldToWorst");
            } else {
              utils.removeFromArray(datum.warnings, "yieldToWorst");
            }
          } else {
            datum.calculatorErrors = ["quantity_edit", "originalSize"];
            datum.price = null;
            datum.yieldToMaturity = null;
            datum.yieldToWorst = null;
          }

          if (!utils.hasNonEmptyValue(datum.principal) || datum.principal === UNCALCULATED_VALUE) {
            utils.addToArray(datum.warnings, "principal");
            datum.principal = UNCALCULATED_VALUE;
          } else {
            utils.removeFromArray(datum.warnings, "principal");
          }

          if (!utils.hasNonEmptyValue(datum.total) || datum.total === UNCALCULATED_VALUE) {
            utils.addToArray(datum.warnings, "total");
            datum.total = UNCALCULATED_VALUE;
          } else {
            utils.removeFromArray(datum.warnings, "total");
          }

          if (
            !utils.hasNonEmptyValue(datum.accruedInterest) ||
            datum.accruedInterest === UNCALCULATED_VALUE
          ) {
            utils.addToArray(datum.warnings, "accruedInterest");
            datum.accruedInterest = UNCALCULATED_VALUE;
          } else {
            utils.removeFromArray(datum.warnings, "accruedInterest");
          }

          data[i] = { ...datum };
        }
      });
      validateAndSetData(data);
    }
  };

  const handleValueChange = (props, value, relatedTarget) => {
    const accessor = props.column.id;
    const datum = data[props.index];

    if (relatedTarget) {
      setFocusedElementId(relatedTarget.id);
    } else {
      setFocusedElementId(null);
    }

    if (accessor === "toggleImport") {
      toggleImport();
    } else if (accessor === "delete") {
      resetErrorState();
      data.splice(props.index, 1);
      validateAndSetData(data);
    } else if (accessor === "carryToNextDay") {
      datum.carryToNextDay = value;
      validateAndSetData(data);
    } else if (accessor === "userLabel") {
      datum.userLabel = value.trim();
      validateAndSetData(data);
    } else {
      const rawValue = value.trim().replace(/\$|,/g, "");
      const currentValue = utils.hasNonEmptyValue(props.row[accessor])
        ? props.row[accessor].toString()
        : props.row[accessor];

      // Improve UX slightly by not re-calculating things and making network calls
      // if the user is simply moving around blank cells
      const clickedAwayFromBlankCell = currentValue == null && rawValue === "";

      if (!clickedAwayFromBlankCell) {
        // Link Price, YTW, YTM such that if any of them are cleared then they are all cleared
        if (
          (accessor === "price" || accessor === "yieldToMaturity" || accessor === "yieldToWorst") &&
          rawValue === ""
        ) {
          if (!datum.calculatorErrors?.includes(accessor)) {
            delete datum.price;
            delete datum.yieldToMaturity;
            delete datum.yieldToWorst;
          }
        }

        if (accessor === "originalSize") {
          datum.quantity_edit = isNaN(rawValue / 1000) ? rawValue : rawValue / 1000;
        } else if (accessor === "quantity_edit") {
          datum.originalSize = isNaN(rawValue * 1000) ? rawValue : rawValue * 1000;
        }

        if (currentValue !== rawValue || !utils.hasNonEmptyValue(relatedTarget)) {
          resetErrorState();
          datum[accessor] = rawValue;
          if (accessor === "cusip") {
            var updatedRec = {
              id: -1,
              issuer: "",
              cusip: datum.cusip,
              buyerSeller: datum.buyerSeller,
              originalSize: datum.originalSize,
              quantity_edit: datum.quantity_edit,
              errors: [],
            };
            validateBond(updatedRec, tradeType);
            data[props.index] = updatedRec;
            lookupCusips(data);
          } else if (Number(currentValue) !== Number(rawValue)) {
            validateBond(datum, tradeType);
            if (accessor !== quantityField) {
              if (!datum.calculatorErrors?.includes(accessor) && !datum.errors.includes(accessor)) {
                delete datum.price;
                delete datum.yieldToMaturity;
                delete datum.yieldToWorst;
              }
              datum[accessor] = rawValue;
              datum.additionalValueKey = accessor;
            } else if (accessor === quantityField) {
              if (!datum.errors.includes(quantityField)) {
                // clear price and yield errors so that calculator can handle originalSize/quantity changes
                if (
                  datum.errors.includes(additionalValueKey) &&
                  !datum.calculatorErrors?.includes(accessor)
                ) {
                  delete datum[additionalValueKey];
                  utils.removeFromArray(datum.errors, additionalValueKey);
                }
              }
            }

            data[props.index] = { ...datum };
            updatePriceAndYield([datum.cusip]);
          }

          validateAndSetData(data);
        }
      }
    }
  };

  const updatePriceYieldNullValues = (data) => {
    const accessorsToAdjust = ["price", "yieldToMaturity", "yieldToWorst"];

    data.forEach((datum) => {
      accessorsToAdjust.forEach((a) => {
        if (!datum[a]) {
          datum[a] = ticketStatus !== ticketUtils.TICKET_STATUS.EDIT ? UNCALCULATED_VALUE : null;
        } else {
          datum[a] =
            ticketStatus === ticketUtils.TICKET_STATUS.EDIT && datum[a] === UNCALCULATED_VALUE
              ? null
              : datum[a];
        }
      });
    });
  };

  const handleAccountSelected = ({ brokeredClearingAccount, affiliateTrader, affiliateInstitution }) => {
    setBuyer({ brokeredClearingAccount, affiliateTrader, affiliateInstitution });
  };

  const hasCalculatorError = (data) => data.some((d) => d.calculatorErrors?.length);

  const validateAndSetData = (data, doNotAddEmptyRow) => {
    if (!userHasAnyPermission(user, [USER_PERMISSIONS.CAN_TRADE])) {
      errorMessages.push(Messages.MESSAGE.NOT_A_TRADER_ACCOUNT);
    } else {
      if (isOffersForm) {
        updatePriceYieldNullValues(data);
      }

      data.forEach((d) => {
        validateBond(d, tradeType, errorMessages);
      });

      if (data.length && !data.some((d) => isValidCusip(d.cusip) || d.qty)) {
        errorMessages.push("There are no valid CUSIPs to proceed with submission");
      }

      if (hasCalculatorError(data)) {
        warningMessages.push(Messages.MESSAGE.CALCULATOR_ERROR);
      }

      if (tradeType !== Constants.TRADE_TYPE.PRICE_QUOTES) {
        const hasThresholdTotal = ticketUtils.hasTotalThreshold(data);

        if (hasThresholdTotal) {
          errorMessages.push(Messages.MESSAGE.EXCEED_TOTAL_THRESHOLD);
        }
      }

      const cusips = data.filter((d) => d.id > -1 && utils.isValidCusip(d.cusip)).map((d) => d.cusip);
      data.forEach((datum) => {
        datum.duplicate = cusips.indexOf(datum.cusip) !== cusips.lastIndexOf(datum.cusip);
      });

      const hasDuplicate = data.some((d) => d.duplicate);
      if (hasDuplicate && !isOrderEdit) {
        errorMessages.push(Messages.MESSAGE.REQUEST_DUPLICATE_CUSIP[tradeType]);
      }
    }

    setInfoMessages(infoMessages);
    setWarningMessages(warningMessages);
    setErrorMessages(errorMessages);

    const hasEmptyRow = data.some((datum) => datum.id < 0);
    if (
      !hasEmptyRow &&
      !doNotAddEmptyRow &&
      !isOrderEdit &&
      ticketStatus === ticketUtils.TICKET_STATUS.EDIT
    ) {
      data.push(ticketUtils.generateRequestRecord());
    }
    setData(utils.cloneArrayOfObjects(data));
  };

  const importCusips = (bonds, additionalKey) => {
    toggleImport();
    setAdditionalValueKey(additionalKey);
    const filteredData = data.filter((datum) => utils.hasNonEmptyValue(datum.cusip.trim()));
    bonds.forEach((bond) => {
      bond = ticketUtils.readyBond(bond);
      validateBond(bond, tradeType);
      filteredData.push(bond);
    });

    lookupCusips(filteredData);
  };

  // The trade desk is going back and forth on how these disclaimers work
  // so allow us to use 2 footer styles expecting us to go to the full featured one
  // later for other sell types without too much hassle by making this dynamic.
  const DynamicSellFooter = ({ tradeType }) => {
    if (utils.hasNonEmptyValue(Messages.MESSAGE.DISCLAIMERS[tradeType])) {
      return (
        <TicketFooter
          text={Messages.MESSAGE.DISCLAIMERS[tradeType].SHORT}
          extendedText={Messages.MESSAGE.DISCLAIMERS[tradeType].EXTENDED}
          helpLinkText={Messages.LABEL.SEE_FULL_DISCLAIMER}
          helpSection="disclaimers"
          onClose={handleCloseRequestTicket}
        />
      );
    } else {
      return (
        <TicketFooter
          onClose={handleCloseRequestTicket}
          text=""
          helpLinkText={Messages.LABEL.SEE_FULL_DISCLAIMER}
          helpSection="disclaimers"
        />
      );
    }
  };

  const accountSelectionModalOpen =
    open &&
    buyer?.brokeredClearingAccount == null &&
    (user.brokeredClearingAccounts?.length > 1 || canTradeForOthers);

  return (
    <>
      <UnclosableModal
        open={open}
        header={Messages.LABEL.REQUEST[tradeType]}
        onClose={handleCloseRequestTicket}
      >
        <div className={clsx(classes.root, { [classes.offersModal]: isOffersForm })}>
          <TicketHeader
            infoMessages={infoMessages}
            warningMessages={warningMessages}
            errorMessages={errorMessages}
            cart={data}
            buyer={buyer}
            onOpenAccountSelectModal={() => {
              setBuyer(null);
            }}
            disableAccountEdit={isOrderEdit || ticketStatus !== ticketUtils.TICKET_STATUS.EDIT}
            allowTradeForOthers={canTradeForOthers}
          />
          <TicketBody ref={ref} style={{ maxHeight: "inherit" }}>
            <div
              style={{
                display: "flex",
                width: "100%",
                justifyContent: "space-between",
                alignItems: "center",
                marginTop: "0.5rem",
              }}
            >
              <div className={classes.switchRoot}>
                <span className={classes.switchLabel}>Original Size</span>
                <FormControlLabel
                  className={classes.switchWrapper}
                  control={
                    <Switch
                      size="small"
                      checked={quantityField === "quantity_edit"}
                      onChange={toggleQuantityField}
                      value="quantity_edit"
                    />
                  }
                />
                <span className={classes.switchLabel}>Quantity</span>
              </div>
              {!isOrderEdit && ticketStatus === ticketUtils.TICKET_STATUS.EDIT && (
                <div>
                  <ModalImportCusipButton onClick={toggleImport} label={Messages.LABEL.ADD_CUSIPS_TICKET} />
                </div>
              )}
              {ticketStatus === ticketUtils.TICKET_STATUS.IMPORT && (
                <div>
                  <ModalReturnButton onClick={toggleImport} label={Messages.LABEL.RETURN_TICKET} />
                </div>
              )}
            </div>

            <div>
              {utils.hasNonEmptyValue(data) && ticketStatus === ticketUtils.TICKET_STATUS.COMPLETE && (
                <SuccessMessage
                  text={
                    hasCalculatorError(data)
                      ? Messages.MESSAGE.REQUEST_SUCCESS_NEXT["TENATIVE_BONDS_FOR_SALE"]
                      : Messages.MESSAGE.REQUEST_SUCCESS_NEXT[tradeType]
                  }
                />
              )}
            </div>
            <CSSTransition
              mountOnEnter
              unmountOnExit
              timeout={{ enter: 500, exit: 100 }}
              classNames="transition-fade"
              onExited={setStatus}
              in={
                ticketStatus === ticketUtils.TICKET_STATUS.IMPORT &&
                stagedStatus === ticketUtils.TICKET_STATUS.IMPORT
              }
            >
              <div style={{ margin: "1.0rem auto" }}>
                <ImportCusips
                  quantityField={quantityField}
                  tradeType={tradeType}
                  onImport={importCusips}
                  onCancel={toggleImport}
                />
              </div>
            </CSSTransition>

            <CSSTransition
              mountOnEnter
              unmountOnExit
              timeout={{ enter: 500, exit: 100 }}
              classNames="transition-fade"
              onExited={setStatus}
              in={
                ticketStatus !== ticketUtils.TICKET_STATUS.IMPORT &&
                stagedStatus !== ticketUtils.TICKET_STATUS.IMPORT
              }
            >
              <>
                <DataTable
                  id={`selltable_${quantityField}`}
                  style={{ marginTop: 10 }}
                  queryType={Constants.QUERY_TYPE.REQUEST_TICKET}
                  querySubType={isOrderEdit ? Constants.TRADE_TYPE.BONDS_FOR_SALE_EDIT : tradeType}
                  data={data}
                  loading={postTradeState.isLoading}
                  editable={ticketStatus === ticketUtils.TICKET_STATUS.EDIT}
                  alternateView={quantityField === "quantity_edit"}
                  selectable={false}
                  focusedElementId={focusedElementId}
                  onValueChange={handleValueChange}
                  TableProps={{ className: "totals-table" }}
                />
                {utils.hasNonEmptyValue(data) && (
                  <>
                    <TicketRow style={{ marginTop: 5 }}>
                      <TicketRowSection>
                        <div style={{ marginRight: 10 }}>
                          {utils.hasNonEmptyValue(data) && (
                            <ModalMessages
                              messages={errorMessages}
                              level={Constants.ALERT_LEVEL.ERROR}
                              isFooterMessage={true}
                            />
                          )}
                          <ModalMessages
                            messages={warningMessages}
                            level={Constants.ALERT_LEVEL.WARNING}
                            isFooterMessage={true}
                          />
                        </div>
                      </TicketRowSection>
                      <TicketRowSection>
                        <div style={{ display: "flex", justifyContent: "flex-end" }}>
                          {ticketStatus !== ticketUtils.TICKET_STATUS.COMPLETE && (
                            <TicketButton onClick={handleCloseRequestTicket} label={Messages.LABEL.CANCEL} />
                          )}
                          {error === ticketUtils.ERROR.LOOKUP_FAIL && (
                            <TicketButton onClick={handleRetryLookup} label={Messages.LABEL.RETRY} />
                          )}
                          {error === ticketUtils.ERROR.SUBMIT_FAIL && (
                            <TicketButton onClick={handleSubmitRequest} label={Messages.LABEL.RETRY} />
                          )}
                          {ticketStatus === ticketUtils.TICKET_STATUS.EDIT &&
                            error === ticketUtils.ERROR.NONE && (
                              <TicketButton
                                disabled={!readyToPreview()}
                                onClick={enablePreview}
                                label={Messages.LABEL.PREVIEW_REQUEST}
                                color="primary"
                              />
                            )}
                          {ticketStatus === ticketUtils.TICKET_STATUS.PREVIEW &&
                            error === ticketUtils.ERROR.NONE && (
                              <>
                                <TicketButton
                                  onClick={enableEditing}
                                  label={Messages.LABEL.EDIT_REQUEST}
                                  color="primary"
                                />
                                <TicketButton
                                  onClick={handleSubmitRequest}
                                  label={
                                    isOrderEdit ? Messages.LABEL.SUBMIT_UPDATE : Messages.LABEL.PLACE_REQUEST
                                  }
                                  color="primary"
                                />
                              </>
                            )}
                          {ticketStatus === ticketUtils.TICKET_STATUS.COMPLETE && (
                            <TicketButton onClick={handleCloseRequestTicket} label={Messages.LABEL.CLOSE} />
                          )}
                        </div>
                        <div style={{ marginTop: 10, marginLeft: 10 }}>
                          {ticketStatus === ticketUtils.TICKET_STATUS.EDIT && (
                            <TextAreaInput
                              style={{ alignItems: "flex-end", minWidth: 400 }}
                              rows={2}
                              value={userMessage}
                              onChange={(event) => {
                                setUserMessage(event.currentTarget.value);
                              }}
                              placeholder={Messages.LABEL.USER_MESSAGE}
                            />
                          )}
                          {ticketStatus !== ticketUtils.TICKET_STATUS.EDIT && (
                            <UserMessage text={userMessage} />
                          )}
                        </div>
                      </TicketRowSection>
                    </TicketRow>
                  </>
                )}
              </>
            </CSSTransition>
          </TicketBody>

          <DynamicSellFooter tradeType={tradeType} />
        </div>
      </UnclosableModal>
      <UnclosableModal open={utils.hasNonEmptyValue(failures)}>
        <FailedRequests
          failures={failures}
          handleCloseFailedBondsModal={(e) => setFailures([])}
          tradeType={tradeType}
          altView={quantityField === "quantity_edit"}
        />
      </UnclosableModal>
      <AccountSelectionModal
        allowTradeForOthers={canTradeForOthers}
        onSelect={handleAccountSelected}
        open={accountSelectionModalOpen}
      />
    </>
  );
};

const FailedRequests = ({ failures, handleCloseFailedBondsModal, tradeType, altView }) => {
  const Messages = useMessages();
  return (
    <>
      <ModalMessages messages={[Messages.MESSAGE.REQUEST_FAIL]} level={Constants.ALERT_LEVEL.ERROR} />

      <DataTable
        style={{ marginTop: 10 }}
        queryType={Constants.QUERY_TYPE.REQUEST_TICKET_FAILURES}
        querySubType={tradeType}
        alternateView={altView}
        data={failures}
        editable={false}
        selectable={false}
        TableProps={{ className: "datatable-plain" }}
      />

      <>
        <Spacer />
        <TicketRow>
          <TicketRowSection></TicketRowSection>
          <TicketRowSection>
            <TicketButton onClick={handleCloseFailedBondsModal} label={Messages.LABEL.CLOSE} />
          </TicketRowSection>
        </TicketRow>
      </>
    </>
  );
};

RequestTicket.propTypes = {
  open: PropTypes.bool.isRequired,
  tradeType: PropTypes.oneOf(Object.values(Constants.TRADE_TYPE)),
  bondsInRequest: PropTypes.arrayOf(PropTypes.object),
  onCloseRequestTicket: PropTypes.func.isRequired,
};

export const AddRequestButton = (props) => {
  const { onClick, label } = props;

  return (
    <ActionButton onClick={onClick} variant="outlined" color="secondary" size="small">
      {label}
    </ActionButton>
  );
};

AddRequestButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
};

const StyledSellButton = withStyles((theme) => ({
  root: {
    minWidth: 40,
    padding: "4px",
    fontSize: "0.6rem",
    lineHeight: 1,
    color: theme.palette.text.selected.secondary,
    backgroundColor: theme.palette.background.button.secondary,
    "&:hover": {
      backgroundColor: alpha(theme.palette.background.button.secondary, 0.8),
      color: theme.palette.text.selected.secondary,
    },
    "&.MuiButton-outlined.Mui-disabled": {
      border: "1px solid",
      borderColor: theme.palette.border.disabled,
      color: theme.palette.text.unselected.disabled,
      backgroundColor: alpha(theme.palette.background.button.disabledsecondary, 0.3),
    },
  },
}))(Button);

export const SellButton = (props) => {
  const { onClick, disabled, label, tooltip, ...otherProps } = props;

  return (
    <StyledSecondaryTooltip title={tooltip || label}>
      <span>
        <StyledSellButton
          onClick={onClick}
          disabled={disabled}
          variant="outlined"
          color="secondary"
          size="small"
          {...otherProps}
        >
          {label}
        </StyledSellButton>
      </span>
    </StyledSecondaryTooltip>
  );
};

SellButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  tooltip: PropTypes.string,
  disabled: PropTypes.bool,
};
