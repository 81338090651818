import React from "react";
import { get } from "lodash";
import PropTypes from "prop-types";

import { useDataService } from "services/DataService";
import { useViewState, useViewStateDispatcher } from "providers/ViewStateProvider";
import * as Constants from "commons/constants";
import * as queryUtils from "commons/queryUtils";
import YieldCurve from "components/YieldCurve";
import DataTable from "components/datatable/DataTable";
import { Div } from "components/containers/Containers";
import useAutoRefresh from "hooks/useAutoRefresh";

const RateBoard = ({ setRatesRefreshedTime, tool = Constants.TOOL_TYPE.RATES }) => {
  const viewState = useViewState();
  const dispatchViewStateChange = useViewStateDispatcher();
  const [state, queryDataService] = useDataService();
  const [data, setData] = React.useState([]);

  const currentViewState = get(viewState, Constants.VIEW.RATES, {});
  const boardType = currentViewState.boardType || Constants.RATES_TYPE.HIGHEST;

  React.useEffect(() => {
    queryData(true);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [boardType]);

  React.useEffect(() => {
    if (currentViewState.searchId) {
      queryData(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentViewState.searchId]);

  React.useEffect(() => {
    if (state.refreshedTime) {
      setRatesRefreshedTime(state.refreshedTime);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.refreshedTime]);

  React.useEffect(() => {
    if (!state.isLoading && !state.isError) {
      if (data?.length && state.data?.length) {
        state.data.forEach((datum) => {
          const bondType = datum.bondType.value;
          const comparisonDatum = data.find((row) => row.bondType.value === bondType);
          if (comparisonDatum) {
            Object.keys(datum)
              .filter((key) => key !== "bondType" && datum[key].value)
              .forEach((key, i) => {
                if (datum[key].value !== comparisonDatum[key].value) {
                  datum[key].valueChanged = true;
                }
              });
          }
        });
      }
    }

    setData(state.data);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state]);

  const queryData = (clear, autorefresh) => {
    const queryParams = {
      type: Constants.QUERY_TYPE.RATES,
      searchId: new Date().getTime(),
      suppressLoading: !clear,
      clearDataOnFetch: clear,
      filter: {
        boardType: boardType,
      },
    };

    queryDataService(queryParams, { autorefresh });
  };

  useAutoRefresh(() => queryData(false, true), false, 10000);

  const handleCurrentViewStateChange = (state) => {
    dispatchViewStateChange({ state: state, view: Constants.VIEW.RATES });
  };

  const handleCellNav = (queryString) => {
    const query = queryUtils.getInventoryQueryFromQueryString(queryString);
    query.referrer = Constants.VIEW.RATES;

    dispatchViewStateChange({
      state: { query: query, activeView: Constants.VIEW.INVENTORY },
      view: Constants.VIEW.INVENTORY,
      subView: Constants.SUBVIEW.BUY,
      querySubType: get(query, "filter.instrumentCategory"),
    });
  };

  if (tool === Constants.TOOL_TYPE.RATES) {
    return (
      <Div className="inlineRateTable" style={{ display: "flex", paddingBottom: 10 }}>
        <DataTable
          queryType={Constants.QUERY_TYPE.RATES}
          querySubType={boardType}
          selectable={false}
          data={data}
          loading={state.isLoading && !state.query.suppressLoading}
          onViewStateChange={handleCurrentViewStateChange}
          onCellNavigation={handleCellNav}
        />
      </Div>
    );
  } else if (tool === Constants.TOOL_TYPE.YIELD_CURVE) {
    return (
      <YieldCurve
        data={data}
        onViewStateChange={handleCurrentViewStateChange}
        onCellNavigation={handleCellNav}
        loading={state.isLoading && !state.query.suppressLoading}
      />
    );
  } else {
    return null;
  }
};

RateBoard.propTypes = {
  setRatesRefreshedTime: PropTypes.func.isRequired,
};

export default RateBoard;
