import { assign } from "lodash";

import { Common } from "./Common";
import image from "assets/img/sidebar-bg.jpg";

import { createTheme } from "@material-ui/core/styles";

const darkTheme = {
  palette: {
    type: "dark",
    primary: {
      main: "#329fff",
      dark: "#329fff",
    },
    secondary: {
      main: "#00c109",
      dark: "#00c109",
    },
    tertiary: {
      main: "#00c109",
    },
    purple: {
      main: "#e711dd",
    },
    amber: {
      main: "#ffc107",
    },

    error: {
      main: "#d02e22",
    },
    border: {
      main: "rgba(129, 129, 129, 0.2)",
      highlight: "rgba(129, 129, 129, 0.6)",
      textInput: "rgba(255, 255, 255, 0.1)",
      disabled: "rgba(255, 255, 255, 0.1)",
      subtle: "rgba(255, 255, 255, 0.05)",
      drawer: "#282828",
      primary: "rgba(50, 159, 255, 0.5)",
      secondary: "rgba(0, 193, 9, 0.5)",
      warning: "#dac20e",
      table: "rgba(44, 68, 90, 0.8)",
      axis: "rgba(44, 68, 90, 1.0)",
    },
    logo: {
      main: "#00b0f0",
    },
    text: {
      primary: "rgba(255, 255, 255, 0.87)",
      secondary: "rgba(180, 180, 180, 0.87)",
      tertiary: "rgba(158, 158, 162, 0.87)", //'rgba(255, 255, 255, 0.75)',
      disabled: "rgba(255, 255, 255, 0.2)",
      textInput: "#cdcdcd",
      warning: "#dac20e",
      snackbar: "rgba(0, 0, 0, 0.87)",
      unselected: {
        primary: "rgba(50, 159, 255, 0.87)",
        secondary: "rgba(255, 255, 255, 0.6)",
        disabled: "rgba(255, 255, 255, 0.2)",
      },
      selected: {
        primary: "rgba(50, 159, 255, 0.87)",
        secondary: "rgba(0, 193, 9, 0.87)",
      },
      icon: "rgba(50, 159, 255, 0.87)",
      popper: {
        header: "rgba(158, 158, 162, 0.87)",
        text: "rgba(255, 255, 255, 0.87)",
      },
    },
    background: {
      default: "#16161D",
      main: "#16161D",
      drawer: "rgba(0, 0, 0, 0.8)",
      paperButton: "#222",
      paperForm: "#222",
      paper: "#2a2a2a",
      popperPaper: "#404040",
      popperPaperLight: "#555555",
      textInput: "rgba(255, 255, 255, 0.07)",
      snackbar: "#eaeaea",
      shade: "rgba(255, 255, 255, 0.05)",
      hover: "rgba(255, 255, 255, 0.075)",
      menuhover: "rgba(255, 255, 255, 0.1)",
      selected: "rgba(255, 255, 255, 0.1)",
      subtle: "rgba(255, 255, 255, 0.03)",
      header: "#16161D",
      badge: "#007CAB",
      cartbadge: "#ff6A00",
      disabled: "rgba(255, 255, 255, 0.05)",
      obfuscatedBackdrop: "rgba(0,0,0, 0.8)",
      tooltip: {
        primary: "#007CAB",
        secondary: "#027b07",
      },
      button: {
        primary: "#0c2f4e",
        disabledprimary: "#0c2f4e",
        disabledprimarysubtle: "#0c2f4e",
        secondary: "#05411c",
        disabledsecondary: "#05411c",
        buy: "#0c2f4e",
      },
    },
  },
  sidebar: {
    backgroundImage: image,
  },
};

assign(darkTheme, Common);

export const DarkTheme = createTheme(darkTheme);
