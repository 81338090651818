import React from "react";
import PropTypes from "prop-types";
import { Formik } from "formik";
import * as Yup from "yup";

import { useMessages } from "providers/BrandingProvider";
import * as utils from "commons/utils";
import { FormTextField, FormSelect } from "components/forms/FormikFields";
import {
  ModalForm,
  ModalFormFieldset,
  ModalFormLinkButtonset,
  ModalSubmitButton,
} from "../common/FormComponents";
import { LinkButton } from "components/Controls";
import { useOptionsService } from "services/OptionsService";

import EmailOutlinedIcon from "@material-ui/icons/EmailOutlined";
import VpnKeyOutlinedIcon from "@material-ui/icons/VpnKeyOutlined";
import AccountCircleOutlinedIcon from "@material-ui/icons/AccountCircleOutlined";
import PhoneOutlinedIcon from "@material-ui/icons/PhoneOutlined";

import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  adornment: {
    fontSize: "1.0rem",
    color: theme.palette.primary.main,
  },
}));

const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

const NewAccountForm = ({ onSuccess, onLoginClick }) => {
  const classes = useStyles();
  const Messages = useMessages();
  const [institutionOptions, setInstitutionOptions] = React.useState([]);
  const [options, setOptionKey] = useOptionsService();

  React.useEffect(() => {
    setOptionKey("newusersignupinvestortypes");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    if (utils.hasNonEmptyValue(options)) {
      const processedOptions = options.map((o) => {
        return { id: o.id, name: o.description };
      });
      processedOptions.unshift({ id: "-1", name: "Select one" });
      setInstitutionOptions(processedOptions);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [options]);

  const NewAccountSchema = Yup.object().shape({
    firstname: Yup.string().required(Messages.MESSAGE.VALIDATION.REQUIRED),
    lastname: Yup.string().required(Messages.MESSAGE.VALIDATION.REQUIRED),
    password: Yup.string()
      .min(8, Messages.MESSAGE.VALIDATION.PASSWORD_MINIMUM)
      .required(Messages.MESSAGE.VALIDATION.REQUIRED),
    email: Yup.string()
      .email(Messages.MESSAGE.VALIDATION.INVALID_FORMAT)
      .required(Messages.MESSAGE.VALIDATION.REQUIRED),
    phonenumber: Yup.string()
      .matches(phoneRegExp, Messages.MESSAGE.VALIDATION.INVALID_FORMAT)
      .required(Messages.MESSAGE.VALIDATION.REQUIRED),
    institutiontype: Yup.string().required(Messages.MESSAGE.VALIDATION.REQUIRED),
  });

  return (
    <Formik
      initialValues={{
        firstname: "",
        lastname: "",
        email: "",
        phonenumber: "",
        password: "",
        institutiontype: "-1",
      }}
      validationSchema={NewAccountSchema}
      onSubmit={(values) => {
        onSuccess();
      }}
    >
      {({ errors, touched }) => (
        <ModalForm>
          <ModalFormFieldset>
            <FormTextField
              placeholder={Messages.FORM_LABEL.FIRSTNAME}
              name="firstname"
              autoFocus={true}
              startAdornment={<AccountCircleOutlinedIcon className={classes.adornment} />}
            />
            <FormTextField
              placeholder={Messages.FORM_LABEL.LASTNAME}
              name="lastname"
              startAdornment={<AccountCircleOutlinedIcon className={classes.adornment} />}
            />
            <FormTextField
              placeholder={Messages.FORM_LABEL.EMAIL}
              name="email"
              type="email"
              startAdornment={<EmailOutlinedIcon className={classes.adornment} />}
            />
            <FormTextField
              placeholder={Messages.FORM_LABEL.PHONE}
              name="phonenumber"
              type="tel"
              startAdornment={<PhoneOutlinedIcon className={classes.adornment} />}
            />
            <FormSelect
              label={Messages.FORM_LABEL.INSTITUTION}
              dataSource={institutionOptions}
              name="institutiontype"
            />
            <FormTextField
              placeholder={Messages.FORM_LABEL.PASSWORD}
              name="password"
              type="password"
              startAdornment={<VpnKeyOutlinedIcon className={classes.adornment} />}
            />
            <FormTextField
              placeholder={Messages.FORM_LABEL.PASSWORD}
              name="password"
              type="password"
              startAdornment={<VpnKeyOutlinedIcon className={classes.adornment} />}
            />
            <FormTextField
              placeholder={Messages.FORM_LABEL.PASSWORD}
              name="password"
              type="password"
              startAdornment={<VpnKeyOutlinedIcon className={classes.adornment} />}
            />
          </ModalFormFieldset>
          <ModalFormLinkButtonset>
            {onLoginClick && (
              <LinkButton onClick={onLoginClick} variant="form" label={Messages.LABEL.LOG_INTO_ACCOUNT} />
            )}
          </ModalFormLinkButtonset>
          <ModalSubmitButton label={Messages.LABEL.SUBMIT} />
        </ModalForm>
      )}
    </Formik>
  );
};

NewAccountForm.propTypes = {
  onSuccess: PropTypes.func.isRequired,
  onLoginClick: PropTypes.func,
};

export default NewAccountForm;
