import React from "react";
import PropTypes from "prop-types";

import { useMessages } from "providers/BrandingProvider";
import { setPreference } from "services/PreferencesService";
import { TicketBody, TicketRow } from "components/modals//tickets/TicketComponents";
import { ClosableModal } from "components/containers/Modals";
import QBTypography from "components/QBTypography";
import { FormOutlinedButton } from "components/Controls";

import KeyboardBackspaceIcon from "@material-ui/icons/KeyboardBackspace";
import CartIcon from "@material-ui/icons/Receipt";
import Backdrop from "@material-ui/core/Backdrop";
import { makeStyles, withStyles } from "@material-ui/core/styles";

const BlinkingArrowIcon = withStyles((theme) => ({
  "@keyframes blinker": {
    "0%": { opacity: 0.3 },
    "60%": { opacity: 1.0 },
  },
  root: {
    animationName: "$blinker",
    animationDuration: "1500ms",
    animationTimingFunction: "ease-in-out",
    animationIterationCount: "infinite",
    fontSize: 128,
    position: "absolute",
    top: 40,
    left: 54,
  },
}))(KeyboardBackspaceIcon);

const useStyles = makeStyles((theme) => ({
  root: {
    padding: "30px 20px",
    marginBottom: 20,
    minWidth: 400,
    maxWidth: 500,
    border: "1px solid",
    borderColor: theme.palette.border.subtle,
    borderRadius: theme.shape.borderRadius,
    background: theme.palette.background.subtle,
  },
}));

const CartDemoBackdrop = () => {
  return (
    <Backdrop open={true} style={{ color: "#fff" }}>
      <BlinkingArrowIcon />
    </Backdrop>
  );
};

const DemoCartDialog = ({ isOpen, onClose, onOpenTradeTicket }) => {
  const classes = useStyles();
  const Messages = useMessages();

  React.useEffect(() => {
    if (isOpen) {
      setPreference("cartdemoseen", true);
    }
  }, [isOpen]);

  const handleClose = () => {
    onClose();
  };

  const handleOpenTradeTicket = () => {
    onClose();
    onOpenTradeTicket();
  };

  return (
    <ClosableModal open={isOpen} onClose={onClose} BackdropComponent={CartDemoBackdrop}>
      <TicketBody>
        <div className={classes.root}>
          <QBTypography>{Messages.MESSAGE.CART_DEMO_MESSAGE}</QBTypography>
          <QBTypography>
            You can always access your current ticket by clicking the{" "}
            <CartIcon style={{ opacity: 0.3, marginTop: 10, marginBottom: -3 }} /> icon on the left sidebar.
          </QBTypography>
        </div>
        <TicketRow style={{ justifyContent: "center" }}>
          <FormOutlinedButton style={{ margin: "0 0.5rem" }} onClick={handleOpenTradeTicket}>
            {Messages.LABEL.PROCEED_TO_CART}
          </FormOutlinedButton>
          <FormOutlinedButton style={{ margin: "0 0.5rem" }} onClick={handleClose}>
            {Messages.LABEL.CONTINUE_SHOPPING}
          </FormOutlinedButton>
        </TicketRow>
      </TicketBody>
    </ClosableModal>
  );
};

DemoCartDialog.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func,
  onOpenTradeTicket: PropTypes.func,
};

export default DemoCartDialog;
