import React from "react";
import PropTypes from "prop-types";

import FormGroup from "@material-ui/core/FormGroup";
import ToggleButtonFilter from "components/filters/ToggleButtonFilter";

import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  headerWrapper: {
    height: 28,
    width: "100%",
    display: "flex",
    alignItems: "center",
    borderBottom: "1px solid",
    borderBottomColor: theme.palette.border.main,
  },
  header: {
    margin: "auto 2px",
    textTransform: "uppercase",
    color: theme.palette.text.popper.header,
  },
  formControl: {
    marginTop: 2,
    paddingTop: 6,
  },
  sectionWrapper: {
    margin: 5,
    marginBottom: 10,
  },
}));

const ToggleButtonFilterGroup = (props) => {
  const { filterConfigs, filters, header, onFilterChange } = props;
  const classes = useStyles();

  return (
    <React.Fragment>
      {header && (
        <div className={classes.headerWrapper}>
          <span className={classes.header}>{header}</span>
        </div>
      )}
      <FormGroup classes={{ root: classes.formControl }}>
        {filterConfigs.map((config, i) => {
          return (
            <div key={config.accessor} className={classes.sectionWrapper}>
              <ToggleButtonFilter
                label={config.filterlabel || config.label}
                optionsKey={config.filter.optionsKey}
                filterKey={config.accessor}
                filter={filters[config.accessor]}
                onFilterChange={onFilterChange}
              />
            </div>
          );
        })}
      </FormGroup>
    </React.Fragment>
  );
};

ToggleButtonFilterGroup.propTypes = {
  filters: PropTypes.object.isRequired,
  filterConfigs: PropTypes.arrayOf(PropTypes.object).isRequired,
  header: PropTypes.string,
  onFilterChange: PropTypes.func.isRequired,
};

export default ToggleButtonFilterGroup;
