import React from "react";
import PropTypes from "prop-types";
import { get } from "lodash";

import * as utils from "commons/utils";
import QBTypography from "components/QBTypography";
import ThemeToggle from "components/ThemeToggle";
import { LinkButton } from "components/Controls";
import RequestInfo from "components/modals/RequestInfo";
import { useViewState } from "providers/ViewStateProvider";
import { useMessages, useBranding } from "providers/BrandingProvider";
import { logoutUser } from "commons/helpers/userStorage";
import RateFreshnessTime from "components/RateFreshnessTime";

import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  banner: {
    display: "flex",
    marginLeft: 8,
    alignItems: "center",
    justifyContent: "space-between",
    height: 40,
  },
  bannerSection: {
    display: "flex",
    margin: "auto 0",
    justifyContent: "center",
    alignItems: "center",
    flexWrap: "nowrap",
    flexBasis: "33.33%",
    "&:first-child": {
      justifyContent: "flex-start",
    },
    "&:last-child": {
      justifyContent: "flex-end",
    },
  },
  header: {
    margin: "4px 10px 0 10px",
    whiteSpace: "nowrap",
  },
}));

const ApplicationHeader = ({ inventoryRefreshedTime }) => {
  const classes = useStyles();
  const viewState = useViewState();
  const Messages = useMessages();
  const branding = useBranding();

  const activeView = get(viewState, "activeView");
  const activeSubView = get(viewState, [activeView, "activeSubView"]);

  return (
    <div className={classes.banner}>
      <div className={classes.bannerSection}>
        <img src={`/logos/${branding.logo?.application_header}`} alt={Messages.BRANDING.INSTITUTION_NAME} />
        <QBTypography className={classes.header} variant="h1" component="div">
          {viewState && viewState.activeView
            ? Messages.LABEL[viewState.activeView]
            : Messages.APPLICATION_NAME}
        </QBTypography>
      </div>
      <div className={classes.bannerSection}>
        {utils.isFreshnessSensitiveView(activeView, activeSubView) && (
          <RateFreshnessTime
            label={Messages.LABEL.PRICES_CURRENT}
            refreshedTime={inventoryRefreshedTime}
            key="inventory_clock"
          />
        )}
      </div>

      <div className={classes.bannerSection}>
        <div style={{ display: "flex", alignItems: "center" }}>
          <QBTypography
            component="div"
            variant="body1"
            style={{ whiteSpace: "nowrap", marginLeft: 5, marginRight: 5 }}
          >
            <span>{Messages.LABEL.TRADE_DESK}</span>
            <span>{Messages.LABEL.TRADE_DESK_PHONE}</span>
          </QBTypography>
          <RequestInfo />
        </div>
        <ThemeToggle style={{ margin: "0 20px" }} />
        {branding.externalUrl && (
          <LinkButton
            color="primary"
            onClick={(e) => window.open(branding.externalUrl)}
            label={Messages.LABEL.EXTERNAL_LINK_TEXT}
          />
        )}
        <LinkButton color="primary" onClick={logoutUser} label="Logout" />
      </div>
    </div>
  );
};

ApplicationHeader.propTypes = {
  inventoryRefreshedTime: PropTypes.string,
};

export default ApplicationHeader;
