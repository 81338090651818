import React from "react";
import PropTypes from "prop-types";
import { get, cloneDeep, pickBy, keys, isEqual } from "lodash";
import { useGlobal } from "reactn";
import { setPreference } from "services/PreferencesService";

import * as utils from "commons/utils";
import * as filterUtils from "commons/filterUtils";
import { useDataService } from "services/DataService";
import { useViewState, useViewStateDispatcher } from "providers/ViewStateProvider";
import * as Constants from "commons/constants";
import { useMessages } from "providers/BrandingProvider";
import * as DataConfig from "config/dataconfig";
import DataTable from "components/datatable/DataTable";
import TableToolbar from "components/toolbars/TableToolbar";
import ImportIssuers from "components/modals/ImportIssuers";
import { MainPaperWithHeader, Div } from "components/containers/Containers";
import { LabelledIconButton } from "components/Controls";

import AddIcon from "@material-ui/icons/Add";
import EmailFrequency from "components/EmailFrequency";
import useAutoRefresh from "hooks/useAutoRefresh";

const topLevelProperties = ["cusip", "issuer", "insuranceNumber", "enteredUser"];

const PortfolioTrackerView = ({ onAddBondsToCart, onOpenTradeTicket, setInventoryRefreshedTime }) => {
  const Messages = useMessages();
  const viewState = useViewState();
  const dispatchViewStateChange = useViewStateDispatcher();
  const currentViewState = get(viewState, Constants.VIEW.ISSUER_TRACKER, {});

  const [showImportModal, setShowImportModal] = React.useState(false);
  const [totalNumberRows, setTotalNumberRows] = React.useState();

  const [favoriteIssuers = []] = useGlobal("favoriteIssuers");
  const [userprefs = {}] = useGlobal("userprefs");

  const activeInstrumentCategory = get(currentViewState, "activeSubView", "CD");
  const subViewState = get(currentViewState, activeInstrumentCategory, {});
  const searchId = subViewState?.query?.searchId;

  const defaultQuantityPrefsPath = `issuerTracker.${activeInstrumentCategory}.defaultQuantity`;

  const selectedBond = get(subViewState, "selectedBond", {});

  const [state, queryDataService] = useDataService();
  const [queryState, setQueryState] = React.useState([]);
  const [dataCache, setDataCache] = React.useState([]);
  const [data, setData] = React.useState([]);

  const [lastTopLevelSort, setLastTopLevelSort] = React.useState();

  const activeInstrumentKeys = keys(
    pickBy(Constants.INSTRUMENT_CATEGORY, (f) => f === activeInstrumentCategory),
  ).map((k) => Number(k));

  const favoriteIssuerCusips = favoriteIssuers
    ? Object.values(favoriteIssuers)
        .filter((f) => {
          return (
            utils.hasNonEmptyValue(f.instrumentTypes) &&
            f.instrumentTypes.some((t) => activeInstrumentKeys.includes(t.id))
          );
        })
        .map((f) => f.cusip)
    : [];

  React.useEffect(() => {
    handleCurrentViewStateChange({ activeView: Constants.VIEW.ISSUER_TRACKER });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    if (state.refreshedTime) {
      setInventoryRefreshedTime(state.refreshedTime);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.refreshedTime]);

  React.useEffect(() => {
    queryData({ refresh: false });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(favoriteIssuerCusips), activeInstrumentCategory]);

  React.useEffect(() => {
    if (searchId) {
      queryData({ reloadId: searchId, refresh: true });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchId]);

  const queryData = ({ refresh, reloadId }) => {
    const queryParams = {
      type: Constants.QUERY_TYPE.ISSUER_TRACKER,
      page: 0,
      size: 500,
      sort: [{ id: "cusip", desc: false }],
      issuers: favoriteIssuerCusips,
      filter: {
        instrumentCategory: activeInstrumentCategory,
      },
      suppressLoading: refresh,
      clearDataOnFetch: !refresh,
      reloadId,
    };

    queryDataService(queryParams);
  };

  const refreshData = () => {
    queryData({ refresh: true });
  };

  useAutoRefresh(refreshData);

  React.useEffect(() => {
    if (subViewState?.query) {
      if (subViewState.query.filter) {
        let quantity = 0;
        if (subViewState.query.filter.hasOwnProperty("quantity")) {
          quantity = subViewState.query.filter.quantity.filterValue[0];
        }

        if (get(userprefs, defaultQuantityPrefsPath, 0) !== quantity) {
          setPreference(defaultQuantityPrefsPath, quantity);
        }
      }

      applyFiltersAndSort(dataCache, subViewState.query);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(subViewState)]);

  React.useEffect(() => {
    if (!state.isLoading && !state.isError & !state.isInit) {
      state.data = state.data.filter((d) => favoriteIssuers.hasOwnProperty(d.cusip));

      state.data.forEach((d) => {
        d.enteredUser = favoriteIssuers[d.cusip].enteredUser;
        d.issuerInventory = d.issuerInventory.map((rec) => {
          const mergedRec = { ...favoriteIssuers[d.cusip], ...rec };
          return mergedRec;
        });
      });

      if (!isEqual(JSON.stringify(state.data), JSON.stringify(dataCache))) {
        setDataCache(cloneDeep(state.data));
      }

      if (state.query?.filter) {
        const userPrefQuantity = get(userprefs, defaultQuantityPrefsPath, 0);
        if (!state.query.filter.quantity && userPrefQuantity > 0) {
          state.query.filter.quantity = {
            filterKey: "quantity",
            filterType: Constants.FILTER_TYPE.RANGE,
            filterValue: [get(userprefs, defaultQuantityPrefsPath, 0)],
          };
        }
      }

      if (subViewState?.query) {
        applyFiltersAndSort(state.data, subViewState.query);
      } else {
        applyFiltersAndSort(state.data, state.query);
      }
    }

    setQueryState(state);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state]);

  const applyFiltersAndSort = (rawData, query) => {
    let filteredSortedData = cloneDeep(rawData);

    if (query.filter) {
      Object.keys(query.filter).forEach((f) => {
        const filter = query.filter[f];
        if (filter.filterKey && filter.filterKey !== "instrumentCategory") {
          filteredSortedData.forEach((r, i) => {
            const rec = filteredSortedData[i];

            rec.issuerInventory = r.issuerInventory.filter((i) => {
              i._original = { ...i };
              return filterUtils.filter({ id: filter.filterKey, value: filter }, i);
            });
            rec.inventorySize = rec.issuerInventory.length;

            filteredSortedData[i] = rec;
          });
        }
      });
    }

    filteredSortedData.forEach((d) => {
      d.hasInventory = d.inventorySize > 0 ? 1 : 0;
    });

    let topLevelSorts = [{ id: "hasInventory", desc: true }];
    let isTopLevelSort = true;

    if (query.sort) {
      const currentSorts = query.sort;

      isTopLevelSort = topLevelProperties.includes(currentSorts[0].id);

      if (isTopLevelSort) {
        topLevelSorts.push(currentSorts[0]);
        setLastTopLevelSort(currentSorts[0]);
      } else if (utils.hasNonEmptyValue(lastTopLevelSort)) {
        topLevelSorts.push(lastTopLevelSort);
      }
    }

    filteredSortedData = utils.sortData(filteredSortedData, topLevelSorts);
    if (!isTopLevelSort) {
      filteredSortedData.forEach((d, i) => {
        filteredSortedData[i].issuerInventory = utils.sortData(d.issuerInventory, query.sort);
      });
    }

    const totalNumberRows = filteredSortedData.length;
    filteredSortedData = utils.pageData(filteredSortedData, query.page, DataConfig.DEFAULT_PAGE_SIZE);

    if (!isEqual(JSON.stringify(data), JSON.stringify(filteredSortedData))) {
      setTotalNumberRows(totalNumberRows);
      setData(filteredSortedData);
    }
  };

  const handleNavOptionSelected = (querySubType) => {
    dispatchViewStateChange({ state: {}, view: Constants.VIEW.ISSUER_TRACKER, subView: querySubType });
  };

  const handleCurrentViewStateChange = (state, querySubType) => {
    if (!querySubType) {
      dispatchViewStateChange({ state: state, view: Constants.VIEW.ISSUER_TRACKER });
    } else {
      dispatchViewStateChange({
        state: state,
        view: Constants.VIEW.ISSUER_TRACKER,
        subView: querySubType,
      });
    }
  };

  const handleOpenImportModal = () => {
    setShowImportModal(true);
  };

  const handleCloseImportModal = (importedIssuers) => {
    if (utils.hasNonEmptyValue(importedIssuers)) {
      const recWithInstrumentType = importedIssuers.find((i) => utils.hasNonEmptyValue(i.instrumentType));
      if (recWithInstrumentType) {
        const category = Constants.INSTRUMENT_CATEGORY[recWithInstrumentType.instrumentType.id];
        dispatchViewStateChange({ state: {}, view: Constants.VIEW.ISSUER_TRACKER, subView: category });
      }
    }
    setShowImportModal(false);
  };

  const additionalItems = [
    <div style={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
      <EmailFrequency style={{ marginLeft: "0.4rem" }} prefKey="issuers" />
      <LabelledIconButton
        key="importIssuerButton"
        onClick={handleOpenImportModal}
        variant="outlined"
        color="primary"
        startIcon={<AddIcon />}
      >
        {Messages.LABEL.ADD_ISSUERS_TO_TRACKER}
      </LabelledIconButton>
    </div>,
  ];

  const headerComp = (
    <TableToolbar
      additionalItems={additionalItems}
      view={Constants.VIEW.ISSUER_TRACKER}
      subView={Constants.SUBVIEW.BUY}
      selectedNavOption={activeInstrumentCategory}
      onNavOptionSelected={handleNavOptionSelected}
    />
  );

  return (
    <Div>
      <MainPaperWithHeader headerItems={headerComp}>
        <DataTable
          manual={true}
          unfilteredData={dataCache}
          totalNumberRows={totalNumberRows}
          queryType={Constants.QUERY_TYPE.ISSUER_TRACKER}
          querySubType={activeInstrumentCategory}
          instrumentCategory={activeInstrumentCategory}
          data={data}
          loading={queryState.isLoading && !queryState.query.suppressLoading}
          error={queryState.isError}
          selectable={false}
          selectedBond={selectedBond}
          autoSelectFirstBond={false}
          onViewStateChange={handleCurrentViewStateChange}
          onAddBondsToCart={onAddBondsToCart}
          onOpenTradeTicket={onOpenTradeTicket}
          determineDisabledRow={(r) => r.inventorySize === 0}
        />
      </MainPaperWithHeader>
      <ImportIssuers open={showImportModal} onCloseModal={handleCloseImportModal} />
    </Div>
  );
};

PortfolioTrackerView.propTypes = {
  onAddBondsToCart: PropTypes.func.isRequired,
  onOpenTradeTicket: PropTypes.func.isRequired,
  setInventoryRefreshedTime: PropTypes.func.isRequired,
};

export default PortfolioTrackerView;
