import React from "react";

import QBTypography from "components/QBTypography";
import { useMessages, useBranding } from "providers/BrandingProvider";

import CircularProgress from "@material-ui/core/CircularProgress";
import Button from "@material-ui/core/Button";
import RequestInfo from "components/modals/RequestInfo";

const ApplicationLoading = ({ text, hasError }) => {
  const Messages = useMessages();
  const branding = useBranding();

  return (
    <div
      style={{
        height: "100vh",
        display: "flex",
        flexGrow: 1,
        flexFlow: "column wrap",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <div style={{ marginBottom: "3.0rem" }}>
        <img src={`/logos/${branding.logo?.splash}`} alt={Messages.BRANDING.INSTITUTION_NAME} />
      </div>
      {!hasError && (
        <>
          <div style={{ marginBottom: "1.0rem" }}>
            <CircularProgress color="secondary" />
          </div>
          <QBTypography>{text}</QBTypography>
        </>
      )}
      {hasError && (
        <>
          <QBTypography style={{ marginBottom: "1.0rem" }} color="error">
            The application has experienced an error.
            {text}
          </QBTypography>
          <QBTypography style={{ marginBottom: "1.0rem" }}>
            {Messages.MESSAGE.FOR_ASSISTANCE}{" "}
            <RequestInfo variant="hyperlink" linkLabel={`${Messages.MESSAGE.SEND_MESSAGE}.`} />
          </QBTypography>
          <Button color="secondary" onClick={() => window.location.reload(false)}>
            {Messages.LABEL.RETRY}
          </Button>
        </>
      )}
    </div>
  );
};

export default ApplicationLoading;
