import React from "react";
import SplitPane from "react-split-pane";
import PropTypes from "prop-types";
import { get } from "lodash";
import clsx from "clsx";

import { useViewState, useViewStateDispatcher } from "providers/ViewStateProvider";
import * as utils from "commons/utils";
import * as Constants from "commons/constants";
import { Div } from "components/containers/Containers";

import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  secondaryRoot: {
    width: "100%",
    height: "inherit",
    transition: theme.transitions.create("all", {
      easing: theme.transitions.easing.easeIn,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  secondaryFullScreen: {
    width: 0,
    height: 0,
    minHeight: 0,
    transition: theme.transitions.create("all", {
      easing: theme.transitions.easing.easeIn,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
}));

const mainMin = 325;
const mainMax = 1400;
const VIEWSTATE_KEY = "horizontalMainSize";

const HorizontalSplitPane = (props) => {
  const { children, height } = props;
  const viewState = useViewState();
  const isFullScreen = !get(viewState, "isSplitScreen", true);
  const mainSizeFromState = get(viewState, VIEWSTATE_KEY);

  const [resized, setResized] = React.useState(false);
  const [mainSize, setMainSize] = React.useState(0);
  const [splitSize, setSplitSize] = React.useState();
  const [windowHeight, setWindowHeight] = React.useState();
  const activeTool = get(viewState, "activeTool");

  const dispatchViewStateChange = useViewStateDispatcher();

  let secondaryMin = 135;

  const calculateAndSetSize = () => {
    if (utils.hasNonEmptyValue(height)) {
      if (!resized) {
        secondaryMin =
          activeTool === Constants.TOOL_TYPE.COMPARABLE || activeTool === Constants.TOOL_TYPE.STATUS
            ? 260
            : 345;
      }

      let topHeight = height - secondaryMin;
      topHeight = Math.max(mainMin, topHeight);
      topHeight = Math.min(mainMax, topHeight);

      const isWindowResize = height !== windowHeight;

      let size = 0;
      if (isFullScreen) {
        size = height - 8;
      } else if (resized && !isWindowResize) {
        size = splitSize;
      } else {
        size = !mainSize && mainSizeFromState ? mainSizeFromState : topHeight;
        setSplitSize(size);
      }

      setMainSize(size);
      setWindowHeight(height);
    }
  };

  const handleDragEnd = (newSize) => {
    setSplitSize(newSize);
    setMainSize(newSize);
  };

  React.useEffect(() => {
    calculateAndSetSize();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [height, isFullScreen, activeTool]);

  React.useEffect(() => {
    if (mainSize) {
      const state = {};
      state[VIEWSTATE_KEY] = mainSize;
      state.horizontalSecondarySize = pane2Height;
      dispatchViewStateChange({ state });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mainSize]);

  const pane2Height = utils.hasNonEmptyValue(height) ? height - mainSize - 15 : 0;
  return (
    <SplitPane
      split="horizontal"
      onDragFinished={handleDragEnd}
      onDragStarted={(e) => setResized(true)}
      minSize={mainMin}
      maxSize={-secondaryMin}
      size={mainSize}
      pane2Style={{ height: pane2Height }}
    >
      {children[0]}
      {children[1]}
    </SplitPane>
  );
};

HorizontalSplitPane.propTypes = {
  children: PropTypes.node.isRequired,
  width: PropTypes.number,
};

HorizontalSplitPane.Main = function SplitPaneMain(props) {
  const { children } = props;

  return <Div>{children}</Div>;
};

HorizontalSplitPane.Main.propTypes = {
  children: PropTypes.node.isRequired,
};

HorizontalSplitPane.Secondary = function SplitPaneSecondary(props) {
  const classes = useStyles();
  const { children } = props;

  const viewState = useViewState();
  const isFullScreen = !get(viewState, "isSplitScreen", true);

  return (
    <div className={clsx(classes.secondaryRoot, { [classes.secondaryFullScreen]: isFullScreen })}>
      {children}
    </div>
  );
};

HorizontalSplitPane.Secondary.propTypes = {
  children: PropTypes.node,
};

export default HorizontalSplitPane;
