import React from "react";
import PropTypes from "prop-types";
import { invert, cloneDeep, get } from "lodash";

import { useViewState, useViewStateDispatcher } from "providers/ViewStateProvider";
import * as utils from "commons/utils";
import * as Constants from "commons/constants";
import { useMessages } from "providers/BrandingProvider";
import QBTypography from "components/QBTypography";
import BondDetail from "components/BondDetail";
import DepthOfBook from "components/modals/DepthOfBook";
import AllByIssuer from "components/modals/AllByIssuer";
import RequestInfo from "components/modals/RequestInfo";
import Holdings from "components/Holdings";
import Favorite from "components/Favorite";
import FavoriteIssuer from "components/FavoriteIssuer";
import { BuyButton } from "components/modals/TradeTicket";
import { SellButton } from "components/modals/RequestTicket";
import { TooltipBadge } from "components/Controls";
import { StyledTooltip } from "components/Controls";

import IconButton from "@material-ui/core/IconButton";
import { makeStyles } from "@material-ui/core/styles";
import PrintIcon from "@material-ui/icons/Print";
import { PillButton } from "./Controls";

const useStyles = makeStyles((theme) => ({
  wrapper: {
    animation: "flash 400ms ease-in-out",
    display: "flex",
    flexDirection: "column",
    maxHeight: "100%",
    minHeight: 0,
  },
  topbar: {
    display: "flex",
  },
  topbarSection: {
    flexGrow: 1,
  },
  section: {
    marginBottom: 0,
  },
  alert: {
    color: theme.palette.text.warning,
  },
  iconButtons: {
    marginTop: 5,
    marginBottom: 5,
    marginRight: 5,
    display: "flex",
    flexWrap: "wrap",
    alignItems: "center",
  },
  subHeaderBlock: {
    marginTop: 4,
  },
}));

const isQuotesEnabled = process.env.REACT_APP_FEATURES_QUOTES === "true";
const isBidsEnabled = process.env.REACT_APP_FEATURES_BIDS === "true";
const isOfferEnabled = process.env.REACT_APP_FEATURES_OFFER === "true";

export const HeaderText = ({ bond }) => {
  return (
    <>
      <QBTypography variant="overline" color="primary">
        {bond ? bond.cusip : ""}
      </QBTypography>
      {bond.issuer && (
        <div style={{ display: "flex", alignItems: "center" }}>
          <QBTypography variant="h3">{bond.issuer}</QBTypography>
          <FavoriteIssuer bond={bond} />
        </div>
      )}
    </>
  );
};

// Renders our subheader with its various customizable and multi-line elements
export const SubHeaderText = ({ bond, style }) => {
  const classes = useStyles();
  const Messages = useMessages();

  let subheaderLine1;
  let subheaderLine2;
  let subheaderLine3;

  if (utils.hasNonEmptyValue(bond)) {
    subheaderLine1 = Messages.LABEL[utils.getInventoryKeyFromType(bond.instrumentCategory)];

    if (bond.instrumentCategory === Constants.INVENTORY_TYPE.CD) {
      const cdtype = Messages.LABEL[invert(Constants.INSTRUMENT_TYPE)[bond.instrumentType.description]];
      subheaderLine1 = `${cdtype} ${subheaderLine1}`;
    }

    if (bond.taxable) {
      subheaderLine1 = `${subheaderLine1} ${String.fromCharCode(8226)} Taxable`;
    }

    if (
      bond.instrumentCategory === Constants.INVENTORY_TYPE.MUNICIPAL &&
      bond.combinedRatings?.combinedRating
    ) {
      subheaderLine1 = `${subheaderLine1} ${String.fromCharCode(8226)} ${
        bond.combinedRatings.combinedRating
      }`;
    }

    if (bond.instrumentCategory === Constants.INVENTORY_TYPE.TREASURY && bond.productCategory) {
      subheaderLine1 = `${subheaderLine1} ${String.fromCharCode(8226)} ${bond.productCategory}`;
    }

    if (bond.instrumentCategory === Constants.INVENTORY_TYPE.MORTGAGE && bond.sector) {
      subheaderLine1 = `${subheaderLine1} ${String.fromCharCode(8226)} ${bond.sector}`;
    }

    if (bond.instrumentCategory === Constants.INVENTORY_TYPE.CD && bond.insuranceNumber) {
      subheaderLine2 = `FDIC ${utils.formatValue(bond.insuranceNumber, "insuranceNumber")}`;
    }

    if (bond.instrumentCategory === Constants.INVENTORY_TYPE.CD && bond?.stateRestrictions?.length > 0) {
      const restrictionsArr = bond.stateRestrictions.map((k) => k.state.stateAbbreviation);
      subheaderLine3 = `Restricted States: ${restrictionsArr.join(", ", restrictionsArr)}`;
    }
  }

  return (
    <div style={style}>
      <div className={classes.subHeaderBlock}>
        <QBTypography color="secondary" style={{ textTransform: "uppercase" }}>
          {subheaderLine1}
        </QBTypography>
        {subheaderLine2 && (
          <QBTypography color="secondary" style={{ textTransform: "uppercase" }}>
            {subheaderLine2}
            {bond.hasHoldings && <Holdings bond={bond} />}
          </QBTypography>
        )}
        {subheaderLine3 && (
          <QBTypography color="secondary" style={{ textTransform: "uppercase", lineHeight: 1.5 }}>
            {subheaderLine3} <TooltipBadge tooltip={Messages.TOOLTIP.RESTRICTED_STATES} />
          </QBTypography>
        )}
      </div>
    </div>
  );
};

const DetailCard = ({
  onAddBondsToCart,
  onOpenTradeTicket,
  onOpenRequestTicket,
  selectedBond,
  allByIssuerEnabled = true,
  findCompBondsEnabled = true,
}) => {
  const classes = useStyles();
  const Messages = useMessages();

  const [bond, setBond] = React.useState();

  const viewState = useViewState();
  const dispatchViewStateChange = useViewStateDispatcher();

  const selectedTool = get(viewState, "activeTool", Constants.TOOL_TYPE.RATES);

  const comparableView = get(viewState, Constants.VIEW.COMPARABLE, {});
  const compareBond = comparableView.compareBond || {};

  const showCompBondsButton =
    selectedTool !== Constants.TOOL_TYPE.COMPARABLE ||
    (compareBond && bond && compareBond.cusip !== bond?.cusip);

  React.useEffect(() => {
    if (utils.hasNonEmptyValue(selectedBond)) {
      setBond(selectedBond);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedBond]);

  const handleUpdateCompBond = () => {
    dispatchViewStateChange({
      state: {
        selectedBond: bond,
        activeTool: Constants.TOOL_TYPE.COMPARABLE,
      },
    });
  };

  const handleOpenOfferBond = () => {
    const updatedBond = cloneDeep(bond);
    delete updatedBond.price;
    delete updatedBond.yieldToMaturity;
    delete updatedBond.yieldToWorst;

    onOpenRequestTicket(Constants.TRADE_TYPE.BONDS_FOR_SALE, [updatedBond]);
  };

  return (
    <div className={classes.wrapper} key={bond?.cusip}>
      {utils.hasNonEmptyValue(bond) && (
        <>
          <div style={{ paddingRight: 10, flexShrink: 0 }}>
            <HeaderText bond={bond} />
            <SubHeaderText bond={bond} />
            {!bond.instrumentCategory && (
              <div>
                <QBTypography gutterBottom className={classes.alert}>
                  {Messages.MESSAGE.BOND_UNKNOWN}
                </QBTypography>
                <RequestInfo bond={bond} />
              </div>
            )}
            {bond.instrumentCategory && (
              <>
                {!bond.inInventory && (
                  <QBTypography gutterBottom className={classes.alert}>
                    {Messages.MESSAGE.BOND_NOT_IN_INVENTORY}
                  </QBTypography>
                )}
                <div
                  style={{
                    display: "flex",
                    flexWrap: "wrap",
                    alignItems: "center",
                    marginTop: 10,
                  }}
                >
                  <div className={classes.iconButtons}>
                    <BuyButton
                      style={{ marginRight: 4 }}
                      disabled={!bond.inInventory}
                      bond={bond}
                      onClick={onAddBondsToCart}
                      onCartClick={onOpenTradeTicket}
                    />
                    {isQuotesEnabled && (
                      <SellButton
                        style={{ marginRight: 4 }}
                        tooltip={Messages.LABEL.PRICE_QUOTES}
                        label={Messages.LABEL.PRICE_QUOTES_SHORT}
                        onClick={() => onOpenRequestTicket(Constants.TRADE_TYPE.PRICE_QUOTES, [bond])}
                      />
                    )}
                    {isBidsEnabled && (
                      <SellButton
                        style={{ marginRight: 4 }}
                        tooltip={Messages.LABEL.BIDS_RFQ}
                        label={Messages.LABEL.BIDS_RFQ_SHORT}
                        onClick={() => onOpenRequestTicket(Constants.TRADE_TYPE.BIDS_RFQ, [bond])}
                      />
                    )}
                    {isOfferEnabled && (
                      <SellButton
                        style={{ marginRight: 4 }}
                        tooltip={Messages.LABEL.BONDS_FOR_SALE}
                        label={Messages.LABEL.BONDS_FOR_SALE_SHORT}
                        onClick={handleOpenOfferBond}
                      />
                    )}
                  </div>
                  <div className={classes.iconButtons}>
                    <Favorite bond={bond} />

                    {bond.numSimilarCusips > 0 && (
                      <DepthOfBook
                        onAddBondsToCart={onAddBondsToCart}
                        onOpenTradeTicket={onOpenTradeTicket}
                        bond={bond}
                      />
                    )}

                    {allByIssuerEnabled && (
                      <AllByIssuer
                        onAddBondsToCart={onAddBondsToCart}
                        onOpenRequestTicket={onOpenRequestTicket}
                        onOpenTradeTicket={onOpenTradeTicket}
                        bond={bond}
                      />
                    )}
                    <RequestInfo bond={bond} />
                    <StyledTooltip title={Messages.TOOLTIP.PRINT}>
                      <IconButton
                        size="small"
                        onClick={() => window.open(`/detail?cusip=${bond.cusip}`, "PrintDetail")}
                      >
                        <PrintIcon color="primary" />
                      </IconButton>
                    </StyledTooltip>
                  </div>

                  {findCompBondsEnabled && showCompBondsButton && (
                    <div className={classes.iconButtons}>
                      <PillButton onClick={handleUpdateCompBond}>
                        {bond.instrumentCategory === Constants.INVENTORY_TYPE.CD
                          ? Messages.LABEL.FIND_COMPRABLE_CDS
                          : Messages.LABEL.FIND_COMPRABLE_BONDS}
                      </PillButton>
                    </div>
                  )}
                </div>
                {(bond.emmaLink || bond.ratingLink) && (
                  <div
                    style={{
                      display: "flex",
                      flexWrap: "wrap",
                      alignItems: "center",
                      gap: 10,
                    }}
                  >
                    {bond.emmaLink && <>{utils.formatValue(get(bond, "emmaLink"), "emmaLink", bond, true)}</>}
                    {bond.ratingLink && (
                      <>{utils.formatValue(get(bond, "ratingLink"), "ratingLink", bond, true)}</>
                    )}
                  </div>
                )}
              </>
            )}
          </div>
          <BondDetail bond={bond} />
        </>
      )}
    </div>
  );
};

DetailCard.propTypes = {
  onAddBondsToCart: PropTypes.func.isRequired,
  onOpenRequestTicket: PropTypes.func.isRequired,
  onOpenTradeTicket: PropTypes.func.isRequired,
  selectedBond: PropTypes.object,
  allByIssuerEnabled: PropTypes.bool,
  findCompBondsEnabled: PropTypes.bool,
};

export default DetailCard;
