import React from "react";
import { useGlobal } from "reactn";

import * as Constants from "commons/constants";
import { useMessages } from "providers/BrandingProvider";
import { ClosableModal } from "components/containers/Modals";

import Snackbar from "@material-ui/core/Snackbar";
import Slide from "@material-ui/core/Slide";
import Fade from "@material-ui/core/Fade";
import { makeStyles } from "@material-ui/core/styles";

import NotificationContentWrapper from "./NotificationContentWrapper";
import AnnouncementContentWrapper from "./AnnouncementContentWrapper";

const useStyles = makeStyles((theme) => ({
  anchorOriginTopRight: {
    top: -3,
    right: 8,
  },
}));

function SlideTransition(props) {
  return <Slide {...props} direction="down" />;
}

function NotificationCenter() {
  const classes = useStyles();
  const Messages = useMessages();

  const [notificationMessage, setNotificationMessage] = useGlobal("notificationMessage");
  const [open, setOpen] = React.useState(true);

  React.useEffect(() => {
    if (notificationMessage) {
      setOpen(true);
    }
  }, [notificationMessage]);

  const handleExit = () => {
    setNotificationMessage(null);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  return (
    <div>
      {notificationMessage && notificationMessage.type !== Constants.ALERT_LEVEL.ANNOUNCEMENT && (
        <Snackbar
          classes={{ anchorOriginTopRight: classes.anchorOriginTopRight }}
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          TransitionComponent={SlideTransition}
          open={open}
          transitionDuration={{ enter: 500, exit: 1200 }}
          autoHideDuration={4500}
          onClose={handleClose}
          onExited={handleExit}
        >
          <div>
            <Fade in={open} timeout={{ enter: 500, exit: 800 }}>
              <NotificationContentWrapper
                onClose={handleClose}
                level={notificationMessage.type}
                message={notificationMessage.message}
                action={notificationMessage.action}
              />
            </Fade>
          </div>
        </Snackbar>
      )}
      {notificationMessage && notificationMessage.type === Constants.ALERT_LEVEL.ANNOUNCEMENT && (
        <ClosableModal
          open={open}
          header={Messages.LABEL.ANNOUNCEMENT}
          HeaderProps={{ color: "secondary" }}
          onClose={handleClose}
        >
          <AnnouncementContentWrapper messages={notificationMessage.messages} />
        </ClosableModal>
      )}
    </div>
  );
}

export default NotificationCenter;
