import React from "react";
import queryString from "query-string";
import CircularProgress from "@material-ui/core/CircularProgress";
import BondDetail from "components/BondDetail";
import { useDataService } from "services/DataService";
import * as Constants from "commons/constants";
import * as utils from "commons/utils";
import QBTypography from "components/QBTypography";
import { SubHeaderText } from "components/DetailCard";
import { useMessages, useBranding } from "providers/BrandingProvider";

import { LightTheme } from "theme/LightTheme";
import { ThemeProvider } from "@material-ui/styles";
import CssBaseline from "@material-ui/core/CssBaseline";

const Detail = ({ location }) => {
  const Messages = useMessages();
  const branding = useBranding();
  const { cusip } = queryString.parse(location.search);
  const [bond, setBond] = React.useState();
  const [state, queryDataService] = useDataService();

  React.useEffect(() => {
    const queryParams = {
      type: Constants.QUERY_TYPE.CUSIP_SEARCH,
      identifier: {
        filterKey: "cusip",
        filterValue: [cusip],
      },
      clearDataOnFetch: false,
    };

    queryDataService(queryParams);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cusip]);

  React.useEffect(() => {
    if (utils.hasNonEmptyValue(state.data)) {
      const bond = state.data[0];
      setBond(bond);
    }
  }, [state.data]);

  const openPrint = React.useCallback(() => {
    window.print();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bond]);

  return (
    <ThemeProvider theme={LightTheme}>
      <CssBaseline />
      <header>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            marginBottom: 20,
            alignItems: "center",
          }}
        >
          <img src={`/logos/${branding.logo?.print_header}`} alt={Messages.BRANDING.INSTITUTION_NAME} />
        </div>
      </header>
      <div style={{ margin: "2rem" }}>
        {!bond && (
          <>
            <div style={{ marginBottom: "1.0rem" }}>
              <CircularProgress color="secondary" />
            </div>
          </>
        )}
        {bond && (
          <div ref={openPrint}>
            <QBTypography variant="overline" color="primary">
              {bond ? bond.cusip : ""}
            </QBTypography>
            {bond.issuer && (
              <div style={{ display: "flex", alignItems: "center" }}>
                <QBTypography variant="h3" style={{ marginBottom: 10 }}>
                  {bond.issuer}
                </QBTypography>
              </div>
            )}
            <SubHeaderText bond={bond} style={{ marginBottom: 15 }} />
            <BondDetail bond={bond} />
          </div>
        )}
      </div>
    </ThemeProvider>
  );
};

export default Detail;
