import React from "react";
import { useGlobal } from "reactn";

import { setPreference } from "services/PreferencesService";

import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  wrapper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontSize: "0.75rem",
  },
  header: {
    margin: "auto 4px",
    textTransform: "uppercase",
    color: theme.palette.text.secondary,
  },
  switchRoot: {
    margin: "auto 4px",
    color: theme.palette.secondary.main,
    whiteSpace: "nowrap",
  },
  switchWrapper: {
    marginRight: 2,
    marginLeft: 2,
  },
}));

const ThemeToggle = ({ style = {} }) => {
  const classes = useStyles();

  const [userprefs = {}] = useGlobal("userprefs");
  const toggleTheme = () => {
    if (userprefs.theme !== "dark") {
      setPreference("theme", "dark");
    } else {
      setPreference("theme", "light");
    }
  };

  return (
    <div className={classes.wrapper} style={style}>
      <div className={classes.header}>Theme</div>
      <div className={classes.switchRoot}>
        <span className={classes.label}>Light</span>
        <FormControlLabel
          className={classes.switchWrapper}
          control={
            <Switch size="small" checked={userprefs.theme === "dark"} onChange={toggleTheme} value="dark" />
          }
        />
        <span className={classes.label}>Dark</span>
      </div>
    </div>
  );
};

export default ThemeToggle;
