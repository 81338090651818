import React from "react";
import queryString from "query-string";
import PropTypes from "prop-types";
import { useGlobal } from "reactn";
import httpService from "../services/commons/HttpService";

import { storeAccessToken } from "commons/helpers/userStorage";
import { TRACKING_ENDPOINT } from "../config/dataconfig";
import { TRACKING_LOGIN_VIA } from "../commons/constants";

function OAuth2CallbackHandler(props) {
  const { location } = props;
  const [isAuthenticated] = useGlobal("authenticated");
  const [redirectUri, setRedirectUri] = React.useState();
  const [loginVia, setLoginVia] = React.useState();

  /* Simple post to track the user has logged in */
  const trackLogin = async (loginViaParm) => {
    const URL = TRACKING_ENDPOINT + `/login?loginVia=${loginViaParm}`;
    httpService.post(URL).then(null, (error) => {
      console.log("Error tracking login:" + error);
    });
  };

  React.useEffect(() => {
    if (location.hash && location.hash.indexOf("access_token=") > 0) {
      const parsedHash = queryString.parse(location.hash);

      const accessToken = parsedHash.access_token;
      const expires = parsedHash.expires_in;
      let referrer = parsedHash.referrer;

      // Decode referrer to send to the backend
      if (referrer) {
        referrer = referrer.toLowerCase();
        if (referrer === "direct") {
          setLoginVia(TRACKING_LOGIN_VIA.QWICKBONDS);
        } else if (referrer.includes("qwickrate.com")) {
          setLoginVia(TRACKING_LOGIN_VIA.QWICKRATE);
        } else {
          setLoginVia(TRACKING_LOGIN_VIA.QWICKBONDS);
        }
      } else {
        setLoginVia(TRACKING_LOGIN_VIA.QWICKBONDS); // Some sort of direct login
      }

      storeAccessToken(accessToken, expires);

      let redirectUri = decodeURIComponent(parsedHash.state) || "/";

      if (parsedHash.search) {
        redirectUri = `${redirectUri}search=${parsedHash.search}`;
        if (parsedHash.sort) {
          redirectUri = `${redirectUri}&sort=${parsedHash.sort}`;
        }
      }
      setRedirectUri(redirectUri);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    if (isAuthenticated && redirectUri) {
      trackLogin(loginVia);
      window.location.assign(redirectUri);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated, redirectUri]);

  return null;
}

OAuth2CallbackHandler.propTypes = {
  location: PropTypes.object.isRequired,
};

export default OAuth2CallbackHandler;
