import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";

import * as Constants from "commons/constants";
import * as DataOptions from "commons/dataOptions";
import { useOptionsService } from "services/OptionsService";
import { StyledSecondaryButtonGroup } from "components/Controls";

import QBTypography from "components/QBTypography";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import Button from "@material-ui/core/Button";
import { makeStyles, withStyles } from "@material-ui/core/styles";

import { TRACKING_ENDPOINT } from "../../config/dataconfig";
import httpService from "../../services/commons/HttpService";

const useStyles = makeStyles((theme) => ({
  button: {
    letterSpacing: "0.12em",
    fontSize: "0.6rem",
    backgroundColor: theme.palette.background.paperButton,
  },
  tableNavButton: {
    minWidth: 50,
    height: 30,
    padding: "0px 10px",
  },
  header: {
    color: theme.palette.primary.main,
  },
}));

export const MainNavigation = withStyles((theme) => ({
  root: {
    padding: 0,
  },
  grouped: {
    whiteSpace: "nowrap",
    "&.Mui-disabled": {
      backgroundColor: theme.palette.background.button.primary,
      color: theme.palette.text.selected.primary,
      borderColor: theme.palette.border.primary,
    },
  },
}))(ButtonGroup);

const isQuotesEnabled = process.env.REACT_APP_FEATURES_QUOTES === "true";
const isBidsEnabled = process.env.REACT_APP_FEATURES_BIDS === "true";
const isOfferEnabled = process.env.REACT_APP_FEATURES_OFFER === "true";

const NavigationToolbar = (props) => {
  const classes = useStyles();

  const { optionsKey, variant, selectedOption, onOptionSelected, style = {} } = props;
  const isTableNav = variant === "main";

  const [options, setOptionKey] = useOptionsService();

  React.useEffect(() => {
    if (optionsKey) {
      setOptionKey(optionsKey);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [optionsKey]);

  const handleClick = (event, value) => {
    if (selectedOption !== value) {
      onOptionSelected(value, optionsKey);
    }
  };

  const openLendKeyInNewTab = () => {
    window.open("https://aliromarketplace.lendkey.com/register", "_blank");
    trackWholeLoans();
  };  
  
  /* Simple post to track the user clicked "Whole Loans" */
  const trackWholeLoans = async () => {
    const URL = TRACKING_ENDPOINT + `/wholeloans`;
    httpService.post(URL).then(null, (error) => {
      console.log("Error tracking login:" + error);
    });
  };

  const navOptions = options
    .filter((navOption) => {
      if (optionsKey !== Constants.SUBVIEW.SELL) {
        return true;
      } else {
        if (navOption.value === Constants.TRADE_TYPE.PRICE_QUOTES) {
          return isQuotesEnabled;
        } else if (navOption.value === Constants.TRADE_TYPE.BIDS_RFQ) {
          return isBidsEnabled;
        } else if (navOption.value === Constants.TRADE_TYPE.BONDS_FOR_SALE) {
          return isOfferEnabled;
        }
      }
      return true;
    })
    .map((navOption, i, allOptions) => {
      if (allOptions.length === 1) {
        return (
          <QBTypography variant="h3" color="secondary">
            {navOption.label}
          </QBTypography>
        );
      }
      return (
        <Button
          key={navOption.value}
          className={clsx(classes.button, { [classes.tableNavButton]: isTableNav })}
          disabled={selectedOption === navOption.value}
          onClick={(e) => handleClick(e, navOption.value)}
        >
          {navOption.label}
        </Button>
      );
    });

  return (
    <div style={style}>
      {navOptions.length === 1 && <div>{navOptions[0]}</div>}
      {isTableNav && navOptions.length > 1 && (
        <MainNavigation variant="outlined" size="small" color="primary">
          {navOptions}
          <Button
            className={clsx(classes.button, { [classes.tableNavButton]: isTableNav })}
            onClick={(e) => openLendKeyInNewTab()}
          >
            Loans
          </Button>
        </MainNavigation>
      )}
      {!isTableNav && navOptions.length > 1 && (
        <StyledSecondaryButtonGroup variant="outlined" size="small">
          {navOptions}
        </StyledSecondaryButtonGroup>
      )}
    </div>
  );
};

NavigationToolbar.propTypes = {
  selectedOption: PropTypes.string.isRequired,
  onOptionSelected: PropTypes.func.isRequired,
  optionsKey: PropTypes.oneOf(Object.keys(DataOptions.STATIC_OPTIONS)).isRequired,
  variant: PropTypes.oneOf(["main", "secondary"]),
};

export default NavigationToolbar;
