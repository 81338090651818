import React from "react";
import PropTypes from "prop-types";
import { CSSTransition } from "react-transition-group";
import { get } from "lodash";

import { useViewState } from "providers/ViewStateProvider";
import * as utils from "commons/utils";

import * as Constants from "commons/constants";
import DetailCard from "components/DetailCard";
import SearchCard from "components/SearchCard";
import { SidebarCard, SidebarCardContent, Div } from "components/containers/Containers";

import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  card: {
    marginBottom: 8,
    marginLeft: 4,
    "&:last-child": {
      marginBottom: 0,
    },
  },
  highlight: {
    animation: "highlightcard 1500ms",
  },
}));

const BondSidebar = (props) => {
  const classes = useStyles();
  const { onAddBondsToCart, onOpenTradeTicket, onOpenRequestTicket } = props;

  const viewState = useViewState();
  const detailView = get(viewState, Constants.VIEW.DETAIL, {});
  const selectedBond = detailView.selectedBond;

  return (
    <React.Fragment>
      <div className={classes.card}>
        <SearchCard />
      </div>
      <CSSTransition
        mountOnEnter
        timeout={500}
        classNames="transition-grow"
        in={utils.hasNonEmptyValue(selectedBond)}
      >
        <div className={classes.card} style={{ height: "calc(100% - 94px)" }}>
          <Div>
            <SidebarCard>
              <SidebarCardContent
                style={{ paddingRight: 0 }}
                classes={{ root: classes.highlight }}
                key={selectedBond?.cusip}
              >
                <DetailCard
                  selectedBond={selectedBond}
                  onAddBondsToCart={onAddBondsToCart}
                  onOpenRequestTicket={onOpenRequestTicket}
                  onOpenTradeTicket={onOpenTradeTicket}
                />
              </SidebarCardContent>
            </SidebarCard>
          </Div>
        </div>
      </CSSTransition>
    </React.Fragment>
  );
};

BondSidebar.propTypes = {
  onAddBondsToCart: PropTypes.func.isRequired,
  onOpenRequestTicket: PropTypes.func.isRequired,
  onOpenTradeTicket: PropTypes.func.isRequired,
};

export default BondSidebar;
