import React from "react";
import PropTypes from "prop-types";
import { get, cloneDeep } from "lodash";

import { useViewState, useViewStateDispatcher } from "providers/ViewStateProvider";
import * as DataConfig from "config/dataconfig";
import * as Constants from "commons/constants";
import { useMessages } from "providers/BrandingProvider";
import * as utils from "commons/utils";
import DataTable from "components/datatable/DataTable";
import { ClosableModal } from "components/containers/Modals";
import { useDataService } from "services/DataService";
import { StyledTooltip } from "components/Controls";

import IconButton from "@material-ui/core/IconButton";
import VisibilityIcon from "@material-ui/icons/Visibility";
import DetailCard from "components/DetailCard";

const AllByIssuer = (props) => {
  const { onAddBondsToCart, onOpenTradeTicket, onOpenRequestTicket, bond } = props;

  const Messages = useMessages();
  const [open, setOpen] = React.useState(false);
  const [data, setData] = React.useState([]);
  const [selectedBond, setSelectedBond] = React.useState(bond);
  const [totalNumberRows, setTotalNumberRows] = React.useState();

  const instrumentCategory = utils.hasNonEmptyValue(bond) ? bond.instrumentCategory : null;
  const [state, queryDataService] = useDataService();
  const viewState = useViewState();
  const dispatchViewStateChange = useViewStateDispatcher();

  const currentViewState = get(viewState, Constants.VIEW.ALL_BY_ISSUER, {});

  const handleAddBondsToCart = (bonds, currentBonds) => {
    setOpen(false);
    onAddBondsToCart(bonds, currentBonds);
  };

  const handleOpenTradeTicket = (bonds) => {
    setOpen(false);
    onOpenTradeTicket(bonds);
  };

  const handleOpenRequestTicket = (bonds) => {
    setOpen(false);
    onOpenRequestTicket(bonds);
  };

  const handleCurrentViewStateChange = (viewstate) => {
    if (viewstate.selectedBond) {
      setSelectedBond(viewstate.selectedBond);
      delete viewstate.selectedBond;
    }
    dispatchViewStateChange({ state: viewstate, view: Constants.VIEW.ALL_BY_ISSUER });
  };

  const applyFiltersAndSort = (data, query) => {
    setTotalNumberRows(data.length);

    let sortedData = cloneDeep(data);
    sortedData = utils.sortData(sortedData, query.sort);
    sortedData = utils.pageData(sortedData, query.page, DataConfig.DEFAULT_PAGE_SIZE);

    setData(sortedData);
  };

  React.useEffect(() => {
    if (currentViewState?.query) {
      applyFiltersAndSort(state.data, currentViewState.query);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(currentViewState), state.data]);

  // initial query after component mount
  React.useEffect(() => {
    if (open && utils.hasNonEmptyValue(bond)) {
      const cusip = bond.cusip;
      const queryParams = {
        type: Constants.QUERY_TYPE.INVENTORY,
        filter: {
          cusip: {
            filterKey: "cusip",
            filterValue: cusip.substring(0, 6),
            filterType: Constants.FILTER_TYPE.KEYWORD_WILDCARD,
          },
        },
        size: 500,
      };

      queryParams.clearDataOnFetch = true;
      queryDataService(queryParams);
    } else if (!open) {
      dispatchViewStateChange({ state: { query: {} }, view: Constants.VIEW.ALL_BY_ISSUER });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  return (
    <>
      <StyledTooltip title={`${Messages.TOOLTIP.SEE_ALL_OFFERINGS_FROM} ${bond ? bond.issuer : ""}`}>
        <IconButton size="small" onClick={() => setOpen(true)}>
          <VisibilityIcon color="primary" />
        </IconButton>
      </StyledTooltip>
      <ClosableModal
        open={open}
        onClose={(e) => setOpen(false)}
        scrollable={false}
        header={
          <div>
            {Messages.LABEL.ALL_OFFERINGS_FROM} {bond ? bond.issuer : ""}
          </div>
        }
      >
        <React.Fragment>
          <div
            style={{
              marginTop: 20,
              minWidth: 900,
              maxHeight: "calc(97vh - 150px)",
              display: "flex",
              flexWrap: "nowrap",
            }}
          >
            <DataTable
              manual={true}
              queryType={Constants.QUERY_TYPE.ALL_BY_ISSUER}
              view={Constants.VIEW.ALL_BY_ISSUER}
              instrumentCategory={instrumentCategory}
              data={data}
              loading={state.isLoading}
              error={state.isError}
              filterable={false}
              selectedBond={selectedBond}
              selectable={true}
              onAddBondsToCart={handleAddBondsToCart}
              onOpenTradeTicket={handleOpenTradeTicket}
              onViewStateChange={handleCurrentViewStateChange}
              autoSelectFirstBond={false}
              totalNumberRows={totalNumberRows}
              style={{ marginRight: 10 }}
            />
            <div style={{ marginLeft: 20 }}>
              <DetailCard
                selectedBond={selectedBond}
                onAddBondsToCart={handleAddBondsToCart}
                onOpenTradeTicket={handleOpenTradeTicket}
                onOpenRequestTicket={handleOpenRequestTicket}
                allByIssuerEnabled={false}
                findCompBondsEnabled={false}
              />
            </div>
          </div>
        </React.Fragment>
      </ClosableModal>
    </>
  );
};

AllByIssuer.propTypes = {
  onAddBondsToCart: PropTypes.func.isRequired,
  onOpenTradeTicket: PropTypes.func.isRequired,
  onOpenRequestTicket: PropTypes.func.isRequired,
  bond: PropTypes.object,
};

export default AllByIssuer;
