import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";

import * as Constants from "commons/constants";

import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import Slider from "@material-ui/core/Slider";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import MenuItem from "@material-ui/core/MenuItem";
import Tooltip from "@material-ui/core/Tooltip";
import Checkbox from "@material-ui/core/Checkbox";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import SearchIcon from "@material-ui/icons/Search";
import InfoIcon from "@material-ui/icons/InfoOutlined";
import EditIcon from "@material-ui/icons/Edit";
import CancelIcon from "@material-ui/icons/Cancel";
import SettingsIcon from "@material-ui/icons/Settings";
import DateRangeIcon from "@material-ui/icons/DateRange";
import CheckIcon from "@material-ui/icons/Check";

import { makeStyles, withStyles } from "@material-ui/core/styles";
import { alpha } from "@material-ui/core/styles/colorManipulator";

const useStyles = makeStyles((theme) => ({
  inputRoot: {
    paddingLeft: 2,
    paddingRight: 2,
    borderRadius: 4,
    background: theme.palette.background.shade,
    width: "100%",
    "&:hover:not(.Mui-disabled):not(.Mui-error) .MuiOutlinedInput-notchedOutline": {
      borderColor: theme.palette.border.highlight,
      borderWidth: 1,
    },
    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderWidth: 1,
    },
    "&.Mui-focused:not(.Mui-disabled):not(.Mui-error) .MuiOutlinedInput-notchedOutline": {
      borderColor: theme.palette.border.highlight,
      borderWidth: 1,
    },
    "&.Mui-disabled": {
      color: theme.palette.text.disabled,
      background: theme.palette.background.disabled,
    },
  },
  formInputRoot: {
    "&:hover:not(.Mui-disabled):not(.Mui-error) .MuiOutlinedInput-notchedOutline": {
      borderColor: theme.palette.border.primary,
    },
    "&.Mui-focused:not(.Mui-disabled):not(.Mui-error) .MuiOutlinedInput-notchedOutline": {
      borderColor: theme.palette.border.primary,
    },
  },
  notchedOutline: {
    borderColor: theme.palette.border.textInput,
    borderWidth: 1,
    ".Mui-disabled &": {
      borderColor: `${theme.palette.border.disabled} !important`,
    },
  },
  notchedOutlineWarning: {
    borderColor: `${theme.palette.border.warning} !important`,
    borderWidth: 1,
  },
  input: {
    fontSize: "0.65rem",
    padding: "2px 3px",
    borderRadius: 3,
    fontWeight: "normal",
    color: theme.palette.text.textInput,
    lineHeight: 1,
    "&.Mui-disabled": {
      color: theme.palette.text.disabled,
    },
    "&::-webkit-input-placeholder": {
      fontStyle: "italic",
      fontSize: "0.65rem",
    },
    "&:-ms-input-placeholder": {
      fontStyle: "italic",
      fontSize: "0.65rem",
    },
    "&::placeholder": {
      fontStyle: "italic",
      fontSize: "0.65rem",
    },
  },
  formInput: {
    fontSize: "0.75rem",
    padding: "3px 5px",
  },
  filterInput: {
    textAlign: "center",
    maxWidth: 50,
  },
  adornmentPositionStart: {
    marginLeft: 4,
    marginRight: 0,
    fontSize: "0.7rem",
  },
  searchIcon: {
    fontSize: "0.9rem",
    color: theme.palette.text.secondary,
  },
  searchIconPrimary: {
    fontSize: "0.9rem",
    color: theme.palette.primary.main,
  },
  formControlRoot: {
    width: "100%",
  },
  textareaRoot: {
    fontSize: "0.8rem",
    padding: 10,
    maxWidth: 500,
    height: "100%",
    width: "100%",
    alignItems: "start",
    borderRadius: 4,
    background: theme.palette.background.subtle,
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: theme.palette.border.textInput,
      borderWidth: 1,
    },
    "&:hover .MuiOutlinedInput-notchedOutline": {
      borderColor: theme.palette.border.highlight,
      borderWidth: 1,
    },
    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: theme.palette.border.highlight,
      borderWidth: 1,
    },
  },
  textareaInput: {
    height: "inherit",
    "&::-webkit-input-placeholder": {
      fontStyle: "italic",
      fontSize: "0.7rem",
    },
    "&:-ms-input-placeholder": {
      fontStyle: "italic",
      fontSize: "0.7rem",
    },
    "&::placeholder": {
      fontStyle: "italic",
      fontSize: "0.7rem",
    },
  },
  sliderRoot: {
    padding: "5px 0",
  },
  sliderThumb: {
    height: 6,
    width: 4,
    marginLeft: -3,
    marginTop: -2,
    "&:hover": {
      boxShadow: "0px 0px 0px 3px rgba(0, 193, 9, 0.16)",
    },
    "&.MuiSlider-active": {
      boxShadow: "0px 0px 0px 5px rgba(0, 193, 9, 0.16)",
    },
  },
  sliderMark: {
    opacity: 1,
  },
  sliderMarkHide: {
    opacity: 0,
  },
  sliderMarkLabelActive: {
    color: theme.palette.text.secondary,
  },
  sliderMarkLabel: {
    top: 10,
    fontSize: "0.6rem",
  },
  valueLabel: {
    "& span span": {
      color: theme.palette.common.white,
      fontSize: "0.6rem",
    },
  },
  helperText: {
    fontSize: "0.7rem",
    margin: 0,
  },
  selectPaper: {
    backgroundColor: theme.palette.background.popperPaper,
  },
  select: {
    fontSize: "0.7rem",
    paddingTop: 4,
    paddingBottom: 0,
    color: theme.palette.text.secondary,
    "&:focus": {
      backgroundColor: "transparent",
    },
  },
  filterselect: {
    fontSize: "0.65rem",
    paddingTop: 0,
    paddingBottom: 0,
    "&.MuiSelect-select": {
      paddingRight: 12,
    },
  },
  filterselectlg: {
    fontSize: "0.75rem",
    color: theme.palette.secondary.main,
    "&.Mui-disabled": {
      color: theme.palette.text.disabled,
    },
    "&.MuiSelect-select": {
      paddingRight: 20,
    },
  },
  selecticon: {
    color: theme.palette.primary.main,
  },
  filterselecticon: {
    color: theme.palette.secondary.main,
    height: 20,
    width: 20,
    right: -2,
  },
  linkbutton: {
    fontSize: "0.7rem",
    whiteSpace: "nowrap",
    "&:hover": {
      background: "transparent",
      textDecoration: "underline",
    },
  },
  linkbuttonForm: {
    letterSpacing: "0.12em",
  },
  checkboxRoot: {
    padding: 0,
    "&:hover": {
      backgroundColor: "transparent !important",
    },
  },
  iconbutton: {
    margin: 0,
    padding: "2px 0",
    fontSize: "0.6rem",
    minWidth: 40,
    lineHeight: 1,
    color: alpha(theme.palette.secondary.main, 0.6),
    "&:hover": {
      borderColor: alpha(theme.palette.secondary.main, 0.15),
      backgroundColor: alpha(theme.palette.secondary.main, 0.08),
    },
  },
}));

export const TextInput = (props) => {
  const classes = useStyles();
  const {
    startAdornment,
    endAdornment,
    variant,
    tabIndex,
    maxLength,
    warning,
    inputProps = {},
    style = {},
    ...otherProps
  } = props;
  const isForm = variant === "form";
  const isFilter = variant === "filter";

  const helperTextProps = {
    classes: {
      root: classes.helperText,
    },
  };

  const InputProps = {
    classes: {
      root: clsx(classes.inputRoot, { [classes.formInputRoot]: isForm }),
      formControl: classes.inputFormControl,
      notchedOutline: warning ? classes.notchedOutlineWarning : classes.notchedOutline,
      input: clsx(classes.input, { [classes.formInput]: isForm, [classes.filterInput]: isFilter }),
    },
  };

  if (startAdornment) {
    InputProps.startAdornment = (
      <InputAdornment
        disableTypography={true}
        position="start"
        classes={{ positionStart: classes.adornmentPositionStart }}
      >
        {startAdornment}
      </InputAdornment>
    );
  }

  if (endAdornment) {
    InputProps.endAdornment = (
      <InputAdornment disableTypography={true} position="end">
        {endAdornment}
      </InputAdornment>
    );
  }

  inputProps.maxLength = maxLength || 256;

  if (tabIndex) {
    inputProps.tabIndex = parseInt(tabIndex);
  }

  return (
    <TextField
      variant="outlined"
      autoComplete="off"
      style={{ ...style, display: isForm ? "inline-flex" : "block" }}
      InputProps={InputProps}
      inputProps={inputProps}
      FormHelperTextProps={helperTextProps}
      InputLabelProps={{
        shrink: true,
      }}
      className={classes.formControlRoot}
      {...otherProps}
    />
  );
};

TextInput.propTypes = {
  startAdornment: PropTypes.node,
  variant: PropTypes.oneOf(["form", "filter", "standalone"]),
  tabIndex: PropTypes.number,
};

export const TextAreaInput = ({ variant, ...otherProps }) => {
  const classes = useStyles();
  const isForm = variant === "form";

  return (
    <TextField
      InputProps={{
        classes: {
          root: clsx(classes.textareaRoot, { [classes.formInputRoot]: isForm }),
          input: clsx(classes.textareaInput, { [classes.formInput]: isForm }),
        },
      }}
      className={classes.formControlRoot}
      multiline={true}
      variant="outlined"
      {...otherProps}
    />
  );
};

export const SliderInput = ({ showMarks, ...props }) => {
  const classes = useStyles();

  return (
    <Slider
      classes={{
        root: classes.sliderRoot,
        thumb: classes.sliderThumb,
        mark: showMarks ? classes.sliderMark : classes.sliderMarkHide,
        markLabel: classes.sliderMarkLabel,
        markLabelActive: classes.sliderMarkLabelActive,
        valueLabel: classes.valueLabel,
      }}
      {...props}
    />
  );
};

export const StyledSelectFormControl = withStyles((theme) => ({
  root: {
    width: "100%",
    whiteSpace: "nowrap",
    "& .MuiInput-underline:before": {
      borderWidth: 0,
    },
    "& .MuiInput-underline:after": {
      borderWidth: 0,
    },
    "& .MuiInput-underline:hover:not(.Mui-disabled):before": {
      borderWidth: 0,
    },
  },
}))(FormControl);

export const StyledFilterSelect = (props) => {
  const classes = useStyles();
  const { size = "lg", ...otherProps } = props;

  return (
    <Select
      classes={{
        select: clsx(classes.select, classes.filterselect, { [classes.filterselectlg]: size === "lg" }),
        icon: classes.filterselecticon,
      }}
      MenuProps={{ PopoverClasses: { paper: classes.selectPaper } }}
      {...otherProps}
    />
  );
};

StyledFilterSelect.propTypes = {
  size: PropTypes.oneOf(["lg", "sm"]),
};

export const StyledSelect = (props) => {
  const classes = useStyles();

  return (
    <Select
      classes={{ select: classes.select, icon: classes.selecticon }}
      MenuProps={{ PopoverClasses: { paper: classes.selectPaper } }}
      {...props}
    />
  );
};

export const SearchAdornment = (props) => {
  const { color = "gray" } = props;
  const classes = useStyles();
  return (
    <SearchIcon
      className={clsx(classes.searchIcon, { [classes.searchIconPrimary]: color === "primary" })}
      {...props}
      fontSize="small"
    />
  );
};

StyledFilterSelect.propTypes = {
  color: PropTypes.string,
};

export const StyledButtonGroup = withStyles((theme) => ({
  root: {
    padding: 0,
  },
  grouped: {
    whiteSpace: "nowrap",
    letterSpacing: "0.12em",
    padding: "0px 5px",
    fontSize: "0.6rem",
    lineHeight: 1,
    backgroundColor: theme.palette.background.paperButton,
    minWidth: 50,
    height: 20,
    "&.Mui-disabled": {
      backgroundColor: theme.palette.background.button.primary,
      color: theme.palette.text.selected.primary,
      borderColor: theme.palette.border.primary,
    },
  },
}))(ButtonGroup);

export const StyledSecondaryButtonGroup = withStyles((theme) => ({
  root: {
    padding: 0,
  },
  grouped: {
    whiteSpace: "nowrap",
    letterSpacing: "0.12em",
    padding: "0px 5px",
    fontSize: "0.6rem",
    lineHeight: 1,
    color: theme.palette.secondary.main,
    backgroundColor: theme.palette.background.paperButton,
    borderColor: theme.palette.border.secondary,
    minWidth: 50,
    height: 20,
    "&.Mui-disabled": {
      color: theme.palette.text.selected.secondary,
      backgroundColor: theme.palette.background.button.secondary,
      borderColor: theme.palette.border.secondary,
    },
  },
}))(ButtonGroup);

export const StyledMenuItem = withStyles((theme) => ({
  root: {
    fontSize: "0.7rem",
    color: theme.palette.text.textInput,
    margin: "0 4px",
  },
}))(MenuItem);

export const StyledSecondaryTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: theme.palette.background.tooltip.secondary,
    color: theme.palette.common.white,
    letterSpacing: "0.03em",
    fontWeight: "normal",
    fontSize: "0.7rem",
    zIndex: 20000,
  },
}))(Tooltip);

export const StyledTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: theme.palette.background.tooltip.primary,
    color: theme.palette.common.white,
    letterSpacing: "0.03em",
    fontWeight: "normal",
    fontSize: "0.7rem",
    zIndex: 20000,
  },
}))(Tooltip);

export const StyledInfoIcon = withStyles((theme) => ({
  root: {
    fontSize: "0.95rem",
    marginBottom: -3,
    marginLeft: 4,
    color: theme.palette.primary.main,
  },
}))(InfoIcon);

export const StyledSecondaryInfoIcon = withStyles((theme) => ({
  root: {
    fontSize: "0.95rem",
    marginBottom: -3,
    marginLeft: 4,
    color: theme.palette.secondary.main,
  },
}))(InfoIcon);

export const StyledWarningInfoIcon = withStyles((theme) => ({
  root: {
    fontSize: "0.95rem",
    marginBottom: -3,
    marginLeft: 4,
    color: theme.palette.text.warning,
  },
}))(InfoIcon);

export const StyledCheckmarkIcon = withStyles((theme) => ({
  root: {
    fontSize: "1.0rem",
    marginBottom: -3,
    marginLeft: 4,
    color: theme.palette.primary.main,
  },
}))(CheckIcon);

export const StyledWarningCheckmarkIcon = withStyles((theme) => ({
  root: {
    fontSize: "1.0rem",
    marginBottom: -3,
    marginLeft: 4,
    color: theme.palette.text.warning,
  },
}))(CheckIcon);

export const InlineErrorTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: alpha("#eaeaea", 0.95),
    color: theme.palette.error.main,
    letterSpacing: "0.03em",
    fontWeight: "normal",
    fontSize: "0.7rem",
  },
  arrow: {
    color: alpha("#eaeaea", 0.9),
  },
  popper: {
    top: "-10px !important",
  },
}))(Tooltip);

export const StyledCheck = (props) => {
  const classes = useStyles();

  return (
    <Checkbox
      classes={{ root: classes.checkboxRoot }}
      size="small"
      disableRipple
      disableFocusRipple
      icon={<StyledCheckIcon />}
      checkedIcon={<StyledCheckedIcon />}
      {...props}
    />
  );
};

export const StyledCheckIcon = withStyles((theme) => ({
  root: {
    fontSize: "0.8rem",
  },
}))(CheckBoxOutlineBlankIcon);

export const StyledCheckedIcon = withStyles((theme) => ({
  root: {
    fontSize: "0.8rem",
  },
}))(CheckBoxIcon);

export const StyledIconButton = withStyles((theme) => ({
  root: {
    padding: 0,
    borderRadius: 4,
    "&:hover": {
      backgroundColor: "transparent",
    },
    "&.Mui-disabled": {
      opacity: 0.5,
    },
  },
}))(IconButton);

export const StyledEditIcon = withStyles((theme) => ({
  root: {
    fontSize: "1.0rem",
    color: theme.palette.primary.main,
  },
}))(EditIcon);

export const StyledCancelIcon = withStyles((theme) => ({
  root: {
    fontSize: "1.0rem",
    color: theme.palette.error.main,
  },
}))(CancelIcon);

export const ActionButton = withStyles((theme) => ({
  root: {
    minWidth: 40,
    lineHeight: 1.1,
    padding: "6px 10px",
    fontSize: "0.65rem",
    color: theme.palette.text.selected.secondary,
    backgroundColor: theme.palette.background.button.secondary,
    "&:hover": {
      backgroundColor: alpha(theme.palette.background.button.secondary, 0.5),
      color: theme.palette.text.selected.secondary,
    },
    "&.MuiButton-outlined.Mui-disabled": {
      borderColor: theme.palette.border.disabled,
      color: theme.palette.text.unselected.disabled,
      backgroundColor: alpha(theme.palette.background.button.disabledsecondary, 0.3),
    },
  },
  label: {
    whiteSpace: "nowrap",
  },
}))(Button);

export const LabelledIconButton = withStyles((theme) => ({
  root: {
    padding: "2px 8px",
    fontSize: "0.6rem",
    lineHeight: 1,
    fontWeight: "normal",
    borderRadius: 4,
    minWidth: 40,
    backgroundColor: theme.palette.background.paperButton,
  },
}))(Button);

export const FormButton = withStyles((theme) => ({
  root: {
    fontSize: "0.6rem",
    lineHeight: 1,
    padding: "8px 15px",
    color: theme.palette.text.selected.primary,
    backgroundColor: theme.palette.background.button.primary,
    borderColor: theme.palette.border.primary,
    letterSpacing: "0.12",
    border: "1px solid",
    whiteSpace: "nowrap",
    "&:hover": {
      color: theme.palette.primary.main,
      borderColor: theme.palette.border.primary,
      border: "1px solid",
    },
    "&.MuiButton-outlined.Mui-disabled": {
      border: "1px solid",
      borderColor: theme.palette.border.disabled,
      color: theme.palette.text.unselected.disabled,
      backgroundColor: theme.palette.background.button.disabledprimary,
    },
  },
}))(Button);

export const StyledFormOutlinedButton = withStyles((theme) => ({
  root: {
    fontSize: "0.6rem",
    lineHeight: 1,
    padding: "8px 15px",
    color: theme.palette.primary.main,
    borderColor: theme.palette.border.primary,
    letterSpacing: "0.12em",
    whiteSpace: "nowrap",
    "&:hover": {
      color: theme.palette.primary.main,
    },
    "&.MuiButton-outlined.Mui-disabled": {
      border: "1px solid",
      borderColor: theme.palette.border.disabled,
      color: theme.palette.text.unselected.disabled,
      backgroundColor: theme.palette.background.button.disabledprimary,
    },
  },
}))(Button);

export const FormOutlinedButton = ({ children, ...otherProps }) => {
  return (
    <StyledFormOutlinedButton variant="outlined" color="primary" {...otherProps}>
      {children}
    </StyledFormOutlinedButton>
  );
};

export const LinkButton = (props) => {
  const classes = useStyles();
  const { label, title, onClick, variant = "inline", color = "secondary", ...otherProps } = props;

  return (
    <Button
      className={clsx(classes.linkbutton, { [classes.linkbuttonForm]: variant === "form" })}
      disableFocusRipple
      onClick={onClick}
      color={color}
      title={title}
      {...otherProps}
    >
      {label}
    </Button>
  );
};

LinkButton.propTypes = {
  label: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  variant: PropTypes.oneOf(["inline", "form"]),
  color: PropTypes.oneOf(["primary", "secondary"]),
};

const StyledSettingsIcon = withStyles((theme) => ({
  root: {
    padding: 0,
    fontSize: "1.0rem",
  },
}))(SettingsIcon);

export const SettingsButton = ({ onClick }) => {
  const classes = useStyles();
  return (
    <Button
      color="secondary"
      variant="outlined"
      className={classes.iconbutton}
      type="button"
      onClick={onClick}
    >
      <StyledSettingsIcon />
    </Button>
  );
};

SettingsButton.propTypes = {
  onClick: PropTypes.func.isRequired,
};

const StyledCalendarIcon = withStyles((theme) => ({
  root: {
    padding: 0,
    fontSize: "1.0rem",
  },
}))(DateRangeIcon);

export const CalendarButton = ({ onClick }) => {
  const classes = useStyles();
  return (
    <Button
      color="secondary"
      variant="outlined"
      className={classes.iconbutton}
      type="button"
      onClick={onClick}
    >
      <StyledCalendarIcon />
    </Button>
  );
};

CalendarButton.propTypes = {
  onClick: PropTypes.func.isRequired,
};

export const PillButton = ({ children, ...otherProps }) => {
  return (
    <Button
      color="secondary"
      variant="outlined"
      size="small"
      style={{
        padding: 4,
        fontSize: "0.6rem",
        lineHeight: 1,
        whiteSpace: "nowrap",
      }}
      type="button"
      {...otherProps}
    >
      {children}
    </Button>
  );
};

export const TooltipBadge = ({ tooltip, icon = Constants.HEADER_BADGE_ICONS.STYLEDINFOICON }) => {
  // At minimum you're doing this because you want a badge with a tooltip....
  if (!tooltip) {
    return null;
  }

  return (
    <StyledSecondaryTooltip title={tooltip}>
      <span className="no-print">
        {icon === Constants.HEADER_BADGE_ICONS.STYLEDINFOICON && <StyledSecondaryInfoIcon />}
      </span>
    </StyledSecondaryTooltip>
  );
};

TooltipBadge.propTypes = {
  tooltip: PropTypes.string,
  icon: PropTypes.string,
};
