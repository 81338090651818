import { setGlobal } from "reactn";
import Cookies from "universal-cookie";
import moment from "moment-timezone";
import { set } from "lodash";

import * as utils from "commons/utils";
import HttpService from "services/commons/HttpService";
import * as DataConfig from "config/dataconfig";

const COOKIE_PREFS = "userprefs";

const cookies = new Cookies();

const getPrefsFromCookie = () => {
  return cookies.get(COOKIE_PREFS) || {};
};

export const setPrefsFromCookie = (theme) => {
  const prefs = getPrefsFromCookie();
  if (utils.hasNonEmptyValue(prefs)) {
    setGlobal({ userprefs: prefs });
  }
};

export const initPreferences = () => {
  HttpService.get(DataConfig.PREFERENCES_ENDPOINT)
    .then((response) => {
      const preferences = JSON.parse(response.uiConfig);
      const oneyear = moment().add(1, "years");
      cookies.set(COOKIE_PREFS, preferences, { path: "/", expires: oneyear.toDate(), sameSite: "strict" });
      setGlobal({ userprefs: preferences });
    })
    .catch((e) => {});
};

export const setPreference = (pref, value) => {
  const prefs = getPrefsFromCookie();
  set(prefs, pref, value);

  setGlobal({ userprefs: prefs });

  const params = {
    url: DataConfig.PREFERENCES_ENDPOINT,
    method: "put",
    data: {
      uiConfig: JSON.stringify(prefs),
    },
  };

  HttpService(params)
    .then((response) => {
      if (response.data.uiConfig) {
        const oneyear = moment().add(1, "years");
        cookies.set(COOKIE_PREFS, JSON.parse(response.data.uiConfig), {
          path: "/",
          expires: oneyear.toDate(),
          sameSite: "strict",
        });
      }
    })
    .catch((e) => {});
};
