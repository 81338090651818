import React from "react";
import { useGlobal } from "reactn";
import PropTypes from "prop-types";
import { get, cloneDeep } from "lodash";

import { useViewState, useViewStateDispatcher } from "providers/ViewStateProvider";
import * as utils from "commons/utils";
import * as Constants from "commons/constants";
import { useMessages } from "providers/BrandingProvider";
import * as DataConfig from "config/dataconfig";
import * as filterUtils from "commons/filterUtils";
import DataTable from "components/datatable/DataTable";
import TableToolbar from "components/toolbars/TableToolbar";
import ImportFavorites from "components/modals/ImportFavorites";
import { MainPaperWithHeader, Div } from "components/containers/Containers";
import { LabelledIconButton } from "components/Controls";

import AddIcon from "@material-ui/icons/Add";

import EmailFrequency from "components/EmailFrequency";
import { fetchFavorites } from "services/FavoritesService";
import useAutoRefresh from "hooks/useAutoRefresh";

const processFavorites = (favorites) => {
  if (!utils.hasNonEmptyValue(favorites)) {
    return [];
  }

  const favoritesBonds = favorites.map((f, i) => {
    let favorite = { ...f.inventoryBondInformation, ...f.bondInformation };

    favorite.id = i + 1;
    favorite.enteredUser = f.enteredUser;
    favorite.brokeredsecurityid = favorite.brokeredsecurityid || favorite.brokeredSecurityID;
    favorite.instrumentCategory = Constants.INSTRUMENT_CATEGORY[favorite.instrumentType.id];
    return favorite;
  });

  return utils.groupByProperty(favoritesBonds, "instrumentCategory");
};

const FavoritesView = ({ onAddBondsToCart, onOpenTradeTicket, setInventoryRefreshedTime }) => {
  const Messages = useMessages();
  const [data, setData] = React.useState([]);
  const [unfilteredData, setUnfilteredData] = React.useState([]);
  const [totalNumberRows, setTotalNumberRows] = React.useState();
  const [favoritesByType, setFavoritesByType] = React.useState({});

  const [favorites] = useGlobal("favorites");
  const [favoritesRefreshedTime] = useGlobal("favoritesRefreshedTime");

  const viewState = useViewState();
  const dispatchViewStateChange = useViewStateDispatcher();
  const currentViewState = get(viewState, Constants.VIEW.FAVORITES, {});

  const getFirstRecordInstrumentCategory = () => {
    let category = null;

    if (utils.hasNonEmptyValue(favorites)) {
      Object.keys(Constants.INSTRUMENT_CATEGORY).some((key) => {
        favorites.some((f) => {
          if (f.bondInformation?.instrumentType?.id === key) {
            category = Constants.INSTRUMENT_CATEGORY[key];
          }
          return utils.hasNonEmptyValue(category);
        });
        return utils.hasNonEmptyValue(category);
      });
    }

    return category || Constants.INVENTORY_TYPE.CD;
  };

  const [showImportModal, setShowImportModal] = React.useState(false);

  const activeInstrumentCategory = get(currentViewState, "activeSubView", getFirstRecordInstrumentCategory());
  const subViewState = get(currentViewState, activeInstrumentCategory, {});
  const selectedBond = get(subViewState, "selectedBond", {});
  const searchId = subViewState?.query?.searchId;

  React.useEffect(() => {
    fetchFavorites();
    handleCurrentViewStateChange({ activeView: Constants.VIEW.FAVORITES });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    if (searchId) {
      fetchFavorites(searchId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchId]);

  React.useEffect(() => {
    setInventoryRefreshedTime(favoritesRefreshedTime);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [favoritesRefreshedTime]);

  React.useEffect(() => {
    const favoritesByType = processFavorites(favorites);
    setFavoritesByType(favoritesByType);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(favorites)]);

  React.useEffect(() => {
    if (subViewState?.query) {
      const categoryData = favoritesByType[activeInstrumentCategory] || [];
      applyFiltersAndSort(categoryData, subViewState.query);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(subViewState), favoritesByType, activeInstrumentCategory]);

  useAutoRefresh(() => fetchFavorites());

  const applyFiltersAndSort = (data, query) => {
    setUnfilteredData(data);
    let filteredSortedData = cloneDeep(data);

    if (query.filter) {
      Object.keys(query.filter).forEach((f) => {
        const filter = query.filter[f];
        if (filter.filterKey && filter.filterKey !== "instrumentCategory") {
          filteredSortedData = filteredSortedData.filter((r, i) => {
            r._original = { ...r };
            return filterUtils.filter({ id: filter.filterKey, value: filter }, r);
          });
        }
      });
    }

    filteredSortedData = utils.sortData(filteredSortedData, query.sort);
    setTotalNumberRows(filteredSortedData.length);

    filteredSortedData = utils.pageData(filteredSortedData, query.page, DataConfig.DEFAULT_PAGE_SIZE);

    setData(filteredSortedData);
  };

  const handleNavOptionSelected = (querySubType) => {
    dispatchViewStateChange({ state: {}, view: Constants.VIEW.FAVORITES, subView: querySubType });
  };

  const handleCurrentViewStateChange = (state, querySubType) => {
    if (!querySubType) {
      dispatchViewStateChange({ state: state, view: Constants.VIEW.FAVORITES });
    } else {
      dispatchViewStateChange({
        state: state,
        view: Constants.VIEW.FAVORITES,
        subView: querySubType,
      });
    }
  };

  const handleOpenImportModal = () => {
    setShowImportModal(true);
  };

  const handleCloseImportModal = (category) => {
    if (category && category !== activeInstrumentCategory) {
      dispatchViewStateChange({ state: {}, view: Constants.VIEW.FAVORITES, subView: category });
    }
    setShowImportModal(false);
  };

  const additionalItems = [
    <div style={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
      <EmailFrequency style={{ marginLeft: "0.4rem" }} prefKey="favorites" />
      <LabelledIconButton
        onClick={handleOpenImportModal}
        variant="outlined"
        color="primary"
        startIcon={<AddIcon />}
      >
        {Messages.LABEL.ADD_FAVORITES}
      </LabelledIconButton>
    </div>,
  ];

  const headerComp = (
    <TableToolbar
      additionalItems={additionalItems}
      view={Constants.VIEW.FAVORITES}
      subView={Constants.SUBVIEW.BUY}
      selectedNavOption={activeInstrumentCategory}
      onNavOptionSelected={handleNavOptionSelected}
    />
  );

  return (
    <Div>
      <MainPaperWithHeader headerItems={headerComp}>
        <DataTable
          manual={true}
          view={Constants.VIEW.FAVORITES}
          queryType={Constants.QUERY_TYPE.FAVORITES}
          querySubType={activeInstrumentCategory}
          instrumentCategory={activeInstrumentCategory}
          data={data}
          unfilteredData={unfilteredData}
          totalNumberRows={totalNumberRows}
          loading={!favorites}
          selectable={true}
          selectedBond={selectedBond}
          autoSelectFirstBond={false} //{!utils.hasNonEmptyValue(selectedBond)}
          onViewStateChange={handleCurrentViewStateChange}
          onAddBondsToCart={onAddBondsToCart}
          onOpenTradeTicket={onOpenTradeTicket}
          setInventoryRefreshedTime={setInventoryRefreshedTime}
        />
      </MainPaperWithHeader>
      <ImportFavorites open={showImportModal} onCloseModal={handleCloseImportModal} />
    </Div>
  );
};

FavoritesView.propTypes = {
  onAddBondsToCart: PropTypes.func.isRequired,
  onOpenTradeTicket: PropTypes.func.isRequired,
  setInventoryRefreshedTime: PropTypes.func.isRequired,
};

export default FavoritesView;
