import React from "react";
import { useGlobal } from "reactn";
import PropTypes from "prop-types";
import clsx from "clsx";
import { NavLink } from "react-router-dom";
import moment from "moment-timezone";

import { useViewStateDispatcher, useViewState } from "providers/ViewStateProvider";
import * as Constants from "commons/constants";
import { useMessages } from "providers/BrandingProvider";
import routes from "config/routes";
import { StyledTooltip } from "components/Controls";
import { Div } from "components/containers/Containers";

import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Badge from "@material-ui/core/Badge";
import IconButton from "@material-ui/core/IconButton";
import DoubleArrowIcon from "@material-ui/icons/DoubleArrow";
import { makeStyles } from "@material-ui/core/styles";
import CartIcon from "@material-ui/icons/Receipt";

import styles from "./sidebarStyle.js";
import { TOOL_TYPE } from "commons/constants.js";
import { useEffect } from "react";
import { LAST_LOGIN_TIME } from "commons/constants.js";
import { useBranding } from "providers/BrandingProvider.jsx";
import { featureFlagEnabled } from "commons/utils.js";

const useStyles = makeStyles(styles);

const Sidebar = ({ onDrawerToggle, onOpenTradeTicket }) => {
  const classes = useStyles();
  const Messages = useMessages();
  const branding = useBranding();
  const [favorites = []] = useGlobal("favorites");
  const [favoriteIssuers = []] = useGlobal("favoriteIssuers");
  const [newsItems = []] = useGlobal("newsItems");
  const [open, setOpen] = React.useState(false);
  const dispatchViewStateChange = useViewStateDispatcher();
  const viewState = useViewState();
  const bondsInCart = viewState.cart || [];

  const permittedRoutes = routes.filter((route) => {
    return (
      (!route.affiliateFeatureFlag || branding.features[route.affiliateFeatureFlag] === true) &&
      (!route.envFeatureFlag || featureFlagEnabled(route.envFeatureFlag))
    );
  });

  const lastLoginTime = React.useRef();
  const lastLogin = lastLoginTime.current ? moment(Number(lastLoginTime.current)) : moment();
  const recentNewsItems = newsItems.filter((item) => {
    const itemDate = moment(item.enteredDate);
    return itemDate.isAfter(lastLogin) && itemDate.add(2, "day").isAfter(moment());
  });

  useEffect(() => {
    lastLoginTime.current = window.localStorage.getItem(LAST_LOGIN_TIME);
    window.localStorage.setItem(LAST_LOGIN_TIME, moment().valueOf());
  }, []);

  const handleDrawerToggle = (open) => {
    setOpen(open);
    onDrawerToggle(open);
  };

  const handleNavItemClick = (view) => {
    handleDrawerToggle(false);

    if (view === Constants.VIEW.INVENTORY) {
      dispatchViewStateChange({
        state: {
          activeView: Constants.VIEW.INVENTORY,
          activeSubView: Constants.SUBVIEW.BUY,
          activeTool: TOOL_TYPE.RATES,
        },
      });
    }
  };

  let links = (
    <List className={classes.list}>
      <ListItem
        button
        className={classes.itemLink}
        style={{ marginBottom: 20 }}
        onClick={() => onOpenTradeTicket()}
      >
        <StyledTooltip placement="right" title={open ? "" : Messages.LABEL.CART}>
          <Badge
            badgeContent={bondsInCart ? bondsInCart.length : 0}
            classes={{ badge: clsx(classes.badge, classes.cartbadge) }}
          >
            <CartIcon className={classes.itemIcon} />
          </Badge>
        </StyledTooltip>
        <ListItemText primary={Messages.LABEL.CART} className={classes.itemText} disableTypography={true} />
      </ListItem>
      {permittedRoutes.map((route, i) => {
        var label = Messages.LABEL[route.view];
        return (
          <NavLink
            to={route.path}
            className={classes.item}
            activeClassName="active"
            key={route.path}
            onClick={(e) => handleNavItemClick(route.view)}
            isActive={(match, location) => {
              if (!match) {
                return false;
              }
              return match.isExact;
            }}
          >
            <ListItem button className={classes.itemLink}>
              {route.path === "/favorites" && (
                <StyledTooltip placement="right" title={open ? "" : label}>
                  <Badge badgeContent={favorites ? favorites.length : 0} classes={{ badge: classes.badge }}>
                    <route.icon className={classes.itemIcon} />
                  </Badge>
                </StyledTooltip>
              )}
              {route.path === "/issuers" && (
                <StyledTooltip placement="right" title={open ? "" : label}>
                  <Badge
                    badgeContent={favoriteIssuers ? Object.keys(favoriteIssuers).length : 0}
                    classes={{ badge: classes.badge }}
                  >
                    <route.icon className={classes.itemIcon} />
                  </Badge>
                </StyledTooltip>
              )}
              {route.path === "/news" && (
                <StyledTooltip placement="right" title={open ? "" : label}>
                  <Badge badgeContent={recentNewsItems.length} classes={{ badge: classes.badge }}>
                    <route.icon className={classes.itemIcon} />
                  </Badge>
                </StyledTooltip>
              )}
              {route.path !== "/favorites" && route.path !== "/issuers" && route.path !== "/news" && (
                <StyledTooltip
                  classes={{ tooltip: classes.tooltip }}
                  placement="right"
                  title={open ? "" : label}
                >
                  <route.icon className={classes.itemIcon} />
                </StyledTooltip>
              )}
              <ListItemText primary={label} className={classes.itemText} disableTypography={true} />
            </ListItem>
          </NavLink>
        );
      })}
    </List>
  );

  return (
    <Drawer
      anchor="left"
      variant="permanent"
      open={open}
      className={clsx(classes.drawer, {
        [classes.drawerOpen]: open,
        [classes.drawerClose]: !open,
      })}
      classes={{
        paper: clsx({
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        }),
      }}
    >
      <div className={classes.sidebarWrapper}>
        {!open && (
          <StyledTooltip title={Messages.TOOLTIP.OPEN_MENU}>
            <IconButton onClick={(e) => handleDrawerToggle(true)} className={classes.menuButton}>
              <DoubleArrowIcon />
            </IconButton>
          </StyledTooltip>
        )}
        {open && (
          <StyledTooltip title={Messages.TOOLTIP.CLOSE_MENU}>
            <IconButton onClick={(e) => handleDrawerToggle(false)} className={classes.menuButton}>
              <DoubleArrowIcon style={{ transform: "scaleX(-1)" }} />
            </IconButton>
          </StyledTooltip>
        )}
        {links}
      </div>
      <Div className={classes.background} />
    </Drawer>
  );
};

Sidebar.propTypes = {
  onDrawerToggle: PropTypes.func,
};

export default Sidebar;
