import React from "react";
import { useGlobal } from "reactn";
import { get } from "lodash";
import PropTypes from "prop-types";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import HighchartsCustomEvents from "highcharts-custom-events";

import { LightTheme } from "theme/LightTheme";
import { DarkTheme } from "theme/DarkTheme";

import * as Constants from "commons/constants";
import * as utils from "commons/utils";
import { useViewState } from "providers/ViewStateProvider";
import { PROPERTY } from "config/dataconfig";
import { RATES_BOND_TYPE } from "commons/constants";
import { CHART_INTRUMENT_MARKER } from "commons/constants";
import { useEffect } from "react";
import { isEqual } from "lodash";

HighchartsCustomEvents(Highcharts);

const DEFAULT_TERMS = [
  PROPERTY["3m"],
  PROPERTY["6m"],
  PROPERTY["9m"],
  PROPERTY["1y"],
  PROPERTY["2y"],
  PROPERTY["3y"],
  PROPERTY["4y"],
  PROPERTY["5y"],
  PROPERTY["7y"],
  PROPERTY["10y"],
  PROPERTY["20y"],
  PROPERTY["30y"],
];
const YieldCurve = ({ data = [], onViewStateChange, onCellNavigation }) => {
  const [chartOptions, setChartOptions] = React.useState();

  const [userPrefs = {}] = useGlobal("userprefs");

  const theme = userPrefs.theme && userPrefs.theme === "dark" ? DarkTheme : LightTheme;

  const instruments = data.map((d) => d.bondType);
  const viewState = useViewState();

  const width = get(viewState, "verticalMainSize", 800);
  const height = get(viewState, "horizontalSecondarySize", 400);
  const selectedItems = get(viewState, [Constants.VIEW.RATES, "selected"], ["Treasuries"]);
  const zoom = get(viewState, [Constants.VIEW.RATES, "zoom"]);

  const chart = React.useRef();

  const generateSeries = () => {
    const series = instruments.map((instrument, i) => {
      const dataRow = data.find((d) => d.bondType.value === instrument.value);
      const bondType = Object.values(RATES_BOND_TYPE).find((t) => t.label === instrument.value);
      if (bondType) {
        const marker = CHART_INTRUMENT_MARKER[bondType.type].symbol;
        const radius = CHART_INTRUMENT_MARKER[bondType.type].radius;

        return {
          name: instrument.value,
          marker: {
            symbol: marker,
            radius: radius ?? 4,
          },
          color: bondType.color,
          data: DEFAULT_TERMS.map((term, i) => {
            let value = get(dataRow, [term.accessor, "value"]);
            if (isNaN(value)) {
              value = null;
            }
            return [term.value.xvalue, value];
          }),
          visible: selectedItems.includes(instrument.value),
        };
      }
      return null;
    });

    return series;
  };

  const defaultTickPositions = DEFAULT_TERMS.map((term) => term.value.xvalue);

  const showHideZoomResetButton = () => {
    const tickPositions = chart.current.xAxis[0].tickPositions;
    const isShowingAll = tickPositions.length === DEFAULT_TERMS.length;
    const zoomButton = document.querySelector(".highcharts-button.highcharts-reset-zoom");
    if (zoomButton) {
      zoomButton.style.display = isShowingAll ? "none" : "block";
    } else if (!isShowingAll) {
      chart.current.showResetZoom();
    }
  };

  const handleXAxisLabelClick = (e) => {
    const maxTerm = e.target.innerHTML;

    if (maxTerm) {
      const maxTermIndex = DEFAULT_TERMS.findIndex((term) => term.value.label === maxTerm);
      const tickPositions = DEFAULT_TERMS.slice(0, maxTermIndex + 1).map((term) => term.value.xvalue);

      if (tickPositions.length === defaultTickPositions.length) {
        chart.current.xAxis[0].setExtremes(null, null);
      } else {
        chart.current.xAxis[0].setExtremes(tickPositions[0], tickPositions[tickPositions.length - 1]);
      }
    }
  };

  const generateChartOptions = () => {
    const options = {
      chart: {
        type: "spline",
        style: {
          fontFamily: "Arial",
        },
        width: width - 50,
        height: height - 60,
        backgroundColor: theme.palette.background.paper,
        zoomType: "x",
        resetZoomButton: {
          position: {
            x: 10,
            y: -10,
          },

          theme: {
            fill: theme.palette.background.paperButton,
            stroke: theme.palette.border.primary,
            r: 4,
            style: {
              letterSpacing: "0.02857em",
              textTransform: "uppercase",
              color: theme.palette.primary.main,
              fontSize: "0.6rem",
            },
            states: {
              hover: {
                fill: theme.palette.background.button.disabledprimarysubtle,
                stroke: theme.palette.border.primary,
              },
            },
          },
        },
        events: {
          render: () => {
            if (chart.current.tooltip) {
              chart.current.tooltip.update({ enabled: true });
            }

            showHideZoomResetButton();
            onViewStateChange({ zoom: [chart.current.xAxis[0].min, chart.current.xAxis[0].max] });
          },
          load: () => {
            if (zoom) {
              chart.current.xAxis[0].setExtremes(zoom[0], zoom[1]);
            }
          },
        },
      },
      title: {
        text: null,
      },
      credits: {
        enabled: false,
      },
      legend: {
        layout: "vertical",
        align: "right",
        verticalAlign: "top",
        itemMarginTop: 2,
        itemMarginBottom: 2,
        itemStyle: {
          color: theme.palette.text.secondary,
          cursor: "pointer",
          fontSize: "12px",
          fontWeight: "normal",
          textOverflow: "ellipsis",
        },
        itemHoverStyle: {
          color: theme.palette.text.primary,
        },
        itemHiddenStyle: {
          color: theme.palette.text.disabled,
        },
        title: {
          text: "Click to add/remove",
          style: { fontWeight: "normal", fontSize: "90%", color: "#999" },
        },
      },
      xAxis: {
        tickPositions: defaultTickPositions,
        lineColor: theme.palette.border.axis,
        title: {
          text: "Click any term label to zoom",
          style: { fontWeight: "normal", fontSize: "90%", color: "#999" },
        },
        plotBands: {
          events: {
            mousedown: () => {
              if (chart.current.tooltip) {
                chart.current.tooltip.update({ enabled: false });
              }
            },
            mouseout: () => {
              if (chart.current.tooltip) {
                chart.current.tooltip.update({ enabled: true });
              }
            },
          },
        },
        labels: {
          style: {
            color: theme.palette.text.secondary,
            cursor: "pointer",
          },
          staggerLines: 2,
          formatter: function () {
            const term = DEFAULT_TERMS.find((t) => {
              return t.value.xvalue === this.value;
            });

            const label = term?.value.label || "";
            return label;
          },
          events: {
            click: handleXAxisLabelClick,
          },
        },
      },
      yAxis: {
        title: {
          text: "Yield (%)",
          style: {
            color: theme.palette.text.secondary,
          },
        },
        labels: {
          formatter: function () {
            return `${utils.formatNumber(this.value, 1)}`;
          },
          style: {
            color: theme.palette.text.secondary,
          },
        },
        tickInterval: 0.5,
        gridLineColor: theme.palette.border.table,
      },
      tooltip: {
        crosshairs: true,
        shared: true,
        useHTML: true,
        backgroundColor: theme.palette.background.default,
        borderColor: theme.palette.border.secondary,
        formatter: function () {
          const term = DEFAULT_TERMS.find((t) => t.value.xvalue === this.x);
          const formattedTooltip = this.points.reduce(
            (prev, point) => {
              if (!point.series?.name) return prev;

              const symbolHtml = Object.values(CHART_INTRUMENT_MARKER).find(
                (m) => m.symbol === point.point?.graphic?.symbol,
              );

              return `
                ${prev}
                <tr>
                  <td style="text-align: center;color:${point.color}">${
                symbolHtml ? symbolHtml.html : ""
              }</td>
                  <td>
                    <span style="color:${theme.palette.text.tertiary}">${point.series.name}: </span>
                    <span style="color:${theme.palette.text.secondary}"><b>${point.y}</b></span>
                  </td>
                </tr>
              `;
            },
            `
              <table>
                <tr>
                  <td colspan="2" style="font-size: 12px; padding-bottom: 4px;">
                    <span style="color:${theme.palette.text.tertiary}">Term: </span>
                    <span style="color:${theme.palette.text.secondary}"><b>${term.value.label}</b></span>
                  </td>
                </tr>
            `,
          );

          return `${formattedTooltip}</table>`;
        },
      },
      plotOptions: {
        spline: {
          lineWidth: 1.5,
          marker: {
            radius: 3,
          },
          stickyTracking: false,
        },
        series: {
          cursor: "pointer",
          events: {
            legendItemClick: function (e) {
              let selected = e.target.chart.series.filter((s) => s.visible).map((s) => s.name);
              const itemWasSelected = e.target.visible;
              if (itemWasSelected) {
                selected = selected.filter((s) => s !== e.target.name);
              } else {
                selected.push(e.target.name);
              }

              onViewStateChange({ selected });
            },
          },
          point: {
            events: {
              click: function (e) {
                const dataRow = data.find((d) => d.bondType.value === e.point.series.name);
                const term = DEFAULT_TERMS.find((t) => t.value.xvalue === e.point.category);
                const datum = dataRow[term.accessor];
                if (datum.queryString) {
                  onCellNavigation(datum.queryString);
                } else if (datum.link) {
                  window.open(datum.link);
                }
              },
            },
          },
        },
      },
      series: generateSeries(),
    };

    return options;
  };

  React.useEffect(() => {
    if (data && data.length) {
      if (!chartOptions) {
        const options = generateChartOptions();
        setChartOptions(options);
      } else {
        const updatedSeries = generateSeries();
        updatedSeries.forEach((updated) => {
          const currentDataIndex = chart.current.series.findIndex((current) => current.name === updated.name);
          if (currentDataIndex > -1 && !isEqual(chart.current.series[currentDataIndex].data, updated.data)) {
            chart.current.series[currentDataIndex].update({ data: updated.data });
          }
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(data)]);

  useEffect(() => {
    if (chartOptions) {
      const options = generateChartOptions();
      setChartOptions(options);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [width, height, theme]);

  const chartCreated = React.useCallback(
    (e) => {
      chart.current = e;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  return (
    <div style={{ backgroundColor: theme.palette.background.paper, padding: "20px 5px 20px 5px" }}>
      {chartOptions && (
        <HighchartsReact
          immutable={true}
          highcharts={Highcharts}
          options={chartOptions}
          callback={chartCreated}
        />
      )}
    </div>
  );
};

YieldCurve.propTypes = {
  data: PropTypes.array,
  onViewStateChange: PropTypes.func.isRequired,
  onCellNavigation: PropTypes.func.isRequired,
};

export default YieldCurve;
