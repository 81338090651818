import React from "react";
import PropTypes from "prop-types";
import { get, cloneDeep, isEqual } from "lodash";
import moment from "moment";

import { useViewState, useViewStateDispatcher } from "providers/ViewStateProvider";
import * as utils from "commons/utils";
import * as Constants from "commons/constants";
import { useMessages } from "providers/BrandingProvider";
import * as DataConfig from "config/dataconfig";
import QBTypography from "components/QBTypography";
import DataTable from "components/datatable/DataTable";
import CusipSearch from "components/CusipSearch";
import { useDataService } from "services/DataService";

import { SearchContainer, Div } from "components/containers/Containers";
import { makeStyles } from "@material-ui/core/styles";
import { compBondQtyFilterDefaultValue } from "commons/utils";
import AdvancedCriteriaModal from "./modals/advancedcriteria/AdvancedCriteriaModal";

const useStyles = makeStyles((theme) => ({
  topbar: {
    display: "flex",
  },
  nobond: {
    margin: "auto",
    display: "flex",
    flexGrow: 1,
    alignItems: "center",
  },
  searchWrapper: {
    textAlign: "center",
  },
  filterWrapper: {
    display: "flex",
    justifyContent: "center",
    margin: "4px 0",
  },
  label: {
    color: theme.palette.text.secondary,
    lineHeight: 1,
    textAlign: "right",
    fontSize: "0.6rem",
    marginTop: 5,
    marginBottom: 5,
  },
}));

// Translate increment filters to true filters
const getQueryFilter = (filter, bondValue) => {
  if (filter && filter.filterType === Constants.FILTER_TYPE.RATING_INCREMENT) {
    filter.filterType = Constants.FILTER_TYPE.SELECTRATING;
    if (!utils.hasNonEmptyValue(bondValue) || isNaN(filter.filterValue.increment)) {
      filter.filterValue = null;
    } else {
      if (bondValue > 99) {
        bondValue = 17;
      }
      filter.filterValue = {
        value: Math.min(Math.max(bondValue + filter.filterValue.increment, 11), 28),
        exclude: 999,
      };
    }
  }
  if (filter && filter.filterType === Constants.FILTER_TYPE.NUMERIC_RANGE_INCREMENT) {
    filter.filterType = Constants.FILTER_TYPE.DUALRANGE;
    if (!utils.hasNonEmptyValue(bondValue) || isNaN(filter.filterValue)) {
      filter.filterValue = [null, null];
    } else {
      filter.filterValue = [bondValue - filter.filterValue, bondValue + filter.filterValue];
    }
  }
  if (filter && filter.filterType === Constants.FILTER_TYPE.DATE_RANGE_INCREMENT) {
    filter.filterType = Constants.FILTER_TYPE.DATERANGE;
    if (!utils.hasNonEmptyValue(bondValue) || isNaN(filter.filterValue)) {
      filter.filterValue = [null, null];
    } else {
      filter.filterValue = [
        moment(bondValue, DataConfig.DATE_FORMAT.API)
          .subtract(filter.filterValue, "months")
          .format(DataConfig.DATE_FORMAT.FILTER),
        moment(bondValue, DataConfig.DATE_FORMAT.API)
          .add(filter.filterValue, "months")
          .format(DataConfig.DATE_FORMAT.FILTER),
      ];
    }
  }
  return filter;
};

const ComparableBonds = ({ onAddBondsToCart, onOpenTradeTicket }) => {
  const classes = useStyles();
  const Messages = useMessages();
  const [bond, setBond] = React.useState();
  const [data, setData] = React.useState([]);
  const [modalData, setModalData] = React.useState();

  const [state, queryDataService] = useDataService();

  const viewState = useViewState();
  const dispatchViewStateChange = useViewStateDispatcher();
  const comparableView = get(viewState, Constants.VIEW.COMPARABLE, {});
  const compareBond = comparableView.compareBond || {};
  const selectedBond = comparableView.selectedBond;

  const qtyFilter = utils.findCompBondQuantityFilter(viewState, compareBond);
  const qtyFilterValue = qtyFilter.filterValue[0];

  const excludeOwnedCdsFilter = utils.findCompBondExcludeOwnedCdsFilter(viewState, compareBond);
  const excludeOwnedCdsFilterValue = excludeOwnedCdsFilter.filterValue;

  const query = utils.findActiveCompBondQuery(viewState, compareBond);
  const searchId = query?.searchId;

  React.useEffect(() => {
    if (utils.hasNonEmptyValue(compareBond?.cusip)) {
      if (compareBond?.instrumentCategory) {
        executeCompBondQuery();
      } else {
        const inventoryInstrumentCategory = get(
          viewState,
          `${Constants.VIEW.INVENTORY}.${Constants.SUBVIEW.BUY}.activeQuerySubType`,
          Constants.INVENTORY_TYPE.CD,
        );

        setBond({
          cusip: compareBond.cusip,
          instrumentCategory: bond ? bond.instrumentCategory : inventoryInstrumentCategory,
        });
        setData([]);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [compareBond?.cusip, qtyFilterValue, excludeOwnedCdsFilterValue, searchId]);

  React.useEffect(() => {
    if (state.isLoading || state.isError || state.isInit) {
      return;
    }

    tagBondsAsCompBonds(state.data);

    if (!isEqual(JSON.stringify(state.data), JSON.stringify(data))) {
      setData(state.data);
    } else {
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state]);

  const executeCompBondQuery = () => {
    if (utils.hasNonEmptyValue(searchId)) {
      let updatedQuery = cloneDeep(query);
      let updatedFilter = {};

      for (var filterKey in updatedQuery.filter) {
        updatedFilter[filterKey] = getQueryFilter(
          updatedQuery.filter[filterKey],
          get(compareBond, filterKey),
        );
      }

      updatedFilter[qtyFilter.filterKey] = qtyFilter;

      if (
        compareBond.instrument !== Constants.INVENTORY_TYPE.TREASURY &&
        compareBond.instrument !== Constants.INVENTORY_TYPE.MORTGAGE
      ) {
        updatedFilter.callable = {
          filterKey: "callable",
          filterValue: compareBond.callable,
          filterType: Constants.FILTER_TYPE.TOGGLE,
        };
      }

      // filter needs to exclude compared bond cusip
      updatedFilter.cusip = {
        filterKey: "cusip",
        filterValue: compareBond.cusip,
        filterType: Constants.FILTER_TYPE.NEGATING,
      };
      updatedQuery.filter = updatedFilter;
      updatedQuery.clearDataOnFetch = false;
      updatedQuery.suppressLoading = false;

      // Track any customization
      updatedQuery.trackingMetadata = ["detailedCustomBondComp"];
      queryDataService(updatedQuery);

      setBond(compareBond);
    }
    // query if a) bond has not been set, b) no query has yet occurred, or
    // c) cusip or filter has changed
    else if (
      !utils.hasNonEmptyValue(bond) ||
      !utils.hasNonEmptyValue(state.query) ||
      bond.cusip !== compareBond.cusip ||
      state.query.searchId ||
      qtyFilterValue !== get(state, `query.filter.${qtyFilter.filterKey}.filterValue`, [null])[0] ||
      excludeOwnedCdsFilterValue !== get(state, `query.filter.excludeOwnedCdsFilterValue.filterValue`, false)
    ) {
      let queryParams = {
        type: Constants.QUERY_TYPE.COMPARABLE,
        identifier: {
          filterKey: "cusip",
          filterValue: compareBond.cusip,
        },
        filter: {},
        clearDataOnFetch: false,
        suppressLoading: false,
      };

      // Count as a customization only if they have adjusted from the default values
      queryParams.filter[qtyFilter.filterKey] = qtyFilter;
      queryParams.filter["excludeOwnedCds"] = excludeOwnedCdsFilter;
      if (qtyFilterValue !== compBondQtyFilterDefaultValue(compareBond?.instrumentCategory)) {
        queryParams.trackingMetadata = ["simpleCustomBondComp"];
      }

      queryDataService(queryParams);
      setBond(compareBond);
    }
  };

  // Any data coming through this component should be tagged with the referrer
  // of comp bonds so its tracked on the buy.
  const tagBondsAsCompBonds = (dataArray) => {
    if (dataArray) {
      dataArray
        // We have non-bond rows mixed in here.
        .filter((datum) => datum?.brokeredsecurityid)
        .forEach((datum) => {
          datum.referrerid = Constants.TRACKING_REFERRER_IDS.COMP_BONDS;
        });
    }
  };

  const handleCurrentViewStateChange = (state) => {
    if (state.selectedBond && bond?.cusip && bond?.instrumentCategory) {
      const updatedState = {
        state: state,
        view: Constants.VIEW.COMPARABLE,
        subView: bond.instrumentCategory,
        querySubType: bond.cusip,
      };

      dispatchViewStateChange(updatedState);
    }
  };

  const handleToggleAdvancedCriteriaModal = (tier, filterConfigs) => {
    setModalData({ tier: tier, filterConfigs: filterConfigs });
  };

  if (bond) {
    return (
      <>
        <Div className="comparableBondTable">
          <DataTable
            queryType={Constants.QUERY_TYPE.COMPARABLE}
            instrumentCategory={bond?.instrumentCategory}
            data={data}
            selectable={true}
            selectedBond={selectedBond}
            loading={state.isLoading && !state.query.suppressLoading}
            error={state.isError}
            onViewStateChange={handleCurrentViewStateChange}
            onAddBondsToCart={onAddBondsToCart}
            onOpenTradeTicket={onOpenTradeTicket}
            onToggleAdvancedCriteriaModal={handleToggleAdvancedCriteriaModal}
          />
          {searchId && state.data.length === 1 && (
            <div style={{ width: "100%", textAlign: "center", margin: "5px 0" }}>
              {query.description && (
                <QBTypography component="span">
                  <span>Your custom search</span>
                  <span>{`${query.description} `}</span>
                </QBTypography>
              )}
              {!query.description && (
                <QBTypography component="span">
                  <span>Your custom search </span>
                </QBTypography>
              )}
              <QBTypography component="span">
                <span>
                  yielded no results. Adjust your custom search parameters to improve Comparable Bond search
                  results.
                </span>
              </QBTypography>
            </div>
          )}
        </Div>
        <AdvancedCriteriaModal
          tier={modalData?.tier}
          filterConfigs={modalData?.filterConfigs}
          onClose={() => setModalData(null)}
        />
      </>
    );
  }

  return (
    <Div className={classes.nobond}>
      <SearchContainer>
        <div className={classes.searchWrapper}>
          <QBTypography variant="caption">{Messages.MESSAGE.NO_COMPARABLE}</QBTypography>
          <div className={classes.filterWrapper}>
            <CusipSearch />
          </div>
        </div>
      </SearchContainer>
    </Div>
  );
};

ComparableBonds.propTypes = {
  onAddBondsToCart: PropTypes.func.isRequired,
  onOpenTradeTicket: PropTypes.func.isRequired,
};

export default ComparableBonds;
