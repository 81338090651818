import React from "react";
import PropTypes from "prop-types";
import { useGlobal } from "reactn";

import { useViewStateDispatcher } from "providers/ViewStateProvider";
import * as Constants from "commons/constants";

const PATH_MAP = {
  quotes: Constants.TRADE_TYPE.PRICE_QUOTES,
  bids: Constants.TRADE_TYPE.BIDS_RFQ,
  offers: Constants.TRADE_TYPE.BONDS_FOR_SALE,
};

function SellRedirectHandler(props) {
  const { location } = props;
  const dispatchViewStateChange = useViewStateDispatcher();
  const [isAuthenticated] = useGlobal("authenticated");

  React.useEffect(() => {
    if (location.pathname && isAuthenticated) {
      const paths = location.pathname.split("/").filter((p) => p !== "");
      let view = paths.length > 1 ? paths[1].toLowerCase() : "quotes";
      if (!Object.keys(PATH_MAP).includes(view)) {
        view = "quotes";
      }
      dispatchViewStateChange({
        state: {
          activeView: Constants.VIEW.INVENTORY,
          activeSubView: Constants.SUBVIEW.SELL,
          activeQuerySubType: PATH_MAP[view],
        },
        view: Constants.VIEW.INVENTORY,
        subView: Constants.SUBVIEW.SELL,
        querySubType: PATH_MAP[view],
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname, isAuthenticated]);

  return null;
}

SellRedirectHandler.propTypes = {
  location: PropTypes.object.isRequired,
};

export default SellRedirectHandler;
