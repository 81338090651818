import React from "react";
import { get, set, cloneDeep } from "lodash";

import routes from "config/routes";
import * as utils from "commons/utils";
import * as Constants from "commons/constants";

const ViewStateContext = React.createContext();
const ViewStateDispatcherContext = React.createContext();

function viewStateReducer(currentViewState, action) {
  const { state = {}, view, subView, querySubType } = action;

  let doUpdateActiveViews = true;

  if (state.hasOwnProperty("activeView")) {
    currentViewState.activeView = state.activeView;
    const viewConfig = utils.findInArray(routes, "view", state.activeView)[0];
    currentViewState.isSplitScreen = viewConfig?.secondaryComponent !== "none";

    delete state.activeView;
  }

  if (state.hasOwnProperty("activeSubView") && currentViewState.hasOwnProperty("activeView")) {
    const activeViewKey = currentViewState.activeView;
    currentViewState[activeViewKey] = get(currentViewState, activeViewKey, {});
    currentViewState[activeViewKey].activeSubView = state.activeSubView;
    delete state.activeSubView;
  }

  if (state.hasOwnProperty("activeQuerySubType") && currentViewState.hasOwnProperty("activeView")) {
    const activeViewKey = currentViewState.activeView;
    if (currentViewState[activeViewKey].hasOwnProperty("activeSubView")) {
      const activeSubViewkey = currentViewState[activeViewKey].activeSubView;
      currentViewState[activeViewKey][activeSubViewkey] =
        currentViewState[activeViewKey][activeSubViewkey] || {};
      currentViewState[activeViewKey][activeSubViewkey].activeQuerySubType = state.activeQuerySubType;
    } else {
      currentViewState[activeViewKey] = currentViewState[activeViewKey] || {};
      currentViewState[activeViewKey].activeQuerySubType = state.activeQuerySubType;
    }

    delete state.activeQuerySubType;
  }

  if (state.hasOwnProperty("selectedBond")) {
    let activeView = utils.findCurrentViewState(currentViewState);
    set(currentViewState, [Constants.VIEW.DETAIL, "selectedBond"], state.selectedBond);

    if (view !== Constants.VIEW.COMPARABLE) {
      activeView.selectedBond = state.selectedBond;
      set(currentViewState, [Constants.VIEW.COMPARABLE, "compareBond"], state.selectedBond);
      set(currentViewState, [Constants.VIEW.COMPARABLE, "selectedBond"], null);
    } else {
      set(currentViewState, [Constants.VIEW.COMPARABLE, "selectedBond"], state.selectedBond);
    }

    doUpdateActiveViews = false;
    delete state.selectedBond;
  }

  if (state.hasOwnProperty("compareBond")) {
    set(currentViewState, [Constants.VIEW.COMPARABLE, "compareBond"], state.compareBond);
    doUpdateActiveViews = false;
    delete state.compareBond;
  }

  if (state.hasOwnProperty("activeTool")) {
    currentViewState.activeTool = state.activeTool;
    delete state.activeTool;
  }

  let updatedViewState = get(currentViewState, view, {});

  if (subView) {
    updatedViewState = get(updatedViewState, subView, {});
    currentViewState[view] = currentViewState[view] || {};
    if (querySubType) {
      updatedViewState = get(updatedViewState, querySubType, {});

      currentViewState[view][subView] = get(currentViewState[view], subView, {});
      currentViewState[view][subView][querySubType] = { ...updatedViewState, ...state };

      if (doUpdateActiveViews) {
        currentViewState[view].activeSubView = subView;
        currentViewState[view][subView].activeQuerySubType = querySubType;
      }
    } else {
      currentViewState[view][subView] = { ...updatedViewState, ...state };
      if (doUpdateActiveViews) {
        currentViewState[view].activeSubView = subView;
      }
    }
  } else if (view) {
    currentViewState[view] = { ...updatedViewState, ...state };
  } else {
    currentViewState = { ...currentViewState, ...state };
  }

  // save to session for restoration
  window.sessionStorage.setItem(Constants.STORAGE_SESSION_STATE, JSON.stringify(currentViewState));

  return cloneDeep(currentViewState);
}

function ViewStateProvider({ initialState = {}, children }) {
  const [viewState, dispatch] = React.useReducer(viewStateReducer, initialState);

  return (
    <ViewStateContext.Provider value={viewState}>
      <ViewStateDispatcherContext.Provider value={dispatch}>{children}</ViewStateDispatcherContext.Provider>
    </ViewStateContext.Provider>
  );
}

function useViewState() {
  const context = React.useContext(ViewStateContext);
  if (context === undefined) {
    throw new Error("useViewState must be used within a ViewStateProvider");
  }
  return context;
}

function useViewStateDispatcher() {
  const context = React.useContext(ViewStateDispatcherContext);
  if (context === undefined) {
    throw new Error("useViewStateDispatcher must be used within a ViewStateDispatcherContext");
  }
  return context;
}

export { ViewStateProvider, useViewState, useViewStateDispatcher };
