import React from "react";
import { useGlobal, setGlobal } from "reactn";
import clsx from "clsx";
import { Router, Switch, Route, Redirect } from "react-router-dom";
import { createBrowserHistory } from "history";
import { uniq, uniqBy, isEmpty, throttle } from "lodash";
import * as Sentry from "@sentry/react";

import * as Constants from "commons/constants";
import * as DataConfig from "config/dataconfig";
import { setPreference } from "services/PreferencesService";
import { useViewState, useViewStateDispatcher } from "providers/ViewStateProvider";
import { fetchUserInfo, getUser, hasAccessToken } from "commons/helpers/userStorage";
import HttpService from "services/commons/HttpService";
import * as utils from "commons/utils";

import ApplicationHeader from "components/ApplicationHeader";
import Login from "components/modals/login/Login";
import { TradeTicket } from "components/modals/TradeTicket";
import { RequestTicket } from "components/modals/RequestTicket";
import Sidebar from "components/sidebar/Sidebar";
import SplitPane from "components/containers/SplitPane";
import HorizontalSplitPane from "components/containers/HorizontalSplitPane";
import { Div } from "components/containers/Containers";
import { InformationDialog } from "components/modals/InformationDialog";
import SelectCartDialog from "components/modals/SelectCartDialog";
import DemoCartDialog from "components/modals/DemoCartDialog";
import Tools from "pages/views/Tools";
import BondSidebar from "pages/views/BondSidebar";
import PrintDetail from "pages/PrintDetail";

import routes from "config/routes";
import OAuth2CallbackHandler from "pages/OAuth2CallbackHandler";
import BuyRedirectHandler from "pages/BuyRedirectHandler";
import SellRedirectHandler from "pages/SellRedirectHandler";
import CartRedirectHandler from "pages/CartRedirectHandler";
import Loading from "pages/Loading";
import { redirectToLoginPage } from "services/OAuth2ClientService";
import { redirectToSignUpPage } from "services/OAuth2ClientService";
import { fetchAllFavorites } from "services/FavoritesService";
import { initPreferences } from "services/PreferencesService";

import { makeStyles } from "@material-ui/core/styles";
import { hasNonEmptyValue } from "commons/utils";
import ApplicationLoading from "./ApplicationLoading";
import { useMessages } from "providers/BrandingProvider";
import { CART_SIZE, STORAGE_LAST_ACTIVITY_TIME } from "commons/constants";
import { actuallyLoggedIn } from "services/commons/HttpService";
import { checkBuildVersion } from "OutdatedVersionModal";
import OutdatedVersionModal from "OutdatedVersionModal";
import { useBranding } from "providers/BrandingProvider";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    width: "100%",
  },
  main: {
    position: "absolute",
    left: 56,
    right: 8,
    top: 0,
    bottom: 0,
    overflow: "hidden",
    maxWidth: 1950,
    background: theme.palette.background.main,
  },
  backdrop: {
    position: "absolute",
    zIndex: 200,
    left: 56,
    top: 0,
    background: "rgba(0, 0, 0, 0.33)",
    transition: theme.transitions.create("background", {
      easing: theme.transitions.easing.easeIn,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  hidden: {
    display: "none",
  },
  unclickable: {
    pointerEvents: "none",
  },
}));

const history = createBrowserHistory();
const initRef = React.createRef();
const isSSOEnabled = process.env.REACT_APP_FEATURES_SSO === "true";

const ApplicationLayout = () => {
  const classes = useStyles();

  const viewState = useViewState();
  const Messages = useMessages();
  const branding = useBranding();

  const [openLogin, setOpenLogin] = React.useState(false);
  const [showTradeTicket, setShowTradeTicket] = React.useState(false);
  const [selectCartOpen, setSelectCartOpen] = React.useState(false);
  const [tradeTicketInfo, setTradeTicketInfo] = React.useState({});
  const [demoOpen, setDemoOpen] = React.useState(false);
  const [filteredBondsInCart, setFilteredBondsInCart] = React.useState([]);
  const [bondsInRequest, setBondsInRequest] = React.useState([]);
  const [requestTicketTradeType, setRequestTicketTradeType] = React.useState();
  const [windowWidth, setWindowWidth] = React.useState();
  const [windowHeight, setWindowHeight] = React.useState();
  const [inventoryRefreshedTime, setInventoryRefreshedTime] = React.useState();
  const [userLoaded, setUserLoaded] = React.useState(false);
  const [loadingErrorMsg, setLoadingErrorMsg] = React.useState("");
  const [fullyLoaded, setFullyLoaded] = React.useState(false);

  const [drawerOpen, setDrawerOpen] = React.useState(false);
  const [referrer, setReferrer] = React.useState(null);

  const idleIntervalId = React.useRef();

  const [isAuthenticated] = useGlobal("authenticated");
  const [userPrefs = {}] = useGlobal("userprefs");

  const dispatchViewStateChange = useViewStateDispatcher();

  const activeView = viewState.activeView;
  let bondsInCart = viewState.cart || [];

  const isNonSpaPage =
    window.location.pathname === "/oauth2callback" ||
    window.location.pathname === "/loading" ||
    window.location.pathname === "/detail";

  const isSignUpPage = window.location.pathname === "/signUp";

  const needLoadingPage = !isNonSpaPage;

  const handleWindowUnload = (e) => {
    const cart = JSON.parse(window.sessionStorage.getItem(Constants.STORAGE_SESSION_STATE))?.cart;
    if (!isEmpty(cart)) {
      Sentry.captureMessage("ABANDONED CART", (scope) => {
        scope.setExtra("cart", cart);
      });
    }
  };

  React.useEffect(() => {
    checkBuildVersion();

    setGlobal({
      authenticated: hasAccessToken(),
    });

    window.addEventListener("blur", handleWindowBlur, false);
    window.addEventListener("beforeunload", handleWindowUnload, false);

    return function () {
      window.removeEventListener("blur", handleWindowBlur, false);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Use this to control waiting on all asynchronous application dependencies we need
  // before we can dismiss the loading screen.
  React.useEffect(() => {
    if (isAuthenticated && userLoaded) {
      setLoadingErrorMsg(null);
      setFullyLoaded(true);

      document.title = Messages.BRANDING.APPLICATION_NAME;

      const user = getUser();
      const privileges = user.privileges.join(", ");

      const affiliate = user?.hasQBAffiliate ? user.affiliate.affiliateIdentifier : "none";

      Sentry.setUser({
        id: user.id,
        name: `${user.firstName} ${user.lastName}`,
        email: user.email,
        companyId: user.companyId,
        institution: user.institution,
        account: user.account,
        affiliate: affiliate,
        privileges: privileges,
      });
      Sentry.setTag("Email", user.email);
      Sentry.setTag("Institution", user.institution);
      Sentry.setTag("QN-Account", user.companyId);
      Sentry.setTag("QB-Account", user.account);
      Sentry.setTag("Privileges", privileges);
      Sentry.setTag("Affiliate", affiliate);
    } else {
      setFullyLoaded(false);
      Sentry.configureScope((scope) => scope.setUser(null));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userLoaded, isAuthenticated]);

  React.useEffect(() => {
    if (!isNonSpaPage) {
      if (hasAccessToken()) {
        if (isAuthenticated) {
          setOpenLogin(false);
          initPreferences();
          fetchUserInfo(onUserFetchSuccess, onUserFetchError);
          initiateUserActivityTracking();
        }
      } else {
        if (isSignUpPage) {
          redirectToSignUpPage(`${window.location.pathname}${window.location.search}${window.location.hash}`);
        } else if (isSSOEnabled) {
          redirectToLoginPage(`${window.location.pathname}${window.location.search}${window.location.hash}`);
        } else {
          setOpenLogin(true);
        }
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated]);

  // stores time user last interacted with application via mouse, keyboard
  const setActivityTimer = () => {
    window.sessionStorage.setItem(STORAGE_LAST_ACTIVITY_TIME, new Date().getTime());
  };

  // The events which will cause an update of the activity timer
  const listenEvents = ["mousemove", "keydown", "mousedown", "touchstart"];

  //
  // Set up logout on idle checks. Use sessionStorage so we coordinate across tabs
  //
  const initiateUserActivityTracking = () => {
    if (isAuthenticated) {
      window.addEventListener("focus", validateSession);

      if (!idleIntervalId.current) {
        // In case of refresh or reload of page - no stale data - set to current time.
        setActivityTimer();

        // Bind all events, throttle so we aren't thrashing sessionStorage
        listenEvents.forEach((event) => {
          window.addEventListener(event, throttle(setActivityTimer, 500, { leading: true, trailing: true }));
        });

        // Check if the user should be logged out every 60 secs
        idleIntervalId.current = window.setInterval(checkIfUserShouldBeLoggedOut, 5000);
      }
    } else {
      window.removeEventListener("focus", validateSession);

      if (idleIntervalId.current) {
        // Clear any active timer
        clearInterval(idleIntervalId.current);
        idleIntervalId.current = undefined;
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  };

  // tests if user has valid token, logs user out is not
  const validateSession = () => {
    actuallyLoggedIn();
    checkBuildVersion();
  };

  //
  // If user has been idle for 5 minutes, validate session to ensure user token is still valid, otherwise
  // user is logged out.  If user has been idle for 2 minutes and an inventory-related query has not been
  // executed in 2 minutes, refresh data
  const checkIfUserShouldBeLoggedOut = async () => {
    const maxIdleUserTimeMs = 5 * 60 * 1000; // 5 minutes
    const rightNow = new Date().getTime();

    let lastUserActivity = window.sessionStorage.getItem(STORAGE_LAST_ACTIVITY_TIME);
    if (!lastUserActivity) {
      lastUserActivity = rightNow;
    }

    const userIdleTime = rightNow - lastUserActivity;

    if (userIdleTime > maxIdleUserTimeMs) {
      validateSession();
    }
  };

  React.useEffect(() => {
    if (utils.hasNonEmptyValue(userPrefs) && utils.hasNonEmptyValue(userPrefs.defaultCompBondSearch)) {
      if (!utils.hasNonEmptyValue(initRef.current)) {
        HttpService.get(DataConfig.COMPARISON_ENDPOINT.SAVED).then((response) => {
          if (response && response.status === Constants.HTTP_CODES.SUCCESS) {
            Object.keys(userPrefs.defaultCompBondSearch).forEach((instrument) => {
              const search = response.data.content.find(
                (r) => r.id === userPrefs.defaultCompBondSearch[instrument],
              );
              if (utils.hasNonEmptyValue(search)) {
                const query = JSON.parse(search.filterConfig);
                query.filter.instrumentCategory = instrument;
                query.id = search.id;
                query.searchId = Date.now();
                const state = {};

                state[instrument] = {
                  query: query,
                };

                const updatedState = {
                  state: state,
                  view: Constants.VIEW.COMPARABLE,
                };

                dispatchViewStateChange(updatedState);
              }
            });
          }
        });
      }

      initRef.current = "initialized";
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userPrefs]);

  React.useEffect(() => {
    if (activeView) {
      const currentPath = history.location.pathname;
      const currentRouteConfig = utils.findInArray(routes, "path", currentPath)[0];

      if (
        (!currentRouteConfig || activeView !== currentRouteConfig.view) &&
        utils.findInArray(routes, "view", activeView).length > 0
      ) {
        const newRouteConfig = utils.findInArray(routes, "view", activeView);
        history.push(newRouteConfig[0].path);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeView]);

  React.useLayoutEffect(() => {
    function updateSize() {
      setWindowWidth(window.innerWidth - 64);
      setWindowHeight(window.innerHeight - 40);
    }
    window.addEventListener("resize", updateSize);
    updateSize();
    return () => window.removeEventListener("resize", updateSize);
  }, []);

  const generateNextTradeTicket = (instrumentCategory) => {
    const msbBonds = bondsInCart.filter(
      (bond) => bond.instrumentCategory === Constants.INVENTORY_TYPE.MORTGAGE,
    );
    const otherBonds = bondsInCart.filter(
      (bond) => bond.instrumentCategory !== Constants.INVENTORY_TYPE.MORTGAGE,
    );

    //const totalNumTickets = Math.ceil(msbBonds.length / CART_SIZE) + Math.ceil(otherBonds.length / CART_SIZE);
    const updatedTicketNum = tradeTicketInfo?.ticketNum + 1;

    const ticketInfo = {
      ticketNum: updatedTicketNum,
      totalNumTickets: tradeTicketInfo.totalNumTickets,
      hasNextTicket: tradeTicketInfo.totalNumTickets > updatedTicketNum,
    };

    if (
      utils.hasNonEmptyValue(msbBonds) &&
      (instrumentCategory === Constants.INVENTORY_TYPE.MORTGAGE || !otherBonds.length)
    ) {
      return [msbBonds.slice(0, CART_SIZE), ticketInfo];
    } else if (otherBonds.length) {
      return [otherBonds.slice(0, CART_SIZE), ticketInfo];
    }
    return [
      [],
      {
        ticketNum: 0,
        totalNumTickets: 0,
        hasNextTicket: false,
      },
    ];
  };

  const fetchAnnouncements = async () => {
    HttpService.get(DataConfig.ACTIVE_ANNOUNCEMENTS_ENDPOINT)
      .then((response) => {
        if (response?.status === Constants.HTTP_CODES.SUCCESS) {
          let alreadySeenIds = userPrefs.announcementsSeen || [];
          const msgs = response.data.content;

          const text = msgs
            .filter((msg) => !alreadySeenIds.includes(msg.id))
            .map((msg) => {
              return msg.message;
            });

          const updatedAlreadySeenIds = uniq(
            msgs
              .filter((msg) => !msg.persistent)
              .map((msg) => {
                return msg.id;
              })
              .concat(alreadySeenIds),
          );

          if (utils.hasNonEmptyValue(text)) {
            utils.issueAnnouncements(text);
          }

          setPreference("announcementsSeen", updatedAlreadySeenIds);
        }
      })
      .catch((e) => {
        console.error(`Error fetching announcements: ${e}`);
      });
  };

  const fetchNews = async () => {
    if (branding.features["NEWS_PAGE"] === true) {
      HttpService.get(DataConfig.NEWS_ENDPOINT)
        .then((response) => {
          if (response?.status === Constants.HTTP_CODES.SUCCESS) {
            const newsItems = response.data.content.reduce((acc, item) => {
              if (item.id === 0) return acc;

              const message = JSON.parse(item.message);
              return acc.concat({ ...item, ...message });
            }, []);
            setGlobal({ newsItems });
          }
        })
        .catch((e) => {
          console.error(`Error fetching announcements: ${e}`);
        });
    }
  };

  const onUserFetchSuccess = () => {
    setUserLoaded(true);
    fetchAllFavorites();
    fetchAnnouncements();
    fetchNews();
  };

  const onUserFetchError = (error) => {
    setUserLoaded(false);
    setLoadingErrorMsg(Messages.MESSAGE.FETCH_PROFILE_WARNING);
  };

  const handleWindowBlur = () => {
    setReferrer(null);
  };

  const closeLogin = () => {
    setOpenLogin(false);
  };

  const handleDrawerToggle = (open) => {
    setDrawerOpen(open);
  };

  const handleCartReferrerError = (referrer) => {
    setReferrer(referrer);
  };

  const handleCloseTradeTicket = (populateNextTicket, instrumentCategory) => {
    const [nextTicket, ticketInfo] = generateNextTradeTicket(instrumentCategory);
    if (populateNextTicket && nextTicket.length) {
      setFilteredBondsInCart(nextTicket);
      setTradeTicketInfo(ticketInfo);
    } else {
      setShowTradeTicket(false);
    }
  };

  const handleOpenTradeTicket = (instrumentCategory, updatedBonds, addingBonds) => {
    const bonds = updatedBonds || bondsInCart;
    const msbBonds = bonds.filter((bond) => bond.instrumentCategory === Constants.INVENTORY_TYPE.MORTGAGE);
    const otherBonds = bonds.filter((bond) => bond.instrumentCategory !== Constants.INVENTORY_TYPE.MORTGAGE);
    const totalNumTickets = Math.ceil(msbBonds.length / CART_SIZE) + Math.ceil(otherBonds.length / CART_SIZE);
    const ticketInfo = {
      ticketNum: 1,
      totalNumTickets: totalNumTickets,
      hasNextTicket: totalNumTickets > 1,
      addingBonds: addingBonds,
    };

    if (!instrumentCategory && msbBonds.length && otherBonds.length) {
      setSelectCartOpen(true);
    } else if (instrumentCategory === Constants.INVENTORY_TYPE.MORTGAGE || !otherBonds.length) {
      setFilteredBondsInCart(msbBonds.slice(0, CART_SIZE));
      setTradeTicketInfo(ticketInfo);
      setShowTradeTicket(true);
    } else {
      setFilteredBondsInCart(otherBonds.slice(0, CART_SIZE));
      setTradeTicketInfo(ticketInfo);
      setShowTradeTicket(true);
    }
  };

  const handleCartUpdated = ({ deleted, updated }) => {
    if (deleted) {
      bondsInCart = bondsInCart.filter((bond) => !deleted.some((b) => b.cusip === bond.cusip));
    }

    if (updated) {
      const updatedBondsIds = updated.map((b) => b.brokeredsecurityid);
      bondsInCart = bondsInCart.filter((bond) => !updatedBondsIds.includes(bond.brokeredsecurityid));
      bondsInCart = updated.concat(bondsInCart);
    }

    dispatchViewStateChange({ state: { cart: bondsInCart } });
  };

  const handleAddBondsToCart = (bonds, currentBonds) => {
    if (utils.hasNonEmptyValue(bonds)) {
      let updatedBonds = currentBonds || [];

      updatedBonds = uniqBy([...utils.cloneArrayOfObjects(bonds), ...updatedBonds], "brokeredsecurityid");
      dispatchViewStateChange({ state: { cart: updatedBonds } });

      const isReferred = updatedBonds.some((bond) => bond.returnURL);

      if (!isReferred && userPrefs && !userPrefs.cartdemoseen && updatedBonds.length === 1) {
        setDemoOpen(true);
      } else {
        handleOpenTradeTicket(bonds[0].instrumentCategory, updatedBonds, true);
      }
    }
  };

  const handleOpenRequestTicket = (tradeType, requests) => {
    if (utils.hasNonEmptyValue(requests)) {
      setBondsInRequest(utils.cloneArrayOfObjects(requests));
    }
    setRequestTicketTradeType(tradeType);
  };

  const handleCloseRequestTicket = () => {
    setBondsInRequest([]);
    setRequestTicketTradeType(null);
  };

  return (
    <>
      <Router history={history}>
        <Switch>
          <Route path="/loading" component={Loading} />
          <Route path="/oauth2callback" component={OAuth2CallbackHandler} />
          <Route path="/bonds" component={BuyRedirectHandler} />
          <Route path="/sell" component={SellRedirectHandler} />
          <Route path="/detail" component={PrintDetail} />
          <Route
            path="/cart"
            render={(routeProps) => (
              <CartRedirectHandler
                {...routeProps}
                onAddBondsToCart={handleAddBondsToCart}
                onError={handleCartReferrerError}
              />
            )}
          />
        </Switch>
        {needLoadingPage && fullyLoaded && (
          <div className={classes.root}>
            <Sidebar onDrawerToggle={handleDrawerToggle} onOpenTradeTicket={handleOpenTradeTicket} />
            <div
              className={clsx(classes.main, {
                [classes.unclickable]: drawerOpen,
              })}
            >
              <ApplicationHeader inventoryRefreshedTime={inventoryRefreshedTime} />
              <SplitPane width={windowWidth} hidden={!isAuthenticated}>
                <SplitPane.Main>
                  <HorizontalSplitPane height={windowHeight}>
                    <HorizontalSplitPane.Main>
                      <Switch>
                        {routes.map(({ path, component: Component }) => (
                          <Route
                            exact
                            key={path}
                            path={path}
                            render={(routeProps) =>
                              isAuthenticated ? (
                                <Component
                                  {...routeProps}
                                  onAddBondsToCart={handleAddBondsToCart}
                                  onOpenRequestTicket={handleOpenRequestTicket}
                                  onOpenTradeTicket={handleOpenTradeTicket}
                                  setInventoryRefreshedTime={setInventoryRefreshedTime}
                                />
                              ) : null
                            }
                          />
                        ))}
                        <Route render={() => <Redirect to="/" />} />
                      </Switch>
                    </HorizontalSplitPane.Main>
                    <HorizontalSplitPane.Secondary>
                      {isAuthenticated && !isNonSpaPage ? (
                        <Tools
                          onAddBondsToCart={handleAddBondsToCart}
                          onOpenTradeTicket={handleOpenTradeTicket}
                        />
                      ) : null}
                    </HorizontalSplitPane.Secondary>
                  </HorizontalSplitPane>
                </SplitPane.Main>
                <SplitPane.Secondary>
                  {isAuthenticated && !isNonSpaPage ? (
                    <BondSidebar
                      onAddBondsToCart={handleAddBondsToCart}
                      onOpenRequestTicket={handleOpenRequestTicket}
                      onOpenTradeTicket={handleOpenTradeTicket}
                    />
                  ) : null}
                </SplitPane.Secondary>
              </SplitPane>
              <TradeTicket
                open={showTradeTicket}
                onCloseTradeTicket={handleCloseTradeTicket}
                bonds={filteredBondsInCart}
                onCartUpdated={handleCartUpdated}
                ticketInfo={tradeTicketInfo}
              />
              <RequestTicket
                open={utils.hasNonEmptyValue(requestTicketTradeType)}
                onCloseRequestTicket={handleCloseRequestTicket}
                bondsInRequest={bondsInRequest}
                tradeType={requestTicketTradeType}
              />
              <Login open={openLogin} onSuccess={closeLogin} />
            </div>
          </div>
        )}
        {needLoadingPage && !fullyLoaded && (
          <ApplicationLoading
            text={loadingErrorMsg || Messages.MESSAGE.QB_APPLICATION_LOADING}
            hasError={hasNonEmptyValue(loadingErrorMsg)}
          />
        )}
        <Div
          className={clsx(classes.backdrop, {
            [classes.hidden]: !drawerOpen && isAuthenticated,
          })}
        ></Div>
        <InformationDialog
          message={`${Messages.MESSAGE.RETURN_TO_REFERRER_ERROR.replace(
            "{0}",
            referrer ? referrer.returnSiteName : "",
          )}`}
          isOpen={utils.hasNonEmptyValue(referrer)}
          setIsOpen={() => {
            setReferrer(null);
          }}
          cancelLabel={Messages.LABEL.CLOSE}
        />
        <SelectCartDialog
          isOpen={selectCartOpen}
          onClose={() => setSelectCartOpen(false)}
          onCartSelected={(instrument) => handleOpenTradeTicket(instrument)}
        />
        <DemoCartDialog
          isOpen={demoOpen}
          onOpenTradeTicket={handleOpenTradeTicket}
          onClose={() => setDemoOpen(false)}
        />
        <OutdatedVersionModal />
      </Router>
    </>
  );
};

export default ApplicationLayout;
