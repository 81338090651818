import React from "react";
import PropTypes from "prop-types";
import { Formik } from "formik";
import * as Yup from "yup";
import Cookies from "universal-cookie";

import { useMessages } from "providers/BrandingProvider";
import * as utils from "commons/utils";
import * as Constants from "commons/constants";
import { FormTextField } from "components/forms/FormikFields";
import {
  ModalForm,
  ModalFormFieldset,
  ModalFormLinkButtonset,
  ModalSubmitButton,
} from "../common/FormComponents";
import { LinkButton } from "components/Controls";
import { resetPassword } from "services/AuthService";
import ModalMessages from "../common/ModalMessages";

import EmailOutlinedIcon from "@material-ui/icons/EmailOutlined";
import AccountCircleOutlinedIcon from "@material-ui/icons/AccountCircleOutlined";

import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  adornment: {
    fontSize: "1.0rem",
    color: theme.palette.primary.main,
  },
}));

const cookies = new Cookies();

const ForgetPasswordForm = ({ onLoginClick, onNewAccountClick }) => {
  const classes = useStyles();
  const Messages = useMessages();

  const [errorMessages, setErrorMessages] = React.useState([]);
  const [successMessages, setSuccessMessages] = React.useState([]);

  const savedRememberValue = cookies.get("rememberme");
  const savedUsername =
    utils.hasNonEmptyValue(savedRememberValue) && savedRememberValue !== "false" ? savedRememberValue : "";

  React.useEffect(() => {
    setErrorMessages([]);
    setSuccessMessages([]);
  }, []);

  const ForgetPasswordSchema = Yup.object().shape({
    username: Yup.string().required(Messages.MESSAGE.VALIDATION.REQUIRED),
    email: Yup.string()
      .email(Messages.MESSAGE.VALIDATION.INVALID_FORMAT)
      .required(Messages.MESSAGE.VALIDATION.REQUIRED),
  });

  return (
    <React.Fragment>
      <ModalMessages messages={successMessages} level={Constants.ALERT_LEVEL.INFO} />
      <ModalMessages messages={errorMessages} level={Constants.ALERT_LEVEL.ERROR} />
      <Formik
        initialValues={{ username: savedUsername, email: "" }}
        validationSchema={ForgetPasswordSchema}
        onSubmit={(values) => {
          setErrorMessages([]);
          setSuccessMessages([]);

          resetPassword({ username: values.username, email: values.email })
            .then((response) => {
              setSuccessMessages([`An email has been sent to ${values.email} with your new password.`]);
            })
            .catch((error) => {
              setErrorMessages([Messages.MESSAGE.GENERAL_RETRY]);
            });
        }}
      >
        {({ errors, touched }) => (
          <ModalForm>
            <ModalFormFieldset>
              <FormTextField
                placeholder={Messages.FORM_LABEL.USERNAME}
                name="username"
                autoFocus={true}
                startAdornment={<AccountCircleOutlinedIcon className={classes.adornment} />}
              />
              <FormTextField
                placeholder={Messages.FORM_LABEL.EMAIL}
                name="email"
                type="email"
                autoFocus={true}
                startAdornment={<EmailOutlinedIcon className={classes.adornment} />}
              />
            </ModalFormFieldset>
            {(onLoginClick || onNewAccountClick) && (
              <ModalFormLinkButtonset>
                {onLoginClick && (
                  <LinkButton onClick={onLoginClick} variant="form" label={Messages.LABEL.LOG_INTO_ACCOUNT} />
                )}
                {onNewAccountClick && (
                  <LinkButton
                    onClick={onNewAccountClick}
                    variant="form"
                    label={Messages.LABEL.NEED_ACCOUNT}
                  />
                )}
              </ModalFormLinkButtonset>
            )}
            <ModalSubmitButton label={Messages.LABEL.REQUEST_PASSWORD} />
          </ModalForm>
        )}
      </Formik>
    </React.Fragment>
  );
};

ForgetPasswordForm.propTypes = {
  onLoginClick: PropTypes.func,
  onNewAccountClick: PropTypes.func,
};

export default ForgetPasswordForm;
