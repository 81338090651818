import React from "react";
import { pick, isEqual, cloneDeep } from "lodash";
import { v4 as uuid } from "uuid";

import { getUser } from "commons/helpers/userStorage";
import { useMessages } from "providers/BrandingProvider";

import * as Constants from "commons/constants";
import QBTypography from "components/QBTypography";
import { ClosableModal } from "components/containers/Modals";
import { LabelledIconButton } from "components/Controls";
import { PillButton } from "components/Controls";
import { SimpleConfirmation } from "./InformationDialog";
import NewsItemList from "components/news/NewsItemList";
import NewsItemEditor from "components/news/NewsItemEditor";
import { useDataService } from "services/DataService";
import { QUERY_TYPE } from "commons/constants";
import ModalMessages from "./common/ModalMessages";
import { TicketButton, TicketRow } from "./tickets/TicketComponents";

import { makeStyles } from "@material-ui/core/styles";
import AnnouncementOutlinedIcon from "@material-ui/icons/AnnouncementOutlined";
import { Backdrop, CircularProgress } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  newsItemEditor: {
    border: `1px solid ${theme.palette.border.main}`,
    backgroundColor: theme.palette.background.paperForm,
    borderRadius: theme.shape.borderRadius,
  },
  backdrop: {
    backgroundColor: theme.palette.background.disabled,
  },
}));

const getEmptyNewsItem = () => {
  const item = {
    header: "",
    itemText: "",
    links: [
      {
        id: uuid(),
      },
    ],
    key: uuid(),
  };
  return item;
};

export const NewsAdmin = ({ newsItems: currentNewsItems, onClose }) => {
  const classes = useStyles();

  const Messages = useMessages();

  const [open, setOpen] = React.useState(false);
  const [newsItems, setNewsItems] = React.useState([]);
  const [itemToEdit, setItemToEdit] = React.useState(getEmptyNewsItem());

  const [errorMessages, setErrorMessages] = React.useState([]);
  const [infoMessages, setInfoMessages] = React.useState([]);

  const [confirmationModal, setConfirmationModal] = React.useState();
  const [updateState, updateWithDataService] = useDataService();

  const user = getUser();

  const highestOrderedItem = newsItems
    ? newsItems.reduce((max, item) => {
        return Math.max(max, item.order);
      }, 0)
    : 0;

  React.useEffect(() => {
    if (open) {
      setNewsItems(currentNewsItems || []);
      setEmptyItem();
      setErrorMessages([]);
      setInfoMessages([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  React.useEffect(() => {
    if (!updateState.isInit && !updateState.isLoading && !updateState.isError) {
      if (updateState.data) {
        const message = JSON.parse(updateState.data.message);
        const item = { ...updateState.data, ...message };
        setNewsItems((items) => {
          let idx = items.findIndex((i) => i.id === item.id);
          if (idx > -1) {
            items.splice(idx, 1, item);
          } else {
            items.push(item);
          }
          return [...items];
        });
        setItemToEdit(item);
      }
      const msg = `News item has been ${updateState.query.action === "delete" ? "deleted" : "saved"}`;
      setInfoMessages([msg]);
    } else if (updateState.isError) {
      const msg = `There was an error ${
        updateState.query.action === "delete" ? "deleting" : "saving"
      } news item.  Please try again.`;
      setErrorMessages([msg]);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateState]);

  const addOrUpdateNewsItem = (action, newsItem) => {
    setErrorMessages([]);
    setInfoMessages([]);

    delete newsItem.edited;

    const queryParams = {
      type: QUERY_TYPE.NEWS_ACTION,
      action,
      clearDataOnFetch: false,
    };

    if (newsItem) {
      const message = pick(newsItem, ["header", "itemText", "links", "order"]);
      queryParams.params = {
        message: JSON.stringify(message),
      };
      if (newsItem.id) {
        queryParams.identifier = newsItem.id;
      }
    }

    updateWithDataService(queryParams);
  };

  const closeModal = () => {
    setOpen(false);
    onClose();
  };

  const setEmptyItem = () => {
    setErrorMessages([]);
    setInfoMessages([]);
    setItemToEdit(getEmptyNewsItem());
  };

  const handleCloseModal = () => {
    alertIfUnsavedData(closeModal);
  };

  const handleNewItem = () => {
    setErrorMessages([]);
    setInfoMessages([]);
    alertIfUnsavedData(setEmptyItem);
  };

  const handleSubmitItem = (item) => {
    addOrUpdateNewsItem(item.id ? "put" : "post", item);
  };

  const handleItemChange = (item) => {
    setErrorMessages([]);
    setInfoMessages([]);
    item.order = item.order ?? highestOrderedItem + 1;
    setItemToEdit(item);
  };

  const handleDeleteItem = (item) => {
    setErrorMessages([]);
    setInfoMessages([]);

    setConfirmationModal({
      open: true,
      onCloseModal: () => {
        setNewsItems((items) => {
          const updatedMsgs = items.filter((i) => i.id !== item.id);
          return [...updatedMsgs];
        });
        if (itemToEdit?.id === item.id) {
          setEmptyItem();
        }
        addOrUpdateNewsItem("delete", item);
      },
      message: "Are you sure you want to delete this item?",
    });
  };

  const handleSelectMessage = (msg) => {
    setErrorMessages([]);
    setInfoMessages([]);
    alertIfUnsavedData(() => setItemToEdit(cloneDeep(msg)));
  };

  const areLinksEqual = (aLinks, bLinks) => {
    const aPickedLinks = aLinks.map((link) => pick(link, ["file", "url", "text"]));
    const bPickedLinks = bLinks.map((link) => pick(link, ["file", "url", "text"]));
    return isEqual(aPickedLinks, bPickedLinks);
  };

  const areNewsItemsEqual = (aItem, bItem) => {
    return (
      aItem.header === bItem.header &&
      aItem.itemText === bItem.itemText &&
      areLinksEqual(aItem.links, bItem.links)
    );
  };

  const alertIfUnsavedData = (callback) => {
    let savedItem = newsItems.find((i) => i.id === itemToEdit?.id);

    if (!savedItem) {
      savedItem = getEmptyNewsItem();
    }

    const hasUnsavedData = !areNewsItemsEqual(savedItem, itemToEdit);

    if (hasUnsavedData) {
      setConfirmationModal({
        open: true,
        onCloseModal: callback,
        message:
          "You have unsaved changes to the current item. Are you sure you want to proceed and lose these changes?",
      });
    } else {
      callback();
    }
  };

  return (
    <>
      {user.affiliateAdmin && (
        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          <LabelledIconButton
            onClick={() => setOpen(!open)}
            variant="outlined"
            color="primary"
            startIcon={<AnnouncementOutlinedIcon />}
          >
            {Messages.LABEL.NEWS_ADMIN}
          </LabelledIconButton>
        </div>
      )}
      <ClosableModal
        open={open}
        header={Messages.LABEL.NEWS_ADMIN}
        onClose={handleCloseModal}
        scrollable={false}
      >
        <div style={{ minHeight: 20 }}>
          <ModalMessages
            wrapperStyle={{ marginTop: 0 }}
            messages={errorMessages}
            level={Constants.ALERT_LEVEL.ERROR}
          />
          <ModalMessages
            wrapperStyle={{ marginTop: 0 }}
            messages={infoMessages}
            level={Constants.ALERT_LEVEL.INFO}
          />
        </div>
        <div
          style={{
            maxWidth: 900,
            minHeight: "85vh",
            display: "flex",
            alignItems: "flex-start",
            gap: 30,
            padding: "20px 10px 20px 10px",
          }}
        >
          <div style={{ minWidth: 400, maxWidth: 400 }}>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                marginBottom: 20,
              }}
            >
              <QBTypography variant="h3" color="secondary">
                {Messages.LABEL.CURRENT_NEWS_ITEMS}
              </QBTypography>
              <PillButton color="primary" onClick={handleNewItem}>
                {Messages.LABEL.NEW_NEWS_ITEM}
              </PillButton>
            </div>
            <NewsItemList
              newsItems={newsItems}
              setMessages={setNewsItems}
              itemToEdit={itemToEdit}
              onEdit={(msg) => handleSelectMessage(msg)}
              onDelete={(msg) => handleDeleteItem(msg)}
              setConfirmationModal={setConfirmationModal}
            />
          </div>
          <div
            className={classes.newsItemEditor}
            style={{
              minWidth: 400,
              maxWidth: 400,
              maxHeight: "calc(100vh - 180px)",
              display: "flex",
              flexDirection: "column",
              padding: 20,
              position: "relative",
            }}
          >
            <Backdrop
              className={classes.backdrop}
              style={{ position: "absolute", zIndex: 200 }}
              open={updateState.isLoading}
            >
              <CircularProgress color="secondary" />
            </Backdrop>
            <QBTypography variant="h3" color="secondary">
              {itemToEdit?.id ? Messages.LABEL.EDIT_NEWS_ITEM : Messages.LABEL.NEW_NEWS_ITEM}
            </QBTypography>
            <NewsItemEditor
              newsItems={newsItems}
              item={itemToEdit}
              onSubmit={handleSubmitItem}
              onNewsItemChange={handleItemChange}
              setConfirmationModal={setConfirmationModal}
              loading={updateState.isLoading}
              key={itemToEdit?.id ?? itemToEdit?.key}
            />
          </div>
        </div>
        <TicketRow style={{ marginTop: 0, justifyContent: "center" }}>
          <TicketButton onClick={handleCloseModal} label={Messages.LABEL.CLOSE} />
        </TicketRow>
      </ClosableModal>
      {confirmationModal && (
        <SimpleConfirmation
          message={confirmationModal?.message}
          isOpen={confirmationModal?.open}
          setIsOpen={() => setConfirmationModal(null)}
          onCloseModal={confirmationModal?.onCloseModal}
        />
      )}
    </>
  );
};

export default NewsAdmin;
