import React from "react";
import deburr from "lodash/deburr";
import { get, uniqBy, padStart } from "lodash";
import PropTypes from "prop-types";
import { useGlobal } from "reactn";

import * as utils from "commons/utils";
import * as Constants from "commons/constants";
import { useMessages } from "providers/BrandingProvider";
import RemoveFilterButton from "components/filters/common/RemoveFilterButton";

import { useDataService } from "services/DataService";

import { makeStyles } from "@material-ui/core/styles";

import FavoriteIssuer from "components/FavoriteIssuer";
import Typeahead from "components/Typeahead";
import { isTypeaheadEqual } from "components/Typeahead";

const useStyles = makeStyles((theme) => ({
  wrapper: {
    flexGrow: 1,
  },
  filterText: {
    padding: 0,
    margin: 0,
    marginTop: 3,
    marginBottom: 3,
    fontSize: ".65rem",
    color: theme.palette.text.secondary,
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    overflow: "hidden",
    letterSpacing: "0.03em",
    height: 18,
  },
  removeFilter: {
    fontSize: ".6rem",
  },
  removeIcon: {
    fontSize: "0.7rem",
  },
  trackerButtonWrapper: {
    display: "flex",
    justifyContent: "center",
    borderBottom: `1px solid ${theme.palette.border.main}`,
    margin: "4px 8px",
    padding: "4px 4px 8px",
  },
}));

const KeywordFilter = ({
  filter,
  filterKey,
  filterType,
  baseFilter,
  showFilterText,
  placeholder,
  onFilterChange,
  data,
  color,
  lookahead = true,
  style = {},
  pad = 0,
  maxLength = 100,
}) => {
  const classes = useStyles();
  const Messages = useMessages();
  let currentFilter = get(filter, "filterValue");

  if (pad > 0 && utils.hasNonEmptyValue(currentFilter)) {
    while (currentFilter.indexOf("0") === 0) {
      currentFilter = currentFilter.substring(1);
    }
  }

  const [favoriteIssuers = []] = useGlobal("favoriteIssuers");
  const favoriteIssuerCusips = Object.keys(favoriteIssuers);

  const [value, setValue] = React.useState("");
  const [processedData, setProcessedData] = React.useState([]);

  const filterText = utils.getFilterDisplayText(currentFilter);
  const hasFilter = utils.hasNonEmptyValue(currentFilter);

  const [state, queryDataService] = useDataService(data);

  React.useEffect(() => {
    let suggestions = [];

    state.data.forEach((d) => {
      let suggestion = typeof d === "object" ? d[filterKey] : d.toString();
      if (utils.hasNonEmptyValue(data)) {
        if (utils.hasNonEmptyValue(suggestion)) {
          suggestion = suggestion.toString();
          if (isTypeaheadEqual(suggestion, value)) {
            suggestions.push(suggestion);
          }
        }
      } else {
        suggestions.push(d);
      }
    });

    suggestions = uniqBy(suggestions).sort();

    if (suggestions.length > 0 && filterKey === "cusip" && value.length === 6) {
      let val = value.trim().toUpperCase();
      suggestions.unshift(Messages.LABEL.ALL_BY + val);
      if (!favoriteIssuerCusips.includes(val))
        suggestions.unshift(
          <div className={classes.trackerButtonWrapper} key={val}>
            <FavoriteIssuer variant="button" bond={{ cusip: val }} />
          </div>,
        );
    }
    setProcessedData(suggestions);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.data]);

  const handleInputValueChange = (value) => {
    setValue(value);

    if (value.length >= 3 && lookahead) {
      value = `${deburr(value.trim()).toLowerCase()}*`;
      let query = {
        type: Constants.QUERY_TYPE.LOOKAHEAD,
        filterKey: filterKey,
        term: value,
        size: 10,
      };
      if (utils.hasNonEmptyValue(baseFilter)) {
        query.filter = baseFilter;
      }
      queryDataService(query);
    }
  };

  const handleClosePopper = () => {
    setProcessedData([]);
    queryDataService(null);
  };

  const removeFilter = () => {
    applyKeywordFilter("");
  };

  const handleSubmitSearch = (val) => {
    if (typeof val === "string") {
      handleClosePopper();
      if (val) {
        applyKeywordFilter(val);
      }
    }
  };

  const applyKeywordFilter = (value) => {
    if (utils.hasNonEmptyValue(value) && pad > 0) {
      value = padStart(value, pad, "0");
    }

    value = value.replace(Messages.LABEL.ALL_BY, "").trim();

    onFilterChange({
      filterKey: filterKey,
      filterValue: value,
      filterType: filterType,
    });

    setValue("");
    setProcessedData([]);
    queryDataService(null);
  };

  const handleClickAway = () => {
    setValue("");
  };

  return (
    <div className={classes.wrapper} style={style}>
      {showFilterText && (
        <div className={classes.filterText}>
          {hasFilter && <RemoveFilterButton isOmit={false} onClick={removeFilter} />}
          {filterText.toUpperCase()}
        </div>
      )}
      <Typeahead
        value={value}
        onSubmitSearch={handleSubmitSearch}
        onInputValueChange={handleInputValueChange}
        onClosePopper={handleClosePopper}
        onClickAway={handleClickAway}
        placeholder={placeholder}
        maxLength={maxLength}
        color={color}
        suggestions={processedData}
      />
    </div>
  );
};

KeywordFilter.FilterDefinition = {
  filterKey: PropTypes.string,
  filterValue: PropTypes.string,
  filterType: PropTypes.oneOf(Object.values(Constants.FILTER_TYPE)),
};

KeywordFilter.propTypes = {
  filter: PropTypes.shape(KeywordFilter.KeywordFilter),
  filterKey: PropTypes.string.isRequired,
  baseFilter: PropTypes.shape({
    filterKey: PropTypes.string,
    filterValue: PropTypes.oneOfType([PropTypes.string, PropTypes.object, PropTypes.array]),
    filterType: PropTypes.oneOf(Object.values(Constants.FILTER_TYPE)),
  }),
  showFilterText: PropTypes.bool,
  placeholder: PropTypes.string,
  onFilterChange: PropTypes.func.isRequired,
  data: PropTypes.arrayOf(PropTypes.object),
  color: PropTypes.string,
  lookahead: PropTypes.bool,
  style: PropTypes.object,
  pad: PropTypes.number,
  maxLength: PropTypes.number,
};

export default KeywordFilter;
