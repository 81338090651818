import React from "react";
import PropTypes from "prop-types";
import { Form } from "formik";

import { FormButton } from "components/Controls";
import { Div } from "components/containers/Containers";
import { LabelledIconButton } from "components/Controls";

import AddIcon from "@material-ui/icons/Add";
import ArrowBackIcon from "@material-ui/icons/ArrowBackIos";

import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  form: {
    height: "100%",
  },
  formWrapper: {
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
  },
  fieldset: {
    display: "flex",
    flexDirection: "column",
    marginTop: "auto",
    marginBottom: "auto",
  },
  linkbuttonset: {
    textAlign: "center",
    marginBottom: 10,
  },
  submitbutton: {
    width: "100%",
  },
}));

export const ModalForm = (props) => {
  const classes = useStyles();
  const { children } = props;

  return (
    <Form className={classes.form}>
      <Div className={classes.formWrapper}>{children}</Div>
    </Form>
  );
};

ModalForm.propTypes = {
  children: PropTypes.node.isRequired,
};

export const ModalFormFieldset = (props) => {
  const classes = useStyles();
  const { children } = props;

  return <div className={classes.fieldset}>{children}</div>;
};

ModalFormFieldset.propTypes = {
  children: PropTypes.node.isRequired,
};

export const ModalFormLinkButtonset = (props) => {
  const classes = useStyles();
  const { children } = props;

  return <div className={classes.linkbuttonset}>{children}</div>;
};

ModalFormLinkButtonset.propTypes = {
  children: PropTypes.node.isRequired,
};

export const ModalSubmitButton = (props) => {
  const { label } = props;
  const classes = useStyles();

  return (
    <FormButton className={classes.submitbutton} type="submit" color="primary" variant="outlined">
      {label}
    </FormButton>
  );
};

ModalSubmitButton.propTypes = {
  label: PropTypes.string.isRequired,
};

export const ModalImportCusipButton = (props) => {
  const { label, onClick } = props;

  return (
    <LabelledIconButton onClick={onClick} variant="outlined" color="secondary" startIcon={<AddIcon />}>
      {label}
    </LabelledIconButton>
  );
};

ModalImportCusipButton.propTypes = {
  label: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
};

export const ModalReturnButton = (props) => {
  const { label, onClick } = props;

  return (
    <LabelledIconButton onClick={onClick} variant="outlined" color="secondary" startIcon={<ArrowBackIcon />}>
      {label}
    </LabelledIconButton>
  );
};

ModalImportCusipButton.propTypes = {
  label: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
};
