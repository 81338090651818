import React from "react";
import PropTypes from "prop-types";
import { get, cloneDeep } from "lodash";

import { useViewState, useViewStateDispatcher } from "providers/ViewStateProvider";
import { useDataService } from "services/DataService";
import useAutoRefresh from "hooks/useAutoRefresh";

import * as Constants from "commons/constants";
import { useMessages } from "providers/BrandingProvider";
import * as utils from "commons/utils";
import * as DataConfig from "config/dataconfig";

import DataTable from "components/datatable/DataTable";
import TableToolbar from "components/toolbars/TableToolbar";
import SavedSearch from "components/SavedSearch";
import { AddRequestButton } from "components/modals/RequestTicket";

import { MainPaperWithHeader, Div } from "components/containers/Containers";
import { LabelledIconButton } from "components/Controls";

import TrendingUpIcon from "@material-ui/icons/TrendingUp";
import { SUBVIEW } from "commons/constants";
import { TOOL_TYPE } from "commons/constants";
import EmailFrequency from "components/EmailFrequency";
import AltThemedComponent from "components/AltThemedComponent";

const isQuotesEnabled = process.env.REACT_APP_FEATURES_QUOTES === "true";
const isBidsEnabled = process.env.REACT_APP_FEATURES_BIDS === "true";
const isOfferEnabled = process.env.REACT_APP_FEATURES_OFFER === "true";
const isSellEnabled = isQuotesEnabled || isBidsEnabled || isOfferEnabled;

const InventoryView = ({
  onAddBondsToCart,
  onOpenRequestTicket,
  onOpenTradeTicket,
  setInventoryRefreshedTime,
}) => {
  const Messages = useMessages();
  const viewState = useViewState();
  const dispatchViewStateChange = useViewStateDispatcher();

  const [state, queryDataService] = useDataService();
  const [data, setData] = React.useState([]);
  const [totalNumberRows, setTotalNumberRows] = React.useState();

  const currentViewState = get(viewState, Constants.VIEW.INVENTORY, {});
  const activeSubView = get(currentViewState, "activeSubView", Constants.SUBVIEW.BUY);
  const activeSubViewState = get(currentViewState, activeSubView);

  const selectedBond = utils.findCurrentViewState(viewState).selectedBond;
  const subViewNavKey = isSellEnabled ? "INVENTORY" : null;

  const activeInstrumentCategory = get(
    currentViewState,
    Constants.SUBVIEW.BUY + ".activeQuerySubType",
    Constants.INVENTORY_TYPE.CD,
  );

  const activeTradeType = get(
    currentViewState,
    Constants.SUBVIEW.SELL + ".activeQuerySubType",
    Constants.TRADE_TYPE.PRICE_QUOTES,
  );
  const activeQuerySubType =
    activeSubView === Constants.SUBVIEW.BUY ? activeInstrumentCategory : activeTradeType;

  const activeQuerySubTypeState = get(activeSubViewState, activeQuerySubType);
  const searchId = activeQuerySubTypeState?.query?.searchId;

  const queryData = (clear, autorefresh) => {
    const queryParams = {
      type: Constants.QUERY_TYPE.SELL,
      searchId: new Date().getTime(),
      suppressLoading: !clear,
      clearDataOnFetch: clear,
      filter: {
        tradeType: activeTradeType,
      },
      size: 500,
      page: 0,
    };

    queryDataService(queryParams, { autorefresh });
  };

  React.useEffect(() => {
    if (activeSubView === Constants.SUBVIEW.SELL) {
      queryData(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeSubView, activeTradeType, searchId]);

  useAutoRefresh(() => queryData(false, true), activeSubView !== Constants.SUBVIEW.SELL, 10000);

  const handleNavOptionSelected = (querySubType, subView) => {
    const updatedState = {
      state: {},
      view: Constants.VIEW.INVENTORY,
      subView: subView,
      querySubType: querySubType,
    };

    if (subView === SUBVIEW.BUY) {
      updatedState.state.activeTool = TOOL_TYPE.RATES;
    }

    dispatchViewStateChange(updatedState);
  };

  const handleCurrentViewStateChange = (state, subType) => {
    if (!subType) {
      dispatchViewStateChange({ state: state, view: Constants.VIEW.INVENTORY, subView: activeSubView });
    } else {
      const subView = subType === activeInstrumentCategory ? Constants.SUBVIEW.BUY : Constants.SUBVIEW.SELL;
      const querySubType = subView === Constants.SUBVIEW.BUY ? activeInstrumentCategory : activeTradeType;
      dispatchViewStateChange({
        state: state,
        view: Constants.VIEW.INVENTORY,
        subView: subView,
        querySubType: querySubType,
      });
    }
  };

  const handleRatesClick = () => {
    dispatchViewStateChange({
      state: {
        activeTool: Constants.TOOL_TYPE.RATES,
        searchId: Date.now(),
      },
      view: Constants.VIEW.RATES,
    });
  };

  const handleOpenRequestTicket = (requests) => {
    onOpenRequestTicket(activeTradeType, requests);
  };

  const handleSellTableValueChange = (status, action, orders) => {
    if (status === "success") {
      const state = {
        activeView: Constants.VIEW.INVENTORY,
        activeSubView: Constants.SUBVIEW.SELL,
        activeQuerySubType: activeTradeType,
        activeTool: Constants.TOOL_TYPE.STATUS,
      };
      const orderIds = orders.map((o) => o.id);

      const queryState = get(currentViewState[activeSubView], activeTradeType);
      if (queryState?.query) {
        state.query = cloneDeep(queryState.query);
        state.query.searchId = Date.now();
        state.query.clearDataOnFetch = false;
        state.query.doNotReset = true;
        state.query.highlighted = orderIds;
      } else {
        state.query = {
          searchId: Date.now(),
          highlighted: orderIds,
        };
      }

      dispatchViewStateChange({
        state: state,
        view: Constants.VIEW.INVENTORY,
        subView: Constants.SUBVIEW.SELL,
        querySubType: activeTradeType,
      });

      const statusState = {
        query: {
          searchId: Date.now(),
          highlighted: orderIds,
        },
      };

      dispatchViewStateChange({
        state: statusState,
        view: Constants.VIEW.STATUS,
      });
    }
  };

  React.useEffect(() => {
    dispatchViewStateChange({
      state: {
        activeView: Constants.VIEW.INVENTORY,
      },
      view: Constants.VIEW.INVENTORY,
      subView: activeSubView,
      querySubType: activeQuerySubType,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    if (activeSubView === Constants.SUBVIEW.SELL) {
      if (activeQuerySubTypeState?.query) {
        applyFiltersAndSort(state.data, activeQuerySubTypeState.query);
      } else {
        applyFiltersAndSort(state.data, state.query);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.data]);

  React.useEffect(() => {
    if (activeSubView === Constants.SUBVIEW.SELL && activeQuerySubTypeState?.query) {
      applyFiltersAndSort(state.data, activeQuerySubTypeState.query);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(activeQuerySubTypeState)]);

  const applyFiltersAndSort = (data, query) => {
    let filteredSortedData = cloneDeep(data);

    filteredSortedData = utils.sortData(filteredSortedData, query.sort);
    setTotalNumberRows(filteredSortedData.length);

    filteredSortedData = utils.pageData(filteredSortedData, query.page, DataConfig.DEFAULT_PAGE_SIZE);
    setData(filteredSortedData);
  };

  const determineAlternateView = (data) => {
    return utils.hasEditableOrder(data);
  };

  const buyAdditionalItems = [
    <div style={{ display: "flex", flexGrow: 1, justifyContent: "center" }} key="savedSearch">
      <SavedSearch />
      <AltThemedComponent>
        <EmailFrequency style={{ marginLeft: "0.4rem" }} prefKey="savedSearch" />
      </AltThemedComponent>
    </div>,
    <AltThemedComponent key="seeRates">
      <LabelledIconButton
        onClick={handleRatesClick}
        variant="outlined"
        color="secondary"
        startIcon={<TrendingUpIcon />}
      >
        {Messages.LABEL.SEE_RATES}
      </LabelledIconButton>
    </AltThemedComponent>,
  ];

  const sellAdditionalItems = [
    <AddRequestButton
      onClick={(e) => handleOpenRequestTicket([])}
      label={Messages.LABEL.REQUEST[activeTradeType]}
    />,
  ];

  return (
    <Div>
      {activeSubView === Constants.SUBVIEW.BUY && (
        <MainPaperWithHeader
          headerItems={[
            <TableToolbar
              key="buyTableToolbar"
              selectedNavOption={activeInstrumentCategory}
              additionalItems={buyAdditionalItems}
              view={Constants.VIEW.INVENTORY}
              subView={Constants.SUBVIEW.BUY}
              subViewNavigationOptionsKey={subViewNavKey}
              onNavOptionSelected={handleNavOptionSelected}
            />,
          ]}
        >
          <DataTable
            queryType={Constants.QUERY_TYPE.INVENTORY}
            subView={Constants.SUBVIEW.BUY}
            querySubType={activeInstrumentCategory}
            instrumentCategory={activeInstrumentCategory}
            selectable={true}
            autoSelectFirstBond={true}
            selectedBond={selectedBond}
            onAddBondsToCart={onAddBondsToCart}
            onOpenTradeTicket={onOpenTradeTicket}
            onViewStateChange={handleCurrentViewStateChange}
            setInventoryRefreshedTime={setInventoryRefreshedTime}
            autorefresh={true}
          />
        </MainPaperWithHeader>
      )}
      {activeSubView === Constants.SUBVIEW.SELL && (
        <MainPaperWithHeader
          headerItems={[
            <TableToolbar
              key="sellTableToolbar"
              selectedNavOption={activeTradeType}
              onNavOptionSelected={handleNavOptionSelected}
              view={Constants.VIEW.INVENTORY}
              subView={Constants.SUBVIEW.SELL}
              subViewNavigationOptionsKey={subViewNavKey}
              additionalItems={sellAdditionalItems}
            />,
          ]}
        >
          <DataTable
            queryType={Constants.QUERY_TYPE.SELL}
            view={Constants.VIEW.INVENTORY}
            subView={Constants.SUBVIEW.SELL}
            querySubType={activeTradeType}
            determineAlternateView={determineAlternateView}
            onValueChange={handleSellTableValueChange}
            onViewStateChange={handleCurrentViewStateChange}
            onOpenRequestTicket={handleOpenRequestTicket}
            defaultHideFilterbar={true}
            manual={true}
            loading={state.isLoading}
            data={data}
            unfilteredData={state.data}
            totalNumberRows={totalNumberRows}
          />
        </MainPaperWithHeader>
      )}
    </Div>
  );
};

InventoryView.propTypes = {
  onAddBondsToCart: PropTypes.func.isRequired,
  onOpenRequestTicket: PropTypes.func.isRequired,
  onOpenTradeTicket: PropTypes.func.isRequired,
  setInventoryRefreshedTime: PropTypes.func.isRequired,
};

export default InventoryView;
