import React from "react";
import SplitPane from "react-split-pane";
import PropTypes from "prop-types";
import { get } from "lodash";
import clsx from "clsx";

import { useViewState, useViewStateDispatcher } from "providers/ViewStateProvider";
import * as utils from "commons/utils";
import { Div } from "components/containers/Containers";

import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  secondaryRoot: {
    height: "100%",
    width: "inherit",
    minWidth: "inherit",
    display: "flex",
    flexDirection: "column",
    transition: theme.transitions.create("all", {
      easing: theme.transitions.easing.easeIn,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  secondaryFullScreen: {
    width: 0,
    maxWidth: 0,
    minWidth: 0,
    margin: 0,
    transition: theme.transitions.create("all", {
      easing: theme.transitions.easing.easeIn,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
}));

const mainMin = 930;
const secondaryMin = 300;
const mainMax = 1400;
const maxTotalWidth = 1950;
const VIEWSTATE_KEY = "verticalMainSize";

const QBSplitPane = (props) => {
  const { children, width, hidden } = props;
  const viewState = useViewState();
  const isFullScreen = !get(viewState, "isSplitScreen", true);
  const mainSizeFromState = get(viewState, VIEWSTATE_KEY);

  const [resized, setResized] = React.useState(false);
  const [mainSize, setMainSize] = React.useState();
  const [splitSize, setSplitSize] = React.useState();
  const [windowWidth, setWindowWidth] = React.useState();

  const dispatchViewStateChange = useViewStateDispatcher();

  const calculateAndSetSize = () => {
    if (utils.hasNonEmptyValue(width)) {
      let workingWidth = Math.min(width, maxTotalWidth);

      const isWindowResize = workingWidth !== windowWidth;
      const secMin = Math.max(workingWidth - secondaryMin, mainMin);
      const maxWidth = Math.min(secMin, mainMax);

      if (isWindowResize) {
        setSplitSize(null);
      }
      let size = 0;
      if (isFullScreen) {
        size = workingWidth;
      } else if (splitSize && resized && !isWindowResize) {
        size = splitSize;
      } else {
        size = !mainSize && mainSizeFromState ? mainSizeFromState : maxWidth;
        setSplitSize(size);
      }

      setMainSize(size);
      setWindowWidth(workingWidth);
    }
  };

  const handleDragEnd = (newSize) => {
    setSplitSize(newSize);
    setMainSize(newSize);
  };
  React.useEffect(() => {
    calculateAndSetSize();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [width, isFullScreen]);

  React.useEffect(() => {
    if (mainSize) {
      const state = {};
      state[VIEWSTATE_KEY] = mainSize;
      state.verticalSecondarySize = pane2Width;
      dispatchViewStateChange({ state });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mainSize]);

  const pane2Width =
    utils.hasNonEmptyValue(width) && utils.hasNonEmptyValue(mainSize)
      ? Math.min(width, maxTotalWidth) - mainSize
      : 0;

  return (
    <SplitPane
      className={hidden || isFullScreen ? "fullScreen" : "splitScreen"}
      onDragFinished={handleDragEnd}
      onDragStarted={(e) => setResized(true)}
      minSize={mainMin}
      maxSize={-secondaryMin}
      size={mainSize}
      pane2Style={{ width: pane2Width, minWidth: 292, marginBottom: 8 }}
    >
      {children[0]}
      {children[1]}
    </SplitPane>
  );
};

QBSplitPane.propTypes = {
  children: PropTypes.node.isRequired,
  width: PropTypes.number,
};

QBSplitPane.Main = function SplitPaneMain(props) {
  const { children } = props;

  return <Div>{children}</Div>;
};

QBSplitPane.Main.propTypes = {
  children: PropTypes.node.isRequired,
};

QBSplitPane.Secondary = function SplitPaneSecondary(props) {
  const classes = useStyles();
  const { children } = props;

  const viewState = useViewState();
  const isFullScreen = !get(viewState, "isSplitScreen", true);

  return (
    <div className={clsx(classes.secondaryRoot, { [classes.secondaryFullScreen]: isFullScreen })}>
      {children}
    </div>
  );
};

QBSplitPane.Secondary.propTypes = {
  children: PropTypes.node,
};

export default QBSplitPane;
