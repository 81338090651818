import React from "react";
import PropTypes from "prop-types";

import { get, sortBy } from "lodash";
import { useMessages } from "providers/BrandingProvider";

import * as utils from "commons/utils";
import * as Constants from "commons/constants";
import QBTypography from "components/QBTypography";
import * as DataConfig from "config/dataconfig";
import RequestInfo from "components/modals/RequestInfo";
import { Div } from "components/containers/Containers";
import { PopperPaper } from "components/containers/Modals";
import { LinkButton } from "components/Controls";
import DocumentDownload from "components/DocumentDownload";

import { makeStyles } from "@material-ui/core/styles";
import Popper from "@material-ui/core/Popper";
import Fade from "@material-ui/core/Fade";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import { hasContactTradeDesk } from "commons/utils";

const useStyles = makeStyles((theme) => ({
  wrapper: {
    display: "flex",
    flexFlow: "column nowrap",
    flex: "0 1 100%",
    overflowY: "auto",
    overflowX: "hidden",
    maxWidth: 600,
    paddingTop: 10,
  },
  topDetail: {},
  toolbar: {
    fontSize: "0.8rem",
  },
  toolbarButton: {
    fontSize: "0.9rem",
  },
  toolbarButtonLabel: {
    marginLeft: 4,
  },
  detailRoot: {
    display: "flex",
    flex: "0 1 auto",
    width: "100%",
    maxWidth: "100%",
    flexFlow: "row wrap",
  },
  topDetailRoot: {
    display: "flex",
    flex: "0 0 auto",
    flexFlow: "row wrap",
    paddingBottom: 20,
    width: "100%",
    maxWidth: "100%",
  },
  topDetailColumn: {
    paddingRight: 20,
    flex: "0 0 auto",
    minWidth: "50%",
  },
  detailColumn: {
    padding: "0 20px 20px 0",
    display: "flex",
    flex: "0 1 auto",
    flexFlow: "column wrap",
    maxHeight: "inherit",
    minWidth: "50%",
    "&:last-child": {
      marginBottom: 0,
    },
  },
  tableRoot: {
    display: "flex",
    width: "100%",
    flexFlow: "row nowrap",
  },
  tableColumn: {
    flexGrow: 0,
    paddingRight: 20,
  },
  headerCell: {
    textAlign: "center",
    whiteSpace: "nowrap",
  },
  numericCell: {
    textAlign: "right",
  },
  textCell: {
    textAlign: "center",
    whiteSpace: "nowrap",
  },
  rowHeaderCell: {
    whiteSpace: "nowrap",
  },
  additional: {
    marginTop: 10,
  },
  transitionPopper: {
    zIndex: 10,
  },
  paper: {
    width: "100%",
    maxHeight: "97vh",
  },
  scheduleLabel: {
    textAlign: "center",
    marginBottom: 3,
    paddingBottom: 3,
    color: theme.palette.primary.main,
    borderBottom: `1px solid ${theme.palette.border.main}`,
  },
  scheduleWrapper: {
    background: theme.palette.background.shade,
    display: "flex",
    flexFlow: "column wrap",
    overflowX: "auto",
    paddingRight: 10,
  },
  value: {
    color: theme.palette.secondary.main,
  },
}));

const TopDetailGroup = (props) => {
  const classes = useStyles();
  const { bond, groupType } = props;
  const type = utils.getInventoryKeyFromType(bond.instrumentCategory);
  const config = get(DataConfig.PROPERTY_GROUP, type);
  if (!config || !config.hasOwnProperty(groupType)) {
    return null;
  }

  const groupConfig = config[groupType];
  let properties = groupConfig.properties;

  if (properties[0].hasOwnProperty("table")) {
    return DetailTable(props);
  }

  properties = properties.filter((prop) => {
    if (prop.condition) {
      return utils.hasNonEmptyValue(get(bond, prop.accessor)) && prop.condition(bond);
    }
    return utils.hasNonEmptyValue(get(bond, prop.accessor));
  });

  if (!properties.length) {
    return null;
  }

  const details = properties.map((prop) => {
    return <Detail key={prop.accessor} accessor={prop.accessor} bond={bond} />;
  });

  const column1 = details.slice(0, Math.ceil(details.length / 2));
  const column2 = details.slice(column1.length);

  return (
    <div className={classes.topDetailRoot}>
      <div className={classes.topDetailColumn}>{column1}</div>
      <div className={classes.topDetailColumn}>{column2}</div>
    </div>
  );
};

TopDetailGroup.propTypes = {
  groupType: PropTypes.oneOf(Object.values(Constants.DATA_PROPERTY_GROUP)).isRequired,
  bond: PropTypes.object.isRequired,
};

const DetailGroup = (props) => {
  const classes = useStyles();
  const { bond, groupType } = props;
  const type = utils.getInventoryKeyFromType(bond.instrumentCategory);
  const config = get(DataConfig.PROPERTY_GROUP, type);

  const groupConfig = config[groupType];
  const header = groupConfig.label;
  let properties = groupConfig.properties;

  if (properties[0].hasOwnProperty("table")) {
    return DetailTable(props);
  }

  properties = properties.filter((prop) => {
    if (prop.condition) {
      return utils.hasNonEmptyValue(get(bond, prop.accessor)) && prop.condition(bond);
    }
    return utils.hasNonEmptyValue(get(bond, prop.accessor));
  });

  const details = properties.map((prop) => {
    return <Detail key={prop.accessor} accessor={prop.accessor} bond={bond} />;
  });

  return (
    <div className={classes.detailColumn}>
      {header && (
        <QBTypography variant="h6" gutterBottom>
          {header}
        </QBTypography>
      )}
      {details}
    </div>
  );
};

const DetailTable = ({ bond, groupType }) => {
  const classes = useStyles();
  const Messages = useMessages();

  const type = utils.getInventoryKeyFromType(bond.instrumentCategory);
  const config = get(DataConfig.PROPERTY_GROUP, type);
  if (!config || !config.hasOwnProperty(groupType)) {
    return null;
  }

  const groupConfig = config[groupType];
  const header = groupConfig.label;
  const groupProperty = groupConfig.properties[0];

  if (!groupProperty || !groupProperty.hasOwnProperty("table")) {
    return null;
  }

  const tableProperty = groupProperty.table;
  const additional = groupProperty.additionalProperties;

  const rowKey = tableProperty.rowKey;
  const rowConfig = tableProperty.rows;
  const columnConfig = tableProperty.columns;

  let rowHeader = null;
  let rowKeys = [];
  let values = {};

  if (rowKey) {
    if (!groupConfig.alwaysShow && !utils.hasNonEmptyValue(get(bond, groupProperty.accessor))) {
      return null;
    }

    rowHeader = utils.hasNonEmptyValue(rowKey.label) ? rowKey.label : "\u00A0";
    rowKeys = rowConfig;
    let recs = get(bond, groupProperty.accessor, []);
    if (typeof recs === "object") {
      recs = Object.values(recs);
    }
    values = utils.groupByProperty(recs, rowKey.accessor);
  } else {
    rowHeader = utils.hasNonEmptyValue(tableProperty.label) ? tableProperty.label : "\u00A0";
    rowConfig.forEach((row) => {
      const vals = {};
      columnConfig.forEach((column) => {
        if (utils.hasNonEmptyValue(get(bond, column.accessor))) {
          const columnValue = get(bond, column.accessor);
          let val;
          // If its a nested array, take the top element and assume the backend
          // sorted it for us to put the top element as the right one
          if (Array.isArray(columnValue)) {
            val = get(columnValue[0], row.accessor);
          } else {
            val = get(columnValue, row.accessor);
          }
          if (utils.hasNonEmptyValue(val)) {
            vals[column.accessor] = utils.formatValue(val, row.accessor, bond, true);
          } else {
            vals[column.accessor] = "--";
          }
        }
      });
      values[row.label] = [vals];
      rowKeys.push(row.label);
    });
  }

  const rowHeaders = (
    <div className={classes.tableColumn}>
      <div>
        <QBTypography gutterBottom>{rowHeader}</QBTypography>
      </div>
      {rowKeys.map((row) => {
        return (
          <div key={row}>
            <QBTypography className={classes.rowHeaderCell} color="textSecondary">
              {row}
            </QBTypography>
          </div>
        );
      })}
    </div>
  );

  var contactTradeDeskTextUsed = false;
  const columns = columnConfig.map((column) => {
    const columnAccessor = column.accessor;
    return (
      <div key={columnAccessor} className={classes.tableColumn}>
        <div>
          <QBTypography color="primary" gutterBottom className={classes.headerCell}>
            {column.label}
          </QBTypography>
        </div>
        {rowKeys.map((row) => {
          const value =
            values.hasOwnProperty(row) && utils.hasNonEmptyValue(values[row])
              ? values[row][0][columnAccessor]
              : null;
          const formatClass =
            column.format === Constants.FORMAT_TYPE.NUMBER || column.format === Constants.FORMAT_TYPE.CURRENCY
              ? classes.numericCell
              : classes.textCell;
          const formattedValue = utils.formatValue(value, columnAccessor, bond, true) || "--";
          // Leave as true if any column matches.
          if (!contactTradeDeskTextUsed) {
            contactTradeDeskTextUsed = hasContactTradeDesk(
              value,
              columnAccessor,
              bond,
              true,
            ).contactTradeDeskValueUsed;
          }
          return (
            <div key={row} className={formatClass}>
              <QBTypography color="secondary">{formattedValue}</QBTypography>
            </div>
          );
        })}
      </div>
    );
  });

  const additionalProperties = !additional
    ? null
    : additional.map((prop) => {
        if (!utils.hasNonEmptyValue(bond[prop.accessor])) {
          return null;
        }
        return <Detail key={prop.accessor} accessor={prop.accessor} bond={bond} />;
      });

  return (
    <div className={classes.detailColumn}>
      {header && (
        <QBTypography gutterBottom variant="h6">
          {header}
        </QBTypography>
      )}
      <div className={classes.tableRoot}>
        {rowHeaders}
        {columns}
      </div>
      {additionalProperties && <div className={classes.additional}>{additionalProperties}</div>}
      {contactTradeDeskTextUsed && (
        <div className={classes.value}>
          * <RequestInfo bond={bond} variant="hyperlink" linkLabel={Messages.LABEL.CONTACT_HELP_DESK} />
        </div>
      )}
    </div>
  );
};

DetailTable.propTypes = {
  groupType: PropTypes.oneOf(Object.values(Constants.DATA_PROPERTY_GROUP)).isRequired,
  bond: PropTypes.object.isRequired,
};

const Detail = (props) => {
  const { accessor, bond } = props;
  const config = get(DataConfig.PROPERTY, accessor);
  const label = config.label;
  const value = utils.formatValue(get(bond, accessor), accessor, bond, true);

  if (config.type === Constants.DETAIL_TYPE.POPPER) {
    return <DetailPopper key={accessor} accessor={accessor} bond={bond} />;
  }

  if (config.type === Constants.DETAIL_TYPE.LINK_DOWNLOAD) {
    return (
      <DocumentDownload
        key={accessor}
        style={{ padding: 0 }}
        variant="link"
        url={get(bond, accessor)}
        title={config.label}
        fileName={`${config.filenamePrefix}${bond.cusip}`}
      />
    );
  }

  return (
    <QBTypography component="div">
      {config.format !== Constants.FORMAT_TYPE.HYPERLINK &&
        config.format !== Constants.FORMAT_TYPE.HYPERLINK_BUTTON && <span>{label}</span>}
      <span>{value}</span>
    </QBTypography>
  );
};

Detail.propTypes = {
  accessor: PropTypes.string.isRequired,
  bond: PropTypes.object.isRequired,
};

const DetailPopper = (props) => {
  const classes = useStyles();

  const { accessor, bond } = props;
  const config = get(DataConfig.PROPERTY, accessor);
  const label = config.label;
  let value = get(bond, accessor);
  value = sortBy(value, "calldate");
  const [anchorEl, setAnchorEl] = React.useState(null);
  const isOpen = Boolean(anchorEl);

  const openPopper = (event) => {
    event.stopPropagation();
    setAnchorEl(anchorEl ? null : event.currentTarget.parentElement);
  };

  const closePopper = () => {
    setAnchorEl(null);
  };

  if (value.length === 0) {
    return null;
  }

  const linkLabel = isOpen ? `Close ${label}` : `Show ${label}`;
  const numRows = value.length < 10 ? value.length : Math.ceil(value.length / 2);
  const numCols = value.length < 10 ? 1 : Math.ceil(value.length / numRows);

  const height = numRows * 21 + 10;
  const width = numCols * 95;
  return (
    <React.Fragment>
      <div className="no-print">
        <LinkButton style={{ padding: 0 }} onClick={openPopper} label={linkLabel} />
      </div>
      <Popper
        open={isOpen}
        placement="left"
        anchorEl={anchorEl}
        className={classes.transitionPopper}
        transition
      >
        {({ TransitionProps }) => (
          <Fade {...TransitionProps}>
            <div>
              <ClickAwayListener onClickAway={closePopper}>
                <PopperPaper onClick={(e) => e.stopPropagation()} classes={{ root: classes.paper }}>
                  <QBTypography className={classes.scheduleLabel}>{label}</QBTypography>
                  <div className={classes.scheduleWrapper} style={{ width: width, height: height }}>
                    {value.map((v) => {
                      return (
                        <QBTypography style={{ textAlign: "right", marginTop: 5 }} key={v.calldate}>
                          <span>{utils.formatValue(v.calldate, "callDate", bond, true)}</span>
                        </QBTypography>
                      );
                    })}
                  </div>
                </PopperPaper>
              </ClickAwayListener>
            </div>
          </Fade>
        )}
      </Popper>
    </React.Fragment>
  );
};

DetailPopper.propTypes = {
  accessor: PropTypes.string.isRequired,
  bond: PropTypes.object.isRequired,
};

const orderedGroups = [
  Constants.DATA_PROPERTY_GROUP.CALL_INFORMATION,
  Constants.DATA_PROPERTY_GROUP.REDEMPTIONS,
  Constants.DATA_PROPERTY_GROUP.PROCEEDS_TAX,
  Constants.DATA_PROPERTY_GROUP.ISSUANCE,
  Constants.DATA_PROPERTY_GROUP.CURRENT_DATA,
  Constants.DATA_PROPERTY_GROUP.RATINGS,
  Constants.DATA_PROPERTY_GROUP.HISTORICAL_SPEED,
  Constants.DATA_PROPERTY_GROUP.RATE_SHOCKS,
];

const BondDetail = ({ bond }) => {
  const classes = useStyles();
  const type = utils.getInventoryKeyFromType(bond?.instrumentCategory);
  const config = get(DataConfig.PROPERTY_GROUP, type);

  const groups = React.useMemo(() => {
    let width = 0;
    const groupTypes = [];
    orderedGroups.forEach((groupType) => {
      const groupConfig = get(config, groupType);
      let isTable = false;
      if (utils.hasNonEmptyValue(groupConfig)) {
        let properties = groupConfig.properties || [];
        if (properties.length > 0 && properties[0].hasOwnProperty("table")) {
          isTable = true;
          properties = properties[0].accessor
            ? [{ accessor: properties[0].accessor }]
            : properties[0].table.columns;
          if (groupConfig.properties[0].hasOwnProperty("additionalProperties")) {
            properties = properties.concat(groupConfig.properties[0].additionalProperties);
          }
        }
        properties = properties.filter((prop) => {
          if (groupConfig.alwaysShow) {
            return true;
          }
          if (prop.condition) {
            return utils.hasNonEmptyValue(get(bond, prop.accessor)) && prop.condition(bond);
          }
          return utils.hasNonEmptyValue(get(bond, prop.accessor));
        });
        if (properties.length) {
          width += groupConfig.width || (isTable ? 260 : 150);
          groupTypes.push(groupType);
        }
      }
    });

    return { width: width, groups: groupTypes };
  }, [bond, config]);

  const detailGroups = React.useMemo(() => {
    const children = [];

    groups.groups.forEach((groupType) => {
      children.push(<DetailGroup key={groupType} groupType={groupType} bond={bond} />);
    });

    return children;
  }, [bond, groups]);

  return (
    <React.Fragment>
      {bond && (
        <Div className={classes.wrapper}>
          <TopDetailGroup groupType={Constants.DATA_PROPERTY_GROUP.STRUCTURE} bond={bond} />
          <div className={classes.detailRoot}>{detailGroups}</div>
        </Div>
      )}
    </React.Fragment>
  );
};

BondDetail.propTypes = {
  bond: PropTypes.object.isRequired,
};

export default BondDetail;
