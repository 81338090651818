import React from "react";
import PropTypes from "prop-types";
import { replace } from "lodash";

import { useMessages } from "providers/BrandingProvider";
import * as utils from "commons/utils";
import * as Constants from "commons/constants";
import * as ticketUtils from "./ticketUtils";
import QBTypography from "components/QBTypography";
import { TextAreaInput } from "components/Controls";
import { TicketRow, TicketRowSection, TicketButton } from "./TicketComponents";
import { Div } from "components/containers/Containers";

import Button from "@material-ui/core/Button";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import { makeStyles } from "@material-ui/core/styles";
import { alpha } from "@material-ui/core/styles/colorManipulator";

const useStyles = makeStyles((theme) => ({
  instructionsWrapper: {
    display: "flex",
    flexDirection: "column",
    paddingLeft: 20,
  },
  instructions: {
    flexGrow: 1,
    textAlign: "left",
    marginBottom: 10,
  },
  buttonRow: {
    flexGrow: 0,
    textAlign: "right",
  },
  buttonGroupRoot: {
    padding: 0,
    marginTop: 10,
    marginBottom: 10,
  },
  grouped: {
    letterSpacing: "0.12em",
    fontSize: "0.6rem",
    color: theme.palette.text.unselected.secondary,
    backgroundColor: alpha(theme.palette.background.button.secondary, 0.1),
    "&.Mui-disabled": {
      color: theme.palette.secondary.main,
      backgroundColor: alpha(theme.palette.background.button.secondary, 0.3),
    },
  },
}));

export const ImportCusips = ({ tradeType, quantityField, onImport, onCancel }) => {
  const classes = useStyles();
  const Messages = useMessages();
  const [userData, setUserData] = React.useState("");
  const [additionalValueKey, setAdditionalValueKey] = React.useState("price");

  const quantityFieldDisplay = quantityField === "originalSize" ? "Original Size" : "Quantity (000s)";

  const parseUserData = (userData = "", additionalValueKey) => {
    const rows = userData.split("\n");
    let values, val1, val2, val3;

    const cusips = [];

    rows.forEach((row) => {
      row = replace(row.trim(), /,|'|'|$/g, "");
      if (utils.hasNonEmptyValue(row)) {
        values = row.split(/\b\s+(?!$)/);
        var rec = ticketUtils.generateRequestRecord();
        if (values.length > 1) {
          val1 = values[0].trim();
          val2 = values[1].trim();
          if (isNaN(Number(val1))) {
            rec.cusip = val1;
            if (!isNaN(Number(val2))) {
              rec[quantityField] = Number(val2);
            }
          } else if (isNaN(Number(val2))) {
            rec.cusip = val2;
            if (!isNaN(Number(val1))) {
              rec[quantityField] = Number(val1);
            }
          } else if (utils.isValidCusip(val1)) {
            rec.cusip = val1;
            if (!isNaN(Number(val2))) {
              rec[quantityField] = Number(val2);
            }
          } else {
            rec.cusip = val2;
            if (!isNaN(Number(val1))) {
              rec[quantityField] = Number(val1);
            }
          }
        } else {
          rec.cusip = values[0].trim();
        }

        if (values.length === 3) {
          val3 = values[2].trim();
          if (!isNaN(Number(val3))) {
            rec[additionalValueKey] = Number(val3);
            rec.additionalValueKey = additionalValueKey;
          }
        }

        cusips.push(rec);
      }
    });

    return cusips;
  };

  const cancelImport = () => {
    setUserData("");
    onCancel();
  };

  const processImport = () => {
    const cusips = parseUserData(userData, additionalValueKey);
    onImport(cusips, additionalValueKey);
  };

  var parseValueOptions = [
    { label: "Price", value: "price" },
    { label: "YTW", value: "yieldToWorst" },
    { label: "YTM/Yield", value: "yieldToMaturity" },
  ];

  let providedValueButtons = parseValueOptions.map((option, i) => {
    return (
      <Button
        key={option.value}
        disabled={additionalValueKey === option.value}
        onClick={(e) => setAdditionalValueKey(option.value)}
      >
        {option.label}
      </Button>
    );
  });

  const getParseValueKeyLabel = () => {
    const activeOption = utils.findInArray(parseValueOptions, "value", additionalValueKey);
    return utils.hasNonEmptyValue(activeOption) ? activeOption[0].label : parseValueOptions[0].label;
  };

  const placeholderText =
    tradeType !== Constants.TRADE_TYPE.BONDS_FOR_SALE
      ? `CUSIP, ${quantityFieldDisplay}`
      : `CUSIP, ${quantityFieldDisplay}, ${getParseValueKeyLabel()}`;

  // Repeat N times to demonstrate to user to enter multiple rows
  const placeholder = Array(3).fill(placeholderText).join("\n") + "\n ...";

  const setInput = (event) => {
    setUserData(event.currentTarget.value);
  };

  return (
    <TicketRow>
      <TicketRowSection style={{ minWidth: 400 }}>
        <TextAreaInput
          rows={8}
          rowsMax={Infinity}
          value={userData}
          onChange={setInput}
          placeholder={placeholder}
        />
      </TicketRowSection>
      <TicketRowSection>
        <Div className={classes.instructionsWrapper}>
          <div className={classes.instructions}>
            {tradeType !== Constants.TRADE_TYPE.BONDS_FOR_SALE && (
              <QBTypography variant="body1" color="textSecondary">
                Enter CUSIP and an optional {quantityFieldDisplay}, one per line.
              </QBTypography>
            )}
            {tradeType === Constants.TRADE_TYPE.BONDS_FOR_SALE && (
              <React.Fragment>
                <QBTypography style={{ marginBottom: 10 }} color="textSecondary">
                  Enter CUSIP and an optional {quantityFieldDisplay}, one per line. You may also provide a
                  Price, Yield or YTM.
                </QBTypography>
                <QBTypography color="textSecondary">
                  If providing a third value, please indicate which below:
                </QBTypography>
                <ButtonGroup
                  value={additionalValueKey}
                  classes={{
                    root: classes.buttonGroupRoot,
                    grouped: classes.grouped,
                  }}
                  variant="outlined"
                  size="small"
                >
                  {providedValueButtons}
                </ButtonGroup>
              </React.Fragment>
            )}
          </div>
          <div className={classes.buttonRow}>
            <TicketButton onClick={cancelImport} label={Messages.LABEL.CANCEL} />
            <TicketButton
              disabled={!utils.hasNonEmptyValue(userData)}
              onClick={processImport}
              label={Messages.LABEL.ENTER}
              color="primary"
            />
          </div>
        </Div>
      </TicketRowSection>
    </TicketRow>
  );
};

ImportCusips.propTypes = {
  tradeType: PropTypes.oneOf(Object.values(Constants.TRADE_TYPE)),
  quantityField: PropTypes.string.isRequired,
  onImport: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};

export default ImportCusips;
