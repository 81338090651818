import React from "react";
import PropTypes from "prop-types";
import { uniqBy } from "lodash";
import clsx from "clsx";

import * as utils from "commons/utils";
import * as Constants from "commons/constants";
import QBTypography from "components/QBTypography";

import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  messageWrapper: {
    marginTop: 5,
    marginBottom: 5,
    "&:first-child": {
      marginTop: 15,
    },
  },
  footerMessage: {
    "&:first-child": {
      marginTop: 0,
    },
    "& div": {
      marginBottom: 5,
    },
    "& div:last-child": {
      marginBottom: 0,
    },
  },
  warning: {
    color: theme.palette.text.warning,
  },
}));

export const ModalMessages = (props) => {
  const classes = useStyles();
  const { messages, level, isFooterMessage, style = {}, wrapperStyle = {} } = props;

  const color =
    level === Constants.ALERT_LEVEL.INFO
      ? "secondary"
      : level === Constants.ALERT_LEVEL.WARNING
      ? "inherit"
      : "error";
  // de-dupe
  const dedupedMessages = uniqBy(messages);

  return !utils.hasNonEmptyValue(messages) ? null : (
    <div
      className={clsx(classes.messageWrapper, { [classes.footerMessage]: isFooterMessage })}
      style={{ textAlign: isFooterMessage ? "left" : "center", ...wrapperStyle }}
    >
      {dedupedMessages.map((msg, i) => (
        <div key={i} className={classes.warning}>
          <QBTypography style={style} color={color}>
            {msg}
          </QBTypography>
        </div>
      ))}
    </div>
  );
};

ModalMessages.propTypes = {
  messages: PropTypes.arrayOf(PropTypes.string),
  level: PropTypes.oneOf([
    Constants.ALERT_LEVEL.ERROR,
    Constants.ALERT_LEVEL.WARNING,
    Constants.ALERT_LEVEL.INFO,
  ]).isRequired,
};

export default ModalMessages;
