import queryString from "query-string";
import { get, isNil } from "lodash";
import moment from "moment-timezone";

import * as DataConfig from "config/dataconfig";
import * as Constants from "commons/constants";
import * as utils from "commons/utils";

// Take an array and add metadata array to it
// Relies on existing ? in place - cant be used with null/no params
const addTrackingMetadataToURL = (url, trackingMetadata) => {
  if (!trackingMetadata || trackingMetadata.length === 0) {
    return url;
  } else {
    return url + `&metadata=${trackingMetadata.join(",")}`;
  }
};

export const getQueryConfig = (query) => {
  let params = {
    method: "get",
  };

  if (query.cancelToken) {
    params.cancelToken = query.cancelToken;
  }

  let queryString;

  switch (query.type) {
    case Constants.QUERY_TYPE.COMPARABLE:
      queryString = _getQueryStringParamPairsFromFilter(query.filter);
      params.url = `${DataConfig.COMPARISON_ENDPOINT.COMPARE}?${query.identifier.filterKey}=${query.identifier.filterValue}&${queryString}`;
      params.url = addTrackingMetadataToURL(params.url, query.trackingMetadata);
      break;
    case Constants.QUERY_TYPE.COMPARABLE_CUSTOM:
      queryString = _queryStringFromQuery(query);
      params.url = `${DataConfig.INVENTORY_ENDPOINT}?${queryString}`;
      params.url = addTrackingMetadataToURL(params.url, query.trackingMetadata);
      break;
    case Constants.QUERY_TYPE.SIMILAR: // depth of book, but excluding specified bond id
      queryString = _queryStringFromQuery(query);
      params.url = `${DataConfig.INVENTORY_ENDPOINT}/${query.identifier.filterValue}/offerings?${queryString}`;
      break;
    case Constants.QUERY_TYPE.INVENTORY:
      queryString = _queryStringFromQuery(query);
      params.url = `${DataConfig.INVENTORY_ENDPOINT}?${queryString}`;
      break;
    case Constants.QUERY_TYPE.LOOKAHEAD:
      queryString = _queryStringFromQuery(query);
      const value = encodeURIComponent(query.term);
      params.url = `${DataConfig.LOOKAHEAD_ENDPOINT}/?column=${query.filterKey}&q=${value}&${queryString}`;
      break;
    case Constants.QUERY_TYPE.SELL: // sellside transactions
      queryString = _queryStringFromQuery(query);
      params.url = `${DataConfig.TRADE_ENDPOINT[query.filter.tradeType]}/active?${queryString}`;
      break;
    case Constants.QUERY_TYPE.TRADE_TICKET: // posts buys
      params.method = "post";
      params.url = `${DataConfig.INVENTORY_ENDPOINT}/buylist`;
      params.data = query.params;
      break;
    case Constants.QUERY_TYPE.REQUEST_TICKET: // posts offers, bids
      params.method = "post";
      params.url = `${DataConfig.SELL_ENDPOINT[query.tradeType]}`;
      params.data = query.params;
      break;
    case Constants.QUERY_TYPE.REQUEST_TICKET_EDIT: // edits an offer
      params.method = "patch";
      params.url = DataConfig.TRADE_ENDPOINT.BONDS_FOR_SALE;
      params.data = query.params;
      break;
    case Constants.QUERY_TYPE.ADJUST_QUANTITY: {
      params.method = "post";
      params.url = `${DataConfig.CALCULATOR_ENDPOINT}/desiredtotal`;
      params.data = query.params;
      break;
    }
    case Constants.QUERY_TYPE.PRINCIPAL_INTEREST_LOOKUP:
      params.method = "post";
      params.url = `${DataConfig.CALCULATOR_ENDPOINT}/buyamounts`;
      params.data = query.params;
      break;
    case Constants.QUERY_TYPE.PRICE_YIELD_LOOKUP:
      params.method = "post";
      params.url = `${DataConfig.CALCULATOR_ENDPOINT}/sellcalculator`;
      params.data = query.params;
      break;
    case Constants.QUERY_TYPE.AVAILABILITY_LOOKUP: {
      params.method = "post";
      params.url = `${DataConfig.INVENTORY_ENDPOINT}/withupdate?id=${query.ids.join(
        ",",
      )}&cusip=${query.cusips.join(",")}`;
      params.data = {};
      break;
    }
    case Constants.QUERY_TYPE.HISTORY:
      queryString = _queryStringFromQuery(query);
      params.url = `${DataConfig.ORDERS_ENDPOINT}?${queryString}`;
      break;
    case Constants.QUERY_TYPE.RATES:
      params.url = `${DataConfig.RATES_ENDPOINT}?calculation=${query.filter.boardType}`;
      break;
    case Constants.QUERY_TYPE.STATUS: // curent day and pending
      queryString = _queryStringFromQuery(query);
      params.url = `${DataConfig.ORDERS_ENDPOINT}/status?${queryString}`;
      break;
    case Constants.QUERY_TYPE.CUSIP_SEARCH: // searches in- and out-of-inventory
      params.url = `${DataConfig.INVENTORY_ENDPOINT}/cusip/${query.identifier.filterValue}/`;
      break;
    case Constants.QUERY_TYPE.CUSIP_LOOKUP: // adds to inventory if not found
      params.url = `${DataConfig.INVENTORY_ENDPOINT}/lookup/?cusips=${query.identifier.filterValue}`;
      break;
    case Constants.QUERY_TYPE.SAVED_SEARCH_ACTION:
      params.method = query.action;
      params.url = `${
        query.isAdmin ? DataConfig.SAVEDSEARCH_ENDPOINT_ADMIN : DataConfig.SAVEDSEARCH_ENDPOINT
      }/`;
      if (params.method === "get") {
        queryString = _queryStringFromQuery(query);
        params.url = `${params.url}?${queryString}`;
      }
      if (utils.hasNonEmptyValue(query.identifier)) {
        params.url = `${params.url}${query.identifier}`;
      }
      if (utils.hasNonEmptyValue(query.params)) {
        params.data = query.params;
      }
      break;
    case Constants.QUERY_TYPE.NEWS_ACTION:
      params.method = query.action;
      params.url = `${DataConfig.NEWS_ENDPOINT}/`;
      if (params.method === "get") {
        params.url = `${params.url}`;
      }
      if (utils.hasNonEmptyValue(query.identifier)) {
        params.url = `${params.url}${query.identifier}`;
      }
      if (utils.hasNonEmptyValue(query.params)) {
        params.data = query.params;
      }
      break;
    case Constants.QUERY_TYPE.DOCUMENTS:
      params.method = query.action;
      params.url = `${DataConfig.RESOURCE_DOCUMENTS_ENDPOINT}`;
      if (utils.hasNonEmptyValue(query.identifier)) {
        params.url = `${params.url}${query.identifier}`;
      }
      if (utils.hasNonEmptyValue(query.params)) {
        params.data = query.params;
      }
      break;
    case Constants.QUERY_TYPE.TRADE_DOCUMENTS:
      params.method = query.action;
      params.url = `${DataConfig.TRADE_DOCUMENTS_ENDPOINT}`;
      if (utils.hasNonEmptyValue(query.buySellType)) {
        params.url = `${params.url}${query.buySellType}/`;
      }
      if (utils.hasNonEmptyValue(query.identifier)) {
        params.url = `${params.url}${query.identifier}`;
      }
      if (utils.hasNonEmptyValue(query.params)) {
        params.data = query.params;
      }
      break;
    case Constants.QUERY_TYPE.SAVED_CRITERIA_ACTION:
      params.method = query.action;
      params.url = `${DataConfig.COMPARISON_ENDPOINT.SAVED}/`;
      if (utils.hasNonEmptyValue(query.identifier)) {
        params.url = `${params.url}${query.identifier.filterValue}`;
      }
      if (utils.hasNonEmptyValue(query.params)) {
        params.data = query.params;
      }
      // FIX ME: THIS BREAKS SAVING NEW SEARCHES
      // if (query.params) {
      //   params.params = query.params;
      // }
      break;
    case Constants.QUERY_TYPE.FAVORITES_ACTION:
      params.method = query.action;
      params.url = DataConfig.SAVEDSEARCH_ENDPOINT;
      if (utils.hasNonEmptyValue(query.identifier)) {
        params.url += `/${query.identifier.filterValue}`;
      }
      if (utils.hasNonEmptyValue(query.params)) {
        params.data = query.params;
      }
      break;
    case Constants.QUERY_TYPE.ISSUER_TRACKER:
      if (!query.issuers || query.issuers.length === 0) {
        return null;
      }
      queryString = _queryStringFromQuery(query);
      params.url = `${DataConfig.ISSUER_LIST}?${queryString}`;
      break;
    case Constants.QUERY_TYPE.AFFILIATES:
      params.url = DataConfig.AFFILIATES_ENDPOINT;
      break;
    case Constants.QUERY_TYPE.AFFILIATES_TRADERS:
      params.url = `${DataConfig.AFFILIATES_TRADERS_ENDPOINT}?companyId=${query.companyId}`;
      break;
    default:
      return null;
  }

  // Add this for (advisory) security checks
  params.query = query.type;
  return params;
};

const _queryStringFromQuery = (query) => {
  let qs = "";

  if (query.size) {
    qs = `size=${query.size}`;
  } else {
    qs = `size=${DataConfig.DEFAULT_PAGE_SIZE}`;
  }

  if (query.page) {
    qs = `${qs}&page=${query.page}`;
  } else {
    qs = `${qs}&page=0`;
  }

  if (query.category) {
    qs = `${qs}&category=${query.category}`;
  }

  if (query.issuers) {
    qs = `${qs}&issuers=${query.issuers.join(",")}`;
  }

  if (query.reloadId) {
    qs = `${qs}&reload=${query.reloadId}`;
  }

  const filterAndSortQuerystring = getFilterAndSortQueryString(query);

  if (filterAndSortQuerystring) qs = `${qs}&${filterAndSortQuerystring}`;

  return qs;
};

export const getFilterAndSortQueryString = (query) => {
  let qs = "";

  if (utils.hasNonEmptyValue(query.sort)) {
    //sort=quantity,desc&sort=cusip,asc
    const sort = query.sort.map((s) => {
      return `sort=${s.id},${s.desc ? "desc" : "asc"}`;
    });
    qs += sort.join("&");
  }

  const filter = query.filter;

  if (utils.hasNonEmptyValue(filter)) {
    let clauses = [];

    for (let key in filter) {
      if (filter.hasOwnProperty(key)) {
        if (key === "instrumentCategory") {
          if (!filter.hasOwnProperty("instrumentTypeId")) {
            clauses.push(_getInstrumentCategoryClause(filter.instrumentCategory));
          } else if (filter.instrumentTypeId.filterValue === "(128,129)") {
            delete filter.instrumentTypeId;
            clauses.push(_getInstrumentCategoryClause(Constants.INVENTORY_TYPE.CD));
          }
        } else {
          const f = filter[key];
          let value = f.filterValue;
          switch (f.filterType) {
            case Constants.FILTER_TYPE.NONE:
              break;
            case Constants.FILTER_TYPE.SELECTRATING:
              if (utils.hasNonEmptyValue(value.value)) {
                if (Array.isArray(value.value)) {
                  const values = value.value.join(",");
                  clauses.push(`${key}=in=(${values})`);
                } else {
                  clauses.push(`${key}=ge=${value.value}`);
                }

                if (utils.hasNonEmptyValue(value.exclude)) {
                  clauses.push(`${key}!=${value.exclude}`);
                }
              }
              break;
            case Constants.FILTER_TYPE.DUALRANGE:
            case Constants.FILTER_TYPE.RANGE:
              if (utils.hasNonEmptyValue(value[0])) {
                clauses.push(`${key}=ge=${value[0]}`);
              }
              if (utils.hasNonEmptyValue(value[1])) {
                clauses.push(`${key}=le=${value[1]}`);
              }
              break;
            case Constants.FILTER_TYPE.DATERANGE:
              _addDataRangeSearchClauses(key, value, clauses);
              break;
            case Constants.FILTER_TYPE.DATE_RANGE_INCREMENT:
              let startDate = moment();
              let endDate = null;
              if (utils.hasNonEmptyValue(value[0])) {
                startDate.add(value[0], "month");
              }
              if (utils.hasNonEmptyValue(value[1])) {
                endDate = moment().add(value[1], "month").format(DataConfig.DATE_FORMAT.FILTER);
              }
              startDate = startDate.format(DataConfig.DATE_FORMAT.FILTER);
              _addDataRangeSearchClauses(key, [startDate, endDate], clauses);
              break;
            case Constants.FILTER_TYPE.KEYWORD:
              if (utils.hasNonEmptyValue(value)) {
                value = value.replace(/'/g, "\\'");
                value = encodeURIComponent(value);
                clauses.push(`${key}=='${value}'`);
              }
              break;
            case Constants.FILTER_TYPE.KEYWORD_WILDCARD:
              if (utils.hasNonEmptyValue(value)) {
                value = value.replace(/'/g, "\\'");
                if (value.lastIndexOf("*") !== value.length - 1) {
                  value = `${value}*`;
                }
                value = encodeURIComponent(value);
                clauses.push(`${key}=='${value}'`);
              }
              break;
            case Constants.FILTER_TYPE.CHECKBOX:
              const operator = value.omit ? "out" : "in";
              if (utils.hasNonEmptyValue(value.selected)) {
                const values = value.selected.join(",");
                clauses.push(`${key}=${operator}=(${values})`);
              }
              break;
            case Constants.FILTER_TYPE.BOOLEAN_TOGGLE:
              if (utils.hasNonEmptyValue(value)) {
                clauses.push(`${key}==${value}`);
              }
              break;
            case Constants.FILTER_TYPE.TOGGLE:
              if (utils.hasNonEmptyValue(value)) {
                clauses.push(`${key}=='${value}'`);
              }
              break;
            case Constants.FILTER_TYPE.NEGATING:
              if (utils.hasNonEmptyValue(value)) {
                clauses.push(`${key}!='${value}'`);
              }
              break;
            default:
              if (utils.hasNonEmptyValue(value)) {
                clauses.push(`${key}==${value}`);
              }
              break;
          }
        }
      }
    }

    if (utils.hasNonEmptyValue(clauses)) {
      if (utils.hasNonEmptyValue(qs)) {
        qs += "&";
      }
      qs += `search=${clauses.join(" and ")}`;
    }
  }

  return qs;
};

const _addDataRangeSearchClauses = (key, value, clauses) => {
  const propertyConfig = get(DataConfig.PROPERTY, key, {});
  const apiFormat = propertyConfig.apiFormat || DataConfig.DATE_FORMAT.API;

  if (utils.hasNonEmptyValue(value[0])) {
    let startDate = moment(value[0], DataConfig.DATE_FORMAT.FILTER);

    if (!startDate.isValid()) {
      startDate = moment(value[0]);
    }

    if (startDate.isValid()) {
      const startDateString = startDate.format(apiFormat);
      clauses.push(`${key}=ge='${startDateString}'`);
    }
  }
  if (utils.hasNonEmptyValue(value[1])) {
    let endDate = moment(value[1], DataConfig.DATE_FORMAT.FILTER);

    if (!endDate.isValid()) {
      endDate = moment(value[1]);
    }

    if (endDate.isValid()) {
      if (apiFormat === DataConfig.DATE_FORMAT.API_TIMESTAMP) {
        endDate = endDate.add(1, "days").subtract("1", "milliseconds");
      }
      const endDateString = endDate.format(apiFormat);
      clauses.push(`${key}=le='${endDateString}'`);
    }
  }
};

const _getQueryStringParamPairsFromFilter = (filter) => {
  const params = [];

  for (var f in filter) {
    if (filter.hasOwnProperty(f)) {
      const filterValue = Array.isArray(filter[f].filterValue)
        ? filter[f].filterValue[0]
        : filter[f].filterValue;
      params.push(`${filter[f].filterKey}=${filterValue}`);
    }
  }

  return params.join("&");
};

const _getInstrumentCategoryClause = (instrumentCategory) => {
  let clause = "";
  switch (instrumentCategory) {
    case Constants.INVENTORY_TYPE.CD:
      return "instrumentTypeId=in=(128,129)";
    case Constants.INVENTORY_TYPE.AGENCY:
      clause = 130;
      break;
    case Constants.INVENTORY_TYPE.TREASURY:
      clause = 131;
      break;
    case Constants.INVENTORY_TYPE.MUNICIPAL:
      clause = 132;
      break;
    case Constants.INVENTORY_TYPE.MORTGAGE:
      clause = 133;
      break;
    default:
      return "";
  }

  return `instrumentTypeId==${clause}`;
};

const _getInstrumentTypeFromClause = (clause) => {
  let value = Object.values(clause)[0];

  value = utils.removeEnclosingParentheses(value);
  value = value.split(",")[0];
  value = utils.removeEnclosingQuotes(value);

  let intrumentType;

  switch (value) {
    case Constants.INSTRUMENT_TYPE.CD_PRIMARY:
    case Constants.INSTRUMENT_TYPE.CD_SECONDARY:
    case "128":
    case "129":
      intrumentType = Constants.INVENTORY_TYPE.CD;
      break;
    case Constants.INSTRUMENT_TYPE.AGENCY:
    case "130":
      intrumentType = Constants.INVENTORY_TYPE.AGENCY;
      break;
    case Constants.INSTRUMENT_TYPE.TREASURY:
    case "131":
      intrumentType = Constants.INVENTORY_TYPE.TREASURY;
      break;
    case Constants.INSTRUMENT_TYPE.MUNICIPAL:
    case "132":
      intrumentType = Constants.INVENTORY_TYPE.MUNICIPAL;
      break;
    case Constants.INSTRUMENT_TYPE.MORTGAGE:
    case "133":
      intrumentType = Constants.INVENTORY_TYPE.MORTGAGE;
      break;
    default:
      return "";
  }

  return intrumentType;
};

const _parseFilter = (filterMap, key) => {
  const operatorMap = filterMap[key];

  let filter = { filterKey: key };

  const propertyConfig = get(DataConfig.PROPERTY, key, {});
  if (propertyConfig.filter) {
    filter.filterType = propertyConfig.filter.type;
  }

  for (var operator in operatorMap) {
    if (operatorMap.hasOwnProperty(operator) && utils.hasNonEmptyValue(operatorMap[operator])) {
      let value = operatorMap[operator].trim();
      switch (filter.filterType) {
        case Constants.FILTER_TYPE.SELECTRATING:
          if (operator === "=gt=" || operator === "=ge=") {
            value = utils.removeEnclosingQuotes(value).trim();
            filter.filterValue = {
              value: value,
            };
          }
          if (operator === "=in=" || operator === "=out=") {
            value = utils.removeEnclosingParentheses(value).trim();
            filter.filterValue = {
              value: value.split(",").map((v) => Number(v)),
            };
          }
          if (filter.filterValue) {
            if (operatorMap.hasOwnProperty("!=")) {
              filter.filterValue.exclude = operatorMap["!="];
            }
          }
          break;
        case Constants.FILTER_TYPE.CHECKBOX:
          if (operator === "=in=" || operator === "=out=") {
            value = utils.removeEnclosingParentheses(value).trim();
            filter.filterValue = {
              selected: value.split(","),
              omit: operator === "=out=",
            };
          }
          if (operator === "=" || operator === "==") {
            value = utils.removeEnclosingParentheses(value).trim();
            filter.filterValue = {
              selected: value.split(","),
              omit: false,
            };
          }
          break;
        case Constants.FILTER_TYPE.KEYWORD:
        case Constants.FILTER_TYPE.KEYWORD_WILDCARD:
          if (operator === "=" || operator === "==") {
            value = utils.removeEnclosingQuotes(value).trim();
            value = decodeURIComponent(value);
            value = value.replace(/\\'/g, "'");
            // remove trailing asterisk
            if (value.lastIndexOf("*") === value.length - 1) {
              value = value.substring(0, value.length - 1);
            }

            filter.filterValue = value;
          }
          break;
        case Constants.FILTER_TYPE.DUALRANGE:
        case Constants.FILTER_TYPE.RANGE:
          if (operator === "=ge=" || operator === "=gt") {
            filter.filterValue = filter.filterValue || [null, null];
            value = utils.removeEnclosingQuotes(value).trim();

            if (!isNaN(value)) {
              filter.filterValue[0] = Number(value);
            }
          }
          if (operator === "=le=" || operator === "=lt=") {
            filter.filterValue = filter.filterValue || [null, null];
            value = utils.removeEnclosingQuotes(value).trim();

            if (!isNaN(value)) {
              filter.filterValue[1] = Number(value);
            }
          }
          break;
        case Constants.FILTER_TYPE.DATERANGE:
          if (operator === "=ge=" || operator === "=gt") {
            filter.filterValue = filter.filterValue || [null, null];
            value = utils.removeEnclosingQuotes(value).trim();

            const dateValue =
              propertyConfig.apiFormat === DataConfig.DATE_FORMAT.API_TIMESTAMP
                ? utils.parseTimestamp(value)
                : utils.parseDate(value);
            if (dateValue.isValid()) {
              filter.filterValue[0] = dateValue.format(DataConfig.DATE_FORMAT.FILTER);
            }
          }
          if (operator === "=le=" || operator === "=lt=") {
            filter.filterValue = filter.filterValue || [null, null];
            value = utils.removeEnclosingQuotes(value);

            const dateValue =
              propertyConfig.apiFormat === DataConfig.DATE_FORMAT.API_TIMESTAMP
                ? utils.parseTimestamp(value)
                : utils.parseDate(value);
            if (dateValue.isValid()) {
              filter.filterValue[1] = dateValue.format(DataConfig.DATE_FORMAT.FILTER);
            }
          }
          break;
        default:
          value = utils.removeEnclosingQuotes(value);
          filter.filterValue = value;
          break;
      }
    }
  }

  return filter;
};

const _getQueryFromQueryString = (querystring) => {
  const queryParams = queryString.parse(querystring);
  const filterMap = {};
  const query = {};

  if (queryParams.search) {
    const filters = queryParams.search.split(" and ");
    filters.forEach((f) => {
      let operator;
      if (f.split("!=").length === 2) {
        operator = "!=";
      } else if (f.split("=").length === 2) {
        operator = "=";
      } else {
        operator = f.match(/=[a-z=]{0,3}=/g);
      }

      if (operator) {
        const filterParts = f.split(operator);
        if (
          filterParts.length === 2 &&
          utils.hasNonEmptyValue(filterParts[0]) &&
          utils.hasNonEmptyValue(filterParts[1])
        ) {
          const filterKey = filterParts[0].trim();
          filterMap[filterKey] = filterMap[filterKey] || {};
          filterMap[filterKey][operator] = filterParts[1].trim();
        }
      }
    });

    query.filter = {};

    for (var key in filterMap) {
      if (filterMap.hasOwnProperty(key)) {
        if (
          key === "instrumentType.description" ||
          key === "instrumentType.id" ||
          key === "instrumentTypeId"
        ) {
          query.filter.instrumentCategory = _getInstrumentTypeFromClause(filterMap[key]);
          if (key === "instrumentTypeId") {
            query.filter[key] = _parseFilter(filterMap, key);
          }
        } else {
          query.filter[key] = _parseFilter(filterMap, key);
        }
      }
    }
  }

  if (queryParams.sort) {
    query.sort = [];
    const sorts = queryParams.sort.split("&");
    sorts.forEach((sort) => {
      const sortParts = sort.split(",");
      if (sortParts.length === 2) {
        var sortConfig = {
          id: sortParts[0],
          desc: sortParts[1] === "desc",
        };
        query.sort.push(sortConfig);
      }
    });
  }

  if (queryParams.category) {
    query.category = queryParams.category;
  }

  if (queryParams.issuers) {
    query.issuers = queryParams.issuers.split(",");
  }

  return query;
};

export const getInventoryQueryFromQueryString = (querystring) => {
  const query = _getQueryFromQueryString(querystring);
  query.type = Constants.QUERY_TYPE.INVENTORY;
  query.searchId = Date.now();
  return query;
};

export const updateBondWithPortfolio = (bond) => {
  bond.hasHoldings = bond.holdings?.length > 0;
};

export const processApiData = ({ payload, query }) => {
  if (
    query.type === Constants.QUERY_TYPE.INVENTORY ||
    query.type === Constants.QUERY_TYPE.FAVORITES ||
    query.type === Constants.QUERY_TYPE.CUSIP_SEARCH
  ) {
    payload.content.forEach((b) => {
      b = b.bondInformation || b;
      updateBondWithPortfolio(b);
    });
  }

  if (payload.productRates) {
    const data = [];
    let maxValue = 0;
    const raw = payload.productRates;
    let i = 0;
    for (var e in raw) {
      if (raw.hasOwnProperty(e)) {
        const rawRec = raw[e];
        const rec = {
          bondType: {
            value: e,
            link: payload.productLinks[e].link,
            queryString: `search=${payload.productLinks[e].searchQueryString}&sort=${payload.productLinks[e].sortQueryString}`,
          },
          id: i,
        };
        for (var c in rawRec) {
          if (rawRec.hasOwnProperty(c)) {
            maxValue = Math.max(maxValue, Math.abs(rawRec[c].value));
            if (utils.hasNonEmptyValue(rawRec[c].value)) {
              rec[c] = { value: rawRec[c].value, link: rawRec[c].link, valueScale: rawRec[c].valueScale };
              if (utils.hasNonEmptyValue(rawRec[c].searchQueryString)) {
                rec[
                  c
                ].queryString = `search=${rawRec[c].searchQueryString}&sort=${rawRec[c].sortQueryString}`;
              }
            } else {
              rec[c] = { value: "--" };
            }
          }
        }
        data.push(rec);
        i++;
      }
    }

    data.forEach((d) => {
      d.maxHeatValue = maxValue;
    });

    payload.content = data;

    payload.page = {
      number: 0,
      size: data.length,
      totalElements: data.length,
      totalPages: 1,
    };
  }

  if (payload.tiers) {
    let comparable = [];
    payload.tiers.forEach((tier, i) => {
      if (utils.hasNonEmptyValue(tier.bonds)) {
        comparable.push({
          tier: `TIER ${utils.formatRomanNumeral(tier.tierNumber)}`,
          tierDescription: tier.tierDescription,
        });
      }

      tier.bonds = tier.bonds.filter((b) => !isNil(b));
      tier.bonds.forEach((bond, j) => {
        bond.tierDescription = tier.tierDescription;
        bond.tierNumber = tier.tierNumber;
        updateBondWithPortfolio(bond);
        comparable.push(bond);
      });
    });

    payload.content = comparable;

    payload.page = {
      number: 0,
      size: comparable.length,
      numRecs: comparable.length,
      totalElements: comparable.length,
      totalPages: 1,
    };
  }

  if (query.type === Constants.QUERY_TYPE.COMPARABLE_CUSTOM) {
    //if (payload.content.length > 0) {
    payload.content.unshift({
      tier: query.description || "CUSTOM SEARCH",
      tierDescription: "These bonds have been found using your custom search criteria",
    });
    //}
  }
};
