import React from "react";
import { useGlobal } from "reactn";
import { get, cloneDeep } from "lodash";
import PropTypes from "prop-types";
import clsx from "clsx";

import * as Constants from "commons/constants";
import { useMessages } from "providers/BrandingProvider";
import * as utils from "commons/utils";
import * as filterUtils from "commons/filterUtils";
import { setPreference } from "services/PreferencesService";
import { useViewState, useViewStateDispatcher } from "providers/ViewStateProvider";
import { useDataService } from "services/DataService";
import { ClosableModal } from "components/containers/Modals";
import { StyledSecondaryTooltip, StyledCheck, ActionButton, SettingsButton } from "components/Controls";
import QBTypography from "components/QBTypography";
import SavedCriteria from "components/SavedCriteria";
import {
  AdvancedCriteriaDateRangeIncrementFilter,
  AdvancedCriteriaNumericRangeIncrementFilter,
  AdvancedCriteriaDualRangeFilter,
  AdvancedCriteriaCheckboxFilter,
  AdvancedCriteriaSelectRatingFilter,
  AdvancedCriteriaTaxStatusFilter,
} from "./Filters";

import Fade from "@material-ui/core/Fade";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  wrapper: {
    color: theme.palette.text.popper.text,
  },
  header: {
    marginBottom: 40,
    marginTop: 10,
  },
  body: {
    paddingRight: 8,
    height: "calc(100% - 250px)",
    maxHeight: "calc(97vh - 250px)",
    overflowY: "auto",
  },
  footer: {
    marginTop: 20,
    marginBottom: 10,
    textAlign: "center",
  },
  button: {
    lineHeight: 1,
    padding: 7,
    fontSize: "0.6rem",
    marginLeft: 4,
  },
  compareBond: {
    color: theme.palette.primary.main,
  },
  checkboxLabel: {
    marginLeft: 4,
    fontSize: "0.7rem",
    color: theme.palette.text.secondary,
  },
  disabled: {
    color: theme.palette.text.disabled,
  },
}));

const getDefaultValue = (config, compareBond, tier) => {
  const accessor = config.accessor;
  const instrumentCategory = compareBond?.instrumentCategory;
  const bondValue = get(compareBond, accessor);

  if (config.filter.type === Constants.FILTER_TYPE.DATE_RANGE_INCREMENT) {
    let increment = 12;

    if (
      instrumentCategory === Constants.INVENTORY_TYPE.TREASURY ||
      instrumentCategory === Constants.INVENTORY_TYPE.AGENCY ||
      instrumentCategory === Constants.INVENTORY_TYPE.CD
    ) {
      switch (tier) {
        case 1:
          increment = 3;
          break;
        case 2:
          increment = 6;
          break;
        case 3:
        default:
          increment = 12;
      }
    }
    return increment;
  }

  if (config.filter.type === Constants.FILTER_TYPE.NUMERIC_RANGE_INCREMENT) {
    let increment;

    if (config.accessor === "interestRate") {
      switch (tier) {
        case 1:
          increment = 0;
          break;
        case 2:
        case 3:
        default:
          increment = 0.5;
      }
    }

    if (config.accessor === "weightedAverageLife") {
      switch (tier) {
        case 1:
        case 2:
          increment = 0.5;
          break;
        case 3:
        default:
          increment = 1.0;
      }
    }

    return increment;
  }

  if (config.filter.type === Constants.FILTER_TYPE.DUALRANGE) {
    return [null, null];
  }

  if (config.filter.type === Constants.FILTER_TYPE.CHECKBOX) {
    if (accessor === "state") {
      return {
        selected: [],
      };
    }

    const selected = bondValue ? [bondValue.toString()] : [];

    return {
      selected: selected,
    };
  }

  if (config.filter.type === Constants.FILTER_TYPE.SELECTRATING) {
    let increment = tier === 1 ? 0 : -1;

    if (!bondValue) {
      return {
        increment: increment,
      };
    }

    if (bondValue > 99) {
      return {
        increment: increment,
        value: 17,
      };
    }

    let value;

    if (tier === 1) {
      value = bondValue;
    } else {
      value = bondValue - 1;
    }

    return {
      increment: increment,
      value: value,
      exclude: 999,
    };
  }

  if (config.filter.type === Constants.FILTER_TYPE.TOGGLE) {
    let val;

    switch (tier) {
      case 1:
      case 2:
        val = bondValue?.toString();
        break;
      case 3:
      default:
        break;
    }
    return val;
  }

  return;
};

const getFilterComponent = (configClone) => {
  const type = configClone.type || configClone.filter.type;

  switch (type) {
    case Constants.FILTER_TYPE.DATE_RANGE_INCREMENT:
      return AdvancedCriteriaDateRangeIncrementFilter;
    case Constants.FILTER_TYPE.NUMERIC_RANGE_INCREMENT:
      return AdvancedCriteriaNumericRangeIncrementFilter;
    case Constants.FILTER_TYPE.DUALRANGE:
      return AdvancedCriteriaDualRangeFilter;
    case Constants.FILTER_TYPE.CHECKBOX:
      return AdvancedCriteriaCheckboxFilter;
    case Constants.FILTER_TYPE.SELECTRATING:
      return AdvancedCriteriaSelectRatingFilter;
    case Constants.FILTER_TYPE.TAXSTATUS_TOGGLEGROUP:
      return AdvancedCriteriaTaxStatusFilter;
    default:
      return null;
  }
};

const AdvancedCriteriaModal = ({ filterConfigs = null, tier = 3, onClose }) => {
  const classes = useStyles();
  const Messages = useMessages();
  const viewState = useViewState();
  const dispatchViewStateChange = useViewStateDispatcher();
  const [fetchState, fetchFromDataService] = useDataService();
  const [userPrefs = {}] = useGlobal("userprefs");

  const savedCriteriaPrefs = userPrefs.defaultCompBondSearch || {};

  const comparableView = get(viewState, Constants.VIEW.COMPARABLE, {});
  const compareBond = comparableView.compareBond;
  const prefApplyToAll = savedCriteriaPrefs[compareBond?.instrumentCategory];

  const [applyToAll, setApplyToAll] = React.useState(false);

  const [query, setQuery] = React.useState();
  const [savedSearches, setSavedSearches] = React.useState([]);

  const open = filterConfigs !== null;

  const configs = filterConfigs
    ?.map((config) => {
      const configClone = cloneDeep(config);
      configClone.filter = config.criteriumfilter || config.filter;
      configClone.Component = getFilterComponent(configClone);
      return configClone;
    })
    .filter((config) => {
      if (
        config.filter &&
        (config.filter.type === Constants.FILTER_TYPE.DATE_RANGE_INCREMENT ||
          config.filter.type === Constants.FILTER_TYPE.NUMERIC_RANGE_INCREMENT)
      ) {
        return utils.hasNonEmptyValue(get(compareBond, config.accessor));
      }
      return true;
    });

  React.useEffect(() => {
    if (compareBond && open) {
      const currentQuery = getCurrentQuery();
      setQuery(currentQuery);
      setApplyToAll(isAppliedToAll(currentQuery));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [compareBond, open]);

  React.useEffect(() => {
    if (utils.hasNonEmptyValue(savedSearches)) {
      querySavedCriteria();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    if (!fetchState.isLoading && !fetchState.isError) {
      // sort
      const fetchedData = fetchState.data
        .map((datum) => {
          const filterConfig = JSON.parse(datum.filterConfig);
          if (!filterConfig.hasOwnProperty("instrumentCategory")) {
            filterConfig.filter = filterConfig.filter || {};
            filterConfig.filter.instrumentCategory = Constants.INSTRUMENT_CATEGORY[datum.instrumentType];
            datum.filterConfig = JSON.stringify(filterConfig);
          }
          return datum;
        })
        .filter((d) => utils.hasNonEmptyValue(d));

      setSavedSearches(fetchedData);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchState.data]);

  const isAppliedToAll = (query) => {
    return (
      utils.hasNonEmptyValue(prefApplyToAll) && utils.hasNonEmptyValue(query) && prefApplyToAll === query.id
    );
  };

  const getDefaultFilterValuesFromConfig = () => {
    const filter = {
      instrumentCategory: compareBond?.instrumentCategory,
    };

    configs.forEach((config) => {
      if (config.filter) {
        filter[config.accessor] = {
          filterKey: config.accessor,
          filterValue: getDefaultValue(config, compareBond, tier),
          filterType: config.filter.type,
        };
      } else if (config.filters) {
        config.filters.forEach((f) => {
          filter[f.accessor] = {
            filterKey: f.accessor,
            filterValue: getDefaultValue(f, compareBond, tier),
            filterType: f.filter.type,
          };
        });
      }
    });

    return filter;
  };

  const getCurrentSearchCriteria = () => {
    let activeSearchCriteria = utils.findActiveCompBondQuery(viewState, compareBond);
    const qtyFilterKey = utils.getCompBondQuantityFilterKey(compareBond);

    if (activeSearchCriteria) {
      activeSearchCriteria = cloneDeep(activeSearchCriteria);
      delete activeSearchCriteria.filter[qtyFilterKey];
      if (Object.keys(activeSearchCriteria.filter).length === 0) {
        return;
      }
    }

    return activeSearchCriteria;
  };

  const getCurrentQuery = () => {
    const ignored = [];

    filterConfigs.forEach((config) => {
      const filter = config.criteriumfilter || config.filter;
      if (
        filter &&
        !utils.hasNonEmptyValue(get(compareBond, config.accessor)) &&
        (filter.type === Constants.FILTER_TYPE.DATE_RANGE_INCREMENT ||
          filter.type === Constants.FILTER_TYPE.NUMERIC_RANGE_INCREMENT)
      ) {
        ignored.push(config.accessor);
      }
    });

    let currentQuery = {
      filter: {},
      ignore: ignored,
      sort: [{ id: "yieldToWorst", desc: true }],
      type: Constants.QUERY_TYPE.COMPARABLE_CUSTOM,
      size: 5,
    };

    let activeSearchCriteria = getCurrentSearchCriteria();

    if (utils.hasNonEmptyValue(activeSearchCriteria)) {
      currentQuery = { ...currentQuery, ...cloneDeep(activeSearchCriteria) };
    }

    const defaultFilter = getDefaultFilterValuesFromConfig();
    currentQuery.filter = { ...defaultFilter, ...currentQuery.filter };

    return currentQuery;
  };

  const querySavedCriteria = () => {
    const queryParams = {
      type: Constants.QUERY_TYPE.SAVED_CRITERIA_ACTION,
      action: "get",
      clearDataOnFetch: false,
    };
    fetchFromDataService(queryParams);
  };

  const handleFilterChange = (filters) => {
    filters = Array.isArray(filters) ? filters : [filters];

    filters.forEach((filter) => {
      if (filter.filterKey === "ignore") {
        const ignore = filter.filterValue.ignore;
        const accessor = filter.filterValue.accessor;
        if (accessor) {
          setQuery((updatedQuery) => {
            if (ignore) {
              updatedQuery.ignore.push(accessor);
            } else {
              updatedQuery.ignore = updatedQuery.ignore.filter((c) => c !== accessor);
            }
            return cloneDeep(updatedQuery);
          });
        }
      } else {
        setQuery((updatedQuery) => {
          updatedQuery.filter[filter.filterKey] = filter;
          return cloneDeep(updatedQuery);
        });
      }
    });
  };

  const handleDeleteSearch = (id) => {
    if (query.id === id) {
      savedCriteriaPrefs[compareBond.instrumentCategory] = null;
      setPreference("defaultCompBondSearch", savedCriteriaPrefs);

      setApplyToAll(false);
      setQuery((query) => {
        delete query.id;
        delete query.description;
        return cloneDeep(query);
      });
    }

    querySavedCriteria();
  };

  const handleSetSearch = (search) => {
    let query = JSON.parse(search.filterConfig);
    query.description = search.description;
    query.id = search.id;

    const defaultFilter = getDefaultFilterValuesFromConfig();
    query.filter = { ...defaultFilter, ...query.filter };

    setApplyToAll(isAppliedToAll(query));
    setQuery(query);
  };

  const executeSearch = () => {
    const originalSavedSearch = savedSearches.find((s) => s.id === query.id);
    const isModifiedSavedQuery = utils.isDirtyCompBondSavedQuery(query, savedSearches);

    const updatedQuery = cloneDeep(query);

    let updatedFilter = {};

    for (var filterKey in updatedQuery.filter) {
      if (
        filterKey === "instrumentCategory" ||
        (!query.ignore.includes(filterKey) &&
          !filterUtils.hasEmptyFilterValue(query.filter[filterKey].filterValue))
      ) {
        updatedFilter[filterKey] = query.filter[filterKey];
      }
    }

    updatedQuery.filter = updatedFilter;
    updatedQuery.searchId = Date.now();
    updatedQuery.description = originalSavedSearch?.description;

    // applyToAll sets category-scoped query and removes all cusip-scoped
    if (applyToAll && !isModifiedSavedQuery) {
      const state = {
        selectedBond: compareBond,
      };

      state[compareBond.instrumentCategory] = {
        query: updatedQuery,
      };

      const updatedState = {
        state: state,
        view: Constants.VIEW.COMPARABLE,
      };

      dispatchViewStateChange(updatedState);

      if (prefApplyToAll !== updatedQuery.id) {
        savedCriteriaPrefs[compareBond.instrumentCategory] = updatedQuery.id;
        setPreference("defaultCompBondSearch", savedCriteriaPrefs);
      }
    } else {
      // applyToAll has been unchecked
      if (prefApplyToAll && prefApplyToAll === updatedQuery.id && !applyToAll && !isModifiedSavedQuery) {
        delete savedCriteriaPrefs[compareBond.instrumentCategory];
        setPreference("defaultCompBondSearch", savedCriteriaPrefs);

        // set query to be cusip-scoped; remove category-scoped search
        const state = {};

        state[compareBond.instrumentCategory] = {};
        state[compareBond.instrumentCategory][compareBond.cusip] = {
          query: updatedQuery,
        };

        const updatedState = {
          state: state,
          view: Constants.VIEW.COMPARABLE,
        };

        dispatchViewStateChange(updatedState);

        // otherwise just set cusip-scoped query
      } else {
        // this is a 'Custom Search'
        if (isModifiedSavedQuery) {
          delete updatedQuery.description;
          delete updatedQuery.id;
        }

        const updatedState = {
          state: {
            query: updatedQuery,
          },
          view: Constants.VIEW.COMPARABLE,
          subView: compareBond.instrumentCategory,
          querySubType: compareBond.cusip,
        };

        dispatchViewStateChange(updatedState);
      }
    }

    onClose();
  };

  const executeDefaultSearch = () => {
    const hasAppliedAllBeenUnchecked = prefApplyToAll && prefApplyToAll === query.id && !applyToAll;
    clearSearch(hasAppliedAllBeenUnchecked);
  };

  const clearSearch = (forAllOfType) => {
    // retain cusip-defined quantity filter
    const qtyFilter = utils.findCompBondQuantityFilter(viewState, compareBond);

    if (forAllOfType) {
      const state = {};
      state[compareBond.instrumentCategory] = {};
      if (utils.hasNonEmptyValue(qtyFilter)) {
        state[compareBond.instrumentCategory][compareBond.cusip] = {
          query: {
            filter: {},
          },
        };

        state[compareBond.instrumentCategory][compareBond.cusip]["query"]["filter"][qtyFilter.filterKey] =
          cloneDeep(qtyFilter);
      }

      const updatedState = {
        state: state,
        view: Constants.VIEW.COMPARABLE,
      };
      dispatchViewStateChange(updatedState);

      delete savedCriteriaPrefs[compareBond.instrumentCategory];
      setPreference("defaultCompBondSearch", savedCriteriaPrefs);
    } else {
      const state = {};
      if (utils.hasNonEmptyValue(qtyFilter)) {
        state.query = {
          filter: {},
        };

        state["query"]["filter"][qtyFilter.filterKey] = qtyFilter;
      }

      const updatedState = {
        state: state,
        view: Constants.VIEW.COMPARABLE,
        subView: compareBond.instrumentCategory,
        querySubType: compareBond.cusip,
      };
      dispatchViewStateChange(updatedState);
    }

    onClose();
  };

  const instrumentText =
    compareBond?.instrumentCategory === Constants.INVENTORY_TYPE.CD
      ? Messages.LABEL.CD
      : Messages.LABEL.BOND.toLowerCase();
  const categoryText = utils.hasNonEmptyValue(compareBond)
    ? Messages.LABEL[utils.getInventoryKeyFromType(compareBond.instrumentCategory)]
    : "";

  const getHeader = () => {
    const isEditingActiveSearch = utils.hasNonEmptyValue(getCurrentSearchCriteria());
    const cusipText = <span className={classes.compareBond}>{compareBond.cusip}</span>;

    if (query?.description) {
      const descriptionText = <span style={{ fontWeight: 400 }}>{query.description}</span>;

      if (utils.isDirtyCompBondSavedQuery(query, savedSearches)) {
        return <span>Custom Search Criteria for {cusipText}</span>;
      } else if (applyToAll) {
        return (
          <span>
            {descriptionText} (All {categoryText} searches)
          </span>
        );
      } else {
        return (
          <span>
            {descriptionText} ({cusipText} only)
          </span>
        );
      }
    } else {
      if (isEditingActiveSearch) {
        return <span>Custom Search Criteria for {cusipText}</span>;
      } else {
        return <span>New Search Criteria for {cusipText}</span>;
      }
    }
  };

  return utils.hasNonEmptyValue(compareBond) ? (
    <>
      <ClosableModal
        open={open}
        onClose={onClose}
        scrollable={false}
        disableEnforceFocus={true}
        header={getHeader()}
        width={600}
      >
        {utils.hasNonEmptyValue(compareBond) && (
          <div className={classes.wrapper}>
            <div className={classes.header}>
              <QBTypography variant="caption">
                Below were some of the criteria used to find {instrumentText}s comparable to{" "}
                <span className={classes.compareBond}>{compareBond ? compareBond.cusip : ""}.</span> You may
                adjust the criteria to find your preference of comparable {instrumentText}.
              </QBTypography>
              <div
                style={{ display: "flex", marginTop: 20, justifyContent: "flex-start", alignItems: "center" }}
              >
                <div style={{ flexGrow: 1 }}>
                  <SavedCriteria
                    compareBond={compareBond}
                    query={query}
                    searches={savedSearches}
                    onSearchesUpdated={querySavedCriteria}
                    onSetSearch={handleSetSearch}
                    onDeleteSearch={handleDeleteSearch}
                  />
                  <div style={{ display: "flex" }}>
                    <StyledCheck
                      color="primary"
                      checked={applyToAll}
                      disabled={!query || !query.id || utils.isDirtyCompBondSavedQuery(query, savedSearches)}
                      onChange={(e) => setApplyToAll(!applyToAll)}
                    />
                    <span
                      className={clsx(classes.checkboxLabel, {
                        [classes.disabled]:
                          !query || !query.id || utils.isDirtyCompBondSavedQuery(query, savedSearches),
                      })}
                    >
                      {`Use this search for all ${categoryText} searches`}
                    </span>
                  </div>
                </div>
              </div>

              <div style={{ display: "flex", marginTop: 20 }}>
                <div
                  style={{
                    display: "flex",
                    flexGrow: 1,
                    justifyContent: "flex-start",
                    alignItems: "center",
                    marginRight: 4,
                  }}
                >
                  {utils.hasNonEmptyValue(getCurrentSearchCriteria()) && (
                    <Button
                      color="secondary"
                      variant="outlined"
                      className={classes.button}
                      type="button"
                      onClick={executeDefaultSearch}
                    >
                      Clear (This {instrumentText} only)
                    </Button>
                  )}
                  {isAppliedToAll(query) && (
                    <Button
                      color="secondary"
                      variant="outlined"
                      className={classes.button}
                      type="button"
                      onClick={(e) => clearSearch(true)}
                    >
                      Clear (All {categoryText})
                    </Button>
                  )}
                  <Button
                    color="secondary"
                    variant="outlined"
                    className={classes.button}
                    type="button"
                    onClick={onClose}
                  >
                    {Messages.LABEL.CANCEL}
                  </Button>
                </div>
                <div
                  style={{ display: "flex", justifyContent: "flex-end", alignItems: "center", marginLeft: 4 }}
                >
                  <ActionButton
                    onClick={executeSearch}
                    style={{ marginRight: 8 }}
                    variant="outlined"
                    color="secondary"
                    size="small"
                  >
                    {Messages.LABEL.APPLY_SEARCH}
                  </ActionButton>
                </div>
              </div>
            </div>
            <div className={classes.body}>
              <Fade in={utils.hasNonEmptyValue(query)}>
                <div>
                  {query &&
                    configs?.map((config, i) => {
                      const FilterComponent = config.Component;
                      return (
                        <FilterComponent
                          key={config.key || config.accessor}
                          bond={compareBond}
                          query={query}
                          config={config}
                          searches={savedSearches}
                          onFilterChange={handleFilterChange}
                        />
                      );
                    })}
                </div>
              </Fade>
            </div>
          </div>
        )}
      </ClosableModal>
    </>
  ) : null;
};

export const AdvancedCriteriaModalButton = ({ tier, filterConfigs, onToggleAdvancedCriteriaModal }) => {
  const Messages = useMessages();

  return (
    <StyledSecondaryTooltip title={Messages.TOOLTIP.CRITERIA}>
      <div>
        <SettingsButton onClick={(e) => onToggleAdvancedCriteriaModal(tier, filterConfigs)} />
      </div>
    </StyledSecondaryTooltip>
  );
};

AdvancedCriteriaModalButton.propTypes = {
  filterConfigs: PropTypes.arrayOf(PropTypes.object).isRequired,
  tier: PropTypes.number,
  onToggleAdvancedCriteriaModal: PropTypes.func.isRequired,
};

AdvancedCriteriaModal.propTypes = {
  filterConfigs: PropTypes.arrayOf(PropTypes.object),
  tier: PropTypes.number,
};

export default AdvancedCriteriaModal;
