import React from "react";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";

import { useMessages } from "providers/BrandingProvider";
import * as utils from "commons/utils";
import QBTypography from "components/QBTypography";
import { FormButton, LinkButton } from "components/Controls";

import Collapse from "@material-ui/core/Collapse";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  body: {
    maxHeight: "calc(99vh - 200px)",
  },
  caption: {
    marginTop: 10,
  },
  row: {
    width: "100%",
    display: "flex",
    margin: "auto 0",
  },
  rowSection: {
    "&:first-child": {
      flexGrow: 1,
    },
  },
  ticketButton: {
    marginLeft: 4,
    whiteSpace: "nowrap",
  },
  userMessage: {
    fontSize: "0.75rem",
    marginTop: 15,
  },
  narrowtable: {
    margin: "20px auto",
    minWidth: 600,
  },
  success: {
    textAlign: "center",
    margin: "5px 0 30px 0",
  },
  spacer: {
    margin: 10,
  },
}));

export const TicketBody = React.forwardRef((props, ref) => {
  const classes = useStyles();
  const { children, style = {} } = props;

  return (
    <div className={classes.body} style={style} ref={ref}>
      {children}
    </div>
  );
});

TicketBody.propTypes = {
  children: PropTypes.node.isRequired,
};

export const NarrowTableWrapper = (props) => {
  const classes = useStyles();
  const { children } = props;

  return <div className={classes.narrowtable}>{children}</div>;
};

NarrowTableWrapper.propTypes = {
  children: PropTypes.node.isRequired,
};

export const SuccessMessage = (props) => {
  const classes = useStyles();
  const { text } = props;

  if (!utils.hasNonEmptyValue(text)) {
    return null;
  }

  return (
    <div className={classes.success}>
      <QBTypography color="primary" variant="body1">
        {text}
      </QBTypography>
    </div>
  );
};

SuccessMessage.propTypes = {
  text: PropTypes.string,
};

export const Spacer = () => {
  const classes = useStyles();
  return <div className={classes.spacer}></div>;
};

export const UserMessage = ({ text }) => {
  const classes = useStyles();
  const Messages = useMessages();

  if (!text) return null;

  return (
    <div className={classes.userMessage}>
      {Messages.LABEL.INSTRUCTIONS}: {text}
    </div>
  );
};

UserMessage.propTypes = {
  text: PropTypes.string.isRequired,
};

export const TicketFooter = (props) => {
  const classes = useStyles();
  const { text, extendedText, helpSection, helpLinkText, onClose } = props;

  const [learnMore, setLearnMore] = React.useState(false);
  const history = useHistory();

  const learnMoreLink = learnMore ? "Less << " : "Learn More >> ";

  const handleHelpLinkClick = (path) => {
    onClose();
    history.push(path);
  };

  const HelpLink = () => {
    return (
      <LinkButton
        style={{ paddingTop: 0, paddingBottom: 0 }}
        onClick={(e) => handleHelpLinkClick(`/help#${helpSection}`)}
        label={helpLinkText}
      />
    );
  };

  return (
    <div className={classes.caption}>
      <Collapse in={!learnMore}>
        <div>
          <QBTypography variant="caption" style={{ fontSize: "0.7rem" }}>
            {text}
            {helpSection && helpLinkText && <HelpLink />}
          </QBTypography>
        </div>
      </Collapse>
      <Collapse in={learnMore}>
        <div>
          <QBTypography variant="caption" style={{ fontSize: "0.7rem" }}>
            {extendedText}
            {helpSection && helpLinkText && <HelpLink />}
          </QBTypography>
        </div>
      </Collapse>
      {utils.hasNonEmptyValue(extendedText) && (
        <LinkButton
          color="secondary"
          onClick={() => {
            setLearnMore(!learnMore);
          }}
          style={{ padding: 0, minWidth: 0 }}
          label={learnMoreLink}
        />
      )}
    </div>
  );
};

TicketFooter.propTypes = {
  text: PropTypes.string.isRequired,
};

export const TicketRow = (props) => {
  const classes = useStyles();
  const { children, ...otherProps } = props;

  return (
    <div className={classes.row} {...otherProps}>
      {children}
    </div>
  );
};

TicketRow.propTypes = {
  children: PropTypes.node.isRequired,
};

export const TicketRowSection = (props) => {
  const classes = useStyles();
  const { children, ...otherProps } = props;
  return (
    <div className={classes.rowSection} {...otherProps}>
      {children}
    </div>
  );
};

TicketRowSection.propTypes = {
  children: PropTypes.node,
};

export const TicketButton = (props) => {
  const classes = useStyles();
  const { onClick, label, ...otherProps } = props;
  return (
    <FormButton onClick={onClick} className={classes.ticketButton} variant="outlined" {...otherProps}>
      {label}
    </FormButton>
  );
};
