import React from "react";
import { useGlobal } from "reactn";
import PropTypes from "prop-types";
import { get } from "lodash";

import * as Messages from "commons/messages";
import { PopperPaper, PopperHeader } from "components/containers/Modals";
import { setPreference } from "services/PreferencesService";

import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import IconButton from "@material-ui/core/IconButton";
import EmailIcon from "@material-ui/icons/Email";
import Popper from "@material-ui/core/Popper";
import Fade from "@material-ui/core/Fade";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import { makeStyles } from "@material-ui/core/styles";
import { StyledTooltip } from "./Controls";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  transitionPopper: {
    zIndex: 10,
  },
  labelRoot: {
    margin: "5px 0",
    minWidth: 100,
  },
  label: {
    fontSize: "0.7rem",
    marginLeft: 0,
    letterSpacing: "0.03em",
    color: theme.palette.text.secondary,
  },
  radio: {
    width: 10,
    height: 10,
    marginRight: 4,
    "& .MuiSvgIcon-fontSizeSmall": {
      fontSize: "1.0rem",
    },
  },
}));

const FREQUENCIES = {
  NONE: {
    label: "None",
    value: "NONE",
  },
  DAILY: {
    label: "Daily",
    value: "DAILY",
  },
  DAILY_2X: {
    label: "2x / Day",
    value: "DAILY_2X",
  },
  WEEKLY: {
    label: "Weekly",
    value: "WEEKLY",
  },
  WEEKLY_2X: {
    label: "2x / Week",
    value: "WEEKLY_2X",
  },
};

const EmailFrequency = ({ style = {}, prefKey }) => {
  const classes = useStyles();

  const [userprefs = {}] = useGlobal("userprefs");
  const prefValue = get(userprefs, ["emailFrequency", prefKey]);
  const isButtonOffState = prefValue === FREQUENCIES.NONE.value;

  const iconButtonRef = React.useRef();
  const [open, setOpen] = React.useState(false);

  return (
    <>
      <StyledTooltip title={Messages.LABEL.EMAIL_FREQUENCY}>
        <IconButton ref={iconButtonRef} style={style} size="small" onClick={(e) => setOpen((o) => !o)}>
          <EmailIcon color={isButtonOffState ? "disabled" : "primary"} />
        </IconButton>
      </StyledTooltip>
      <Popper
        open={open}
        placement="bottom"
        anchorEl={iconButtonRef.current}
        className={classes.transitionPopper}
        transition
      >
        {({ TransitionProps }) => (
          <Fade {...TransitionProps}>
            <div>
              <ClickAwayListener onClickAway={(e) => setOpen(false)}>
                <PopperPaper onClick={(e) => e.stopPropagation()}>
                  <EmailFrequencyControl prefKey={prefKey} />
                </PopperPaper>
              </ClickAwayListener>
            </div>
          </Fade>
        )}
      </Popper>
    </>
  );
};

EmailFrequency.propTypes = {
  prefKey: PropTypes.string.isRequired,
  style: PropTypes.object,
};

export default EmailFrequency;

const EmailFrequencyControl = ({ prefKey }) => {
  const classes = useStyles();

  prefKey = `emailFrequency.${prefKey}`;
  const [userprefs = {}] = useGlobal("userprefs");
  const prefValue = get(userprefs, prefKey, FREQUENCIES.DAILY.value);

  const handleFrequencyChange = (e) => {
    setPreference(prefKey, e.currentTarget.value);
  };

  const StyledRadio = <Radio size="small" classes={{ root: classes.radio }} />;

  return (
    <FormControl>
      <PopperHeader>{Messages.LABEL.EMAIL_FREQUENCY}</PopperHeader>
      <RadioGroup name="emailfrequency" value={prefValue} onChange={handleFrequencyChange}>
        {Object.values(FREQUENCIES).map((f) => {
          return (
            <FormControlLabel
              classes={{ root: classes.labelRoot, label: classes.label }}
              value={f.value}
              control={StyledRadio}
              label={f.label}
              key={f.value}
            />
          );
        })}
      </RadioGroup>
    </FormControl>
  );
};
