// This must be the first line; IE polyfill
import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";

import React from "react";
import ReactDOM from "react-dom";
import * as Sentry from "@sentry/react";
import { set } from "lodash";
import Highcharts from "highcharts";

import App from "./App";
import * as Constants from "commons/constants";
import * as serviceWorker from "./serviceWorker";

if (process.env.REACT_APP_ENABLE_SENTRY === "true") {
  Sentry.init({
    dsn: "https://ba1d1f2707904ed8a42173e9bc9a2264@o944190.ingest.sentry.io/6079938",
    environment: process.env.REACT_APP_STAGE,
    autoSessionTracking: true,
    beforeSend: (event) => {
      set(
        event,
        "extra.viewState",
        JSON.parse(window.sessionStorage.getItem(Constants.STORAGE_SESSION_STATE)),
      );
      return event;
    },
  });
}

Highcharts.setOptions({
  lang: {
    thousandsSep: ",",
  },
});

ReactDOM.render(<App />, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
