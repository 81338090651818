const sidebarStyle = (theme) => ({
  background: {
    position: "absolute",
    zIndex: 1,
    display: "block",
    top: 0,
    left: 0,
    backgroundSize: "cover",
    backgroundPosition: "-100px 0",
    backgroundImage: "url(" + theme.sidebar.backgroundImage + ")",
    "&:after": {
      position: "absolute",
      zIndex: 3,
      width: "100%",
      height: "100%",
      content: '""',
      display: "block",
      background: theme.palette.background.drawer,
    },
  },
  list: {
    marginTop: 40,
    paddingLeft: 0,
    paddingTop: 0,
    paddingBottom: 0,
    marginBottom: 0,
    listStyle: "none",
    position: "relative",
  },
  item: {
    position: "relative",
    display: "block",
    textDecoration: "none",
    "&:hover,&:focus,&:visited,&": {
      color: theme.palette.common.white,
      outline: "none !important",
    },
  },
  itemIcon: {
    width: "24px",
    height: "30px",
    fontSize: "24px",
    lineHeight: "30px",
    float: "left",
    textAlign: "center",
    verticalAlign: "middle",
    color: theme.palette.common.white,
  },
  itemLink: {
    "&:hover": {
      backgroundColor: theme.palette.background.menuhover,
    },
  },
  itemText: {
    margin: 0,
    lineHeight: "30px",
    marginLeft: "15px",
    fontSize: 14,
    fontWeight: 300,
    color: theme.palette.common.white,
  },
  badge: {
    top: 4,
    fontSize: "0.65rem",
    padding: 0,
    backgroundColor: theme.palette.background.badge,
  },
  cartbadge: {
    color: theme.palette.common.white,
    backgroundColor: theme.palette.background.cartbadge,
  },
  sidebarWrapper: {
    position: "relative",
    overflow: "auto",
    marginTop: 10,
    width: theme.drawerWidth,
    zIndex: 4,
  },
  drawer: {
    flexShrink: 0,
    whiteSpace: "nowrap",
  },
  drawerOpen: {
    width: theme.drawerWidth,
    borderRightColor: theme.palette.border.drawer,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.easeIn,
      duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: "hidden",
  },
  drawerClose: {
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.easeIn,
      duration: theme.transitions.duration.leavingScreen,
    }),
    borderRightColor: theme.palette.border.drawer,
    overflowX: "hidden",
    width: theme.spacing(7),
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: theme.spacing(0, 1),
    backgroundColor: "white",
    zIndex: theme.zIndex.drawer + 1,
    ...theme.mixins.toolbar,
  },
  menuButton: {
    color: theme.palette.common.white,
    padding: 5,
    margin: "0 11px",
  },
});

export default sidebarStyle;
