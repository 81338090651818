import React from "react";
import PropTypes from "prop-types";
import { useGlobal } from "reactn";
import clsx from "clsx";

import QBTypography from "components/QBTypography";
import { Div } from "components/containers/Containers";

import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/CancelPresentation";
import Paper from "@material-ui/core/Paper";
import Modal from "@material-ui/core/Modal";
import ListSubheader from "@material-ui/core/ListSubheader";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import { withStyles, makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  obfuscatedBackdrop: {
    backgroundColor: theme.palette.background.obfuscatedBackdrop,
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  modalpaper: {
    padding: 0,
    outline: 0,
    backgroundColor: theme.palette.background.paper,
    maxWidth: "97vw",
    maxHeight: "97vh",
    overflow: "hidden",
  },
  toolbar: {
    display: "flex",
    height: 28,
  },
  toolbarSection: {
    textAlign: "center",
    paddingTop: 10,
    flexGrow: 0,
    minWidth: 0,
    "&:first-child": {
      textAlign: "left",
      flexGrow: 1,
      paddingLeft: 10,
    },
    "&:last-child": {
      textAlign: "right",
      flexGrow: 1,
      padding: 3,
    },
  },
  icon: {
    padding: 0,
    fontSize: "0.9rem",
  },
  contentWrapper: {
    padding: theme.spacing(2),
    paddingTop: 8,
    maxHeight: "calc(99vh - 28px)",
    overflowY: "auto",
  },
  unclosableContentWrapper: {
    padding: theme.spacing(2),
    maxHeight: "calc(99vh - 28px)",
    overflowY: "auto",
  },
  noscroll: {
    overflowY: "hidden",
  },
  header: {
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  },
}));

export const StyledPopperPaper = withStyles((theme) => ({
  root: {
    fontSize: ".75rem",
    backgroundColor: theme.palette.background.popperPaper,
    padding: theme.spacing(1),
    color: theme.palette.text.popper.text,
    maxHeight: "99vh",
    display: "flex",
    flexDirection: "column",
    overflowY: "auto",
    overflowX: "hidden",
  },
}))(Paper);

export const PopperHeader = withStyles((theme) => ({
  root: {
    fontSize: "0.65rem",
    color: theme.palette.text.popper.header,
    padding: "4px 0",
    lineHeight: 1,
    margin: "4px",
    marginTop: 8,
    textTransform: "uppercase",
    letterSpacing: "0.07em",
    fontWeight: 800,
    borderBottom: "1px solid",
    borderBottomColor: theme.palette.border.main,
  },
}))(ListSubheader);

export const PopperPaper = React.forwardRef((props, ref) => {
  const { children, ...otherProps } = props;
  const [userprefs = {}] = useGlobal("userprefs");

  return (
    <StyledPopperPaper
      className={userprefs.theme === "dark" ? "darkMode" : "lightMode"}
      elevation={8}
      ref={ref}
      {...otherProps}
    >
      {children}
    </StyledPopperPaper>
  );
});

export const ClosableModal = (props) => {
  const {
    children,
    onClose,
    open,
    header,
    scrollable = true,
    obfuscatedBackdrop,
    width = "100%",
    HeaderProps,
    ...otherProps
  } = props;
  const classes = useStyles();
  const [userprefs = {}] = useGlobal("userprefs");
  const className = userprefs.theme === "dark" ? "darkMode" : "lightMode";

  return (
    <Modal
      className={clsx(classes.modal, className)}
      open={open}
      onClose={onClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 200,
        classes: {
          root: clsx({ [classes.obfuscatedBackdrop]: obfuscatedBackdrop }),
        },
      }}
      {...otherProps}
    >
      <Fade in={open}>
        <Paper elevation={8} className={classes.modalpaper}>
          <Div style={{ width: width }}>
            <div className={classes.toolbar}>
              <div className={classes.toolbarSection}></div>
              <div className={classes.toolbarSection}>
                <QBTypography className={classes.header} variant="h3" {...HeaderProps}>
                  {header}
                </QBTypography>
              </div>
              <div className={classes.toolbarSection}>
                <IconButton color="primary" size="small" onClick={onClose}>
                  <CloseIcon className={classes.icon} />
                </IconButton>
              </div>
            </div>
            <div className={clsx(classes.contentWrapper, { [classes.noscroll]: !scrollable })}>
              {children}
            </div>
          </Div>
        </Paper>
      </Fade>
    </Modal>
  );
};

ClosableModal.propTypes = {
  children: PropTypes.node.isRequired,
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  header: PropTypes.node,
  scrollable: PropTypes.bool,
  HeaderProps: PropTypes.object,
  PaperProps: PropTypes.object,
};

export const UnclosableModal = (props) => {
  const { children, open, obfuscatedBackdrop, onClose, ...otherProps } = props;
  const classes = useStyles();
  const [userprefs = {}] = useGlobal("userprefs");
  const className = userprefs.theme === "dark" ? "darkMode" : "lightMode";

  const handleOnClose = (event, reason) => {
    if (reason === "backdropClick") {
      return false;
    }

    if (onClose) {
      onClose();
    }
  };

  return (
    <Modal
      onClose={handleOnClose}
      className={clsx(classes.modal, className)}
      open={open}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 200,
        classes: {
          root: clsx({ [classes.obfuscatedBackdrop]: obfuscatedBackdrop }),
        },
      }}
      {...otherProps}
    >
      <Fade in={open}>
        <Paper elevation={8} className={classes.modalpaper}>
          <div className={classes.unclosableContentWrapper}>{children}</div>
        </Paper>
      </Fade>
    </Modal>
  );
};

UnclosableModal.propTypes = {
  children: PropTypes.node.isRequired,
  open: PropTypes.bool.isRequired,
};
