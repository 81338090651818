import React from "react";
import * as Sentry from "@sentry/react";

import * as Constants from "commons/constants";
import * as queryUtils from "commons/queryUtils";
import * as utils from "commons/utils";
import HttpService from "services/commons/HttpService";
import { getUser } from "../commons/helpers/userStorage";
import { userHasAnyPermission } from "commons/utils";

const dataFetchReducer = (state, action) => {
  switch (action.type) {
    case Constants.QUERY_STATUS_TYPE.CLEAR_STATE:
      return {
        isLoading: false,
        isError: false,
        isUnauthorized: false,
        isInit: false,
        data: [],
        errors: [],
        query: {},
      };
    case Constants.QUERY_STATUS_TYPE.FETCH_INIT:
      return {
        ...state,
        isLoading: true,
        isError: false,
        isInit: false,
        data: action.clearData ? [] : state.data,
        errors: [],
        query: action.query,
        options: action.options,
      };
    case Constants.QUERY_STATUS_TYPE.FETCH_SUCCESS:
      queryUtils.processApiData(action);

      const data = action.payload.content || action.payload;
      const page = action.payload.page || {
        number: 0,
        size: action.payload.length,
        numRecs: action.payload.length,
        totalElements: action.payload.length,
        totalPages: 1,
      };

      page.numRecs = page.numRecs || data.length;
      return {
        ...state,
        isLoading: false,
        isError: false,
        isUnauthorized: false,
        isInit: false,
        data: data,
        errors: action.payload.errors,
        page: page,
        refreshedTime: action.payload.refreshedTime,
        links: action.payload.links || [],
        query: action.query,
        options: action.options,
      };
    case Constants.QUERY_STATUS_TYPE.FETCH_UNAUTHORIZED:
      return {
        ...state,
        isLoading: false,
        isError: false,
        isUnauthorized: true,
        isInit: false,
        data: [],
        errors: [],
        page: {
          number: 0,
          size: 0,
          numRecs: 0,
          totalElements: 0,
          totalPages: 0,
        },
      };

    case Constants.QUERY_STATUS_TYPE.FETCH_FAILURE:
      return {
        ...state,
        isLoading: false,
        isError: true,
        isInit: false,
        data: [],
        query: action.query,
        options: action.options,
        errors: [action.error],
        page: {
          number: 0,
          size: 0,
          numRecs: 0,
          totalElements: 0,
          totalPages: 0,
        },
      };
    default:
      throw new Error();
  }
};

export const useDataService = (providedData, totalNumberRows) => {
  const [query, setQuery] = React.useState({});
  let options = React.useRef({});

  const executeQuery = (query, opts) => {
    options.current = opts;
    setQuery(query);
  };

  const user = getUser();

  const [state, dispatch] = React.useReducer(dataFetchReducer, {
    isLoading: false,
    isError: false,
    isInit: true,
    data: [],
    errors: [],
    page: {},
    links: [],
    query: {},
  });

  const dispatchData = (state) => {
    dispatch({
      type: Constants.QUERY_STATUS_TYPE.FETCH_SUCCESS,
      payload: { content: state.data, page: state.page, refreshedTime: state.refreshedTime },
      query: state.query,
      options: options.current,
    });
  };

  React.useEffect(() => {
    let didCancel = false;
    const fetchData = async () => {
      if (utils.hasNonEmptyValue(query)) {
        dispatch({
          type: Constants.QUERY_STATUS_TYPE.FETCH_INIT,
          clearData: query.clearDataOnFetch,
          query,
          options: options.current,
        });
        try {
          const permsRequired = Constants.QUERY_TYPE_PERMISSIONS[query.type] || null;
          // We should always have this, unless we have bugs. If this is cleared
          // its likely a event handler reset it carelessly and now our permission check
          // is broken. Luckily this is advisory for UX and permissions are still enforced
          // by the backend.
          if (!query.type) {
            console.error("Lost query type along the way in dataService. This is a bug.");
          }
          // If we require a specific role for this query, check that the user has it.
          // if they have it or we dont actually have any permissions defined, execute the query else
          // return no data and set state.isUnauthorized
          if (!userHasAnyPermission(user, permsRequired)) {
            dispatch({
              type: Constants.QUERY_STATUS_TYPE.FETCH_UNAUTHORIZED,
            });
          } else if (providedData) {
            const totalElements = totalNumberRows || providedData.length;

            const totalPages = Math.ceil(totalElements / query.size);
            let numRecs = 0;

            if (query.page === totalPages - 1) {
              numRecs = Math.min(totalElements - query.size * query.page, totalElements);
            } else {
              numRecs = Math.min(query.size, totalElements);
            }

            const data = {
              content: providedData,
              page: {
                number: query.page,
                size: query.size,
                numRecs: numRecs,
                totalPages: totalPages,
                totalElements: totalElements,
              },
            };
            dispatch({
              type: Constants.QUERY_STATUS_TYPE.FETCH_SUCCESS,
              payload: data,
              query,
              options: options.current,
            });
          } else {
            let queryParams = queryUtils.getQueryConfig(query);

            if (utils.hasNonEmptyValue(queryParams)) {
              if (options.current?.autorefresh) {
                queryParams.url = `${queryParams.url}&metadata=autoupdate`;
              }

              HttpService(queryParams)
                .then((response) => {
                  if (!didCancel) {
                    if (response.status >= 200 && response.status < 300) {
                      dispatch({
                        type: Constants.QUERY_STATUS_TYPE.FETCH_SUCCESS,
                        payload: response.data,
                        query,
                        options: options.current,
                      });
                    } else {
                      Sentry.captureException(
                        new Error(`Data query response with status ${response.status}`),
                        (scope) => {
                          scope.setContext("response", response);
                        },
                      );
                      dispatch({ type: Constants.QUERY_STATUS_TYPE.FETCH_FAILURE, query, options });
                    }
                  }
                })
                .catch((error) => {
                  if (!didCancel) {
                    // delete sensitive data which would trigger Sentry filters to not report data
                    delete error?.config?.headers?.Authorization;
                    delete error?.config?.withCredentials;
                    const msg = error?.message ? error.message : "Data service error";
                    Sentry.captureException(new Error(msg), (scope) => {
                      scope.setExtras({ query: JSON.stringify(query), error: JSON.stringify(error) });
                    });
                    dispatch({
                      type: Constants.QUERY_STATUS_TYPE.FETCH_FAILURE,
                      error,
                      query,
                      options: options.current,
                    });
                  }
                });
            } else {
              dispatch({
                type: Constants.QUERY_STATUS_TYPE.FETCH_SUCCESS,
                payload: [],
                query,
                options: options.current,
              });
            }
          }
        } catch (error) {
          if (!didCancel) {
            delete error?.config?.headers?.Authorization;
            delete error?.config?.withCredentials;
            const msg = error?.message ? error.message : "Data service error";
            Sentry.captureException(new Error(msg), (scope) => {
              scope.setExtras({ query: JSON.stringify(query), error: JSON.stringify(error) });
            });
            dispatch({
              type: Constants.QUERY_STATUS_TYPE.FETCH_FAILURE,
              error,
              query,
              options: options.current,
            });
          }
        }
      } else if (query === null) {
        dispatch({ type: Constants.QUERY_STATUS_TYPE.CLEAR_STATE });
      }
    };
    fetchData();
    return () => {
      didCancel = true;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query, providedData]);

  return [state, executeQuery, dispatchData];
};
