import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { alpha } from "@material-ui/core/styles/colorManipulator";

import * as utils from "commons/utils";
import { useMessages } from "providers/BrandingProvider";
import * as DataConfig from "config/dataconfig";
import { HTTP_CODES } from "commons/constants";
import HttpService from "services/commons/HttpService";
import Button from "@material-ui/core/Button";
import { StyledTooltip, StyledIconButton } from "components/Controls";

import CircularProgress from "@material-ui/core/CircularProgress";

const SellSideSimpleActionButtonBase = withStyles((theme) => ({
  root: {
    minWidth: 40,
    padding: 4,
    fontSize: "0.6rem",
    lineHeight: 1,
    "&.MuiButton-outlined.Mui-disabled": {
      border: "1px solid",
      borderColor: theme.palette.border.disabled,
      color: theme.palette.text.unselected.disabled,
      backgroundColor: alpha(theme.palette.background.button.primary, 0.3),
    },
  },
}))(Button);

const SellSideSimpleActionButtonFilled = withStyles((theme) => ({
  root: {
    color: theme.palette.text.selected.primary,
    backgroundColor: theme.palette.background.button.buy,
    "&:hover": {
      backgroundColor: alpha(theme.palette.background.button.buy, 0.5),
      color: theme.palette.text.selected.primary,
    },
  },
}))(SellSideSimpleActionButtonBase);

const SellSideSimpleActionButtonOutlined = withStyles((theme) => ({
  root: {
    color: theme.palette.text.unselected.primary,
    backgroundColor: theme.palette.background.paperButton,
    "&:hover": {
      color: theme.palette.text.unselected.primary,
    },
    "&.MuiButton-outlined.Mui-disabled": {
      backgroundColor: "transparent",
      borderColor: theme.palette.border.disabled,
      color: theme.palette.text.disabled,
    },
  },
}))(SellSideSimpleActionButtonBase);

const SellSideSimpleActionButton = ({
  url,
  tooltip,
  action,
  label,
  orders,
  variant = "filled",
  onActionStatusChange,
  Icon,
  confirmationModal,
  disabled = false,
}) => {
  const Messages = useMessages();
  const [openModal, setOpenModal] = React.useState(false);
  const [processing, setProcessing] = React.useState(false);

  const executeAction = async () => {
    //onActionStatusChange("initiated", action, order);
    setProcessing(true);

    if (utils.hasNonEmptyValue(url)) {
      HttpService.post(url)
        .then((response) => {
          if (response.status === HTTP_CODES.SUCCESS) {
            onActionStatusChange("success", action, orders);
          }
          setProcessing(false);
        })
        .catch((e) => {
          setProcessing(false);
          utils.issueErrorAlert(Messages.MESSAGE.REQUEST_FAIL);
          onActionStatusChange("failed", action, orders);
        });
    } else if (action === "cancel") {
      var data = [];
      orders.forEach((o) => {
        data.push({
          id: o.id,
          "@class": "com.qwickrate.qwickbondsapi.sell.forms.GenericNullForm",
        });
      });

      if (utils.hasNonEmptyValue(data)) {
        const params = {
          method: "post",
          url: `${DataConfig.TRADE_ENDPOINT.BONDS_FOR_SALE}/doUserCancelOffer`,
          data: data,
        };

        HttpService(params)
          .then((response) => {
            if (response.status === HTTP_CODES.SUCCESS) {
              onActionStatusChange("success", action, orders);
            }
            setProcessing(false);
          })
          .catch((e) => {
            setProcessing(false);
            utils.issueErrorAlert(Messages.MESSAGE.REQUEST_FAIL);
            onActionStatusChange("failed", action, orders);
          });
      }
    }
  };

  const performAction = () => {
    if (confirmationModal?.Component) {
      setOpenModal(true);
    } else {
      executeAction();
    }
  };

  const SellSideSimpleActionButtonStyle =
    variant === "filled" ? SellSideSimpleActionButtonFilled : SellSideSimpleActionButtonOutlined;

  let ButtonComp = () => (
    <SellSideSimpleActionButtonStyle
      onClick={performAction}
      variant="outlined"
      color="primary"
      size="small"
      disabled={disabled}
    >
      {label}
    </SellSideSimpleActionButtonStyle>
  );

  return (
    <>
      {processing && (
        <div style={{ marginTop: 4 }}>
          <CircularProgress size={16} color="primary" />
        </div>
      )}
      {!processing && (
        <>
          {Icon && (
            <StyledTooltip title={label}>
              <span>
                <StyledIconButton disabled={disabled} onClick={performAction}>
                  <Icon />
                </StyledIconButton>
              </span>
            </StyledTooltip>
          )}
          {!Icon && utils.hasNonEmptyValue(tooltip) && (
            <StyledTooltip title={tooltip}>
              <span>
                <ButtonComp />
              </span>
            </StyledTooltip>
          )}
          {!Icon && !utils.hasNonEmptyValue(tooltip) && <ButtonComp />}
        </>
      )}
      {confirmationModal?.Component && (
        <confirmationModal.Component
          message={confirmationModal.message}
          isOpen={openModal}
          setIsOpen={setOpenModal}
          orders={orders}
          onCloseModal={executeAction}
        />
      )}
    </>
  );
};

SellSideSimpleActionButton.propTypes = {
  label: PropTypes.string.isRequired,
  action: PropTypes.string.isRequired,
  tooltip: PropTypes.string,
  url: PropTypes.string,
  orders: PropTypes.arrayOf(PropTypes.object).isRequired,
  variant: PropTypes.oneOf(["filled", "outlined"]),
  onActionStatusChange: PropTypes.func.isRequired,
  confirmationModal: PropTypes.shape({
    Component: PropTypes.func,
    message: PropTypes.string,
  }),
  Icon: PropTypes.object,
  disabled: PropTypes.bool,
};

export default SellSideSimpleActionButton;
