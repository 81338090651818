import { get } from "lodash";
import * as Constants from "commons/constants";
import { LABEL } from "commons/messages";

const AUTHSERVER_URL = process.env.REACT_APP_AUTHSERVER_URL;

export const DEFAULT_PAGE_SIZE = 20;
export const DEFAULT_POLLING_INTERVAL = 15000;

export const DATE_FORMAT = {
  API: "YYYY-MM-DD",
  API_TIMESTAMP: "YYYY-MM-DD[T]HH:mm:ss.SSS",
  DISPLAY: "M/D/YYYY",
  FILTER_DISPLAY: "M/D/YY",
  FILTER: "M-D-YYYY",
  MONTH_DAY: "M/YY",
  DATETIME: "M/D/YYYY h:mm A z",
};

export const INVENTORY_ENDPOINT = "/bonds";
export const ORDERS_ENDPOINT = "/orders";

const _SELL_ENDPOINT = "/sell";

export const TRADE_ENDPOINT = {
  BUY: `${ORDERS_ENDPOINT}/buy`,
  PRICE_QUOTES: `${_SELL_ENDPOINT}/quotes`,
  BIDS_RFQ: `${_SELL_ENDPOINT}/bids`,
  BONDS_FOR_SALE: `${_SELL_ENDPOINT}/offers`,
};

export const SELL_ENDPOINT = {
  PRICE_QUOTES: `${_SELL_ENDPOINT}/quotes`,
  BIDS_RFQ: `${_SELL_ENDPOINT}/bids`,
  BONDS_FOR_SALE: `${_SELL_ENDPOINT}/offer`,
};

export const RATES_ENDPOINT = "/public/rateboard";
export const CALCULATOR_ENDPOINT = "/calculator";
export const PREFERENCES_ENDPOINT = "/user/preferences/";
export const FAVORITES_ENDPOINT = "/savedfavorite";
export const SAVEDSEARCH_ENDPOINT = "/savedsearches";
export const SAVEDSEARCH_ENDPOINT_ADMIN = `${SAVEDSEARCH_ENDPOINT}/admin`;
export const LOOKAHEAD_ENDPOINT = "/lookahead";
export const MESSAGE_DESK_ENDPOINT = `${INVENTORY_ENDPOINT}/tradeDeskHelpRequest`;
export const ISSUER_LIST = `${INVENTORY_ENDPOINT}/issuerList/`;
export const USER_INFO_ENDPOINT = "/user/me";
export const USER_LOGGED_IN_CHECK_ENDPOINT = "/user/loggedIn";
export const TRACKING_ENDPOINT = "/tracking";

export const AFFILIATES_ENDPOINT = "/company/affiliates";
export const AFFILIATES_TRADERS_ENDPOINT = `${AFFILIATES_ENDPOINT}/traders`;

export const NEWS_ENDPOINT = "/news";
export const RESOURCE_DOCUMENTS_ENDPOINT = "/resourceDocuments/";
export const TRADE_DOCUMENTS_ENDPOINT = "/tradedocument/";

export const USER_PORTFOLIO_ENDPOINT =
  "/portfolios?category=101&insuranceType=101&insuranceType=102&matured=false";
export const ACTIVE_ANNOUNCEMENTS_ENDPOINT = "/announcements/active";
export const AUTH_LOGIN_ENDPOINT = `${AUTHSERVER_URL}/oauth/token`;
export const AUTH_RESET_PASSWORD_ENDPOINT = `${AUTHSERVER_URL}/user/resetPassword`;

export const _COMPARISON_ENDPOINT = "/bondcomparison";
export const COMPARISON_ENDPOINT = {
  COMPARE: `${_COMPARISON_ENDPOINT}/comparison`,
  SAVED: `${_COMPARISON_ENDPOINT}/saved`,
};

export const REFERENCE_ENDPOINT = {
  interestfrequencies: {
    sortKey: "description",
    desc: false,
  },
  instrumenttypes: {
    sortKey: "description",
    desc: false,
  },
  issuers: {
    sortKey: "description",
    desc: false,
  },
  municipalsecuritytypes: {
    url: "municipalsecuritytypes/grouped",
  },
  newusersignupinvestortypes: {
    sortKey: "rank",
  },
  finalorderstatus: {
    sortKey: "rank",
  },
  ordertypes: {
    sortKey: "description",
  },
  ordertypesbyaction: {
    sortKey: "description",
  },
  rateterms: {
    sortKey: "id",
    desc: false,
    applyIntrumentCategoryFilter: true,
  },
  ratingsorder: {
    url: "ratingsorder/grouped",
    sortKey: "rank",
    desc: true,
  },
  seasonings: {
    sortKey: "description",
    desc: false,
  },
  termyears: {
    sortKey: "description",
    desc: false,
  },
  treasurytypes: {},
  usesofproceeds: {
    url: "usesofproceeds/grouped",
    sortKey: "description",
    desc: false,
  },
  markethours: {
    url: "markethours",
  },
};

Object.keys(REFERENCE_ENDPOINT).forEach((key) => {
  REFERENCE_ENDPOINT[key].key = key;
  if (!REFERENCE_ENDPOINT[key].hasOwnProperty("url")) {
    REFERENCE_ENDPOINT[key].url = key;
  }
});

const DATE_RANGE_INCREMENT_MARKS = [
  { value: 0 },
  { value: 12, label: "1y" },
  { value: 24, label: "2y" },
  { value: 36, label: "3y" },
  { value: 44, label: "5y" },
  { value: 55, label: "7.5y" },
  { value: 65, label: "10y" },
  { value: 75, label: "20y" },
  { value: 85, label: "30y" },
];

const DATE_RANGE_INCREMENT_SCALE = [
  { value: 0, scaledValue: 0 },
  { value: 1, scaledValue: 1 },
  { value: 2, scaledValue: 2 },
  { value: 3, scaledValue: 3 },
  { value: 4, scaledValue: 4 },
  { value: 5, scaledValue: 5 },
  { value: 6, scaledValue: 6 },
  { value: 7, scaledValue: 7 },
  { value: 8, scaledValue: 8 },
  { value: 9, scaledValue: 9 },
  { value: 10, scaledValue: 10 },
  { value: 11, scaledValue: 11 },
  { value: 12, scaledValue: 12 },
  { value: 13, scaledValue: 13 },
  { value: 14, scaledValue: 14 },
  { value: 15, scaledValue: 15 },
  { value: 16, scaledValue: 16 },
  { value: 17, scaledValue: 17 },
  { value: 18, scaledValue: 18 },
  { value: 19, scaledValue: 19 },
  { value: 20, scaledValue: 20 },
  { value: 21, scaledValue: 21 },
  { value: 22, scaledValue: 22 },
  { value: 23, scaledValue: 23 },
  { value: 24, scaledValue: 24 },
  { value: 25, scaledValue: 25 },
  { value: 26, scaledValue: 26 },
  { value: 27, scaledValue: 27 },
  { value: 28, scaledValue: 28 },
  { value: 29, scaledValue: 29 },
  { value: 30, scaledValue: 30 },
  { value: 31, scaledValue: 31 },
  { value: 32, scaledValue: 32 },
  { value: 33, scaledValue: 33 },
  { value: 34, scaledValue: 34 },
  { value: 35, scaledValue: 35 },
  { value: 36, scaledValue: 36 },
  { value: 37, scaledValue: 39 },
  { value: 38, scaledValue: 42 },
  { value: 39, scaledValue: 45 },
  { value: 40, scaledValue: 48 },
  { value: 41, scaledValue: 51 },
  { value: 42, scaledValue: 54 },
  { value: 43, scaledValue: 57 },
  { value: 44, scaledValue: 60 },
  { value: 45, scaledValue: 63 },
  { value: 46, scaledValue: 66 },
  { value: 47, scaledValue: 69 },
  { value: 48, scaledValue: 72 },
  { value: 49, scaledValue: 75 },
  { value: 50, scaledValue: 78 },
  { value: 51, scaledValue: 81 },
  { value: 52, scaledValue: 84 },
  { value: 53, scaledValue: 84 },
  { value: 54, scaledValue: 87 },
  { value: 55, scaledValue: 90 },
  { value: 56, scaledValue: 93 },
  { value: 57, scaledValue: 96 },
  { value: 58, scaledValue: 99 },
  { value: 59, scaledValue: 102 },
  { value: 60, scaledValue: 105 },
  { value: 61, scaledValue: 108 },
  { value: 62, scaledValue: 111 },
  { value: 63, scaledValue: 114 },
  { value: 64, scaledValue: 117 },
  { value: 65, scaledValue: 120 },
  { value: 66, scaledValue: 132 },
  { value: 67, scaledValue: 144 },
  { value: 68, scaledValue: 156 },
  { value: 69, scaledValue: 168 },
  { value: 70, scaledValue: 180 },
  { value: 71, scaledValue: 192 },
  { value: 72, scaledValue: 204 },
  { value: 73, scaledValue: 216 },
  { value: 74, scaledValue: 228 },
  { value: 75, scaledValue: 240 },
  { value: 76, scaledValue: 252 },
  { value: 77, scaledValue: 264 },
  { value: 78, scaledValue: 276 },
  { value: 79, scaledValue: 288 },
  { value: 80, scaledValue: 300 },
  { value: 81, scaledValue: 312 },
  { value: 82, scaledValue: 324 },
  { value: 83, scaledValue: 336 },
  { value: 84, scaledValue: 348 },
  { value: 85, scaledValue: 360 },
];

const DATE_RANGE_INCREMENT_FILTER = {
  type: Constants.FILTER_TYPE.DATE_RANGE_INCREMENT,
  min: 0,
  max: 85,
  step: 1,
  marks: DATE_RANGE_INCREMENT_MARKS,
  scale: DATE_RANGE_INCREMENT_SCALE,
};

export const PROPERTY = {
  "3m": {
    value: {
      label: "3m",
      format: Constants.FORMAT_TYPE.NUMBER,
      xvalue: 3,
    },
  },
  "6m": {
    value: {
      label: "6m",
      format: Constants.FORMAT_TYPE.NUMBER,
      xvalue: 8,
    },
  },
  "9m": {
    value: {
      label: "9m",
      format: Constants.FORMAT_TYPE.NUMBER,
      xvalue: 13,
    },
  },
  "1y": {
    value: {
      label: "1y",
      format: Constants.FORMAT_TYPE.NUMBER,
      xvalue: 18,
    },
  },
  "2y": {
    value: {
      label: "2y",
      format: Constants.FORMAT_TYPE.NUMBER,
      xvalue: 30,
    },
  },
  "3y": {
    value: {
      label: "3y",
      format: Constants.FORMAT_TYPE.NUMBER,
      xvalue: 42,
    },
  },
  "4y": {
    value: {
      label: "4y",
      format: Constants.FORMAT_TYPE.NUMBER,
      xvalue: 54,
    },
  },
  "5y": {
    value: {
      label: "5y",
      format: Constants.FORMAT_TYPE.NUMBER,
      xvalue: 66,
    },
  },
  "7y": {
    value: {
      label: "7y",
      format: Constants.FORMAT_TYPE.NUMBER,
      xvalue: 90,
    },
  },
  "10y": {
    value: {
      label: "10y",
      format: Constants.FORMAT_TYPE.NUMBER,
      xvalue: 126,
    },
  },
  "20y": {
    value: {
      label: "20y",
      format: Constants.FORMAT_TYPE.NUMBER,
      xvalue: 243,
    },
  },
  "30y": {
    value: {
      label: "30y",
      format: Constants.FORMAT_TYPE.NUMBER,
      xvalue: 360,
    },
  },
  account: {
    label: "Account ID",
    filter: {
      type: Constants.FILTER_TYPE.CHECKBOX,
    },
  },
  accruedInterest: {
    label: "Accrued Interest",
    format: Constants.FORMAT_TYPE.CURRENCY,
    minDecimal: 2,
  },
  action: {
    label: "Action",
    filter: {
      type: Constants.FILTER_TYPE.CHECKBOX,
      optionsKey: "ordertypes",
      width: 200,
    },
  },
  bankQualified: {
    label: "Bank Qualified",
    format: Constants.FORMAT_TYPE.BOOLEAN,
    filter: {
      type: Constants.FILTER_TYPE.TOGGLE,
      optionsKey: "ALL_ONLY_OMIT",
    },
  },
  brokeredClearingAccount: {
    account: {
      label: "Account ID",
      filter: {
        type: Constants.FILTER_TYPE.CHECKBOX,
      },
    },
  },
  brokeredsecurityid: {
    label: "Brokered Security ID",
    filter: {
      type: Constants.FILTER_TYPE.ADVANCED,
    },
  },
  callSchedule: {
    label: "Call Schedule",
    type: Constants.DETAIL_TYPE.POPPER,
    condition: (bond) => {
      var show = false;
      if (get(bond, "callable")) {
        if (get(bond, "instrumentCategory") === Constants.INVENTORY_TYPE.AGENCY) {
          if (get(bond, "continuouslyCallable")) {
            show = false;
          } else {
            show = true;
          }
        } else {
          show = true;
        }
      }
      return show;
    },
  },
  otherEnhancementTypeDescription: {
    label: "State Enhancement Program",
  },
  insuredBy: {
    label: "Insurer",
  },
  callable: {
    label: "Callable",
    format: Constants.FORMAT_TYPE.BOOLEAN,
    filter: {
      type: Constants.FILTER_TYPE.NAV,
    },
  },
  callPrice: {
    label: "Call Price",
    format: Constants.FORMAT_TYPE.NUMBER,
    formatAccessor: "callPriceScale",
    condition: (bond) => {
      return get(bond, "callable");
    },
  },
  callDate: {
    label: "Call Date",
    format: Constants.FORMAT_TYPE.DATE,
    filter: {
      type: Constants.FILTER_TYPE.DATERANGE,
      disablePast: true,
    },
    incrementfilter: DATE_RANGE_INCREMENT_FILTER,
    criteriumfilter: {
      type: Constants.FILTER_TYPE.DATE_RANGE_INCREMENT,
      min: 0,
      max: 36,
      marks: [
        { value: 0, label: "0m" },
        { value: 3 },
        { value: 6 },
        { value: 9 },
        { value: 12, label: "+/- 1yr" },
        { value: 18 },
        { value: 24, label: "+/- 2yr" },
        { value: 30 },
        { value: 36, label: "+/- 3yr" },
      ],
    },
  },
  carryToNextDay: {
    label: "Carry",
    tooltip: "Carry to next trading day",
    format: Constants.FORMAT_TYPE.BOOLEAN,
  },
  cdDetailSheetLink: {
    label: "Issuer financial details",
    type: Constants.DETAIL_TYPE.LINK_DOWNLOAD,
    filenamePrefix: "Details_",
  },
  combinedRatings: {
    combinedRating: {
      header: "Ratings",
      label: "Minimum Rating",
    },
    ratingSortRank: {
      header: "Ratings",
      label: "Minimum Rating",
      filter: {
        type: Constants.FILTER_TYPE.SELECTRATING,
        optionsKey: "ratingsorder",
        filterKey: "combinedRatings.ratingSortRank",
      },
    },
  },
  continuouslyCallable: {
    label: "Continuously Callable",
    format: Constants.FORMAT_TYPE.BOOLEAN,
    condition: (bond) => {
      return get(bond, "callable");
    },
  },
  couponMonth: {
    label: "Coupon Month",
    filter: {
      type: Constants.FILTER_TYPE.CHECKBOX,
      optionsKey: "MONTHS",
    },
  },
  couponType: {
    description: {
      label: "Coupon Type",
      filterlabel: "Zero Coupon",
      filter: {
        type: Constants.FILTER_TYPE.TOGGLE,
        optionsKey: "COUPON_FEATURES",
      },
    },
  },
  cpr: {
    label: "CPR",
    format: Constants.FORMAT_TYPE.NUMBER,
    minDecimal: 2,
  },
  currentBalance: {
    label: "Current Balance",
    format: Constants.FORMAT_TYPE.CURRENCY,
    minDecimal: 2,
  },
  currentFace: {
    label: "Current Face",
    format: Constants.FORMAT_TYPE.CURRENCY,
    filter: {
      type: Constants.FILTER_TYPE.DUALRANGE,
      step: 10000,
      min: 0,
      max: 9999999,
    },
  },
  cusip: {
    label: "CUSIP",
    filter: {
      type: Constants.FILTER_TYPE.KEYWORD_WILDCARD,
      maxLength: 9,
    },
  },
  datedDate: {
    label: "Dated Date",
    format: Constants.FORMAT_TYPE.DATE,
  },
  dayBasis: {
    description: {
      label: "Day Count Basis",
    },
  },
  dayBasisDescription: {
    description: {
      label: "Day Count Basis",
    },
  },
  denominationIncrementAmount: {
    label: "Denomination Increment Amt",
    format: Constants.FORMAT_TYPE.CURRENCY,
    omitComma: true,
  },
  description: {
    label: "",
  },
  duplicate: {
    label: "",
  },
  emmaLink: {
    label: "EMMA",
    format: Constants.FORMAT_TYPE.HYPERLINK_BUTTON,
  },
  enteredDate: {
    label: "Date",
    format: Constants.FORMAT_TYPE.DATE,
    apiFormat: DATE_FORMAT.API_TIMESTAMP, //2020-09-04T16:26:56.657
    filter: {
      type: Constants.FILTER_TYPE.DATERANGE,
      disablePast: false,
    },
  },
  enteredUser: {
    label: "User",
    fullName: {
      label: "User",
      filter: {
        type: Constants.FILTER_TYPE.CHECKBOX,
      },
    },
  },
  enteredUserName: {
    label: "Uploaded by",
  },
  companyName: {
    label: "Institution",
    filter: {
      type: Constants.FILTER_TYPE.CHECKBOX,
      width: 200,
    },
  },
  estSpeed: {
    label: "PSA Speed",
  },
  excludeOwnedCds: {
    format: Constants.FORMAT_TYPE.BOOLEAN,
    filter: {
      type: Constants.FILTER_TYPE.BOOLEAN_TOGGLE,
      optionsKey: "EXCLUDE_FDIC",
      hideOmit: true,
    },
  },
  factor: {
    label: "Factor",
    format: Constants.FORMAT_TYPE.NUMBER,
    filter: {
      type: Constants.FILTER_TYPE.DUALRANGE,
      step: 0.1,
      min: 0,
      max: 1,
    },
  },
  file: {
    label: "Download",
  },
  fileName: {
    label: "File Name",
  },
  fileDescription: {
    label: "Description",
  },
  fileUploaded: {
    label: "Uploaded at",
    format: Constants.FORMAT_TYPE.DATETIME,
  },
  firstCouponDate: {
    label: "First Coupon Date",
    format: Constants.FORMAT_TYPE.DATE,
  },
  frequency: {
    label: "Frequency",
    filter: {
      type: Constants.FILTER_TYPE.CHECKBOX,
      optionsKey: "FREQUENCY",
    },
  },
  generalMarket: {
    label: "General Market/Bank Eligible",
    format: Constants.FORMAT_TYPE.BOOLEAN,
    filter: {
      type: Constants.FILTER_TYPE.TOGGLE,
      optionsKey: "ALL_ONLY_OMIT",
    },
  },
  highBid: {
    markupPrice: {
      label: "Price",
      format: Constants.FORMAT_TYPE.NUMBER,
    },
    markupYieldToWorst: {
      label: "YTW",
      format: Constants.FORMAT_TYPE.NUMBER,
    },
    markupYieldToMaturity: {
      label: "YTM/ Yield",
      format: Constants.FORMAT_TYPE.NUMBER,
    },
  },
  highestMoodyRating: {
    label: "Moody's",
  },
  highestSPRating: {
    label: "S&P",
  },
  historicalSpeed: {
    label: "Historical Speed",
  },
  holdingAmount: {
    label: "Amount",
    format: Constants.FORMAT_TYPE.CURRENCY,
    minDecimal: 2,
  },
  holdingCompany: {
    insuranceNumber: {
      label: "FDIC",
      format: Constants.FORMAT_TYPE.NUMBER,
      minDecimal: 0,
      omitComma: true,
    },
    name: {
      label: "Description",
    },
  },
  instrumentType: {
    label: "Type",
    description: {
      label: "Type",
      filter: {
        type: Constants.FILTER_TYPE.CHECKBOX,
        optionsKey: "instrumenttypes",
        filterKey: "instrumentType.id",
      },
    },
    id: {
      label: "",
      filter: {
        type: Constants.FILTER_TYPE.CHECKBOX,
      },
    },
  },
  insurance: {
    label: "Insurance",
  },
  insuranceNumber: {
    label: "FDIC",
    format: Constants.FORMAT_TYPE.NUMBER,
    minDecimal: 0,
    omitComma: true,
    filter: {
      type: Constants.FILTER_TYPE.KEYWORD,
      lookahead: false,
      pad: 6,
      maxLength: 6,
    },
  },
  interestAccrualDate: {
    label: "Interest Accrual Date",
    format: Constants.FORMAT_TYPE.DATE,
  },
  interestFrequency: {
    description: {
      label: "Coupon Frequency",
    },
    id: {
      label: "Coupon Frequency",
      filter: {
        type: Constants.FILTER_TYPE.CHECKBOX,
        optionsKey: "interestfrequencies",
      },
    },
  },
  mandatoryRedemptionCode: {
    description: {
      label: "Mandatory",
    },
  },
  specialRedemptionType: {
    label: "Special",
  },
  interestRate: {
    label: "Coupon",
    format: Constants.FORMAT_TYPE.NUMBER,
    minDecimal: 3,
    filter: {
      type: Constants.FILTER_TYPE.DUALRANGE,
      step: 0.5,
      min: 0,
      max: 8,
    },
    criteriumfilter: {
      type: Constants.FILTER_TYPE.NUMERIC_RANGE_INCREMENT,
      min: 0,
      max: 3,
      marks: [
        { value: 0, label: "0.0" },
        { value: 0.5 },
        { value: 1, label: "+/- 1.0" },
        { value: 1.5 },
        { value: 2, label: "+/- 2.0" },
        { value: 2.5 },
        { value: 3, label: "+/- 3.0" },
      ],
    },
  },
  isOverFDICLimit: {
    label: "",
  },
  requiredPartialQty: {
    label: "",
  },
  issueDate: {
    label: "Issue Date",
    format: Constants.FORMAT_TYPE.DATE,
  },
  issuer: {
    label: "Description",
    filter: {
      type: Constants.FILTER_TYPE.KEYWORD_WILDCARD,
    },
  },
  issuerShort: {
    label: "Issuer",
    filter: {
      type: Constants.FILTER_TYPE.KEYWORD_WILDCARD,
    },
  },
  issuerType: {
    label: "Issuer Type",
  },
  links: {
    label: "Documents & Links",
  },
  loanCount: {
    label: "Loan Count",
    format: Constants.FORMAT_TYPE.NUMBER,
    noDataText: LABEL.CONTACT_HELP_DESK,
    noDataValueEquivalent: 0,
  },
  maturityDate: {
    label: "Maturity Date",
    header: "Maturity",
    format: Constants.FORMAT_TYPE.DATE,
    filter: {
      type: Constants.FILTER_TYPE.DATERANGE,
      disablePast: true,
    },
    incrementfilter: DATE_RANGE_INCREMENT_FILTER,
    criteriumfilter: {
      type: Constants.FILTER_TYPE.DATE_RANGE_INCREMENT,
      min: 0,
      max: 36,
      marks: [
        { value: 0, label: "0m" },
        { value: 3 },
        { value: 6 },
        { value: 9 },
        { value: 12, label: "+/- 1yr" },
        { value: 18 },
        { value: 24, label: "+/- 2yr" },
        { value: 30 },
        { value: 36, label: "+/- 3yr" },
      ],
    },
  },
  mbsSeasoning: {
    label: "Seasoning",
    filter: {
      type: Constants.FILTER_TYPE.CHECKBOX,
      optionsKey: "seasonings",
      hideOmit: true,
    },
  },
  minimumDenominationAmount: {
    label: "Minimum Denomination Amt",
    format: Constants.FORMAT_TYPE.CURRENCY,
    omitComma: true,
  },
  municipalSecurity: {
    description: {
      label: "Security Description",
    },
    id: {
      label: "Security Type",
      filter: {
        type: Constants.FILTER_TYPE.CHECKBOX,
        optionsKey: "municipalsecuritytypes",
        height: 75,
      },
    },
  },
  nextCouponPaymentDate: {
    label: "Next Coupon",
    format: Constants.FORMAT_TYPE.DATE,
  },
  orderHistoryTypeAction: {
    label: "Action",
    filter: {
      type: Constants.FILTER_TYPE.CHECKBOX,
      optionsKey: "ordertypesbyaction",
      filterKey: "orderHistoryTypeAction",
      width: 200,
    },
  },
  orderNo: {
    label: "Order#",
    format: Constants.FORMAT_TYPE.NUMBER,
    minDecimal: 0,
    omitComma: true,
    filter: {
      type: Constants.FILTER_TYPE.KEYWORD,
      lookahead: false,
    },
  },
  originalFace: {
    label: "Original Face",
    format: Constants.FORMAT_TYPE.CURRENCY,
    filter: {
      type: Constants.FILTER_TYPE.DUALRANGE,
      step: 10000,
      min: 0,
      max: 9999999,
    },
  },
  originalIssueAmount: {
    label: "Original Issue Amt",
    format: Constants.FORMAT_TYPE.CURRENCY, //Whole numbers - no minDecimal
    noDataText: LABEL.CONTACT_HELP_DESK,
    noDataValueEquivalent: 0,
  },
  originalSize: {
    label: "Original Face",
    format: Constants.FORMAT_TYPE.CURRENCY,
  },
  outstandingIssueAmount: {
    label: "Outstanding Issue Amt",
    format: Constants.FORMAT_TYPE.CURRENCY, //Whole numbers - no minDecimal
  },
  portfolio: {
    label: "Portfolio",
  },
  preRefunded: {
    label: "Pre-Refunded ",
    format: Constants.FORMAT_TYPE.BOOLEAN,
    filter: {
      type: Constants.FILTER_TYPE.TOGGLE,
      optionsKey: "ALL_ONLY_OMIT",
    },
  },
  purchaseDate: {
    label: "Date",
    format: Constants.FORMAT_TYPE.DATE,
  },
  sinkingFund: {
    label: "Sinking Fund ",
    format: Constants.FORMAT_TYPE.BOOLEAN,
    filter: {
      type: Constants.FILTER_TYPE.TOGGLE,
      optionsKey: "ALL_ONLY_OMIT",
    },
  },
  price: {
    label: "Price",
    format: Constants.FORMAT_TYPE.NUMBER,
    filter: {
      type: Constants.FILTER_TYPE.DUALRANGE,
      step: 1,
      min: 80,
      max: 200,
    },
  },
  price_previous: {
    label: "Previous Price",
    format: Constants.FORMAT_TYPE.NUMBER,
    formatAccessor: "priceScale",
  },
  principal: {
    label: "Pricipal Amount",
    format: Constants.FORMAT_TYPE.CURRENCY,
    minDecimal: 2,
  },
  productCategoryAsIssuer: {
    label: "Issuer",
    filterKey: "productCategory",
    filter: {
      type: Constants.FILTER_TYPE.CHECKBOX,
      optionsKey: "issuers",
    },
  },
  productCategory: {
    label: "Product Category",
    filter: {
      type: Constants.FILTER_TYPE.CHECKBOX,
    },
  },
  psa: {
    label: "PSA Speed",
    format: Constants.FORMAT_TYPE.NUMBER,
    minDecimal: 0,
  },
  psaSpeed: {
    label: "PSA Speed",
    format: Constants.FORMAT_TYPE.NUMBER,
    minDecimal: 0,
    contactTradeDeskText: "*",
    noDataValueEquivalent: 0,
  },
  quantity: {
    label: "Quantity",
    header: "QTY (000s)",
    tooltip: "Quantity",
    format: Constants.FORMAT_TYPE.NUMBER,
    omitComma: true,
    filter: {
      type: Constants.FILTER_TYPE.RANGE,
      marks: [
        { value: 0 },
        { value: 100 },
        { value: 200 },
        { value: 300 },
        { value: 400 },
        { value: 500 },
        { value: 750 },
        { value: 1000 },
        { value: 1500 },
        { value: 2000 },
        { value: 2500 },
        { value: 3000 },
        { value: 4000 },
        { value: 5000 },
      ],
      min: 0,
      max: 5000,
    },
  },
  quantity_previous: {
    format: Constants.FORMAT_TYPE.NUMBER,
    omitComma: true,
  },
  rateTerm: {
    id: {
      label: "Term",
      filter: {
        type: Constants.FILTER_TYPE.CHECKBOX,
        optionsKey: "rateterms",
      },
    },
  },
  rating: {
    label: "Ratings",
  },
  ratingdate: {
    label: "Rating Date",
    format: Constants.FORMAT_TYPE.DATE,
  },
  ratingLink: {
    label: "S&P Report",
    format: Constants.FORMAT_TYPE.HYPERLINK_BUTTON,
    authenticated: true,
  },
  ratingMoody: {
    label: "Moody",
  },
  ratingSP: {
    label: "S&P",
  },
  enhanced: {
    rating: {
      label: "Enhanced Rating",
    },
    ratingDate: {
      label: "Enhanced Date",
      format: Constants.FORMAT_TYPE.DATE,
    },
  },

  overall: {
    rating: {
      label: "Rating",
    },
    ratingDate: {
      format: Constants.FORMAT_TYPE.DATE,
      label: "Rating Date",
    },
  },

  underlying: {
    rating: {
      label: "Underlying Rating",
    },
    ratingDate: {
      label: "Underlying Date",
      format: Constants.FORMAT_TYPE.DATE,
    },
  },

  topRatingsSP: {
    label: "S&P",
  },

  topRatingsMoody: {
    label: "Moody",
  },

  sector: {
    label: "Sector",
  },
  settlementDate: {
    header: "Settlement",
    label: "Settlement Date",
    format: Constants.FORMAT_TYPE.DATE,
    filter: {
      type: Constants.FILTER_TYPE.DATERANGE,
      disablePast: true,
    },
    incrementfilter: DATE_RANGE_INCREMENT_FILTER,
  },
  state: {
    label: "State",
    filter: {
      type: Constants.FILTER_TYPE.CHECKBOX,
      width: 500,
      optionsKey: "STATES",
    },
  },
  stateTaxStatus: {
    description: {
      label: "State Tax Status",
    },
  },
  status: {
    description: {
      label: "Status",
      filter: {
        type: Constants.FILTER_TYPE.CHECKBOX,
        optionsKey: "finalorderstatus",
        filterKey: "status.id",
      },
    },
    id: {
      label: "",
      filter: {
        type: Constants.FILTER_TYPE.CHECKBOX,
      },
    },
  },
  subjectToAMT: {
    label: "Subject to AMT",
    format: Constants.FORMAT_TYPE.BOOLEAN,
    filter: {
      type: Constants.FILTER_TYPE.TOGGLE,
      optionsKey: "ALL_ONLY_OMIT",
    },
  },
  taxable: {
    label: "Federally Taxable",
    format: Constants.FORMAT_TYPE.BOOLEAN,
    filter: {
      type: Constants.FILTER_TYPE.TOGGLE,
      optionsKey: "ALL_ONLY_OMIT",
    },
  },
  term: {
    label: "Term",
    filter: {
      type: Constants.FILTER_TYPE.CHECKBOX,
      optionsKey: "TERM",
    },
  },
  termDays: {
    label: "Term",
  },
  termYrOfMbs: {
    label: "Loan Type",
    filter: {
      type: Constants.FILTER_TYPE.CHECKBOX,
      optionsKey: "termyears",
      hideOmit: true,
    },
  },
  timePeriodDescription: {
    label: "",
  },
  total: {
    label: "Total",
    format: Constants.FORMAT_TYPE.CURRENCY,
    minDecimal: 2,
  },
  treasuryType: {
    label: "Product Type",
    filter: {
      type: Constants.FILTER_TYPE.CHECKBOX,
      optionsKey: "treasurytypes",
    },
  },
  type: {
    label: "Action",
  },

  useOfProceeds: {
    description: {
      label: "Use of Proceeds",
    },
    id: {
      label: "Use of Proceeds",
      filter: {
        type: Constants.FILTER_TYPE.CHECKBOX,
        optionsKey: "usesofproceeds",
        height: 150,
      },
    },
  },
  useOfProceedsSupplementary: {
    label: "Use of Proceeds Secondary",
  },
  userLabel: {
    label: "ACCT ID (optional)",
    filter: {
      type: Constants.FILTER_TYPE.KEYWORD_WILDCARD,
      maxLength: 12,
      lookahead: false,
    },
  },
  weightedAverageCoupon: {
    label: "WAC",
    format: Constants.FORMAT_TYPE.NUMBER,
  },
  weightedAverageLife: {
    label: "WAL",
    format: Constants.FORMAT_TYPE.NUMBER,
    contactTradeDeskText: "*",
    noDataValueEquivalent: 0,
    filter: {
      type: Constants.FILTER_TYPE.DUALRANGE,
      step: 0.1,
      min: 0,
      max: 10,
    },
    criteriumfilter: {
      type: Constants.FILTER_TYPE.NUMERIC_RANGE_INCREMENT,
      min: 0,
      max: 3,
      step: 0.5,
    },
  },
  weightedAverageLoanAge: {
    label: "WALA",
    format: Constants.FORMAT_TYPE.NUMBER,
  },
  weightedAverageMaturity: {
    label: "WAM",
    format: Constants.FORMAT_TYPE.NUMBER,
  },
  weightedAverageOfLoanToValue: {
    label: "WAOLTV",
    format: Constants.FORMAT_TYPE.NUMBER,
  },
  weightedAverageOriginalLoanSize: {
    label: "WAOLS",
    format: Constants.FORMAT_TYPE.CURRENCY,
    minDecimal: 2,
    noDataText: LABEL.CONTACT_HELP_DESK,
    noDataValueEquivalent: 0,
  },
  yieldToCall: {
    label: "YTC",
    format: Constants.FORMAT_TYPE.NUMBER,
    tooltip: "Yield to Call",
  },
  yieldToMaturity: {
    label: "YTM",
    tooltip: "Yield to Maturity",
    format: Constants.FORMAT_TYPE.NUMBER,
    contactTradeDeskText: "*",
    noDataValueEquivalent: 0,
    filter: {
      type: Constants.FILTER_TYPE.DUALRANGE,
      step: 0.1,
      min: 0,
      max: 5,
    },
  },
  yieldToMaturity_previous: {
    label: "Previous Yield",
    format: Constants.FORMAT_TYPE.NUMBER,
    formatAccessor: "yieldToMaturityScale",
  },
  yieldToWorst: {
    label: "YTW",
    tooltip: "Yield to Worst",
    format: Constants.FORMAT_TYPE.NUMBER,
    filter: {
      type: Constants.FILTER_TYPE.DUALRANGE,
      step: 0.1,
      min: 0,
      max: 5,
    },
  },
  yieldToWorst_previous: {
    format: Constants.FORMAT_TYPE.NUMBER,
    formatAccessor: "yieldToWorstScale",
  },
  extraordinaryMakeWholeCall: {
    label: "Extraordinary Make Whole Call",
    format: Constants.FORMAT_TYPE.BOOLEAN,
  },
  makeWholeCall: {
    label: "Make Whole Call",
    format: Constants.FORMAT_TYPE.BOOLEAN,
    filterlabel: "Make Whole Call",
    filter: {
      type: Constants.FILTER_TYPE.TOGGLE,
      optionsKey: "ALL_ONLY_OMIT",
    },
  },
  makeWholeCallSpread: {
    label: "Make Whole Call Spread",
    format: Constants.FORMAT_TYPE.NUMBER,
    condition: (bond) => {
      return get(bond, "makeWholeCall");
    },
  },
  makeWholeBenchMarkDescription: {
    label: "Make Whole Call Benchmark",
    condition: (bond) => {
      return get(bond, "makeWholeCall");
    },
  },
};

Object.keys(PROPERTY).forEach((key) => {
  PROPERTY[key].accessor = key;
  Object.keys(PROPERTY[key]).forEach((k) => {
    if (typeof PROPERTY[key][k] === "object" && k !== "filter") {
      PROPERTY[key][k].accessor = key + "." + k;
    }
  });
});

export const PROPERTY_TABLE = {
  HISTORICAL_SPEED: {
    accessor: "historicalSpeed",
    table: {
      rowKey: PROPERTY.timePeriodDescription,
      rows: [
        "Last Month",
        "Last 3 Months",
        "Last 6 Months",
        "Last 12 Months",
        "Last 24 Months",
        "Since Issuance",
      ],
      columns: [PROPERTY.cpr, PROPERTY.psa],
    },
  },
  RATE_SHOCKS: {
    accessor: "rateShocks",
    table: {
      rowKey: PROPERTY.description,
      rows: [
        "Rates Down 300",
        "Rates Down 200",
        "Rates Down 100",
        "Unchanged",
        "Rates Up 100",
        "Rates Up 200",
        "Rates Up 300",
      ],
      columns: [
        PROPERTY.psaSpeed,
        PROPERTY.weightedAverageLife,
        { ...PROPERTY.yieldToMaturity, ...{ label: "Yield" } },
      ],
    },
  },
  RATINGS: {
    table: {
      columns: [PROPERTY.topRatingsSP, PROPERTY.topRatingsMoody],
      rows: [
        PROPERTY.overall.rating,
        PROPERTY.overall.ratingDate,
        PROPERTY.underlying.rating,
        PROPERTY.underlying.ratingDate,
        PROPERTY.enhanced.rating,
        PROPERTY.enhanced.ratingDate,
      ],
    },
    additionalProperties: [
      PROPERTY.insuredBy,
      PROPERTY.otherEnhancementTypeDescription,
      PROPERTY.insurance,
      PROPERTY.emmaLink,
      PROPERTY.ratingLink,
    ],
  },
};

export const PROPERTY_GROUP = {
  MUNICIPAL: {
    STRUCTURE: {
      properties: [
        PROPERTY.settlementDate,
        PROPERTY.interestRate,
        PROPERTY.couponType.description,
        PROPERTY.interestFrequency.description,
        PROPERTY.maturityDate,
      ],
    },
    CALL_INFORMATION: {
      label: "Call Information",
      properties: [
        PROPERTY.callable,
        PROPERTY.continuouslyCallable,
        PROPERTY.preRefunded,
        PROPERTY.sinkingFund,
        PROPERTY.callPrice,
        PROPERTY.callDate,
        PROPERTY.makeWholeCall,
        PROPERTY.makeWholeBenchMarkDescription,
        PROPERTY.makeWholeCallSpread,
        PROPERTY.extraordinaryMakeWholeCall,
        PROPERTY.callSchedule,
      ],
    },
    REDEMPTIONS: {
      label: "Redemptions",
      properties: [PROPERTY.mandatoryRedemptionCode.description, PROPERTY.specialRedemptionType],
    },
    RATINGS: {
      label: "Ratings",
      properties: [PROPERTY_TABLE.RATINGS],
    },
    ISSUANCE: {
      label: "Issuance",
      properties: [
        PROPERTY.issueDate,
        PROPERTY.datedDate,
        PROPERTY.firstCouponDate,
        PROPERTY.originalIssueAmount,
        PROPERTY.minimumDenominationAmount,
        PROPERTY.denominationIncrementAmount,
        PROPERTY.dayBasis.description,
      ],
    },
    PROCEEDS_TAX: {
      label: "Proceeds and Tax",
      properties: [
        PROPERTY.municipalSecurity.description,
        PROPERTY.useOfProceeds.description,
        PROPERTY.useOfProceedsSupplementary,
        PROPERTY.state,
        PROPERTY.stateTaxStatus.description,
        PROPERTY.subjectToAMT,
        PROPERTY.taxable,
        PROPERTY.bankQualified,
      ],
    },
  },
  MORTGAGE: {
    STRUCTURE: {
      properties: [
        PROPERTY.settlementDate,
        PROPERTY.weightedAverageCoupon,
        PROPERTY.interestRate,
        PROPERTY.nextCouponPaymentDate,
        PROPERTY.maturityDate,
        PROPERTY.weightedAverageMaturity,
        PROPERTY.weightedAverageLoanAge,
        PROPERTY.factor,
      ],
    },
    ISSUANCE: {
      label: "Issuance",
      properties: [
        PROPERTY.issueDate,
        PROPERTY.firstCouponDate,
        PROPERTY.originalIssueAmount,
        PROPERTY.dayBasis.description,
      ],
    },
    CURRENT_DATA: {
      label: "Current Data",
      properties: [
        PROPERTY.currentBalance,
        PROPERTY.loanCount,
        PROPERTY.weightedAverageOriginalLoanSize,
        PROPERTY.weightedAverageOfLoanToValue,
      ],
    },
    HISTORICAL_SPEED: {
      label: "Historical Prepayment Speeds",
      properties: [PROPERTY_TABLE.HISTORICAL_SPEED],
      alwaysShow: true,
    },
    RATE_SHOCKS: {
      label: "Rate Shocks",
      properties: [PROPERTY_TABLE.RATE_SHOCKS],
    },
  },
  AGENCY: {
    STRUCTURE: {
      properties: [
        PROPERTY.settlementDate,
        PROPERTY.interestRate,
        PROPERTY.couponType.description,
        PROPERTY.interestFrequency.description,
        PROPERTY.maturityDate,
      ],
    },
    CALL_INFORMATION: {
      label: "Call Information",
      properties: [
        PROPERTY.callable,
        PROPERTY.continuouslyCallable,
        PROPERTY.preRefunded,
        PROPERTY.sinkingFund,
        PROPERTY.callPrice,
        PROPERTY.callDate,
        PROPERTY.callSchedule,
      ],
    },
    RATINGS: {
      label: "Ratings",
      properties: [PROPERTY.highestSPRating, PROPERTY.highestMoodyRating],
    },
    ISSUANCE: {
      label: "Issuance",
      properties: [
        PROPERTY.issueDate,
        PROPERTY.datedDate,
        PROPERTY.firstCouponDate,
        PROPERTY.originalIssueAmount,
        PROPERTY.minimumDenominationAmount,
        PROPERTY.denominationIncrementAmount,
        PROPERTY.dayBasis.description,
      ],
    },
  },
  TREASURY: {
    STRUCTURE: {
      properties: [
        PROPERTY.settlementDate,
        PROPERTY.interestRate,
        PROPERTY.couponType.description,
        PROPERTY.interestFrequency.description,
        PROPERTY.outstandingIssueAmount,
        PROPERTY.maturityDate,
      ],
    },
    ISSUANCE: {
      label: "Issuance",
      properties: [
        PROPERTY.issueDate,
        PROPERTY.datedDate,
        PROPERTY.firstCouponDate,

        PROPERTY.minimumDenominationAmount,
        PROPERTY.denominationIncrementAmount,
        PROPERTY.dayBasis.description,
      ],
    },
  },
  CD: {
    STRUCTURE: {
      properties: [
        PROPERTY.settlementDate,
        PROPERTY.state,
        PROPERTY.interestRate,
        PROPERTY.couponType.description,
        PROPERTY.interestFrequency.description,
        PROPERTY.maturityDate,
        PROPERTY.cdDetailSheetLink,
      ],
    },
    CALL_INFORMATION: {
      label: "Call Information",
      properties: [
        PROPERTY.callable,
        PROPERTY.continuouslyCallable,
        PROPERTY.preRefunded,
        PROPERTY.sinkingFund,
        PROPERTY.callPrice,
        PROPERTY.callDate,
        PROPERTY.callSchedule,
      ],
    },
    ISSUANCE: {
      label: "Issuance",
      properties: [
        PROPERTY.issueDate,
        PROPERTY.datedDate,
        PROPERTY.firstCouponDate,
        PROPERTY.originalIssueAmount,
        PROPERTY.minimumDenominationAmount,
        PROPERTY.denominationIncrementAmount,
        PROPERTY.dayBasis.description,
      ],
    },
  },
};
