import React from "react";
import PropTypes from "prop-types";

import * as utils from "commons/utils";
import * as Constants from "commons/constants";
import { useMessages } from "providers/BrandingProvider";
import QBTypography from "components/QBTypography";
import { getUser } from "commons/helpers/userStorage";
import ModalMessages from "components/modals/common/ModalMessages";
import RequestInfo from "components/modals/RequestInfo";
import RateFreshnessTime from "components/RateFreshnessTime";

import { makeStyles } from "@material-ui/core/styles";
import EditIcon from "@material-ui/icons/Edit";

const useStyles = makeStyles((theme) => ({
  header: {
    width: "100%",
    display: "flex",
  },
  headerItem: {
    flexGrow: 1,
    textAlign: "center",
    "&:first-child": {
      flexGrow: 0,
      textAlign: "left",
    },
    "&:last-child": {
      flexGrow: 0,
      textAlign: "right",
    },
  },
}));

export const TicketHeader = ({
  infoMessages,
  warningMessages,
  errorMessages,
  cart,
  instrumentCategory,
  ticketInfo,
  refreshTime,
  buyer,
  onOpenAccountSelectModal,
  disableAccountEdit,
  allowTradeForOthers,
}) => {
  const classes = useStyles();
  const user = getUser();
  const Messages = useMessages();

  const handleOpenSelectModal = () => {
    if (!disableAccountEdit) {
      onOpenAccountSelectModal();
    }
  };

  return !utils.hasNonEmptyValue(user) ? null : (
    <div className={classes.header}>
      <div className={classes.headerItem}>
        {utils.hasNonEmptyValue(user) && (
          <React.Fragment>
            <QBTypography>
              <span>{Messages.LABEL.ACCOUNT}</span>
              <span
                style={{ cursor: !disableAccountEdit ? "pointer" : "default" }}
                onClick={handleOpenSelectModal}
              >
                {buyer?.brokeredClearingAccount?.accountFormatted}
              </span>
              {!disableAccountEdit &&
                buyer?.brokeredClearingAccount &&
                (user?.brokeredClearingAccounts?.length > 1 || allowTradeForOthers) && (
                  <span style={{ cursor: "pointer" }} onClick={handleOpenSelectModal}>
                    <EditIcon style={{ fontSize: 11 }} />
                  </span>
                )}
            </QBTypography>
            <QBTypography>
              <span>{Messages.LABEL.NAME}</span>
              <>
                {buyer?.brokeredClearingAccount && (
                  <>
                    {buyer?.affiliateTrader && <span>{buyer?.affiliateTrader.fullName}</span>}
                    {!buyer?.affiliateTrader && (
                      <span>
                        {user.firstName} {user.lastName}
                      </span>
                    )}
                  </>
                )}
              </>
            </QBTypography>
            <QBTypography>
              <span>{Messages.LABEL.INSTITUTION}</span>
              <>
                {buyer?.brokeredClearingAccount && (
                  <>
                    {buyer?.affiliateInstitution && <span>{buyer?.affiliateInstitution.companyName}</span>}
                    {!buyer?.affiliateInstitution && <span>{user.institution}</span>}
                  </>
                )}
              </>
            </QBTypography>
            <QBTypography>
              <span>{Messages.LABEL.DATE}</span>
              <span>{utils.getCurrentDateTime(true)}</span>
            </QBTypography>
          </React.Fragment>
        )}
      </div>
      <div className={classes.headerItem}>
        {refreshTime && (
          <RateFreshnessTime label={Messages.LABEL.PRICES_CURRENT} refreshedTime={refreshTime} />
        )}
        {utils.hasNonEmptyValue(cart) && errorMessages.length > 0 && (
          <ModalMessages
            messages={[Messages.MESSAGE.TRADE_ALERT]}
            level={Constants.ALERT_LEVEL.ERROR}
            style={{ textTransform: "uppercase", fontSize: "0.85rem" }}
          />
        )}

        <ModalMessages messages={infoMessages} level={Constants.ALERT_LEVEL.INFO} />
        {errorMessages.length === 0 && warningMessages.length > 0 && (
          <ModalMessages
            messages={[Messages.MESSAGE.TRADE_ALERT]}
            level={Constants.ALERT_LEVEL.WARNING}
            style={{ textTransform: "uppercase", fontSize: "0.85rem" }}
          />
        )}
      </div>
      <div className={classes.headerItem}>
        <div>
          <div style={{ display: "flex", justifyContent: "space-evenly", alignItems: "center" }}>
            <QBTypography>
              <span>{Messages.LABEL.TRADE_DESK}</span>
              <span>{Messages.LABEL.TRADE_DESK_PHONE}</span>
              <RequestInfo variant="icon" />
            </QBTypography>
          </div>
          {utils.hasNonEmptyValue(instrumentCategory) && (
            <QBTypography>
              <span>{Messages.LABEL.TYPE}</span>
              <span>{Messages.LABEL[utils.getInventoryKeyFromType(instrumentCategory)]}</span>
            </QBTypography>
          )}
          {ticketInfo && (
            <QBTypography style={{ marginTop: 2 }}>
              <span style={{ fontSize: "0.85rem", fontWeight: 500 }}>{`Ticket `}</span>
              <span
                style={{ fontSize: "0.85rem", fontWeight: 500 }}
              >{`${ticketInfo.ticketNum} of ${ticketInfo.totalNumTickets}`}</span>
            </QBTypography>
          )}
        </div>
      </div>
    </div>
  );
};

TicketHeader.propTypes = {
  instrumentCategory: PropTypes.oneOf(Object.values(Constants.INVENTORY_TYPE)),
  headerItem: PropTypes.node,
  ticketInfo: PropTypes.object,
  infoMessages: PropTypes.arrayOf(PropTypes.string),
  warningMessages: PropTypes.arrayOf(PropTypes.string),
  errorMessages: PropTypes.arrayOf(PropTypes.string),
  brokeredClearingAccount: PropTypes.object,
  affiliateTrader: PropTypes.object,
  affiliateInstitution: PropTypes.object,
};

export default TicketHeader;
