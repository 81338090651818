import React from "react";
import { cloneDeep, get } from "lodash";

import { useViewState, useViewStateDispatcher } from "providers/ViewStateProvider";
import * as utils from "commons/utils";
import * as Constants from "commons/constants";
import * as DataConfig from "config/dataconfig";
import * as filterUtils from "commons/filterUtils";
import DataTable from "components/datatable/DataTable";
import { MainPaper } from "components/containers/Containers";
import { useDataService } from "services/DataService";

const HistoryView = () => {
  const dispatchViewStateChange = useViewStateDispatcher();

  const [data, setData] = React.useState([]);

  // sorted, filtered but not paged data for export
  const [exportData, setExportData] = React.useState();

  const [totalNumberRows, setTotalNumberRows] = React.useState();

  const [state, queryDataService] = useDataService();

  const viewState = useViewState();
  const currentViewState = get(viewState, Constants.VIEW.HISTORY, {});

  React.useEffect(() => {
    handleCurrentViewStateChange({ activeView: Constants.VIEW.HISTORY });
    fetchData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    if (currentViewState?.query) {
      applyFiltersAndSort(state.data, currentViewState.query);
    } else {
      applyFiltersAndSort(state.data, state.query);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.data]);

  React.useEffect(() => {
    if (currentViewState?.query) {
      applyFiltersAndSort(state.data, currentViewState.query);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(currentViewState)]);

  const fetchData = () => {
    const queryParams = {
      type: Constants.QUERY_TYPE.HISTORY,
      size: 500,
      page: 0,
      sort: [{ id: "enteredDate", desc: true }],
      clearDataOnFetch: true,
    };

    queryDataService(queryParams);
  };

  const applyFiltersAndSort = (data, query) => {
    let filteredSortedData = cloneDeep(data);

    if (query.filter) {
      Object.keys(query.filter).forEach((f) => {
        const filter = query.filter[f];
        if (filter.filterKey) {
          filteredSortedData = filteredSortedData.filter((r, i) => {
            r._original = { ...r };
            return filterUtils.filter({ id: filter.filterKey, value: filter }, r);
          });
        }
      });
    }

    filteredSortedData = utils.sortData(filteredSortedData, query.sort);
    setTotalNumberRows(filteredSortedData.length);
    setExportData(utils.obfuscateCusips(cloneDeep(filteredSortedData)));

    filteredSortedData = utils.pageData(filteredSortedData, query.page, DataConfig.DEFAULT_PAGE_SIZE);
    setData(filteredSortedData);
  };

  const handleCurrentViewStateChange = (state) => {
    dispatchViewStateChange({ state: state, view: Constants.VIEW.HISTORY });
  };

  const hasCompanyColumn = () => {
    return utils.hasCompanyColumn(state.data);
  };

  return (
    <React.Fragment>
      <MainPaper style={{ display: "flex" }}>
        <DataTable
          manual={true}
          view={Constants.VIEW.HISTORY}
          queryType={Constants.QUERY_TYPE.HISTORY}
          determineAlternateView={hasCompanyColumn}
          data={data}
          unfilteredData={state.data}
          exportData={exportData}
          totalNumberRows={totalNumberRows}
          loading={state.isLoading}
          error={state.isError}
          onViewStateChange={handleCurrentViewStateChange}
          onDocumentsUpdated={fetchData}
        />
      </MainPaper>
    </React.Fragment>
  );
};

export default HistoryView;
