import React from "react";
import clsx from "clsx";
import { get, remove, deburr, sortBy } from "lodash";
import PropTypes from "prop-types";

import * as utils from "commons/utils";
import * as Constants from "commons/constants";
import { useMessages } from "providers/BrandingProvider";
import { TextInput, SearchAdornment, InlineErrorTooltip } from "components/Controls";
import { PopperPaper } from "components/containers/Modals";
import { useDataService } from "services/DataService";

import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import ListItem from "@material-ui/core/ListItem";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import IconButton from "@material-ui/core/IconButton";
import EditIcon from "@material-ui/icons/Edit";
import RemoveCircleOutlineIcon from "@material-ui/icons/RemoveCircleOutline";
import Popper from "@material-ui/core/Popper";
import Fade from "@material-ui/core/Fade";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";

import { makeStyles, withStyles } from "@material-ui/core/styles";
import AltThemedComponent from "./AltThemedComponent";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    width: "100%",
  },
  transitionPopper: {
    zIndex: 1400,
  },
  paper: {
    width: "100%",
    minWidth: 200,
    maxWidth: 300,
    paddingRight: 4,
  },
  label: {
    lineHeight: "30px",
    fontSize: "0.7rem",
    marginRight: 4,
  },
  icon: {
    color: theme.palette.primary.main,
    height: 20,
    width: 20,
    right: -5,
  },
  menuItem: {
    fontSize: "0.65rem",
    color: theme.palette.text.textInput,
    padding: 4,
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    overflow: "hidden",
  },
  selectable: {
    "&:hover:not(.edit)": {
      backgroundColor: theme.palette.background.hover,
    },
    "&.selected": {
      backgroundColor: theme.palette.background.selected,
    },
  },
  iconButton: {
    padding: 2,
  },
  itemIcon: {
    fontSize: "0.8rem",
  },
  nameWrapper: {
    marginRight: 4,
    width: "100%",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  inputWrapper: {
    marginRight: 4,
    width: "100%",
  },
  inputRoot: {
    margin: 0,
    width: "100%",
  },
  name: {
    cursor: "pointer",
  },
  toolbarSection: {
    width: "100%",
    textAlign: "center",
    paddingRight: 4,
  },
  searchesWrapper: {
    overflowY: "auto",
    maxHeight: 600,
    paddingRight: 4,
  },
  searchInputWrapper: {
    maxWidth: 150,
    textAlign: "center",
  },
  saveAsWrapper: {
    marginTop: 4,
    paddingTop: 6,
    borderTop: "1px solid",
    borderTopColor: theme.palette.border.main,
  },
  buttonWrapper: {
    textAlign: "right",
    width: "100%",
    "& button": {
      margin: "0 2px",
    },
  },
}));

const SavedCriteriaButton = withStyles((theme) => ({
  root: {
    minWidth: 50,
    maxWidth: 300,
    lineHeight: 1,
    padding: 4,
    fontSize: "0.6rem",
    textTransform: "none",
  },
}))(Button);

const SavedCriteriaOutlineButton = withStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.paperButton,
    marginRight: 0,
    "&.MuiButton-outlined.Mui-disabled": {
      borderColor: theme.palette.border.disabled,
      color: theme.palette.text.disabled,
    },
  },
}))(SavedCriteriaButton);

const MenuButton = withStyles((theme) => ({
  root: {
    padding: 4,
    lineHeight: 1,
    fontSize: "0.6rem",
    minWidth: 40,
    fontWeight: "normal",
    borderRadius: 4,
    "&.MuiButton-outlinedSecondary.Mui-disabled": {
      border: "1px solid",
      borderColor: theme.palette.border.disabled,
      color: theme.palette.text.disabled,
    },
  },
}))(Button);

const Search = ({ search, validationNames, onUpdate, onDelete, onSelect, onEditStart }) => {
  const classes = useStyles();
  const Messages = useMessages();

  const [edit, setEdit] = React.useState(false);
  const [confirm, setConfirm] = React.useState(false);
  const [searchName, setSearchName] = React.useState(search.description);

  const [searchNameValid, setSearchNameValid] = React.useState(true);
  const saveButtonId = `SavedCriteriaEdit_${search.id}`;
  const allowEdit =
    utils.hasNonEmptyValue(onUpdate) &&
    utils.hasNonEmptyValue(onDelete) &&
    utils.hasNonEmptyValue(onEditStart);

  const toggleEdit = () => {
    if (!edit) {
      onEditStart();
    }
    setEdit(!edit);
  };

  const isValid = (value) => {
    return utils.hasNonEmptyValue(value) && validationNames.indexOf(value) === -1;
  };

  const doUpdate = () => {
    if (searchName !== search.description) {
      search.description = searchName;
      onUpdate(search);
    }
    setEdit(false);
  };

  const handleKeyPress = (event) => {
    if (event.charCode === 13) {
      const valid = isValid(searchName);

      if (valid) {
        doUpdate();
        setEdit(false);
      }
    }
  };

  const handleInputChange = (event) => {
    let val = event.target.value;
    const valid = isValid(val);
    setSearchNameValid(valid);
    setSearchName(val);
  };

  const handleBlur = (event) => {
    if (get(event, "relatedTarget.id") !== saveButtonId) {
      setEdit(false);
    }
    setConfirm(false);
  };

  return (
    <ClickAwayListener onClickAway={handleBlur}>
      <ListItem
        key={searchName}
        classes={{
          root: clsx(classes.menuItem, {
            [classes.selectable]: !edit && !confirm,
          }),
        }}
      >
        {edit && (
          <React.Fragment>
            <div className={classes.nameWrapper}>
              <TextInput
                autoFocus={true}
                maxLength={100}
                value={searchName}
                error={!searchNameValid}
                className={classes.inputRoot}
                onChange={handleInputChange}
                onBlur={handleBlur}
                onKeyPress={handleKeyPress}
                onClick={(e) => e.stopPropagation()}
              />
            </div>
            <MenuButton
              id={saveButtonId}
              size="small"
              color="secondary"
              variant="outlined"
              disableFocusRipple={true}
              disabled={!searchNameValid}
              onClick={doUpdate}
            >
              {Messages.LABEL.SAVE}
            </MenuButton>
          </React.Fragment>
        )}
        {!edit && (
          <React.Fragment>
            {!confirm && (
              <React.Fragment>
                <div className={classes.nameWrapper} onClick={(e) => onSelect(e, search)}>
                  <Typography className={classes.name} variant="inherit" noWrap>
                    {searchName}
                  </Typography>
                </div>
                {allowEdit && (
                  <React.Fragment>
                    <IconButton
                      disableRipple
                      disableFocusRipple
                      className={classes.iconButton}
                      onClick={toggleEdit}
                    >
                      <EditIcon color="secondary" className={classes.itemIcon} fontSize="small" />
                    </IconButton>
                    <IconButton
                      disableRipple
                      disableFocusRipple
                      className={classes.iconButton}
                      onClick={(e) => setConfirm(true)}
                    >
                      <RemoveCircleOutlineIcon color="error" className={classes.itemIcon} fontSize="small" />
                    </IconButton>
                  </React.Fragment>
                )}
              </React.Fragment>
            )}
            {confirm && (
              <React.Fragment>
                <div className={classes.nameWrapper}>
                  <Typography className={classes.name} variant="inherit" noWrap>
                    {searchName}
                  </Typography>
                </div>
                <div className={classes.buttonWrapper}>
                  <MenuButton
                    size="small"
                    variant="outlined"
                    disableFocusRipple={true}
                    onClick={(e) => setConfirm(false)}
                  >
                    {Messages.LABEL.CANCEL}
                  </MenuButton>
                  <MenuButton
                    size="small"
                    color="secondary"
                    variant="outlined"
                    disableFocusRipple={true}
                    onClick={(e) => onDelete(e, search)}
                  >
                    {Messages.LABEL.DELETE}
                  </MenuButton>
                </div>
              </React.Fragment>
            )}
          </React.Fragment>
        )}
      </ListItem>
    </ClickAwayListener>
  );
};

Search.SearchDefinition = PropTypes.shape({
  id: PropTypes.number.isRequired,
  instrumentType: PropTypes.number.isRequired,
  description: PropTypes.string.isRequired,
  filterConfig: PropTypes.string.isRequried,
});

Search.propTypes = {
  search: Search.SearchDefinition.isRequired,
  validationNames: PropTypes.arrayOf(PropTypes.string),
  onSelect: PropTypes.func.isRequired,
  onUpdate: PropTypes.func,
  onDelete: PropTypes.func,
  onEditStart: PropTypes.func,
};

const Save = ({ searches, query, onUpdate, onAdd }) => {
  const classes = useStyles();
  const Messages = useMessages();
  const [saveName, setSaveName] = React.useState("");
  const [tooltipMessage, setTooltipMessage] = React.useState("");

  const validationNames = searches.map((s) => s.description);
  const searchName = utils.hasNonEmptyValue(query.id)
    ? searches.find((s) => s.id === query.id)?.description
    : null;

  const isValid = (value) => {
    return utils.hasNonEmptyValue(value) && validationNames.indexOf(value) === -1;
  };

  const handleInputChange = (event) => {
    setTooltipMessage("");

    const val = event.target.value;

    if (!isValid(val)) {
      setTooltipMessage(Messages.MESSAGE.VALIDATION.DUPLICATE_NAME);
    }
    setSaveName(val);
  };

  const handleAddSearch = () => {
    onAdd({ description: saveName });
  };

  const handleSaveAs = () => {
    query.description = searchName;
    onUpdate(query);
  };

  return (
    <React.Fragment>
      <ListItem classes={{ root: classes.menuItem }}>
        <InlineErrorTooltip
          arrow
          onClick={(e) => setTooltipMessage("")}
          open={utils.hasNonEmptyValue(tooltipMessage)}
          title={tooltipMessage}
        >
          <div className={classes.inputWrapper}>
            <TextInput
              value={saveName}
              maxLength={100}
              error={utils.hasNonEmptyValue(tooltipMessage)}
              className={classes.inputRoot}
              onChange={handleInputChange}
              autoFocus={true}
              placeholder={Messages.LABEL.SEARCH_NAME}
            />
          </div>
        </InlineErrorTooltip>
        <MenuButton
          disabled={utils.hasNonEmptyValue(tooltipMessage) || !utils.hasNonEmptyValue(saveName)}
          size="small"
          color="secondary"
          variant="outlined"
          disableFocusRipple={true}
          onClick={handleAddSearch}
        >
          {Messages.LABEL.SAVE}
        </MenuButton>
      </ListItem>
      {utils.isDirtyCompBondSavedQuery(query, searches) && (
        <div className={classes.saveAsWrapper}>
          <ListItem classes={{ root: clsx(classes.menuItem, { [classes.selectable]: true }) }}>
            <Typography onClick={handleSaveAs} className={classes.name} variant="inherit" noWrap>
              {Messages.LABEL.UPDATE} '{searchName}'
            </Typography>
          </ListItem>
        </div>
      )}
    </React.Fragment>
  );
};

Save.propTypes = {
  query: PropTypes.object.isRequired,
  searches: PropTypes.arrayOf(Search.SearchDefinition).isRequired,
  onUpdate: PropTypes.func.isRequired,
  onAdd: PropTypes.func.isRequired,
};

const savedCriteriaRef = React.createRef();

const SavedCriteria = ({ compareBond, query, searches, onSetSearch, onDeleteSearch, onSearchesUpdated }) => {
  const classes = useStyles();
  const Messages = useMessages();

  const [updateState, updateWithDataService] = useDataService();

  const [savedCriteriaAnchorEl, setSavedCriteriaAnchorEl] = React.useState(null);
  const [saveAnchorEl, setSaveAnchorEl] = React.useState(null);
  const [searchValue, setSearchValue] = React.useState("");
  const [filteredOptions, setFilteredOptions] = React.useState([]);

  React.useEffect(() => {
    if (!updateState.isLoading && !updateState.isError) {
      if (utils.hasNonEmptyValue(updateState.data)) {
        updateState.data.id = updateState.data.id || updateState.query.identifier.filterValue;
        onSetSearch(updateState.data);
      }
      onSearchesUpdated();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateState]);

  const handleEditStart = () => {
    if (savedCriteriaRef.current) {
      savedCriteriaRef.current.style["min-width"] = savedCriteriaRef.current.clientWidth + "px";
    }
  };

  const clearSearch = () => {
    setFilteredOptions([]);
    setSearchValue("");
  };

  const openSavePopper = (event) => {
    event.stopPropagation();
    setSaveAnchorEl(saveAnchorEl ? null : event.currentTarget);
  };

  const closeSavedCriteriaPopper = () => {
    setSavedCriteriaAnchorEl(null);
    clearSearch();
  };

  const closeSavePopper = () => {
    setSaveAnchorEl(null);
  };

  const openSavedCriteria = (event) => {
    event.stopPropagation();
    setSavedCriteriaAnchorEl(savedCriteriaAnchorEl ? null : event.currentTarget);
  };

  const doSavedCriteriaAction = (action, id, search) => {
    const queryParams = {
      type: Constants.QUERY_TYPE.SAVED_CRITERIA_ACTION,
      action: action,
      identifier: {
        filterKey: "id",
        filterValue: id,
      },
      clearDataOnFetch: false,
    };

    if (utils.hasNonEmptyValue(search)) {
      delete search.id;
      queryParams.params = search;
    }

    updateWithDataService(queryParams);
  };

  const handleSelectCriteria = (event, search) => {
    event.stopPropagation();
    closeSavedCriteriaPopper();
    onSetSearch(search);
  };

  const handleDeleteCriteria = (event, search) => {
    event.stopPropagation();
    const id = search.id;
    remove(searches, (d) => d.id === id);
    doSavedCriteriaAction("delete", id, search);
    onDeleteSearch(id);
  };

  const handleUpdateCriteriaProperties = (query) => {
    const params = {
      description: query.description,
      filterConfig: query.filterConfig,
      instrumentTypeId: query.instrumentType,
    };

    doSavedCriteriaAction("put", query.id, params);
  };

  const handleUpdateCriteria = () => {
    const updatedQuery = utils.createCompBondQueryForSave(query);

    const params = {
      description: updatedQuery.description,
      filterConfig: JSON.stringify(updatedQuery),
      instrumentTypeId: compareBond.instrumentType.id,
    };

    doSavedCriteriaAction("put", updatedQuery.id, params);
    closeSavePopper();
  };

  const handleAddSearch = ({ description }) => {
    const updatedQuery = utils.createCompBondQueryForSave(query);

    delete updatedQuery.id;
    updatedQuery.description = description;

    const params = {
      description: description,
      filterConfig: JSON.stringify(updatedQuery),
      instrumentTypeId: compareBond.instrumentType.id,
    };

    const queryParams = {
      type: Constants.QUERY_TYPE.SAVED_CRITERIA_ACTION,
      action: "post",
      clearDataOnFetch: false,
      params: params,
    };

    updateWithDataService(queryParams);
    closeSavePopper();
  };

  const handleInputChange = (event) => {
    const filtered = [];

    let value = event.target.value;
    setSearchValue(value);
    value = `${deburr(value.trim()).toLowerCase()}`;

    if (value !== "") {
      searches.forEach((item, i) => {
        if (item.description.toLowerCase().startsWith(value)) {
          filtered.push(item);
        }
      });
    }

    setFilteredOptions(filtered);
  };

  const instrumentId = compareBond
    ? compareBond.instrumentType.id === 128 || compareBond.instrumentType.id === 129
      ? [128, 129]
      : [compareBond.instrumentType.id]
    : [0];

  const getFilteredSearches = () => {
    const filteredSearches = [];
    const searchData = utils.hasNonEmptyValue(searchValue) ? filteredOptions : searches;
    let searchCmp, searchNames;

    const sortedData = sortBy(searchData, "description");
    sortedData.forEach((search) => {
      if (instrumentId.includes(search.instrumentType)) {
        searchNames = searches
          .filter((s) => s.id !== search.id && instrumentId.includes(s.instrumentType))
          .map((s) => s.description);
        searchCmp = (
          <Search
            key={search.description}
            search={search}
            validationNames={searchNames}
            onSelect={handleSelectCriteria}
            onEditStart={handleEditStart}
            onDelete={handleDeleteCriteria}
            onUpdate={handleUpdateCriteriaProperties}
          />
        );
        filteredSearches.push(searchCmp);
      }
    });

    return filteredSearches;
  };

  const savedSearchButtonLabel =
    utils.hasNonEmptyValue(query) && utils.hasNonEmptyValue(query.description)
      ? `${query.description}${utils.isDirtyCompBondSavedQuery(query, searches) ? " (EDITED)" : ""}`
      : `--- ${Messages.LABEL.SAVED_SEARCH_OPTIONS} ${
          Messages.LABEL[utils.getInventoryKeyFromType(compareBond?.instrumentCategory)]
        } ${Messages.LABEL.SAVED_SEARCH} ---`.toUpperCase();

  return (
    <div className={classes.root}>
      <AltThemedComponent>
        <SavedCriteriaButton
          disabled={!utils.hasNonEmptyValue(getFilteredSearches())}
          size="small"
          color="primary"
          endIcon={<ArrowDropDownIcon />}
          onClick={openSavedCriteria}
        >
          <div
            style={{
              minWidth: 225,
              textAlign: "left",
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
            }}
          >
            {savedSearchButtonLabel}
          </div>
        </SavedCriteriaButton>

        <SavedCriteriaOutlineButton
          style={{ marginLeft: "auto" }}
          size="small"
          variant="outlined"
          color="primary"
          onClick={openSavePopper}
        >
          {Messages.LABEL.SAVE}
        </SavedCriteriaOutlineButton>
      </AltThemedComponent>
      <Popper
        open={Boolean(savedCriteriaAnchorEl) && utils.hasNonEmptyValue(getFilteredSearches())}
        placement="bottom"
        ref={savedCriteriaRef}
        anchorEl={savedCriteriaAnchorEl}
        className={classes.transitionPopper}
        transition
      >
        {({ TransitionProps }) => (
          <Fade {...TransitionProps}>
            <div>
              <ClickAwayListener onClickAway={closeSavedCriteriaPopper}>
                <PopperPaper onClick={(e) => e.stopPropagation()} classes={{ root: classes.paper }}>
                  {getFilteredSearches().length >= 10 && (
                    <div className={classes.toolbarSection}>
                      <TextInput
                        value={searchValue}
                        maxLength={100}
                        classes={{ root: classes.searchInputWrapper }}
                        onChange={handleInputChange}
                        endAdornment={<SearchAdornment />}
                        autoFocus={true}
                      />
                    </div>
                  )}
                  <div className={classes.searchesWrapper}>
                    <div className={classes.categorizedSearches}>{getFilteredSearches()}</div>
                  </div>
                </PopperPaper>
              </ClickAwayListener>
            </div>
          </Fade>
        )}
      </Popper>
      <Popper
        open={Boolean(saveAnchorEl)}
        placement="bottom-end"
        anchorEl={saveAnchorEl}
        className={classes.transitionPopper}
        transition
      >
        {({ TransitionProps }) => (
          <Fade {...TransitionProps}>
            <React.Fragment>
              <ClickAwayListener onClickAway={closeSavePopper}>
                <PopperPaper onClick={(e) => e.stopPropagation()} classes={{ root: classes.paper }}>
                  <Save
                    searches={searches.filter((s) => instrumentId.includes(s.instrumentType))}
                    query={query}
                    bond={compareBond}
                    onUpdate={handleUpdateCriteria}
                    onAdd={handleAddSearch}
                  />
                </PopperPaper>
              </ClickAwayListener>
            </React.Fragment>
          </Fade>
        )}
      </Popper>
    </div>
  );
};

SavedCriteria.propTypes = {
  bond: PropTypes.object,
  query: PropTypes.object,
  searches: PropTypes.arrayOf(Search.SearchDefinition),
  onSetSearch: PropTypes.func.isRequired,
  onDeleteSearch: PropTypes.func.isRequired,
  onSearchesUpdated: PropTypes.func.isRequired,
};

export default SavedCriteria;
