import React from "react";
import { setGlobal } from "reactn";
import { sortBy } from "lodash";

import { useMessages } from "providers/BrandingProvider";

import * as DataConfig from "config/dataconfig";
import * as Constants from "commons/constants";
import * as utils from "commons/utils";
import { useViewStateDispatcher } from "providers/ViewStateProvider";
import { Div, MainPaper } from "components/containers/Containers";
import QBTypography from "components/QBTypography";
import NewsAdmin from "components/modals/NewsAdmin";
import { useDataService } from "services/DataService";
import { QUERY_TYPE } from "commons/constants";
import DocumentDownload from "components/DocumentDownload";

import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  paragraph: {
    marginTop: 20,
    marginBottom: 40,
  },
}));

export const NewsView = () => {
  const classes = useStyles();
  const dispatchViewStateChange = useViewStateDispatcher();
  const Messages = useMessages();

  const [fetchState, fetchFromDataService] = useDataService();
  const [newsItems, setNewsItems] = React.useState([]);

  React.useEffect(() => {
    dispatchViewStateChange({
      state: { activeView: Constants.VIEW.NEWS },
      view: Constants.VIEW.NEWS,
    });
    queryNews();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    if (!fetchState.isInit && !fetchState.isLoading && !fetchState.isError) {
      if (fetchState.data) {
        const newsItems = fetchState.data.reduce((acc, item) => {
          if (item.id === 0) return acc;

          const message = JSON.parse(item.message);
          return acc.concat({ ...item, ...message });
        }, []);

        setNewsItems(sortBy(newsItems, "order"));
        setGlobal({ newsItems });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchState]);

  const queryNews = () => {
    const queryParams = {
      type: QUERY_TYPE.NEWS_ACTION,
      action: "get",
      clearDataOnFetch: false,
    };
    fetchFromDataService(queryParams);
  };

  const handleAdminModalClose = () => {
    queryNews();
  };

  return (
    <>
      <Div>
        <MainPaper style={{ padding: 0 }}>
          <Div style={{ overflow: "auto", padding: "10px 20px" }}>
            <NewsAdmin onClose={handleAdminModalClose} newsItems={newsItems} />
            {newsItems?.length === 0 && (
              <QBTypography className={classes.paragraph}>{Messages.MESSAGE.NO_MESSAGES}</QBTypography>
            )}
            {newsItems.map((item) => {
              return <NewsDisplay key={item.id} item={item} />;
            })}
          </Div>
        </MainPaper>
      </Div>
    </>
  );
};

const NewsDisplay = ({ item }) => {
  const classes = useStyles();

  return (
    <div className={classes.paragraph}>
      <QBTypography variant="h3" color="primary" gutterBottom>
        {item.header}
      </QBTypography>
      <QBTypography>{item.itemText}</QBTypography>
      <QBTypography component="div">
        {item.links.map((link) => {
          if (link.url) {
            let url = link.url;
            if (!url.startsWith("http")) {
              url = `http://${link.url}`;
            }
            return (
              <div key={link.id} style={{ marginTop: 5 }}>
                {utils.formatHyperlinkButton(url, link.text ?? link.url)}
              </div>
            );
          }
          if (link.file) {
            return (
              <DocumentDownload
                key={link.id}
                style={{ padding: 0, marginTop: 5 }}
                variant="link"
                url={`${DataConfig.RESOURCE_DOCUMENTS_ENDPOINT}files/${link.file.id}/download`}
                title={link.text ?? link.file.fileName}
                fileName={link.file.fileName}
              />
            );
          }
          return null;
        })}
      </QBTypography>
    </div>
  );
};

export default NewsView;
