import React from "react";
import clsx from "clsx";
import { get } from "lodash";
import PropTypes from "prop-types";

import * as DataOptions from "commons/dataOptions";
import * as utils from "commons/utils";
import * as Constants from "commons/constants";
import { useOptionsService } from "services/OptionsService";

import Button from "@material-ui/core/Button";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import { alpha } from "@material-ui/core/styles/colorManipulator";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: 0,
  },
  wrapper: {
    width: "100%",
    display: "flex",
    justifyContent: "inherit",
    alignItems: "center",
  },
  label: {
    textTransform: "uppercase",
    fontSize: "0.7rem",
    marginRight: 10,
    whiteSpace: "nowrap",
  },
  togglebuttonlabel: {
    minWidth: 200,
  },
  switchRoot: {
    marginLeft: 0,
  },
  togglebutton: {
    letterSpacing: "0.12em",
    fontSize: "0.6rem",
    lineHeight: 1,
    padding: "6px 9px",
    color: theme.palette.text.unselected.secondary,
    backgroundColor: alpha(theme.palette.background.button.secondary, 0.1),
    "&.Mui-disabled": {
      color: theme.palette.secondary.main,
      backgroundColor: alpha(theme.palette.background.button.secondary, 0.3),
    },
  },
  navigation: {
    whiteSpace: "nowrap",
    letterSpacing: "0.12em",
    padding: "5px",
    fontSize: "0.6rem",
    lineHeight: 1,
    color: theme.palette.secondary.main,
    backgroundColor: theme.palette.background.paperButton,
    borderColor: theme.palette.border.secondary,
    minWidth: 50,
    height: 20,
    "&.Mui-disabled": {
      backgroundColor: theme.palette.background.button.secondary,
      color: theme.palette.text.selected.secondary,
      borderColor: theme.palette.border.secondary,
    },
  },
}));

const ToggleButtonFilter = (props) => {
  const classes = useStyles();

  const {
    filter,
    filterKey,
    filterType = Constants.FILTER_TYPE.TOGGLE,
    optionsKey,
    label,
    onFilterChange,
    variant = "filter",
    ButtonGroupProps,
  } = props;
  const isNavigation = variant === "navigation";
  const color = isNavigation ? "secondary" : "default";
  const value = get(filter, "filterValue", "");

  const [options, setOptionKey] = useOptionsService();

  React.useEffect(() => {
    setOptionKey(optionsKey);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [optionsKey]);

  const applyFilter = (value) => {
    onFilterChange({ filterKey: filterKey, filterValue: value, filterType: filterType });
  };

  let buttons = options.map((option, i) => {
    const disabled =
      value.toString() === option.value.toString() || (!utils.hasNonEmptyValue(value) && i === 0);
    return (
      <Button key={option.value} disabled={disabled} onClick={(event) => applyFilter(option.value)}>
        {option.label}
      </Button>
    );
  });

  return (
    <div className={classes.wrapper}>
      <span className={clsx(classes.label, { [classes.togglebuttonlabel]: !isNavigation })}>{label}</span>
      {options.length === 1 && (
        <FormControlLabel
          className={classes.switchRoot}
          control={
            <Switch
              size="small"
              checked={value === options[0].value.toString()}
              onChange={(event) => applyFilter(event.currentTarget.checked ? options[0].value : null)}
              value={options[0].value.toString()}
            />
          }
        />
      )}
      {options.length > 1 && (
        <ButtonGroup
          classes={{
            root: classes.root,
            grouped: clsx({
              [classes.navigation]: isNavigation,
              [classes.togglebutton]: !isNavigation,
            }),
          }}
          variant="outlined"
          color={color}
          size="small"
          {...ButtonGroupProps}
        >
          {buttons}
        </ButtonGroup>
      )}
    </div>
  );
};

ToggleButtonFilter.FilterDefinition = {
  filterKey: PropTypes.string,
  filterValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  filterType: PropTypes.oneOf(Object.values(Constants.FILTER_TYPE)),
};

ToggleButtonFilter.propTypes = {
  filter: PropTypes.shape(ToggleButtonFilter.FilterDefinition),
  filterKey: PropTypes.string.isRequired,
  optionsKey: PropTypes.oneOf(Object.keys(DataOptions.OPTIONS)).isRequired,
  label: PropTypes.string,
  onFilterChange: PropTypes.func.isRequired,
  variant: PropTypes.oneOf(["filter", "navigation"]),
};

export default ToggleButtonFilter;
