import * as Constants from "commons/constants";
import * as DataConfig from "config/dataconfig";

const DataTableConfig = (Messages) => {
  const BASE_COLUMNS = {
    INTEREST: {
      column: {
        header: "Accrued",
        accessor: "accruedInterest",
        width: 90,
      },
      datacell: {
        className: "numericCell",
      },
      footer: {
        type: Constants.CELL_TYPE.TOTAL,
        className: "totalCell",
        minDecimal: 2,
      },
    },
  };

  const COLUMNS = {
    MO_3: {
      column: {
        accessor: "3m.value",
        width: 50,
        maxWidth: 100,
      },
      datacell: {
        type: Constants.CELL_TYPE.CLICKABLE,
      },
    },
    MO_6: {
      column: {
        accessor: "6m.value",
        width: 50,
        maxWidth: 100,
      },
      datacell: {
        type: Constants.CELL_TYPE.CLICKABLE,
      },
    },
    MO_9: {
      column: {
        accessor: "9m.value",
        width: 50,
        maxWidth: 100,
      },
      datacell: {
        type: Constants.CELL_TYPE.CLICKABLE,
      },
    },
    YR_1: {
      column: {
        accessor: "1y.value",
        width: 50,
        maxWidth: 100,
      },
      datacell: {
        type: Constants.CELL_TYPE.CLICKABLE,
      },
    },
    YR_2: {
      column: {
        accessor: "2y.value",
        width: 50,
        maxWidth: 100,
      },
      datacell: {
        type: Constants.CELL_TYPE.CLICKABLE,
      },
    },
    YR_3: {
      column: {
        accessor: "3y.value",
        width: 50,
        maxWidth: 100,
      },
      datacell: {
        type: Constants.CELL_TYPE.CLICKABLE,
      },
    },
    YR_4: {
      column: {
        accessor: "4y.value",
        width: 50,
        maxWidth: 100,
      },
      datacell: {
        type: Constants.CELL_TYPE.CLICKABLE,
      },
    },
    YR_5: {
      column: {
        accessor: "5y.value",
        width: 50,
        maxWidth: 100,
      },
      datacell: {
        type: Constants.CELL_TYPE.CLICKABLE,
      },
    },
    YR_7: {
      column: {
        accessor: "7y.value",
        width: 50,
        maxWidth: 100,
      },
      datacell: {
        type: Constants.CELL_TYPE.CLICKABLE,
      },
    },
    YR_10: {
      column: {
        accessor: "10y.value",
        width: 50,
        maxWidth: 100,
      },
      datacell: {
        type: Constants.CELL_TYPE.CLICKABLE,
      },
    },
    YR_20: {
      column: {
        accessor: "20y.value",
        width: 50,
        maxWidth: 100,
      },
      datacell: {
        type: Constants.CELL_TYPE.CLICKABLE,
      },
    },
    YR_30: {
      column: {
        accessor: "30y.value",
        width: 50,
        maxWidth: 100,
      },
      datacell: {
        type: Constants.CELL_TYPE.CLICKABLE,
      },
    },
    ADJ_FDIC: {
      column: {
        accessor: "isOverFDICLimit",
        width: 45,
      },
      datacell: {
        type: Constants.CELL_TYPE.LINK_BUTTON,
        label: "Adjust",
      },
    },
    ADJ_MBS: {
      column: {
        accessor: "requiredPartialQty",
        width: 45,
      },
      datacell: {
        type: Constants.CELL_TYPE.LINK_BUTTON,
        label: "Adjust",
      },
    },
    ACCOUNT: {
      column: {
        accessor: "brokeredClearingAccount.account",
        width: 150,
      },
      datacell: {
        accessor: "account",
      },
    },
    ACTION: {
      column: {
        accessor: "action",
        width: 65,
      },
      datacell: {
        tooltip: {
          title: "Price Quotes remain in Order History for 2 days",
          valueFilter: ["Quote"],
        },
      },
    },
    ACTION_HISTORY: {
      column: {
        accessor: "orderHistoryTypeAction",
        width: 65,
      },
      /*
      KLT: No longer showing Price Quotes in Order History
      datacell: {
        tooltip: {
          title: "Price Quotes remain in Order History for 2 days",
          valueFilter: ["Quote"],
        },
      },
      */
    },
    ACTION_STATUS: {
      column: {
        accessor: "action",
        width: 65,
      },
    },
    ACTIVE_BIDS: {
      column: {
        accessor: "biddingEndTime",
        sortable: false,
        width: 75,
        header: "Time to Delivery",
      },
      datacell: {
        type: Constants.CELL_TYPE.ACTIVE_BIDS,
        className: "controlCell",
      },
    },
    BOND_TYPE: {
      column: {
        accessor: "bondType.value",
        width: 220,
        maxWidth: 600,
      },
      datacell: {
        type: Constants.CELL_TYPE.RATES_ROW_HEADER,
      },
    },
    BUY: {
      column: {
        accessor: "brokeredsecurityid",
        sortable: false,
        width: 50,
        type: Constants.CELL_TYPE.FILTER_TOGGLE,
      },
      datacell: {
        type: Constants.CELL_TYPE.BUY,
        className: "controlCell",
      },
    },
    BUY_COMPARABLE: {
      column: {
        accessor: "brokeredsecurityid",
        sortable: false,
        width: 50,
        type: Constants.CELL_TYPE.ADVANCED_CRITERIA,
      },
      datacell: {
        type: Constants.CELL_TYPE.BUY,
        className: "controlCell",
      },
    },
    BUYER_SELLER: {
      column: {
        accessor: "buyerSeller",
        header: "I am",
        width: 110,
      },
      datacell: {
        type: Constants.CELL_TYPE.TOGGLE_BUTTONS,
        editClassName: "controlCell",
      },
    },
    CALL_DATE: {
      column: {
        accessor: "callDate",
        width: 85,
        nofilterWidth: 80,
        className: "dateHeader",
      },
      datacell: {
        className: "dateCell",
      },
    },
    CARRY_TO_NEXT_DAY: {
      column: {
        accessor: "carryToNextDay",
        header: "Carry",
        width: 70,
      },
      datacell: {
        type: Constants.CELL_TYPE.CHECKBOX,
        editClassName: "controlCell",
      },
    },
    COUPON: {
      column: {
        accessor: "interestRate",
        width: 75,
        nofilterWidth: 68,
      },
      datacell: {
        className: "numericCell",
      },
    },
    CDTYPE: {
      column: {
        accessor: "instrumentType.description",
        width: 120,
      },
    },
    CURRENT_FACE: {
      column: {
        accessor: "currentFace",
        width: 110,
      },
      datacell: {
        className: "numericCell",
      },
    },
    CUSIP: {
      column: {
        accessor: "cusip",
        width: 85,
        exportWidth: 12,
      },
      datacell: {
        className: "cusipCell",
      },
    },
    CUSIP_NOLOOKAHEAD: {
      column: {
        accessor: "cusip",
        width: 85,
      },
      datacell: {
        className: "cusipCell",
      },
      filter: {
        lookahead: false,
      },
    },
    CUSIP_EDIT: {
      column: {
        accessor: "cusip",
        width: 80,
      },
      datacell: {
        type: Constants.CELL_TYPE.EDITABLE,
        editClassName: "editableCell",
        maxLength: 9,
      },
    },
    DUPLICATE: {
      column: {
        accessor: "duplicate",
        width: 4,
      },
      datacell: {
        type: Constants.CELL_TYPE.SHOW_ALERT,
        className: "duplicateCell",
      },
    },
    DELETE: {
      column: {
        accessor: "delete",
        sortable: false,
        width: 20,
        static: true,
      },
      datacell: {
        type: Constants.CELL_TYPE.DELETE,
        className: "controlCell",
      },
    },
    DOCUMENTS: {
      column: {
        accessor: "links",
        width: 75,
        sortable: false,
        exportable: false,
      },
      datacell: {
        type: Constants.CELL_TYPE.DOCUMENTS,
        className: "documentIcons",
      },
    },
    ENTERED_DATE: {
      column: {
        accessor: "enteredDate",
        width: 80,
        className: "dateHeader",
      },
      datacell: {
        className: "dateCell",
      },
      filter: {
        popperPlacement: "bottom-start",
      },
    }, //enteredUser
    ENTERED_USER: {
      column: {
        accessor: "enteredUser.fullName",
        width: 120,
      },
      label: "User",
    },
    CUSTOMERCOMPANY: {
      column: {
        accessor: "companyName",
        width: 200,
      },
      label: "companyName",
    },
    FACTOR: {
      column: {
        accessor: "factor",
        width: 80,
      },
      datacell: {
        className: "numericCell",
      },
    },
    FAVORITE: {
      column: {
        header: "",
        accessor: "id",
        sortable: false,
        width: 30,
      },
      datacell: {
        type: Constants.CELL_TYPE.FAVORITE,
        className: "controlCell",
      },
    },
    FAVORITE_ISSUER: {
      column: {
        header: "",
        accessor: "issuerInventory",
        sortable: false,
        width: 30,
      },
      datacell: {
        type: Constants.CELL_TYPE.FAVORITE_ISSUER,
        className: "controlCell",
      },
    },
    FDIC: {
      column: {
        accessor: "insuranceNumber",
        width: 75,
      },
      datacell: {
        type: Constants.CELL_TYPE.INSURANCE_NUMBER,
        className: "centeredCell",
      },
    },
    FILE: {
      column: {
        accessor: "file",
        width: 75,
        sortable: false,
        exportable: false,
      },
      datacell: {
        type: Constants.CELL_TYPE.FILE,
        className: "documentIcons",
      },
    },
    FILE_NAME: {
      column: {
        accessor: "fileName",
        width: 250,
      },
    },
    FILE_UPLOADED: {
      column: {
        accessor: "fileUploaded",
        width: 150,
      },
    },
    FILE_DESCRIPTION: {
      column: {
        accessor: "fileDescription",
        width: 300,
      },
      datacell: {
        className: "doNotTruncate",
      },
    },
    FILE_UPLOADED_BY: {
      column: {
        accessor: "enteredUserName",
        width: 150,
      },
      label: "User",
    },
    FREQUENCY: {
      column: {
        accessor: "frequency",
        width: 75,
      },
    },
    HIGHBID_PRICE: {
      column: {
        accessor: "highBid.markupPrice",
        width: 80,
      },
      datacell: {
        className: "numericCell",
      },
    },
    HIGHBID_YTW: {
      column: {
        accessor: "highBid.markupYieldToWorst",
        width: 75,
      },
      datacell: {
        className: "numericCell",
      },
    },
    HIGHBID_YTM_YIELD: {
      column: {
        accessor: "highBid.markupYieldToMaturity",
        width: 60,
      },
      datacell: {
        className: "numericCell",
      },
    },
    HOLDING_AMOUNT: {
      column: {
        accessor: "holdingAmount",
        width: 100,
      },
      datacell: {
        className: "numericCell",
      },
      footer: {
        type: Constants.CELL_TYPE.TOTAL,
        className: "totalCell",
        minDecimal: 2,
      },
    },
    HOLDING_ISSUER: {
      column: {
        accessor: "holdingCompany.name",
        width: 200,
      },
      datacell: {
        type: Constants.CELL_TYPE.ISSUER,
      },
    },
    HOLDING_FDIC: {
      column: {
        accessor: "holdingCompany.insuranceNumber",
        width: 75,
      },
      datacell: {
        type: Constants.CELL_TYPE.INSURANCE_NUMBER,
        className: "numericCell",
      },
    },
    PURCHASED_DATE: {
      column: {
        accessor: "purchaseDate",
        width: 80,
        className: "dateHeader",
      },
      datacell: {
        className: "dateCell",
      },
    },
    INSTRUMENT_TYPE: {
      column: {
        accessor: "instrumentType.description",
        width: 115,
        exportWidth: 18,
      },
    },
    INTEREST: BASE_COLUMNS.INTEREST,
    INTEREST_BADGED: {
      ...BASE_COLUMNS.INTEREST,
      column: {
        ...BASE_COLUMNS.INTEREST.column,
        headerbadge: {
          tooltip: "Estimate only - See final amount on trade ticket",
        },
      },
    },
    ISSUER: {
      column: {
        accessor: "issuer",
        width: 95,
        maxWidth: 600,
      },
      datacell: {
        type: Constants.CELL_TYPE.ISSUER,
      },
    },
    ISSUER_SHORT: {
      column: {
        accessor: "issuerShort",
        width: 75,
        maxWidth: 150,
      },
      datacell: {
        type: Constants.CELL_TYPE.ISSUER,
        tooltip: {
          accessor: "issuer",
        },
      },
    },
    ISSUER_NOLOOKAHEAD: {
      column: {
        accessor: "issuer",
        width: 95,
        maxWidth: 600,
      },
      datacell: {
        type: Constants.CELL_TYPE.ISSUER,
      },
      filter: {
        lookahead: false,
      },
    },
    LOAN_TYPE: {
      column: {
        accessor: "termYrOfMbs",
        width: 75,
        maxWidth: 100,
      },
      datacell: {
        className: "centeredCell",
      },
    },
    SEASONING: {
      column: {
        accessor: "mbsSeasoning",
        width: 85,
        maxWidth: 100,
      },
      datacell: {
        className: "centeredCell",
      },
    },
    ISSUER_RESPONSIVE: {
      column: {
        accessor: "issuer",
        width: 90,
        maxWidth: 400,
      },
      datacell: {
        type: Constants.CELL_TYPE.ISSUER_RESPONSIVE,
      },
    },
    MATURITY_DATE: {
      column: {
        accessor: "maturityDate",
        width: 85,
        nofilterWidth: 80,
        className: "dateHeader",
      },
      datacell: {
        className: "dateCell",
      },
    },
    MINIMUM_EDIT: {
      column: {
        accessor: "minimum_edit",
        header: "Minimum Amt",
        width: 75,
      },
      datacell: {
        type: Constants.CELL_TYPE.EDITABLE,
        className: "numericCell",
        editClassName: "editableNumericCell",
      },
    },
    MULTISELECT_ORDER: {
      column: {
        type: Constants.CELL_TYPE.MULTISELECT,
        sortable: false,
        width: 20,
        className: "checkFilterCell",
        accessor: "orderNo",
      },
      datacell: {
        className: "checkCell",
      },
      filter: {
        type: Constants.FILTER_TYPE.CUSTOM,
      },
    },
    ORDER_NUMBER: {
      column: {
        accessor: "orderNo",
        width: 65,
      },
      datacell: {
        className: "numericCell",
      },
    },
    ORIGINAL_FACE: {
      column: {
        accessor: "originalFace",
        width: 110,
      },
      datacell: {
        className: "numericCell",
      },
    },
    ORIGINAL_SIZE: {
      column: {
        accessor: "originalSize",
        header: "Original Size",
        width: 90,
      },
      datacell: {
        type: Constants.CELL_TYPE.EDITABLE,
        placeholder: "$0,000",
        className: "numericCell",
        editClassName: "editableNumericCell",
        maxLength: 12,
      },
    },
    PORTFOLIO: {
      column: {
        accessor: "portfolio",
        width: 100,
      },
    },
    PRICE: {
      column: {
        accessor: "price",
        width: 75,
      },
      datacell: {
        className: "numericCell",
      },
    },
    PRICE_MBS: {
      column: {
        accessor: "price",
        width: 80,
      },
      datacell: {
        className: "numericCell",
      },
    },
    PRICE_EDIT: {
      column: {
        accessor: "price",
        width: 70,
        header: "Price",
      },
      datacell: {
        type: Constants.CELL_TYPE.EDITABLE,
        className: "numericCell",
        editClassName: "editableNumericCell",
      },
    },
    PRICE_PREVIOUS: {
      column: {
        accessor: "price_previous",
        width: 80,
        header: "Previous Price",
      },
      datacell: {
        className: "numericCell",
      },
    },
    PRINCIPAL: {
      column: {
        header: "Principal",
        accessor: "principal",
        width: 100,
      },
      datacell: {
        className: "numericCell",
      },
      footer: {
        type: Constants.CELL_TYPE.TOTAL,
        className: "totalCell",
        minDecimal: 2,
      },
    },
    PRINCIPAL_EDIT: {
      column: {
        accessor: "quantity_edit",
        header: "Principal Amt",
        width: 75,
      },
      datacell: {
        type: Constants.CELL_TYPE.EDITABLE,
        className: "numericCell",
        editClassName: "editableNumericCell",
      },
    },
    PSA_SPEED: {
      column: {
        accessor: "estSpeed",
        width: 50,
        sortable: false,
      },
      datacell: {
        className: "numericCell",
      },
    },
    QUANTITY: {
      column: {
        accessor: "quantity",
        width: 70,
      },
      datacell: {
        className: "numericCell",
      },
    },
    QUANTITY_EDIT: {
      column: {
        accessor: "quantity_edit",
        header: "Qty (000s)",
        width: 75,
      },
      datacell: {
        type: Constants.CELL_TYPE.EDITABLE,
        className: "numericCell",
        editClassName: "editableNumericCell",
      },
    },
    QUANTITY_PREVIOUS: {
      column: {
        accessor: "quantity_previous",
        width: 70,
        header: "Previous Qty",
      },
      datacell: {
        className: "numericCell",
      },
    },
    RATINGS: {
      column: {
        accessor: "combinedRatings.ratingSortRank",
        width: 85,
        nofilterWidth: 70,
      },
      datacell: {
        accessor: "combinedRatings.combinedRating",
        className: "ratingsCell",
      },
    },
    SELL_SIDE_ACTION: {
      column: {
        accessor: "links",
        sortable: false,
        width: 50,
        header: "",
        headerStyle: { textAlign: "right" },
        type: Constants.CELL_TYPE.FILTER_TOGGLE,
      },
      filter: {
        type: Constants.FILTER_TYPE.CLEAR,
      },
      datacell: {
        type: Constants.CELL_TYPE.SELL_SIDE_ACTION_BUTTON,
        className: "controlCell",
      },
    },
    SETTLE_DATE: {
      column: {
        accessor: "settlementDate",
        width: 95,
        className: "dateHeader",
      },
      datacell: {
        className: "dateCell",
      },
    },
    STATE: {
      column: {
        accessor: "state",
        width: 65,
        nofilterWidth: 55,
      },
      datacell: {
        className: "stateCell",
      },
    },
    STATUS: {
      column: {
        accessor: "status.description",
        width: 100,
      },
    },
    STATUS_OFFERS: {
      column: {
        accessor: "status.description",
        width: 100,
      },
      datacell: {
        type: Constants.CELL_TYPE.STATUS,
      },
    },
    TERM: {
      column: {
        accessor: "term",
        width: 75,
      },
    },
    TIER: {
      column: {
        accessor: "tier",
        className: "subheader",
        width: 1,
      },
      datacell: {
        className: "subheader",
        tooltip: {
          accessor: "tierDescription",
          placement: "right",
          color: "secondary",
        },
      },
    },
    TOTAL: {
      column: {
        accessor: "total",
        width: 100,
      },
      datacell: {
        className: "numericCell",
      },
      footer: {
        type: Constants.CELL_TYPE.TOTAL,
        className: "totalCell",
        minDecimal: 2,
      },
    },

    WAL: {
      column: {
        accessor: "weightedAverageLife",
        width: 75,
        nofilterWidth: 65,
      },
      datacell: {
        className: "numericCell",
      },
    },
    YIELD_MBS: {
      column: {
        accessor: "yieldToMaturity",
        header: "Est Yield",
        width: 60,
      },
      datacell: {
        className: "numericCell",
      },
    },
    YIELD_PREVIOUS: {
      column: {
        accessor: "yieldToMaturity_previous",
        width: 60,
        header: "Previous Est Yield",
      },
      datacell: {
        className: "numericCell",
      },
    },
    YTM: {
      column: {
        accessor: "yieldToMaturity",
        width: 75,
        nofilterWidth: 68,
      },
      datacell: {
        className: "numericCell",
      },
    },
    YTM_PREVIOUS: {
      column: {
        accessor: "yieldToMaturity_previous",
        width: 60,
        header: "Previous YTM",
      },
      datacell: {
        className: "numericCell",
      },
    },
    YTM_YIELD: {
      column: {
        accessor: "yieldToMaturity",
        width: 60,
        header: "YTM/ Yield",
      },
      datacell: {
        className: "numericCell",
      },
    },
    YTM_YIELD_EDIT: {
      column: {
        accessor: "yieldToMaturity",
        width: 70,
        header: "YTM/Yield",
      },
      datacell: {
        type: Constants.CELL_TYPE.EDITABLE,
        className: "numericCell",
        editClassName: "editableNumericCell",
      },
    },
    YTW: {
      column: {
        accessor: "yieldToWorst",
        width: 75,
        nofilterWidth: 68,
      },
      datacell: {
        className: "numericCell",
      },
    },
    YTW_EDIT: {
      column: {
        accessor: "yieldToWorst",
        width: 70,
        header: "YTW",
      },
      datacell: {
        type: Constants.CELL_TYPE.EDITABLE,
        className: "numericCell",
        editClassName: "editableNumericCell",
      },
    },
    YTW_PREVIOUS: {
      column: {
        accessor: "yieldToWorst_previous",
        width: 70,
        header: "Previous YTW",
      },
      datacell: {
        className: "numericCell",
      },
    },
    _SPACER: {
      column: {
        header: "",
        width: 8,
        sortable: false,
        exportable: false,
      },
    },
    _EXPANDER: {
      column: {
        expander: true,
        expanderType: "INVENTORY",
        width: 14,
        exportable: false,
      },
    },
    _BIDS_EXPANDER: {
      column: {
        expander: true,
        expanderType: "BIDS",
        width: 30,
        header: "# Bids",
        exportable: false,
      },
    },
  };

  const ADVANCED_FILTER_FEATURES = {
    MUNICIPAL: {
      header: "Features",
      key: "MUNICIPAL",
      type: Constants.FILTER_TYPE.TOGGLEGROUP,
      filters: [
        DataConfig.PROPERTY.preRefunded,
        DataConfig.PROPERTY.makeWholeCall,
        DataConfig.PROPERTY.couponType.description,
      ],
    },
    MUNICIPAL_TAX: {
      header: "Tax Status",
      key: "MUNICIPAL_TAX",
      type: Constants.FILTER_TYPE.TAXSTATUS_TOGGLEGROUP,
      filters: [
        DataConfig.PROPERTY.generalMarket,
        DataConfig.PROPERTY.bankQualified,
        DataConfig.PROPERTY.taxable,
        DataConfig.PROPERTY.subjectToAMT,
      ],
    },
    MUNICIPAL_TAX_COMPBOND: {
      header: "Tax Status",
      key: "MUNICIPAL_TAX",
      type: Constants.FILTER_TYPE.TAXSTATUS_TOGGLEGROUP,
      filters: [
        DataConfig.PROPERTY.generalMarket,
        DataConfig.PROPERTY.bankQualified,
        DataConfig.PROPERTY.taxable,
      ],
    },
    AGENCY: {
      header: "Features",
      key: "AGENCY",
      type: Constants.FILTER_TYPE.TOGGLEGROUP,
      filters: [DataConfig.PROPERTY.couponType.description],
    },
  };

  const DEFAULT_TABLE = {
    COMPARABLE: {
      filterable: false,
      sortable: false,
      showPagination: false,
      defaultPageSize: DataConfig.DEFAULT_PAGE_SIZE,
      maxHeight: "100%",
      hideHeaders: false,
      noDataText: Messages.MESSAGE.NODATA.BONDS_NOT_FOUND_COMPARABLE,
      useFilterWidth: true,
      prependedColumns: [COLUMNS.TIER, COLUMNS.BUY_COMPARABLE],
    },
    FAVORITES: {
      filterable: true,
      sortable: true,
      showPagination: true,
      defaultPageSize: DataConfig.DEFAULT_PAGE_SIZE,
      maxHeight: "calc(100% - 30px)",
      noDataText: Messages.MESSAGE.NODATA.NO_FAVORITES_TYPE,
      defaultSort: [{ id: "inInventory", desc: true }],
      appendedColumns: [COLUMNS.ENTERED_USER],
      prependedColumns: [COLUMNS.FAVORITE, COLUMNS.BUY],
    },
    ISSUER_TRACKER: {
      filterable: true,
      sortable: true,
      showPagination: true,
      defaultPageSize: DataConfig.DEFAULT_PAGE_SIZE,
      maxHeight: "calc(100% - 30px)",
      noDataText: Messages.MESSAGE.NODATA.NO_FAVORITE_ISSUERS_TYPE,
      appendedColumns: [COLUMNS.ENTERED_USER],
      prependedColumns: [COLUMNS.FAVORITE_ISSUER, COLUMNS.BUY],
      subTypeFilterKey: "instrumentCategory",
      defaultSort: [{ id: "issuer", desc: false }],
      defaultFilter: { issuers: [] },
    },
    HOLDINGS: {
      filterable: false,
      sortable: false,
      showPagination: false,
      defaultPageSize: 100,
      showFooter: true,
      maxHeight: "97vh",
      defaultSort: [{ id: "enteredDate", desc: false }],
      noDataText: Messages.MESSAGE.NODATA.HOLDINGS_NOT_FOUND,
      columns: [
        COLUMNS.HOLDING_ISSUER,
        COLUMNS.HOLDING_FDIC,
        COLUMNS.PURCHASED_DATE,
        COLUMNS.MATURITY_DATE,
        COLUMNS.HOLDING_AMOUNT,
        COLUMNS.PORTFOLIO,
      ],
    },
    FAVORITE_IMPORT: {
      filterable: false,
      sortable: false,
      showPagination: false,
      defaultPageSize: DataConfig.DEFAULT_PAGE_SIZE,
      maxHeight: "70vh",
      noDataText: Messages.MESSAGE.NODATA.NO_BONDS_IMPORT,
      columns: [
        COLUMNS.DELETE,
        COLUMNS.DUPLICATE,
        COLUMNS.CUSIP,
        COLUMNS.ISSUER_RESPONSIVE,
        COLUMNS.INSTRUMENT_TYPE,
      ],
    },
    INVENTORY: {
      filterable: true,
      sortable: true,
      showPagination: true,
      defaultPageSize: DataConfig.DEFAULT_PAGE_SIZE,
      maxHeight: "calc(100% - 30px)",
      noDataText: Messages.MESSAGE.NODATA.BONDS_NOT_FOUND,
      noFilteredDataText: Messages.MESSAGE.NODATA.NO_BONDS_SEARCH,
      subTypeFilterKey: "instrumentCategory",
      defaultSort: [{ id: "maturityDate", desc: false }],
      prependedColumns: [COLUMNS.BUY],
    },
    SELL: {
      filterable: true,
      sortable: true,
      showPagination: true,
      defaultPageSize: DataConfig.DEFAULT_PAGE_SIZE,
      maxHeight: "100%",
      noDataText: Messages.MESSAGE.NODATA.NO_ORDER,
      noFilteredDataText: Messages.MESSAGE.NODATA.NO_ORDER_SEARCH,
      defaultSort: [{ id: "enteredDate", desc: true }],
      subTypeFilterKey: "tradeType",
    },
    ALL_BY_ISSUER: {
      filterable: false,
      sortable: true,
      showPagination: true,
      defaultPageSize: DataConfig.DEFAULT_PAGE_SIZE,
      maxHeight: "calc(97vh - 90px)",
      noDataText: Messages.MESSAGE.NODATA.BONDS_NOT_FOUND,
      defaultSort: [{ id: "maturityDate", desc: false }],
      prependedColumns: [COLUMNS.BUY],
    },
    DEPTH_OF_BOOK: {
      filterable: false,
      sortable: true,
      showPagination: true,
      expandable: false,
      defaultPageSize: DataConfig.DEFAULT_PAGE_SIZE,
      maxHeight: "calc(97vh - 90px)",
      noDataText: Messages.MESSAGE.NODATA.BONDS_NOT_FOUND,
      defaultSort: [
        { id: "price", desc: false },
        { id: "quantity", desc: true },
      ],
      prependedColumns: [COLUMNS.BUY],
    },
    DOCUMENTS: {
      filterable: false,
      sortable: true,
      showPagination: true,
      expandable: false,
      defaultPageSize: DataConfig.DEFAULT_PAGE_SIZE,
      maxHeight: "calc(100vh - 200px)",
      noDataText: Messages.MESSAGE.NODATA.DOCUMENTS,
      defaultSort: [{ id: "fileUploaded", desc: true }],
      columns: [
        COLUMNS.DELETE,
        COLUMNS.FILE,
        COLUMNS.FILE_NAME,
        COLUMNS.FILE_UPLOADED,
        COLUMNS.FILE_DESCRIPTION,
      ],
    },
    TRADE_DOCUMENTS: {
      filterable: false,
      sortable: true,
      showPagination: false,
      expandable: false,
      defaultPageSize: 100,
      maxHeight: "calc(100vh - 200px)",
      noDataText: Messages.MESSAGE.NODATA.DOCUMENTS,
      defaultSort: [{ id: "fileUploaded", desc: true }],
      columns: [
        COLUMNS.DELETE,
        COLUMNS.FILE,
        COLUMNS.FILE_NAME,
        COLUMNS.FILE_UPLOADED,
        COLUMNS.FILE_UPLOADED_BY,
        COLUMNS.FILE_DESCRIPTION,
      ],
    },
    HISTORY: {
      filterable: true,
      sortable: true,
      exportable: true,
      showPagination: true,
      defaultPageSize: DataConfig.DEFAULT_PAGE_SIZE,
      maxHeight: "calc(100% - 25px)",
      noDataText: Messages.MESSAGE.NODATA.NO_ORDER_HISTORY,
      noFilteredDataText: Messages.MESSAGE.NODATA.NO_ORDER_SEARCH,
      defaultSort: [{ id: "enteredDate", desc: true }],
      showClearFilter: true,
      columns: [
        COLUMNS.DOCUMENTS,
        COLUMNS.ENTERED_DATE,
        COLUMNS.STATUS,
        COLUMNS.ACTION_HISTORY,
        COLUMNS.CUSIP,
        COLUMNS.ISSUER,
        COLUMNS.INSTRUMENT_TYPE,
        COLUMNS.QUANTITY,
        COLUMNS.CURRENT_FACE,
        COLUMNS.PRICE_MBS,
        COLUMNS.PRINCIPAL,
        COLUMNS.INTEREST,
        COLUMNS.TOTAL,
        COLUMNS.YTW,
        COLUMNS.YTM,
        { ...COLUMNS.SETTLE_DATE, filter: { ...COLUMNS.SETTLE_DATE.filter, disablePast: false } },
        { ...COLUMNS.MATURITY_DATE, filter: { ...COLUMNS.MATURITY_DATE.filter, disablePast: false } },
        { ...COLUMNS.CALL_DATE, filter: { ...COLUMNS.CALL_DATE.filter, disablePast: false } },
        COLUMNS.ORDER_NUMBER,
        COLUMNS.ENTERED_USER,
        COLUMNS.ACCOUNT,
        COLUMNS._SPACER,
      ],
      alternateViewColumns: [
        COLUMNS.DOCUMENTS,
        COLUMNS.ENTERED_DATE,
        COLUMNS.STATUS,
        COLUMNS.ACTION_HISTORY,
        COLUMNS.CUSIP,
        COLUMNS.ISSUER,
        COLUMNS.INSTRUMENT_TYPE,
        COLUMNS.QUANTITY,
        COLUMNS.CURRENT_FACE,
        COLUMNS.PRICE_MBS,
        COLUMNS.PRINCIPAL,
        COLUMNS.INTEREST,
        COLUMNS.TOTAL,
        COLUMNS.YTW,
        COLUMNS.YTM,
        { ...COLUMNS.SETTLE_DATE, filter: { ...COLUMNS.SETTLE_DATE.filter, disablePast: false } },
        { ...COLUMNS.MATURITY_DATE, filter: { ...COLUMNS.MATURITY_DATE.filter, disablePast: false } },
        { ...COLUMNS.CALL_DATE, filter: { ...COLUMNS.CALL_DATE.filter, disablePast: false } },
        COLUMNS.ORDER_NUMBER,
        COLUMNS.ENTERED_USER,
        COLUMNS.ACCOUNT,
        COLUMNS.CUSTOMERCOMPANY,
        COLUMNS._SPACER,
      ],
    },
    RATES: {
      filterable: false,
      sortable: false,
      showPagination: false,
      maxHeight: "100%",
      loadingTop: 20,
      noDataText: Messages.MESSAGE.NODATA.RATES_UNAVALIABLE,
      subTypeFilterKey: "boardType",
      columns: [
        COLUMNS.BOND_TYPE,
        COLUMNS.MO_3,
        COLUMNS.MO_6,
        COLUMNS.MO_9,
        COLUMNS.YR_1,
        COLUMNS.YR_2,
        COLUMNS.YR_3,
        COLUMNS.YR_4,
        COLUMNS.YR_5,
        COLUMNS.YR_7,
        COLUMNS.YR_10,
        COLUMNS.YR_20,
        COLUMNS.YR_30,
      ],
    },
    REQUEST_TICKET: {
      filterable: false,
      sortable: false,
      showPagination: false,
      showFooter: true,
      defaultPageSize: DataConfig.DEFAULT_PAGE_SIZE,
    },
    REQUEST_TICKET_FAILURES: {
      filterable: false,
      sortable: false,
      showPagination: false,
      defaultPageSize: DataConfig.DEFAULT_PAGE_SIZE,
    },
    REVIEW_PRICE_QUOTE: {
      filterable: false,
      sortable: false,
      showPagination: false,
      defaultPageSize: DataConfig.DEFAULT_PAGE_SIZE,
      columns: [
        COLUMNS.ENTERED_DATE,
        COLUMNS.QUANTITY,
        COLUMNS.CURRENT_FACE,
        COLUMNS.CUSIP,
        COLUMNS.ISSUER,
        COLUMNS.INSTRUMENT_TYPE,
        COLUMNS.PRICE_MBS,
        COLUMNS.YTW,
        COLUMNS.YTM_YIELD,
      ],
    },
    STATUS: {
      filterable: false,
      sortable: false,
      showPagination: true,
      defaultPageSize: 5,
      minRows: 5,
      maxHeight: "100%",
      defaultSort: [{ id: "enteredDate", desc: true }],
      noDataText: Messages.MESSAGE.NODATA.NO_ORDER_STATUS,
      columns: [
        COLUMNS.ENTERED_DATE,
        COLUMNS.STATUS,
        COLUMNS.ACTION_STATUS,
        COLUMNS.CUSIP,
        COLUMNS.ISSUER,
        COLUMNS.QUANTITY,
        COLUMNS.PRICE_MBS,
        COLUMNS.YTW,
        COLUMNS.YTM,
        COLUMNS.ORDER_NUMBER,
        COLUMNS.ENTERED_USER,
        COLUMNS._SPACER,
      ],
    },
    TRADE_TICKET: {
      filterable: false,
      sortable: false,
      showPagination: false,
      showFooter: true,
      defaultPageSize: DataConfig.DEFAULT_PAGE_SIZE,
      noDataText: Messages.MESSAGE.NODATA.NO_BONDS_TICKET,
    },
    TRADE_TICKET_CONFLICT: {
      filterable: false,
      sortable: false,
      showPagination: false,
      pruneEmptyColumns: true,
      defaultPageSize: DataConfig.DEFAULT_PAGE_SIZE,
      maxHeight: "95vh",
    },
    TRADE_TICKET_FAILURES: {
      filterable: false,
      sortable: false,
      showPagination: false,
      pruneEmptyColumns: true,
      defaultPageSize: DataConfig.DEFAULT_PAGE_SIZE,
      maxHeight: "95vh",
    },
  };

  const SUBTYPED_TABLE = {
    SELL: {
      PRICE_QUOTES: {
        noDataText: Messages.MESSAGE.NODATA.NO_BONDS_SELL,
        columns: [
          COLUMNS.SELL_SIDE_ACTION,
          COLUMNS.ENTERED_DATE,
          COLUMNS.ENTERED_USER,
          COLUMNS.STATUS,
          COLUMNS.QUANTITY,
          COLUMNS.CURRENT_FACE,
          COLUMNS.CUSIP_NOLOOKAHEAD,
          COLUMNS.ISSUER_NOLOOKAHEAD,
          COLUMNS.INSTRUMENT_TYPE,
          COLUMNS.PRICE_MBS,
          COLUMNS.YTW,
          COLUMNS.YTM_YIELD,
          COLUMNS._SPACER,
        ],
      },
      BIDS_RFQ: {
        noDataText: Messages.MESSAGE.NODATA.NO_BONDS_SELL,
        columns: [
          COLUMNS.SELL_SIDE_ACTION,
          COLUMNS.ACTIVE_BIDS,
          COLUMNS._BIDS_EXPANDER,
          COLUMNS.ENTERED_DATE,
          COLUMNS.ENTERED_USER,
          COLUMNS.STATUS,
          COLUMNS.QUANTITY,
          COLUMNS.CURRENT_FACE,
          COLUMNS.CUSIP_NOLOOKAHEAD,
          COLUMNS.ISSUER_NOLOOKAHEAD,
          COLUMNS.INSTRUMENT_TYPE,
          COLUMNS.HIGHBID_PRICE,
          COLUMNS.HIGHBID_YTW,
          COLUMNS.HIGHBID_YTM_YIELD,
          COLUMNS.ACCOUNT,
          COLUMNS._SPACER,
        ],
      },
      BONDS_FOR_SALE: {
        noDataText: Messages.MESSAGE.NODATA.NO_BONDS_SELL,
        columns: [
          COLUMNS.SELL_SIDE_ACTION,
          COLUMNS.ENTERED_DATE,
          COLUMNS.ENTERED_USER,
          COLUMNS.STATUS_OFFERS,
          COLUMNS.QUANTITY,
          COLUMNS.CURRENT_FACE,
          COLUMNS.CUSIP_NOLOOKAHEAD,
          COLUMNS.ISSUER_NOLOOKAHEAD,
          COLUMNS.INSTRUMENT_TYPE,
          COLUMNS.PRICE_MBS,
          COLUMNS.YTW,
          COLUMNS.YTM_YIELD,
          COLUMNS.CARRY_TO_NEXT_DAY,
          COLUMNS.ACCOUNT,
          COLUMNS._SPACER,
        ],
        alternateViewColumns: [
          COLUMNS.MULTISELECT_ORDER,
          COLUMNS.SELL_SIDE_ACTION,
          COLUMNS.ENTERED_DATE,
          COLUMNS.ENTERED_USER,
          COLUMNS.STATUS_OFFERS,
          COLUMNS.QUANTITY,
          COLUMNS.CURRENT_FACE,
          COLUMNS.CUSIP_NOLOOKAHEAD,
          COLUMNS.ISSUER_NOLOOKAHEAD,
          COLUMNS.INSTRUMENT_TYPE,
          COLUMNS.PRICE_MBS,
          COLUMNS.YTW,
          COLUMNS.YTM_YIELD,
          COLUMNS.CARRY_TO_NEXT_DAY,
          COLUMNS.ACCOUNT,
          COLUMNS._SPACER,
        ],
      },
    },
    REQUEST_TICKET: {
      PRICE_QUOTES: {
        columns: [
          COLUMNS.DELETE,
          COLUMNS.BUYER_SELLER,
          COLUMNS.ORIGINAL_SIZE,
          COLUMNS.DUPLICATE,
          COLUMNS.CUSIP_EDIT,
          COLUMNS.ISSUER_RESPONSIVE,
          COLUMNS.COUPON,
          COLUMNS.MATURITY_DATE,
          COLUMNS.INSTRUMENT_TYPE,
        ],
        alternateViewColumns: [
          COLUMNS.DELETE,
          COLUMNS.BUYER_SELLER,
          COLUMNS.QUANTITY_EDIT,
          COLUMNS.DUPLICATE,
          COLUMNS.CUSIP_EDIT,
          COLUMNS.ISSUER_RESPONSIVE,
          COLUMNS.COUPON,
          COLUMNS.MATURITY_DATE,
          COLUMNS.INSTRUMENT_TYPE,
        ],
      },
      BIDS_RFQ: {
        columns: [
          COLUMNS.DELETE,
          COLUMNS.ORIGINAL_SIZE,
          COLUMNS.DUPLICATE,
          COLUMNS.CUSIP_EDIT,
          COLUMNS.ISSUER_RESPONSIVE,
          COLUMNS.COUPON,
          COLUMNS.MATURITY_DATE,
          COLUMNS.INSTRUMENT_TYPE,
        ],
        alternateViewColumns: [
          COLUMNS.DELETE,
          COLUMNS.QUANTITY_EDIT,
          COLUMNS.DUPLICATE,
          COLUMNS.CUSIP_EDIT,
          COLUMNS.ISSUER_RESPONSIVE,
          COLUMNS.COUPON,
          COLUMNS.MATURITY_DATE,
          COLUMNS.INSTRUMENT_TYPE,
        ],
      },
      BONDS_FOR_SALE: {
        columns: [
          COLUMNS.DELETE,
          COLUMNS.ORIGINAL_SIZE,
          COLUMNS.DUPLICATE,
          COLUMNS.CUSIP_EDIT,
          COLUMNS.ISSUER_RESPONSIVE,
          COLUMNS.COUPON,
          COLUMNS.MATURITY_DATE,
          COLUMNS.CALL_DATE,
          COLUMNS.INSTRUMENT_TYPE,
          COLUMNS.PRICE_EDIT,
          COLUMNS.YTW_EDIT,
          COLUMNS.YTM_YIELD_EDIT,
          COLUMNS.PRINCIPAL,
          COLUMNS.INTEREST_BADGED,
          COLUMNS.TOTAL,
          COLUMNS.CARRY_TO_NEXT_DAY,
        ],
        alternateViewColumns: [
          COLUMNS.DELETE,
          COLUMNS.QUANTITY_EDIT,
          COLUMNS.DUPLICATE,
          COLUMNS.CUSIP_EDIT,
          COLUMNS.ISSUER_RESPONSIVE,
          COLUMNS.COUPON,
          COLUMNS.MATURITY_DATE,
          COLUMNS.CALL_DATE,
          COLUMNS.INSTRUMENT_TYPE,
          COLUMNS.PRICE_EDIT,
          COLUMNS.YTW_EDIT,
          COLUMNS.YTM_YIELD_EDIT,
          COLUMNS.PRINCIPAL,
          COLUMNS.INTEREST_BADGED,
          COLUMNS.TOTAL,
          COLUMNS.CARRY_TO_NEXT_DAY,
        ],
      },
      BONDS_FOR_SALE_EDIT: {
        columns: [
          COLUMNS.ORIGINAL_SIZE,
          COLUMNS.CUSIP,
          COLUMNS.ISSUER,
          COLUMNS.COUPON,
          COLUMNS.MATURITY_DATE,
          COLUMNS.CALL_DATE,
          COLUMNS.INSTRUMENT_TYPE,
          COLUMNS.PRICE_EDIT,
          COLUMNS.YTW_EDIT,
          COLUMNS.YTM_YIELD_EDIT,
          COLUMNS.PRINCIPAL,
          COLUMNS.INTEREST_BADGED,
          COLUMNS.TOTAL,
          COLUMNS.CARRY_TO_NEXT_DAY,
        ],
        alternateViewColumns: [
          COLUMNS.QUANTITY_EDIT,
          COLUMNS.CUSIP,
          COLUMNS.ISSUER,
          COLUMNS.COUPON,
          COLUMNS.MATURITY_DATE,
          COLUMNS.CALL_DATE,
          COLUMNS.INSTRUMENT_TYPE,
          COLUMNS.PRICE_EDIT,
          COLUMNS.YTW_EDIT,
          COLUMNS.YTM_YIELD_EDIT,
          COLUMNS.PRINCIPAL,
          COLUMNS.INTEREST_BADGED,
          COLUMNS.TOTAL,
          COLUMNS.CARRY_TO_NEXT_DAY,
        ],
      },
    },
    REQUEST_TICKET_FAILURES: {
      PRICE_QUOTES: {
        columns: [
          COLUMNS.BUYER_SELLER,
          COLUMNS.ORIGINAL_SIZE,
          COLUMNS.CUSIP,
          COLUMNS.ISSUER,
          COLUMNS.COUPON,
          COLUMNS.MATURITY_DATE,
          COLUMNS.INSTRUMENT_TYPE,
        ],
        alternateViewColumns: [
          COLUMNS.BUYER_SELLER,
          COLUMNS.QUANTITY_EDIT,
          COLUMNS.CUSIP,
          COLUMNS.ISSUER,
          COLUMNS.COUPON,
          COLUMNS.MATURITY_DATE,
          COLUMNS.INSTRUMENT_TYPE,
        ],
      },
      BIDS_RFQ: {
        columns: [
          COLUMNS.ORIGINAL_SIZE,
          COLUMNS.CUSIP,
          COLUMNS.ISSUER,
          COLUMNS.COUPON,
          COLUMNS.MATURITY_DATE,
          COLUMNS.INSTRUMENT_TYPE,
        ],
        alternateViewColumns: [
          COLUMNS.QUANTITY_EDIT,
          COLUMNS.CUSIP,
          COLUMNS.ISSUER,
          COLUMNS.COUPON,
          COLUMNS.MATURITY_DATE,
          COLUMNS.INSTRUMENT_TYPE,
        ],
      },
      BONDS_FOR_SALE: {
        columns: [
          COLUMNS.ORIGINAL_SIZE,
          COLUMNS.CUSIP,
          COLUMNS.ISSUER,
          COLUMNS.COUPON,
          COLUMNS.MATURITY_DATE,
          COLUMNS.INSTRUMENT_TYPE,
          COLUMNS.PRICE_EDIT,
          COLUMNS.YTW_EDIT,
          COLUMNS.YTM_YIELD_EDIT,
        ],
        alternateViewColumns: [
          COLUMNS.QUANTITY_EDIT,
          COLUMNS.CUSIP,
          COLUMNS.ISSUER,
          COLUMNS.COUPON,
          COLUMNS.MATURITY_DATE,
          COLUMNS.INSTRUMENT_TYPE,
          COLUMNS.PRICE_EDIT,
          COLUMNS.YTW_EDIT,
          COLUMNS.YTM_YIELD_EDIT,
        ],
      },
    },
  };

  const INVENTORY_TABLE = {
    MUNICIPAL: {
      DEFAULT: {
        columns: [
          COLUMNS.QUANTITY,
          COLUMNS._EXPANDER,
          COLUMNS.CUSIP,
          COLUMNS.ISSUER,
          COLUMNS.STATE,
          COLUMNS.COUPON,
          COLUMNS.MATURITY_DATE,
          COLUMNS.CALL_DATE,
          COLUMNS.RATINGS,
          COLUMNS.PRICE,
          COLUMNS.YTW,
          COLUMNS.YTM,
          COLUMNS._SPACER,
        ],
        secondaryNavOptions: [{ filterKey: "callable", optionsKey: "CALL_FEATURES" }],
        advancedSearch: {
          filters: [
            ADVANCED_FILTER_FEATURES.MUNICIPAL_TAX,
            ADVANCED_FILTER_FEATURES.MUNICIPAL,
            DataConfig.PROPERTY.municipalSecurity.id,
            DataConfig.PROPERTY.settlementDate,
            DataConfig.PROPERTY.useOfProceeds.id,
            DataConfig.PROPERTY.couponMonth,
          ],
        },
      },
      INVENTORY: {
        defaultFilter: {
          "combinedRatings.ratingSortRank": {
            filterKey: "combinedRatings.ratingSortRank",
            filterValue: { value: 17 },
            filterType: Constants.FILTER_TYPE.SELECTRATING,
          },
          quantity: {
            filterKey: "quantity",
            filterValue: [100],
            filterType: Constants.FILTER_TYPE.RANGE,
          },
        },
        secondaryNavOptions: [{ filterKey: "callable", optionsKey: "CALL_FEATURES" }],
        advancedSearch: {
          filters: [
            ADVANCED_FILTER_FEATURES.MUNICIPAL_TAX,
            ADVANCED_FILTER_FEATURES.MUNICIPAL,
            DataConfig.PROPERTY.municipalSecurity.id,
            DataConfig.PROPERTY.settlementDate,
            DataConfig.PROPERTY.useOfProceeds.id,
            DataConfig.PROPERTY.couponMonth,
          ],
        },
      },
      FAVORITES: {
        defaultSort: [{ id: "inInventory", desc: true }],
      },
      COMPARABLE: {
        advancedCompBondCriteria: {
          filters: [
            DataConfig.PROPERTY.price,
            DataConfig.PROPERTY.maturityDate,
            DataConfig.PROPERTY.callDate,
            DataConfig.PROPERTY.interestRate,
            DataConfig.PROPERTY.combinedRatings.ratingSortRank,
            ADVANCED_FILTER_FEATURES.MUNICIPAL_TAX_COMPBOND,
            DataConfig.PROPERTY.state,
            DataConfig.PROPERTY.municipalSecurity.id,
          ],
        },
      },
      TRADE_TICKET: {
        columns: [
          COLUMNS.DELETE,
          COLUMNS.QUANTITY_EDIT,
          COLUMNS.CUSIP,
          COLUMNS.ISSUER,
          COLUMNS.MATURITY_DATE,
          COLUMNS.PRICE_MBS,
          COLUMNS.YTW,
          COLUMNS.YTM,
          COLUMNS.PRINCIPAL,
          COLUMNS.INTEREST_BADGED,
          COLUMNS.TOTAL,
          COLUMNS.SETTLE_DATE,
          COLUMNS._SPACER,
        ],
      },
      TRADE_TICKET_CONFLICT: {
        columns: [
          COLUMNS.DELETE,
          COLUMNS.QUANTITY,
          COLUMNS.QUANTITY_PREVIOUS,
          COLUMNS.CUSIP,
          COLUMNS.ISSUER,
          COLUMNS.PRICE,
          COLUMNS.YTW,
          COLUMNS.YTM,
          COLUMNS.PRICE_PREVIOUS,
          COLUMNS.YTW_PREVIOUS,
          COLUMNS.YTM_PREVIOUS,
          COLUMNS._SPACER,
        ],
      },
      TRADE_TICKET_FAILURES: {
        columns: [
          COLUMNS.FAVORITE,
          COLUMNS.QUANTITY_EDIT,
          COLUMNS.CUSIP,
          COLUMNS.ISSUER,
          COLUMNS.MATURITY_DATE,
          COLUMNS.PRICE,
          COLUMNS.YTM,
          COLUMNS.YTW,
          COLUMNS._SPACER,
        ],
      },
    },
    MORTGAGE: {
      DEFAULT: {
        columns: [
          COLUMNS.ORIGINAL_FACE,
          COLUMNS.FACTOR,
          COLUMNS.CURRENT_FACE,
          COLUMNS._EXPANDER,
          COLUMNS.CUSIP,
          COLUMNS.ISSUER_SHORT,
          COLUMNS.LOAN_TYPE,
          COLUMNS.COUPON,
          COLUMNS.SEASONING,
          COLUMNS.PRICE_MBS,
          COLUMNS.YIELD_MBS,
          COLUMNS.WAL,
          COLUMNS.PSA_SPEED,
          COLUMNS._SPACER,
        ],
        defaultSort: [{ id: "weightedAverageLife", desc: false }],
        maxHeight: "100%",
        advancedSearch: {
          width: 250,
          filters: [DataConfig.PROPERTY.settlementDate],
        },
      },
      INVENTORY: {
        defaultFilter: {
          currentFace: {
            filterKey: "currentFace",
            filterValue: [100000],
            filterType: Constants.FILTER_TYPE.RANGE,
          },
        },
        maxHeight: "100%",
        advancedSearch: {
          width: 320,
          filters: [DataConfig.PROPERTY.settlementDate],
        },
      },
      FAVORITES: {
        defaultSort: [{ id: "inInventory", desc: true }],
      },
      COMPARABLE: {
        advancedCompBondCriteria: {
          filters: [
            DataConfig.PROPERTY.price,
            DataConfig.PROPERTY.termYrOfMbs,
            DataConfig.PROPERTY.interestRate,
            DataConfig.PROPERTY.mbsSeasoning,
            DataConfig.PROPERTY.weightedAverageLife,
          ],
        },
      },
      TRADE_TICKET: {
        columns: [
          COLUMNS.DELETE,
          COLUMNS.QUANTITY_EDIT,
          COLUMNS.ORIGINAL_FACE,
          COLUMNS.FACTOR,
          COLUMNS.CURRENT_FACE,
          COLUMNS.CUSIP,
          COLUMNS.ISSUER,
          COLUMNS.COUPON,
          COLUMNS.PRICE_MBS,
          COLUMNS.YIELD_MBS,
          COLUMNS.PRINCIPAL,
          COLUMNS.INTEREST_BADGED,
          COLUMNS.TOTAL,
          COLUMNS.SETTLE_DATE,
          COLUMNS._SPACER,
        ],
        alternateViewColumns: [
          COLUMNS.DELETE,
          COLUMNS.ADJ_MBS,
          COLUMNS.QUANTITY_EDIT,
          COLUMNS.ORIGINAL_FACE,
          COLUMNS.FACTOR,
          COLUMNS.CURRENT_FACE,
          COLUMNS.CUSIP,
          COLUMNS.ISSUER,
          COLUMNS.COUPON,
          COLUMNS.PRICE_MBS,
          COLUMNS.YIELD_MBS,
          COLUMNS.PRINCIPAL,
          COLUMNS.INTEREST_BADGED,
          COLUMNS.TOTAL,
          COLUMNS.SETTLE_DATE,
          COLUMNS._SPACER,
        ],
      },
      TRADE_TICKET_CONFLICT: {
        columns: [
          COLUMNS.DELETE,
          COLUMNS.QUANTITY,
          COLUMNS.QUANTITY_PREVIOUS,
          COLUMNS.CUSIP,
          COLUMNS.ISSUER,
          COLUMNS.PRICE_MBS,
          COLUMNS.YIELD_MBS,
          COLUMNS.PRICE_PREVIOUS,
          COLUMNS.YIELD_PREVIOUS,
          COLUMNS._SPACER,
        ],
      },

      TRADE_TICKET_FAILURES: {
        columns: [
          COLUMNS.FAVORITE,
          COLUMNS.QUANTITY_EDIT,
          COLUMNS.CUSIP,
          COLUMNS.ISSUER,
          COLUMNS.MATURITY_DATE,
          COLUMNS.PRICE_MBS,
          COLUMNS.YIELD_MBS,
          COLUMNS._SPACER,
        ],
      },
    },
    AGENCY: {
      DEFAULT: {
        columns: [
          COLUMNS.QUANTITY,
          COLUMNS._EXPANDER,
          COLUMNS.CUSIP,
          COLUMNS.ISSUER,
          COLUMNS.COUPON,
          COLUMNS.MATURITY_DATE,
          COLUMNS.CALL_DATE,
          COLUMNS.PRICE,
          COLUMNS.YTW,
          COLUMNS.YTM,
          COLUMNS._SPACER,
        ],
        secondaryNavOptions: [{ filterKey: "callable", optionsKey: "CALL_FEATURES" }],
        advancedSearch: {
          width: 360,
          filters: [
            DataConfig.PROPERTY.productCategoryAsIssuer,
            DataConfig.PROPERTY.couponMonth,
            DataConfig.PROPERTY.settlementDate,
            ADVANCED_FILTER_FEATURES.AGENCY,
          ],
        },
      },
      INVENTORY: {
        defaultFilter: {
          quantity: {
            filterKey: "quantity",
            filterValue: [100],
            filterType: Constants.FILTER_TYPE.RANGE,
          },
        },
        secondaryNavOptions: [{ filterKey: "callable", optionsKey: "CALL_FEATURES" }],
        advancedSearch: {
          width: 360,
          filters: [
            DataConfig.PROPERTY.productCategoryAsIssuer,
            DataConfig.PROPERTY.couponMonth,
            DataConfig.PROPERTY.settlementDate,
            ADVANCED_FILTER_FEATURES.AGENCY,
          ],
        },
      },
      FAVORITES: {
        defaultSort: [{ id: "inInventory", desc: true }],
      },
      COMPARABLE: {
        advancedCompBondCriteria: {
          filters: [
            DataConfig.PROPERTY.price,
            DataConfig.PROPERTY.callDate,
            DataConfig.PROPERTY.maturityDate,
          ],
        },
      },
      TRADE_TICKET: {
        columns: [
          COLUMNS.DELETE,
          COLUMNS.QUANTITY_EDIT,
          COLUMNS.CUSIP,
          COLUMNS.ISSUER,
          COLUMNS.MATURITY_DATE,
          COLUMNS.PRICE,
          COLUMNS.YTW,
          COLUMNS.YTM,
          COLUMNS.PRINCIPAL,
          COLUMNS.INTEREST_BADGED,
          COLUMNS.TOTAL,
          COLUMNS.SETTLE_DATE,
          COLUMNS._SPACER,
        ],
      },
      TRADE_TICKET_CONFLICT: {
        columns: [
          COLUMNS.DELETE,
          COLUMNS.QUANTITY,
          COLUMNS.QUANTITY_PREVIOUS,
          COLUMNS.CUSIP,
          COLUMNS.ISSUER,
          COLUMNS.PRICE,
          COLUMNS.YTW,
          COLUMNS.YTM,
          COLUMNS.PRICE_PREVIOUS,
          COLUMNS.YTW_PREVIOUS,
          COLUMNS.YTM_PREVIOUS,
          COLUMNS._SPACER,
        ],
      },
      TRADE_TICKET_FAILURES: {
        columns: [
          COLUMNS.FAVORITE,
          COLUMNS.QUANTITY_EDIT,
          COLUMNS.CUSIP,
          COLUMNS.ISSUER,
          COLUMNS.MATURITY_DATE,
          COLUMNS.PRICE,
          COLUMNS.YTM,
          COLUMNS.YTW,
          COLUMNS._SPACER,
        ],
      },
    },
    TREASURY: {
      DEFAULT: {
        columns: [
          COLUMNS.QUANTITY,
          COLUMNS._EXPANDER,
          COLUMNS.CUSIP,
          COLUMNS.ISSUER,
          COLUMNS.COUPON,
          COLUMNS.MATURITY_DATE,
          COLUMNS.PRICE,
          COLUMNS.YTM,
          COLUMNS._SPACER,
        ],
        secondaryNavOptions: [
          { filterKey: "isactivetreasury", optionsKey: "TREASURY_TYPE" }
        ],
        maxHeight: "100%",
        advancedSearch: {
          width: 360,
          filters: [
            DataConfig.PROPERTY.treasuryType,
            DataConfig.PROPERTY.couponMonth,
            DataConfig.PROPERTY.interestFrequency.id,
            DataConfig.PROPERTY.settlementDate,
          ],
        },
      },
      INVENTORY: {
        defaultFilter: {
          quantity: {
            filterKey: "quantity",
            filterValue: [100],
            filterType: Constants.FILTER_TYPE.RANGE,
          },
        },
        maxHeight: "100%",
        advancedSearch: {
          width: 360,
          filters: [
            DataConfig.PROPERTY.treasuryType,
            DataConfig.PROPERTY.couponMonth,
            DataConfig.PROPERTY.interestFrequency.id,
            DataConfig.PROPERTY.settlementDate,
          ],
        },
      },
      FAVORITES: {
        defaultSort: [{ id: "inInventory", desc: true }],
      },
      COMPARABLE: {
        advancedCompBondCriteria: {
          filters: [DataConfig.PROPERTY.price, DataConfig.PROPERTY.maturityDate],
        },
      },
      TRADE_TICKET: {
        columns: [
          COLUMNS.DELETE,
          COLUMNS.QUANTITY_EDIT,
          COLUMNS.CUSIP,
          COLUMNS.ISSUER,
          COLUMNS.MATURITY_DATE,
          COLUMNS.PRICE,
          COLUMNS.YTM,
          COLUMNS.PRINCIPAL,
          COLUMNS.INTEREST_BADGED,
          COLUMNS.TOTAL,
          COLUMNS.SETTLE_DATE,
          COLUMNS._SPACER,
        ],
      },
      TRADE_TICKET_CONFLICT: {
        columns: [
          COLUMNS.DELETE,
          COLUMNS.QUANTITY,
          COLUMNS.QUANTITY_PREVIOUS,
          COLUMNS.CUSIP,
          COLUMNS.ISSUER,
          COLUMNS.PRICE,
          COLUMNS.YTM,
          COLUMNS.PRICE_PREVIOUS,
          COLUMNS.YTM_PREVIOUS,
          COLUMNS._SPACER,
        ],
      },
      TRADE_TICKET_FAILURES: {
        columns: [
          COLUMNS.FAVORITE,
          COLUMNS.QUANTITY_EDIT,
          COLUMNS.CUSIP,
          COLUMNS.ISSUER,
          COLUMNS.MATURITY_DATE,
          COLUMNS.PRICE,
          COLUMNS.YTM,
          COLUMNS.YTW,
          COLUMNS._SPACER,
        ],
      },
    },
    CD: {
      DEFAULT: {
        columns: [
          COLUMNS.QUANTITY,
          COLUMNS._EXPANDER,
          COLUMNS.CUSIP,
          COLUMNS.ISSUER,
          COLUMNS.FDIC,
          COLUMNS.COUPON,
          COLUMNS.MATURITY_DATE,
          COLUMNS.CALL_DATE,
          COLUMNS.PRICE,
          COLUMNS.YTW,
          COLUMNS.YTM,
          COLUMNS._SPACER,
        ],
        secondaryNavOptions: [
          { filterKey: "instrumentType.id", optionsKey: "CD_TYPE" },
          { filterKey: "callable", optionsKey: "CALL_FEATURES" },
        ],
        FDICFilter: {
          accessor: "excludeOwnedCds",
        },
        advancedSearch: {
          filters: [
            DataConfig.PROPERTY.rateTerm.id,
            DataConfig.PROPERTY.interestFrequency.id,
            DataConfig.PROPERTY.settlementDate,
          ],
        },
      },
      INVENTORY: {
        defaultFilter: {
          quantity: {
            filterKey: "quantity",
            filterValue: [100],
            filterType: Constants.FILTER_TYPE.RANGE,
          },
        },
        noDataText: Messages.MESSAGE.NODATA.CDS_NOT_FOUND,
        noFilteredDataText: Messages.MESSAGE.NODATA.NO_CDS_SEARCH,
      },
      FAVORITES: {
        defaultSort: [{ id: "inInventory", desc: true }],
      },
      COMPARABLE: {
        noDataText: Messages.MESSAGE.NODATA.CDS_NOT_FOUND_COMPARABLE,
        advancedCompBondCriteria: {
          filters: [
            DataConfig.PROPERTY.price,
            DataConfig.PROPERTY.callDate,
            DataConfig.PROPERTY.maturityDate,
          ],
        },
      },
      TRADE_TICKET: {
        columns: [
          COLUMNS.DELETE,
          COLUMNS.PRINCIPAL_EDIT,
          COLUMNS.CUSIP,
          COLUMNS.ISSUER,
          COLUMNS.FDIC,
          COLUMNS.CDTYPE,
          COLUMNS.MATURITY_DATE,
          COLUMNS.PRICE,
          COLUMNS.YTW,
          COLUMNS.YTM,
          COLUMNS.PRINCIPAL,
          COLUMNS.INTEREST_BADGED,
          COLUMNS.TOTAL,
          COLUMNS.SETTLE_DATE,
          COLUMNS._SPACER,
        ],
        alternateViewColumns: [
          COLUMNS.DELETE,
          COLUMNS.ADJ_FDIC,
          COLUMNS.PRINCIPAL_EDIT,
          COLUMNS.CUSIP,
          COLUMNS.ISSUER,
          COLUMNS.FDIC,
          COLUMNS.CDTYPE,
          COLUMNS.MATURITY_DATE,
          COLUMNS.PRICE,
          COLUMNS.YTW,
          COLUMNS.YTM,
          COLUMNS.PRINCIPAL,
          COLUMNS.INTEREST_BADGED,
          COLUMNS.TOTAL,
          COLUMNS.SETTLE_DATE,
          COLUMNS._SPACER,
        ],
      },
      TRADE_TICKET_CONFLICT: {
        columns: [
          COLUMNS.DELETE,
          COLUMNS.QUANTITY,
          COLUMNS.QUANTITY_PREVIOUS,
          COLUMNS.CUSIP,
          COLUMNS.ISSUER,
          COLUMNS.PRICE,
          COLUMNS.YTW,
          COLUMNS.YTM,
          COLUMNS.PRICE_PREVIOUS,
          COLUMNS.YTW_PREVIOUS,
          COLUMNS.YTM_PREVIOUS,
          COLUMNS._SPACER,
        ],
      },
      TRADE_TICKET_FAILURES: {
        columns: [
          COLUMNS.FAVORITE,
          COLUMNS.QUANTITY_EDIT,
          COLUMNS.CUSIP,
          COLUMNS.ISSUER,
          COLUMNS.MATURITY_DATE,
          COLUMNS.PRICE,
          COLUMNS.YTM,
          COLUMNS.YTW,
          COLUMNS._SPACER,
        ],
      },
    },
  };

  return [DEFAULT_TABLE, SUBTYPED_TABLE, INVENTORY_TABLE];
};

export default DataTableConfig;
