import React from "react";
import moment from "moment";
import { get, invert } from "lodash";
import PropTypes from "prop-types";
import clsx from "clsx";

import * as DataConfig from "config/dataconfig";
import * as utils from "commons/utils";
import * as Constants from "commons/constants";
import { useMessages } from "providers/BrandingProvider";
import QBTypography from "components/QBTypography";
import RemoveFilterButton from "components/filters/common/RemoveFilterButton";
import { PopperPaper } from "components/containers/Modals";
import {
  TextInput,
  ActionButton,
  SettingsButton,
  CalendarButton,
  StyledSecondaryTooltip,
  StyledCheck,
} from "components/Controls";
import IncrementFilter, { getDateIncrementLabel, getDateIncrementFilterLabel } from "./IncrementFilter";

import Button from "@material-ui/core/Button";
import Popper from "@material-ui/core/Popper";
import Fade from "@material-ui/core/Fade";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import { DatePicker } from "@material-ui/pickers";
import { makeStyles } from "@material-ui/core/styles";
import DateRangeIcon from "@material-ui/icons/DateRange";
import { StyledIconButton } from "components/Controls";

const useStyles = makeStyles((theme) => ({
  wrapper: {
    display: "flex",
  },
  headerWrapper: {
    height: 28,
    width: "100%",
    display: "flex",
    alignItems: "center",
    borderBottom: "1px solid",
    borderBottomColor: theme.palette.border.main,
  },
  header: {
    margin: "auto 2px",
    textTransform: "uppercase",
    color: theme.palette.primary.main,
  },
  withinPopperHeader: {
    color: "inherit",
  },
  transitionPopper: {
    zIndex: 1500,
  },
  withinPopperFormControlWrapper: {
    marginLeft: 5,
    paddingTop: 15,
  },
  label: {
    fontSize: "0.7rem",
    margin: "auto 0",
  },
  button: {
    lineHeight: 1,
    padding: 7,
    fontSize: "0.6rem",
    marginRight: 4,
  },
  paper: {
    minHeight: 300,
    minWidth: 525,
  },
  datepickerWrapper: {
    margin: "0 1.0rem",
  },
  section: {
    display: "flex",
    flexDirection: "column",
    margin: "auto 0",
    paddingBottom: 20,
  },
  filterWrapper: {
    padding: "25px 20px 25px 10px",
  },
  criteria: {
    color: theme.palette.secondary.main,
  },
  nofilterText: {
    padding: 0,
    margin: 0,
    marginTop: 3,
    marginBottom: 1,
    height: 18,
    fontSize: ".65rem",
    color: theme.palette.text.secondary,
    letterSpacing: "0.05em",
  },
  filterText: {
    textAlign: "center",
    marginTop: 3,
    marginLeft: 3,
    fontSize: "0.65rem",
    letterSpacing: "0.05em",
  },
  withinPopperFilterText: {
    display: "flex",
    fontSize: "0.65rem",
    letterSpacing: "0.05em",
  },
}));

const formatOutput = (value) => {
  if (!utils.hasNonEmptyValue(value)) {
    return "";
  }

  let date = moment(value, DataConfig.DATE_FORMAT.FILTER);
  if (!date.isValid()) {
    return "";
  }
  return moment(date).format(DataConfig.DATE_FORMAT.FILTER_DISPLAY);
};

const isDynamicFilteringEnabled = process.env.REACT_APP_FEATURES_DYNAMIC_DATE_RANGE === "true";

const DateRangeFilter = ({
  filter,
  filterKey,
  onFilterChange,
  header,
  disablePast,
  popperPlacement,
  variant = "filter",
}) => {
  const classes = useStyles();
  const Messages = useMessages();
  const isWithinPopper = variant === "popper"; // when the filter is already contained in a popper, e.g. adv search

  const containerRef = React.useRef();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const dynamic =
    get(filter, "filterType", Constants.FILTER_TYPE.DATERANGE) === Constants.FILTER_TYPE.DATE_RANGE_INCREMENT;
  const [showDynamicFilter, setShowDynamicFilter] = React.useState(dynamic);

  const propertyConfig = get(DataConfig.PROPERTY, filterKey, {});
  const incrementFilter = propertyConfig.incrementfilter;
  const currentFilter = get(filter, "filterValue", []);

  const marksToScale = {};
  if (incrementFilter?.scale) {
    incrementFilter.scale.forEach((s) => (marksToScale[s.value] = s.scaledValue));
  }

  const scaleToMarks = invert(marksToScale);

  let val1 =
    !dynamic && currentFilter.length > 0 && utils.hasNonEmptyValue(currentFilter[0])
      ? moment(currentFilter[0], DataConfig.DATE_FORMAT.FILTER)
      : null;

  let val2 =
    !dynamic && currentFilter.length > 1 && utils.hasNonEmptyValue(currentFilter[1])
      ? moment(currentFilter[1], DataConfig.DATE_FORMAT.FILTER)
      : null;

  let incrementVal1 =
    dynamic && currentFilter.length > 0 && utils.hasNonEmptyValue(currentFilter[0])
      ? scaleToMarks[currentFilter[0]]
      : null;

  let incrementVal2 =
    dynamic && currentFilter.length > 1 && utils.hasNonEmptyValue(currentFilter[1])
      ? scaleToMarks[currentFilter[1]]
      : null;

  const [value, setValue] = React.useState(val1);
  const [value2, setValue2] = React.useState(val2);
  const [incrementValue1, setIncrementValue1] = React.useState(incrementVal1);
  const [incrementValue2, setIncrementValue2] = React.useState(incrementVal2);

  const hasValue1Filter = dynamic
    ? utils.hasNonEmptyValue(incrementVal1)
    : utils.hasNonEmptyValue(formatOutput(val1));
  const hasValue2Filter = dynamic
    ? utils.hasNonEmptyValue(incrementVal2)
    : utils.hasNonEmptyValue(formatOutput(val2));
  const hasFilterValue = hasValue1Filter || hasValue2Filter;

  const [endDateDisabled, setEndDateDisabled] = React.useState(
    utils.hasNonEmptyValue(val1) && !utils.hasNonEmptyValue(val2),
  );

  const removeFilter = () => {
    setValue(null);
    setValue2(null);

    onFilterChange({
      filterKey: filterKey,
      filterValue: [null, null],
      filterType: showDynamicFilter
        ? Constants.FILTER_TYPE.DATE_RANGE_INCREMENT
        : Constants.FILTER_TYPE.DATERANGE,
    });

    setShowDynamicFilter(false);
  };

  const handleDatePickerChange = (val, index) => {
    if (index === 0) {
      setValue(val);
      if (!endDateDisabled && val.isSameOrAfter(value2 || moment())) {
        setValue2(moment(val).add(1, "days"));
      }
    } else {
      setValue2(val);
    }
  };

  const handleIncrementChange = ({ filterValue }, index) => {
    setIncrementValue1(filterValue[0]);
    setIncrementValue2(filterValue[1]);
  };

  const haveValuesChanged = () => {
    if (!showDynamicFilter && !value && !value2) {
      return false;
    }

    return showDynamicFilter
      ? incrementValue1 !== incrementValue2
      : (value && value.isValid() && !value.isSame(val1)) ||
          (value2 && value2.isValid() && !value2.isSame(val2)) ||
          (!val2 && !endDateDisabled) ||
          (val2 && val2.isValid() && endDateDisabled);
  };

  const applyRangeFilters = () => {
    if (haveValuesChanged()) {
      const newVal1 =
        !showDynamicFilter && utils.hasNonEmptyValue(value)
          ? value.format(DataConfig.DATE_FORMAT.FILTER)
          : moment().format(DataConfig.DATE_FORMAT.FILTER);
      const newVal2 =
        !showDynamicFilter && !endDateDisabled && utils.hasNonEmptyValue(value2)
          ? value2.format(DataConfig.DATE_FORMAT.FILTER)
          : null;

      const updatedFilter = {
        filterKey: filterKey,
        filterValue: showDynamicFilter
          ? [scale(incrementValue1), scale(incrementValue2)]
          : [newVal1, newVal2],
        filterType: showDynamicFilter
          ? Constants.FILTER_TYPE.DATE_RANGE_INCREMENT
          : Constants.FILTER_TYPE.DATERANGE,
      };
      onFilterChange(updatedFilter);
    }
    setAnchorEl(null);
  };

  const togglePopper = (event) => {
    setAnchorEl(anchorEl ? null : isWithinPopper ? event.currentTarget : containerRef.current);
  };

  const toggleDynamic = () => {
    setShowDynamicFilter(!showDynamicFilter);
  };

  const toggleEndDateDisable = () => {
    if (endDateDisabled && value.isSameOrAfter(value2)) {
      setValue2(moment(value).add(1, "days"));
    }
    setEndDateDisabled(!endDateDisabled);
  };

  const onClosePopper = () => {
    if (anchorEl) setAnchorEl(null);
  };

  const scale = (value) => {
    return marksToScale[value];
  };

  const StartDateToolbar = (props) => {
    return <DateInput id="startdateToolbar" {...props}></DateInput>;
  };

  const EndDateToolbar = (props) => {
    return <DateInput id="enddateToolbar" disabled={endDateDisabled} {...props}></DateInput>;
  };

  return (
    <>
      {utils.hasNonEmptyValue(header) && (
        <div className={classes.headerWrapper}>
          <span className={clsx(classes.header, { [classes.withinPopperHeader]: isWithinPopper })}>
            {header}
          </span>
        </div>
      )}
      <div ref={containerRef} className={clsx({ [classes.withinPopperFormControlWrapper]: isWithinPopper })}>
        <div className={classes.wrapper} style={{ justifyContent: isWithinPopper ? "flex-start" : "center" }}>
          <div>
            <div style={{ height: 24 }}>
              {hasFilterValue && <RemoveFilterButton disabled={!hasFilterValue} onClick={removeFilter} />}
              {!hasFilterValue && <span className={classes.nofilterText}>Show: ALL</span>}
            </div>
            {!isWithinPopper && (
              <div>
                <StyledIconButton onClick={togglePopper}>
                  <DateRangeIcon color="secondary" fontSize="small" />
                </StyledIconButton>
              </div>
            )}
          </div>
          {hasFilterValue && (
            <div className={clsx(classes.filterText, { [classes.withinPopperFilterText]: isWithinPopper })}>
              <div>
                {dynamic
                  ? getDateIncrementFilterLabel(scale(incrementVal1))
                  : val1
                  ? formatOutput(val1)
                  : null}
              </div>
              <>
                {hasValue2Filter && (
                  <>
                    <div style={{ marginRight: "0.25rem", marginLeft: "0.25rem" }}>to</div>
                    <div>
                      {dynamic
                        ? getDateIncrementFilterLabel(scale(incrementVal2))
                        : val2
                        ? formatOutput(val2)
                        : null}
                    </div>
                  </>
                )}
                {!hasValue2Filter && (
                  <>
                    <div style={{ marginRight: "0.25rem", marginLeft: "0.25rem" }}>or</div>
                    <div>later</div>
                  </>
                )}
              </>
            </div>
          )}
          {isWithinPopper && (
            <div style={{ marginLeft: "0.5rem" }}>
              <StyledIconButton onClick={togglePopper}>
                <DateRangeIcon color="secondary" fontSize="small" />
              </StyledIconButton>
            </div>
          )}
        </div>
      </div>

      <Popper
        open={open}
        placement={popperPlacement || (variant === "filter" ? "bottom" : "right")}
        anchorEl={anchorEl}
        className={classes.transitionPopper}
        transition
      >
        {({ TransitionProps }) => (
          <Fade {...TransitionProps}>
            <React.Fragment>
              <ClickAwayListener onClickAway={onClosePopper}>
                <PopperPaper
                  classes={{ root: classes.paper }}
                  onClose={(e) => {
                    applyRangeFilters();
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      paddingBottom: 8,
                    }}
                  >
                    <div>
                      {isDynamicFilteringEnabled && incrementFilter && (
                        <>
                          {!showDynamicFilter && (
                            <StyledSecondaryTooltip title="Set dynamic filter">
                              <div>
                                <SettingsButton onClick={toggleDynamic} />
                              </div>
                            </StyledSecondaryTooltip>
                          )}
                          {showDynamicFilter && (
                            <StyledSecondaryTooltip title="Set date filters">
                              <div>
                                <CalendarButton onClick={toggleDynamic} />
                              </div>
                            </StyledSecondaryTooltip>
                          )}
                        </>
                      )}
                    </div>
                    <div>
                      <Button
                        color="secondary"
                        variant="outlined"
                        className={classes.button}
                        type="button"
                        onClick={togglePopper}
                      >
                        {Messages.LABEL.CANCEL}
                      </Button>

                      <ActionButton
                        variant="outlined"
                        color="secondary"
                        size="small"
                        onClick={(e) => {
                          applyRangeFilters();
                        }}
                      >
                        Apply
                      </ActionButton>
                    </div>
                  </div>
                  {!showDynamicFilter && (
                    <div style={{ display: "flex", justifyContent: "space-between", marginTop: "0.5rem" }}>
                      <div className={classes.datepickerWrapper}>
                        <div
                          style={{
                            margin: "0.25rem ",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                          }}
                        >
                          <div>
                            <span className={classes.header}>Start Date</span>
                          </div>
                        </div>
                        <DatePicker
                          variant="static"
                          views={["date"]}
                          openTo="date"
                          disablePast={disablePast}
                          value={value}
                          onChange={(event) => handleDatePickerChange(event, 0)}
                          format={DataConfig.DATE_FORMAT.FILTER_DISPLAY}
                          ToolbarComponent={StartDateToolbar}
                        />
                      </div>
                      <div className={classes.datepickerWrapper}>
                        <div
                          style={{
                            margin: "0.25rem ",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                          }}
                        >
                          <div>
                            <span className={classes.header}>End Date</span>
                          </div>
                          <div style={{ display: "flex" }}>
                            <StyledCheck
                              color="primary"
                              checked={endDateDisabled}
                              onChange={toggleEndDateDisable}
                            />
                            <div style={{ fontSize: "0.65rem", marginLeft: 4 }}>No end date</div>
                          </div>
                        </div>
                        <DatePicker
                          variant="static"
                          views={["date"]}
                          openTo="date"
                          disablePast={disablePast}
                          value={value2}
                          onChange={(event) => handleDatePickerChange(event, 1)}
                          format={DataConfig.DATE_FORMAT.FILTER_DISPLAY}
                          ToolbarComponent={EndDateToolbar}
                          shouldDisableDate={(d) =>
                            endDateDisabled ||
                            (utils.hasNonEmptyValue(value) &&
                              moment(d).isBefore(moment(value, DataConfig.DATE_FORMAT.FILTER)))
                          }
                        />
                      </div>
                    </div>
                  )}
                  {showDynamicFilter && (
                    <div className={classes.section}>
                      <div className={classes.filterWrapper}>
                        <div style={{ marginBottom: "1.5rem" }}>
                          <span className={classes.header}>DYNAMIC DATE FILTER</span>
                        </div>
                        <div style={{ margin: "10px 10px 10px 0" }}>
                          <IncrementFilter
                            filterConfig={incrementFilter}
                            filter={{ filterValue: [incrementValue1 || 0, incrementValue2 || 12] }}
                            onFilterChange={(filter) => handleIncrementChange(filter, 0)}
                            SliderProps={{ valueLabelDisplay: "off" }}
                          />
                        </div>
                        <QBTypography variant="caption" component="div" style={{ marginLeft: 3 }}>
                          <span className={classes.criteria}>
                            {getDateIncrementLabel(scale(incrementValue1 || 0))}
                          </span>
                          <span> to </span>
                          <span className={classes.criteria}>
                            {getDateIncrementLabel(scale(incrementValue2 || 12))}
                          </span>
                          <span> from the day the search is performed</span>
                        </QBTypography>
                      </div>
                    </div>
                  )}
                </PopperPaper>
              </ClickAwayListener>
            </React.Fragment>
          </Fade>
        )}
      </Popper>
    </>
  );
};

DateRangeFilter.FilterDefinition = {
  filterKey: PropTypes.string,
  filterValue: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])),
  filterType: PropTypes.oneOf(Object.values(Constants.FILTER_TYPE)),
};

DateRangeFilter.propTypes = {
  filter: PropTypes.shape(DateRangeFilter.FilterDefinition),
  filterKey: PropTypes.string.isRequired,
  onFilterChange: PropTypes.func.isRequired,
  minValue: PropTypes.string,
  header: PropTypes.string,
  variant: PropTypes.oneOf(["filter", "popper"]),
};

const DateInput = (props) => {
  const { date, openView, setOpenView, onChange, disabled, id } = props;
  const [day, setDay] = React.useState(date.format("D"));
  const [month, setMonth] = React.useState(date.format("M"));
  const [year, setYear] = React.useState(date.format("yyyy"));
  const focusedElementId = React.useRef();

  React.useEffect(() => {
    const date = moment(`${day}/${month}/${year}`, "D/M/YYYY");
    const originalValue = moment(date, DataConfig.DATE_FORMAT.FILTER);
    if (!date.isValid()) {
      setMonth(originalValue.format("M"));
      setDay(originalValue.format("D"));
      setYear(originalValue.format("yyyy"));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openView]);

  React.useEffect(() => {
    setMonth(date.format("M"));
    setDay(date.format("D"));
    setYear(date.format("yyyy"));
  }, [date]);

  const onMonthChange = (val) => {
    setMonth(val);
  };

  const onDayChange = (val) => {
    setDay(val);
  };

  const onYearChange = (val) => {
    setYear(val);
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      evaluateDate(e);
      e.stopPropagation();
    }
  };

  const evaluateDate = (e) => {
    if (e?.relatedTarget) {
      focusedElementId.current = e.relatedTarget.id;
    } else {
      focusedElementId.current = null;
    }

    try {
      const date = moment(`${day}/${month}/${year}`, "D/M/YYYY");

      if (date.isValid()) {
        onChange(date);
        setOpenView("date");
      }
    } catch {}
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        margin: "0.5rem",
      }}
    >
      <TextInput
        id={`DateRangeFilter_month_${id}`}
        variant="filter"
        style={{ marginLeft: "0.25rem", marginRight: "0.25rem", width: 30 }}
        onSelect={(e) => setOpenView("month")}
        onChange={(e) => onMonthChange(e.target.value)}
        onKeyDown={handleKeyPress}
        value={month}
        autoFocus={focusedElementId.current === `DateRangeFilter_month_${id}`}
        disabled={disabled}
      ></TextInput>{" "}
      /
      <TextInput
        id={`DateRangeFilter_day_${id}`}
        variant="filter"
        style={{ marginLeft: "0.25rem", marginRight: "0.25rem", width: 30 }}
        onSelect={(e) => setOpenView("date")}
        onChange={(e) => onDayChange(e.target.value)}
        onKeyDown={handleKeyPress}
        value={day}
        autoFocus={focusedElementId.current === `DateRangeFilter_day_${id}`}
        disabled={disabled}
      ></TextInput>{" "}
      /
      <TextInput
        id={`DateRangeFilter_year_${id}`}
        variant="filter"
        style={{ marginLeft: "0.25rem", marginRight: "0.25rem", width: 40 }}
        onSelect={(e) => setOpenView("year")}
        onChange={(e) => onYearChange(e.target.value)}
        onKeyDown={handleKeyPress}
        value={year}
        autoFocus={focusedElementId.current === `DateRangeFilter_year_${id}`}
        disabled={disabled}
      ></TextInput>
    </div>
  );
};

export default DateRangeFilter;
