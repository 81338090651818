import React from "react";
import PropTypes from "prop-types";
import { sortBy } from "lodash";
import deburr from "lodash/deburr";

import { getUser } from "commons/helpers/userStorage";
import { useMessages } from "providers/BrandingProvider";
import * as utils from "commons/utils";

import { ClosableModal } from "components/containers/Modals";
import QBTypography from "components/QBTypography";
import { TextInput, StyledCheck } from "components/Controls";
import { getAffiliateInstitutions } from "commons/helpers/userStorage";
import Typeahead from "components/Typeahead";
import { isTypeaheadEqual } from "components/Typeahead";

import Button from "@material-ui/core/Button";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import DeleteIcon from "@material-ui/icons/RemoveCircleOutline";
import { LinkButton } from "components/Controls";

const useStyles = makeStyles((theme) => ({
  modalItem: {
    fontSize: "0.7rem",
    color: theme.palette.text.textInput,
    padding: 4,
    display: "flex",
    alignItems: "flex-start",
  },
  checkboxLabel: {
    marginLeft: 4,
  },
  saveAsWrapper: {
    marginTop: 4,
    paddingTop: 6,
    borderTop: "1px solid",
    borderTopColor: theme.palette.border.main,
  },
  selectable: {
    "&:hover:not(.edit)": {
      backgroundColor: theme.palette.background.hover,
    },
    "&.selected": {
      backgroundColor: theme.palette.background.selected,
    },
  },
  name: {
    cursor: "pointer",
  },
  info: {
    color: theme.palette.text.secondary,
    fontSize: "0.65rem",
  },
  error: {
    color: theme.palette.error.main,
    fontSize: "0.7rem",
  },
}));

const MenuButton = withStyles((theme) => ({
  root: {
    padding: 4,
    fontSize: "0.8rem",
    minWidth: 40,
    fontWeight: "normal",
    borderRadius: 4,
    lineHeight: 1.2,
    "&.MuiButton-outlinedSecondary.Mui-disabled": {
      border: "1px solid",
      borderColor: theme.palette.border.disabled,
      color: theme.palette.text.disabled,
    },
  },
}))(Button);

const SaveSearchModal = ({ open, validationNames, search, editCompanyOnly, onUpdate, onAdd, onClose }) => {
  const classes = useStyles();
  const Messages = useMessages();
  const user = getUser();

  const [saveName, setSaveName] = React.useState("");
  const [email, setEmail] = React.useState(true);

  const [selectedInstitutions, setSelectedInstitutions] = React.useState([]);

  const affiliateInstitutions = getAffiliateInstitutions();
  const canSaveForOthers = user.affiliateAdmin && affiliateInstitutions?.length > 0;
  const canEditInstitutions = canSaveForOthers && (editCompanyOnly || !utils.hasNonEmptyValue(search));

  React.useEffect(() => {
    if (open) {
      setSaveName(search?.description ?? "");
      setEmail(search ? search.emailEnabled : true);
      setSelectedInstitutions(search?.templateCompanies ?? []);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  const uniqueSaveName = validationNames.indexOf(saveName) === -1;
  const isSaveDisabled = !utils.hasNonEmptyValue(saveName) || !uniqueSaveName;
  const isUpdateDisabled =
    !utils.hasNonEmptyValue(saveName) || (!uniqueSaveName && saveName !== search?.description);

  let errorMessage = null;
  if (!utils.hasNonEmptyValue(saveName)) {
    errorMessage = Messages.MESSAGE.VALIDATION.REQUIRED;
  } else if (search && !uniqueSaveName && saveName !== search?.description) {
    errorMessage = Messages.MESSAGE.VALIDATION.DUPLICATE_NAME;
  } else if (!search && !uniqueSaveName) {
    errorMessage = Messages.MESSAGE.VALIDATION.DUPLICATE_NAME;
  }

  const handleInputChange = (event) => {
    const val = event.target.value;
    setSaveName(val);
  };

  const handleAddSearch = () => {
    onAdd({
      description: saveName,
      emailEnabled: email,
      companyId: selectedInstitutions.map((i) => i.companyId),
    });
  };

  const handleUpdateSearch = () => {
    onUpdate(
      {
        ...search,
        description: saveName,
        emailEnabled: email,
        companyId: selectedInstitutions.map((i) => i.companyId),
      },
      editCompanyOnly,
    );
  };

  return utils.hasNonEmptyValue(user) ? (
    <>
      <ClosableModal open={open} header="Save Search" onClose={onClose}>
        <div
          style={{
            minHeight: canEditInstitutions ? 500 : 120,
            display: "flex",
            flexDirection: "column",
          }}
        >
          <div style={{ flexGrow: 1 }}>
            <div className={classes.modalItem}>
              <div style={{ marginRight: 4, marginTop: 2, flexBasis: 75 }}>{Messages.LABEL.SEARCH_NAME}</div>
              {!editCompanyOnly && (
                <TextInput
                  variant="form"
                  value={saveName}
                  maxLength={100}
                  error={errorMessage !== null}
                  onChange={handleInputChange}
                  autoFocus={true}
                  placeholder={Messages.LABEL.SEARCH_NAME}
                  helperText={errorMessage}
                  style={{ width: 300 }}
                />
              )}
              {editCompanyOnly && (
                <QBTypography style={{ marginTop: 2 }} color="secondary">
                  {saveName}
                </QBTypography>
              )}
            </div>
            {!editCompanyOnly && (
              <div className={classes.modalItem}>
                <div style={{ flexBasis: 75 }}></div>
                <StyledCheck checked={email} onChange={(e) => setEmail(!email)} />
                <div style={{ marginLeft: 4 }}>{Messages.LABEL.EMAIL_ALERTS}</div>
              </div>
            )}
          </div>
          {canEditInstitutions && (
            <div style={{ maxHeight: 400, minHeight: 400 }}>
              <InstitutionSelector
                open={open}
                setSelectedInstitutions={setSelectedInstitutions}
                selectedInstitutions={selectedInstitutions}
              />
            </div>
          )}
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "flex-start",
              marginTop: 40,
              gap: 10,
            }}
          >
            {!editCompanyOnly && (
              <>
                <div>
                  <MenuButton
                    disabled={isSaveDisabled}
                    size="small"
                    color="primary"
                    variant="outlined"
                    disableFocusRipple={true}
                    onClick={handleAddSearch}
                  >
                    {Messages.LABEL.SAVE_AS_NEW_SEARCH}
                  </MenuButton>
                  {search && saveName === search?.description && (
                    <div style={{ maxWidth: 150, marginTop: 5 }} className={classes.info}>
                      {Messages.MESSAGE.VALIDATION.SEARCH_NAME_DIFFERENT}
                    </div>
                  )}
                </div>

                {utils.hasNonEmptyValue(search) && (
                  <MenuButton
                    disabled={isUpdateDisabled}
                    size="small"
                    color="secondary"
                    variant="outlined"
                    disableFocusRipple={true}
                    onClick={handleUpdateSearch}
                  >
                    {Messages.LABEL.UPDATE_CURRENT_SEARCH}
                  </MenuButton>
                )}
              </>
            )}
            {editCompanyOnly && (
              <div>
                <MenuButton
                  size="small"
                  color="secondary"
                  variant="outlined"
                  disableFocusRipple={true}
                  onClick={handleUpdateSearch}
                  disabled={selectedInstitutions.length === 0}
                >
                  {Messages.LABEL.UPDATE_INSTITUTIONS}
                </MenuButton>
                {selectedInstitutions.length === 0 && (
                  <div style={{ maxWidth: 180, marginTop: 5 }} className={classes.error}>
                    {Messages.MESSAGE.VALIDATION.INSTITUTIONS_REQUIRED}
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      </ClosableModal>
    </>
  ) : null;
};

SaveSearchModal.propTypes = {
  open: PropTypes.bool,
  validationNames: PropTypes.arrayOf(PropTypes.string),
  search: PropTypes.shape({
    id: PropTypes.number.isRequired,
    description: PropTypes.string.isRequired,
    emailEnabled: PropTypes.bool.isRequired,
    searchLastExecutedDate: PropTypes.string,
  }),
  editCompanyOnly: PropTypes.bool,
  onUpdate: PropTypes.func.isRequired,
  onAdd: PropTypes.func.isRequired,
};

export default SaveSearchModal;

const useInstitutionSelectorStyles = makeStyles((theme) => ({
  header: {
    fontSize: "0.65rem",
    color: theme.palette.text.popper.header,
    lineHeight: 1,
    marginBottom: 4,
    textTransform: "uppercase",
    letterSpacing: "0.07em",
    fontWeight: 800,
  },
  selectedContainer: {
    padding: 5,
    backgroundColor: theme.palette.background.main,
  },
}));

const InstitutionSelector = ({ open, setSelectedInstitutions, selectedInstitutions }) => {
  const classes = useInstitutionSelectorStyles();
  const Messages = useMessages();
  const user = getUser();

  const [institutionKeyword, setInstitutionKeyword] = React.useState("");
  const [filteredInstitutions, setFilteredInstitutions] = React.useState([]);
  const [touched, setTouched] = React.useState(false);

  let affiliateInstitutions = getAffiliateInstitutions();
  if (affiliateInstitutions) {
    affiliateInstitutions = sortBy(affiliateInstitutions, "companyName");
  }

  React.useEffect(() => {
    if (open) {
      setInstitutionKeyword("");
    } else {
      setTouched(false);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  React.useEffect(() => {
    if (touched) {
      filterInstitutions(institutionKeyword);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedInstitutions, institutionKeyword]);

  const findInstitution = (value) => {
    if (!value) return null;
    return affiliateInstitutions.find(
      (i) => deburr(i.companyName.trim().toLowerCase()) === deburr(value.trim().toLowerCase()),
    );
  };

  const filterInstitutions = (value) => {
    const selectedInstitutionIds = selectedInstitutions.map((i) => i.companyId);
    if (!value) {
      setFilteredInstitutions(
        affiliateInstitutions
          .filter((i) => !selectedInstitutionIds.includes(i.companyId))
          .map((i) => i.companyName),
      );
    } else {
      const filtered = affiliateInstitutions
        .filter(
          (i) =>
            i.companyName.split(" ").find((part) => isTypeaheadEqual(part, value)) &&
            !selectedInstitutionIds.includes(i.companyId),
        )
        .map((i) => i.companyName);
      setFilteredInstitutions(filtered);
    }
  };

  const handleInstitutionDeleted = (institution) => {
    setSelectedInstitutions((institutions) => {
      return institutions.filter((selected) => selected.companyId !== institution.companyId);
    });
    setFilteredInstitutions([]);
    setTouched(false);
  };

  const handleRemoveAll = () => {
    setSelectedInstitutions([]);
    setFilteredInstitutions([]);
    setTouched(false);
  };

  const handleSelectAll = () => {
    setSelectedInstitutions(affiliateInstitutions);
    setFilteredInstitutions([]);
    setTouched(false);
  };

  const handleInstitutionSelected = (value) => {
    const institution = findInstitution(value);
    if (institution) {
      setSelectedInstitutions((institutions) => {
        institutions.push(institution);
        return [...institutions];
      });
    } else {
      setFilteredInstitutions([]);
      setTouched(false);
    }
  };

  const handleInstitutionKeywordChange = (value, typeaheadState) => {
    if (typeaheadState.selectedItem !== value) {
      setInstitutionKeyword(value);
    }
  };

  const handleInstitutionInputClick = () => {
    setTouched(true);
    filterInstitutions(institutionKeyword);
  };

  const handleCloseInstitutionPopper = (value) => {
    setFilteredInstitutions([]);
    setTouched(false);
  };

  return utils.hasNonEmptyValue(user) ? (
    <div style={{ marginTop: 30 }}>
      <QBTypography variant="caption">{Messages.LABEL.SELECT_ACCOUNTS_FOR_SAVED_SEARCH}</QBTypography>
      <div style={{ marginTop: 20, display: "flex", gap: 40 }}>
        {affiliateInstitutions && (
          <>
            <div style={{ marginBottom: 20, width: 300 }}>
              <div className={classes.header}>{Messages.LABEL.AVAILABLE_INSTITUTIONS}</div>
              <Typeahead
                value={institutionKeyword}
                onSubmitSearch={handleInstitutionSelected}
                onInputValueChange={handleInstitutionKeywordChange}
                onInputClick={handleInstitutionInputClick}
                onClosePopper={handleCloseInstitutionPopper}
                onClickAway={handleCloseInstitutionPopper}
                placeholder={Messages.LABEL.SEARCH_INSTITUTIONS}
                suggestions={filteredInstitutions}
                alwaysRenderSuggestions={true}
                size="large"
              />
              <LinkButton
                disabled={affiliateInstitutions.length === selectedInstitutions.length}
                label={Messages.LABEL.ADD_ALL_INSTITUTIONS}
                onClick={handleSelectAll}
              ></LinkButton>
            </div>
            <div style={{ marginBottom: 20, width: 300 }}>
              <div className={classes.header}>{Messages.LABEL.SELECTED_INSTITUTIONS}</div>
              <div
                className={classes.selectedContainer}
                style={{ minHeight: 270, maxHeight: 270, overflowY: "auto" }}
              >
                {sortBy(selectedInstitutions, "companyName").map((i) => {
                  return (
                    <div
                      key={i.companyId}
                      style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}
                    >
                      <QBTypography
                        component="div"
                        style={{ whiteSpace: "nowrap", textOverflow: "ellipsis", overflow: "hidden" }}
                      >
                        <span>{i.companyName}</span>
                      </QBTypography>

                      <div>
                        <DeleteIcon
                          onClick={() => handleInstitutionDeleted(i)}
                          color="error"
                          style={{ fontSize: "0.85rem", marginLeft: 5, cursor: "pointer" }}
                        />
                      </div>
                    </div>
                  );
                })}
              </div>

              <LinkButton
                disabled={selectedInstitutions?.length < 1}
                onClick={handleRemoveAll}
                label={Messages.LABEL.REMOVE_ALL_INSTITUTIONS}
              ></LinkButton>
            </div>
          </>
        )}
      </div>
    </div>
  ) : null;
};
