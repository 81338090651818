import axios from "axios";
import * as Sentry from "@sentry/react";

import { getAccessToken, logoutUser, hasAccessToken, getUser, removeUser } from "commons/helpers/userStorage";
import * as utils from "commons/utils";
import { MESSAGE } from "commons/messages";
import * as Constants from "commons/constants";
import { USER_LOGGED_IN_CHECK_ENDPOINT } from "config/dataconfig";

const common_axios_parms = {
  baseURL: process.env.REACT_APP_API_URL,
  withCredentials: true,
  timeout: 20000,
};

const httpService = axios.create(common_axios_parms);

const httpServiceWithoutResponseInterceptors = axios.create(common_axios_parms);

httpService.interceptors.request.use(
  (config) => {
    if (config.query) {
      const user = getUser();
      const permsRequired = Constants.QUERY_TYPE_PERMISSIONS[config.query.type] || null;
      // Remember this permission check is more advisory for UX and needs to still
      // be enforced by the backend with a 401 if its attempted by this user
      if (!utils.userHasAnyPermission(user, permsRequired)) {
        console.log("Not going to query");
        return false;
      }
    }
    const token = getAccessToken();

    if (!utils.hasNonEmptyValue(token)) {
      removeUser();
      return;
    }

    config.headers.Authorization = `Bearer ${token}`;
    return config;
  },
  (error) => {
    // eslint-disable-next-line no-console
    console.error(error);
    Sentry.captureException(error, (scope) => {
      if (error.request) {
        scope.setContext("request", error.request);
      }
    });
    return Promise.reject(error);
  },
);

httpServiceWithoutResponseInterceptors.interceptors.request = httpService.interceptors.request;

httpService.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response) {
      const { response } = error;

      if (
        response.status === Constants.HTTP_CODES.BAD_REQUEST &&
        response.config?.url?.indexOf("/savedfavorite/") > -1
      ) {
        return;
      }

      if (
        !hasAccessToken() ||
        response.status === Constants.HTTP_CODES.UNAUTHORIZED ||
        response.status === Constants.HTTP_CODES.FORBIDDEN
      ) {
        actuallyLoggedIn();
      } else if (response.status !== Constants.HTTP_CODES.NOT_FOUND) {
        Sentry.captureException(error, (scope) => {
          if (response.data) {
            scope.setContext("response", response.data);
          }
        });
        utils.issueErrorAlert(MESSAGE.FETCH_DATA_ERROR);
      }
    }

    return Promise.reject(error);
  },
);

// A raw 401 response might be that the user level is incorrect, not that we are logged out,
// so defer to a method that will more reliably tell us we are logged out
export const actuallyLoggedIn = async () => {
  // Only if the logged in endpoint returns a 401 will we actually log the user out.
  httpServiceWithoutResponseInterceptors.get(USER_LOGGED_IN_CHECK_ENDPOINT).catch((error) => logoutUser());
};

export default httpService;
