import React from "react";
import PropTypes from "prop-types";

import { makeStyles } from "@material-ui/core/styles";
import QBTypography from "components/QBTypography";

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: 10,
    padding: 10,
    minWidth: 400,
    border: "1px solid",
    borderColor: theme.palette.border.subtle,
    borderRadius: theme.shape.borderRadius,
    background: theme.palette.background.subtle,
    maxWidth: 800,
  },
  announcement: {
    paddingTop: 5,
    paddingBottom: 5,
    "& p": {
      margin: "4px 0",
    },
  },
}));

// COMPONENT
const AnnouncementContentWrapper = React.forwardRef((props, ref) => {
  const classes = useStyles();
  const { messages } = props;

  return (
    <div className={classes.root}>
      {messages.map((msg, i) => {
        return (
          <QBTypography component="div" className={classes.announcement} key={i}>
            {msg.split("\n").map((item, j) => (
              <p key={j} dangerouslySetInnerHTML={{ __html: item }} />
            ))}
          </QBTypography>
        );
      })}
    </div>
  );
});

AnnouncementContentWrapper.propTypes = {
  messages: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default AnnouncementContentWrapper;
