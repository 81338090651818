import React from "react";
import PropTypes from "prop-types";
import { CSSTransition } from "react-transition-group";
import clsx from "clsx";

import { useMessages } from "providers/BrandingProvider";

import QBTypography from "components/QBTypography";
import LoginForm from "./LoginForm";
import ForgetPasswordForm from "./ForgetPasswordForm";
import NewAccountForm from "./NewAccountForm";
import { UnclosableModal } from "components/containers/Modals";

import { makeStyles } from "@material-ui/core/styles";

import logo from "assets/img/logo_md.png";

const useStyles = makeStyles((theme) => ({
  root: {
    minWidth: 275,
    width: 275,
    display: "flex",
    flexDirection: "column",
  },
  loginRoot: {
    minHeight: 240,
    transition: theme.transitions.create("min-height", {
      easing: theme.transitions.easing.easeIn,
      duration: 200,
    }),
  },
  forgetPasswordRoot: {
    minHeight: 210,
    transition: theme.transitions.create("min-height", {
      easing: theme.transitions.easing.easeIn,
      duration: 200,
    }),
  },
  newAccountRoot: {
    minHeight: 380,
    transition: theme.transitions.create("min-height", {
      easing: theme.transitions.easing.easeIn,
      duration: 200,
    }),
  },
  header: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    marginBottom: 20,
    alignItems: "center",
  },
}));

const MODAL_STATUS = {
  LOGIN: "LOGIN",
  FORGET_PASSWORD: "FORGET_PASSWORD",
  NEW_ACCOUNT: "NEW_ACCOUNT",
};

const Login = ({ open, onSuccess }) => {
  const classes = useStyles();
  const Messages = useMessages();

  const [modalStatus, setModalStatus] = React.useState(MODAL_STATUS.LOGIN);
  const [stagedStatus, setStagedStatus] = React.useState(MODAL_STATUS.LOGIN);

  const setStatus = () => {
    setModalStatus(stagedStatus);
  };

  const showLogin = () => {
    setStagedStatus(MODAL_STATUS.LOGIN);
  };

  const showForgetPassword = () => {
    setStagedStatus(MODAL_STATUS.FORGET_PASSWORD);
  };

  // const showNewAccount = () => {
  //    setStagedStatus(MODAL_STATUS.NEW_ACCOUNT);
  // }

  return (
    <UnclosableModal open={open}>
      <div
        className={clsx(classes.root, {
          [classes.loginRoot]: modalStatus === MODAL_STATUS.LOGIN,
          [classes.forgetPasswordRoot]: modalStatus === MODAL_STATUS.FORGET_PASSWORD,
          [classes.newAccountRoot]: modalStatus === MODAL_STATUS.NEW_ACCOUNT,
        })}
      >
        <div className={classes.header}>
          <img src={logo} alt="QwickBonds" />
          <QBTypography style={{ marginTop: 5, fontStyle: "italic" }} variant="body1">
            <span>
              {Messages.LABEL.PROVIDED_BY} {Messages.BRANDING.PROVIDER_NAME}
            </span>
          </QBTypography>
        </div>
        {modalStatus === MODAL_STATUS.LOGIN && (
          <CSSTransition
            mountOnEnter
            appear
            timeout={{ enter: 500, exit: 100 }}
            onExited={setStatus}
            classNames="transition-fade"
            in={modalStatus === MODAL_STATUS.LOGIN && stagedStatus === MODAL_STATUS.LOGIN}
          >
            <LoginForm onSuccess={onSuccess} onForgetPasswordClick={showForgetPassword} />
          </CSSTransition>
        )}
        {modalStatus === MODAL_STATUS.FORGET_PASSWORD && (
          <CSSTransition
            mountOnEnter
            appear
            timeout={{ enter: 500, exit: 100 }}
            onExited={setStatus}
            classNames="transition-fade"
            in={modalStatus === MODAL_STATUS.FORGET_PASSWORD && stagedStatus === MODAL_STATUS.FORGET_PASSWORD}
          >
            <ForgetPasswordForm onLoginClick={showLogin} />
          </CSSTransition>
        )}
        {modalStatus === MODAL_STATUS.NEW_ACCOUNT && (
          <CSSTransition
            mountOnEnter
            appear
            timeout={{ enter: 500, exit: 100 }}
            onExited={setStatus}
            classNames="transition-fade"
            in={modalStatus === MODAL_STATUS.NEW_ACCOUNT && stagedStatus === MODAL_STATUS.NEW_ACCOUNT}
          >
            <NewAccountForm onSuccess={showLogin} onLoginClick={showLogin} />
          </CSSTransition>
        )}
      </div>
    </UnclosableModal>
  );
};

Login.propTypes = {
  open: PropTypes.bool.isRequired,
  onSuccess: PropTypes.func.isRequired,
};

export default Login;
