export const Common = {
  drawerWidth: 175,
  shape: {
    borderRadius: 5,
  },
  padding: {
    paper: 10,
  },
  overrides: {
    MuiPickersStaticWrapper: {
      staticWrapperRoot: {
        minWidth: 220,
        minHeight: 200,
        borderRadius: 4,
        border: "1px solid rgba(0,0,0, 0.05)",
        "&:first-child": {
          marginRight: 8,
        },
      },
    },
    MuiPickersBasePicker: {
      pickerView: {
        minWidth: 220,
        minHeight: 180,
      },
    },

    MuiPickersMonthSelection: {
      container: {
        width: 220,
      },
    },

    MuiPickersYearSelection: {
      container: {
        height: 180,
      },
    },

    MuiPickersCalendar: {
      transitionContainer: {
        minHeight: 146,
      },
    },

    MuiPickersCalendarHeader: {
      dayLabel: {
        width: 24,
        color: "rgba(90, 90, 90, 0.95)",
      },
      switchHeader: {
        margin: 0,
        "& p": {
          fontSize: "0.8rem",
        },
      },
      transitionContainer: {
        "& p": {
          fontSize: "0.8rem",
        },
      },
      iconButton: {
        fontSize: "1.0rem",
        padding: 4,
        backgroundColor: "transparent",
      },
    },
    MuiPickersMonth: {
      root: {
        height: 35,
      },
      monthSelected: {
        fontSize: "1rem",
        lineHeight: 1.75,
        color: "#00c109",
      },
    },
    MuiPickersYear: {
      root: {
        height: 30,
      },
      yearSelected: {
        fontSize: "1rem",
        lineHeight: 1.75,
        margin: 0,
        color: "#00c109",
      },
    },
    MuiPickersDay: {
      day: {
        height: 24,
        width: 24,
      },
      daySelected: {
        color: "#fff",
        backgroundColor: "#00c109",
      },
      dayDisabled: {
        backgroundColor: "transparent",
      },
      current: {
        color: "#00c109",
      },
    },
  },
};
