import React from "react";
import { useGlobal } from "reactn";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import * as Sentry from "@sentry/react";

import ApplicationLoading from "pages/ApplicationLoading";
import * as Constants from "commons/constants";
import NotificationCenter from "components/notifications/NotificationCenter";
import ApplicationLayout from "pages/ApplicationLayout";
import { ViewStateProvider } from "providers/ViewStateProvider";
import { LightTheme } from "theme/LightTheme";
import { DarkTheme } from "theme/DarkTheme";
import { setPrefsFromCookie } from "services/PreferencesService";
import { setInstitutionFromStoredValue } from "commons/helpers/userStorage";
import routes from "config/routes";

import CssBaseline from "@material-ui/core/CssBaseline";
import { ThemeProvider } from "@material-ui/styles";

import "assets/css/app.scss";
import "assets/css/app_dark.scss";
import "assets/css/app_light.scss";
import { BrandingProvider } from "providers/BrandingProvider";

const App = () => {
  const [userPrefs = {}] = useGlobal("userprefs");
  const [affiliate] = useGlobal("affiliate");

  const mode = userPrefs.theme && userPrefs.theme === "dark" ? DarkTheme : LightTheme;
  const className = userPrefs.theme && userPrefs.theme === "dark" ? "darkMode" : "lightMode";

  // Allows dark/light mode to set before user logs in
  React.useEffect(() => {
    setPrefsFromCookie();
    setInstitutionFromStoredValue();
  }, []);

  let initialState = {};

  // if this is a navigable route, check session to restore state
  if (routes.find((r) => r.path === window.location.pathname)) {
    const state = window.sessionStorage.getItem(Constants.STORAGE_SESSION_STATE);
    initialState = state ? JSON.parse(state) : {};
  }

  const ErrorFallback = <ApplicationLoading hasError={true} />;

  return (
    <ThemeProvider theme={mode}>
      <CssBaseline />
      <div className={className}>
        <BrandingProvider affiliate={affiliate}>
          <NotificationCenter />
          <MuiPickersUtilsProvider utils={MomentUtils}>
            <ViewStateProvider initialState={initialState}>
              <Sentry.ErrorBoundary fallback={ErrorFallback}>
                <ApplicationLayout />
              </Sentry.ErrorBoundary>
            </ViewStateProvider>
          </MuiPickersUtilsProvider>
        </BrandingProvider>
      </div>
    </ThemeProvider>
  );
};

export default App;
