import React from "react";
import PropTypes from "prop-types";
import moment from "moment-timezone";

import { useMessages } from "providers/BrandingProvider";
import * as Constants from "commons/constants";
import QBTypography from "components/QBTypography";
import { fetchMarketHours } from "services/UtilityService";
import { TooltipBadge } from "components/Controls";

import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  clock: {
    color: theme.palette.secondary.main,
    fontSize: "0.7rem",
    whiteSpace: "nowrap",
  },
}));

const RateFreshnessTime = ({ label, refreshedTime }) => {
  const classes = useStyles();
  const Messages = useMessages();

  const [marketClosedMessage, setMarketClosedMessage] = React.useState(false);
  const [time, setTime] = React.useState(null);

  const updateMessage = async () => {
    const marketHours = await fetchMarketHours();
    if (marketHours) {
      if (!marketHours.inQBHours) {
        const marketStatus = marketHours.marketClosing
          ? Constants.MARKET_STATUS.EOD
          : Constants.MARKET_STATUS.CLOSED;
        const message =
          marketStatus === Constants.MARKET_STATUS.CLOSED
            ? Messages.MESSAGE.MARKET_CLOSED
            : Messages.MESSAGE.MARKET_EOD.replace("{0}", marketHours.qwickBondsClosedTime);
        setMarketClosedMessage(message);
        setTime(null);
      } else {
        setTime(refreshedTime);
        setMarketClosedMessage(null);
      }
    } else {
      setTime(null);
      setMarketClosedMessage(null);
    }
  };

  React.useEffect(() => {
    if (refreshedTime) {
      updateMessage();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshedTime]);

  if (!refreshedTime) return null;

  return (
    <div style={{ display: "flex", justifyContent: "center" }}>
      {time && (
        <>
          {label && (
            <QBTypography
              component="div"
              color="textSecondary"
              variant="body1"
              style={{ marginRight: 4, fontSize: "0.7rem", lineHeight: 1.43 }}
            >
              {label}
            </QBTypography>
          )}
          <div className={classes.clock}>{moment(refreshedTime).format("h:mm:ss A z")} EST</div>
        </>
      )}
      {marketClosedMessage && (
        <QBTypography
          component="div"
          color="secondary"
          variant="body1"
          style={{ marginRight: 4, fontSize: "0.7rem", lineHeight: 1.43 }}
        >
          {Messages.MESSAGE.MARKET_CLOSED_SHORT} <TooltipBadge tooltip={marketClosedMessage} />
        </QBTypography>
      )}
    </div>
  );
};

RateFreshnessTime.propTypes = {
  label: PropTypes.string,
  refreshedTime: PropTypes.string,
};

export default RateFreshnessTime;
