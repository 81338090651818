import { setGlobal } from "reactn";
import Cookies from "universal-cookie";

import {
  STORAGE_ACCESS_TOKEN,
  STORAGE_USER,
  STORAGE_AFFILIATE,
  USER_PERMISSIONS,
  DEFAULT_AFFILIATE_ID,
} from "commons/constants";
import * as utils from "commons/utils";
import * as DataConfig from "config/dataconfig";

import HttpService from "services/commons/HttpService";
import { fullOAuth2ClientID } from "services/OAuth2ClientService";
import { STORAGE_LAST_ACTIVITY_TIME } from "commons/constants";
import { STORAGE_AFFILIATE_INSTITUTIONS } from "commons/constants";

const cookies = new Cookies();

const AUTHSERVER_URL = process.env.REACT_APP_AUTHSERVER_URL;
const OAUTH2_CLIENT_ID = fullOAuth2ClientID();
const isSSOEnabled = process.env.REACT_APP_FEATURES_SSO === "true";
const isBrandingEnabled = process.env.REACT_APP_FEATURES_AFFILIATE_BRANDING === "true";

export const storeAccessToken = (accessToken) => {
  cookies.set(STORAGE_ACCESS_TOKEN, accessToken, {
    path: "/",
    sameSite: "strict",
  });
  setGlobal({
    authenticated: true,
  });
};

export const getAccessToken = () => {
  return cookies.get(STORAGE_ACCESS_TOKEN);
};

export const removeUserToken = () => {
  window.sessionStorage.removeItem(STORAGE_USER);
  cookies.remove(STORAGE_ACCESS_TOKEN);
};

export const hasAccessToken = () => {
  return utils.hasNonEmptyValue(getAccessToken());
};

export const storeUser = (loggedInUser) => {
  window.sessionStorage.setItem(STORAGE_USER, JSON.stringify(loggedInUser));

  let affiliateId = DEFAULT_AFFILIATE_ID;

  if (isBrandingEnabled) {
    affiliateId = loggedInUser.affiliate?.affiliateIdentifier ?? DEFAULT_AFFILIATE_ID;

    if (loggedInUser.institution === "Bankers' Bank") {
      affiliateId = "BBNK";
    }
  }

  window.localStorage.setItem(STORAGE_AFFILIATE, affiliateId);
  setGlobal({ affiliate: affiliateId });
};

export const getAffiliateInstitutions = () => {
  const affiliates = window.sessionStorage.getItem(STORAGE_AFFILIATE_INSTITUTIONS);
  if (affiliates) {
    return JSON.parse(affiliates);
  }
  return null;
};

export const setInstitutionFromStoredValue = () => {
  const affiliate = window.localStorage.getItem(STORAGE_AFFILIATE);
  if (utils.hasNonEmptyValue(affiliate)) {
    setGlobal({ affiliate: affiliate });
  }
};

export const getUser = () => {
  let user = window.sessionStorage.getItem(STORAGE_USER);
  if (utils.hasNonEmptyValue(user)) {
    user = JSON.parse(user);
  } else {
    user = {};
  }

  // Set up particular UI permissions and make them into concrete booleans
  user[USER_PERMISSIONS.CAN_USE_FAVORITES_AND_TRACKERS] =
    utils.hasNonEmptyValue(user.canUseCompanyTrackers) && user.canUseCompanyTrackers;
  user[USER_PERMISSIONS.CAN_TRADE] = utils.hasNonEmptyValue(user.canTrade) && user.canTrade;
  user[USER_PERMISSIONS.CAN_VIEW_HISTORY] =
    utils.hasNonEmptyValue(user.canViewHistory) && user.canViewHistory;

  return user;
};

export const removeUser = () => {
  removeUserToken();
  setGlobal({
    authenticated: false,
  });
};

export const fetchUserInfo = (onUserFetchSuccess, onUserFetchError) => {
  HttpService.get(DataConfig.USER_INFO_ENDPOINT)
    .then((resp) => {
      if (resp.data) {
        storeUser(resp.data);
        if (resp.data.privileges?.includes(USER_PERMISSIONS.ROLE_AFFILIATE_ADMIN)) {
          HttpService.get(DataConfig.AFFILIATES_ENDPOINT).then((resp) => {
            if (resp.data) {
              window.sessionStorage.setItem(
                STORAGE_AFFILIATE_INSTITUTIONS,
                JSON.stringify(resp.data.content),
              );
            }
          });
        }
        onUserFetchSuccess();
      }
    })
    .catch((error) => {
      onUserFetchError(error);
    });
};

export const logoutUser = async () => {
  window.sessionStorage.setItem(STORAGE_LAST_ACTIVITY_TIME, null);
  removeUserToken();

  if (isSSOEnabled) {
    window.location.assign(`${AUTHSERVER_URL}/oauth/logout?client_id=${OAUTH2_CLIENT_ID}`);
  }
};
