import React from "react";
import { useGlobal } from "reactn";
import PropTypes from "prop-types";

import { useMessages } from "providers/BrandingProvider";
import * as utils from "commons/utils";
import * as Constants from "commons/constants";
import { getUser } from "commons/helpers/userStorage";
import { LabelledIconButton, StyledSecondaryTooltip } from "components/Controls";
import { addFavoriteIssuer, deleteFavoriteIssuer } from "services/FavoritesService";

import IconButton from "@material-ui/core/IconButton";
import TrackChangesIcon from "@material-ui/icons/TrackChanges";

const FavoriteIssuer = ({ bond, variant = "icon" }) => {
  const Messages = useMessages();
  const [favoriteIssuers = []] = useGlobal("favoriteIssuers");
  const favoriteIssuerCusips = Object.keys(favoriteIssuers);

  const user = getUser();
  const cusip = bond ? bond.cusip.substr(0, 6) : null;
  const [isFavorite, setFavorite] = React.useState(favoriteIssuerCusips.includes(cusip));

  React.useEffect(() => {
    if (utils.hasNonEmptyValue(cusip)) {
      setFavorite(favoriteIssuerCusips.includes(cusip));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cusip, favoriteIssuerCusips]);

  const handleFavoriteClick = () => {
    if (user[Constants.USER_PERMISSIONS.CAN_USE_FAVORITES_AND_TRACKERS]) {
      if (isFavorite) {
        deleteFavoriteIssuer(cusip);
      } else {
        const issuer = {
          cusip: cusip,
          instrumentTypes: bond.instrumentType ? [bond.instrumentType] : null,
        };

        addFavoriteIssuer(issuer);
      }
      setFavorite(!isFavorite);
    } else {
      utils.issueWarningAlert(Messages.MESSAGE.MUST_BE_A_TRADER_FEATURE);
    }
  };

  if (!bond) return null;

  if (!utils.featureFlagEnabled("REACT_APP_FEATURES_ISSUER_TRACKER")) {
    return null;
  }

  return variant === "icon" ? (
    <IconButton size="small" onClick={handleFavoriteClick}>
      {isFavorite && (
        <StyledSecondaryTooltip title={`Remove ${cusip} from Issuer Tracker`}>
          <TrackChangesIcon color="secondary" fontSize="small" />
        </StyledSecondaryTooltip>
      )}
      {!isFavorite && (
        <StyledSecondaryTooltip title={`Add ${cusip} to Issuer Tracker`}>
          <TrackChangesIcon color="action" fontSize="small" />
        </StyledSecondaryTooltip>
      )}
    </IconButton>
  ) : (
    <LabelledIconButton
      key="importIssuerButton"
      onClick={handleFavoriteClick}
      variant="outlined"
      color="secondary"
      startIcon={<TrackChangesIcon color={isFavorite ? "secondary" : "action"} />}
    >
      {isFavorite ? `Remove ${cusip} from Issuer tracker` : `Add ${cusip} to Issuer Tracker`}
    </LabelledIconButton>
  );
};

FavoriteIssuer.propTypes = {
  bond: PropTypes.object,
};

export default FavoriteIssuer;
