import React from "react";
import ReactTable from "react-table";
import PropTypes from "prop-types";
import { cloneDeep, isEqual } from "lodash";

import * as Constants from "commons/constants";
import * as utils from "commons/utils";
import { useDataService } from "services/DataService";

import ArrowLeftIcon from "@material-ui/icons/ArrowLeft";
import ArrowRightIcon from "@material-ui/icons/ArrowRight";
import CircularProgress from "@material-ui/core/CircularProgress";
import IconButton from "@material-ui/core/IconButton";
import { withStyles } from "@material-ui/core/styles";

const PaginationIconButton = withStyles((theme) => ({
  root: {
    padding: 0,
  },
}))(IconButton);

export const Loading = (props) => {
  const { loading, loadingText, ...other } = props;

  if (!loading) return null;

  return (
    <div className="-loading -active" {...other}>
      <div className="-loading-inner">
        <CircularProgress color="secondary" />
      </div>
    </div>
  );
};

Loading.propTypes = {
  loading: PropTypes.bool.isRequired,
  loadingText: PropTypes.string,
};

export const InnerLoading = (props) => {
  const { loading, loadingText, ...other } = props;

  if (!loading) return null;

  return (
    <div className="-loading -active" {...other}>
      <div className="-loading-inner">
        <CircularProgress size={18} color="secondary" />
      </div>
    </div>
  );
};

InnerLoading.propTypes = {
  loading: PropTypes.bool.isRequired,
  loadingText: PropTypes.string,
};

export const PreviousButton = (props) => {
  const { onClick, disabled } = props;
  return (
    <PaginationIconButton onClick={onClick} disabled={disabled}>
      <ArrowLeftIcon color={disabled ? "disabled" : "secondary"} size="small" />
    </PaginationIconButton>
  );
};

PreviousButton.propTypes = {
  disabled: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
};

export const NextButton = (props) => {
  const { onClick, disabled } = props;
  return (
    <PaginationIconButton onClick={onClick} disabled={disabled}>
      <ArrowRightIcon color={disabled ? "disabled" : "secondary"} size="small" />
    </PaginationIconButton>
  );
};

NextButton.propTypes = {
  disabled: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
};

export const InnerTable = React.memo((props) => {
  const {
    tableColumns,
    bondId,
    queryFilter,
    selectedBond,
    onRowSelected,
    data,
    queryType,
    numSimilarCusips,
  } = props;
  const [state, queryDataService] = useDataService(data);
  const selectable = utils.hasNonEmptyValue(onRowSelected);
  const [tableData, setTableData] = React.useState(data);

  const ref = React.useRef();

  // initial query after component mount
  React.useEffect(() => {
    if (bondId) {
      const queryParams = {
        type: Constants.QUERY_TYPE.SIMILAR,
        filter: queryFilter ? cloneDeep(queryFilter) : {},
        identifier: {
          filterKey: "id",
          filterValue: bondId,
        },
        sort: [
          { id: "price", desc: false },
          { id: "quantity", desc: true },
        ],
        clearDataOnFetch: false,
        size: 500,
      };

      delete queryParams.filter["instrumentCategory"];
      queryDataService(queryParams);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bondId, queryFilter, numSimilarCusips]);

  React.useEffect(() => {
    if (utils.hasNonEmptyValue(data)) {
      const queryParams = {
        clearDataOnFetch: false,
        type: queryType,
      };

      queryDataService(queryParams);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  React.useEffect(() => {
    if (state.isLoading || state.isError || state.isInit) {
      return;
    }

    if (!isEqual(JSON.stringify(state.data), JSON.stringify(tableData))) {
      setTableData(state.data);
      ref.current = state.data;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.data]);

  const pageSize = utils.hasNonEmptyValue(state.data) ? state.data.length : 1;

  return (
    <ReactTable
      className="datatable-inner"
      columns={tableColumns}
      data={tableData}
      minRows={pageSize}
      defaultPageSize={500}
      loading={state.isLoading}
      LoadingComponent={InnerLoading}
      noDataText=""
      resizable={false}
      filterable={false}
      sortable={false}
      sorted={state.query.sort}
      showPagination={false}
      expanderDefaults={{
        sortable: false,
        resizable: false,
        filterable: false,
      }}
      getTheadProps={(tableState, columnInfo) => {
        return {
          className: "-nofilters -hidden",
        };
      }}
      getTheadFilterProps={(tableState, columnInfo) => {
        return {
          className: "-hidden",
        };
      }}
      getTrProps={(tableState, rowInfo) => {
        if (!selectable || !utils.hasNonEmptyValue(selectedBond) || !utils.hasNonEmptyValue(rowInfo)) {
          return {};
        }

        let classNames = rowInfo.original.id === selectedBond.id ? "selected" : "";

        if (utils.hasNonEmptyValue(rowInfo.original.cusip)) {
          classNames += " selectable";
        }

        return {
          className: classNames,
        };
      }}
      getTdProps={(state, rowInfo, column, instance) => {
        if (
          rowInfo &&
          rowInfo.original &&
          selectable &&
          column.className !== "checkCell" &&
          column.className !== "controlCell" &&
          !column.expander
        ) {
          return {
            onClick: (e) => onRowSelected(rowInfo.original),
          };
        } else if (column.expander) {
          return { style: { visibility: "hidden" } };
        } else {
          return {};
        }
      }}
      getNoDataProps={(tableState) => {
        return { style: { display: "none" } };
      }}
    />
  );
});

InnerTable.propTypes = {
  tableColumns: PropTypes.arrayOf(PropTypes.object),
  data: PropTypes.arrayOf(PropTypes.object),
  queryState: PropTypes.object,
  bondId: PropTypes.number,
  bidId: PropTypes.number,
  selectedBond: PropTypes.object,
  onRowSelected: PropTypes.func,
};
