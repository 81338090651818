import React from "react";
import { cloneDeep } from "lodash";

import brandingConfigs from "config/affiliate.json";
import defaultMsgs from "commons/messages"; // TODO: move to /message
import * as Constants from "commons/constants";

const BrandingContext = React.createContext();
const MessageContext = React.createContext();

function BrandingProvider({ affiliate, children }) {
  const [messages, setMessages] = React.useState(defaultMsgs);

  React.useEffect(() => {
    importMessages(affiliate);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [affiliate]);

  const importMessages = async (affiliate) => {
    const messages = await getMessages(affiliate);
    setMessages(messages);
  };

  const getBrandingConfig = (affiliateId) => {
    if (brandingConfigs) {
      if (affiliateId && brandingConfigs.hasOwnProperty(affiliateId)) {
        return brandingConfigs[affiliateId];
      } else {
        return brandingConfigs[Constants.DEFAULT_AFFILIATE_ID];
      }
    }
    return;
  };

  const getMessages = async (affiliateId) => {
    const brandingConfig = getBrandingConfig(affiliateId);
    const msgs = cloneDeep(defaultMsgs);

    if (brandingConfig && brandingConfig.id !== Constants.DEFAULT_AFFILIATE_ID) {
      const customMsgs = await import(`messages/${brandingConfig.id}`);

      Object.keys(msgs).forEach((k) => {
        const custom = customMsgs.default[k] || {};
        msgs[k] = { ...msgs[k], ...custom };
      });
    }

    return msgs;
  };

  const branding = getBrandingConfig(affiliate);

  return (
    <BrandingContext.Provider value={branding}>
      <MessageContext.Provider value={messages}>{children}</MessageContext.Provider>
    </BrandingContext.Provider>
  );
}

function useBranding() {
  const context = React.useContext(BrandingContext);
  if (context === undefined) {
    throw new Error("useBranding must be used within a BrandingProvider");
  }
  return context;
}

function useMessages() {
  const context = React.useContext(MessageContext);
  if (context === undefined) {
    throw new Error("useMessages must be used within a BrandingProvider");
  }
  return context;
}

export { BrandingProvider, useBranding, useMessages };
