import React from "react";
import PropTypes from "prop-types";

import Paper from "@material-ui/core/Paper";
import Box from "@material-ui/core/Box";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";

import { makeStyles, withStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "inherit",
    padding: "0 10px",
    outline: 0,
  },
  paperToolbar: {
    display: "flex",
    paddingTop: 0,
    paddingRight: 0,
    padding: 0,
    minHeight: 42,
    height: 42,
    overflow: "hidden",
  },
  paperContent: {
    paddingTop: 10,
    height: "calc(100% - 42px)",
    width: "100%",
    display: "flex",
  },
  toolbar: {
    height: 36,
    display: "flex",
    alignItems: "center",
  },
  toolbarSection: {
    flexGrow: 1,
    margin: "auto",
    justifyContent: "center",
    alignItems: "center",
    display: "flex",
    "&:first-child": {
      flexGrow: 2,
      marginLeft: 10,
      display: "flex",
      justifyContent: "flex-start",
    },
    "&:last-child": {
      display: "flex",
      justifyContent: "flex-end",
      marginRight: 10,
      flexGrow: 2,
    },
  },
}));

export const Div = ({ style, ...otherProps }) => (
  <div style={{ ...{ height: "100%", width: "100%" }, ...style }} {...otherProps} />
);

export const MainPaper = withStyles((theme) => ({
  root: {
    padding: theme.padding.paper,
    outline: 0,
    height: "100%",
  },
}))(Paper);

export const MainPaperWithHeader = (props) => {
  const { headerItems, children } = props;
  const classes = useStyles();

  return (
    <Paper className={classes.root}>
      <div className={classes.paperToolbar}>{headerItems}</div>
      <div className={classes.paperContent}>{children}</div>
    </Paper>
  );
};

MainPaperWithHeader.propTypes = {
  children: PropTypes.node.isRequired,
};

export const SearchContainer = withStyles((theme) => ({
  root: {
    border: "1px solid",
    borderColor: theme.palette.border.main,
    borderRadius: theme.shape.borderRadius,
    background: theme.palette.background.subtle,
    padding: 10,
    color: theme.palette.text.primary,
    textAlign: "center",
    margin: "auto",
  },
}))(Box);

export const TabbedCard = (props) => {
  const { children, headerItems = [], ...otherProps } = props;
  const classes = useStyles();

  return (
    <Card className={classes.root} {...otherProps}>
      <div className={classes.toolbar}>
        {headerItems.map((item, i) => {
          return (
            <div key={item.key} className={classes.toolbarSection}>
              {item}
            </div>
          );
        })}
      </div>
      {children}
    </Card>
  );
};

TabbedCard.propTypes = {
  children: PropTypes.node.isRequired,
  headerItems: PropTypes.arrayOf(PropTypes.node),
};

export const SidebarCard = withStyles((theme) => ({
  root: {
    height: "inherit",
  },
}))(Card);

export const SidebarCardContent = withStyles((theme) => ({
  root: {
    padding: theme.padding.paper,
    height: "100%",
    width: "100%",
    "&:last-child": {
      padding: theme.padding.paper,
    },
  },
}))(CardContent);

export const TabbedCardContent = withStyles((theme) => ({
  root: {
    padding: theme.padding.paper,
    paddingTop: 0,
    paddingBottom: 0,
    height: "calc(100% - 36px)",
    display: "flex",
    flexWrap: "wrap",
    "&:last-child": {
      paddingBottom: 0,
    },
  },
}))(CardContent);
