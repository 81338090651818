import React from "react";

import * as Constants from "commons/constants";
import * as utils from "commons/utils";
import { useViewStateDispatcher } from "providers/ViewStateProvider";
import { Div, MainPaper } from "components/containers/Containers";
import QBTypography from "components/QBTypography";
import RequestInfo from "components/modals/RequestInfo";

import { makeStyles } from "@material-ui/core/styles";
import { useMessages, useBranding } from "providers/BrandingProvider";

const useStyles = makeStyles((theme) => ({
  section: {
    marginBottom: 40,
    marginTop: 25,
  },
  sectionTitle: {
    marginTop: 10,
    marginBottom: 10,
  },
  titlesection: {
    marginTop: 10,
    marginBottom: 10,
  },
  paragraph: {
    marginTop: 15,
    marginBottom: 15,
  },
  subsection: {
    marginTop: 20,
  },
  name: {
    fontWeight: 500,
    fontSize: "0.9rem",
  },
  phone: {
    color: theme.palette.primary.dark,
  },
}));

const REFS = {
  team: React.createRef(),
  disclaimers: React.createRef(),
  quotes: React.createRef(),
  bids: React.createRef(),
  offers: React.createRef(),
  orders: React.createRef(),
};

let SCROLL_TIMEOUT = null;

export const HelpView = ({ location }) => {
  const classes = useStyles();
  const dispatchViewStateChange = useViewStateDispatcher();
  const Messages = useMessages();
  const branding = useBranding();

  const isQuotesEnabled =
    process.env.REACT_APP_FEATURES_QUOTES === "true" &&
    utils.hasNonEmptyValue(Messages.MESSAGE.DISCLAIMERS[Constants.TRADE_TYPE.PRICE_QUOTES]);
  const isBidsEnabled =
    process.env.REACT_APP_FEATURES_BIDS === "true" &&
    utils.hasNonEmptyValue(Messages.MESSAGE.DISCLAIMERS[Constants.TRADE_TYPE.BIDS_RFQ]);
  const isOfferEnabled =
    process.env.REACT_APP_FEATURES_OFFER === "true" &&
    utils.hasNonEmptyValue(Messages.MESSAGE.DISCLAIMERS[Constants.TRADE_TYPE.BONDS_FOR_SALE]);

  // Returns an array of QBTypographies so that we can effectively render many paragraphs as
  // well as make it manageable in the messages file to store the paragraph breaks.
  const QBTypographyParagraphs = ({ textArray }) => {
    const qbTypographies = textArray.map((text, index) => {
      return (
        <QBTypography key={index} className={classes.paragraph}>
          {text}
        </QBTypography>
      );
    });
    return <div>{qbTypographies}</div>;
  };

  React.useEffect(() => {
    dispatchViewStateChange({
      state: { activeView: Constants.VIEW.HELP },
      view: Constants.VIEW.HELP,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    if (SCROLL_TIMEOUT) {
      SCROLL_TIMEOUT = null;
    }

    SCROLL_TIMEOUT = window.setTimeout(function () {
      const hash = location.hash.substring(1);
      if (REFS.hasOwnProperty(hash) && REFS[hash].current) {
        REFS[hash].current.scrollIntoView();
      }
    }, 10);

    return function () {
      SCROLL_TIMEOUT = null;
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.hash]);

  return (
    <Div>
      <MainPaper style={{ padding: 0 }}>
        <Div style={{ overflow: "auto", padding: "10px 20px" }}>
          <div className={classes.section}>
            <QBTypography className={classes.paragraph} component="div" variant="body2">
              The Help Desk is available to serve you by answering questions and providing additional
              information.For questions, additional information, if you have an immediate need to place or
              resolve a trade, or questions about a specific bond please call the Help Desk at{" "}
              <span className={classes.phone}>{Messages.LABEL.TRADE_DESK_PHONE}</span> or{" "}
              <RequestInfo variant="hyperlink" linkLabel={`${Messages.MESSAGE.SEND_MESSAGE}.`} />
            </QBTypography>
          </div>
          {branding.isQwickBonds && (
            <div className={classes.section}>
              <QBTypography
                className={classes.sectionTitle}
                variant="h3"
                color="primary"
                gutterBottom
                ref={REFS.team}
              >
                QwickBonds Team
              </QBTypography>
              <QBTypography className={classes.titlesection} component="div" variant="body2">
                <div>
                  <span className={classes.name}>Matthew Coe</span>
                </div>
                <div>
                  Managing Director, Head of QwickBonds <br />
                  MCAP, LLC.
                </div>
                <div>
                  <span className={classes.phone}>312-506-8755</span>{" "}
                  <span>{utils.formatEmail("mcoe@qwickbonds.com")}</span>
                </div>
              </QBTypography>
              <QBTypography className={classes.paragraph} component="div">
                Matthew Coe created QwickBonds in 2016, as part of QwickRate and Monroe Financial, MCAP, LLC
                purchased Qwickbonds in 2023 and continues to partner with QwickRate.
                With over 20 years of investment experience, Matthew provides guidance to financial
                institutions on investment portfolio management and fixed income portfolio strategies.
                Matthew leads the development of QwickBonds and the QwickRate Marketplace CD Rate Alert feature.
                Previously, Matthew served as Vice President of City Securities Corporation, advising portfolio
                managers on fixed-income strategies, portfolio management, fixed-income products, and managing
                institutional funds. He has also held positions at Fifth Third Bank, JPMorgan Chase & Co., and
                Claymore Securities. Matthew holds FINRA Series 7, 63, 53, and 24 licenses and graduated from
                Taylor University.
              </QBTypography>
              <QBTypography className={classes.titlesection} component="div" variant="body2">
                <div>
                  <span className={classes.name}>Lorenzo Luciano</span>
                </div>
                <div>Vice President</div>
                <div>
                  <span className={classes.phone}>312-506-8755</span>{" "}
                  <span>{utils.formatEmail("lorenzo.luciano@qwickbonds.com")}</span>
                </div>
              </QBTypography>
              <QBTypography className={classes.paragraph} component="div">
                Lorenzo Luciano is a seasoned professional with over 4 years experience in investment management 
                and the securities industry. Lorenzo has been with Qwickbonds with since 2023 and is responsible 
                for sales, trading, & back office operations. He holds his Series 7, 66, 99, and 52, a testament 
                to his expertise and commitment to the highest standards of ethical and professional conduct. 
                Lorenzo graduated Cumme Laude with a B.S. from Manhattan College.
              </QBTypography>
            </div>
          )}
          <div className={classes.section}>
            <QBTypography
              className={classes.sectionTitle}
              variant="h3"
              color="primary"
              gutterBottom
              ref={REFS.disclaimers}
            >
              {Messages.LABEL.DISCLAIMERS}
            </QBTypography>
            <QBTypography className={classes.paragraph} style={{ fontSize: "1.0rem" }}>
              {utils.formatHyperlink(
                `${process.env.PUBLIC_URL}/documents/QwickBonds_Disclaimer.pdf`,
                "Important Information and Disclaimers",
              )}
            </QBTypography>

            <QBTypography className={classes.paragraph}>{Messages.MESSAGE.DISCLAIMERS.GENERAL1}</QBTypography>
            <QBTypography className={classes.paragraph}>{Messages.MESSAGE.DISCLAIMERS.GENERAL2}</QBTypography>
            <QBTypography className={classes.paragraph}>{Messages.MESSAGE.DISCLAIMERS.GENERAL3}</QBTypography>
            <QBTypography className={classes.paragraph}>{Messages.MESSAGE.DISCLAIMERS.GENERAL4}</QBTypography>
            <QBTypography className={classes.paragraph}>
              Please refer to the{" "}
              {utils.formatHyperlink(
                "https://livewebapp.qwickrate.com/qwickrate/XSP/brokeredsecurities/cds/CD_disclosure.pdf",
                "Brokered CD Disclosure Statement",
              )}{" "}
              for important disclosures about brokered CDs, which should be read carefully before purchasing a
              CD. Finra's BrokerCheck for {Messages.BRANDING.PROVIDER_NAME}. can be found{" "}
              {utils.formatHyperlink(
                "https://brokercheck.finra.org/firm/summary/139515",
                "here",
                "FINRA BrokerCheck",
              )}
              , pursuant to Finra Rule 2210, By accessing this website and our services, you agree to the
              terms and conditions stipulated in our{" "}
              {utils.formatHyperlink(`${process.env.PUBLIC_URL}/documents/Privacy.pdf`, "Privacy Policy")}.
            </QBTypography>

            <div className={classes.subsection}>
              <QBTypography variant="h6" color="secondary" ref={REFS.orders}>
                Orders
              </QBTypography>

              <QBTypography className={classes.paragraph}>
                {Messages.MESSAGE.DISCLAIMERS.ORDERS1}
              </QBTypography>
              <QBTypography className={classes.paragraph}>
                {Messages.MESSAGE.DISCLAIMERS.ORDERS2}
              </QBTypography>
            </div>

            {isQuotesEnabled && (
              <div className={classes.subsection}>
                <QBTypography variant="h6" color="secondary" ref={REFS.quotes}>
                  {Messages.LABEL.PRICE_QUOTES}:
                </QBTypography>

                <QBTypography className={classes.paragraph}>
                  {Messages.MESSAGE.DISCLAIMERS[Constants.TRADE_TYPE.PRICE_QUOTES].EXTENDED}
                </QBTypography>
              </div>
            )}
            {isBidsEnabled && (
              <div className={classes.subsection}>
                <QBTypography variant="h6" color="secondary" ref={REFS.bids}>
                  {Messages.LABEL.BIDS_RFQ}:
                </QBTypography>

                <QBTypography className={classes.paragraph}>
                  {Messages.MESSAGE.DISCLAIMERS[Constants.TRADE_TYPE.BIDS_RFQ].EXTENDED}
                </QBTypography>
              </div>
            )}

            {isOfferEnabled && (
              <div className={classes.subsection}>
                <QBTypography variant="h6" color="secondary" ref={REFS.offers}>
                  {Messages.LABEL.BONDS_FOR_SALE}:
                </QBTypography>

                <QBTypography className={classes.paragraph}>
                  {Messages.MESSAGE.DISCLAIMERS[Constants.TRADE_TYPE.BONDS_FOR_SALE].EXTENDED}
                </QBTypography>
              </div>
            )}
          </div>
          <div className={classes.section}>
            <QBTypography className={classes.sectionTitle} variant="h3" color="primary" gutterBottom>
              Credit Ratings
            </QBTypography>

            <div className={classes.subsection}>
              <QBTypography variant="h6" color="secondary">
                Moody's Rating
              </QBTypography>

              <QBTypography className={classes.paragraph} component="div">
                {Messages.MESSAGE.DISCLAIMERS.MOODYS}
              </QBTypography>
            </div>

            <div className={classes.subsection}>
              <QBTypography variant="h6" color="secondary">
                S&P Rating
              </QBTypography>
              <QBTypographyParagraphs textArray={Messages.MESSAGE.DISCLAIMERS.SANDP} />
            </div>
          </div>
          <div className={classes.section}>
            <QBTypography className={classes.sectionTitle} variant="h3" color="primary" gutterBottom>
              Data Providers
            </QBTypography>

            <QBTypography className={classes.paragraph} component="div">
              {Messages.MESSAGE.DISCLAIMERS.DATAPROVIDERS}
            </QBTypography>
          </div>
          <div className={classes.section}>
            <QBTypography className={classes.sectionTitle} variant="h3" color="primary" gutterBottom>
              Supported Browsers
            </QBTypography>

            <QBTypography className={classes.paragraph} component="div">
              The {Messages.BRANDING.APPLICATION_NAME} application is supported for the following browsers:
              <ul>
                <li>Chrome 87+</li>
                <li>Safari 13.1+</li>
                <li>Firefox 90+</li>
                <li>Edge 92+</li>
                <li>Opera 77+</li>
              </ul>
              While other browsers may be used with {Messages.BRANDING.APPLICATION_NAME}, complete
              functionality cannot be assured.
            </QBTypography>
          </div>
        </Div>
      </MainPaper>
    </Div>
  );
};

export default HelpView;
