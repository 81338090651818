import * as Constants from "commons/constants";
import InventoryView from "pages/views/Inventory";
import FavoritesView from "pages/views/Favorites";
import PortfolioTrackerView from "pages/views/IssuerTracker";
import HistoryView from "pages/views/History";
import HelpView from "pages/views/Help";
import NewsView from "pages/views/News";
import BondSidebar from "pages/views/BondSidebar";

import Tools from "pages/views/Tools";

import LibraryBooksIcon from "@material-ui/icons/LibraryBooks";
import ContactPhoneIcon from "@material-ui/icons/ContactPhone";
import ViewListIcon from "@material-ui/icons/ViewList";
import BookmarkIcon from "@material-ui/icons/Bookmark";
import TrackChangesIcon from "@material-ui/icons/TrackChanges";
import AnnouncementOutlinedIcon from "@material-ui/icons/AnnouncementOutlined";

const sidebarRoutes = [
  {
    path: "/news",
    view: Constants.VIEW.NEWS,
    icon: AnnouncementOutlinedIcon,
    component: NewsView,
    secondaryComponent: Tools,
    sidebar: BondSidebar,
    affiliateFeatureFlag: "NEWS_PAGE",
  },
  {
    path: "/",
    view: Constants.VIEW.INVENTORY,
    icon: ViewListIcon,
    component: InventoryView,
    secondaryComponent: Tools,
    sidebar: BondSidebar,
  },
  {
    path: "/favorites",
    view: Constants.VIEW.FAVORITES,
    icon: BookmarkIcon,
    component: FavoritesView,
    secondaryComponent: Tools,
    sidebar: BondSidebar,
  },
  {
    path: "/issuers",
    view: Constants.VIEW.ISSUER_TRACKER,
    icon: TrackChangesIcon,
    component: PortfolioTrackerView,
    secondaryComponent: Tools,
    sidebar: BondSidebar,
    envFeatureFlag: "REACT_APP_FEATURES_ISSUER_TRACKER",
  },
  {
    path: "/history",
    view: Constants.VIEW.HISTORY,
    icon: LibraryBooksIcon,
    component: HistoryView,
    secondaryComponent: "none",
  },

  {
    path: "/help",
    view: Constants.VIEW.HELP,
    icon: ContactPhoneIcon,
    component: HelpView,
    secondaryComponent: Tools,
    sidebar: BondSidebar,
  },
];

export default sidebarRoutes;
