import React from "react";
import PropTypes from "prop-types";

import { SliderInput } from "components/Controls";

export const getDateIncrementTooltipValue = (v) => {
  let label;

  v = Math.abs(Number(v));

  if (v < 12) {
    label = `${v}m`;
  } else {
    const y = Math.floor(v / 12);
    label = `${y}y`;
    if (v % 12 !== 0) {
      const m = v % 12;
      label = `${label}${m}m`;
    }
  }

  return label;
};

export const getDateIncrementLabel = (v) => {
  let label;

  v = Math.abs(Number(v));

  if (v < 12) {
    label = `${v} month`;
    if (v > 1) {
      label = `${label}s`;
    }
  } else {
    const y = Math.floor(v / 12);
    label = `${y} year`;
    if (y > 1) {
      label = `${label}s`;
    }
    if (v % 12 !== 0) {
      const m = v % 12;
      label = `${label} ${m} month`;
      if (m > 1) {
        label = `${label}s`;
      }
    }
  }

  return label;
};

export const getDateIncrementFilterLabel = (v) => {
  let label;

  v = Math.abs(Number(v));

  if (v < 12) {
    label = `${v}mo`;
  } else {
    const y = Math.floor(v / 12);
    label = `${y}yr`;
    if (v % 12 !== 0) {
      const m = v % 12;
      label = `${label} ${m}mo`;
    }
  }

  return label;
};

const IncrementFilter = (props) => {
  const { filterConfig, filter, onFilterChange, SliderProps } = props;

  return (
    <div style={{ marginLeft: 10 }}>
      <SliderInput
        value={filter.filterValue}
        color="secondary"
        onChange={(e, v) =>
          onFilterChange({ filterKey: filter.filterKey, filterValue: v, filterType: filterConfig.type })
        }
        min={filterConfig.min}
        max={filterConfig.max}
        marks={filterConfig.marks}
        step={filterConfig.step}
        valueLabelDisplay="auto"
        showMarks={true}
        {...SliderProps}
      />
    </div>
  );
};

IncrementFilter.propTypes = {
  filterConfig: PropTypes.object.isRequired,
  filter: PropTypes.object.isRequired,
  onFilterChange: PropTypes.func.isRequired,
};

export default IncrementFilter;
