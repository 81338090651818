import React from "react";
import PropTypes from "prop-types";

import { useMessages } from "providers/BrandingProvider";
import * as Constants from "commons/constants";
import { TicketBody, TicketRow } from "components/modals//tickets/TicketComponents";
import { ClosableModal } from "components/containers/Modals";
import QBTypography from "components/QBTypography";
import { FormOutlinedButton } from "components/Controls";

import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: "30px 20px",
    marginBottom: 20,
    minWidth: 400,
    maxWidth: 500,
    border: "1px solid",
    borderColor: theme.palette.border.subtle,
    borderRadius: theme.shape.borderRadius,
    background: theme.palette.background.subtle,
  },
}));

const SelectCartDialog = ({ isOpen, onClose, onCartSelected }) => {
  const classes = useStyles();
  const Messages = useMessages();

  const handleSelectInstrument = (instrument) => {
    onCartSelected(instrument);
    onClose();
  };

  return (
    <ClosableModal open={isOpen} onClose={onClose}>
      <TicketBody>
        <div className={classes.root}>
          <QBTypography>{Messages.MESSAGE.SELECT_CART}</QBTypography>
        </div>
        <TicketRow style={{ justifyContent: "center" }}>
          <FormOutlinedButton
            style={{ margin: "0 0.5rem" }}
            onClick={() => handleSelectInstrument(Constants.INVENTORY_TYPE.CD)}
          >
            {Messages.LABEL.OTHER_OFFERINGS}
          </FormOutlinedButton>
          <FormOutlinedButton
            style={{ margin: "0 0.5rem" }}
            onClick={() => handleSelectInstrument(Constants.INVENTORY_TYPE.MORTGAGE)}
          >
            {Messages.LABEL.MORTGAGE}
          </FormOutlinedButton>
        </TicketRow>
      </TicketBody>
    </ClosableModal>
  );
};

SelectCartDialog.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func,
  onCartSelected: PropTypes.func,
};

export default SelectCartDialog;
