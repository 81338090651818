import { assign } from "lodash";

import { Common } from "./Common";
import image from "assets/img/sidebar-bg-blue.jpg";

import { createTheme } from "@material-ui/core/styles";

export const lightTheme = {
  palette: {
    type: "light",
    primary: {
      main: "#00a0da", //'#00b0f0',
      dark: "#099cd2",
    },
    secondary: {
      main: "#13b71b", //'#00c109',
      dark: "#069e0d",
    },
    tertiary: {
      main: "#00c109",
    },
    purple: {
      main: "#7b1ab7",
    },
    amber: {
      main: "#d34b01",
    },
    error: {
      main: "#d00c0c",
    },
    border: {
      main: "rgba(129, 129, 129, 0.1)",
      highlight: "rgba(30, 150, 36, 0.4)",
      textInput: "rgba(129, 129, 129, 0.4)",
      disabled: "rgba(200, 200, 200, 0.6)",
      subtle: "rgba(200, 200, 200, 0.3)",
      drawer: "#4ca1c0",
      primary: "rgba(0, 143, 195, 0.6)",
      secondary: "rgba(30, 150, 36, 0.5)",
      warning: "#e96507",
      table: "rgba(44, 68, 90, 0.07)",
      axis: "rgba(44, 68, 90, 0.1)",
    },
    logo: {
      main: "#00b0f0",
      dark: "#006185",
    },
    text: {
      primary: "rgba(10, 10, 10, 0.95)",
      secondary: "rgba(100, 100, 100, 0.95)",
      tertiary: "rgba(90, 90, 90, 0.95)",
      disabled: "rgba(160, 160, 160, 0.95)",
      textInput: "rgba(60, 60, 60, 0.95)",
      info: "#e6cb02",
      warning: "#e96507",
      snackbar: "rgba(10, 10, 10, 0.95)",
      unselected: {
        primary: "rgba(0, 143, 195, 0.95)",
        secondary: "rgba(0, 193, 9, 0.87)",
        disabled: "rgba(255, 255, 255, 1.0)",
      },
      selected: {
        primary: "rgba(255, 255, 255, 0.87)",
        secondary: "rgba(255, 255, 255, 0.87)",
      },
      popper: {
        header: "rgba(60, 60, 60, 0.95)",
        text: "rgba(90, 90, 90, 0.95)",
      },
    },
    background: {
      default: "#f3f3f3",
      main: "#f3f3f3",
      drawer: "none",
      paperButton: "#fff",
      paperForm: "#fff",
      paper: "#fafafa",
      popperPaper: "#f6f6f6",
      popperPaperLight: "#f0f0f0",
      textInput: "rgba(0, 0, 0, 0.87)",
      snackbar: "#fff",
      shade: "rgba(255, 255, 255, 0.05)",
      hover: "rgba(0, 0, 0, 0.04)",
      menuhover: "rgba(255, 255, 255, 0.2)",
      selected: "rgba(0, 0, 0, 0.16)",
      subtle: "rgba(200, 200, 200, 0.03)",
      header: "#fff",
      badge: "#13b71b",
      cartbadge: "#e96507",
      disabled: "rgba(0, 0, 0, 0.05)",
      obfuscatedBackdrop: "rgba(255,255,255, 0.8)",
      tooltip: {
        primary: "#007CAB",
        secondary: "#027b07",
      },
      button: {
        primary: "rgba(0, 143, 195, 0.68)",
        disabledprimary: "rgba(0, 143, 195, 0.3)",
        disabledprimarysubtle: "rgba(0, 143, 195, 0.1)",
        secondary: "rgba(6, 158, 13, 0.62)",
        disabledsecondary: "rgba(6, 158, 13, 0.3)",
        buy: "rgba(33, 96, 152, 0.88)",
      },
    },
  },
  sidebar: {
    backgroundImage: image,
  },
};

assign(lightTheme, Common);

export const LightTheme = createTheme(lightTheme);
