export const ACTOR_TYPE = {
  BUYER: "buyer",
  SELLER: "seller",
};

export const ALERT_LEVEL = {
  ERROR: "error",
  WARNING: "warning",
  INFO: "info",
  ANNOUNCEMENT: "annoucement",
};

export const CELL_TYPE = {
  BUY: "BUY",
  DELETE: "DELETE",
  EDIT: "EDIT",
  EDITABLE: "EDITABLE",
  FILTER_TOGGLE: "FILTER_TOGGLE",
  SHOW_ALERT: "SHOW_ALERT",
  TOGGLE_BUTTONS: "TOGGLE_BUTTONS",
  ISSUER_RESPONSIVE: "ISSUER_RESPONSIVE",
  RATES_ROW_HEADER: "RATES_ROW_HEADER",
  HEAT_MAP: "HEAT_MAP",
  CLICKABLE: "CLICKABLE",
  DOCUMENTS: "DOCUMENTS",
  FILE: "FILE",
  ADVANCED_CRITERIA: "ADVANCED_CRITERIA",
  FAVORITE: "FAVORITE",
  FAVORITE_ISSUER: "FAVORITE_ISSUER",
  ISSUER: "ISSUER",
  CHECKBOX: "CHECKBOX",
  LINK_BUTTON: "LINK_BUTTON",
  SELL_SIDE_ACTION_BUTTON: "SELL_SIDE_ACTION_BUTTON",
  ACTIVE_BIDS: "ACTIVE_BIDS",
  STATUS: "STATUS",
  INSURANCE_NUMBER: "INSURANCE_NUMBER",
  MULTISELECT: "MULTISELECT",
};

export const CART_SIZE = 8;

export const CD_TYPE = {
  PRIMARY: "PRIMARY",
  SECONDARY: "SECONDARY",
};

export const DATA_PROPERTY_GROUP = {
  STRUCTURE: "STRUCTURE",
  CALL_INFORMATION: "CALL_INFORMATION",
  REDEMPTIONS: "REDEMPTIONS",
  RATINGS: "RATINGS",
  ISSUANCE: "ISSUANCE",
  CURRENT_DATA: "CURRENT_DATA",
  PROCEEDS_TAX: "PROCEEDS_TAX",
  HISTORICAL_SPEED: "HISTORICAL_SPEED",
  RATE_SHOCKS: "RATE_SHOCKS",
};

export const DETAIL_TYPE = {
  LINK_DOWNLOAD: "LINK_DOWNLOAD",
  POPPER: "POPPER",
};

export const DEFAULT_AFFILIATE_ID = "qwickbonds";

export const FILTER_TYPE = {
  RANGE: "range",
  DUALRANGE: "dualrange",
  SELECTRATING: "selectrrange",
  KEYWORD: "keyword",
  KEYWORD_WILDCARD: "wildcard",
  CHECKBOX: "checkbox",
  TOGGLE: "toggle",
  BOOLEAN_TOGGLE: "boolean_toggle",
  TOGGLEGROUP: "togglegroup",
  TAXSTATUS_TOGGLEGROUP: "taxstatustogglegroup",
  DATERANGE: "daterange",
  RATING_INCREMENT: "ratingincrement",
  NUMERIC_RANGE_INCREMENT: "numericincrement",
  DATE_RANGE_INCREMENT: "dateincrement",
  ADVANCED: "advanced",
  CLEAR: "clear",
  NAV: "nav",
  NEGATING: "negating",
  CUSTOM: "custom",
  NONE: "none",
};

export const FORMAT_TYPE = {
  BOOLEAN: "boolean",
  CURRENCY: "currency",
  DATE: "date",
  DATETIME: "datetime",
  HYPERLINK: "hyperlink",
  HYPERLINK_BUTTON: "hyperlinkbutton",
  NUMBER: "number",
};

export const INVENTORY_TYPE = {
  CD: "CD",
  AGENCY: "Agency",
  MUNICIPAL: "Muni",
  TREASURY: "Treasury",
  MORTGAGE: "MBS",
};

export const INSTRUMENT_CATEGORY = {
  128: INVENTORY_TYPE.CD,
  129: INVENTORY_TYPE.CD,
  130: INVENTORY_TYPE.AGENCY,
  131: INVENTORY_TYPE.TREASURY,
  132: INVENTORY_TYPE.MUNICIPAL,
  133: INVENTORY_TYPE.MORTGAGE,
};

export const INSTRUMENT_TYPE = {
  CD_PRIMARY: "DTC Primary CD",
  CD_SECONDARY: "DTC Secondary CD",
  MUNICIPAL: "Municipal Bond",
  TREASURY: "Treasury",
  AGENCY: "Agency",
  MORTGAGE: "Mortgage Backed",
};

export const RATES_BOND_TYPE = {
  CDS_DIRECT: { label: "QwickRate Direct CDs", type: INVENTORY_TYPE.CD, color: "#039409" },
  CDS_NONCALLABLE: { label: "QwickBonds CDs (Non-Callable)", type: INVENTORY_TYPE.CD, color: "#6fc91e" },
  CDS_CALLABLE: { label: "QwickBonds CDs (Callable)", type: INVENTORY_TYPE.CD, color: "#a3c738" },
  TREASURY: { label: "Treasuries", type: INVENTORY_TYPE.TREASURY, color: "#dbbe5e" },
  AGENCY_NONCALLABLE: { label: "Agencies (Non-Callable)", type: INVENTORY_TYPE.AGENCY, color: "#a11f1d" },
  AGENCY_CALLABLE: { label: "Agencies (Callable)", type: INVENTORY_TYPE.AGENCY, color: "#f52828" },
  MUNICIPAL_TAXEXEMPT_AAA: {
    label: "Tax Exempt Municipal Bonds (AAA)",
    type: INVENTORY_TYPE.MUNICIPAL,
    color: "#3b24d1",
  },
  MUNICIPAL_TAXEXEMPT_AA: {
    label: "Tax Exempt Municipal Bonds (AA)",
    type: INVENTORY_TYPE.MUNICIPAL,
    color: "#1172d4",
  },
  MUNICIPAL_TAXEXEMPT_A: {
    label: "Tax Exempt Municipal Bonds (A)",
    type: INVENTORY_TYPE.MUNICIPAL,
    color: "#00b9ec",
  },
  MUNICIPAL_TAXABLE: { label: "Taxable Municipal Bonds", type: INVENTORY_TYPE.MUNICIPAL, color: "#2ab0b0" },
  MORTGAGE: { label: "Mortgage Backed (Average Life)", type: INVENTORY_TYPE.MORTGAGE, color: "#e17646" },
};

export const CHART_INTRUMENT_MARKER = {
  [INVENTORY_TYPE.CD]: { symbol: "circle", radius: 3, html: "&#9679;" },
  [INVENTORY_TYPE.AGENCY]: { symbol: "square", radius: 3, html: "&#9724;" },
  [INVENTORY_TYPE.MUNICIPAL]: { symbol: "diamond", html: "&#9670" },
  [INVENTORY_TYPE.TREASURY]: { symbol: "triangle", html: "&#9650;" },
  [INVENTORY_TYPE.MORTGAGE]: { symbol: "triangle-down", html: "&#9660;" },
};

export const MESSAGE_KEYS = {
  CONTACT_TRADE_DESK: "CONTACT_TRADE_DESK",
};

export const PANE_TYPE = {
  MAIN: "main",
  SECONDARY: "secondary",
};

export const QUERY_TYPE = {
  INVENTORY: "INVENTORY",
  COMPARABLE: "COMPARABLE",
  COMPARABLE_CUSTOM: "COMPARABLE_CUSTOM",
  CUSIP_LOOKUP: "CUSIP_LOOKUP",
  CUSIP_SEARCH: "CUSIP_SEARCH",
  SIMILAR: "SIMILAR",
  FAVORITES: "FAVORITES",
  FAVORITE_IMPORT: "FAVORITE_IMPORT",
  FAVORITE_ISSUER_IMPORT: "FAVORITE_ISSUER_IMPORT",
  HISTORY: "HISTORY",
  RATES: "RATES",
  STATUS: "STATUS",
  SELL: "SELL",
  REQUEST_TICKET: "REQUEST_TICKET",
  REQUEST_TICKET_EDIT: "REQUEST_TICKET_EDIT",
  REQUEST_TICKET_FAILURES: "REQUEST_TICKET_FAILURES",
  REVIEW_PRICE_QUOTE: "REVIEW_PRICE_QUOTE",
  TRADE_TICKET: "TRADE_TICKET",
  TRADE_TICKET_CONFLICT: "TRADE_TICKET_CONFLICT",
  TRADE_TICKET_FAILURES: "TRADE_TICKET_FAILURES",
  LOOKAHEAD: "LOOKAHEAD",
  DEPTH_OF_BOOK: "DEPTH_OF_BOOK",
  ALL_BY_ISSUER: "ALL_BY_ISSUER",
  PRICE_YIELD_LOOKUP: "PRICE_YIELD_LOOKUP",
  PRINCIPAL_INTEREST_LOOKUP: "PRINCIPAL_INTEREST_LOOKUP",
  AVAILABILITY_LOOKUP: "AVAILABILITY_LOOKUP",
  ADJUST_QUANTITY: "ADJUST_QUANTITY",
  SAVED_SEARCH_ACTION: "SAVED_SEARCH_ACTION",
  FAVORITES_ACTION: "FAVORITES_ACTION",
  SAVED_CRITERIA_ACTION: "SAVED_CRITERIA_ACTION",
  HOLDINGS: "HOLDINGS",
  ALL_BIDS: "ALL_BIDS",
  ISSUER_TRACKER: "ISSUER_TRACKER",
  AFFILIATES: "AFFILIATES",
  AFFILIATES_TRADERS: "AFFILIATES_TRADERS",
  NEWS_ACTION: "NEWS_ACTION",
  DOCUMENTS: "DOCUMENTS",
  TRADE_DOCUMENTS: "TRADE_DOCUMENTS",
};

export const USER_PERMISSIONS = {
  CAN_TRADE: "CANTRADE",
  CAN_USE_FAVORITES_AND_TRACKERS: "CANUSEFAVORITES",
  CAN_VIEW_HISTORY: "CAN_VIEW_HISTORY",
  ROLE_AFFILIATE_ADMIN: "ROLE_AFFILIATE_ADMIN",
};

// By defining a query here and the permission needed to access it,
// the service will automatically return an UNAUTHORIZED without even calling
// the backend in a way the UI component can capture and react
// appropriately - without having to trigger a useless and
// potentially confusing 401 unauth at the backend endpoint
export const QUERY_TYPE_PERMISSIONS = new (function () {
  this[QUERY_TYPE.SELL] = [USER_PERMISSIONS.CAN_TRADE];
  this[QUERY_TYPE.STATUS] = [USER_PERMISSIONS.CAN_TRADE];
  this[QUERY_TYPE.FAVORITES] = [USER_PERMISSIONS.CAN_USE_FAVORITES_AND_TRACKERS];
  this[QUERY_TYPE.ISSUER_TRACKER] = [USER_PERMISSIONS.CAN_USE_FAVORITES_AND_TRACKERS];
  this[QUERY_TYPE.HISTORY] = [USER_PERMISSIONS.CAN_VIEW_HISTORY];
})();

export const QUERY_STATUS_TYPE = {
  CLEAR_STATE: "CLEAR_STATE",
  FETCH_INIT: "FETCH_INIT",
  FETCH_SUCCESS: "FETCH_SUCCESS",
  FETCH_FAILURE: "FETCH_FAILURE",
  FETCH_UNAUTHORIZED: "FETCH_UNAUTHORIZED",
};

export const RATES_TYPE = {
  MEDIAN: "MEDIAN",
  HIGHEST: "HIGHEST",
};

export const RESPONSE = {
  LOADING: "~~~LOADINGDATA~~~",
  NOT_FOUND: "~~~NOTFOUND~~~",
  ISSUER_NOT_FOUND: "~~~ISSUERNOTFOUND~~~",
  INVALID: "~~~INVALID~~~",
  DISABLED: "~~~DISABLED~~~",
};

export const SAVED_SEARCH_CATEGORY = {
  SAVED_SEARCH: "savedsearch",
};

export const STORAGE_ACCESS_TOKEN = "qba";
export const STORAGE_USER = "qbuser";
export const STORAGE_AFFILIATE = "qbi";
export const STORAGE_AFFILIATE_INSTITUTIONS = "qbai";
export const STORAGE_PORTFOLIO = "qbp";
export const STORAGE_SESSION_STATE = "qbs";
export const STORAGE_LAST_ACTIVITY_TIME = "qbla";
export const LAST_LOGIN_TIME = "qbllt";

export const MARKET_STATUS = {
  CLOSED: "CLOSED",
  OPEN: "OPEN",
  EOD: "EOD",
};

export const SUBVIEW = {
  BUY: "BUY",
  SELL: "SELL",
};

export const TOOL_TYPE = {
  COMPARABLE: "COMPARABLE",
  STATUS: "STATUS",
  RATES: "RATES",
  YIELD_CURVE: "YIELD_CURVE",
};

export const TRADE_TYPE = {
  ////buy, sell, bids, quotes
  BUY: "BUY",
  PRICE_QUOTES: "PRICE_QUOTES",
  BIDS_RFQ: "BIDS_RFQ",
  BONDS_FOR_SALE: "BONDS_FOR_SALE",
  BONDS_FOR_SALE_EDIT: "BONDS_FOR_SALE_EDIT",
};

export const VIEW = {
  HOME: "HOME",
  INVENTORY: "INVENTORY",
  FAVORITES: "FAVORITES",
  ISSUER_TRACKER: "ISSUER_TRACKER",
  ALL_BY_ISSUER: "ALL_BY_ISSUER",
  REQUEST: "REQUEST",
  HISTORY: "HISTORY",
  RATES: "RATES",
  NEW_ACCOUNT: "NEW_ACCOUNT",
  CONTACT: "CONTACT",
  HELP: "HELP",
  COMPARABLE: "COMPARABLE",
  STATUS: "STATUS",
  TOOLS: "TOOLS",
  TOOLS_CD: "TOOLS_CD",
  DETAIL: "DETAIL",
  NEWS: "NEWS",
};

export const HEADER_BADGE_ICONS = {
  STYLEDINFOICON: "STYLED_INFO_ICON",
};

export const HTTP_CODES = {
  SUCCESS: 200,
  NO_CONTENT: 204,
  BAD_REQUEST: 400,
  UNAUTHORIZED: 401,
  FORBIDDEN: 403,
  NOT_FOUND: 404,
};

// Tied to lkbond_buy_referrer table on backend
export const TRACKING_REFERRER_IDS = {
  BETTER_RATE: 1,
  COMP_BONDS: 2,
};

// Strings the backend expects for login source tracking
export const TRACKING_LOGIN_VIA = {
  QWICKBONDS: "QB",
  QWICKRATE: "QR",
};

export const LINK_TYPE = {
  FILE: "FILE",
  URL: "URL",
};
