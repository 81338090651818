import React from "react";
import PropTypes from "prop-types";
import { sortBy } from "lodash";
import deburr from "lodash/deburr";

import * as Constants from "commons/constants";
import { useDataService } from "services/DataService";
import { getUser } from "commons/helpers/userStorage";
import { useMessages } from "providers/BrandingProvider";
import * as utils from "commons/utils";

import { UnclosableModal } from "components/containers/Modals";
import QBTypography from "components/QBTypography";

import { makeStyles } from "@material-ui/core/styles";
import { getAffiliateInstitutions } from "commons/helpers/userStorage";
import Typeahead from "components/Typeahead";
import { isTypeaheadEqual } from "components/Typeahead";
import CircularProgress from "@material-ui/core/CircularProgress";

const useStyles = makeStyles((theme) => ({
  body: {
    marginTop: 20,
    maxHeight: "calc(97vh - 250px)",
    overflowY: "auto",
    maxWidth: 300,
  },
  header: {
    fontSize: "0.65rem",
    color: theme.palette.text.popper.header,
    lineHeight: 1,
    marginBottom: 4,
    textTransform: "uppercase",
    letterSpacing: "0.07em",
    fontWeight: 800,
  },
  selectable: {
    fontSize: 12,
    margin: "4px 0",
    border: "1px solid",
    textAlign: "center",
    borderColor: theme.palette.border.textInput,
    borderRadius: theme.shape.borderRadius,
    padding: "4px 8px",
    cursor: "pointer",
    "&:hover": {
      color: theme.palette.secondary.dark,
      borderColor: theme.palette.secondary.main,
    },
  },
}));

const AccountSelectionModal = ({ open, onSelect, allowTradeForOthers = false }) => {
  const classes = useStyles();
  const Messages = useMessages();
  const user = getUser();
  const [state, queryDataService] = useDataService();

  const [institutionKeyword, setInstitutionKeyword] = React.useState("");
  const [filteredInstitutions, setFilteredInstitutions] = React.useState([]);
  const [traders, setTraders] = React.useState();
  const [traderKeyword, setTraderKeyword] = React.useState("");
  const [filteredTraders, setFilteredTraders] = React.useState([]);
  const [selectedAffiliateInstitution, setSelectedAffiliateInstitution] = React.useState();

  const brokeredClearingAccounts = user.brokeredClearingAccounts;
  let affiliateInstitutions = getAffiliateInstitutions();
  if (affiliateInstitutions) {
    affiliateInstitutions = sortBy(affiliateInstitutions, "companyName");
  }

  React.useEffect(() => {
    setInstitutionKeyword("");
    setTraderKeyword("");
    setTraders(null);
    setFilteredTraders([]);

    if (selectedAffiliateInstitution) {
      handleInstitutionSelected(selectedAffiliateInstitution.companyName);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  React.useEffect(() => {
    if (state.data) {
      const traders = state.data.map((d) => {
        return {
          ...d,
          accountFormatted: d.fullName,
        };
      });

      setTraders(sortBy(traders, "fullName"));
    }
  }, [state.data]);

  const findInstitution = (value) => {
    if (!value) return null;
    return affiliateInstitutions.find(
      (i) => deburr(i.companyName.trim().toLowerCase()) === deburr(value.trim().toLowerCase()),
    );
  };

  const filterInstitutions = (value) => {
    if (!value) {
      setFilteredInstitutions(affiliateInstitutions.map((i) => i.companyName));
    } else {
      const filtered = affiliateInstitutions
        .filter((i) => isTypeaheadEqual(i.companyName, value))
        .map((i) => i.companyName);
      setFilteredInstitutions(filtered);
    }
  };

  const handleInstitutionSelected = (value) => {
    const institution = findInstitution(value);
    if (institution) {
      setSelectedAffiliateInstitution(institution);
      queryDataService({
        type: Constants.QUERY_TYPE.AFFILIATES_TRADERS,
        companyId: institution.companyId,
      });
    } else {
      setSelectedAffiliateInstitution(null);
    }
    setFilteredInstitutions([]);
  };

  const handleInstitutionKeywordChange = (value) => {
    setInstitutionKeyword(value);
    filterInstitutions(value);
  };

  const handleInstituionInputClick = () => {
    filterInstitutions(institutionKeyword);
  };

  const handleCloseInstitutionPopper = (value) => {
    setFilteredInstitutions([]);
  };

  const handleInstitutionClickAway = (value) => {
    setFilteredInstitutions([]);
  };

  const findTrader = (value) => {
    if (!value) return null;
    return traders.find(
      (t) => deburr(t.fullName.trim().toLowerCase()) === deburr(value.trim().toLowerCase()),
    );
  };

  const filterTraders = (value) => {
    if (!value) {
      setFilteredTraders(traders.map((t) => t.fullName));
    } else {
      const filtered = traders
        .filter((t) => isTypeaheadEqual(t.fullName, value) || isTypeaheadEqual(t.lastName, value))
        .map((t) => t.fullName);
      setFilteredTraders(filtered);
    }
  };

  const handleTraderSelected = (value) => {
    const trader = findTrader(value);
    if (trader) {
      // TODO, this is not correct, need institution brokered account
      onSelect({
        brokeredClearingAccount: selectedAffiliateInstitution.brokeredClearingAccount,
        affiliateInstitution: selectedAffiliateInstitution,
        affiliateTrader: trader,
      });
    } else {
      setFilteredTraders([]);
    }
  };

  const handleTraderKeywordChange = (value) => {
    setTraderKeyword(value);
    filterTraders(value);
  };

  const handleTraderInputClick = () => {
    filterTraders(traderKeyword);
  };

  const handleCloseTraderPopper = () => {
    setFilteredTraders([]);
  };

  const handleTraderClickAway = () => {
    setFilteredTraders([]);
  };

  return utils.hasNonEmptyValue(user) ? (
    <>
      <UnclosableModal open={open}>
        <QBTypography variant="caption">{Messages.LABEL.SELECT_ACCOUNT}</QBTypography>
        <div className={classes.body}>
          {brokeredClearingAccounts && (
            <div style={{ marginBottom: affiliateInstitutions ? 10 : 0 }}>
              {affiliateInstitutions && <div className={classes.header}>Your Accounts</div>}

              {brokeredClearingAccounts?.map((account) => {
                return (
                  <div
                    key={account.id}
                    className={classes.selectable}
                    onClick={() => onSelect({ brokeredClearingAccount: account })}
                  >
                    {account.accountFormatted}
                  </div>
                );
              })}
            </div>
          )}
          {allowTradeForOthers && affiliateInstitutions && (
            <>
              <div style={{ display: "flex", justifyContent: "center", marginBottom: 10 }}>-- OR --</div>
              <div style={{ marginBottom: 20 }}>
                <div className={classes.header}>Select an Institution</div>
                <Typeahead
                  value={institutionKeyword}
                  onSubmitSearch={handleInstitutionSelected}
                  onInputValueChange={handleInstitutionKeywordChange}
                  onInputClick={handleInstituionInputClick}
                  onClosePopper={handleCloseInstitutionPopper}
                  onClickAway={handleInstitutionClickAway}
                  placeholder={Messages.LABEL.SEARCH_INSTITUTIONS}
                  suggestions={filteredInstitutions}
                  alwaysRenderSuggestions={true}
                  size="large"
                />
              </div>
            </>
          )}
          {allowTradeForOthers && (traders || state.isLoading) && (
            <div style={{ minHeight: 40 }}>
              <div className={classes.header}>Select a Trader</div>
              {!state.isLoading && (
                <Typeahead
                  value={traderKeyword}
                  onSubmitSearch={handleTraderSelected}
                  onInputValueChange={handleTraderKeywordChange}
                  onInputClick={handleTraderInputClick}
                  onClosePopper={handleCloseTraderPopper}
                  onClickAway={handleTraderClickAway}
                  placeholder={Messages.LABEL.SEARCH_TRADERS}
                  suggestions={filteredTraders}
                  alwaysRenderSuggestions={true}
                  size="large"
                />
              )}
              {state.isLoading && (
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <CircularProgress color="secondary" size={16} />
                </div>
              )}
            </div>
          )}
        </div>
      </UnclosableModal>
    </>
  ) : null;
};

AccountSelectionModal.propTypes = {
  open: PropTypes.bool,
  onSelect: PropTypes.func.isRequired,
};

export default AccountSelectionModal;
