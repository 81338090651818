import React from "react";
import PropTypes from "prop-types";

import * as Constants from "commons/constants";
import * as DataOptions from "commons/dataOptions";
import NavigationToolbar from "components/toolbars/NavigationToolbar";
import { useViewStateDispatcher } from "providers/ViewStateProvider";
import { useOptionsService } from "services/OptionsService";

import ButtonGroup from "@material-ui/core/ButtonGroup";
import Button from "@material-ui/core/Button";

import { makeStyles, withStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  wrapper: {
    display: "flex",
    width: "100%",
    flexWrap: "nowrap",
    justifyContent: "center",
    alignItems: "center",
  },
  topbarRoot: {
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
    flexWrap: "nowrap",
    overflow: "hidden",
  },
  topbarSection: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginLeft: 10,
    flexGrow: 1,
    "&:first-child": {
      justifyContent: "flex-start",
      marginLeft: 0,
      flexGrow: 0,
    },
    "&:last-child": {
      justifyContent: "flex-end",
      flexGrow: 1,
    },
  },
  subviewButton: {
    padding: 0,
    fontSize: "0.9rem",
    lineHeight: 1,
    fontWeight: 400,
    backgroundColor: theme.palette.background.paperButton,
    minWidth: 60,
    height: 30,
    borderRadius: 2,
    "&.Mui-disabled": {
      backgroundColor: theme.palette.background.paperButton,
    },
  },
}));

/* BUY/SELL BUTTON GROUP */
export const SubViewNavigation = withStyles((theme) => ({
  root: {
    padding: 0,
    marginRight: 15,
  },
  grouped: {
    whiteSpace: "nowrap",
    color: theme.palette.primary.dark,
    backgroundColor: theme.palette.background.paperButton,
    "&.Mui-disabled": {
      color: theme.palette.primary.dark,
      backgroundColor: theme.palette.background.button.disabledprimarysubtle,
      borderColor: theme.palette.border.primary,
    },
  },
}))(ButtonGroup);

const TableToolbar = (props) => {
  const classes = useStyles();

  const {
    view,
    subView,
    selectedNavOption,
    onNavOptionSelected,
    subViewNavigationOptionsKey,
    additionalItems = [],
  } = props;

  const [options, setOptionKey] = useOptionsService();
  const dispatchViewStateChange = useViewStateDispatcher();

  React.useEffect(() => {
    if (subViewNavigationOptionsKey) {
      setOptionKey(subViewNavigationOptionsKey);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subViewNavigationOptionsKey]);

  const onViewNavSelected = (subView) => {
    dispatchViewStateChange({ state: { activeSubView: subView }, view: view });
  };

  const navOptions = options.map((navOption, i) => {
    return (
      <Button
        key={navOption.value}
        className={classes.subviewButton}
        color="primary"
        variant="outlined"
        onClick={(e) => onViewNavSelected(navOption.value)}
        disabled={subView === navOption.value}
      >
        {navOption.label}
      </Button>
    );
  });

  return (
    <div className={classes.wrapper}>
      <div className={classes.topbarRoot}>
        <div className={classes.topbarSection}>
          {navOptions.length > 0 && (
            <SubViewNavigation variant="outlined" size="small">
              {navOptions}
            </SubViewNavigation>
          )}
          <NavigationToolbar
            selectedOption={selectedNavOption}
            onOptionSelected={onNavOptionSelected}
            optionsKey={subView}
            variant="main"
          />
        </div>
        {additionalItems.map((item, i) => {
          return (
            <div key={item.key} className={classes.topbarSection}>
              {item}
            </div>
          );
        })}
      </div>
    </div>
  );
};

TableToolbar.propTypes = {
  view: PropTypes.oneOf(Object.values(Constants.VIEW)).isRequired,
  subView: PropTypes.oneOf(Object.values(Constants.SUBVIEW)).isRequired,
  additionalItems: PropTypes.arrayOf(PropTypes.node),
  selectedNavOption: PropTypes.string,
  onNavOptionSelected: PropTypes.func,
  subViewNavigationOptionsKey: PropTypes.oneOf(Object.keys(DataOptions.STATIC_OPTIONS)),
};

export default TableToolbar;
